import React from 'react';
import PropTypes from 'prop-types';
import FilterPopover from 'Components/Molecules/FilterPopover/FilterPopover';
import { ReactComponent as FilterIcon } from 'Common/Assets/filter.svg';
import Radio from '@mui/material/Radio';
import { withStyles } from 'tss-react/mui';
import { safeToCapitalizeAllFirstLetters } from 'Utils/safeMethods';
import langValues from 'Config/i18n/index';
import './RadioFilter.scss';

const BlueRadio = withStyles(
  (props) => <Radio color="default" {...props} />,
  (_theme, _params, classes) => ({
    root: {
      color: '#758CA5',
      padding: 0,
      margin: '0px 16px 0px 0px',
      [`&.${classes.checked}`]: {
        color: '#5D7FC8',
      },
    },
    checked: {},
  })
);

const RadioFilter = (props) => {
  const { entries, handleChangeSelect, selected = null, filterName, showCode = false } = props;

  const filterNameCapitalized = safeToCapitalizeAllFirstLetters(filterName);

  const renderModelFilterCode = (code) => {
    if (showCode) {
      return (
        <div className="codeTexts">
          <span className="codeSpan">cod. </span>
          <span className="codeNumberSpan">{code}</span>
        </div>
      );
    }
    return null;
  };

  const renderRadioItem = (item, setAnchorEl) => (
    <div className={`radioItem ${showCode}`} key={item.value}>
      <div className="radio">
        <BlueRadio
          checked={selected === item.value}
          onChange={() => {
            handleChangeSelect(item.value);
            setAnchorEl(null);
          }}
          value={item.value}
          name="radio-button-demo"
          inputProps={{ 'aria-label': item.value }}
          size="small"
        />
      </div>
      <div className="radioItemText">
        {safeToCapitalizeAllFirstLetters(item.label)}
        {renderModelFilterCode(item.value)}
      </div>
    </div>
  );

  const renderHeader = () => (
    <div className="radioHeader">
      <span>{filterNameCapitalized}</span>
    </div>
  );

  const renderRadioList = (setAnchorEl) => (
    <div className="radioFilterPopper">
      {renderHeader()}
      <div className="radioList">{entries.map((item) => renderRadioItem(item, setAnchorEl))}</div>
    </div>
  );

  const isSelected = selected !== null;

  const getSelectedName = () => {
    const selectedEntry = entries.find((item) => item.value === selected);
    return safeToCapitalizeAllFirstLetters(selectedEntry.label);
  };

  const getButtonText = () => {
    let buttonText = `${langValues.filterLabels.tobeFiltered} ${filterNameCapitalized}`;
    if (isSelected) {
      const selectedName = getSelectedName();
      buttonText = `${langValues.filterLabels.filteredBy}: ${selectedName}`;
    }
    return buttonText;
  };

  return (
    <div className="radioFilterButton">
      <FilterPopover
        IconLeft={FilterIcon}
        buttonText={getButtonText()}
        active={isSelected}
        handleIconRightClick={() => handleChangeSelect(null)}
        placement="bottom-start"
        popperOverflow={false}
        popoverContent={(setAnchorEl) => renderRadioList(setAnchorEl)}
      />
    </div>
  );
};

RadioFilter.propTypes = {
  entries: PropTypes.array.isRequired,
  handleChangeSelect: PropTypes.func.isRequired,
  filterName: PropTypes.string.isRequired,
  showCode: PropTypes.bool,
  selected: PropTypes.string,
};

export default RadioFilter;
