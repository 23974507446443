import React from 'react';
import PropTypes from 'prop-types';

import { safeToUpperCase } from 'Utils/safeMethods';
import './LabelWithHelpIcon.scss';

function LabelWithHelpIcon({ text, Icon }) {
  return (
    <div className="labelWithIconDiv">
      <div className="labelIcon">{safeToUpperCase(text)}</div>
      <div className="icon"><Icon /></div>
    </div>
  );
}

LabelWithHelpIcon.propTypes = {
  text: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
};

export default LabelWithHelpIcon;
