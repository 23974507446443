import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ReactComponent as ErrorIcon } from 'Common/Assets/alert-octagon.svg';
import InfoIcon from '@mui/icons-material/Info';
import green from '@mui/material/colors/green';
import amber from '@mui/material/colors/amber';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import WarningIcon from '@mui/icons-material/Warning';
import { withStyles } from 'tss-react/mui';
import langValues from 'Config/i18n/index';
import Button from '@mui/material/Button';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = (theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  snackBarContentWrapper: {
    display: 'flex',
    width: 'fit-content',
    flexDirection: 'row',
    alignItens: 'center',
  },
  errorSnackbarRedLine: {
    width: '100%',
    heigth: '5px',
    marginTop: '5px',
    marginBottom: '0px',
    backgroundColor: 'red',
  },
  typography: {
    useNextVariants: true,
  },
});

const updateScreen = () => {
  window.location.reload();
};

const action = (
  <Button onClick={updateScreen} style={{ border: '1px solid black', fontSize: '9px' }}>
    {langValues.snackBarLabels.updateScreen}
  </Button>
);

function MySnackbarContent(props) {
  const { classes, className = null, message, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <div id="snackBarContentWrapper" className={classes.snackBarContentWrapper}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {message}
          </div>
        </span>
      }
      action={action}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(MySnackbarContent, styles1);

const styles2 = (theme) => ({
  errorSnackbar: {
    margin: theme.spacing(1),
    backgroundColor: '#FFFFFF',
    width: 'max-content',
    maxHeight: '100%',
    minHeight: '62px',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#212B36',
    vertical: 'bottom',
    horizontal: 'center',
    maxWidth: '100%',
    minWidth: '100%',
  },
  typography: {
    useNextVariants: true,
  },
});

class CustomizedSnackbars extends React.Component {
  state = {
    open: false,
  };

  componentDidMount = () => {
    this.setState({ open: true });
  };

  handleClose = () => {};

  render() {
    const { classes, message } = this.props;
    const { open } = this.state;

    return (
      <>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={open}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <div>
            <MySnackbarContentWrapper variant="error" className={classes.errorSnackbar} message={message} />
          </div>
        </Snackbar>
      </>
    );
  }
}

CustomizedSnackbars.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
};

export default withStyles(CustomizedSnackbars, styles2);
