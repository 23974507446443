import React from 'react';
import PropTypes from 'prop-types';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';
import langValues from 'Config/i18n/index';
import { trackEvent } from 'Utils/analytics';
import { trackedEvents, trackedButtons } from 'Utils/consts';

import './FeedbackTip.scss';

const FeedbackTip = ({ declineFeedbackTip, setFeedbackModalOpened }) => (
  <div className="helpButton__feedbackTip">
    <h6>{langValues.feedbackLabels.feedbackTip.title}</h6>
    <span className="font-body2">{langValues.feedbackLabels.feedbackTip.text}</span>
    <div className="helpButton__feedbackTip__buttons">
      <MaterialButton
        buttonText={langValues.feedbackLabels.feedbackTip.buttonRefuse}
        onClick={() => {
          declineFeedbackTip();
          trackEvent(trackedEvents.buttonClick, { button: trackedButtons.declineFeedback });
        }}
        type="secondary"
        variant="outlined"
      />
      <MaterialButton
        buttonText={langValues.feedbackLabels.feedbackTip.buttonAccept}
        onClick={() => {
          setFeedbackModalOpened(true);
          trackEvent(trackedEvents.buttonClick, { button: trackedButtons.acceptFeedback });
        }}
        type="primary"
        variant="contained"
      />
    </div>
  </div>
);

FeedbackTip.propTypes = {
  declineFeedbackTip: PropTypes.func.isRequired,
  setFeedbackModalOpened: PropTypes.func.isRequired,
};

export default FeedbackTip;
