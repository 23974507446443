import ChamadoSiebelRepository from 'Infrastructure/Repositories/ChamadoSiebelRepository';

class ChamadoSiebelService {
  constructor(chamadoSiebelRepository = new ChamadoSiebelRepository()) {
    this.chamadoSiebelRepository = chamadoSiebelRepository;
  }

  put = async (incomingData) => {
    try {
      const { data } = await this.chamadoSiebelRepository.put(incomingData);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  get = async (industrialOrderId) => {
    try {
      const { data } = await this.chamadoSiebelRepository.get(industrialOrderId);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default ChamadoSiebelService;
