import { connect } from 'react-redux';
import {
  loadNotifications, updateViewedNotifications,
} from 'Infrastructure/Ducks/NotificationDuck';
import NotificationButton from './NotificationButton';

const mapStateToProps = (state) => {
  const { currentNotifications, viewedNotificationIds } = state.notificationDuck;
  return {
    currentNotifications, viewedNotificationIds,
  };
};

const mapDispatchToProps = dispatch => ({
  loadNotifications: () => dispatch(loadNotifications()),
  updateViewedNotifications: () => dispatch(updateViewedNotifications()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationButton);
