import React from 'react';
import PropTypes from 'prop-types';
import MuiButton from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';
import { defaultButtonStyles, activeButtonStyles } from './styles';

const DoubleIconButton = ({
  text,
  IconRight,
  IconLeft,
  active,
  onButtonClick = () => {},
  onIconRightClick = null,
  onIconLeftClick = null,
}) => {
  const onClickIcon = (e, onClickIconInput) => {
    if (onClickIconInput) {
      onClickIconInput();
      e.stopPropagation();
    }
  };

  const styles = active ? activeButtonStyles : defaultButtonStyles;
  const Button = withStyles(MuiButton, styles);

  return (
    <Button
      startIcon={IconLeft ? <IconLeft onClick={(e) => onClickIcon(e, onIconLeftClick)} /> : null}
      endIcon={IconRight ? <IconRight onClick={(e) => onClickIcon(e, onIconRightClick)} /> : null}
      onClick={onButtonClick}
    >
      <span>{text}</span>
    </Button>
  );
};

DoubleIconButton.propTypes = {
  text: PropTypes.string.isRequired,
  active: PropTypes.bool,
  IconLeft: PropTypes.elementType,
  IconRight: PropTypes.elementType,
  onIconRightClick: PropTypes.func,
  onIconLeftClick: PropTypes.func,
  onButtonClick: PropTypes.func,
};

export default DoubleIconButton;
