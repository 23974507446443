import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import { orderListConsts, environment } from 'Utils/consts';

import CpfText from 'Components/Formatters/CpfText';
import CnpjText from 'Components/Formatters/CnpjText';
import ClientCategoryIcon from 'Components/ComercialDetailsScreenComponents/PedidoComercial/DadosCliente/ClientCategoryIcon';
import NomeCliente from 'Components/ComercialDetailsScreenComponents/PedidoComercial/DadosCliente/NomeCliente';
import Pendencia from 'Components/ComercialDetailsScreenComponents/PedidoComercial/DadosCliente/Pendencia';

import { safeToLowerCase } from 'Utils/safeMethods';
import langValues from 'Config/i18n/index';

import './DadosCliente.scss';

class DadosCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderTypePedido = (clientCategory, orderType) => (
    ENV_ENVIRONMENT === environment.BR
      ? (
        <div className="typeIcon">
          <ClientCategoryIcon clientCategory={clientCategory} orderType={orderType} />
        </div>
      )
      : null
  );

  renderNomeCliente = clientName => (
    <NomeCliente clientName={clientName} />
  );

  renderPendecia = (order) => {
    const tipoRender = langValues.labelsPedidoComercial.pendCli;
    return (
      <Pendencia item={order} renderType={tipoRender} />
    );
  }

  renderClienteDocField = (order) => {
    if (order.personTypeId === orderListConsts.naturalPerson) {
      return <CpfText cpf={order.clientDocument} />;
    }
    return <CnpjText cnpj={order.clientDocument} />;
  }

  render = () => {
    const {
      order,
    } = this.props;
    return (
      <div className="item">

        {this.renderTypePedido(order.clientCategoryId, order.orderType)}

        <div className="clientDataDiv">
          <div className="nome-cliente">
            {this.renderNomeCliente(safeToLowerCase(order.clientName))}
          </div>
          <div className="doc-cliente">
            {this.renderClienteDocField(order)}
          </div>
        </div>
      </div>
    );
  };
}

DadosCliente.propTypes = {
  order: PropTypes.object.isRequired,
};

export default DadosCliente;
