import React from 'react';
import langValues from 'Config/i18n/index';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowUpIcon } from 'Common/Assets/ArrowDownWhite.svg';
import CircularProgress from '@mui/material/CircularProgress';
import SnackbarTransactions from 'Components/Atoms/SnackbarTransactions/SnackbarTransactions';
import './UploadButton.scss';

const UploadButton = ({
  isUploading,
  successUploading = false,
  errorUploading,
  onUpload,
  resetError,
  titleSnackbarError = langValues.uploadButton.titleSnackbarErrorUpload,
}) => {
  const renderSnackbarError = () => (
    <SnackbarTransactions
      type="failure"
      isOpen={errorUploading}
      message={titleSnackbarError.concat(errorUploading ? errorUploading.response.data : null)}
      onClose={() => resetError()}
      shouldRenderOnCloseButton
    />
  );

  const renderSnackbarSuccess = () => (
    <SnackbarTransactions
      type="success"
      isOpen={successUploading}
      message={langValues.uploadButton.titleSnackbarSuccess}
    />
  );

  const renderUploadButton = () => (
    <label htmlFor="upload-button" className="uploadButton">
      <input id="upload-button" type="file" accept=".csv" onChange={onUpload} />
      <div className="uploadButton-div">
        {isUploading ? (
          <CircularProgress size={14} className="progress-bar" />
        ) : (
          <>
            <ArrowUpIcon />
            <span className="uploadButton-title">{langValues.upload}</span>
          </>
        )}
      </div>
    </label>
  );

  return (
    <>
      {renderUploadButton()}
      {renderSnackbarError()}
      {renderSnackbarSuccess()}
    </>
  );
};

UploadButton.propTypes = {
  titleSnackbarError: PropTypes.string,
  isUploading: PropTypes.bool,
  successUploading: PropTypes.bool,
  errorUploading: PropTypes.object,
  onUpload: PropTypes.func,
  resetError: PropTypes.func,
};

export default UploadButton;
