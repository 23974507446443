import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as FilterIcon } from 'Common/Assets/filter.svg';
import langValues from 'Config/i18n/index';

import FilterPopover from '../FilterPopover/FilterPopover';

const FilterAccordionAhorro = ({ popoverContent, isAnyFilterSelected, handleClear }) => {
  return (
    <FilterPopover
      IconLeft={FilterIcon}
      active={isAnyFilterSelected}
      buttonText={langValues.filterLabels[isAnyFilterSelected ? 'changeFilters' : 'filterPlans']}
      handleIconRightClick={handleClear}
      placement="bottom-start"
      popoverContent={popoverContent}
    />
  );
};

FilterAccordionAhorro.propTypes = {
  popoverContent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  isAnyFilterSelected: PropTypes.bool.isRequired,
  handleClear: PropTypes.func,
};

export default FilterAccordionAhorro;
