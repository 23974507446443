/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from 'Components/Shared/Input';
import langValues from 'Config/i18n/index';

import { safeString2Int } from 'Utils/safeMethods';

import './TransitTimeListItem.scss';

const sort = langValues.labelsTransitTime.keys;
class TransitTimeListItem extends Component {
  renderEmptyListItem = () => (
    <tr className="list-item empty-list-item-transitTime">
      <td className="emptyData" />
      {sort.map(() => (
        <td className="emptyData" key={Math.random()} />
      ))}
    </tr>
  );

  handleOnClickItem = () => {
    const { item, onClickItem = () => {}, editing } = this.props;
    if (editing) onClickItem(item.destiny);
  };

  handleChange = (key, value) => {
    const { onChange = () => {}, item } = this.props;
    const newItem = { ...item };
    newItem[key] = safeString2Int(value);
    onChange(item.destiny, newItem);
  };

  renderInputBase = (value, editingItem, key) => {
    const { editing = false } = this.props;
    return (
      <Input
        value={value}
        editingItem={editingItem}
        editing={editing}
        itemKey={key}
        onChange={this.handleChange}
        inputProps={{ maxLength: 4 }}
        placeholder="nº"
        type="numeric"
      />
    );
  };

  renderDestiny = (item, editingItem) => (
    <td className="transitTime-field">
      <Input value={item.destiny} editingItem={editingItem} disabled fixedWidth="100%" textAlign="left" />
    </td>
  );

  renderItem = (item, editingItem) =>
    sort.map((key) => {
      if (sort.includes(key)) {
        const lineStyle = editingItem ? 'input-base-complement input-base-complement-editing' : 'input-base-complement';
        return (
          <td className="transitTime-field" key={key}>
            <div className="transitTime-input-wrapper">{this.renderInputBase(item[key], editingItem, key)}</div>
            <p className={lineStyle}>{`dia${item[key] !== '1' && item[key] !== 1 ? 's' : ''}`}</p>
          </td>
        );
      }
      return null;
    });

  renderListComponent = (item) => {
    if (item) {
      const { currentEditing = null, editing } = this.props;
      const editingItem = item.destiny === currentEditing;
      const lineStyle = editing && editingItem ? 'list-item-content list-item-editing' : 'list-item-content';
      return (
        <tr className={`transitTime-list-item ${lineStyle}`} disabled={editingItem} onClick={this.handleOnClickItem}>
          {this.renderDestiny(item, editingItem)}
          {this.renderItem(item, editingItem)}
        </tr>
      );
    }
    return this.renderEmptyListItem();
  };

  render = () => {
    const { item } = this.props;
    return this.renderListComponent(item);
  };
}

TransitTimeListItem.propTypes = {
  item: PropTypes.object,
  onClickItem: PropTypes.func,
  currentEditing: PropTypes.object,
  onChange: PropTypes.func,
  editing: PropTypes.bool,
};

export default TransitTimeListItem;
