import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListItemPedidos from 'Components/OrderListComponents/ListItemPedidos';

import '../../OrderListComponents/ListPedidos/ListPedidos.scss';
import './List.scss';

export const listPedidosSkeleton = (numeroItens) => {
  const skeleton = [];
  for (let i = 0; i < numeroItens; i += 1) {
    skeleton.push(<ListItemPedidos />);
  }
  return skeleton;
};
class List extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderHeader = (header) => (
    <thead>
      <tr>
        {header.map((title) => (
          <th className="header-item" key={title + Math.random()}>
            {title}
          </th>
        ))}
      </tr>
    </thead>
  );

  renderList = (childProps, Type) => <Type {...childProps} />;

  render = () => {
    const { header, childProps = {}, Type, listContentStyle = {} } = this.props;
    return (
      <table className={`list-content ${childProps.isPlanAhorro ? 'plan-ahorro' : ''}`} style={listContentStyle}>
        {this.renderHeader(header)}
        <tbody>{this.renderList(childProps, Type)}</tbody>
      </table>
    );
  };
}

List.propTypes = {
  header: PropTypes.array.isRequired,
  childProps: PropTypes.object,
  Type: PropTypes.func.isRequired,
  listContentStyle: PropTypes.object,
};

export default List;
