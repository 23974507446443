import MuiCheckbox from '@mui/material/Checkbox';
import { withStyles } from 'tss-react/mui';

export const Checkbox = withStyles(MuiCheckbox, {
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
});

export default null;
