import React from 'react';
import PropTypes from 'prop-types';
import { trackedPages } from 'Utils/consts';
import FilterDates from 'Components/Molecules/FilterDates';
import FilterAccordion from 'Components/Molecules/FilterAccordion';
import FilterSearch from 'Components/Molecules/FilterSearch';
import './OrderFilters.scss';

const OrderFilters = ({ ...props }) => {
  const { onConfirmFilter, renderSearch, filterPlacement, pageName, models } = props;

  const isPageDelayManamegent = pageName === trackedPages.delayManagement;

  return (
    <>
      <div className="ordersFiltersButtons">
        <FilterAccordion
          models={models}
          handleConfirm={onConfirmFilter}
          filterPlacement={filterPlacement}
          isPageDelayManamegent={isPageDelayManamegent}
        />
        <FilterDates
          handleConfirm={onConfirmFilter}
          filterPlacement={filterPlacement}
          isPageDelayManamegent={isPageDelayManamegent}
        />
      </div>
      {renderSearch ? (
        <FilterSearch handleConfirm={onConfirmFilter} isPageDelayManamegent={isPageDelayManamegent} />
      ) : null}
    </>
  );
};

OrderFilters.propTypes = {
  onConfirmFilter: PropTypes.func.isRequired,
  renderSearch: PropTypes.bool,
  filterPlacement: PropTypes.string.isRequired,
  pageName: PropTypes.string,
  models: PropTypes.object.isRequired,
};

export default OrderFilters;
