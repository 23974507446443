import React from 'react';
import PropTypes from 'prop-types';
import { User } from 'Common/Helpers/AuthHelper';
import {
  environment,
  roleConsts,
} from 'Utils/consts';
import { ReactComponent as CalendarIcon } from 'Common/Assets/calendar.svg';
import DataExtenso from 'Components/Formatters/DataExtenso';
import './ListModelVersionForecast.scss';
import langValues from 'Config/i18n/index';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';

const renderDateAverageInvoiceTime = date => (
    <>
      <div className="calendar-icon">
        <CalendarIcon />
      </div>
      <div className="limitDeliveryDate">
        <DataExtenso date={date} />
      </div>
    </>
);

const ListModelVersionForecast = ({ listItems }) => listItems.map((item) => {
  const isUserDealerBR = User.get().userProfile.includes(roleConsts.dealers)
    && ENV_ENVIRONMENT === environment.BR;

  const isEnvironmentAR = ENV_ENVIRONMENT === environment.AR;

  const getAverageInvoiceTime = () => {
    if (item.averageInvoiceTime) {
      return `${item.averageInvoiceTime} dias`;
    }
    return langValues.unavailableData;
  };

  const getInsertedInvoiceTime = () => {
    if (item.insertedInvoiceTime) {
      const invoiceDate = new Date(item.insertedInvoiceTime);
      return renderDateAverageInvoiceTime(invoiceDate);
    }
    return langValues.unavailableData;
  };

  const renderAverageInvoiceTime = () => {
    if (isUserDealerBR) {
      return null;
    }
    return (
      <td className="font-subtitle2">{getAverageInvoiceTime()}</td>
    );
  };

  const renderInsertedInvoiceTime = () => {
    if (isEnvironmentAR) {
      return null;
    }
    return (
      <td className="font-subtitle2">{getInsertedInvoiceTime()}</td>
    );
  };
  return (
    <tr className="listModelVersionForecast-item" key={`${item.modelCode}-${item.versionCode}`}>
      <td className="font-subtitle2 item-bolder">{item.model}</td>
      <td className="font-subtitle2">{item.versionName}</td>
      {renderAverageInvoiceTime()}
      {renderInsertedInvoiceTime()}
    </tr>
  );
});

ListModelVersionForecast.propTypes = {
  listItems: PropTypes.array.isRequired,
};

export default ListModelVersionForecast;
