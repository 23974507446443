import ReleaseNotesService from '../Services/ReleaseNotesService';

export const DUCK_NAME = 'releaseNotesDuck';

const RESPONSE_INITIAL_STATE = () => ({
  loading: false,
  error: false,
});


const INITIAL_STATE = () => ({
  isReleaseNotesModalOpened: false,
  releaseNotesResponse: RESPONSE_INITIAL_STATE(),
  releaseNotes: [],
});

const SET_RELEASE_NOTES_MODAL_OPENED = `${DUCK_NAME}/SET_RELEASE_NOTES_MODAL_OPENED`;
const LOAD_RELEASE_NOTES_STARTED = `${DUCK_NAME}/LOAD_RELEASE_NOTES_STARTED`;
const LOAD_RELEASE_NOTES_SUCCEED = `${DUCK_NAME}/LOAD_RELEASE_NOTES_SUCCEED`;
const LOAD_RELEASE_NOTES_FAILED = `${DUCK_NAME}/LOAD_RELEASE_NOTES_FAILED`;
const CLEAR_STATE = `${DUCK_NAME}/CLEAR_STATE`;

const loadReleaseNotesStarted = () => ({ type: LOAD_RELEASE_NOTES_STARTED });
const loadReleaseNotesSucceed = releaseNotes => (
  { type: LOAD_RELEASE_NOTES_SUCCEED, releaseNotes }
);
const loadReleaseNotesFailed = error => ({ type: LOAD_RELEASE_NOTES_FAILED, error });

export const clearReleaseNotesState = () => ({ type: CLEAR_STATE });

export const setReleaseNotesModalOpened = isReleaseNotesModalOpened => (
  { type: SET_RELEASE_NOTES_MODAL_OPENED, isReleaseNotesModalOpened }
);

export const loadReleaseNotes = () => async (dispatch) => {
  dispatch(loadReleaseNotesStarted());
  try {
    const releaseNotesService = new ReleaseNotesService();
    const releaseNotes = await releaseNotesService.getReleaseNotes();
    dispatch(loadReleaseNotesSucceed(releaseNotes));
  } catch (err) {
    dispatch(loadReleaseNotesFailed(err));
  }
};

const reducer = (state = INITIAL_STATE(), action) => {
  switch (action.type) {
    case SET_RELEASE_NOTES_MODAL_OPENED:
      return {
        ...state,
        isReleaseNotesModalOpened: action.isReleaseNotesModalOpened,
      };
    case LOAD_RELEASE_NOTES_STARTED:
      return {
        ...state,
        releaseNotesResponse: {
          loading: true, error: false,
        },
      };
    case LOAD_RELEASE_NOTES_FAILED:
      return {
        ...state,
        releaseNotesResponse: {
          loading: false, error: true,
        },
        releaseNotes: [],
      };
    case LOAD_RELEASE_NOTES_SUCCEED:
      return {
        ...state,
        releaseNotesResponse: {
          loading: false, error: false,
        },
        releaseNotes: action.releaseNotes,
      };
    case CLEAR_STATE:
      return INITIAL_STATE();
    default:
      return state;
  }
};

export default reducer;
