import React from 'react';
// import './OrderDetailPDA.scss';

const Skelleton = () => {
  return (
    <main className="orderDetail pda-center">
      <div className="orderDetail--header" />
      <div className="orderDetail--timeline" />
    </main>
  );
};

export default Skelleton;
