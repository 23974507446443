import React from 'react';
import PropTypes from 'prop-types';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import SearchInput from 'Components/Shared/SearchInput';
import { alertTypes, environment } from 'Utils/consts';
import langValues from 'Config/i18n/index';
import AlertComponent from 'Components/Atoms/AlertComponent';

const FilterSearch = ({ ...props }) => {
  const {
    filters,
    handleConfirm,
    isPageDelayManamegent,
    setIsToolTipOpen,
    setSearchOrdersString,
    setSearchDelayTrackingString,
  } = props;
  console.log(props);
  const { searchOrdersString, searchDelayTrackingString, isToolTipOpen } = filters;

  const searchInputPlaceholder = langValues.componentBuscarLabels.placeholderHistoric;

  const handleOnSearch = () => {
    if (isToolTipOpen && !isPageDelayManamegent) {
      setIsToolTipOpen(false);
    }
    handleConfirm();
  };

  const handleOnClear = () => {
    if (isToolTipOpen && !isPageDelayManamegent) {
      setIsToolTipOpen(null);
    }
    handleConfirm();
  };

  const handleSearchInputChanges = (value) => {
    if (isToolTipOpen === null && !isPageDelayManamegent) {
      setIsToolTipOpen(true);
    }
    if (isPageDelayManamegent) {
      setSearchDelayTrackingString(value);
    } else {
      setSearchOrdersString(value);
    }
  };

  return (
    <AlertComponent
      title={langValues.orderFilterModalWarn.title}
      text={langValues.orderFilterModalWarn.text}
      alertType={alertTypes.info}
      isToolTipOpen={isToolTipOpen && !isPageDelayManamegent && environment.BR === ENV_ENVIRONMENT}
      setIsToolTipOpen={setIsToolTipOpen}
    >
      <div className="searchInput">
        <SearchInput
          search={() => handleOnSearch()}
          onChange={(value) => handleSearchInputChanges(value)}
          searchString={isPageDelayManamegent ? searchDelayTrackingString : searchOrdersString}
          placeholder={searchInputPlaceholder}
          onClear={handleOnClear}
        />
      </div>
    </AlertComponent>
  );
};

FilterSearch.propTypes = {
  setIsToolTipOpen: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  setSearchOrdersString: PropTypes.func.isRequired,
  setSearchDelayTrackingString: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  isPageDelayManamegent: PropTypes.bool.isRequired,
};

export default FilterSearch;
