import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as CloseIcon } from 'Common/Assets/close-cross.svg';
import { ReactComponent as AlertIcon } from 'Common/Assets/alert-circle.svg';
import Tooltip from '@mui/material/Tooltip';
import { alertTypes } from 'Utils/consts';
import './AlertComponent.scss';

const borderStyle = '0.5px solid #E8E8E8';
const boxShadowStyle = '0px 2px 12px 0px rgba(0, 0, 0, 0.08)';

const useCustomTooltipStyles = makeStyles()({
  tooltip: {
    backgroundColor: '#fff',
    color: '#000',
    padding: 0,
    border: borderStyle,
    boxShadow: boxShadowStyle,
  },
  arrow: {
    color: '#fff',
    fontSize: 18,
    '&::before': {
      border: borderStyle,
      boxShadow: boxShadowStyle,
    },
  },
  tooltipPlacementTop: {
    margin: '20px 0',
  },
});

const AlertComponent = ({
  title,
  text,
  alertType = alertTypes.warn,
  children,
  isToolTipOpen = false,
  setIsToolTipOpen,
}) => {
  const renderCloseIcon = () => <CloseIcon className="closeIcon" onClick={() => setIsToolTipOpen(false)} />;

  const renderAlertIcon = () => <AlertIcon className="alertIcon" />;

  const renderTooltipContent = () => (
    <div className={`alertComponent__container ${alertType}`}>
      {renderAlertIcon()}
      <div className="alertComponent__message">
        <span className="alertTitle">{title}</span>
        <span className="alertText">{text}</span>
      </div>
      {renderCloseIcon()}
    </div>
  );

  const { classes } = useCustomTooltipStyles();

  return (
    <Tooltip
      className="alertComponent__tooltip"
      open={isToolTipOpen || false}
      classes={classes}
      title={renderTooltipContent()}
      placement="top"
      //interactive
      arrow
      PopperProps={{
        disablePortal: true,
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: [],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              altBoundary: true,
            },
          },
        ],
      }}
    >
      {children}
    </Tooltip>
  );
};

AlertComponent.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  alertType: PropTypes.string,
  children: PropTypes.object.isRequired,
  isToolTipOpen: PropTypes.bool,
  setIsToolTipOpen: PropTypes.func.isRequired,
};

export default AlertComponent;
