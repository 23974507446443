import RequestRepository from 'Infrastructure/Repositories/RequestRepository';
import { Token } from 'Common/Helpers/AuthHelper';

class InvoiceService {
  downloadInvoice = async (industrialOrderId, orderId, vin) => {
    const token = Token && Token.get() ? Token.get().token : '';

    const parameters = `invoice/${industrialOrderId}?orderId=${orderId}`;
    const fileName = `notaFiscal_VIN_${vin}`;
    const fileType = 'pdf';
    const config = {
      headers: {
        userToken: token,
      },
    };
    try {
      const { result } = await RequestRepository.export(parameters, config, fileName, fileType);
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  downloadInvoiceEmployee = async (orderId) => {
    const token = Token && Token.get() ? Token.get().token : '';

    const parameters = `invoice/${token}?orderId=${orderId}`;
    const fileName = `notaFiscal_ORDER_${orderId}`;
    const fileType = 'pdf';
    const config = {
      headers: {},
    };
    try {
      const { result } = await RequestRepository.export(parameters, config, fileName, fileType);
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}


export default InvoiceService;
