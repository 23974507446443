import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CalendarIcon } from 'Common/Assets/calendar.svg';
import DataExtenso from '../DataExtenso/DataExtenso';

import './DateText.scss';

const DateText = ({ date }) => (
  <div className="dateText-wrapper">
    <CalendarIcon />
    <div className="dateText">{date ? <DataExtenso date={date} /> : 'Sem Data'}</div>
  </div>
);

DateText.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DateText;
