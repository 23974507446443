import React from 'react';
import PropTypes from 'prop-types';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import { environment } from 'Utils/consts';

function mascaraCpf(cpf) {
  if (!cpf) {
    return null;
  }
  if (ENV_ENVIRONMENT === environment.AR) {
    return cpf.replace(/(\d{2})(\d{8})(\d{1})/g, '$1-$2-$3');
  }
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
}

function CpfText({ cpf }) {
  return <span className="cpfText">{mascaraCpf(cpf)}</span>;
}

CpfText.propTypes = {
  cpf: PropTypes.string,
};

export default CpfText;
