import DashboardAccessReportService from 'Infrastructure/Services/DashboardAccessReportService';

const INITIAL_STATE = () => ({
  loading: false,
  success: false,
  error: null,
  accessReport: null,
});

export const DUCK_NAME = 'dashboardAccessDuck';

const LOAD_ACCESS_STARTED = `${DUCK_NAME}/LOAD_ACCESS_STARTED`;
const LOAD_ACCESS_SUCCEED = `${DUCK_NAME}/LOAD_ACCESS_SUCCEED`;
const LOAD_ACCESS_FAILED = `${DUCK_NAME}/LOAD_ACCESS_FAILED`;
const CLEAR_ACCESS_DUCK = `${DUCK_NAME}/CLEAR_ACCESS_DUCK`;

const loadAccessStarted = () => ({ type: LOAD_ACCESS_STARTED });
const loadAccessSucceed = accessReport => ({ type: LOAD_ACCESS_SUCCEED, accessReport });
const loadAccessFailed = error => ({ type: LOAD_ACCESS_FAILED, error });
export const clearAccessDuck = () => ({ type: CLEAR_ACCESS_DUCK });

export const loadAccessReport = (fromDate, toDate) => async (dispatch) => {
  dispatch(loadAccessStarted());
  const accessService = new DashboardAccessReportService();
  try {
    const accessReport = await accessService.getAccessReport(fromDate, toDate);
    dispatch(loadAccessSucceed(accessReport));
  } catch (err) {
    dispatch(loadAccessFailed(err));
  }
};

const reducer = (state = INITIAL_STATE(), action) => {
  switch (action.type) {
    case LOAD_ACCESS_STARTED:
      return {
        loading: true,
        success: false,
        error: null,
      };
    case LOAD_ACCESS_SUCCEED:
      return {
        ...state,
        loading: false,
        success: true,
        accessReport: action.accessReport,
        error: null,
      };
    case LOAD_ACCESS_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case CLEAR_ACCESS_DUCK:
      return {
        ...INITIAL_STATE(),
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
