import React from 'react';
import PropTypes from 'prop-types';
import langValues from 'Config/i18n/index';

import { Pie } from 'react-chartjs-2';
import './KpiGraph.scss';

const KpiGraph = ({ data, labels, percent }) => {
  const values = [data.total - data.sumLate, data.sumLate];
  const colors = ['#949EAD', '#142544'];
  const graphData = {
    labels,
    datasets: [{
      data: values,
      backgroundColor: colors,
    }],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: true,
      position: 'right',
      align: 'center',
      onClick: e => e.stopPropagation(),
      labels: {
        fontColor: '#758CA5',
        fontFamily: 'Helvetica',
        fontStyle: '600',
        lineHeight: '19px',
        fontSize: 16,
        boxWidth: 16,
        padding: 48,
      },
    },
    tooltips: {
      callbacks: {
        label(tooltipItem, dataSet) {
          const label = Math.round(
            dataSet.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] * 100,
          ) / 100;
          return label;
        },
      },
    },
    cutoutPercentage: 80,
  };

  return (
    <div className="dashboard-kpi-graph">
      <Pie data={graphData} options={options} />
      <div className="donut-inner">
        <h5>{percent}%</h5>
        <span>{langValues.listPedidosLabels.pedido.toLowerCase()}</span>
        <span>{langValues.listPedidosLabels.emDia.toLowerCase()}</span>
      </div>
    </div>
  );
};

KpiGraph.propTypes = {
  data: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
  percent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
};

export default KpiGraph;
