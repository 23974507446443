import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import langValues from 'Config/i18n/index';
import HelpIconTooltip from 'Components/Shared/HelpIconTooltip/HelpIconTooltip';
import { separateDateWithDash, formatDateWithoutYear } from 'Utils/dateUtils';
import ResultResponse from 'Components/Atoms/ResultResponse';
import CustomTooltip from 'Components/Atoms/Tooltip/CustomTooltip';
import { ReactComponent as EmptyState } from 'Common/Assets/empty_state.svg';
import './DashboardAccessReport.scss';

const DashboardAccessReport = ({ loadAccessReport, accessReport, error, loading }) => {
  const toDate = new Date();
  const fromDate = new Date(toDate.getTime() - 7 * 24 * 60 * 60 * 1000);

  useEffect(() => {
    const toDateFormated = separateDateWithDash(toDate);
    const fromDateFormated = separateDateWithDash(fromDate);
    loadAccessReport(fromDateFormated, toDateFormated);
  }, []);

  const renderDashboardAccessReportTitle = () => {
    const toDateFormated = <strong>{formatDateWithoutYear(toDate)}</strong>;
    const fromDateFormated = <strong>{formatDateWithoutYear(fromDate)}</strong>;

    return (
      <div className="dashboardAccessHeader">
        <div className="dashboardAccessTitle">
          <h5 className="dashboardAccessTitleText">{langValues.labelsDashboardAccessDealer.title}</h5>
          <HelpIconTooltip tooltipText={langValues.labelsDashboardAccessDealer.textHelpIcon} />
        </div>
        <div className="dashboardAccessDateParm font-small2">
          <p>
            Entre {fromDateFormated} e {toDateFormated}
          </p>
        </div>
      </div>
    );
  };

  const renderDashboardAccessReportHeader = () => (
    <div className="dashboardAccessReportHeader">
      <span>REGIONAL</span>
      <span>ACESSOS</span>
    </div>
  );

  const renderAccessReportToolTip = (regionalName, percentage) => (
    <div className="dashboardAccessReportToolTip">
      <p className="regional">Regional: {regionalName}</p>
      <p className="access">Acessos: {percentage}</p>
    </div>
  );

  const renderDashboardAccessReportValue = (item) => {
    const percentage = `${(item.accessPercentage * 100).toFixed(2)}%`;
    return (
      <div key={item.regionalId} className="dashboardAccessReportLine">
        <span>{item.regionalId}</span>
        <div className="dashboardAccessReportPercentage">
          <CustomTooltip
            label={renderAccessReportToolTip(item.regionalName, percentage)}
            placement="bottom-end"
            arrow={false}
          >
            <div className="percentageBar bar-background">
              <div style={{ width: percentage }} className="percentageBar" />
            </div>
          </CustomTooltip>
        </div>
      </div>
    );
  };

  const renderSkeleton = () => {
    const skeleton = [];
    for (let i = 0; i < 8; i += 1) {
      skeleton.push(
        <div key={i} className="skeletonItem">
          <div className="dashboardAccessReportPercentage" />
        </div>
      );
    }
    return skeleton;
  };

  const renderError = () => (
    <div className="renderError">
      <ResultResponse Icon={EmptyState} title={langValues.labelsDashboardAccessDealer.errorTitle} />
    </div>
  );

  const renderDashboardAccessReportValues = () => {
    if (loading) {
      return renderSkeleton();
    }

    if (!accessReport || error) {
      return renderError();
    }

    const sortedAccessReport = accessReport.sort((a, b) => (a.accessPercentage < b.accessPercentage ? 1 : -1));

    return <div>{sortedAccessReport.map((item) => renderDashboardAccessReportValue(item))}</div>;
  };

  return (
    <div className="backgroundDashboardAccess">
      {renderDashboardAccessReportTitle()}
      <div className="dashboardAccessReportTable">
        {renderDashboardAccessReportHeader()}
        {renderDashboardAccessReportValues()}
      </div>
    </div>
  );
};

DashboardAccessReport.propTypes = {
  loadAccessReport: PropTypes.func.isRequired,
  accessReport: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  loading: PropTypes.object.isRequired,
};

export default DashboardAccessReport;
