import RequestRepository from '../Repositories/RequestRepository';

class ReleaseNotesService {
  getReleaseNotes = async () => {
    try {
      const url = 'releaseNotes';
      const { data } = await RequestRepository.get(url);
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
}

export default ReleaseNotesService;
