export const selectorStyle = {
  indicatorSeparator: () => null,
  container: styles => ({
    ...styles,
    width: '100%',
    height: '32px',
    cursor: 'pointer',
  }),
  control: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '32px',
    border: '1px solid #758CA580',
    borderColor: 'none',
    borderRadius: '5px',
    boxShadow: 'none',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: '#758CA5',
    marginRight: '12px',
    padding: 0,
    transform: 'scale(0.8)',
  }),
  menu: styles => ({
    ...styles,
    marginTop: '4px',
  }),
  singleValue: styles => ({
    ...styles,
    color: '#5E5E5E',
  }),
  option: styles => ({
    ...styles,
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    color: '#5E5E5E',
    fontFamily: 'Helvetica Neue',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 'normal',
    backgroundColor: 'white',

    ':hover': {
      backgroundColor: '#DEEBFF',
    },
  }),
};

export default null;
