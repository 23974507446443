export function safeToLowerCase(string) {
  if (typeof string === 'string' && string.length > 0) {
    return string.toLowerCase();
  }
  return string;
}

export function safeToUpperCase(string) {
  if (typeof string === 'string' && string.length > 0) {
    return string.toUpperCase();
  }
  return string;
}

export function safeDealerStateToUpperCase(string) {
  if (typeof string === 'string' && string.length > 0 && string.length === 2) {
    return string.toUpperCase();
  }
  return string;
}

export function safeNumberFormatter(string) {
  if (typeof string === 'string' && string.length > 0) {
    return string.replace(/\D/g, '');
  }
  return string;
}

export function safeString2Int(string) {
  if (typeof string === 'string' && string.length > 0) {
    return parseInt(string, 10);
  }
  return string;
}

export function safeToCapitalizeAllFirstLetters(string) {
  if (typeof string === 'string' && string.length > 0) {
    const textLowerCase = string.toLowerCase();
    const pattern = /(?:^[a-zA-Z\u00C0-\u017F]|\s[a-zA-Z\u00C0-\u017F])/g;
    return textLowerCase.replace(pattern, value => value.toUpperCase());
  }
  return string;
}

export function safeNormalizeString(string) {
  if (typeof string === 'string' && string.length > 0) {
    const textLowerCase = string.toLowerCase();
    return textLowerCase.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
  return string;
}
