import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PageHeader from 'Components/Shared/PageHeader/index';
import SystemUpdate from 'Components/Atoms/SystemUpdate';
import DelayManagementList from 'Components/Organisms/DelayManagementList';
import DelayManagementDetails from 'Components/Organisms/DelayManagementDetails';
import SnackBar from 'Components/Shared/SnackBar/index';
import langValues from 'Config/i18n/index';
import { trackEvent } from 'Utils/analytics';
import { trackedEvents, trackedPages } from 'Utils/consts';

import './ManagementPage.scss';

const tabsList = [
  { id: 0, label: langValues.all, value: null },
  { id: 1, label: langValues.labelsRequestResume.late, value: 'late' },
  { id: 2, label: langValues.labelsRequestResume.risk, value: 'inRisk' },
];

const ManagementPage = ({
  loadList,
  filtersDuck,
  error = false,
  setOrderSorting,
  loadingExport = false,
  exportLateOrdersList,
}) => {
  const { appliedDelayFilters, appliedDelayDates, stepStatus, sorting, searchDelayTrackingString } = filtersDuck;
  const initialSortingValue = null;
  const initialSearchInputValue = null;
  const allAppliedFilters = {
    ...appliedDelayFilters,
    ...appliedDelayDates,
    sorting: initialSortingValue,
    filter: initialSearchInputValue,
  };

  useEffect(() => {
    loadList(0, stepStatus, allAppliedFilters);
    setOrderSorting();
    trackEvent(trackedEvents.pageView, { page: trackedPages.delayManagement });
  }, []);

  const renderError = () => {
    if (error) return <SnackBar message={langValues.renderError} />;
    return null;
  };

  const handleDownloadReport = (fileType) => {
    const stepStatusFilterSelected = tabsList.filter((tab) => tab.value === stepStatus);
    const stepStatusFilterValue = stepStatusFilterSelected.length > 0 ? stepStatusFilterSelected[0].value : null;
    const filters = {
      appliedFilters: {
        ...appliedDelayFilters,
        status: appliedDelayFilters.statusDelay,
        steps: appliedDelayFilters.stepsDelay,
      },
      appliedDates: {
        ...appliedDelayDates,
      },
      stepStatus: stepStatusFilterValue,
      sorting,
    };
    trackEvent(trackedEvents.dataExport, { format: fileType });
    exportLateOrdersList(searchDelayTrackingString, filters, fileType);
  };

  return (
    <article className="managementPage">
      <PageHeader
        title={<div className="settingsTitle">{langValues.managementPage.title}</div>}
        subtitle={<SystemUpdate />}
        shouldRenderExportButton
        loadingExport={loadingExport}
        handleDownloadReport={handleDownloadReport}
      />
      <section className="managementPage-content">
        <DelayManagementList tabsList={tabsList} />
        <DelayManagementDetails selectedOrder="inRisk" />
      </section>
      {renderError()}
    </article>
  );
};

ManagementPage.propTypes = {
  loadList: PropTypes.func.isRequired,
  setOrderSorting: PropTypes.func.isRequired,
  filtersDuck: PropTypes.object.isRequired,
  error: PropTypes.bool,
  loadingExport: PropTypes.bool,
  exportLateOrdersList: PropTypes.func.isRequired,
};

export default ManagementPage;
