import React from 'react';
import langValues from 'Config/i18n/index';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowUpIcon } from 'Common/Assets/arrow_up.svg';
import CircularProgress from '@mui/material/CircularProgress';
import SnackbarTransactions from 'Components/Atoms/SnackbarTransactions/SnackbarTransactions';
import './DownloadButton.scss';

const DownloadButton = ({
  download = () => {},
  loading,
  error,
  resetError,
  titleSnackbarError = langValues.downloadButton.titleSnackbarErrorDownload,
}) => {
  const renderSnackbarError = () => (
    <SnackbarTransactions
      type="failure"
      isOpen={error}
      message={titleSnackbarError}
      onClose={() => resetError()}
      shouldRenderOnCloseButton
    />
  );

  const handleDownloadtButtonClick = () => {
    if (error) {
      return renderSnackbarError();
    }
    return download();
  };

  const renderDownloadButton = () => (
    <button type="button" onClick={() => handleDownloadtButtonClick()} className="downloadButton">
      {loading ? (
        <CircularProgress size={14} />
      ) : (
        <>
          <ArrowUpIcon className="downloadButton-arrow" />
          <span className="downloadButton-title">{langValues.download}</span>
        </>
      )}
    </button>
  );

  return (
    <>
      {renderDownloadButton()}
      {renderSnackbarError()}
    </>
  );
};

DownloadButton.propTypes = {
  download: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  resetError: PropTypes.func,
  titleSnackbarError: PropTypes.string,
};

export default DownloadButton;
