import React from 'react';
import PropTypes from 'prop-types';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';
import './RedirectEntry.scss';

const RedirectEntry = ({ url, path }) => {
  const finalUrl = url.concat(path);
  return (
    <div className="redirect-box" id={url}>
      <h3 className="redirect-titulo">Mudamos de endereço</h3>
      <h6 className="redirect-subTitulo">Agora somos Stellantis. Acesse a nova URL do Easy Tracking:</h6>
      <a href={finalUrl} className="redirect-link">{url}</a>
      <MaterialButton
        onClick={() => { window.location.href = finalUrl; }}
        buttonText="Acessar"
        variant="contained"
        type="primary"
      />
    </div>
  );
};

RedirectEntry.propTypes = {
  url: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default RedirectEntry;
