import { connect } from 'react-redux';
import {
  loadAccessReport,
} from 'Infrastructure/Ducks/DashboardAccessReportDuck';
import DashboardAccessReport from './DashboardAccessReport';

const mapStateToProps = (state) => {
  const { accessReport, error, loading } = state.dashboardAccessDuck;
  return { accessReport, error, loading };
};

const mapDispatchToProps = dispatch => ({
  loadAccessReport: (fromDate, toDate) => {
    dispatch(loadAccessReport(fromDate, toDate));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardAccessReport);
