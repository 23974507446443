import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InvoiceIcon from 'Components/Atoms/InvoiceIcon';
import { Button } from '@mui/material';
import { trackEvent } from 'Utils/analytics';
import { trackedButtons, trackedEvents } from 'Utils/consts';
import InvoiceDownloadModal from 'Components/ComercialDetailsScreenComponents/InvoiceDownloadModal';
import langValues from 'Config/i18n/index';
import './InvoiceDownload.scss';

const InvoiceDownload = ({ modalData }) => {
  const [isInvoiceModalOpened, setInvoiceModalOpened] = useState(false);

  const onClickDownloadInvoice = () => {
    setInvoiceModalOpened(true);
    trackEvent(trackedEvents.buttonClick, { button: trackedButtons.openInvoiceModal });
  };

  return (
    <>
      <section className="pedidoIndustrial-downloadInvoiceDiv">
        <InvoiceIcon className="pedidoIndustrial-downloadInvoiceIcon" />
        <span className="pedidoIndustrial-downloadInvoiceDiv-text">{langValues.invoiceLabels.readyToDownload}</span>
        <Button disableFocusRipple className="pedidoIndustrial-downloadInvoiceDiv-button" onClick={onClickDownloadInvoice}>
          {langValues.invoiceLabels.downloadNfe}
        </Button>
      </section>

      <InvoiceDownloadModal data={modalData} open={isInvoiceModalOpened} onClose={() => setInvoiceModalOpened(false)} />
    </>
  );
};

InvoiceDownload.propTypes = {
  modalData: PropTypes.object.isRequired,
};

export default InvoiceDownload;
