/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import StepStatusCircle from 'Components/Atoms/StepStatusCircle/StepStatusCircle';
import StepDate from 'Components/Atoms/StepDate';
import { User } from 'Common/Helpers/AuthHelper';
import { stepStatusId, timeLine, roleConsts, deviceInfo } from 'Utils/consts';
import ChassiInformationIcon from 'Components/Atoms/ChassiInformationIcon/ChassiInformationIcon';
import TagOrderStatus from 'Components/Atoms/TagOrderStatus/index';
import './TimelineItemMobile.scss';

const STEP_CIRCLE_SIZE = 40;
const SUB_STEP_CIRCLE_SIZE = 24;

const TimelineItemMobile = ({
  selectedIndustrialOrder,
  step,
  isCurrentStep = false,
  isSingleStep,
  deviceType = deviceInfo.typeDesktop,
  rules = null,
}) => {
  let stepStatus = step.status;
  if (stepStatus === stepStatusId.onTime && !isCurrentStep) {
    stepStatus = stepStatusId.notStarted;
  }
  const renderStepLine = () => (
    <>
      <div className="step-line">
        <span className="line">
          <svg width="fit-content" height="fit-content">
            <line className={`timeline-line ${stepStatus}`} x1="20" y1="0" x2="20" y2="100" strokeWidth="3" />
          </svg>
        </span>
      </div>
    </>
  );

  const renderChassiInformationIcon = () => {
    if (!selectedIndustrialOrder.industrialOrder.chassi) return null;

    return (
      <div className="chassiInfoIcon">
        <ChassiInformationIcon chassi={selectedIndustrialOrder.industrialOrder.chassi} />
      </div>
    );
  };

  const renderStepItem = () => {
    const stepIds = step.stepId.id;
    const stepDescription = step.stepId.description;

    const isDealer = User && User.get() && User.hasProfile(roleConsts.dealers);
    const isEmployee = User && User.get() && User.hasProfile(roleConsts.employees);

    const isVinStep =
      step &&
      step.stepId &&
      ((stepIds === timeLine.stepNames.produzido && (isDealer || isEmployee)) ||
        (stepIds === timeLine.stepNames.montagem && !isDealer));

    let statusTag = null;
    if ((stepStatus === stepStatusId.late || stepStatus === stepStatusId.inRisk) && !isEmployee) {
      statusTag = <TagOrderStatus lateDays={step.lateDays} statusId={stepStatus} />;
    }

    return (
      <>
        <div className="step-status-date">
          {statusTag}
          <StepDate step={step} status={stepStatus} deviceType={deviceType} rules={rules} />
        </div>
        <div className="step-circle">
          <StepStatusCircle
            key={stepIds}
            step={step}
            isCurrentStep={isCurrentStep}
            size={step.isSubStep ? SUB_STEP_CIRCLE_SIZE : STEP_CIRCLE_SIZE}
          />
          <div className="step-circle-background" />
          {!isSingleStep ? renderStepLine() : null}
        </div>
        <div className="step-name">
          <div className={`font-small1 ${stepStatus}`}>{stepDescription}</div>
          {isVinStep ? renderChassiInformationIcon() : null}
        </div>
      </>
    );
  };
  return renderStepItem();
};

TimelineItemMobile.propTypes = {
  selectedIndustrialOrder: PropTypes.object.isRequired,
  step: PropTypes.object.isRequired,
  isSingleStep: PropTypes.bool,
  isCurrentStep: PropTypes.bool,
  deviceType: PropTypes.string,
  rules: PropTypes.object,
};

export default TimelineItemMobile;
