/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Arrow } from 'Common/Assets/arrow.svg';
import './InputNumber.scss';

const InputNumber = ({
  value, onChange, isEdited,
}) => {
  const [isFocused, setFocus] = React.useState(false);

  const onValueChange = (targetValue) => {
    if (isNaN(targetValue)) {
      onChange(0);
    } else {
      if (targetValue < 0) return;
      onChange(Number(targetValue));
    }
  };

  const getClassName = () => {
    if (isFocused) return 'focused';
    if (isEdited) return 'edited';
    return '';
  };

  return (
    <div className={`inputNumber-wrapper ${getClassName()}`}>
      <input
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        type="tel"
        value={value}
        onChange={e => onValueChange(e.target.value)}
      />
      <div className="inputNumber-arrows">
        <Arrow className="arrow arrow-up" onClick={() => onValueChange(value + 1)} />
        <Arrow className="arrow arrow-down" onClick={() => onValueChange(value - 1)} />
      </div>
    </div>
  );
};

InputNumber.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  isEdited: PropTypes.bool.isRequired,
};

export default InputNumber;
