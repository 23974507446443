import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Arrow } from 'Common/Assets/arrow.svg';
import './SortingIcon.scss';

const handleClick = (state, onClick, item) => {
  if (state) {
    onClick(null);
  } else {
    onClick(item);
  }
};

const SortingIcon = (props) => {
  const { isSortingIconClicked, sortingOnClick, sortingName = 'selected' } = props;
  return (
    <Arrow
      className={`sortingArrowIcon ${isSortingIconClicked}`}
      onClick={() => handleClick(isSortingIconClicked, sortingOnClick, sortingName)}
    />
  );
};

SortingIcon.propTypes = {
  isSortingIconClicked: PropTypes.bool.isRequired,
  sortingName: PropTypes.string,
  sortingOnClick: PropTypes.func.isRequired,
};

export default SortingIcon;
