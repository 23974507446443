import { connect } from 'react-redux';
import { User } from 'Common/Helpers/AuthHelper';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';

import {
  loadList, exportList, clearList, setExportError, setPageNumber, loadExportCountListPDA,
} from 'Infrastructure/Ducks/ListPedidos';
import { loadModels } from 'Infrastructure/Ducks/ModelVersion';
import {
  clearFilters,
  setIsBilledFilter,
  setIsToolTipOpen,
  setIsPlanAhorro,
} from 'Infrastructure/Ducks/FiltersDuck';
import Pedidos from './Pedidos';

const mapStateToProps = (state) => {
  const {
    list, dashboard, modelVersion, filtersDuck,
  } = state;
  const IS_ADMIN = User.hasProfile('ROLE_ADMIN');
  const PDA_USER_AR = User.hasProfile('ROLE_PDA') && ENV_ENVIRONMENT === 'AR';

  return {
    list, dashboard, models: modelVersion.models, filtersDuck, isPlanAhorro: filtersDuck.isPlanAhorro && (IS_ADMIN || PDA_USER_AR),
  };
};

const mapDispatchToProps = dispatch => ({
  loadList: (
    page,
    searchOrdersString,
    selected,
    filterDates,
    isBilledFilter,
    isPlanAhorro,
    searchStringAhorro,
    ahorroFilters,
    getNewPdaEndpoint,
  ) => {
    dispatch(
      loadList(
        page,
        searchOrdersString,
        selected,
        filterDates,
        isBilledFilter,
        isPlanAhorro,
        searchStringAhorro,
        ahorroFilters,
        getNewPdaEndpoint,
      ),
    );
  },
  exportList: (searchOrdersString, filters, fileType, isPlanAhorro, filtersExportPDA) => {
    dispatch(exportList(searchOrdersString, filters, fileType, isPlanAhorro, filtersExportPDA));
  },
  loadExportCountListPDA: filtersExportPDA => dispatch(loadExportCountListPDA(filtersExportPDA)),
  clearList: () => {
    dispatch(clearList());
  },
  loadModels: () => {
    dispatch(loadModels());
  },
  changeActiveTab: (tabId) => {
    if (tabId === 3) {
      dispatch(setIsPlanAhorro(true));
      dispatch(setPageNumber(0));
    } else {
      dispatch(setIsBilledFilter(tabId));
    }
  },
  clearFilters: () => {
    dispatch(clearFilters());
  },
  setExportErrorFalse: () => {
    dispatch(setExportError(false));
  },
  setIsToolTipOpen: () => {
    dispatch(setIsToolTipOpen(null));
  },
  setPageNumber: (page) => {
    dispatch(setPageNumber(page));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Pedidos);
