import { connect } from 'react-redux';

import OrderInformationCard from './OrderInformationCard';

const mapStateToProps = (state) => {
  const { orderDetail } = state;
  return { order: orderDetail.order, loading: orderDetail.loading };
};

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderInformationCard);
