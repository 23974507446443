import React from 'react';
import PropTypes from 'prop-types';
import FiatLogo from 'Common/Assets/fiatLogo.svg';
import ChryslerLogo from 'Common/Assets/chryslerLogo.svg';
import DodgeLogo from 'Common/Assets/dodgeLogo.svg';
import JeepLogo from 'Common/Assets/jeeplogo.svg';
import RamLogo from 'Common/Assets/ramLogo.svg';
import AbarthLogo from 'Common/Assets/abarthLogo.svg';
import LanciaLogo from 'Common/Assets/lanciaLogo.svg';
import AlfaRomeoLogo from 'Common/Assets/alfaRomeoLogo.svg';
import MaseratiLogo from 'Common/Assets/maseratiLogo.svg';
import peugeotLogo from 'Common/Assets/peugeotLogo.png';
import citroenLogo from 'Common/Assets/citroenLogo.png';
import dsLogo from 'Common/Assets/dsLogo.png';
import './BrandLogo.scss';

const BrandLogo = ({ brandId }) => {
  let logo = null;
  let alt = null;
  let likeSquare = '';
  switch (brandId) {
    case 0: {
      logo = FiatLogo;
      alt = 'Fiat';
      break;
    }
    case 55: {
      logo = ChryslerLogo;
      alt = 'Chrysler';
      break;
    }
    case 56: {
      logo = DodgeLogo;
      alt = 'Dodge';
      break;
    }
    case 57: {
      logo = JeepLogo;
      alt = 'Jeep';
      break;
    }
    case 58: {
      logo = RamLogo;
      alt = 'Ram';
      break;
    }
    case 66: {
      logo = AbarthLogo;
      alt = 'Abarth';
      likeSquare = 'like-square';
      break;
    }
    case 70: {
      logo = LanciaLogo;
      alt = 'Lancia';
      likeSquare = 'like-square';
      break;
    }
    case 83: {
      logo = AlfaRomeoLogo;
      alt = 'Alfa Romeo';
      likeSquare = 'like-square';
      break;
    }
    case 98: {
      logo = MaseratiLogo;
      alt = 'Maserati';
      likeSquare = 'like-square';
      break;
    }
    case 59: {
      logo = citroenLogo;
      alt = 'Citroen';
      likeSquare = 'like-square';
      break;
    }
    case 60: {
      logo = peugeotLogo;
      alt = 'Peugeot';
      likeSquare = 'like-square';
      break;
    }
    case 61: {
      logo = dsLogo;
      alt = 'DS';
      likeSquare = 'like-square';
      break;
    }

    default:
      return null;
  }

  return <img src={logo} alt={alt} className={`brand-logo ${likeSquare}`} loading="lazy" />;
};

BrandLogo.propTypes = {
  brandId: PropTypes.number,
};

export default BrandLogo;
