
import { connect } from 'react-redux';

import { loadLogout } from 'Infrastructure/Ducks/Auth';
import ErrorBoundary from './ErrorBoundary';

const mapDispatchToProps = dispatch => ({
  loadLogout: () => {
    dispatch(loadLogout());
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(ErrorBoundary);
