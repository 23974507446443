import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { safeToUpperCase } from 'Utils/safeMethods';
import { roleConsts, stepStatusId } from 'Utils/consts';
import langValues from 'Config/i18n/index';

import './StepStatus.scss';
import { User } from 'Common/Helpers/AuthHelper';

const statusDays = {
  inRisk: 'dias restantes',
  late: 'dias',
};

const StepStatus = ({ step, emptyStatus }) => {
  let isEmpty = true;

  useEffect(() => {
    if (emptyStatus) {
      emptyStatus(isEmpty);
    }
  }, []);

  const renderText = (text, font) => <div className={`${font} ${step.status}`}>{text}</div>;

  const renderStepStatus = () => {
    if (
      step &&
      step.status &&
      (step.status === stepStatusId.late || step.status === stepStatusId.inRisk) &&
      !User.hasProfile(roleConsts.employees)
    ) {
      const fontStatus = 'font-small3';
      const fontDays = 'font-small1';

      const statusText = safeToUpperCase(langValues.listPedidosLabels[step.status]);
      const statusDaysText = `${step.lateDays} ${statusDays[step.status]}`;
      isEmpty = false;
      return (
        <>
          {renderText(statusText, fontStatus)}
          {renderText(statusDaysText, fontDays)}
        </>
      );
    }
    isEmpty = true;
    return null;
  };

  return <div className="stepStatus">{renderStepStatus()}</div>;
};

StepStatus.propTypes = {
  step: PropTypes.object,
  emptyStatus: PropTypes.func,
};

export default StepStatus;
