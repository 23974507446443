import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from 'tss-react/mui';

import { safeNumberFormatter } from 'Utils/safeMethods';

import './Input.scss';

const styles = () => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  input: {
    color: '#5E5E5E',
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'inherit',
    width: '100%',
  },
  disabled: {
    fontWeight: '500',
  },
  changed: {
    color: 'blue',
  },
});

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edited: false,
      width: '30px',
      initialState: props.value,
    };
  }

  componentDidUpdate = (previousProps) => {
    if (previousProps !== this.props) {
      const { editing, value } = this.props;
      if (!editing) {
        this.setState({ edited: false });
        this.setState({ initialState: value });
      }
    }
  };

  handleChange = (event) => {
    const { onChange = () => {}, itemKey = null, type = 'text' } = this.props;
    const value = type === 'numeric' ? safeNumberFormatter(event.target.value) : event.target.value;
    this.setState({ edited: true });
    onChange(itemKey, value);
  };

  render() {
    const {
      value,
      editingItem,
      inputProps = {},
      fixedWidth,
      textAlign = 'textAlign: left',
      disabled,
      placeholder = '',
      style = {},
      newModelItem,
    } = this.props;
    const { edited, initialState } = this.state;
    let { width } = this.state;
    let color = '#5E5E5E';
    let fontWeight = '500';
    if ((edited && value !== initialState) || newModelItem) {
      color = '#657DD9';
      fontWeight = 'bold';
    }
    if (editingItem) {
      fontWeight = 'bold';
    }
    if (fixedWidth) {
      width = fixedWidth;
    }
    let fieldDisabled = false;
    if (disabled || !editingItem) {
      fieldDisabled = true;
    }
    return (
      <input
        className="input-parameter"
        type="text"
        value={value}
        disabled={fieldDisabled}
        style={{
          width,
          color,
          fontWeight,
          textAlign,
          ...style,
        }}
        onChange={this.handleChange}
        placeholder={placeholder}
        {...inputProps}
      />
    );
  }
}

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  editingItem: PropTypes.bool,
  editing: PropTypes.bool,
  itemKey: PropTypes.string,
  inputProps: PropTypes.object,
  fixedWidth: PropTypes.string,
  textAlign: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  newModelItem: PropTypes.bool,
};

export default withStyles(Input, styles);
