import React from 'react';
import PropTypes from 'prop-types';
import './SubMenu.scss';

const SubMenu = ({ currentActiveOption, changeActiveOption, optionList, title }) => {
  const getIcon = (icon) => {
    if (icon) {
      return <div className="subMenu-icon">{icon}</div>;
    }
    return null;
  };

  const renderOption = (option) => {
    const { id, label, icon, isDisabled } = option;

    const isSelected = id === currentActiveOption;
    const optionClass = `subMenu-singleOption${isSelected ? ' selected' : ''}${isDisabled ? ' disabled' : ''}`;

    return (
      <button
        type="button"
        className={optionClass}
        key={`button${label}${id}`}
        onClick={() => {
          if (!isDisabled) {
            changeActiveOption(id);
          }
        }}
        disabled={isDisabled} // Ensure the button is disabled if isDisabled is true
      >
        {getIcon(icon)}
        <p>{label}</p>
      </button>
    );
  };

  return (
    <section className="subMenu">
      <div className="subMenu-title">
        <div className="subMenu-titleContent">
          <p className="subMenu-titleText">{title.label}</p>
        </div>
      </div>
      <div className="subMenu-options">{optionList.map((option) => renderOption(option))}</div>
    </section>
  );
};

SubMenu.propTypes = {
  currentActiveOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  changeActiveOption: PropTypes.func.isRequired,
  optionList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.element,
      isDisabled: PropTypes.bool,
    })
  ).isRequired,
  title: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default SubMenu;
