import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import langValues from 'Config/i18n/index';
import { emptyDate } from 'Utils/consts';
import { getDateAsText } from 'Utils/dateUtils';

import './SystemUpdate.scss';

const SystemUpdate = ({ lastSystemUpdate, loadLastSystemUpdate }) => {
  useEffect(() => {
    loadLastSystemUpdate();
  }, []);

  let updateDateText = emptyDate;
  if (lastSystemUpdate) {
    updateDateText = getDateAsText(new Date(lastSystemUpdate));
  }

  return (
    <span className="systemUpdateDate">
      {langValues.lastUpdate}
      {updateDateText}
    </span>
  );
};

SystemUpdate.propTypes = {
  loadLastSystemUpdate: PropTypes.func.isRequired,
  lastSystemUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SystemUpdate;
