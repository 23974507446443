import { connect } from 'react-redux';
import {
  loadModelsVersions,
  saveModel,
  saveVersion,
  deleteModel,
  deleteVersion,
  clearEditModelsVersions,
} from 'Infrastructure/Ducks/ParametersDuck';
import ParametersModelsVersions from './ParametersModelsVersions';

const mapStateToProps = (state) => {
  const { parametersDuck } = state;
  const { modelsVersions, modelsVersionsEdit } = parametersDuck;
  return { modelsVersions, modelsVersionsEdit };
};

const mapDispatchToProps = (dispatch) => ({
  loadModelsVersions: () => {
    dispatch(loadModelsVersions());
  },
  modelVersionEditFunctions: {
    saveModel: (model) => {
      dispatch(saveModel(model));
    },
    saveVersion: (version) => {
      dispatch(saveVersion(version));
    },
    deleteModel: (modelId) => {
      dispatch(deleteModel(modelId));
    },
    deleteVersion: (versionId, modelId) => {
      dispatch(deleteVersion(versionId, modelId));
    },
    clear: () => {
      dispatch(clearEditModelsVersions());
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ParametersModelsVersions);
