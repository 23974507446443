import React, { Component } from 'react';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

/* Context routes */
import { environment, roleConsts, trackedEvents } from 'Utils/consts';
import { trackEvent } from 'Utils/analytics';
import { GA_APPLICATION_CODE, TAG_MANAGER_APPLICATION_CODE } from 'Common/Helpers/GoogleAnalyticsHelper';
import initializeHotjar from 'Common/Helpers/HotjarHelper';

import VisionConfigurationSubMenu from 'Components/Organisms/DrawerSubMenu/VisionConfigurationSubMenu';

import PrivatePages from './PrivatePages';
import PublicPages from './PublicPages';
import DefaultRoute from './DefaultRoute';

/* Public routes */
import Login from './PublicPages/Login';

/* Private routes */
import Pedidos from './PrivatePages/Pedidos';
import Dashboard from './PrivatePages/Dashboard';
import ClientOrderDetail from './PrivatePages/ClientOrderDetail';
import EmployeeOrderDetail from './PrivatePages/EmployeeOrderDetail';
import TransitTime from './PrivatePages/TransitTime';
import Parameters from './PrivatePages/Parameters';
import VisionConfiguration from './PrivatePages/VisionConfiguration';
import FAQ from './PrivatePages/FAQ';
import OrderDetail from './PrivatePages/OrderDetail';
import ModelVersionForecast from './PrivatePages/ModelVersionForecast';
import ManagementPage from './PrivatePages/Management';
import OrderDetailPDA from './PrivatePages/OrderDetailPDA/index';

import { ReactComponent as PackageIcon } from 'Common/Assets/package.svg';
import { ReactComponent as DashboardIcon } from 'Common/Assets/dashboardIcon.svg';
import { ReactComponent as ManagementIcon } from 'Common/Assets/acompanhamentoIcon.svg';
import { ReactComponent as ModelVersionForecastIcon } from 'Common/Assets/menu-item-models-forecast.svg';
import { ReactComponent as VisionConfigurationIcon } from 'Common/Assets/VisionConfiguration.svg';

import './App.scss';
import '../Common/Assets/Fonts/stylesheet.scss';
import '../Utils/Styles/fonts.scss';

const ordersRoles = [roleConsts.customercare, roleConsts.dealers, roleConsts.admin];

class App extends Component {
  constructor(props) {
    super(props);
    const { setSubMenuOpen, setCommercialDrawerOpen } = this.props;
    let privateItems = [
      {
        component: Pedidos,
        id: 'pedidos',
        name: 'Pedidos',
        path: '/pedidos',
        icon: PackageIcon,
        permittedProfiles: ordersRoles,
        isMenu: true,
        isSetting: false,
        isEndMenu: false,
        menuOrder: 1,
      },
      {
        component: Dashboard,
        id: 'dashboard',
        name: 'Dashboard',
        path: '/dashboard',
        icon: DashboardIcon,
        permittedProfiles: [roleConsts.admin, roleConsts.customercare],
        isMenu: true,
        isSetting: false,
        isEndMenu: false,
        menuOrder: 3,
      },
      {
        component: ModelVersionForecast,
        id: 'modelVersionForecast',
        name: 'Prazos Médios',
        path: '/previsoesModelos',
        icon: ModelVersionForecastIcon,
        permittedProfiles: ordersRoles,
        isMenu: true,
        isSetting: false,
        isEndMenu: false,
        menuOrder: 4,
      },
      {
        component: TransitTime,
        id: 'transitTime',
        name: 'TransitTime',
        path: '/transittime',
        icon: null,
        permittedProfiles: [roleConsts.admin, roleConsts.customercare],
        isMenu: false,
        isSetting: true,
        isEndMenu: false,
        menuOrder: 5,
      },
      {
        component: Parameters,
        id: 'parametros',
        name: 'Parâmetros',
        path: '/parametros',
        icon: null,
        permittedProfiles: [roleConsts.admin, roleConsts.customercare],
        isMenu: false,
        isSetting: true,
        isEndMenu: false,
        menuOrder: 6,
      },
      {
        component: VisionConfiguration,
        id: 'visionConfiguration',
        name: 'Configurações Visões',
        path: '/visionConfiguration',
        icon: VisionConfigurationIcon,
        permittedProfiles: [roleConsts.admin],
        isMenu: false,
        isSetting: false,
        isEndMenu: true,
        method: () => setSubMenuOpen(true),
        subMenu: VisionConfigurationSubMenu,
        menuOrder: 7,
      },
      {
        component: ClientOrderDetail,
        id: 'clientOrders',
        name: 'Pedidos',
        path: '/clientes/pedido',
        icon: PackageIcon,
        permittedProfiles: [roleConsts.client],
        isMenu: true,
        isSetting: false,
        isEndMenu: false,
        method: () => setCommercialDrawerOpen(true),
        menuOrder: 8,
      },
    ];

    if (ENV_ENVIRONMENT === environment.BR) {
      privateItems.push({
        // @ts-ignore
        component: ManagementPage,
        id: 'acompanhamento',
        name: 'Acomp.',
        path: '/acompanhamento',
        icon: ManagementIcon,
        permittedProfiles: [roleConsts.admin],
        isMenu: true,
        isSetting: false,
        isEndMenu: false,
        menuOrder: 2,
      });
    }

    privateItems.sort((a, b) => a.menuOrder - b.menuOrder);

    this.state = {
      privateItems,
    };

    this.initializeGA();
    initializeHotjar();
  }

  initializeGA = () => {
    // @ts-ignore
    if (GA_APPLICATION_CODE && !window.ga) {
      ReactGA.initialize(GA_APPLICATION_CODE);
    }
    if (TAG_MANAGER_APPLICATION_CODE) {
      const tagManagerArgs = {
        gtmId: TAG_MANAGER_APPLICATION_CODE,
      };
      TagManager.initialize(tagManagerArgs);
    }
  };

  selectMenu = (menuId) => {
    const { privateItems } = this.state;
    const { currentScreen } = this.props;
    trackEvent(trackedEvents.menuClick, { menuItem: menuId });
    if (menuId === currentScreen) return;
    this.setState({
      privateItems: privateItems.map((item) => ({ ...item, selected: item.id === menuId })),
    });
  };

  privateItemMenu = () => {
    const { privateItems } = this.state;
    return privateItems.filter((item) => item.isMenu);
  };

  settingsOptions = () => {
    const { privateItems } = this.state;
    return privateItems.filter((item) => item.isSetting);
  };

  endMenuOptions = () => {
    const { privateItems } = this.state;
    return privateItems.filter((item) => item.isEndMenu);
  };

  render() {
    const { privateItems } = this.state;
    const { currentScreen } = this.props;
    const menu = this.privateItemMenu();
    const settings = this.settingsOptions();
    const endMenu = this.endMenuOptions();
    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route key="public-pages-login" path="/login" element={<PublicPages component={Login} />} />
            <Route
              key="public-pages-login-empregados"
              path="/login/empregados"
              element={<PublicPages component={Login} />}
            />
            <Route
              key="private-pages-pedidos"
              path="/"
              element={
                <PrivatePages
                  component={Pedidos}
                  menu={menu}
                  settings={settings}
                  endMenu={endMenu}
                  currentScreen={currentScreen}
                  idScreen="pedidos"
                  selectMenu={this.selectMenu}
                  permittedProfiles={ordersRoles}
                />
              }
            />
            <Route
              key="private-pages-detalhes"
              path="/detalhes/:orderId"
              element={
                <PrivatePages
                  component={OrderDetail}
                  menu={menu}
                  settings={settings}
                  endMenu={endMenu}
                  currentScreen={currentScreen}
                  idScreen="detalhes"
                  selectMenu={this.selectMenu}
                  permittedProfiles={ordersRoles}
                />
              }
            />
            <Route
              key="private-pages-detalhes-pda"
              path="/detalhes-pda/:contractCode/:brandCode/:orderId"
              element={
                <PrivatePages
                  component={OrderDetailPDA}
                  menu={menu}
                  settings={settings}
                  endMenu={endMenu}
                  currentScreen={currentScreen}
                  idScreen="detalhesPDA"
                  selectMenu={this.selectMenu}
                  permittedProfiles={ordersRoles}
                />
              }
            />
            <Route
              key="private-pages-empregados"
              path="/empregados/:employeeCPF"
              element={
                <PrivatePages
                  component={EmployeeOrderDetail}
                  menu={menu}
                  settings={settings}
                  endMenu={endMenu}
                  currentScreen={currentScreen}
                  idScreen="empregados"
                  selectMenu={this.selectMenu}
                  permittedProfiles={[roleConsts.employees]}
                />
              }
            />
            <Route
              key="private-pages-clientes"
              path="/clientes/pedido"
              element={
                <PrivatePages
                  component={ClientOrderDetail}
                  menu={menu}
                  settings={settings}
                  endMenu={endMenu}
                  currentScreen={currentScreen}
                  idScreen="clientes"
                  selectMenu={this.selectMenu}
                  permittedProfiles={[roleConsts.client]}
                />
              }
            />
            <Route
              key="private-pages-faq"
              path="/faq"
              element={
                <PrivatePages
                  component={FAQ}
                  menu={menu}
                  settings={settings}
                  endMenu={endMenu}
                  currentScreen={currentScreen}
                  idScreen="faq"
                  selectMenu={this.selectMenu}
                  permittedProfiles={[roleConsts.dealers]}
                />
              }
            />
            {privateItems.map((item) => (
              <Route
                key={`private-pages-${item.id}`}
                path={item.path}
                element={
                  <PrivatePages
                    component={item.component}
                    menu={menu}
                    settings={settings}
                    endMenu={endMenu}
                    idScreen={item.id}
                    selectMenu={this.selectMenu}
                    permittedProfiles={item.permittedProfiles}
                  />
                }
              />
            ))}
            <Route path="*" element={<DefaultRoute />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

App.propTypes = {
  currentScreen: PropTypes.string.isRequired,
  setSubMenuOpen: PropTypes.func.isRequired,
  setCommercialDrawerOpen: PropTypes.func.isRequired,
};

export default App;
