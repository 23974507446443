import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from 'Utils/analytics';
import errorImage from 'Common/Assets/errorPageImage.svg';
import { ReactComponent as EasyTrackingName } from 'Common/Assets/EasyTrackingName.svg';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';
import langValues from 'Config/i18n/index';

import './ErrorBoundary.scss';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidMount() {
    window.onpopstate = () => {
      this.setState({ hasError: false });
    };
  }

  static getDerivedStateFromError(error) {
    trackEvent('Erro', {
      mensagem: error ? error.message : langValues.noMessage,
    });
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children, loadLogout } = this.props;
    if (hasError) {
      return (
        <section className="errorBoundary-Page">
          <img src={errorImage} alt="" className="errorBoundary-image" />
          <div className="errorBoundary-content">
            <div className="errorBoundary-contentWrapper">
              <EasyTrackingName className="errorBoundary-easyLogo" />
              <h3 className="errorBoundary-title">{langValues.errorBoundaryTitle}</h3>
              <span className="font-body2 errorBoundary-text">{langValues.errorBoundaryText}</span>
              <div className="errorBoundary-buttons">
                <MaterialButton
                  onClick={() => {
                    this.setState({ hasError: false }, () => {
                      loadLogout();
                    });
                  }}
                  buttonText="Log-out"
                  variant="outlined"
                  type="secondary"
                />
                <MaterialButton
                  onClick={() => window.location.reload()}
                  buttonText="Recarregar página"
                  variant="contained"
                  type="primary"
                />
              </div>
            </div>
          </div>
        </section>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object.isRequired,
  loadLogout: PropTypes.func.isRequired,
};

export default ErrorBoundary;
