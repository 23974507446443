import { connect } from 'react-redux';
import { clearDuck } from 'Infrastructure/Ducks/OrderDetailDuck';
import Timeline from './Timeline';

const mapStateToProps = (state) => {
  const { orderDetail } = state;
  return { selectedIndustrialOrder: orderDetail.selectedIndustrialOrder, order: orderDetail.order };
};

const mapDispatchToProps = dispatch => ({
  clearDuck: () => {
    dispatch(clearDuck());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Timeline);
