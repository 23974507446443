/* eslint-disable class-methods-use-this */

import RequestRepository from './RequestRepository';

class ListPedidosIndustriaisRepository {
  get(pedidoComercial) {
    const parameters = `orders/${pedidoComercial}`;
    if (pedidoComercial === '000000004') {
      return RequestRepository.get('orders/010454554');
    }
    return RequestRepository.get(parameters);
  }

  getPda(params) {
    const { contractCode, brandCode } = params;
    const parameters = `orderPDA/${contractCode}/${brandCode}`;

    return RequestRepository.get(parameters);
  }
}

export default ListPedidosIndustriaisRepository;
