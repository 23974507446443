import DelayedOrdersService from '../Services/DelayedOrdersService';

export const DUCK_NAME = 'delayedOrdersDuck';

export const INITIAL_STATE = {
  loadingDelayedOrders: false,
  errorDelayedOrders: null,
  delayedOrders: null,
  loadingDelayedOrdersTotal: false,
  errorDelayedOrdersTotal: null,
  delayedOrdersTotal: 0,
};

export const LOAD_DELAYED_ORDERS_TOTAL_STARTED = `${DUCK_NAME}/LOAD_DELAYED_ORDERS_TOTAL_STARTED`;
export const LOAD_DELAYED_ORDERS_TOTAL_SUCCEED = `${DUCK_NAME}/LOAD_DELAYED_ORDERS_TOTAL_SUCCEED`;
export const LOAD_DELAYED_ORDERS_TOTAL_FAILED = `${DUCK_NAME}/LOAD_DELAYED_ORDERS_TOTAL_FAILED`;

export const LOAD_DELAYED_ORDERS_STARTED = `${DUCK_NAME}/LOAD_DELAYED_ORDERS_STARTED`;
export const LOAD_DELAYED_ORDERS_SUCCEED = `${DUCK_NAME}/LOAD_DELAYED_ORDERS_SUCCEED`;
export const LOAD_DELAYED_ORDERS_FAILED = `${DUCK_NAME}/LOAD_DELAYED_ORDERS_FAILED`;

export const loadDelayedOrdersTotalStarted = () => ({ type: LOAD_DELAYED_ORDERS_TOTAL_STARTED });
export const loadDelayedOrdersTotalSucceed = data => ({
  type: LOAD_DELAYED_ORDERS_TOTAL_SUCCEED, data,
});
export const loadDelayedOrdersTotalFailed = error => ({
  type: LOAD_DELAYED_ORDERS_TOTAL_FAILED, error,
});
export const loadDelayedOrdersStarted = () => ({ type: LOAD_DELAYED_ORDERS_STARTED });
export const loadDelayedOrdersSucceed = data => ({ type: LOAD_DELAYED_ORDERS_SUCCEED, data });
export const loadDelayedOrdersFailed = error => ({ type: LOAD_DELAYED_ORDERS_FAILED, error });

export const loadDelayedOrdersTotal = () => async (dispatch) => {
  dispatch(loadDelayedOrdersTotalStarted());
  let data = [];
  try {
    const delayedOrdersService = new DelayedOrdersService();
    data = await delayedOrdersService.getDelayedOrdersTotal();
    dispatch(loadDelayedOrdersTotalSucceed(data.totalDelayedOrder));
  } catch (err) {
    dispatch(loadDelayedOrdersTotalFailed(err));
  }
};

export const loadDelayedOrders = () => async (dispatch) => {
  dispatch(loadDelayedOrdersStarted());
  let data = [];
  try {
    const delayedOrdersService = new DelayedOrdersService();
    data = await delayedOrdersService.getDelayedOrders();
    dispatch(loadDelayedOrdersSucceed(data));
  } catch (err) {
    dispatch(loadDelayedOrdersFailed(err));
  }
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_DELAYED_ORDERS_TOTAL_STARTED:
      return {
        ...state,
        loadingDelayedOrdersTotal: true,
        errorDelayedOrdersTotal: null,
        delayedOrdersTotal: 0,
      };
    case LOAD_DELAYED_ORDERS_TOTAL_SUCCEED:
      return {
        ...state,
        loadingDelayedOrdersTotal: false,
        errorDelayedOrdersTotal: null,
        delayedOrdersTotal: action.data,
      };
    case LOAD_DELAYED_ORDERS_TOTAL_FAILED:
      return {
        ...state,
        loadingDelayedOrdersTotal: false,
        errorDelayedOrdersTotal: action.error,
        delayedOrdersTotal: 0,
      };
    case LOAD_DELAYED_ORDERS_STARTED:
      return {
        ...state,
        loadingDelayedOrders: true,
        errorDelayedOrders: null,
        delayedOrders: null,
      };
    case LOAD_DELAYED_ORDERS_SUCCEED:
      return {
        ...state,
        loadingDelayedOrders: false,
        errorDelayedOrders: null,
        delayedOrders: action.data,
      };
    case LOAD_DELAYED_ORDERS_FAILED:
      return {
        ...state,
        loadingDelayedOrders: false,
        errorDelayedOrders: action.error,
        delayedOrders: null,
      };
    default:
      return state;
  }
};

export default reducer;
