import React from 'react';
import PropTypes from 'prop-types';

import './Dot.scss';

function Dot({ color = 'black', size }) {
  return <div className="dot" style={{ backgroundColor: color, width: size, height: size }} />;
}

Dot.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string.isRequired,
};

export default Dot;
