import InvoiceService from 'Infrastructure/Services/InvoiceService';

const INITIAL_STATE = () => ({
  loading: false,
  success: false,
  error: null,
});

export const DUCK_NAME = 'invoiceDuck';

const DOWNLOAD_INVOICE_STARTED = `${DUCK_NAME}/DOWNLOAD_INVOICE_STARTED`;
const DOWNLOAD_INVOICE_SUCCEED = `${DUCK_NAME}/DOWNLOAD_INVOICE_SUCCEED`;
const DOWNLOAD_INVOICE_FAILED = `${DUCK_NAME}/DOWNLOAD_INVOICE_FAILED`;
const CLEAR_INVOICE_DUCK = `${DUCK_NAME}/CLEAR_INVOICE_DUCK`;

const downloadInvoiceStarted = () => ({ type: DOWNLOAD_INVOICE_STARTED });
const downloadInvoiceSucceed = () => ({ type: DOWNLOAD_INVOICE_SUCCEED });
const downloadInvoiceFailed = error => ({ type: DOWNLOAD_INVOICE_FAILED, error });
export const clearInvoiceDuck = () => ({ type: CLEAR_INVOICE_DUCK });

export const downloadInvoice = (industrialOrderId, orderId, vin) => async (dispatch) => {
  dispatch(downloadInvoiceStarted());
  const invoiceService = new InvoiceService();
  try {
    await invoiceService.downloadInvoice(industrialOrderId, orderId, vin);
    dispatch(downloadInvoiceSucceed());
  } catch (err) {
    dispatch(downloadInvoiceFailed(err));
  }
};

export const downloadInvoiceEmployee = orderId => async (dispatch) => {
  dispatch(downloadInvoiceStarted());
  const invoiceService = new InvoiceService();
  try {
    await invoiceService.downloadInvoiceEmployee(orderId);
    dispatch(downloadInvoiceSucceed());
  } catch (err) {
    dispatch(downloadInvoiceFailed(err));
  }
};

const reducer = (state = INITIAL_STATE(), action) => {
  switch (action.type) {
    case DOWNLOAD_INVOICE_STARTED:
      return {
        loading: true,
        success: false,
        error: null,
      };
    case DOWNLOAD_INVOICE_SUCCEED:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case DOWNLOAD_INVOICE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case CLEAR_INVOICE_DUCK:
      return {
        ...INITIAL_STATE(),
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
