import RequestRepository from 'Infrastructure/Repositories/RequestRepository';
import { changeStringToAPattern } from 'Utils/stringUtils';
import { getTimestampWithoutHours } from 'Utils/dateUtils';

const generateFiltersWithTimeStampDates = (filters) => {
  const startDate = filters.startDate ? getTimestampWithoutHours(filters.startDate) : null;
  const endDate = filters.endDate ? getTimestampWithoutHours(filters.endDate) : null;
  const newFilters = {
    ...filters,
    minDate: filters.isUsingDateFilter ? startDate : null,
    maxDate: filters.isUsingDateFilter ? endDate : null,
  };
  return newFilters;
};
class OrderManagementService {
  getList = async (page, stepStatus, filters) => {
    try {
      const urlPath = 'industrialorder/management';
      const newFilters = generateFiltersWithTimeStampDates(filters);
      const body = this.createRequestBody(stepStatus, newFilters, page);
      const { data } = await RequestRepository.put(urlPath, body);
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getTotal = async (stepStatus, filters) => {
    try {
      const urlPath = 'industrialorder/management/total';
      const newFilters = generateFiltersWithTimeStampDates(filters);
      const body = this.createRequestBody(stepStatus, newFilters);
      const { data } = await RequestRepository.put(urlPath, body);
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  createRequestBody = (stepStatus, filters, page) => ({
    page,
    size: 10,
    stepStatus,
    macroArea: filters.macroAreas,
    status: filters.statusDelay,
    steps: filters.stepsDelay,
    pendency: filters.pendencies,
    model: filters.models,
    brand: filters.brands,
    clientCategory: filters.categories,
    clientMacroCategory: filters.macroCategories,
    orderTypes: filters.orderType,
    minDate: filters.minDate,
    maxDate: filters.maxDate,
    sorting: filters.sorting,
    filter: changeStringToAPattern(filters.filter),
    dealerId: filters.dealerId ? filters.dealerId.split(';') : [],
  });
}

export default OrderManagementService;
