/* eslint-disable class-methods-use-this */
import RequestRepository from './RequestRepository';

class DelayedOrdersRepository {
  async getDelayedOrders() {
    const delayedOrders = 'order-dealer-delay';
    return RequestRepository.get(delayedOrders);
  }

  async getDelayedOrdersTotal() {
    const delayedOrdersTotal = 'order-dealer-delay/total';
    return RequestRepository.get(delayedOrdersTotal);
  }
}

export default DelayedOrdersRepository;
