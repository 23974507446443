import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import langValues from 'Config/i18n/index';
import { ReactComponent as ErrorIcon } from 'Common/Assets/alert-octagon.svg';
import { ReactComponent as SuccessIcon } from 'Common/Assets/CombinedShape.svg';
import MaterialButton from '../MaterialButton/MaterialButton';
import './SnackbarTransactions.scss';

const SnackbarTransactions = ({
  type = 'success',
  isOpen = false,
  message = null,
  onClose = () => {},
  shouldRenderOnCloseButton,
}) => {
  const getTypeIcon = () => {
    switch (type) {
      case 'success': {
        return <SuccessIcon />;
      }
      case 'failure': {
        return <ErrorIcon />;
      }
      default:
        return null;
    }
  };

  const getOnCloseButton = () => {
    if (shouldRenderOnCloseButton) {
      return (
        <div className="snackBarTransactions-button">
          <MaterialButton
            onClick={() => onClose()}
            buttonText={langValues.downloadButton.buttonTextError}
            variant="outlined"
          />
        </div>
      );
    }
    return null;
  };

  const getMessage = () => (
    <div className="snackBarTransactions-message">
      <span className="snackBarTransactions-icon">{getTypeIcon()}</span>
      <span>{message}</span>
      {getOnCloseButton()}
    </div>
  );
  return (
    <Snackbar open={isOpen} className="snackBarTransactions">
      <SnackbarContent message={getMessage()} className={`snackBarTransactions-modal ${type}`} />
    </Snackbar>
  );
};

SnackbarTransactions.propTypes = {
  message: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  shouldRenderOnCloseButton: PropTypes.bool,
  type: PropTypes.string,
};

export default SnackbarTransactions;
