import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ClientIndustrialOrderDetail from 'Components/ComercialDetailsScreenComponents/ClientIndustrialOrderDetail';
import PedidoComercial from 'Components/ComercialDetailsScreenComponents/PedidoComercial';
import ClientOrderMobile from 'Components/Organisms/ClientOrderMobile';

import { ReactComponent as CloseIcon } from 'Common/Assets/close-cross.svg';

import { deviceInfo, titleEasyTracking, trackedEvents, trackedPages } from 'Utils/consts';
import SnackBar from 'Components/Shared/SnackBar';
import { ReactComponent as NoIndustrialOrders } from 'Common/Assets/NoIndustrialOrders.svg';
import { ReactComponent as StellantisLogo } from 'Common/Assets/logo_stellantis.svg';
import { trackEvent } from 'Utils/analytics';
import Timeline from 'Components/Organisms/Timeline/index';
import InvoiceDownload from 'Components/Molecules/InvoiceDownload/Index';
import { Token } from 'Common/Helpers/AuthHelper';
import langValues from 'Config/i18n/index';
import BaseModal from 'Components/Atoms/BaseModal/BaseModal';
import { getDateAsNumbers } from 'Utils/dateUtils';
import './EmployeeOrderDetail.scss';

class EmployeeOrderDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceType: window.innerWidth > deviceInfo.desktopMinWidth ? deviceInfo.typeDesktop : deviceInfo.typeMobile,
    };
    trackEvent(trackedEvents.pageView, { page: trackedPages.funcionario });
    this.loadOrder();
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const { deviceType } = this.state;
    if (deviceType === deviceInfo.typeDesktop && window.innerWidth <= deviceInfo.desktopMinWidth) {
      this.setState({ deviceType: deviceInfo.typeMobile });
    } else if (deviceType === deviceInfo.typeMobile && window.innerWidth > deviceInfo.desktopMinWidth) {
      this.setState({ deviceType: deviceInfo.typeDesktop });
    }
  };

  loadOrder = () => {
    const { loadEmployeeOrder, params } = this.props;
    const { employeeCPF } = params;
    loadEmployeeOrder(employeeCPF);
  };

  renderIndustrialDetails = () => {
    const { selectedIndustrialOrder, order = null } = this.props;
    const { industrialOrder } = selectedIndustrialOrder;
    const pedidoCriado = langValues.labelsPedidoComercial.dataPedido;
    return (
      <div className="pedidoIndustrial-component">
        <ClientIndustrialOrderDetail
          industrialOrder={industrialOrder}
          subtitle={`${pedidoCriado} ${getDateAsNumbers(order.orderDate, false)}`}
        />
      </div>
    );
  };

  renderComercialDetails = () => {
    const { employeeOrders, order = null, selectedIndustrialOrder, changeSelectedEmployeeOrder } = this.props;
    const { industrialOrder } = selectedIndustrialOrder;
    const { deviceType } = this.state;
    if (deviceType === deviceInfo.typeMobile) {
      return (
        <div className="commercialDetails">
          <ClientOrderMobile
            clientOrders={employeeOrders}
            changeSelectedEmployeeOrder={changeSelectedEmployeeOrder}
            order={order}
            industrialOrder={industrialOrder}
          />
        </div>
      );
    }
    return (
      <div className="commercialDetails">
        <PedidoComercial
          clientOrders={employeeOrders}
          changeSelectedEmployeeOrder={changeSelectedEmployeeOrder}
          changeSelectedOrder={changeSelectedEmployeeOrder}
          order={order}
          industrialOrder={industrialOrder}
        />
      </div>
    );
  };

  renderLeftDiv = () => <div className="pedidoComercial">{this.renderComercialDetails()}</div>;

  renderRightDivSkeleton = () => (
    <div id="industrialDetailsDiv">
      <div id="industrialOrdersWrapperEmpty">
        <div id="topLeftElement" className="skeleton" />
        <div id="carNameEmpty" className="skeleton" />
        <div id="carSubTitle" className="skeleton" />
        <div id="carDetailsWrapperEmpty">
          <div className="carDetailRow">
            <div className="columnEmptyWrapper">
              <div className="smallTitleEmpty skeleton" />
              <div className="verySmallTitleEmpty skeleton" />
            </div>
            <div className="columnEmptyWrapper">
              <div className="smallTitleEmpty skeleton" />
              <div className="verySmallTitleEmpty skeleton" />
            </div>
            <div className="columnEmptyWrapper">
              <div className="largeTitleEmpty skeleton" />
              <div className="verySmallTitleEmpty skeleton" />
            </div>
          </div>
          <div className="carDetailRow">
            <div className="columnEmptyWrapper">
              <div className="largeTitleEmpty skeleton longDistance" />
              <div className="smallTitleEmpty skeleton topDistance" />
            </div>
            <div className="columnEmptyWrapper">
              <div className="largeTitleEmpty skeleton longDistance" />
              <div className="smallTitleEmpty skeleton topDistance" />
            </div>
          </div>
        </div>
      </div>
      <div id="timelineWrapperEmpty" />
      <div id="siebelWrapperEmpty" />
    </div>
  );

  renderInvoiceDownload = () => {
    const { order = null, selectedIndustrialOrder } = this.props;
    const { industrialOrder } = selectedIndustrialOrder;
    const modalData = {
      orderId: order ? order.orderId : null,
      cpf: Token.get(),
    };

    if (industrialOrder.invoiceAvailable) {
      return <InvoiceDownload modalData={modalData} />;
    }

    return null;
  };

  renderRightDiv = () => {
    const { selectedIndustrialOrder, loading } = this.props;
    const { industrialOrder } = selectedIndustrialOrder;
    const { deviceType } = this.state;

    if (loading) {
      return this.renderRightDivSkeleton();
    }

    if (industrialOrder) {
      return (
        <div className="industrialOrder">
          <div id="industrialDetailsDiv">
            {deviceType === deviceInfo.typeDesktop ? (
              <div id="industrialOrdersWrapper">{this.renderIndustrialDetails()}</div>
            ) : null}
            <div className="timeline-body">
              <div id="timelineWrapper" className="timeline-client">
                <Timeline deviceType={deviceType} />
              </div>
              <span className="clientLegalTerm font-small2">{langValues.clientLegalTerm}</span>
            </div>
            {deviceType === deviceInfo.typeMobile ? this.renderInvoiceDownload() : null}
          </div>
        </div>
      );
    }

    return (
      <div id="noIndustrialOrders">
        <div id="noIndustrialOrdersCenterWrapper">
          <NoIndustrialOrders />
          <span id="noIndustrialOrdersMessage">{langValues.detailsCommercial.noIndustrialOrders}</span>
        </div>
      </div>
    );
  };

  renderPedidoComercial = () => (
    <div className="comercialBody">
      {this.renderLeftDiv()}
      {this.renderRightDiv()}
    </div>
  );

  renderWelcomeModal = () => {
    const { showEmployeeFeature, setEmployeeFeatureViewed, clearState } = this.props;

    if (showEmployeeFeature === undefined) {
      clearState();
      return null;
    }

    const { search } = location;
    const isAutoLoginString = new URLSearchParams(search).get('isAutoLogin');
    const isAutoLogin = isAutoLoginString === 'true';

    if (showEmployeeFeature && isAutoLogin) {
      return (
        <BaseModal open onClose={setEmployeeFeatureViewed} className="employeeWelcomeModal-wrapper">
          <div className="employeeWelcomeModal">
            <CloseIcon className="employeeWelcomeModal-closeIcon" onClick={setEmployeeFeatureViewed} />
            <div className="employeeWelcomeModal-image">
              <StellantisLogo className="feather-logo" />
              <div className="divisao" />
              <div className="easy-tracking">{titleEasyTracking.easyTracking}</div>
            </div>
            <div className="employeeWelcomeModal-text">
              <h5>{langValues.loginTexts.trackYourOrder}</h5>
              <span className="font-body2">{langValues.loginTexts.aboutSystemResumed()}</span>
            </div>
          </div>
        </BaseModal>
      );
    }

    return null;
  };

  render = () => {
    const { error = null } = this.props;
    return (
      <>
        {error ? <SnackBar message={langValues.detailsCommercial.errorLoading} /> : this.renderPedidoComercial()}
        {this.renderWelcomeModal()}
      </>
    );
  };
}

EmployeeOrderDetail.propTypes = {
  loadEmployeeOrder: PropTypes.func.isRequired,
  changeSelectedEmployeeOrder: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  employeeOrders: PropTypes.array.isRequired,
  order: PropTypes.object,
  selectedIndustrialOrder: PropTypes.object.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }).isRequired,
  showEmployeeFeature: PropTypes.bool.isRequired,
  setEmployeeFeatureViewed: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired,
};

export default EmployeeOrderDetail;
