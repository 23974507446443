
import { connect } from 'react-redux';

import { loadLogout } from 'Infrastructure/Ducks/Auth';
import Menu from './Menu';

const mapDispatchToProps = dispatch => ({
  loadLogout: () => {
    dispatch(loadLogout());
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(Menu);
