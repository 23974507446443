import React from 'react';

import './SkeletonList.scss';

const SkeletonList = ({ items, columns }) => {
  const skeleton = [];
  for (let i = 0; i < items; i += 1) {
    const line = [];
    for (let j = 0; j < columns; j += 1) {
      line.push(<div className="skeleton-list-item" key={`${i}-${j}`} />);
    }
    skeleton.push(
      <div style={{ display: 'contents' }} key={i}>
        <>{line}</>
      </div>
    );
  }
  return <>{skeleton}</>;
};

export default SkeletonList;
