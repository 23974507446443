import { connect } from 'react-redux';
import {
  loadEmployeeOrder, changeSelectedOrder, clearDuck,
} from 'Infrastructure/Ducks/OrderDetailDuck';
import { setEmployeeFeatureViewed, clearState } from 'Infrastructure/Ducks/FeaturesDuck';
import EmployeeOrderDetail from './EmployeeOrderDetail';

const mapStateToProps = (state) => {
  const { orderDetail, featuresDuck } = state;
  return {
    selectedIndustrialOrder: orderDetail.selectedIndustrialOrder,
    order: orderDetail.order,
    employeeOrders: orderDetail.clientOrders,
    loading: orderDetail.loading,
    error: orderDetail.error,
    showEmployeeFeature: featuresDuck.showEmployeeFeature,
  };
};

const mapDispatchToProps = dispatch => ({
  clearDuck: () => {
    dispatch(clearDuck());
  },
  loadEmployeeOrder: (employeeCPF) => {
    dispatch(loadEmployeeOrder(employeeCPF));
  },
  changeSelectedEmployeeOrder: (order) => {
    dispatch(changeSelectedOrder(order));
  },
  setEmployeeFeatureViewed: () => {
    dispatch(setEmployeeFeatureViewed());
  },
  clearState: () => {
    dispatch(clearState());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeOrderDetail);
