import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as AlertTriangle } from 'Common/Assets/alert-triangle.svg';

import './AlertIcon.scss';

const AlertIcon = ({ type }) => (
  <div className={`alertIcon ${type}`}>
    <AlertTriangle />
  </div>
);

AlertIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default AlertIcon;
