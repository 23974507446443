import React from 'react';

import { withStyles } from 'tss-react/mui';
import Popover from '@mui/material/Popover';

import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import { User } from 'Common/Helpers/AuthHelper';

import { ReactComponent as SettingsIcon } from 'Common/Assets/settings.svg';

import './PopUpMenu.scss';
import langValues from 'Config/i18n/index';
import ToggleEnvironment from 'Components/Atoms/ToggleEnvironment/ToggleEnvironment';
import { environment, roleConsts } from 'Utils/consts';

const styles = () => ({
  paper: {
    boxShadow: 'none',
  },
});

class PopUpMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  renderMacroAreaUserOption = () => {
    const { setMacroAreaModalOpen } = this.props;
    return (
      <div className="pop-up-menu-item-div" onClick={setMacroAreaModalOpen}>
        <div className="pop-up-menu-item-label">Alertas</div>
      </div>
    );
  };

  renderMenuItem = (item) => {
    if (User.hasProfile(roleConsts.customercare) && item.id === 'visionConfiguration') {
      return null;
    }
    const { selectMenu } = this.props;
    return (
      <Link to={item.path} key={`pop-up-menu-item-${item.id}`}>
        <div
          className="pop-up-menu-item-div"
          onClick={() => {
            selectMenu(item.id);
          }}
        >
          <div className="pop-up-menu-item-label">{langValues.menu.popUpMenu[item.id]}</div>
        </div>
      </Link>
    );
  };

  handleClick = (event) => {
    const { anchorEl } = this.state;
    if (anchorEl === event.currentTarget) {
      this.setState({ anchorEl: null });
    } else {
      this.setState({ anchorEl: event.currentTarget });
    }
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  renderSelectedBar = (settingsId) => {
    const { currentScreen } = this.props;
    return settingsId.includes(currentScreen) ? <div className="selectedRectangle" /> : '';
  };

  render() {
    const { anchorEl } = this.state;
    const { options } = this.props;
    const settingsId = options.reduce((acc, cur, index) => {
      acc[index] = cur.id;
      return acc;
    }, []);
    return (
      <div className="menuItem" onClick={this.handleClick}>
        {this.renderSelectedBar(settingsId)}
        <div className="menuItemIcon">
          <SettingsIcon />
          <Popover
            style={{
              width: '400px',
              marginLeft: '12px',
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            <div className="popup-menu">
              {options.map((item) => this.renderMenuItem(item))}
              <ToggleEnvironment env={environment.BR}>{this.renderMacroAreaUserOption()}</ToggleEnvironment>
            </div>
          </Popover>
        </div>
      </div>
    );
  }
}

PopUpMenu.propTypes = {
  options: PropTypes.array.isRequired,
  selectMenu: PropTypes.func.isRequired,
  setMacroAreaModalOpen: PropTypes.func.isRequired,
  currentScreen: PropTypes.string.isRequired,
};

export default withStyles(PopUpMenu, styles);
