import React from 'react';
import PropTypes from 'prop-types';

import './Loader.scss';

const Loader = ({ className = 'loaderWrapper' }) => (
  <div className={className}>
    <div className="lds-roller">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

Loader.propTypes = {
  className: PropTypes.string,
};

export default Loader;
