import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { ReactComponent as PenDocIcon } from 'Common/Assets/penDoc.svg';
import { ReactComponent as PenFinIcon } from 'Common/Assets/pendFinanceira.svg';
import { ReactComponent as PendConcIcon } from 'Common/Assets/pendConc.svg';
import { ReactComponent as OrderSwap } from 'Common/Assets/orders_swap.svg';
import { ReactComponent as Arrow } from 'Common/Assets/arrow-right.svg';
import { ReactComponent as CloseIcon } from 'Common/Assets/close-cross.svg';
import langValues from 'Config/i18n/index';

import Modal from '@mui/material/Modal';

import { trackEvent } from 'Utils/analytics';
import { trackedEvents, trackedButtons } from 'Utils/consts';
import './Pendencia.scss';

function renderIcon(iconCondition, Icon) {
  return (
    <div className="iconDiv">
      <div id="icon">{iconCondition ? <Icon /> : null}</div>
    </div>
  );
}

function renderSwapIcon(item, setOpenModal) {
  return (
    <div
      id="orderSwapIcon"
      onClick={() => {
        setOpenModal();
        trackEvent(trackedEvents.buttonClick, { button: trackedButtons.openSwapModal });
      }}
    >
      {renderIcon(item.orderSwapFlag, OrderSwap)}
    </div>
  );
}

function renderSwaps(orderSwapList, onClick) {
  return (
    <div id="swapIndustrialOrdersModal-swaps">
      {orderSwapList.map((item, index) => (
        <div className="swapIndustrialOrdersModal-swap" key={index}>
          <div className="swapIndustrialOrdersModal-swapContentWrapper">
            <div className="swapIndustrialOrdersModal-swapIndustrial">{`Pedido industrial ${item.industrialOrderId}`}</div>
            <div className="swapIndustrialOrdersModal-arrow">
              <Arrow />
            </div>
            <Link
              to={`/detalhes/${item.currentOrder}`}
              className="swapIndustrialOrdersModal-swapComercial"
              onClick={onClick}
            >
              {`Pedido comercial ${item.currentOrder}`}
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}

export const renderSwapModal = (openModal, onClose, item) => {
  if (item.orderSwapList) {
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openModal}
        onClose={onClose}
        style={{ display: 'flex' }}
      >
        <div id="swapIndustrialOrdersModal">
          <div id="swapIndustrialOrdersModal-header">
            <div id="swapIndustrialOrdersModal-title">
              <div id="swapIndustrialOrdersModal-icon">
                <OrderSwap />
              </div>
              <div id="swapIndustrialOrdersModal-titleText">{langValues.orderExchangeHistory}</div>
              <div id="swapIndustrialOrdersModal-closeIcon" onClick={onClose}>
                <CloseIcon />
              </div>
            </div>
          </div>
          {renderSwaps(item.orderSwapList, onClose)}
        </div>
      </Modal>
    );
  }
  return null;
};

function Pendencia({ item, renderType }) {
  const [openModal, setOpenModal] = useState(false);
  const changeOpenModalState = () => {
    setOpenModal(!openModal);
  };

  if (renderType === 'pendCli') {
    return (
      <div className="alertsDiv">
        <div className="alertsDivWrapper">
          {renderSwapIcon(item, changeOpenModalState)}
          {renderSwapModal(openModal, changeOpenModalState, item)}
          {renderIcon(item.clientFinancialPendencyFlag, PenFinIcon)}
          {renderIcon(item.clientDocumentPendencyFlag, PenDocIcon)}
        </div>
      </div>
    );
  }
  return (
    <div className="alertsDiv">
      <div className="alertsDivWrapper">{renderIcon(item.dealerPendencyFlag, PendConcIcon)}</div>
    </div>
  );
}

Pendencia.propTypes = {
  item: PropTypes.object.isRequired,
  renderType: PropTypes.string.isRequired,
};

export default Pendencia;
