import React from 'react';
import PropTypes from 'prop-types';
import langValues from 'Config/i18n/index';
import Expansion from 'Components/Atoms/Expansion/Expansion';
import InputNumber from 'Components/Atoms/InputNumber/InputNumber';
import './ListRegionCities.scss';

const renderArrowIcon = (name, isSelected, setSelected, shouldRender) => {
  if (shouldRender) {
    return (
      <Expansion
        name={name}
        isSelected={isSelected}
        setSelected={setSelected}
      />
    );
  }
  return null;
};

const ListRegionCities = ({
  listItems,
  isEditingList,
  handleInputChanging,
}) => listItems.map((item) => {
  const renderInputDays = (
    destination,
  ) => {
    const safeNumber = destination.parameter !== null ? destination.parameter : '';
    return (
      <InputNumber
        value={safeNumber}
        onChange={value => handleInputChanging(
          item.district,
          item.name,
          destination.description,
          value,
        )}
        isEdited={destination.isEdited}
      />
    );
  };

  const renderTextDays = (text) => {
    if (text !== null) {
      if (text > 1) {
        return `${text} ${langValues.days}`;
      }
      return `${text} ${langValues.day}`;
    }
    return '-';
  };

  const getDestinationDays = (
    destination,
  ) => {
    if (isEditingList) {
      return renderInputDays(
        destination,
      );
    }
    return renderTextDays(destination.parameter);
  };

  const renderDestinations = (item.destinations.map(destination => (
    <td className="font-subtitle2">{getDestinationDays(destination)}</td>
  )));

  const rowType = item.district ? 'city' : 'region';
  const cityCellType = item.district ? 'cityWithPadding' : null;
  return (
    <tr className={`ListRegionCities-item ${rowType}`} key={item.name}>
      <td className="font-subtitle2">
        {renderArrowIcon(item.name, item.isSelected, item.setSelectedRegion, !item.district)}
      </td>
      <td className={`font-subtitle2 item-bolder ${cityCellType}`}>{item.name}</td>
      {renderDestinations}
    </tr>
  );
});

ListRegionCities.propTypes = {
  listItems: PropTypes.array.isRequired,
  handleInputChanging: PropTypes.func.isRequired,
};

export default ListRegionCities;
