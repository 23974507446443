import { connect } from 'react-redux';
import { changePageScreen, checkAuth } from 'Infrastructure/Ducks/Auth';
import PrivatePages from './PrivatePages';
import { withRouterInfo } from '../withRouterInfo';

const mapStateToProps = (state) => {
  const { auth } = state;
  const { currentScreen } = auth;
  return {
    logged: auth ? auth.logged : false,
    currentScreen,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changePageScreen: (screen) => {
    dispatch(changePageScreen(screen));
  },
  checkAuth: () => {
    dispatch(checkAuth());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouterInfo(PrivatePages));
