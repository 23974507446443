import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { titleEasyTracking, roleConsts } from 'Utils/consts';
import langValues from 'Config/i18n/index';
import UserEntry from 'Components/LoginComponents/UserEntry';
import RedirectEntry from 'Components/LoginComponents/RedirectEntry/RedirectEntry';
import { ReactComponent as StellantisLogo } from 'Common/Assets/logo_stellantis.svg';
import './Login.scss';

class Login extends Component {
  constructor(props) {
    super(props);

    const { location } = props;

    this.state = {
      email: '',
      password: '',
      employeeCPF: '',
      userType: location.pathname === '/login/empregados' ? roleConsts.employees : roleConsts.customercare,
    };
  }

  componentDidMount = () => {
    const { location, navigate, loadAuth, loadEmployeeAuth, clearDucks, intializeLogin } = this.props;
    intializeLogin();

    const { search } = location;
    const userId = new URLSearchParams(search).get('userid');

    if (userId) {
      this.setState({
        userType: roleConsts.dealers,
      });
      clearDucks();
      loadAuth({
        userType: roleConsts.dealers,
        dealerUserId: userId,
        navigate,
      });
    }
  };

  handleChange = (value, id) => {
    this.setState({ [id]: value });
  };

  radioChange = (value) => {
    this.setState({ userType: value });
  };

  renderLoginSubTitle = () => {
    const { location } = this.props;

    if (location.pathname === '/login/empregados') {
      return (
        <div className="loginAbout">
          <span className="font-subtitle2 loginAbout-title">{langValues.loginTexts.aboutSystemTitle}</span>
          <span className="font-body2">{langValues.loginTexts.aboutSystemText()}</span>
        </div>
      );
    }
    return <div className="loginSubtitle">{langValues.loginTexts.systemDescription}</div>;
  };

  onLoginClick = async (recaptchaRef = null) => {
    const { email, password, employeeCPF, userType } = this.state;
    const { loadAuth, loadEmployeeAuth, loadClientAuth, clearDucks, navigate } = this.props;

    clearDucks();
    switch (userType) {
      case roleConsts.employees: {
        const finalEmployeeCPF = this.replaceDotsCPF(employeeCPF);
        const isAutoLogin = false;
        const userName = email;
        await loadEmployeeAuth({ finalEmployeeCPF, userName, password, navigate, isAutoLogin });
        break;
      }
      case roleConsts.client: {
        const clientDocument = this.replaceDotsCPF(email);
        const orderCode = password;
        const captchaToken = await recaptchaRef.current.getValue();
        await loadClientAuth({ clientDocument, orderCode, navigate, captchaToken });
        recaptchaRef.current.reset();
        break;
      }
      default: {
        const userName = email;
        await loadAuth({ userName, password, userType, navigate });
        break;
      }
    }
  };

  replaceDotsCPF = (value) => {
    const regexReplace = /[/|.|-]/g;
    const strCpfEdited = value.replace(regexReplace, '');
    return strCpfEdited;
  };

  renderUserEntry = () => {
    const { email, password, employeeCPF, userType } = this.state;
    const { loginError = null, loginLoading, expiredSession, location } = this.props;
    if (window.location.href.includes('easytracking.fcalatam.com.br')) {
      return <RedirectEntry url="https://easytracking.stellantis.com.br" path={location.pathname} />;
    }
    return (
      <UserEntry
        username={email}
        password={password}
        employeeCPF={employeeCPF}
        onLoginClick={this.onLoginClick}
        loginError={loginError}
        expiredSession={expiredSession}
        userType={userType}
        radioChange={this.radioChange}
        loginLoading={loginLoading}
        onChange={this.handleChange}
      />
    );
  };

  render() {
    return (
      <div className="login-component">
        <div className="login-text">
          <div className="title-box">
            <div id="fcaIconEasy">
              <StellantisLogo className="feather-logo" />
              <div className="divisao" />
              <div className="easy-tracking">{titleEasyTracking.easyTracking}</div>
            </div>
            {this.renderLoginSubTitle()}
          </div>
        </div>
        <div className="entry-background">{this.renderUserEntry()}</div>
      </div>
    );
  }
}

Login.propTypes = {
  loginError: PropTypes.object,
  expiredSession: PropTypes.bool.isRequired,
  loginLoading: PropTypes.bool.isRequired,
  loadAuth: PropTypes.func.isRequired,
  clearDucks: PropTypes.func.isRequired,
  intializeLogin: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }).isRequired,
  loadEmployeeAuth: PropTypes.func.isRequired,
  loadClientAuth: PropTypes.func.isRequired,
};

export default Login;

