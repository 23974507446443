import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as LeftArrowIcon } from 'Common/Assets/leftArrow.svg';
import { ReactComponent as NoIndustrialOrders } from 'Common/Assets/NoIndustrialOrders.svg';
import { trackEvent } from 'Utils/analytics';
import { stepStatusId, trackedEvents, trackedPages, trackedButtons } from 'Utils/consts';

import IndustrialOrdersList from 'Components/Organisms/IndustrialOrdersList/index';
import OrderInformationCard from 'Components/Organisms/OrderInformationCard/index';
import TimelineCard from 'Components/Organisms/TimelineCard/index';
import ResultResponse from 'Components/Atoms/ResultResponse';
import langValues from 'Config/i18n/index';

import './OrderDetail.scss';
import { CircularProgress } from 'Components/HelpComponents/ResponseModal/styles';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';

const OrderDetail = ({ params, loadOrderById, order = null, clearDuck, loading, ...props }) => {
  const trackPageView = (orderType) => {
    const industrialOrder = {
      brandName: null,
      modelName: null,
    };
    if (order !== null) {
      const { industrialOrdersList } = order;
      if (industrialOrdersList.length > 0) {
        industrialOrder.brandName = industrialOrdersList[0].brandName;
        industrialOrder.modelName = industrialOrdersList[0].modelName;
      }
    }

    trackEvent(trackedEvents.pageView, {
      page: trackedPages.detalhes,
      orderType,
      brand: industrialOrder.brandName,
      model: industrialOrder.modelName,
    });
  };

  useLayoutEffect(() => {
    clearDuck();
    loadOrderById(params.orderId, trackPageView);
  }, []);

  const renderReturnButton = () => {
    const { location } = props;
    const { search } = location;
    const previousPage = new URLSearchParams(search).get('previousPage') || 'pedidos';
    return (
      <div className="orderDetail-returnButton">
        <span>
          <Link
            to={`/${previousPage}`}
            className="orderDetail-returnButton-text"
            onClick={() => trackEvent(trackedEvents.buttonClick, { button: trackedButtons.returnToList })}
          >
            <LeftArrowIcon />
            {langValues.telaDetalhesPedidos.returnToList}
          </Link>
        </span>
      </div>
    );
  };

  const renderOrderInformation = () => <OrderInformationCard />;

  const renderIndustrialOrdersList = () => {
    const industrialOrdersList = order ? order.industrialOrdersList : [];
    return <IndustrialOrdersList industrialOrdersList={industrialOrdersList} />;
  };

  const renderTimeline = () => <TimelineCard />;

  const renderLoading = () => (
    <section className="timelineCard-skeleton center">
      <CircularProgress />
    </section>
  );

  const renderIndustrialOrderSections = () => {
    if (order && order.statusId === stepStatusId.withoutOrder) {
      return (
        <ResultResponse
          Icon={NoIndustrialOrders}
          title={langValues.orderDetail.noIndustrialOrdersTitle}
          subtitle={langValues.orderDetail.noIndustrialOrdersSubtitle}
        />
      );
    }

    return (
      <>
        {renderIndustrialOrdersList()}
        {loading && ENV_ENVIRONMENT === 'AR' ? renderLoading() : renderTimeline()}
      </>
    );
  };

  return (
    <main className="orderDetail">
      {renderReturnButton()}
      {renderOrderInformation()}
      {renderIndustrialOrderSections()}
    </main>
  );
};

OrderDetail.propTypes = {
  params: PropTypes.object.isRequired,
  order: PropTypes.object,
  loadOrderById: PropTypes.func.isRequired,
  clearDuck: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }).isRequired,
};

export default OrderDetail;
