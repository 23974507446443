import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import PropTypes from 'prop-types';

const ToggleEnvironment = ({ env, children = null }) => {
  if (env === ENV_ENVIRONMENT) {
    return children;
  }
  return null;
};

ToggleEnvironment.propTypes = {
  env: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default ToggleEnvironment;
