import React from 'react';
import PropTypes from 'prop-types';
import Popper from '@mui/material/Popper';
import { ReactComponent as CloseIcon } from 'Common/Assets/closeIcon.svg';
import NotificationMessage from '../NotificationMessage';
import './NotificationProvider.scss';

const NotificationProvider = ({ anchorEl, handleClose, notifications = [] }) => (
  <Popper
    id="notification-popper"
    open={Boolean(anchorEl)}
    anchorEl={anchorEl}
    //onClose={handleClose}
    placement="right-end"
  >
    <section className="notifications-section">
      <div className="notifications-header">
        <h6>Alertas</h6>
        <button type="button" onClick={(e) => handleClose(e)}>
          <CloseIcon />
        </button>
      </div>
      <div className="notifications-content">
        {notifications.map((notification) => (
          <NotificationMessage key={notification.title} notification={notification} />
        ))}
      </div>
    </section>
  </Popper>
);

NotificationProvider.propTypes = {
  handleClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
  notifications: PropTypes.array,
};

export default NotificationProvider;
