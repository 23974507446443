import React from 'react';
import PropTypes from 'prop-types';
import { safeToUpperCase } from 'Utils/safeMethods';

import './Tab.scss';

const Tab = ({ tabText, isActive, isDisabled, onTabClick }) => (
  <button className="tab-button-wrapper" type="button" onClick={() => onTabClick()}>
    <span
      className={`font-subtitle2 tab-item ${isActive ? 'active' : 'inactive'} ${isDisabled ? 'disabled' : 'enabled'}`}
    >
      {safeToUpperCase(tabText)}
    </span>
  </button>
);

Tab.propTypes = {
  tabText: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  onTabClick: PropTypes.func.isRequired,
};

export default Tab;
