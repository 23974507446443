import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Selector from 'Components/Atoms/Selector/Selector';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';
import TextFieldInput from 'Components/Atoms/TextFieldInput/TextFieldInput';
import langValues from 'Config/i18n/index';
import RequestRepository from 'Infrastructure/Repositories/RequestRepository';
import './ExportOptionsPDA.scss';
import { useKey } from 'Utils/useKey';

const ExportOptionsPDA = ({ setExportPdaFilter, filtersExportPDA, exportCallbackPDA }) => {
  const [activeField, setActiveField] = useState(1);
  const [brands, setBrands] = useState([]);
  const [filters, setFilters] = useState(filtersExportPDA);
  const [from, setFrom] = useState(filtersExportPDA ? filtersExportPDA.groupCodeFrom : '');
  const [to, setTo] = useState(filtersExportPDA ? filtersExportPDA.groupCodeTo : '');
  const [brand, setBrand] = useState(filtersExportPDA ? filtersExportPDA.Marca : '');

  const CAN_FILTER = !!from && !!to;
  const pressed_tab = useKey('Tab');

  const handleClean = () => {
    setFilters({});
    setFrom('');
    setTo('');
    setActiveField(1);

    if (brands.length > 1) {
      setBrand('');
      setExportPdaFilter({});
    } else {
      setExportPdaFilter({ Marca: filters.Marca });
    }
  };

  const handleFilters = (name, value) => {
    let values = filters || {};
    values[name] = value;

    if (name === 'groupCodeFrom') {
      setFrom(value);
    }
    if (name === 'groupCodeTo') {
      setTo(value);
    }
    if (name === 'Marca') {
      setBrand(value);
    }

    return setFilters(values);
  };

  function delay(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const handleFilter = async (type) => {
    setExportPdaFilter(filters);

    await delay(200);
    exportCallbackPDA(type);
  };

  useEffect(() => {
    const getBrands = async () => {
      const { data } = await RequestRepository.get('brands');
      const formatData =
        data && data.length > 0
          ? data.map(({ brandCode, brandDescription }) => ({ label: brandDescription, value: brandCode }))
          : [];

      if (formatData && formatData.length > 0 && formatData.length === 1) {
        handleFilters('Marca', formatData[0].value);
      }
      setBrands(formatData);
    };

    getBrands();
  }, []);

  useEffect(() => {
    if (pressed_tab) {
      setActiveField((prev) => (prev === 2 ? 3 : 2));
    }
  }, [pressed_tab]);

  return (
    <div className="export-options-pda">
      <div className="export-options-pda--filters">
        <div className="export-options-pda--filters--field">
          <p>Marca</p>
          <Selector
            options={brands}
            value={brands.filter((f) => f.value === brand)}
            closeMenuOnSelect={true}
            defaultValue={null}
            handleChangeSelect={({ value }) => handleFilters('Marca', value)}
          />
        </div>
        <div>
          <p>Grupo Desde</p>
          <TextFieldInput
            label=""
            type="tel"
            savedTextContent={from}
            inputRef={(input) => input && activeField && activeField === 2 && input.focus()}
            onChange={(val) => handleFilters('groupCodeFrom', val)}
            onClick={() => setActiveField(2)}
            onFocus={() => setActiveField(2)}
            style={{ height: '40px', marginTop: '4px' }}
            variant="outlined"
            inputProps={{ maxLength: 5 }}
          />
        </div>
        <div>
          <p>Grupo Haste</p>
          <TextFieldInput
            label=""
            inputRef={(input) => input && activeField && activeField === 3 && input.focus()}
            type="tel"
            savedTextContent={to}
            onChange={(val) => handleFilters('groupCodeTo', val)}
            onClick={() => setActiveField(3)}
            onFocus={() => setActiveField(3)}
            style={{ height: '40px', marginTop: '4px' }}
            variant="outlined"
            inputProps={{ maxLength: 5, autoFocus: true }}
          />
        </div>
      </div>

      <div className="export-options-pda--buttons">
        <button type="button" className="cleanAll-text" onClick={handleClean}>
          {langValues.clearSelection}
        </button>
        <MaterialButton
          onClick={CAN_FILTER ? () => handleFilter('xlsx') : () => {}}
          buttonText={'Exportar XLSX'}
          type="primary"
          style={CAN_FILTER ? {} : { opacity: 0.6, cursor: 'default' }}
        />
        <MaterialButton
          onClick={
            CAN_FILTER
              ? () => {
                  handleFilter('csv');
                }
              : () => {}
          }
          buttonText={'Exportar CSV'}
          type="primary"
          style={CAN_FILTER ? {} : { opacity: 0.6, cursor: 'default' }}
        />
      </div>
    </div>
  );
};

ExportOptionsPDA.propTypes = {
  setExportPdaFilter: PropTypes.func.isRequired,
  filtersExportPDA: PropTypes.object,
  exportCallbackPDA: PropTypes.func.isRequired,
};

export default ExportOptionsPDA;
