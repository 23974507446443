import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const Selector = (props) => {
  const {
    value,
    styles = {},
    isMulti = false,
    closeMenuOnSelect = false,
    options,
    placeholder,
    handleOpenSelect = () => null,
    handleCloseSelect = () => null,
    handleChangeSelect,
    isClearable = false,
    defaultValue,
    isDisabled,
  } = props;
  return (
    <Select
      styles={styles}
      isMulti={isMulti}
      closeMenuOnSelect={closeMenuOnSelect}
      options={options}
      isSearchable={false}
      isClearable={isClearable}
      isDisabled={isDisabled}
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onMenuOpen={handleOpenSelect}
      onMenuClose={handleCloseSelect}
      onChange={handleChangeSelect}
      className="react-select-container"
      classNamePrefix="react-select"
      {...props}
    />
  );
};

Selector.propTypes = {
  value: PropTypes.object,
  options: PropTypes.array.isRequired,
  styles: PropTypes.object,
  isMulti: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  handleOpenSelect: PropTypes.func,
  handleCloseSelect: PropTypes.func,
  handleChangeSelect: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  isDisabled: PropTypes.bool,
};

export default Selector;
