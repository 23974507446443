import MuiButton from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';

export const Button = withStyles(MuiButton, {
  root: {
    fontSize: '9px',
  },
});

export default null;
