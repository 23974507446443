import NotificationService from '../Services/NotificationService';

export const DUCK_NAME = 'notificationDuck';

const INITIAL_STATE = () => ({
  currentNotifications: [],
  viewedNotificationIds: [],
  macroAreaNotifications: [],
  newMacroAreaNotificationIds: [],
});

const UPDATE_VIEWED_NOTIFICATIONS = `${DUCK_NAME}/UPDATE_VIEWED_NOTIFICATIONS`;
const LOAD_NOTIFICATIONS_SUCCEED = `${DUCK_NAME}/LOAD_NOTIFICATIONS_SUCCEED`;

const UPDATE_VIEWED_MACRO_AREA_NOTIFICATIONS = `${DUCK_NAME}/UPDATE_VIEWED_MACRO_AREA_NOTIFICATIONS`;
const LOAD_MACRO_AREA_NOTIFICATIONS_SUCCEED = `${DUCK_NAME}/LOAD_MACRO_AREA_NOTIFICATIONS_SUCCEED`;

const CLEAR_STATE = `${DUCK_NAME}/CLEAR_STATE`;

export const loadNotificationsSucceed = receivedNotifications => (
  { type: LOAD_NOTIFICATIONS_SUCCEED, receivedNotifications }
);

export const updateViewedNotifications = () => ({ type: UPDATE_VIEWED_NOTIFICATIONS });

export const loadMacroAreaNotificationsSucceed = receivedNotifications => (
  { type: LOAD_MACRO_AREA_NOTIFICATIONS_SUCCEED, receivedNotifications }
);

export const updateViewedMacroAreaNotifications = () => (
  { type: UPDATE_VIEWED_MACRO_AREA_NOTIFICATIONS }
);

export const clearNotifications = () => ({ type: CLEAR_STATE });

export const loadNotifications = () => async (dispatch) => {
  try {
    const notificationService = new NotificationService();
    const receivedNotifications = await notificationService.getNotifications();
    dispatch(loadNotificationsSucceed(receivedNotifications));
  } catch (err) {
    clearNotifications();
  }
};

export const loadMacroAreaNotifications = () => async (dispatch) => {
  try {
    const notificationService = new NotificationService();
    const receivedNotifications = await notificationService.getMacroAreaNotifications();
    dispatch(loadMacroAreaNotificationsSucceed(receivedNotifications));
  } catch (err) {
    clearNotifications();
  }
};

const getNewMacroAreaNotificationIds = (received, state) => {
  const receivedIds = received.map(notification => notification.id);
  const stateIds = state.map(notification => notification.id);

  return receivedIds.filter(id => !stateIds.includes(id));
};

const reducer = (state = INITIAL_STATE(), action) => {
  switch (action.type) {
    case LOAD_NOTIFICATIONS_SUCCEED:
      return {
        ...state,
        currentNotifications: [...action.receivedNotifications],
        viewedNotificationIds: state.viewedNotificationIds.filter(
          id => [...action.receivedNotifications].map(notification => notification.id).includes(id),
        ),
      };
    case UPDATE_VIEWED_NOTIFICATIONS:
      return {
        ...state,
        viewedNotificationIds: state.currentNotifications.map(notification => notification.id),
      };
    case LOAD_MACRO_AREA_NOTIFICATIONS_SUCCEED:
      return {
        ...state,
        macroAreaNotifications: [...action.receivedNotifications],
        newMacroAreaNotificationIds: getNewMacroAreaNotificationIds(
          action.receivedNotifications,
          state.macroAreaNotifications,
        ),
      };
    case UPDATE_VIEWED_MACRO_AREA_NOTIFICATIONS:
      return {
        ...state,
        newMacroAreaNotificationIds: [],
      };
    case CLEAR_STATE:
      return INITIAL_STATE();
    default:
      return state;
  }
};

export default reducer;
