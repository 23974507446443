import React from 'react';
import langValues from 'Config/i18n/index';
import PropTypes from 'prop-types';
import BaseModal from 'Components/Atoms/BaseModal/BaseModal';
import { ReactComponent as CloseIcon } from 'Common/Assets/closeIcon.svg';
import MaterialButton from '../MaterialButton/MaterialButton';
import './EditTemplateModal.scss';

const EditTemplateModal = ({ title, cancelAction, saveAction, isOpen, input }) => (
  <BaseModal open={isOpen} onBackdropClick={() => cancelAction()}>
    <div className="editTemplate-modal">
      <div className="editTemplate-header">
        <h5>{title}</h5>
        <CloseIcon className="editTemplate-closeIcon" onClick={() => cancelAction()} />
      </div>
      <div className="editTemplate-labels">{input}</div>
      <div className="editTemplate-buttons">
        <MaterialButton
          sx={{ ':hover': { borderColor: '#0000003b' } }}
          type="secondary"
          onClick={() => cancelAction()}
          buttonText={langValues.cancel}
          variant="outlined"
        />
        <MaterialButton type="primary" onClick={() => saveAction()} buttonText={langValues.save} variant="contained" />
      </div>
    </div>
  </BaseModal>
);

EditTemplateModal.propTypes = {
  input: PropTypes.object.isRequired,
  cancelAction: PropTypes.func.isRequired,
  saveAction: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default EditTemplateModal;
