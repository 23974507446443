import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import { GA_APPLICATION_CODE } from 'Common/Helpers/GoogleAnalyticsHelper';
import { Navigate } from 'react-router-dom';

import { User } from 'Common/Helpers/AuthHelper';
import Menu from 'Components/Shared/Menu';
import { getDefaultPath } from 'Utils/helper';
import { trackUserProperties } from 'Utils/analytics';

import './PrivatePages.scss';
import FeaturesModal from 'Components/Organisms/FeaturesModal';
import { roleConsts } from 'Utils/consts';
import LoginExpiredModal from 'Components/Molecules/LoginExpiredModal';
import MacroAreaUserModal from 'Components/Molecules/MacroAreaUserModal';

function PrivatePages({
  component: Component,
  logged,
  menu,
  settings,
  endMenu,
  currentScreen,
  selectMenu,
  idScreen,
  changePageScreen,
  permittedProfiles,
  checkAuth,
  loadPage = () => {},
  ...rest
}) {
  useEffect(() => {
    changePageScreen(idScreen);
  }, []);

  const countPageView = (props) => {
    const { location } = props;
    const detailsPage = '/detalhes';
    const pathname = location.pathname && location.pathname.includes(detailsPage) ? detailsPage : location.pathname;
    if (GA_APPLICATION_CODE) ReactGA.send({ hitType: 'pageview', page: pathname });
  };

  const RenderComponent = (props) => {
    checkAuth();
    const { location } = props;
    if (!logged && !User.hasProfile(roleConsts.employees)) {
      return <Navigate to={{ pathname: '/login' }} state={{ from: location }} />;
    }
    if (!logged && User.hasProfile(roleConsts.employees)) {
      return <Navigate to={{ pathname: '/login/empregados' }} state={{ from: location }} />;
    }

    trackUserProperties();
    countPageView(props);

    if (User.hasOneOfProfiles(permittedProfiles)) {
      const isCLient = User.hasOneOfProfiles([roleConsts.employees, roleConsts.client]);
      return (
        <div className="private-pages">
          <div className="container-fluid">
            <div className={`private-pages-content ${isCLient ? 'client-content' : ''}`}>
              <div className={`private-pages-content-menu ${isCLient ? 'client-menu' : ''}`}>
                <Menu
                  items={menu}
                  endMenu={endMenu}
                  settingsOptions={settings}
                  currentScreen={currentScreen}
                  selectMenu={(menuId) => {
                    changePageScreen(menuId);
                    selectMenu(menuId);
                  }}
                />
              </div>
              <div className="private-pages-content-components">
                <Component {...props} />
              </div>
              <FeaturesModal />
              <LoginExpiredModal />
              <MacroAreaUserModal />
            </div>
          </div>
        </div>
      );
    }

    return <Navigate to={getDefaultPath()} state={{ from: location }} />;
  };

  return <RenderComponent loadPage={loadPage} {...rest} />;
}

PrivatePages.propTypes = {
  component: PropTypes.elementType.isRequired,
  logged: PropTypes.bool.isRequired,
  menu: PropTypes.array.isRequired,
  settings: PropTypes.array.isRequired,
  endMenu: PropTypes.array.isRequired,
  currentScreen: PropTypes.string.isRequired,
  selectMenu: PropTypes.func.isRequired,
  idScreen: PropTypes.string.isRequired,
  loadPage: PropTypes.func,
  checkAuth: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }).isRequired,
  changePageScreen: PropTypes.func.isRequired,
  permittedProfiles: PropTypes.array.isRequired,
};

export default PrivatePages;
