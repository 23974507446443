import React from 'react';
import PropTypes from 'prop-types';

import { capitalizeText } from 'Utils/stringUtils';

import './InfoText.scss';

function InfoText({ label, text, style = { width: '153px' }, textProps = {}, labelProps = {} }) {
  return (
    <div title={capitalizeText(text)} className="info-box" style={style}>
      <p className="text" {...textProps}>
        {text}
      </p>
      <p className="label" {...labelProps}>
        {label}
      </p>
    </div>
  );
}

InfoText.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  style: PropTypes.object,
  textProps: PropTypes.object,
  labelProps: PropTypes.object,
};

export default InfoText;
