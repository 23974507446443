import { connect } from 'react-redux';
import TimelineItemMobile from './TimelineItemMobile';

const mapStateToProps = (state) => {
  const { orderDetail } = state;
  return { selectedIndustrialOrder: orderDetail.selectedIndustrialOrder };
};

export default connect(
  mapStateToProps,
  null,
)(TimelineItemMobile);
