import React from 'react';
import PropTypes from 'prop-types';
import BaseModal from 'Components/Atoms/BaseModal/BaseModal';
import langValues from 'Config/i18n/index';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';
import './LoginExpiredModal.scss';

const LoginExpiredModal = ({ isExpiredModalOpen, loadLogout }) => (
  <BaseModal open={isExpiredModalOpen}>
    <div className="loginExpired-modal">
      <h5>
        {langValues.loginExpiredModal.sessionExpiredTitle}
      </h5>
      <span className="loginExpired-message font-body2">
        {langValues.loginExpiredModal.sessionaExpiredMessage}
      </span>
      <div className="loginExpired-button">
        <MaterialButton
          type="primary"
          onClick={() => loadLogout()}
          buttonText={langValues.loginExpiredModal.loginExpiredLogout}
          variant="contained"
        />
      </div>
    </div>
  </BaseModal>
);

LoginExpiredModal.propTypes = {
  isExpiredModalOpen: PropTypes.bool.isRequired,
  loadLogout: PropTypes.func.isRequired,
};

export default LoginExpiredModal;
