import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from 'tss-react/mui';
import { ReactComponent as HelpIcon } from 'Common/Assets/helpIcon.svg';
import PropTypes from 'prop-types';

import './HelpIconTooltip.scss';

const StyledTooltip = withStyles(Tooltip, () => ({
  tooltip: {
    backgroundColor: 'white',
    maxWidth: '250px',
    border: '1px solid #E5EAEE',
    borderRadius: '3px',
    padding: '16px',
    boxShadow: '4px 2px 12px -15px rgba(0, 0, 0, 0.08)',
  },
}));

const HelpIconTooltip = ({ tooltipText }) => {
  const tooltipTitle = (
    <div className="tooltipWrapper">
      <p className="font-small2">{tooltipText}</p>
    </div>
  );

  return (
    <StyledTooltip title={tooltipTitle} placement="top-start">
      <div className="helpIcon-div">
        <HelpIcon className="helpIcon" />
      </div>
    </StyledTooltip>
  );
};

HelpIconTooltip.propTypes = {
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default HelpIconTooltip;
