import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ReactComponent as CloseIcon } from 'Common/Assets/closeIcon.svg';
import SortingIcon from 'Components/Atoms/SortingIcon/SortingIcon';
import './SearchDropdown.scss';

const SearchDropdown = ({
  entries,
  placement = 'bottom-start',
  popperOverflow = true,
  filtered,
  handleTextChange,
  handleItemSelection,
  searchText,
  handleClearSelection,
  placeholder,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const divRef = useRef();
  function handleOpenFilterMenu() {
    setAnchorEl(divRef.current);
  }

  const renderContent = (entry) => {
    const buttonName = entry.isSelected ? 'filterItem selected' : 'filterItem';
    return (
      <div className="filterOption">
        <button
          type="button"
          onClick={() => {
            handleItemSelection(entry.value);
            handleTextChange(entry.label);
            setAnchorEl(null);
          }}
          className={buttonName}
        >
          <span>{entry.label}</span>
        </button>
      </div>
    );
  };

  const content = () => (
    <div className="searchDropDownContent">{Object(entries).map((entry) => renderContent(entry))}</div>
  );

  function handleInputChange(e) {
    handleTextChange(e.target.value);
    if (!anchorEl) {
      handleOpenFilterMenu();
    }
  }

  function handleEnter(e) {
    const enter = 'Enter';
    if (e.key === enter) {
      handleTextChange(entries[0].label);
      handleItemSelection(entries[0].value);
      setAnchorEl(null);
    }
  }

  const renderRightIcon = () => {
    if (filtered) {
      return (
        <CloseIcon
          className="closeIcon"
          onClick={() => {
            handleClearSelection();
            handleTextChange('');
          }}
        />
      );
    }
    return (
      <SortingIcon
        isSortingIconClicked={Boolean(anchorEl)}
        sortingOnClick={anchorEl ? () => setAnchorEl(null) : () => handleOpenFilterMenu()}
      />
    );
  };

  return (
    <div ref={divRef}>
      <div className="searchDropdown">
        <input
          type="text"
          onClick={() => handleOpenFilterMenu()}
          onChange={handleInputChange}
          onKeyPress={handleEnter}
          value={searchText}
          placeholder={placeholder}
        />
        {renderRightIcon()}
      </div>
      <Popper
        id="simple-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        sx={{ left: '380px' }}
        placement={placement}
        disablePortal={false}
        modifiers={[
          popperOverflow
            ? {
                name: 'preventOverflow',
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: true,
                  tether: true,
                  rootBoundary: 'document',
                  padding: 8,
                },
              }
            : {},
        ]}
      >
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <article>{content()}</article>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

SearchDropdown.propTypes = {
  placement: PropTypes.string,
  popperOverflow: PropTypes.bool,
  entries: PropTypes.array.isRequired,
  filtered: PropTypes.bool,
  handleTextChange: PropTypes.func.isRequired,
  handleItemSelection: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  handleClearSelection: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default SearchDropdown;
