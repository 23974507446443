import React from 'react';
import PropTypes from 'prop-types';
import langValues from 'Config/i18n/index';
import OrderInformationItem from 'Components/Atoms/OrderInformationItem/index';
import DealerInformation from 'Components/Atoms/DealerInformation/DealerInformation';
import ToggleEnvironment from 'Components/Atoms/ToggleEnvironment';
import CarDetailImage from 'Components/Atoms/CarDetailImage/CarDetailImage';
import { safeToLowerCase, safeToUpperCase } from 'Utils/safeMethods';
import { getDateAsNumbers } from 'Utils/dateUtils';
import { environment } from 'Utils/consts';

import './OrderInformationCardDetail.scss';

const optionalsList = (optionals) => {
  let string = '-';
  if (optionals && optionals.length > 0) {
    string = '';
    optionals.forEach((optional) => {
      const optionalText = `${optional.optionalId} - ${optional.description}\n`;
      string += optionalText;
    });
  }
  return string;
};

const modelAndManufactureYear = (modelYear, manufactureYear) => {
  const modelYearText = modelYear ? safeToLowerCase(modelYear.toString()) : ' - ';
  const manufactureYearText = manufactureYear ? safeToLowerCase(manufactureYear.toString()) : ' - ';
  return `${manufactureYearText}/${modelYearText}`;
};

const OrderInformationCardDetail = ({ order, industrialOrder }) => (
  <section className="orderInformationCard-details">
    <div className="details-dealers">
      <h5>{langValues.order}</h5>

      <OrderInformationItem
        text={getDateAsNumbers(order.idealDate, true)}
        label={langValues.labelsPedidoComercial.concessionariaEntregaInicial}
      />

      <DealerInformation
        dealerTypeLabel={langValues.labelsPedidoComercial.concessionariaPedido}
        dealerId={order.sellerDealer.id}
        dealerName={order.sellerDealer.name}
        dealerCity={order.sellerDealer.county}
        dealerState={order.sellerDealer.uf}
      />
      <ToggleEnvironment env={environment.BR}>
        <DealerInformation
          dealerTypeLabel={langValues.labelsPedidoComercial.concessionariaEntrega}
          dealerId={order.deliveryDealer.id}
          dealerName={order.deliveryDealer.name}
          dealerCity={order.deliveryDealer.county}
          dealerState={order.deliveryDealer.uf}
        />
      </ToggleEnvironment>
    </div>
    <div className="details-vehicle">
      <h5>{langValues.orderInformation.vehicle}</h5>
      <div className="details-vehicle-items">
        <div>
          <OrderInformationItem
            text={safeToLowerCase(industrialOrder.modelName)}
            label={langValues.orderInformation.model}
          />
          <OrderInformationItem
            text={modelAndManufactureYear(industrialOrder.modelYear, industrialOrder.manufactureYear)}
            label={langValues.orderInformation.modelAndManufactureYear}
          />
          <OrderInformationItem
            text={safeToUpperCase(industrialOrder.versionName)}
            label={langValues.orderInformation.version}
          />
        </div>
        <div>
          <OrderInformationItem
            text={safeToLowerCase(industrialOrder.externalColorDescription)}
            label={langValues.orderInformation.externalColor}
          />
          <OrderInformationItem
            text={safeToLowerCase(industrialOrder.internalColorDescription)}
            label={langValues.orderInformation.coating}
          />
        </div>
        <div>
          <OrderInformationItem
            text={optionalsList(industrialOrder.optionals)}
            label={langValues.orderInformation.optionals}
          />
        </div>
      </div>
    </div>
    <div className="details-vehicleImage">
      <CarDetailImage industrialOrder={industrialOrder} />
    </div>
  </section>
);

OrderInformationCardDetail.propTypes = {
  order: PropTypes.object.isRequired,
  industrialOrder: PropTypes.object.isRequired,
};

export default OrderInformationCardDetail;
