import { connect } from 'react-redux';
import { loadLastSystemUpdate } from 'Infrastructure/Ducks/SystemUpdateDuck';
import SystemUpdate from './SystemUpdate';

const mapStateToProps = (state) => {
  const { lastSystemUpdate } = state.systemUpdate;
  return { lastSystemUpdate };
};

const mapDispatchToProps = dispatch => ({
  loadLastSystemUpdate: () => dispatch(loadLastSystemUpdate()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemUpdate);
