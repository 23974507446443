import React from 'react';

export default {
  totalVisibleOrders: (totalVisible, total) => `Exibindo ${totalVisible} de ${total || '-'} pedidos`,
  popoverDateText: (lastSeenDate, date) => (lastSeenDate ? `Atualizada em ${date}` : 'Data de atualização indisponível'),
  all: 'Todos',
  no: 'Não',
  yes: 'Sim',
  wait: 'Aguarde.',
  back: 'Voltar',
  days: 'dias',
  day: 'dia',
  attention: 'Atenção',
  save: 'Salvar',
  edit: 'Editar',
  discard: 'Descartar',
  invoiced: 'Faturados',
  notInvoiced: 'Não faturados',
  noOrdersFound: 'Nenhum pedido encontrado.',
  noResults: 'Sem resultados',
  noResultsFound: 'Nenhum resultado encontrado.',
  deliveredOnTime: 'Entregues em dia',
  deliveredLate: 'Entregues em atraso',
  noMessage: 'Sem mensagem',
  dealer: 'Concessionária',
  salesEmployees: 'Vendas a Empregados',
  screenTitle: 'Pedidos',
  loadMore: 'Carregar mais',
  cancel: 'Cancelar',
  delete: 'Deletar',
  clear: 'Limpar',
  clearSelection: 'Limpar seleção',
  apply: 'Aplicar',
  filters: 'Filtros',
  optionals: 'Opcionais',
  selectModel: 'Selecione um modelo',
  brands: 'Marcas',
  brand: 'Marca',
  model: 'Modelo',
  version: 'Versão',
  code: 'Código',
  order: 'Pedido',
  origin: 'Origem',
  numOrders: 'Nº de Pedidos',
  modelVersion: 'Modelo e versão',
  modelsVersions: 'Modelos e versões',
  configurations: 'Configurações',
  parameters: 'Parâmetros',
  macroAreas: 'Macro Áreas',
  macroCategories: 'Macro Categorias',
  orderExchangeHistory: 'Histórico de troca de pedidos',
  reloadPage: 'Recarregar página',
  commonQuestions: 'FAQ - Perguntas Frequentes',
  lastUpdate: 'Última atualização: ',
  lastUpdates: 'Últimas Atualizações',
  as: 'às',
  saveChanges: 'Salvar alterações',
  feedback: 'Feedback',
  confirm: 'Confirmar',
  export: 'Exportar',
  exportAsExcel: 'Exportar como Excel (.xlsx)',
  exportAsCsv: 'Exportar como CSV (.csv)',
  unavailableDate: 'Data indisponível',
  noForeseen: 'Sem previsão',
  insertError: 'Erro ao inserir',
  notIdentified: 'Não identificado',
  unavailableData: 'Dados Indisponíveis',
  loadErrorMessage: 'Algo deu errado ao carregar as informações.\nPor favor, tente novamente.',
  renderError: 'Houve um erro ao carregar esta página. Por favor, tente atualizar a página ou relogar.',
  errorBoundaryTitle: 'Opa... Algo deu errado',
  errorBoundaryText: 'Não foi possível acessar a página solicitada. Você pode experimentar relogar em sua conta ou recarregar a página.',
  notFoundIndustrialOrdersForFilters: 'Não foram encontrados pedidos industriais para os filtros aplicados',
  modelVersionForecast: 'Prazos Médios',
  permissions: 'Permissões',
  download: 'Download',
  upload: 'Upload',
  industrial: 'Industrial',
  commercial: 'Comercial',
  date: 'Data',
  statusOrder: 'Status Etapa',

  city: 'CIDADE',
  federatedUnit: 'UF',
  shipping: 'FROTA',
  shippingDetail: 'DETALHES FROTA',

  frequencyLabels: {
    diary: 'Diário',
    weekly: 'Semanal',
    monthly: 'Mensal',
  },

  months: [
    'jan',
    'fev',
    'mar',
    'abr',
    'mai',
    'jun',
    'jul',
    'ago',
    'set',
    'out',
    'nov',
    'dez',
  ],
  fullMonths: [
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'julho',
    'agosto',
    'setembro',
    'outubro',
    'novembro',
    'dezembro',
  ],

  dateLabels: {
    invalidDate: 'Formato de data inválido',
  },

  versionModal: {
    add: 'Adicionar versão',
    edit: 'Editar versão',
    version: 'Versão',
  },

  modelModal: {
    add: 'Adicionar modelo',
    edit: 'Editar modelo',
  },

  legalTerm: 'As datas apresentadas são meras previsões e não refletem o caso concreto, não podendo, dessa forma, serem consideradas como compromissos. As datas previstas sofrerão alterações de acordo com o cumprimento de cada etapa. Sistema de uso exclusivo da Concessionária, e não pode ser compartilhado com terceiros.',
  clientLegalTerm: 'As datas apresentadas são meras previsões e não refletem o caso concreto, não podendo, dessa forma, serem consideradas como compromissos. As datas previstas sofrerão alterações de acordo com o cumprimento de cada etapa.',
  employeePriceTable: 'Tabela de prazo médio de produção:',
  employeeSite: 'Portal do Empregado',
  employeeSiteLink: 'http://svf.fiat.com.br/CapaVendasFuncionarios/IFrame.aspx?codRegra=30',

  carSalesLabel: 'Car Vendas',
  carSalesPhone: '+55 (31) 2123-8500',
  carSalesSubText: 'Atendimento exclusivo por Whatsapp',
  callCenterEmployeePhone: '+55 (31) 2123-8200',

  parametros: {
    codModel: 'Cod. Modelo',
    newModel: 'NOVO MODELO',
    headerText: [
      'Modelo',
      'Origem',
    ],
  },

  modelVersionLabels: {
    headerItems: [
      { label: 'Modelo' },
      { label: 'Versão' },
      { label: 'Prazos Realizados' },
      { label: 'Expectativa de Faturamento' },
    ],
  },

  forecastLabels: {
    invoiceForecast: 'Faturamento',
    transitTimeForecast: 'Expedição - Trânsito',
    transitTimeWarning: 'O prazo apresentado não inclui o prazo de entrega da nota e composição da carga.',
    titleSnackbarErrorUpload: 'Erro ao carregar arquivo. ',
    titleSnackbarErrorDownload: 'Erro ao exportar planilha de prazos. Tente fazer o download novamente.',
  },

  modelVersionSideBarTextCustomercare: {
    title: 'Entenda como funciona',
    content: [
      {
        subTitle: 'O que são os prazos realizados e expectativas de faturamento? ',
        // eslint-disable-next-line max-len
        text: <span>Os prazos <strong><i>Realizados</i></strong> são médias de quanto tempo os pedidos anteriores levaram para finalizar todas as etapas até o faturamento. Já as <strong><i>Expectativas de faturamento</i></strong> são as previsões que são fornecidas ao dealer, através de dados imputados na tela de Prazos Médios.</span>,
      },
      {
        subTitle: 'O que é a expectativa de faturamento?',
        text: 'É o prazo previsto que o pedido do cliente levará para ser faturado a partir de sua inserção no sistema da Stellantis, seja através de Vendas Diretas ou de pedidos de varejo com OCF.',
      },
      {
        subTitle: 'Como é calculada a expectativa de faturamento?',
        text: 'Os prazos de faturamento são calculados frequentemente, levando em consideração a capacidade produtiva da Stellantis e a carteira de pedidos. Com isso, sabemos quando conseguiremos atender novos pedidos.',
      },
      {
        subTitle: 'Qual a taxa de atualização e duração da expectativa de faturamento?',
        text: 'Os prazos de faturamento são calculados, disponibilizados e atualizados semanalmente.',
      },
    ],
  },

  modelVersionSideBarText: {
    title: 'Entenda como funciona',
    content: [
      {
        subTitle: 'O que é a expectativa de faturamento?',
        text: 'É o prazo previsto que o pedido do cliente levará para ser faturado a partir de sua inserção no sistema da Stellantis, seja através de Vendas Diretas ou de pedidos de varejo com OCF.',
      },
      {
        subTitle: 'Como é calculada a expectativa de faturamento?',
        text: 'Os prazos de faturamento são calculados frequentemente, levando em consideração a capacidade produtiva da Stellantis e a carteira de pedidos. Com isso, sabemos quando conseguiremos atender novos pedidos.',
      },
      {
        subTitle: 'Qual a taxa de atualização e duração da expectativa de faturamento?',
        text: 'Os prazos de faturamento são calculados, disponibilizados e atualizados semanalmente.',
      },
      {
        subTitle: 'Importante: transit time',
        text: 'Além do prazo de faturamento, deve ser levado em consideração o transit time da fábrica até a sua concessionária. O transit time é contabilizado a partir da data de expedição do veículo na fábrica.',
      },
    ],
  },

  regionCitiesSideBarText: {
    title: 'Entenda como funciona',
    subtitle: 'A tabela ao lado representa o tempo médio de Transit Time até a concessionária de acordo com a origem do veículo. Para calcular o tempo total de entrega do veículo após o faturamento é necessário somar os tempos de Entrega da NF-e, Expedição e Trânsito conforme origem.',
    content: [
      {
        subTitle: 'O que é o prazo de entrega da NF-e?',
        text: 'É uma etapa com duração média de 1 dia útil que tem como objetivo levar a nota fiscal dos veículos faturados para a central de distribuição para liberação da expedição.',
      },
      {
        subTitle: 'O que é o prazo de expedição?',
        text: 'É o tempo que o veículo leva para ser expedido na fábrica após entrega da nota fiscal. O prazo de expedição de veículos nacionais é de até 6 dias úteis e de importados até 8. Esse tempo é utilizado para trâmites internos, como validação de notas fiscais e composição de carga.',
      },
      {
        subTitle: 'O que é o Transit Time?',
        text: (
          <>
            É o tempo efetivo de transporte da origem até a concessionária de entrega.
            Cada modelo possui uma origem definida, sendo:
            <ul>
              <li><b>Planta-Betim:</b> modelos FIAT, exceto Toro;</li>
              <li><b>Planta-Goiana:</b> modelos JEEP e Toro;</li>
              <li><b>Pátio-Juiz de Fora:</b> modelos importados.</li>
            </ul>
          </>),
      },
    ],
  },

  parametersModelsVersions: {
    originHelpText: 'Origem de distribuição do modelo',
  },

  orderManagementLabels: {
    headerItems: [
      {
        label: 'Industrial',
        helpText: 'O atraso do industrial é calculado a partir da somatória dos prazos médios de cada etapa.',
        sorting: 'orderByLateDays',
      },
      { label: 'Chassi' },
      { label: 'Modelo' },
      {
        label: 'Entrega Inicial',
        helpText: 'Entrega prevista a partir da data de criação do pedido.',
        sorting: 'orderByIdealDeliveryDate',
      },
      {
        label: 'Status Etapa',
        helpText: 'Atraso calculado a partir de prazos médios por etapa. Os prazos médios variam de acordo com Modelos e Versões.',
        sorting: 'orderByStepStatus',
      },
      { label: 'Pátio' },
    ],
    headerItemsHistoric: [
      {
        label: 'Industrial',
        helpText: 'Número Pedido Industrial',
        sorting: 'orderByLateDays',
      },
      {
        label: 'Comercial',
        helpText: 'Número Pedido Comercial',
      },
      { label: 'MVS' },
      { label: 'Chassi' },
      {
        label: 'Data',
        helpText: 'Data da inclusão do Log',
      },
      {
        label: 'Etapa',
        helpText: 'Ultima etapa do Pedido antes do cancelamento.',
      },

    ],
  },

  invoiceLabels: {
    termsAgree: 'Declaro que li e concordo com os termos de utilização.',
    legalText: 'Ao fazer o download deste arquivo, é necessário ter ciência de que é expressamente proibido o emplacamento ou financiamento prévio de qualquer veículo, antes que o mesmo esteja fisicamente na concessionária, sendo de sua inteira responsabilidade o uso correto desta Nota Fiscal.\n\n*Ao baixar a Nota Fiscal Eletrônica, serão coletados e reservados os dados deste usuário para uso exclusivo do registro de download, e nada além.',
    employeeLegalText: 'Ao fazer o download deste arquivo, é necessário ter ciência de que é expressamente proibido o emplacamento prévio de qualquer veículo antes que o mesmo esteja fisicamente na concessionária, sendo de sua inteira responsabilidade o uso correto desta Nota Fiscal.',
    downloadSuccessTitle: 'Sucesso ao buscar nota fiscal',
    downloadSuccessText: 'Seu download deve iniciar em breve',
    downloadErrorTitle: 'Algo deu errado ao gerar sua nota fiscal',
    downloadErrorText: 'Por favor, atualize a página e tente novamente',
    invoiceNotFoundTitle: 'A nota fiscal ainda não está disponível',
    invoiceNotFoundText: 'Por favor, tente novamente mais tarde',
    downloadLoadingTitle: 'Aguarde',
    downloadLoadingText: 'A nota fiscal está sendo gerada',
    readyToDownload: 'Sua nota fiscal está disponível para download.',
    downloadInvoice: 'Baixar Nota Fiscal',
    downloadNfe: 'Baixar NFe',
  },

  dealerLabels: {
    detailsNotIdentified: 'Detalhes da concessionária não identificados',
    goToWebsite: 'Ir para site da concessionária',
    goToMaps: 'Abrir localização no Google Maps',
    goToPhone: 'Ligar para concessionária',
  },

  clientCategoryLabels: {
    notIdentified: 'Categoria não identificada',
    byId: {
      0: 'Administração Pública Federal',
      1: 'Varejo',
      2: 'Administração Pública Estadual',
      3: 'Administração Pública Municipal',
      5: 'Franqueados Unidas',
      7: 'Franqueados Hertz',
      9: 'Sorteio/Premiação',
      11: 'Fornecedor Fiasa',
      13: 'Empresa Coligada',
      15: 'Diplomata/Embaixada',
      16: 'Fornecedores',
      18: 'Franqueados Localiza',
      23: 'Doação',
      27: 'Concessionárias do Grupo Stellantis ou CNHI',
      28: 'Dealer',
      31: 'Taxistas sem Isenção',
      32: 'Localiza/Total Fleet',
      34: 'Taxistas com Isenção',
      35: 'Auto Escola',
      37: 'Empresas Fumageiras',
      40: 'Motorista de Aplicativo',
      41: 'Mais Alimentos',
      45: 'Funcionário Itaú/Bradesco/Santander',
      50: 'PCD',
      51: 'Produtor Rural',
      54: 'Locadora Abla',
      61: 'Locadora',
      65: 'Frotistas',
      66: 'Agropecuárias',
      67: 'Ducato',
    },
  },

  telaPedidosLabels: {
    screenTitle: 'Pedidos',
    botaoCarregarMais: 'Carregar mais',
    listPedidosHeadersTitle: [
      'Tipo',
      'Categoria',
      'Pedido',
      'Nome Cliente',
      'Entrega Atual',
      'Status',
      'Pendências',
      'Nº pedidos',
    ],
    listPedidosHeadersTitleAhorro: [
      'Tipo',
      'Solicitação',
      'Grupo',
      'Pedido',
      'Nome Cliente',
      'Pedido comercial',
    ],
    listPedidosHeadersTooltip: [
      '',
      '',
      '',
      '',
      { upperText: 'Prazo calculado com base nas informações atualizadas da montadora.', lowerText: 'Prazo pode variar.' },
      '',
      '',
      '',
    ],
    errorCarregarLista: 'Ocorreu algum erro ao carregar a lista de pedidos. Por favor atualize a página.',
    carregarLista: 'Atualizar página',
    listTitle: 'Lista de pedidos',

  },

  componenteCpfText: {
    cpfInvalido: 'Cpf inválido',
  },

  listPedidosLabels: {
    atendimento: 'Atendimento',
    atendimentos: 'Atendimentos',
    emDia: 'Em dia',
    diasAtrasados: 'dias atrasados',
    atrasado: 'Atrasado',
    diaAtrasado: 'dia atrasado',
    emRisco: 'Risco de Atraso',
    pedidos: 'Pedidos',
    pedido: 'Pedido',
    delivered: 'Entregue',
    late: 'Atrasado',
    inRisk: 'Risco de Atraso',
    withoutOrder: 'Sem pedido industrial',
    noOrder: 'Nenhum pedido',
    noForecast: 'Sem previsão',
    ongoing: 'Em curso',
  },

  downloadButton: {
    titleSnackbarErrorDownload: 'Erro ao exportar planilha. Tente fazer o download novamente.',
    buttonTextError: 'Fechar',
  },

  uploadButton: {
    buttonTextError: 'Fechar',
    titleSnackbarSuccess: 'Planilha importada com sucesso.',
    titleSnackbarErrorUpload: 'Erro ao carregar arquivo. ',
  },

  managementPage: {
    title: 'Acompanhamento de Atrasos',
    emptyResult: 'Nenhum pedido para exibir.',
  },

  delayManagementDetails: {
    industrialOrderTypeText: 'Pedido Industrial',
    orderTitle: 'Comercial',
    dealerTitle: 'Concessionária',
    middleDot: ' \u00B7 ',
    lateDealertitle: 'Entregando à Concessionária',
    forecastDelivery: ' Previsão:',
    insertedStepLate: 'Etapa entrou em atraso: ',
    reasonLateTitle: 'Possível motivo de atraso:',
    qualityBlockText: 'Atraso por bloqueio de qualidade',
    reasonLateText: 'Falta de preenchimento da garantia ou emplacamento',
    returnEmpty: '-',
  },

  telaHomeLabels: {
    screenTitle: 'Home',
  },

  componentBuscarLabels: {
    placeholder: 'Pesquisar cliente, CPF, CNPJ, chassi ou pedido.',
    placeholderDelay: 'Pesquisar cliente, CPF, CNPJ, chassi ou pedido industrial.',
    placeholderHistoric: 'Pesquisar pedido industrial ou comercial.',
  },

  loginTexts: {
    systemDescription: 'Sistema de acompanhamento de pedidos',
    loginTitle: 'Faça login utilizando seu login de rede.',
    submitButton: 'ACESSAR',
    consultButton: 'CONSULTAR',
    welcomeText: 'Seja bem-vindo(a)\nao Easy Tracking',
    aboutSystemTitle: 'Sobre o Easy Tracking',
    aboutSystemText: () => <p>{'Alinhados às estratégias de'} <strong>transformação digital</strong> {'da Stellantis e visando a'} <strong>excelência na satisfação dos nossos clientes</strong>, {'compartilhamos em primeira mão, a ferramenta Easy Tracking.\n\nDe uso exclusivo para os clientes de Vendas a Empregados, será possível rastrear o pedido do 0km, passando por'} <strong>previsões de conclusão</strong> {'de todas as fases do processo de produção, faturamento, expedição, até à chegada na concessionária.\n\nPara garantir a segurança dos dados, por favor, insira o UserID e Senha de Rede associados à sua conta. Além disso, informe o número do CPF do comprador para acessar as informações do pedido.'}</p>,
    aboutSystemResumed: () => <p>{'Alinhados às estratégias de'} <strong>transformação digital</strong> {'da Stellantis e visando a'} <strong>excelência na satisfação dos nossos clientes</strong>, {'compartilhamos em primeira mão, a ferramenta Easy Tracking.\n\nDe uso exclusivo para os clientes de Vendas a Empregados, será possível rastrear o pedido do 0km, passando por'} <strong>previsões de conclusão</strong> {'de todas as fases do processo de produção, faturamento, expedição, até à chegada na concessionária.'}</p>,
    trackYourOrder: 'Acompanhe seu pedido online',
  },

  telaLogin: {
    loginInvalido: 'Login/senha inválido(s).',
    sessaoExpirada: 'Sua sessão expirou. Faça login novamente.',
    erroResetPassword: 'Algo deu errado. Confira seu usuário e senha ou abra um chamado no Drive IT.',
    erroLoginSenhaIncorretos: 'Algo deu errado. Confira seu usuário e senha.',
    erroAcessoNegado: 'Acesso negado. Por favor, entre em contato com seu gestor de treinamento.',
    erroInterno: 'Algo deu errado. Por favor, tente novamente mais tarde.',
    cpfNaoEncontrado: 'Nenhum pedido foi encontrado para o usuário.',
    clientBadRequest: 'Algo deu errado. Confira o preenchimento dos dados e tente novamente. Caso persista o erro, entre em contato com a Concessionária de compra.',
    googleRecaptcha: 'Por favor, preencha o captcha para prosseguir.',
    radioCustomerCare: 'Stellantis',
    radioDealer: 'Concessionária',
    radioClient: 'Cliente',
    radioEmployee: 'Empregado',
  },

  userEntry: {
    login: 'Digite seu UserID',
    loginDealer: 'Digite seu Email',
    password: 'Senha',
    forgotPassword: 'Esqueci minha senha',
    customercareLogin: 'Digite seu CPF',
    clientLogin: 'CPF ou CNPJ',
    clientLoginOrder: 'Número do pedido',
    employeesLogin: 'Digite seu CPF',
    sessionExpired: 'Sua sessão expirou.\nFaça login novamente.',
    dealerCredentialMessage: 'Faça login com suas credenciais do My Stellantis Training.',
    employeeCredentialMessage: 'Acompanhe online o andamento do seu pedido.',
    clientCredentialMessage: 'Acompanhe online o andamento do seu pedido.',
    redirectMessages: {
      title: 'Aviso de Redirecionamento',
      subtitle: 'Para solicitar uma nova senha, você será redirecionado ao painel de login do My Stellantis Training. Caso não seja suficiente, entre em contato com seu Analista de Qualidade.',
    },
  },

  telaDetalhesPedidos: {
    inRisk: 'CONCLUIR SEM ATRASO',
    late: 'ATRASO NA ETAPA',
    estimatedTimeToStep: 'Tempo previsto para etapa',
    stepStatus: {
      done: 'Concluído',
      late: 'Previsto',
      risk: 'Previsto',
      current: 'Previsto',
      empty: 'Previsto',
    },
    returnToList: 'Voltar à listagem',
    seeMore: 'Ver Mais',
    hide: 'Ocultar',
  },

  stepNames: {
    pedido: 'Pedido',
    impostacao: 'configuration',
    montagem: 'assembly',
    produzido: 'produced',
    faturado: 'invoice',
    entregaNota: 'invoiceDelivery',
    expedicao: 'dispatch',
    concessionaria: 'dealer',
    entrega: 'delivered',
    refaturamento: 'refactored',
    pagamento: 'payment',
  },

  labelsPedidoComercial: {
    yourOrders: 'Seus Pedidos',
    titulo: 'Pedido',
    tituloDealer: 'Pedido',
    codigoPedido: '#',
    dataPedido: 'Pedido criado em ',
    concessionariaEntrega: 'Concessionária de Entrega',
    concessionariaPedido: 'Concessionária do Pedido',
    atrasoPedido: 'Motivos de atraso no pedido',
    pendConc: 'pendConc',
    pendCli: 'pendCli',
    codigo: 'cod.',
    middleDot: ' \u00B7 ',
    salesCommission: 'Comissão',
    discount: 'Desconto',
    concessionariaEntregaInicial: 'Entrega inicial',
    contateConcessionaria: 'Em caso de dúvidas, favor acionar a Concessionária da compra.',
  },

  labelsPedidoIndustrial: {
    modelo: 'Modelo',
    ano: 'Ano',
    versao: 'Versão',
    cor: 'Cor Externa',
    revestimento: 'Revestimento',
    grupoOpcionais: 'Grupo de Opcionais',
    opcionais: 'Opcionais',
    chassi: 'Chassi',
    patio: 'Pátio',
  },

  labelsRequestResume: {
    late: 'Atraso',
    risk: 'Risco de Atraso',
    beTimes: 'Em Dia',
    pendencyDocs: 'Pendência de Documentação',
    blockDealer: 'Concessionária Bloqueada',
    orderSwap: 'Troca de Pedido',
    blockClient: 'Pendência Financeira',
    pedidos: 'pedidos',
  },

  labelsTransitTime: {
    destinyTitle: 'Destinos',
    destinations: ['Patio-Juiz de Fora', 'Planta-Betim', 'Planta-Goiana', 'Planta-Porto Real'],
    brands: ['FCA', 'CITROEN/DS', 'PEUGEOT'],
    keys: ['juizDeFora', 'betim', 'goiana', 'real'],
    filterCity: 'Filtrar município',
    saveModalTitle: 'Salvar alterações',
    saveModalText: (
      <>Suas alterações somente serão aplicadas no próximo dia útil.
        <strong> Deseja salvar os novos dados inseridos?</strong>
      </>
    ),
    discardModalTitle: 'Descartar alterações',
    discardModalText: (
      <>Descartar implicará em perder todas as alterações.
        <strong> Deseja descartar?</strong>
      </>
    ),
    error: 'Erro ao salvar as alterações. Tente novamente.',
    success: 'Alterações salvas com sucesso.',
  },

  settingsTransitTimeScreen: {
    errorLoading: 'Erro ao carregar o conteúdo da lista de Transit Time.',
    errorInserting: 'Erro ao inserir um ou mais dados.',
    title: 'Transit Time',
  },

  settingsParametersScreen: {
    stepsAndMacroAreas: 'Etapas e Macro Áreas',
    title: 'Parâmetros',
    saveModalTitle: 'Salvar alterações',
    saveModalText: (
      <>Suas alterações somente serão aplicadas no próximo dia útil.
        <strong> Deseja salvar os novos dados inseridos?</strong>
      </>
    ),
    discardModalTitle: 'Descartar alterações',
    discardModalText: (
      <>Descartar implicará em perder todas as alterações.
        <strong> Deseja descartar?</strong>
      </>
    ),
    orderLogHistory: 'Histórico de Logs Pedidos',
  },

  settingsModelsVersions: {
    modelsAndVersions: 'Modelos e Versões',
    newModel: 'Novo modelo',
    newVersion: 'Nova versão',
    deleteModelTitle: 'Deletar modelo',
    // eslint-disable-next-line max-len
    deleteModelText: <p>Deletar implicará em apagar também todas as versões deste modelo. Não é possível voltar a ação. <b>Deseja deletar?</b></p>,
    deleteVersionTitle: 'Deletar versão',
    // eslint-disable-next-line max-len
    deleteVersionText: <p>Deletar implicará em apagar apenas a versão selecionada. Não é possível voltar a ação. <b>Deseja deletar?</b></p>,
    successDeleteModel: 'Sucesso ao deletar o modelo',
    errorDeleteModel: 'Erro ao deletar o modelo. Por favor tente novamente.',
    successDeleteVersion: 'Sucesso ao deletar a versão',
    errorDeleteVersion: 'Erro ao deletar a versão. Por favor tente novamente.',
    successPutModel: 'Sucesso ao salvar o modelo',
    errorPutModel: 'Erro ao inserir o novo modelo. Por favor tente novamente.',
    successPutVersion: 'Sucesso ao salvar a versão',
    errorPutVersion: 'Erro ao salvar a versão. Por favor tente novamente.',
    fillBrandField: 'Por favor insira a marca do modelo.',
  },

  settingsVisionConfiguration: {
    title: 'Controle de Visibilidade',
    permissionsTooltip: 'Em Permissões, são listadas quais informações são atualmente exibidas para cada modelo e versão.',
    selectedItems: 'itens selecionados',
    selectModelVersion: 'Selecione um modelo ou versão a ser alterada.',
    searchInput: 'Pesquisar modelo, versão ou código',

    sideBar: {
      title: 'Entenda como funciona',
      titleEditing: 'Alterar permissões',
      warning: 'Ativar ou desativar a visibilidade de algum item de um modelo implicará na alteração da visibilidade para todas as suas versões.',
      content: [
        {
          subTitle: 'O que é o controle de visibilidade?',
          text: 'É o controle das permissões à visualização de informações e funcionalidades do Easy Tracking, de acordo com perfis de usuário, Modelos, Versões e tipo de pedido. Com ela, é possível escolher item a item, o que deve ser exibido para cada recorte.',
        },
        {
          subTitle: 'Quais são as permissões disponíveis?',
          // eslint-disable-next-line max-len
          text: <>Atualmente você pode controlar a visibilidade de 3 itens:<ul><li>Datas de conclusão das etapas da timeline;</li><li>Status de atraso e risco de atraso, juntamente com a contagem de dias;</li><li>Previsões de conclusão das etapas da timeline.</li></ul></>,
        },
      ],
      contentApi: [
        {
          subTitle: 'O que é o controle de visibilidade?',
          text: 'É o controle das permissões à visualização de informações e funcionalidades do Easy Tracking, de acordo com perfis de usuário, Modelos, Versões e tipo de pedido. Com ela, é possível escolher item a item, o que deve ser exibido para cada recorte.',
        },
        {
          subTitle: 'Quais são as permissões disponíveis?',
          // eslint-disable-next-line max-len
          text: <>Atualmente você pode controlar a visibilidade de 3 itens:<ul><li>Datas de conclusão das etapas da timeline;</li><li>Status de atraso e risco de atraso, juntamente com a contagem de dias;</li><li>Previsões de conclusão das etapas da timeline.</li><li>Disponibilização dos dados do veículo ao chamador</li></ul></>,
        },
      ],
    },

    submenu: [
      {
        roleText: 'CLIENTE FINAL',
        roleId: 'ROLE_CLIENT',
        isDisabled: false,
      },
      {
        roleText: 'Empregado',
        roleId: 'ROLE_EMPLOYEE',
        isDisabled: false,
      },
      {
        roleText: 'Dealer',
        roleId: 'ROLE_DEALER',
        isDisabled: false,
      },
      {
        roleText: 'Customer Care',
        roleId: 'ROLE_CUSTOMERCARE',
        isDisabled: false,
      },
      {
        roleText: 'API',
        roleId: 'ROLE_API_FULL_ACCESS',
        isDisabled: false,
      },
    ],

    tabs: [
      {
        text: 'Venda direta',
        key: 'directSale',
      },
      {
        text: 'Rede sem OCF',
        key: 'networkWithoutOCF',
      },
      {
        text: 'Rede com OCF',
        key: 'networkWithOCF',
      },
    ],

    rules: {
      concluded: {
        text: 'Datas de conclusão',
        description: 'Exibição das datas de quando etapas foram concluídas.',
      },
      late: {
        text: 'Status de atraso',
        description: 'Exibição dos status de atraso e risco de atraso, juntamente à contagem de dias de cada status.',
      },
      estimated: {
        text: 'Datas previstas',
        description: 'Exibição das datas previstas para conclusão de cada etapa.',
      },
      visible: {
        text: 'Dados do veículo',
        description: 'Disponibilização dos dados do veículo ao chamador.',
      },
      differentRules: {
        text: 'Versões com permissões diferentes',
        description: 'Uma ou mais versões deste modelo possuem permissões diferentes.',
        style: { backgroundColor: 'unset' },
      },
    },
  },

  macroAreaSteps: [
    {
      name: 'Produção',
      steps: [
        'configuration',
        'assembly',
        'produced',
      ],
    },
    {
      name: 'Comercial',
      steps: [
        'invoice',
        'payment',
        'refactored',
      ],
    },
    {
      name: 'Expedição',
      steps: [
        'invoiceDelivery',
        'dispatch',
      ],
    },
    {
      name: 'Dealer',
      steps: [
        'delivered',
      ],
    },
  ],

  snackBarLabels: {
    updateScreen: 'Atualizar Página',
    error: 'Erro ao salvar as alterações. Tente novamente.',
    success: 'Alterações salvas com sucesso.',
  },

  transitTimeLabels: {
    save: 'Salvar alterações',
    cancel: 'Cancelar',
    saveModal: 'Salvar',
    edit: 'Editar',
    modalMessageLight: 'Suas alterações somente serão aplicadas no próximo dia útil.',
    modalMessageBold: 'Deseja salvar os novos dados inseridos?',

    cancelModalMessageLight: 'Cancelar implicará em perder todas as alterações.',
    cancelModalMessageBold: 'Deseja cancelar?',
  },

  unregisteredVersionModal: {
    warningTitleSingular: ' versão não está registrada.',
    warningTitlePlural: ' versões não estão registradas.',
    title: 'Versões não registradas',
  },

  unregisteredDataModal: {
    warningTitleSingular: ' Pendência cadastral.',
    warningTitlePlural: ' Pendências Cadastrais.',
    title: 'Pendências Cadastrais.',
    subtitle: 'Pendências',
    unregisteredDealers: 'Dealers não cadastrados',
    unregisteredModels: 'Modelos não cadastrados',
    unRegistered: ' não cadastrado',
  },

  delayedOrdersComponent: {
    warningTitleSingular: ' Pedido na concessionária',
    warningTitlePlural: ' Pedidos na concessionária',
    title: 'Pedidos na concessionária',
    subTitle: 'Quantidade de veículos "Entregue(s) na Concessionária"',
  },

  parametersTimeLabels: {
    save: 'Salvar alterações',
    cancel: 'Cancelar',
    saveModal: 'Salvar',
    edit: 'Editar',
    newModel: 'Novo Modelo',
    modalMessageLight: 'Suas alterações somente serão aplicadas no próximo dia útil.',
    modalMessageBold: 'Deseja salvar os novos dados inseridos?',

    cancelModalMessageLight: 'Cancelar implicará em perder todas as alterações.',
    cancelModalMessageBold: 'Deseja cancelar?',

    modalMessageWrongData: 'Existe um ou mais registro(s) com nome de modelo e/ou código do modelo inválido(s).',

    error: 'Erro ao salvar as alterações. Tente novamente.',
    success: 'Alterações salvas com sucesso.',

    steps: {
      configuration: 'Impostação',
      assembly: 'Montagem',
      produced: 'Produção',
      invoice: 'Faturamento',
      payment: 'Pagamento',
      invoiceDelivery: 'Entrega da nota',
      dispatch: 'Expedição',
      dealer: 'Entrega à Concessionária',
      refactored: 'Refaturamento',
      delivered: 'Entrega ao Cliente',
    },

    tooltipText: {
      configuration: 'Prazo estimado para a etapa de impostação.',
      assembly: 'Prazo estimado para montagem do modelo.',
      produced: 'Prazo estimado para conclusão de produção do modelo.',
      invoice: 'Prazo estimado para faturamento do modelo.',
      payment: 'Prazo estimado para queda do pagamento do modelo.',
      invoiceDelivery: 'Prazo estimado para entrega da nota do modelo.',
      dispatch: 'Prazo estimado para expedição do modelo.',
      refactored: 'Prazo estimado para refaturamento do modelo.',
      delivered: 'Prazo estimado para entrega do modelo.',
    },
  },

  detailsCommercial: {
    errorLoading: 'Ocorreu algum erro ao carregar os detalhes do pedido comercial',
    noIndustrialOrders: 'Não existe nenhum pedido industrial para esse pedido comercial',
  },

  labelsDashboard: {
    deliveryHistory: 'Histórico de Entregas',
    pedidos: 'Pedidos',
    acessos: 'Acessos',
    acessosOpcao: 'Acessos à plataforma',
    pedidosEntregues: 'PEDIDOS ENTREGUES',
    pedidosEmAndamento: 'PEDIDOS EM ANDAMENTO',
    pedidosIndustriais: 'Pedidos Industriais',
    kpiCsi: 'Pedidos Entregues de VD',
    kpiPorArea: 'KPI por Área',
  },

  labelsDashboardAccessDealer: {
    title: 'Acessos de concessionárias',
    textHelpIcon: 'Percentual de concessionárias de cada regional que acessaram o Easy pelo menos uma vez na semana.',
    errorTitle: 'Nenhum relatório de acesso para exibir.',
  },

  labelsOrderType: {
    commercial: 'COMERCIAL',
    dealer: 'REDE',
    notSold: 'notSold',
    others: 'others',
    directSale: 'directSale',
    dealerKey: 'REDE',
    directSaleKey: 'VD',
    notSoldKey: 'NV',
    othersKey: '',
    directSaleTooltip: 'Pedido Venda Direta',
    dealerTooltip: 'Pedido REDE',
    notSoldToolTip: 'Não vendido',
    othersToolTip: 'Outros',
  },

  orderInformationBlock: {
    dealerPendency: 'Concessionária Bloqueada',
    clientDocumentPendencyFlag: 'PENDOC',
    orderSwapFlag: 'Troca de Pedidos',
    clientFinancialPendencyFlag: 'Pendência Financeira',
    commercialBlockLabel: 'Bloqueio Comercial',
    outbountBlockLabel: 'Bloqueio Outbound',
    qualityBlockLabel: 'Bloqueio de Qualidade',
  },

  orderDetail: {
    pedidoCriado: 'Pedido criado em',
    pedidoIndustrial: 'Pedido Industrial #',
    orderDetailTitle: 'Detalhes do pedido',
    clientOrderDetailTitle: 'Seu veículo',
    noIndustrialOrdersTitle: 'Sem pedido industrial',
    noIndustrialOrdersSubtitle: 'Não existe nenhum pedido industrial para esse pedido comercial',
  },

  orderResume: {
    orders: 'Pedidos',
    historySection: 'Histórico de Pedidos Entregues de Venda Direta',
    status: 'STATUS',
    numOrders: 'Nº DE PEDIDOS',
    kpiCsi: {
      onTime: 'Pedidos em dia',
      late: 'Pedidos com atraso',
    },
  },

  // ================================ ================================ //

  timeLineLabels: {
    orderCreation: 'Pedido criado',
    invoiceDownloadBlocked: 'Por terem violado os termos de uso, o download da DANFe foi bloqueado para sua concessionária.',
    flagReprogrammedText: 'A data de Produção do seu pedido ainda será reprogramada, portanto a data exibida não é a data real.',
    alertDefault: {
      title: 'Previsão default',
      text: 'A previsão abaixo é determinada por estimativa para o modelo e pode ser alterada após conhecido o destino do veículo.',
    },
  },

  chamadoSiebelConsts: {
    editableProtocol: 'Informar número de protocolo SIEBEL referente a esse pedido industrial',
    filledProtocol: 'Existe um número de protocolo SIEBEL referente a esse pedido industrial',
    fillProtocolNumber: 'Inserir número protocolo SIEBEL',
    mensagemConfirmacao: 'Você tem certeza que deseja deletar o número de protocolo SIEBEL informado?',
  },

  stepDateStatus: {
    unavailable: 'DATA INDISPONÍVEL',
    done: 'CONCLUÍDO',
    foreseen: 'PREVISTO',
    noForeseen: 'SEM PREVISÃO',
    ongoing: 'EM CURSO',
  },

  statusTabLabel: {
    late: 'Atraso',
    lateRisk: 'Risco Atraso',
    inRisk: 'Em Risco',
    onTime: 'Em Dia',
    delivered: 'Entregue',
    log: 'LOG do Pedido',
  },

  filterLabels: {
    filterBy: 'Filtrar por:',
    changeFilters: 'Alterar filtros',
    filterOrders: 'Filtrar pedidos',
    filterPlans: 'Filtrar Planos',
    tobeFiltered: 'Filtrar',
    filteredBy: 'Filtrado',
    changeDates: 'Alterar datas',
    filterDates: 'Filtrar datas',
    date: 'FILTRAR POR DATA',
    dateSelection: 'Selecione as datas de entrega atual:',
    dateFrom: 'De:',
    dateTo: 'Até:',
    dealerSelection: 'Selecione concessionárias do grupo',
    dealerSelected: 'concessionária selecionada',
    dealersSelected: 'concessionárias selecionadas',
    secondaryDealersTitle: 'Concessionárias da Rede',
    dealerId: 'Código da concessionária',
    dealerIdPlaceholder: 'Códigos separados por ;',
    models: 'Modelos',
  },

  macroAreaUser: {
    title: 'Alertas de pedidos \npor macro-áreas',
    infoText: () => <><p>{'Implementamos um sistema que notificará quando surgirem'} <strong>pedidos atrasados</strong> e com <strong>risco de atraso</strong>{', de acordo com as áreas responsáveis. Para isso, você pode selecionar de quais macro-áreas você quer ser notificado.'}</p><p>{'Caso você não selecione nenhuma, o sistema de notificações será suspenso, mas você pode reativá-lo ao clicar em '} <strong>{'Configurações > Alertas.'}</strong></p></>,
    noAlert: 'Não quero ser alertado',
    easyTracking: 'EASY TRACKING',
    confirm: 'Confirmar',
  },

  filterDates: {
    title: 'Filtrar por data de entrega',
    subtitle: 'Visualizar apenas pedidos com previsão de entrega entre as datas abaixo.',
    titleDelay: 'Filtrar por data de atraso e risco',
    subtitleDelay: 'Filtre para visualizar apenas pedidos que entraram no status de Atrasado ou Risco de Atraso nas datas específicas:',
    initialDate: 'Data Inicial',
    finalDate: 'Data Final',
  },

  menu: {
    pedidos: 'Pedidos',
    dashboard: 'Dashboard',
    modelVersionForecast: 'Prazos Médios',
    acompanhamento: 'Acomp. de Atrasos',
    clientOrders: 'Pedidos',
    popUpMenu: {
      transitTime: 'TransitTime',
      parametros: 'Parâmetros',
      visionConfiguration: 'Controle de Visibilidade',
    },
  },

  delayNotifications: {
    macroAreas: {
      orderManagement: 'Gestão de Pedidos',
      salesOperation: 'Sales Operation',
      salesDistribution: 'Distribuição de Veículos',
      dealer: 'Dealer',
    },
  },

  feedbackLabels: {
    title: 'Conte-nos sobre sua experiência com o Easy Tracking',
    subtitle: 'Gostaríamos de coletar sua opinião sobre o sistema para que seja possível entregar uma plataforma cada vez mais adequada às suas necessidades!',
    aboutEasyTracking: 'Como você avaliaria o Easy Tracking?*',
    comment: 'Você gostaria de deixar alguma sugestão de melhoria?',
    warning: '*Ao enviar este feedback você consente que seus dados de nome e email serão compartilhados com a Stellantis.',
    selection: 'Selecione uma opção',
    nps: {
      bad: 'MUITO RUIM',
      veryGood: 'MUITO BOM',
    },
    success: {
      title: 'Feedback enviado com sucesso!',
      subtitle: 'Obrigado por ajudar a melhorar a experiência com o Easy Tracking.',
      textButton: 'Fechar',
    },
    error: {
      title: 'Ocorreu um erro ao tentar enviar.',
      subtitle: 'Tente enviar o feedback novamente.',
      textButton: 'Tentar novamente',
    },
    sendFeedback: 'Enviar feedback',
    sendingMessage: 'Seu feedback está sendo enviado!',
    feedbackTip: {
      title: 'Gostaríamos de ouvir sua opinião',
      text: 'Olá, gostaríamos de saber sobre como tem sido sua experiência ao utilizar o Easy Tracking.',
      buttonRefuse: 'Agora não',
      buttonAccept: 'Avaliar',
    },
  },

  loginExpiredModal: {
    loginExpiredLogout: 'Deslogar',
    sessionExpiredTitle: 'Sessão Expirada',
    sessionaExpiredMessage: 'Sua sessão expirou devido à inatividade. Por gentileza, realize o login novamente.',
  },

  releaseNoteLabels: {
    errorTitle: 'Algo deu errado ao carregar as informações',
    errorSubtitle: 'Por favor, tente novamente',
    notFoundTitle: 'Nenhuma atualização foi encontrada',
    notFoundSubtitle: 'Por favor, tente novamente mais tarde',
  },

  orderInformation: {
    carName: 'Nome do carro',
    warning: '*Imagem meramente ilustrativa.',
    coating: 'revestimento',
    commission: 'comissão',
    createdOn: 'Criado em',
    discount: 'desconto',
    externalColor: 'cor externa',
    model: 'modelo',
    modelAndManufactureYear: 'ano fabricação/modelo',
    optionals: 'opcionais',
    vehicle: 'Veículo',
    version: 'versão',
    typeLabel: {
      rede: 'REDE',
      comercial: 'COMERCIAL',
      notsold: 'NÃO VENDIDO',
      others: 'Outros',
      directsale: 'VENDA DIRETA',
    },
  },

  orderFilterModalWarn: {
    title: 'Pesquisa de pedidos',
    text: 'Para pesquisar pedidos, utilize o número de pedido comercial, ao invés do de pedido industrial.',
  },

  snackBarExport: {
    close: 'Fechar',
    message: 'Houve um erro ao tentar fazer o download do report.',
  },

  faqQuestions: [
    {
      questionText: 'Qual a frequência de atualização dos dados?',
      answer: '<p>Os dados do sistema são atualizados no início de cada dia, referentes ao dia anterior. O horário exato em que o sistema foi atualizado pela última vez se encontra na listagem dos pedidos, no canto superior esquerdo.</p>',
    },
    {
      questionText: 'Como as datas previstas são calculadas?',
      answer: '<p>Cada etapa possui um tempo configurado pelo time da Stellantis, e varia em função do modelo, do pátio de saída e da cidade de entrega. Todo dia, quando os dados são atualizados, as previsões são recalculadas em cima da conclusão e/ou atraso em cima de alguma etapa.</p>',
    },
    {
      questionText: 'Quais são as regras para as indicações de Atraso e Risco de Atraso?',
      answer: '<p>Quando o pedido é registrado uma data ideal de entrega é estabelecida, com base nos parâmetros definidos pelo time da Stellantis.</p>'
        + '<p>Caso o sistema preveja um atraso no pedido, baseado no cálculo das datas previstas, ele indicará '
        + '<span class="badge-inRisk badge">Risco de Atraso</span></p>'
        + '<p>Caso a data atual ultrapasse a data ideal inicialmente estabelecida, o sistema indicara o pedido como '
        + '<span class="badge-late badge">Atrasado</span></p>',
    },
    {
      questionText: 'O pedido consta pendência/atraso para entrega, mas já está nas mãos do cliente, o que houve?',
      answer: '<p>A entrega para o cliente é registrada através da Garantia ou do Emplacamento, sendo que é considerada a data mais recente entre essas duas. Verifique se o pedido realmente possui Garantia ou Emplacamento registrados.</p>',
    },
    {
      questionText: 'O que é utilizado pelo sistema para validar a entrega na concessionária?',
      answer: '<p>O sistema considera o pedido como entregue na concessionária a partir do momento da baixa no ePod.</p>',
    },
    {
      questionText: 'O que é utilizado pelo sistema para validar a entrega no cliente?',
      answer: '<p>A entrega para o cliente é registrada através da Garantia ou do Emplacamento, sendo que é considerada a data mais recente entre essas duas.</p>',
    },
    {
      questionText: 'Consigo exportar os dados da minha carteira?',
      answer: '<p>Sim. O botão de exportar se encontra no canto superior direito, na listagem de pedidos. É possível exportar tanto nos formatos CSV quanto XLSX (Excell).</p>',
    },
    {
      questionText: 'Consigo ter a visão dos pedidos bloqueados em qualidade?',
      answer: '<p>Não é possível ter a visão de bloqueio de qualidade pelo Easy Tracking.</p>',
    },
    {
      questionText: 'O sistema trata pedidos allRisk?',
      answer: '<p>Não.</p>',
    },
    {
      questionText: 'Como o sistema trata as viradas de nota?',
      answer: '<p>Quando a data de faturamento é maior do que a data de expedição, o sistema considera que houve virada de nota em cima do pedido. Entretanto, ele ainda não atribuí um fluxo específico para a virada.</p>',
    },
    {
      questionText: 'Só consigo ver uma concessionária do grupo, como faço para ver pedidos de outra?',
      answer: '<p>No canto superior esquerdo da listagem de pedidos é possível selecionar a concessionária do grupo da qual vocês querem ter a visibilidade. Entretanto, você só conseguirá acessar as concessionárias atreladas ao seu usuário WCD. Caso alguma concessionária do grupo não apareça para você, verifique no Portal WCD se você possui acesso à ela.</p>',
    },
    {
      questionText: 'Como posso achar um pedido específico?',
      answer: '<p>Através do campo de busca ou dos filtros, dentro da listagem de pedidos.</p>'
        + '<p>Pelo campo de busca você conseguirá achar um pedido através do número de Pedido Comercial, Industrial, Chassi e pelo CPF/CNPJ do cliente.</p>'
        + '<p>Na interface do filtro você conseguirá filtrar pela data, status do pedido, categoria do cliente e modelos de veículo.</p>',
    },
  ],
};
