import { connect } from 'react-redux';
import DelayManagementDetails from './DelayManagementDetails';

const mapStateToProps = (state) => {
  const { selectedOrder } = state.orderManagement;
  return { selectedOrder };
};

export default connect(
  mapStateToProps,
)(DelayManagementDetails);
