import {
  loadLastParametersModelsVersionsUpdate,
  loadLastParametersStepsUpdate,
} from 'Infrastructure/Ducks/SystemUpdateDuck';
import ParametersService from '../Services/ParametersService';

export const DUCK_NAME = 'parametersDuck';

export const INITIAL_STATE = {
  loadingStepParameters: false,
  errorLoadingStepParameters: null,
  stepParameters: [],
  errorSavingStepParameters: null,
  successSavingStepParameters: null,
  modelsVersions: {
    loading: false,
    error: null,
    data: null,
  },
  unregisteredData: [],
  unregisteredVersions: [],
  modelsVersionsEdit: {
    loading: false,
    putModel: {
      success: false,
      error: null,
    },
    putVersion: {
      success: false,
      error: null,
    },
    deleteModel: {
      success: false,
      error: null,
    },
    deleteVersion: {
      success: false,
      error: null,
    },
  },
  stepParametersExport: {
    loading: false,
    error: null,
  },
};

export const LOAD_STEP_PARAMETERS_STARTED = `${DUCK_NAME}/LOAD_STEP_PARAMETERS_STARTED`;
export const SAVE_STEP_PARAMETERS_STARTED = `${DUCK_NAME}/SAVE_STEP_PARAMETERS_STARTED`;
export const LOAD_STEP_PARAMETERS_SUCCEED = `${DUCK_NAME}/LOAD_STEP_PARAMETERS_SUCCEED`;
export const LOAD_STEP_PARAMETERS_FAILED = `${DUCK_NAME}/LOAD_STEP_PARAMETERS_FAILED`;
export const SAVE_STEP_PARAMETERS_SUCCEED = `${DUCK_NAME}/SAVE_STEP_PARAMETERS_SUCCEED`;
export const SAVE_STEP_PARAMETERS_FAILED = `${DUCK_NAME}/SAVE_STEP_PARAMETERS_FAILED`;
export const CLEAR_ERROR_SAVE_STEP_PARAMETERS = `${DUCK_NAME}/CLEAR_ERROR_SAVE_STEP_PARAMETERS`;
export const CLEAR_SUCESS_SAVE_STEP_PARAMETERS = `${DUCK_NAME}/CLEAR_SUCESS_SAVE_STEP_PARAMETERS`;
export const CLEAR_STEP_PARAMETERS = `${DUCK_NAME}/CLEAR_STEP_PARAMETERS`;

export const LOAD_MODELS_VERSIONS_STARTED = `${DUCK_NAME}/LOAD_MODELS_VERSIONS_STARTED`;
export const LOAD_MODELS_VERSIONS_SUCCEED = `${DUCK_NAME}/LOAD_MODELS_VERSIONS_SUCCEED`;
export const LOAD_MODELS_VERSIONS_FAILED = `${DUCK_NAME}/LOAD_MODELS_VERSIONS_FAILED`;
export const SAVE_MODELS_SUCCEED = `${DUCK_NAME}/SAVE_MODELS_SUCCEED`;
export const SAVE_MODELS_FAILED = `${DUCK_NAME}/SAVE_MODELS_FAILED`;
export const SAVE_VERSIONS_SUCCEED = `${DUCK_NAME}/SAVE_VERSIONS_SUCCEED`;
export const SAVE_VERSIONS_FAILED = `${DUCK_NAME}/SAVE_VERSIONS_FAILED`;
export const DELETE_MODELS_SUCCEED = `${DUCK_NAME}/DELETE_MODELS_SUCCEED`;
export const DELETE_MODELS_FAILED = `${DUCK_NAME}/DELETE_MODELS_FAILED`;
export const DELETE_VERSIONS_SUCCEED = `${DUCK_NAME}/DELETE_VERSIONS_SUCCEED`;
export const DELETE_VERSIONS_FAILED = `${DUCK_NAME}/DELETE_VERSIONS_FAILED`;
export const CLEAR_EDIT_MODELS_VERSIONS = `${DUCK_NAME}/CLEAR_EDIT_MODELS_VERSIONS`;

export const LOAD_UNREGISTERED_VERSIONS_SUCCEED = `${DUCK_NAME}/LOAD_UNREGISTERED_VERSIONS_SUCCEED`;
export const LOAD_UNREGISTERED_VERSIONS_FAILED = `${DUCK_NAME}/LOAD_UNREGISTERED_VERSIONS_FAILED`;

export const LOAD_UNREGISTERED_DATA_SUCCEED = `${DUCK_NAME}/LOAD_UNREGISTERED_DATA_SUCCEED`;
export const LOAD_UNREGISTERED_DATA_FAILED = `${DUCK_NAME}/LOAD_UNREGISTERED_DATA_FAILED`;

export const EXPORT_STEP_STARTED = `${DUCK_NAME}/EXPORT_STEP_STARTED`;
export const EXPORT_STEP_SUCCEED = `${DUCK_NAME}/EXPORT_STEP_SUCCEED`;
export const EXPORT_STEP_FAILED = `${DUCK_NAME}/EXPORT_STEP_FAILED`;
export const CLEAR_STEP = `${DUCK_NAME}/CLEAR_STEP`;

export const loadStepParametersStarted = () => ({ type: LOAD_STEP_PARAMETERS_STARTED });
export const loadStepParametersSucceed = stepParameters => ({
  type: LOAD_STEP_PARAMETERS_SUCCEED, stepParameters,
});
export const loadStepParametersFailed = error => ({ type: LOAD_STEP_PARAMETERS_FAILED, error });
export const saveStepParametersSucceed = () => ({ type: SAVE_STEP_PARAMETERS_SUCCEED });
export const saveStepParametersFailed = error => ({ type: SAVE_STEP_PARAMETERS_FAILED, error });
export const clearErrorSaveStepParameters = () => ({ type: CLEAR_ERROR_SAVE_STEP_PARAMETERS });
export const clearSucessSaveStepParameters = () => ({ type: CLEAR_SUCESS_SAVE_STEP_PARAMETERS });
export const clearStepParametersAction = () => ({ type: CLEAR_STEP_PARAMETERS });

export const loadModelsVersionsStarted = () => ({ type: LOAD_MODELS_VERSIONS_STARTED });
export const loadModelsVersionsSucceed = modelsVersions => ({
  type: LOAD_MODELS_VERSIONS_SUCCEED, modelsVersions,
});
export const loadModelsVersionsFailed = error => ({ type: LOAD_MODELS_VERSIONS_FAILED, error });
export const saveModelsSucceed = () => ({ type: SAVE_MODELS_SUCCEED });
export const saveModelsFailed = error => ({ type: SAVE_MODELS_FAILED, error });
export const saveVersionsSucceed = () => ({ type: SAVE_VERSIONS_SUCCEED });
export const saveVersionsFailed = error => ({ type: SAVE_VERSIONS_FAILED, error });
export const deleteModelsSucceed = () => ({ type: DELETE_MODELS_SUCCEED });
export const deleteModelsFailed = error => ({ type: DELETE_MODELS_FAILED, error });
export const deleteVersionsSucceed = () => ({ type: DELETE_VERSIONS_SUCCEED });
export const deleteVersionsFailed = error => ({ type: DELETE_VERSIONS_FAILED, error });
export const clearEditModelsVersions = () => ({ type: CLEAR_EDIT_MODELS_VERSIONS });

export const loadUnregisteredVersionsSucceed = unregisteredVersions => ({
  type: LOAD_UNREGISTERED_VERSIONS_SUCCEED, unregisteredVersions,
});
export const loadUnregisteredVersionsFailed = () => ({ type: LOAD_UNREGISTERED_VERSIONS_FAILED });

export const loadUnregisteredDataSucceed = unregisteredData => ({
  type: LOAD_UNREGISTERED_DATA_SUCCEED, unregisteredData,
});
export const loadUnregisteredDataFailed = () => ({ type: LOAD_UNREGISTERED_DATA_FAILED });

export const exportStepStarted = () => ({ type: EXPORT_STEP_STARTED });
export const exportStepSucceed = () => ({ type: EXPORT_STEP_SUCCEED });
export const exportStepFailed = error => ({ type: EXPORT_STEP_FAILED, error });
export const clearStepAction = () => ({ type: CLEAR_STEP });

export const loadStepParameters = () => async (dispatch) => {
  dispatch(loadStepParametersStarted());
  let stepParameters = [];
  try {
    const parametersService = new ParametersService();
    stepParameters = await parametersService.getStepParameters();
    dispatch(loadStepParametersSucceed(stepParameters));
  } catch (err) {
    dispatch(loadStepParametersFailed(err));
  }
};

export const loadUnregisteredVersions = () => async (dispatch) => {
  let unregisteredVersions = [];
  try {
    const parametersService = new ParametersService();
    unregisteredVersions = await parametersService.getUnregisteredVersions();
    dispatch(loadUnregisteredVersionsSucceed(unregisteredVersions));
  } catch (err) {
    dispatch(loadUnregisteredVersionsFailed());
  }
};

export const loadUnregisteredData = () => async (dispatch) => {
  let unregisteredData = [];
  try {
    const parametersService = new ParametersService();
    unregisteredData = await parametersService.getUnregisteredData();
    dispatch(loadUnregisteredDataSucceed(unregisteredData));
  } catch (err) {
    dispatch(loadUnregisteredDataFailed());
  }
};

export const saveStepParameters = stepParameters => async (dispatch) => {
  try {
    const parametersService = new ParametersService();
    await parametersService.putStepParameters(stepParameters);
    dispatch(saveStepParametersSucceed());
    dispatch(loadStepParameters());
    dispatch(loadLastParametersStepsUpdate());
    setTimeout(() => {
      dispatch(clearSucessSaveStepParameters());
    }, 3000);
    return true;
  } catch (err) {
    dispatch(saveStepParametersFailed(err));
    return false;
  }
};

export const clearStepParameters = () => async (dispatch) => {
  dispatch(clearStepParametersAction());
};

export const loadModelsVersions = () => async (dispatch) => {
  dispatch(loadModelsVersionsStarted());
  let modelsVersions = [];
  try {
    const parametersService = new ParametersService();
    modelsVersions = await parametersService.getModelsVersions();
    dispatch(loadModelsVersionsSucceed(modelsVersions));
  } catch (err) {
    dispatch(loadModelsVersionsFailed(err));
  }
};

export const saveModel = model => async (dispatch) => {
  try {
    const parametersService = new ParametersService();

    await parametersService.putModel(model);
    dispatch(saveModelsSucceed());
    dispatch(loadModelsVersions());
    dispatch(loadLastParametersModelsVersionsUpdate());
    setTimeout(() => {
      dispatch(clearEditModelsVersions());
    }, 3000);
    return true;
  } catch (err) {
    dispatch(saveModelsFailed(err));
    return false;
  }
};

export const saveVersion = version => async (dispatch) => {
  try {
    const parametersService = new ParametersService();
    await parametersService.putVersion(version);
    dispatch(saveVersionsSucceed());
    dispatch(loadModelsVersions());
    dispatch(loadLastParametersModelsVersionsUpdate());
    setTimeout(() => {
      dispatch(clearEditModelsVersions());
    }, 3000);
    return true;
  } catch (err) {
    dispatch(saveVersionsFailed(err));
    return false;
  }
};

export const deleteModel = modelId => async (dispatch) => {
  try {
    const parametersService = new ParametersService();
    await parametersService.deleteModel(modelId);
    dispatch(deleteModelsSucceed());
    dispatch(loadModelsVersions());
    dispatch(loadLastParametersModelsVersionsUpdate());
    setTimeout(() => {
      dispatch(clearEditModelsVersions());
    }, 3000);
    return true;
  } catch (err) {
    dispatch(deleteModelsFailed(err));
    return false;
  }
};

export const deleteVersion = (versionId, modelId) => async (dispatch) => {
  try {
    const parametersService = new ParametersService();
    await parametersService.deleteVersion(versionId, modelId);
    dispatch(deleteVersionsSucceed());
    dispatch(loadModelsVersions());
    dispatch(loadLastParametersModelsVersionsUpdate());
    setTimeout(() => {
      dispatch(clearEditModelsVersions());
    }, 3000);
    return true;
  } catch (err) {
    dispatch(deleteVersionsFailed(err));
    return false;
  }
};

export const exportStep = fileType => async (dispatch) => {
  dispatch(exportStepStarted());
  const parametersService = new ParametersService();
  try {
    await parametersService.exportStep(fileType);
    dispatch(exportStepSucceed());
  } catch (err) {
    dispatch(exportStepFailed(err));
  }
};

export const clearStepParametersExport = () => async (dispatch) => {
  dispatch(clearStepAction());
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_STEP_PARAMETERS_STARTED:
      return {
        ...state,
        loadingStepParameters: true,
        errorLoadingStepParameters: null,
      };
    case LOAD_STEP_PARAMETERS_SUCCEED:
      return {
        ...state,
        loadingStepParameters: false,
        errorLoadingStepParameters: null,
        stepParameters: [
          ...action.stepParameters,
        ],
      };
    case LOAD_STEP_PARAMETERS_FAILED:
      return {
        ...state,
        loadingStepParameters: false,
        errorLoadingStepParameters: action.error,
      };
    case LOAD_MODELS_VERSIONS_STARTED:
      return {
        ...state,
        modelsVersions: {
          loading: true,
          error: null,
          data: null,
        },
      };
    case LOAD_MODELS_VERSIONS_SUCCEED:
      return {
        ...state,
        modelsVersions: {
          loading: false,
          error: null,
          data: action.modelsVersions,
        },
      };
    case LOAD_MODELS_VERSIONS_FAILED:
      return {
        ...state,
        modelsVersions: {
          loading: false,
          error: action.error,
          data: null,
        },
      };
    case SAVE_STEP_PARAMETERS_FAILED:
      return {
        ...state,
        errorSavingStepParameters: action.error,
      };
    case SAVE_STEP_PARAMETERS_SUCCEED:
      return {
        ...state,
        successSavingStepParameters: true,
      };
    case CLEAR_ERROR_SAVE_STEP_PARAMETERS:
      return {
        ...state,
        errorSavingStepParameters: null,
      };
    case CLEAR_SUCESS_SAVE_STEP_PARAMETERS:
      return {
        ...state,
        successSavingStepParameters: null,
      };
    case CLEAR_STEP_PARAMETERS:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case SAVE_MODELS_SUCCEED:
      return {
        ...state,
        modelsVersionsEdit: {
          ...state.modelsVersionsEdit,
          loading: true,
          putModel: {
            success: true,
            error: null,
          },
        },
      };
    case SAVE_MODELS_FAILED:
      return {
        ...state,
        modelsVersionsEdit: {
          ...state.modelsVersionsEdit,
          putModel: {
            success: false,
            error: action.error,
          },
        },
      };
    case SAVE_VERSIONS_SUCCEED:
      return {
        ...state,
        modelsVersionsEdit: {
          ...state.modelsVersionsEdit,
          loading: true,
          putVersion: {
            success: true,
            error: null,
          },
        },
      };
    case SAVE_VERSIONS_FAILED:
      return {
        ...state,
        modelsVersionsEdit: {
          ...state.modelsVersionsEdit,
          putVersion: {
            success: false,
            error: action.error,
          },
        },
      };
    case DELETE_MODELS_SUCCEED:
      return {
        ...state,
        modelsVersionsEdit: {
          ...state.modelsVersionsEdit,
          loading: true,
          deleteModel: {
            success: true,
            error: null,
          },
        },
      };
    case DELETE_MODELS_FAILED:
      return {
        ...state,
        modelsVersionsEdit: {
          ...state.modelsVersionsEdit,
          deleteModel: {
            success: false,
            error: action.error,
          },
        },
      };
    case DELETE_VERSIONS_SUCCEED:
      return {
        ...state,
        modelsVersionsEdit: {
          ...state.modelsVersionsEdit,
          loading: true,
          deleteVersion: {
            success: true,
            error: null,
          },
        },
      };
    case DELETE_VERSIONS_FAILED:
      return {
        ...state,
        modelsVersionsEdit: {
          ...state.modelsVersionsEdit,
          deleteVersion: {
            success: false,
            error: action.error,
          },
        },
      };
    case CLEAR_EDIT_MODELS_VERSIONS:
      return {
        ...state,
        modelsVersionsEdit: INITIAL_STATE.modelsVersionsEdit,
      };
    case EXPORT_STEP_STARTED:
      return {
        ...state,
        stepParametersExport: {
          loading: true,
          error: null,
        },
      };
    case EXPORT_STEP_FAILED:
      return {
        ...state,
        stepParametersExport: {
          loading: false,
          error: action.error,
        },
      };
    case EXPORT_STEP_SUCCEED:
    case CLEAR_STEP:
      return {
        ...state,
        stepParametersExport: {
          loading: false,
          error: null,
        },
      };
    case LOAD_UNREGISTERED_VERSIONS_SUCCEED:
      return {
        ...state,
        unregisteredVersions: [
          ...action.unregisteredVersions,
        ],
      };
    case LOAD_UNREGISTERED_VERSIONS_FAILED:
      return {
        ...state,
        unregisteredVersions: [],
      };
    case LOAD_UNREGISTERED_DATA_SUCCEED:
      return {
        ...state,
        unregisteredData: [
          ...action.unregisteredData,
        ],
      };
    case LOAD_UNREGISTERED_DATA_FAILED:
      return {
        ...state,
        unregisteredData: [],
      };
    default:
      return state;
  }
};

export default reducer;
