import { connect } from 'react-redux';
import { downloadInvoice, clearInvoiceDuck, downloadInvoiceEmployee } from 'Infrastructure/Ducks/InvoiceDuck';
import InvoiceDownloadModal from './InvoiceDownloadModal';

const mapStateToProps = (state) => {
  const { invoiceDuck } = state;
  return { invoiceDuck };
};

const mapDispatchToProps = (dispatch) => ({
  downloadInvoice: (industrialOrderId, orderId, vin) => {
    dispatch(downloadInvoice(industrialOrderId, orderId, vin));
  },
  clearInvoiceDuck: () => {
    dispatch(clearInvoiceDuck());
  },
  downloadInvoiceEmployee: (orderId) => {
    dispatch(downloadInvoiceEmployee(orderId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDownloadModal);
