import React from 'react';
import Accordion from '@mui/material/Accordion';
import PropTypes from 'prop-types';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './AccordionList.scss';

export default function AccordionList({ onLoad, origin }) {
  return (
    <div>
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>{origin}</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">{onLoad()}</AccordionDetails>
      </Accordion>
    </div>
  );
}

AccordionList.propTypes = {
  onLoad: PropTypes.func.isRequired,
  origin: PropTypes.string.isRequired,
  // parametersDuck: PropTypes.object.isRequired,
};
