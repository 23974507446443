import { connect } from 'react-redux';
import { getMacroAreas, putMacroAreas, setMacroAreaModalStatus } from 'Infrastructure/Ducks/UserDuck';
import MacroAreaUserModal from './MacroAreaUserModal';

const mapStateToProps = (state) => {
  const { isMacroAreaModalOpen } = state.userDuck;
  return { isModalOpen: isMacroAreaModalOpen };
};

const mapDispatchToProps = (dispatch) => ({
  putMacroAreas: (macroAreas) => dispatch(putMacroAreas(macroAreas)),
  getMacroAreas: () => dispatch(getMacroAreas()),
  onClose: () => dispatch(setMacroAreaModalStatus(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MacroAreaUserModal);
