import { connect } from 'react-redux';
import { loadList, clearList, saveData } from 'Infrastructure/Ducks/ListRegion';
import TransitTime from './TransitTime';

const mapStateToProps = (state) => {
  const { listRegion } = state;
  return {
    listRegion,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadList: () => {
    dispatch(loadList());
  },
  clearList: () => {
    dispatch(clearList());
  },
  saveData: (incomingData) => {
    dispatch(saveData(incomingData));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TransitTime);
