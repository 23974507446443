import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import { ReactComponent as CloseIcon } from 'Common/Assets/closeIcon.svg';
import { ReactComponent as SearchIcon } from 'Common/Assets/search.svg';
import colorVariables from 'Utils/Styles/colorVariables';
import './SearchInputAhorro.scss';

const SearchInputAhorro = ({
  search = '',
  setSearch = (val) => {},
  handleSearch = () => {},
  handleClear = () => {},
}) => {
  const handleChange = (val) => setSearch(val);

  return (
    <div className="search-component">
      <TextField
        variant="standard"
        style={{
          textAlign: 'center',
          margin: '0px',
        }}
        InputProps={{
          style: {
            width: '410px',
            color: '#758CA5',
            fontSize: '14px',
          },
          endAdornment: (
            <div className="searchInput-endAdornment">
              {search && search.length > 0 ? (
                <>
                  <CloseIcon fill={colorVariables.textPrimaryColor} cursor="pointer" onClick={handleClear} />
                  <div className="searchInput-separator" />
                </>
              ) : null}
              <SearchIcon cursor="pointer" onClick={handleSearch} />
            </div>
          ),
        }}
        placeholder="Búsqueda para cliente, CUIT, orden comercial o industrial"
        margin="normal"
        value={search}
        onChange={(event) => {
          handleChange(event.target.value);
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            handleSearch();
          }
        }}
      />
    </div>
  );
};

SearchInputAhorro.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func,
  handleSearch: PropTypes.func,
  handleClear: PropTypes.func,
};

export default SearchInputAhorro;
