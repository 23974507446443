import { connect } from 'react-redux';
import TimelineItem from './TimelineItem';

const mapStateToProps = (state) => {
  const { orderDetail } = state;
  return { selectedIndustrialOrder: orderDetail.selectedIndustrialOrder };
};

export default connect(
  mapStateToProps,
  null,
)(TimelineItem);
