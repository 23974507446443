import { connect } from 'react-redux';
import {
  loadListRegionWithCities,
  exportCities,
  clearExportCities,
  saveTransitTime,
  clearErrorSaveTransitTime,
  clearList,
} from 'Infrastructure/Ducks/ListRegion';
import ForecastTransitTime from './ForecastTransitTime';

const mapStateToProps = (state) => {
  const { listRegion } = state;
  return { listRegion };
};

const mapDispatchToProps = dispatch => ({
  loadListRegionWithCities: () => {
    dispatch(loadListRegionWithCities());
  },
  exportCities: (fileType) => {
    dispatch(exportCities(fileType));
  },
  saveTransitTime: transitTime => (
    dispatch(saveTransitTime(transitTime))
  ),
  clearErrorSaveTransitTime: () => {
    dispatch(clearErrorSaveTransitTime());
  },
  clearExportCities: () => {
    dispatch(clearExportCities());
  },
  clearList: () => {
    dispatch(clearList());
  },
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForecastTransitTime);
