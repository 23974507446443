import { connect } from 'react-redux';
import { loadMacroAreaNotifications } from 'Infrastructure/Ducks/NotificationDuck';
import { setMacroAreaModalStatus, getMacroAreas } from 'Infrastructure/Ducks/UserDuck';
import { setFilterNotifications } from 'Infrastructure/Ducks/FiltersDuck';
import DelayNotificationsDrawer from './DelayNotificationsDrawer';

const mapStateToProps = (state) => {
  const { macroAreaNotifications, newMacroAreaNotificationIds } = state.notificationDuck;
  const { loading } = state.userDuck;
  return { macroAreaNotifications, newMacroAreaNotificationIds, loadingMacroAreas: loading };
};

const mapDispatchToProps = dispatch => ({
  loadMacroAreaNotifications: () => dispatch(loadMacroAreaNotifications()),
  setMacroAreaModalOpen: () => dispatch(setMacroAreaModalStatus(true)),
  setFilterNotifications: filters => dispatch(setFilterNotifications(filters)),
  getMacroAreas: () => dispatch(getMacroAreas()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DelayNotificationsDrawer);
