import { connect } from 'react-redux';
import { loadLastAverageInvoiceTimeUpdate } from 'Infrastructure/Ducks/SystemUpdateDuck';
import AverageInvoiceTimeUpdate from './AverageInvoiceTimeUpdate';

const mapStateToProps = (state) => {
  const { lastAverageInvoiceTimeUpdate } = state.systemUpdate;
  return { lastAverageInvoiceTimeUpdate };
};

const mapDispatchToProps = dispatch => ({
  loadLastAverageInvoiceTimeUpdate: () => dispatch(loadLastAverageInvoiceTimeUpdate()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AverageInvoiceTimeUpdate);
