/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import axios from 'axios';
import { API_URL, ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';

import {
  routeConsts, roleConsts, environment, googleRecaptchaKeys,
} from 'Utils/consts';
import { User } from 'Common/Helpers/AuthHelper';
import RequestRepository from './RequestRepository';

class AuthRepository {
  constructor({ routePrefix = API_URL, routeVersion = routeConsts.version } = {}) {
    this.url = `${routePrefix}/${routeVersion}/orders`;
  }

  getInitials = (nome) => {
    let initials = '';
    let nomes = '';
    if (nome) {
      nomes = nome.split(' ');
    } else {
      return initials;
    }
    initials = nomes.length === 1 ? nomes[0][0] : nomes[0][0] + nomes[nomes.length - 1][0];
    return initials;
  }

  async logout() {
    return Promise.resolve({});
  }

  async post({ userName, password, userType }) {
    let idToken = '';
    let nome = '';
    let userProfile;
    let newEmail = userName;
    let userDpr;
    let userGroups;
    if (userType === roleConsts.employees) {
      const employeeToken = await RequestRepository.employeeUserAuth(userName, password);
      idToken = employeeToken;
      if (idToken) {
        userGroups = [{ roles: [roleConsts.employees] }];
        nome = 'employee';
        newEmail = 'employee';
      }
    } else if (userType !== roleConsts.dealers) {
      const dadosCustomercareLogin = await RequestRepository.customerCareAuth(userName, password);
      idToken = dadosCustomercareLogin.token;
      const roles = dadosCustomercareLogin.listRoles;
      userGroups = [{ roles: [...roles] }];
      nome = dadosCustomercareLogin.name;
      newEmail = dadosCustomercareLogin.email;
    } else {
      const dadosDealerLogin = await RequestRepository.dealerAuth(userName, password);
      if (dadosDealerLogin) {
        nome = dadosDealerLogin.name;
        newEmail = dadosDealerLogin.email;
        ({ userDpr } = dadosDealerLogin);
        userGroups = [{ roles: [roleConsts.dealers] }];
      }
    }
    if (userGroups && userGroups.length > 0) {
      userProfile = userGroups.reduce((cur, acc) => {
        cur.push(...acc.roles);
        return cur;
      }, []);
    } else {
      throw new Error('No user group');
    }
    userProfile = this.makeUserProfiles(userProfile);
    if (!userProfile.includes(userType) && !userProfile.includes(roleConsts.admin)) {
      throw new Error('Wrong user group');
    }
    const initials = this.getInitials(nome);
    return new Promise((resolve) => {
      const data = {
        success: true,
        token: idToken,
        tokenType: 'bearer',
        nome,
        initials,
        email: newEmail,
        userProfile,
        userDpr,
      };
      resolve({ data });
    });
  }

  makeUserProfiles(profiles) {
    let userProfiles = [...profiles];
    userProfiles = userProfiles.filter(item => item !== roleConsts.customercare);
    const isValidAR = ENV_ENVIRONMENT === environment.AR
      && (profiles.includes(roleConsts.ar) || profiles.some(v => v.includes(`_${environment.AR}`)));
    const isValidBR = ENV_ENVIRONMENT === environment.BR
      && (profiles.includes(roleConsts.br) || profiles.includes(roleConsts.customercare)
        || profiles.some(v => v.includes(`_${environment.BR}`)));
    if (isValidAR || isValidBR) {
      userProfiles.push(roleConsts.customercare);
    }
    return userProfiles;
  }

  checkAuthState() {
    return Promise.resolve(User.get());
  }

  async checkCaptcha(captchaToken) {
    const url = `${API_URL}/${routeConsts.version}/auth/clients/recaptcha/verify`;
    const body = {
      secret: googleRecaptchaKeys.secret,
      token: captchaToken,
    };
    return new Promise((resolve, reject) => {
      const headers = {
        Accept: 'application/json',
      };
      axios.post(url, body, { headers }).then(
        result => resolve(result),
      ).catch((error) => {
        reject(error);
      });
    });
  }
}

export default AuthRepository;
