import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import langValues from 'Config/i18n/index';
import { emptyDate } from 'Utils/consts';
import { getDateAsText } from 'Utils/dateUtils';

import './ParametersStepsUpdate.scss';

const ParametersStepsUpdate = ({ lastParametersStepsUpdate, loadLastParametersStepsUpdate }) => {
  useEffect(() => {
    loadLastParametersStepsUpdate();
  }, []);

  let updateDateText = emptyDate;
  if (lastParametersStepsUpdate) {
    updateDateText = getDateAsText(new Date(lastParametersStepsUpdate));
  }

  return (
    <span className="parametersStepsUpdateDate">
      {langValues.lastUpdate}
      {updateDateText}
    </span>
  );
};

ParametersStepsUpdate.propTypes = {
  loadLastParametersStepsUpdate: PropTypes.func.isRequired,
  lastParametersStepsUpdate: PropTypes.string,
};

export default ParametersStepsUpdate;
