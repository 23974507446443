import { connect } from 'react-redux';
import { loadLogout } from 'Infrastructure/Ducks/Auth';
import LoginExpiredModal from './LoginExpiredModal';

const mapStateToProps = (state) => {
  const { isExpiredModalOpen } = state.auth;
  return { isExpiredModalOpen };
};

const mapDispatchToProps = dispatch => ({
  loadLogout: () => dispatch(loadLogout(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginExpiredModal);
