import { connect } from 'react-redux';
import { loadList, loadTotal } from 'Infrastructure/Ducks/OrderManagementDuck';
import { setOrderSorting } from 'Infrastructure/Ducks/FiltersDuck';
import { exportLateOrdersList, setExportError } from 'Infrastructure/Ducks/ListPedidos';
import ManagementPage from './ManagementPage';

const mapStateToProps = (state) => {
  const { loadingExport } = state.list;
  const { error } = state.orderManagement;
  const { filtersDuck } = state;
  return { error, filtersDuck, loadingExport };
};

const mapDispatchToProps = dispatch => ({
  loadList: (page, stepStatus, filters) => {
    dispatch(loadList(page, stepStatus, filters));
    dispatch(loadTotal(stepStatus, filters));
  },
  setOrderSorting: () => {
    dispatch(setOrderSorting(null));
  },
  exportLateOrdersList: (searchString, filters, fileType) => {
    dispatch(exportLateOrdersList(searchString, filters, fileType));
  },
  setExportErrorFalse: () => {
    dispatch(setExportError(false));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManagementPage);
