import RequestRepository from '../Repositories/RequestRepository';

class NotificationService {
  getNotifications = async () => {
    try {
      const urlPath = 'notifications';
      const { data } = await RequestRepository.get(urlPath);
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  getMacroAreaNotifications = async () => {
    try {
      const urlPath = 'notifications/macroareas';
      const { data } = await RequestRepository.get(urlPath);

      const mappedNotifications = data.reduce((acc, cur) => {
        if (cur.amountRiskNew > 0) {
          const notificationRisk = {
            id: `inRisk${cur.id}`,
            type: 'inRisk',
            macroArea: cur.macroAreaId,
            count: cur.amountRisk,
            countNew: cur.amountRiskNew,
            date: cur.date,
          };

          acc.push(notificationRisk);
        }

        if (cur.amountLateNew > 0) {
          const notificationLate = {
            id: `late${cur.id}`,
            type: 'late',
            macroArea: cur.macroAreaId,
            count: cur.amountLate,
            countNew: cur.amountLateNew,
            date: cur.date,
          };

          acc.push(notificationLate);
        }

        return acc;
      }, []);

      return Promise.resolve(mappedNotifications);
    } catch (err) {
      return Promise.reject(err);
    }
  };
}

export default NotificationService;
