import Drawer from '@mui/material/Drawer';
import { withStyles } from 'tss-react/mui';

const DrawerVision = (style) =>
  withStyles(Drawer, {
    paperAnchorLeft: {
      left: '56px',
    },
    paper: {
      zIndex: '1',
      width: '277px',
      boxShadow: 'inset 8px 0px 10px -4px rgba(0, 0, 0, 0.08), 4px 0px 12px 0px rgba(0, 0, 0, 0.08)',
    },
    ...style,
  });

export default DrawerVision;
