import React from 'react';
import PropTypes from 'prop-types';
import DataExtenso from 'Components/Formatters/DataExtenso/index';
import BlockIconWithTooltip, { getBlockLabel } from 'Components/Atoms/BlockIcon/BlockIcon';

import './BlockPendencyInformation.scss';

const BlockPendencyInformation = ({ cantonmentDate, industrialOrder }) => {
  const blockLabel = getBlockLabel(industrialOrder);
  if (!blockLabel) return null;
  return (
    <div className="blockPendencyWrapper">
      <BlockIconWithTooltip order={industrialOrder} />
      <div className="blockPendency">
        <span className="blockPendency-title">{blockLabel}</span>
        {cantonmentDate ? (
          <div className="blockPendency-date">
            <DataExtenso date={cantonmentDate} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

BlockPendencyInformation.propTypes = {
  cantonmentDate: PropTypes.string,
  industrialOrder: PropTypes.object.isRequired,
};

export default BlockPendencyInformation;
