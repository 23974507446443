import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as BellIcon } from 'Common/Assets/Bell.svg';
import DelayNotificationsDrawer from 'Components/Organisms/DelayNotificationsDrawer';

import './DelayNotificationsButton.scss';

const DelayNotificationsButton = ({ newMacroAreaNotificationIds = [], updateViewedMacroAreaNotifications }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const onDrawerClose = () => {
    setDrawerOpen(false);
    updateViewedMacroAreaNotifications();
  };

  const renderNotificationsAmount = () => {
    const notificationsAmount = newMacroAreaNotificationIds.length;
    if (notificationsAmount > 0) {
      return <div className="newNotifications">{notificationsAmount}</div>;
    }
    return null;
  };

  return (
    <>
      <button className="menuItem delayNotificationsIcon" type="button" onClick={() => setDrawerOpen(true)}>
        <BellIcon />
        {renderNotificationsAmount()}
      </button>
      <DelayNotificationsDrawer drawerOpen={drawerOpen} onClose={() => onDrawerClose()} />
    </>
  );
};

DelayNotificationsButton.propTypes = {
  updateViewedMacroAreaNotifications: PropTypes.func.isRequired,
  newMacroAreaNotificationIds: PropTypes.array,
};

export default DelayNotificationsButton;
