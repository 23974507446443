import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TagOrderStatus from 'Components/Atoms/TagOrderStatus';
import StepStatusCircle from 'Components/Atoms/StepStatusCircle/StepStatusCircle';
import InvoiceIcon from 'Components/Atoms/InvoiceIcon';
import { getDateAsNumbers } from 'Utils/dateUtils';
import './IndustrialOrderCard.scss';
import { trackedEvents, trackedButtons, roleConsts } from 'Utils/consts';
import { trackEvent } from 'Utils/analytics';
import BlockIconWithTooltip from 'Components/Atoms/BlockIcon/BlockIcon';
import { User } from 'Common/Helpers/AuthHelper';

const IndustrialOrderCard = ({ industrialOrder, selectedIndustrialOrder, changeSelectedIndustrialOrder }) => {
  const renderHeader = () => {
    const currentStep = industrialOrder.dates.find((step) => step.stepId.id === industrialOrder.step);
    return (
      <div className="industrialOrderCard-header">
        <TagOrderStatus lateDays={industrialOrder.lateDays} statusId={industrialOrder.statusId} />
        <div className="industrialOrderCard-step">
          {currentStep ? (
            <>
              <StepStatusCircle step={currentStep} isCurrentStep size={25} />
              <span className={`step-${currentStep.status} font-small1`}>{currentStep.stepId.description}</span>
            </>
          ) : null}
        </div>
      </div>
    );
  };

  const renderInformation = () => (
    <div className="industrialOrderCard-information">
      <h5>Industrial</h5>
      {!industrialOrder.invoiceAvailable &&
      (User.hasProfile(roleConsts.dealers) || User.hasProfile(roleConsts.employees)) ? (
        <h5>#*********</h5>
      ) : (
        <h5> {`#${industrialOrder.industrialOrderId}`} </h5>
      )}
      <span className="font-small2">{`Criado em ${getDateAsNumbers(industrialOrder.industrialOrderDate)}`}</span>
    </div>
  );

  const renderAlerts = () => (
    <div className="industrialOrderCard-alerts">
      {industrialOrder.invoiceAvailable ? <InvoiceIcon /> : null}
      <BlockIconWithTooltip order={industrialOrder} />
    </div>
  );

  const isSelected = () => {
    if (!selectedIndustrialOrder) return 'cardNormal';
    if (industrialOrder.industrialOrderId === selectedIndustrialOrder.industrialOrderId) return 'cardSelected';
    return 'cardNormal';
  };

  const [isSwiping, setSwiping] = useState(false);

  const selectAsCurrentCard = () => {
    if (
      isSwiping ||
      (selectedIndustrialOrder && industrialOrder.industrialOrderId === selectedIndustrialOrder.industrialOrderId)
    ) {
      return;
    }
    changeSelectedIndustrialOrder(industrialOrder);
    trackEvent(trackedEvents.buttonClick, { button: trackedButtons.viewIndustrialOrder });
  };

  return (
    <button
      style={{ display: 'contents', textAlign: 'left', userSelect: 'text' }}
      type="button"
      onClick={() => selectAsCurrentCard()}
      onMouseDown={() => {
        setSwiping(false);
      }}
      onMouseMove={() => {
        setSwiping(true);
      }}
    >
      <section className={`industrialOrderCard ${isSelected()}`}>
        {renderHeader()}
        {renderInformation()}
        {renderAlerts()}
      </section>
    </button>
  );
};

IndustrialOrderCard.propTypes = {
  industrialOrder: PropTypes.object.isRequired,
  selectedIndustrialOrder: PropTypes.object,
  changeSelectedIndustrialOrder: PropTypes.func.isRequired,
};

export default IndustrialOrderCard;
