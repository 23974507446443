import React from 'react';
import PropTypes from 'prop-types';
import './StepMilestone.scss';
import colorVariables from 'Utils/Styles/colorVariables';

const StepMilestone = ({ Milestone, strokeColor = colorVariables.textSecondaryColor }) => {
  const renderLine = () => (
    <span className="stepMilestone-line">
      <svg width="1px" height="100%" viewBox="0 0 1 82" fill="none">
        <line
          className="line"
          x1="0.5"
          y1="82"
          x2="0.5"
          y2="0"
          strokeMiterlimit="5.75877"
          strokeDasharray="4 4"
          stroke={strokeColor}
        />
      </svg>
    </span>
  );

  const renderMilestone = () => (
    <>
      <div className="milestone">
        <Milestone />
      </div>
      {renderLine()}
    </>
  );

  return <div className="stepMilestone">{renderMilestone()}</div>;
};

StepMilestone.propTypes = {
  Milestone: PropTypes.elementType.isRequired,
  strokeColor: PropTypes.string,
};

export default StepMilestone;
