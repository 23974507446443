import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StepStatusCircle from 'Components/Atoms/StepStatusCircle/StepStatusCircle';
import StepStatus from 'Components/Atoms/StepStatus';
import StepDate from 'Components/Atoms/StepDate';
import AlertComponent from 'Components/Atoms/AlertComponent';
import StepMilestone from 'Components/Atoms/StepMilestone';
import { User } from 'Common/Helpers/AuthHelper';
import { alertTypes, stepStatusId, timeLine, roleConsts, environment } from 'Utils/consts';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import { safeToUpperCase } from 'Utils/safeMethods';
import './TimelineItem.scss';
import FleetInformationButton from 'Components/Atoms/FleetInformationButton/FleetInformationButton';
import OrderInformationItem from 'Components/Atoms/OrderInformationItem/index';
import colorVariables from 'Utils/Styles/colorVariables';
import langValues from 'Config/i18n/index';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InformationTooltipPDA from 'Components/Atoms/InformationTooltipPDA/InformationTooltipPDA';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const STEP_CIRCLE_SIZE = 40;
const SUB_STEP_CIRCLE_SIZE = 24;

const TimelineItem = ({
  selectedIndustrialOrder,
  step,
  isCurrentStep = false,
  isSingleStep,
  hasDefaultFlag,
  vehicleFleet = null,
  rules = null,
  isPDA,
  isAdmintrative = false,
  previouslyStepIsEnable = false,
}) => {
  const [isStepStatusEmpty, setIsStepStatusEmpty] = useState(true);
  const [isToolTipOpen, setIsToolTipOpen] = useState(true);
  const [showTimelineToolTip, setShowTimelineToolTip] = useState(false);

  const handleTimelineTooltip = () => setShowTimelineToolTip((prev) => !prev);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
      maxWidth: '390px',
      minWidth: '390px',
      padding: 0,
    },
  }));

  let stepStatus = step.status;
  if (step.enable) {
    stepStatus = stepStatusId.delivered;
  }

  if ((stepStatus === stepStatusId.onTime && !isCurrentStep) || (!step.enable && !step.status)) {
    stepStatus = stepStatusId.notStarted;
  }

  let stepStatusLine = stepStatus;
  if (step.enable && step.sequence === 10 && !previouslyStepIsEnable) {
    stepStatusLine = stepStatusId.notStarted;
    stepStatus = stepStatusId.delivered;
  }

  const tootipCondition =
    hasDefaultFlag && step.stepId.id === timeLine.stepNames.concessionaria && stepStatus !== stepStatusId.delivered;

  const renderStepLine = () => (
    <>
      <div />
      <div />
      <div className="step-line ">
        <span className="line">
          <svg width="100%" viewBox="0 0 100 5" fill="none" preserveAspectRatio="xMidYMid meet">
            <line
              className={`timeline-line ${stepStatusLine}`}
              x1="6"
              y1="2.5"
              x2="100%"
              y2="2.5"
              strokeWidth="3"
              strokeLinecap="round"
              strokeDasharray="12 8"
            />
          </svg>
        </span>
        {/* <span className="reason-circle" /> */}
      </div>
      <div />
      <div />
      <div />
    </>
  );

  const formatDate = () => {
    if (!selectedIndustrialOrder.industrialOrder.patentDate) return 'No disponible';
    const dateObj = new Date(selectedIndustrialOrder.industrialOrder.patentDate);
    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const renderVinMilestone = () => {
    const isMilestoneDisabled = stepStatus !== stepStatusId.delivered;
    const Milestone = () => (
      <OrderInformationItem
        label="CHASSI"
        text={!isCurrentStep ? safeToUpperCase(selectedIndustrialOrder.industrialOrder.chassi) : null}
        isDisabled={stepStatus !== stepStatusId.delivered}
      />

    );
    return (
      <StepMilestone
        Milestone={Milestone}
        strokeColor={isMilestoneDisabled
          ? colorVariables.borderAndDividerColor : colorVariables.actionPrimaryColor}
      />
    );
  };

  const renderFechaPatentamientoMilestone = () => {
    const isMilestoneDisabled = stepStatus !== stepStatusId.delivered;
    const dateText = formatDate();
    const Milestone = () => (
      <OrderInformationItem
        label="FECHA PATENTAMIENTO"
        text={dateText}
        isDisabled={stepStatus !== stepStatusId.delivered}
      />
    );
    return (
      <StepMilestone
        Milestone={Milestone}
        strokeColor={isMilestoneDisabled ? colorVariables.borderAndDividerColor : colorVariables.actionPrimaryColor}
      />
    );
  };
  const hasFechaPatentamiento = () => {
    const dates = selectedIndustrialOrder.industrialOrder.dates || [];
    return dates.some(date => date.actualDate !== null && date.stepId.id === 'dealer'
      && date.status === 'delivered');
  };

  const isFechaPatentamiento = hasFechaPatentamiento();

  const renderFleetMilestone = () => {
    const Milestone = () => <FleetInformationButton vehicleFleet={vehicleFleet} />;
    return (
      <>
        <StepMilestone Milestone={Milestone} strokeColor={colorVariables.textSecondaryColor} />
      </>
    );
  };

  const renderMilestone = () => {
    if (isPDA) {
      return <div />;
    }

    if (!selectedIndustrialOrder.industrialOrder || selectedIndustrialOrder.industrialOrder.chassi == null) {
      return <div />;
    }
    const isDealer = User && User.get() && User.hasProfile(roleConsts.dealers);
    const isEmployee = User && User.get() && User.hasProfile(roleConsts.employees);

    const isVinStep =
      step &&
      step.stepId &&
      ((step.stepId.id === timeLine.stepNames.produzido &&
        ((isDealer && selectedIndustrialOrder.industrialOrder.invoiceAvailable) ||
          (isEmployee && selectedIndustrialOrder.industrialOrder.invoiceAvailable) ||
          ENV_ENVIRONMENT === environment.AR)) ||
        (step.stepId.id === timeLine.stepNames.montagem && !isDealer));

    const isFleetMilestone =
      vehicleFleet &&
      (stepStatus === stepStatusId.late || stepStatus === stepStatusId.inRisk || stepStatus === stepStatusId.onTime);

    const isFechaPatenStep = step && step.stepId
      && (step.stepId.id === timeLine.stepNames.concessionaria
      && (ENV_ENVIRONMENT === environment.AR)
      && (selectedIndustrialOrder.industrialOrder.brandName === 'CITROEN'
      || selectedIndustrialOrder.industrialOrder.brandName === 'PEUGEOT'
      || selectedIndustrialOrder.industrialOrder.brandName === 'DS'
      || selectedIndustrialOrder.industrialOrder.brandName === 'CITROEN/DS')
      && isFechaPatentamiento);

    if (isVinStep) {
      return renderVinMilestone();
    }

    if (isFleetMilestone) {
      return renderFleetMilestone();
    }

    if (isFechaPatenStep) {
      return renderFechaPatentamientoMilestone();
    }

    return <div />;
  };

  const wrapperComponentWithAlert = (component, condition) =>
    condition ? (
      <AlertComponent
        title={langValues.timeLineLabels.alertDefault.title}
        text={langValues.timeLineLabels.alertDefault.text}
        alertType={alertTypes.info}
        isToolTipOpen={isToolTipOpen}
        setIsToolTipOpen={setIsToolTipOpen}
      >
        {component}
      </AlertComponent>
    ) : (
      component
    );

  const renderStepStatus = () => {
    if (isAdmintrative) {
      return <div className="step-status" />;
    }

    const stepStatusComp = (
      <div className="step-status">
        <StepStatus step={step} emptyStatus={(isEmpty) => setIsStepStatusEmpty(isEmpty)} />
      </div>
    );
    return wrapperComponentWithAlert(stepStatusComp, !isStepStatusEmpty && tootipCondition);
  };

  const renderStepStatusCircle = () => {
    const stepIds = isAdmintrative ? step.sequence : step.stepId.id;
    const stepStatusCircle = (
      <div className="step-circle">
        <StepStatusCircle
          key={stepIds}
          step={step}
          isCurrentStep={isCurrentStep}
          size={step.isSubStep ? SUB_STEP_CIRCLE_SIZE : STEP_CIRCLE_SIZE}
        />
      </div>
    );
    return wrapperComponentWithAlert(stepStatusCircle, isStepStatusEmpty && tootipCondition);
  };

  const renderLogisticStatus = () => (
    <div className="step-status step-status--logistic">
      <div className="step-status--logistic--card">
        <p>Início Logística</p>
      </div>

      <div className="step-status--logistic--line" />
    </div>
  );

  const renderStepItem = () => {
    const stepDescription = isAdmintrative ? step.name : step.stepId.description;
    return (
      <>
        {renderMilestone()}
        {isCurrentStep && isAdmintrative ? renderLogisticStatus() : renderStepStatus()}
        <BootstrapTooltip
          open={isAdmintrative && showTimelineToolTip}
          onClick={step.enable && handleTimelineTooltip}
          placement="top"
          title={
            <ClickAwayListener
              onClickAway={() => step.enable && isAdmintrative && showTimelineToolTip && setShowTimelineToolTip(false)}
            >
              <div>
                <InformationTooltipPDA step={step} />
              </div>
            </ClickAwayListener>
          }
        >
          {renderStepStatusCircle()}
        </BootstrapTooltip>

        <div className="step-name">
          <div className={`font-small1 ${stepStatus}`}>{stepDescription}</div>
        </div>
        <StepDate
          step={step}
          status={stepStatus}
          rules={rules}
          isAdmintrative={isAdmintrative}
          previouslyStepIsEnable={previouslyStepIsEnable}
        />
      </>
    );
  };

  if (isSingleStep) {
    return renderStepItem();
  }

  return (
    <>
      {renderStepLine()}
      {renderStepItem()}
    </>
  );
};

TimelineItem.propTypes = {
  selectedIndustrialOrder: PropTypes.object.isRequired,
  vehicleFleet: PropTypes.object,
  step: PropTypes.object.isRequired,
  isSingleStep: PropTypes.bool,
  isCurrentStep: PropTypes.bool,
  hasDefaultFlag: PropTypes.bool,
  isPDA: PropTypes.bool,
  isAdmintrative: PropTypes.bool,
  rules: PropTypes.object,
  previouslyStepIsEnable: PropTypes.bool,
};

export default TimelineItem;
