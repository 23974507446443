import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';
import { ReactComponent as EmptyState } from 'Common/Assets/empty_state.svg';
import { ReactComponent as CloseIcon } from 'Common/Assets/close-cross.svg';
import { Modal } from '@mui/material';
import { parseDateUTCtoLocalTimezone, getDateAsMediumText } from 'Utils/dateUtils';
import langValues from 'Config/i18n/index';
import './ReleaseNotesModal.scss';
import { User } from 'Common/Helpers/AuthHelper';
import { roleConsts } from 'Utils/consts';
import { Backdrop } from './styles';

const renderSkeleton = (nItens) => {
  const skeleton = [];
  for (let i = 0; i < nItens; i += 1) {
    skeleton.push(
      <>
        <div className="skeleton-item" key={`${i}-1`} />
        <div className="skeleton-item" key={`${i}-2`} />
        <div className="skeleton-item" key={`${i}-3`} />
      </>
    );
  }
  return skeleton;
};

const ReleaseNotesModal = ({
  isReleaseNotesModalOpened,
  setReleaseNotesModalOpened,
  loadReleaseNotes,
  releaseNotesResponse,
  releaseNotes,
}) => {
  const { error, loading } = releaseNotesResponse;
  useEffect(() => {
    if (!User.hasProfile(roleConsts.employees) && isReleaseNotesModalOpened) {
      loadReleaseNotes();
    }
  }, [isReleaseNotesModalOpened]);

  const handleCloseModal = () => {
    setReleaseNotesModalOpened(false);
  };

  const renderCloseIcon = () => (
    <div className="closeIcon">
      <CloseIcon onClick={() => handleCloseModal()} />
    </div>
  );

  const parseHtmlString = (inputString) => parse(inputString);

  const renderReleaseTitle = (releaseTitle) => {
    if (!releaseTitle) {
      return null;
    }

    return (
      <div className="releaseTitle">
        <h2>{releaseTitle}</h2>
      </div>
    );
  };

  const getReleaseDateAsText = (releaseDate) => {
    if (!releaseDate) {
      return null;
    }

    const date = parseDateUTCtoLocalTimezone(new Date(releaseDate));

    return <span className="releaseDate">{getDateAsMediumText(date)}</span>;
  };

  const renderReleaseDetailedText = (releaseDetailedText) => {
    if (!releaseDetailedText) {
      return null;
    }

    return <div className="releaseDetailedText">{parseHtmlString(releaseDetailedText)}</div>;
  };

  const renderReleaseImg = (releaseImage, releaseCategory) => {
    if (!releaseImage) {
      return null;
    }
    return (
      <div className="releaseImage">
        <img src={releaseImage} alt={releaseCategory} />
      </div>
    );
  };

  const renderReleaseNotes = () => {
    if (loading) return renderSkeleton(10);
    if (error) {
      return (
        <div className="releaseError">
          <EmptyState className="releaseEmptyState" />
          <h5>{langValues.releaseNoteLabels.errorTitle}</h5>
          <span className="font-subtitle2">{langValues.releaseNoteLabels.errorSubtitle}</span>
          <MaterialButton onClick={() => loadReleaseNotes()} buttonText="Recarregar" variant="outlined" />
        </div>
      );
    }
    if (releaseNotes.length === 0) {
      return (
        <div className="releaseError">
          <EmptyState className="releaseEmptyState" />
          <h5>{langValues.releaseNoteLabels.notFoundTitle}</h5>
          <span className="font-subtitle2">{langValues.releaseNoteLabels.notFoundSubtitle}</span>
        </div>
      );
    }
    return releaseNotes.map((release, index) => (
      <article key={index.toString()} className="releaseNote">
        {renderReleaseTitle(release.title)}
        {getReleaseDateAsText(release.insertionDate)}
        {renderReleaseDetailedText(release.detailedText)}
        {renderReleaseImg(release.imageUrl, release.category)}
      </article>
    ));
  };

  return (
    <Modal
      BackdropComponent={Backdrop}
      open={isReleaseNotesModalOpened}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setReleaseNotesModalOpened(false);
        }
      }}
      style={{ display: 'flex' }}
    >
      <div id="releaseNotesModal">
        <header>
          <h1>{langValues.lastUpdates}</h1>
          {renderCloseIcon()}
        </header>
        <section className="releaseNotesModal__body">{renderReleaseNotes()}</section>
      </div>
    </Modal>
  );
};

ReleaseNotesModal.propTypes = {
  isReleaseNotesModalOpened: PropTypes.bool.isRequired,
  loadReleaseNotes: PropTypes.func.isRequired,
  setReleaseNotesModalOpened: PropTypes.func.isRequired,
  releaseNotes: PropTypes.array.isRequired,
  releaseNotesResponse: PropTypes.object.isRequired,
};

export default ReleaseNotesModal;
