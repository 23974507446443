import { connect } from 'react-redux';
import IndustrialOrdersSlider from './IndustrialOrdersSlider';

const mapStateToProps = (state) => {
  const { orderDetail } = state;
  const { currentActiveTab } = orderDetail;
  return { currentActiveTab };
};

export default connect(
  mapStateToProps,
  null,
)(IndustrialOrdersSlider);
