import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as AlertIcon } from 'Common/Assets/alert-triangle.svg';

import './DelayReasonsCard.scss';
import langValues from 'Config/i18n/index';
import colorVariables from 'Utils/Styles/colorVariables';

function renderDetailText(text) {
  return (
    <li key={text} className="delayReasons-text">
      {text}
    </li>
  );
}

function renderDetailDiv(delayReasonsList) {
  return (
    <div className="delayReasonsList">
      <ul>{delayReasonsList.map((reason) => renderDetailText(reason))}</ul>
    </div>
  );
}

function DelayReasonsCard({ delayReasonsList = [] }) {
  const atraso = langValues.labelsPedidoComercial.atrasoPedido;
  return (
    <div className="statusDetails">
      <div className="alert-icon">
        <AlertIcon style={{ fill: colorVariables.errorColor }} />
      </div>
      <div className="texts">
        <div className="text-alert">{atraso}</div>
        <div className="delayReasons">{renderDetailDiv(delayReasonsList)}</div>
      </div>
    </div>
  );
}

DelayReasonsCard.propTypes = {
  delayReasonsList: PropTypes.array,
};

export default DelayReasonsCard;
