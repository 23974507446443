import colorVariables from 'Utils/Styles/colorVariables';
import { fontButton } from 'Utils/Styles/fontVariables';

export const defaultButtonStyles = {
  root: {
    ...fontButton,
    padding: '8px 16px',
    color: colorVariables.textBlackColor,
    fill: colorVariables.textBlackColor,
    border: '1px solid',
    borderColor: colorVariables.textSecondaryColor,
    textTransform: 'none',
  },
};

export const activeButtonStyles = {
  root: {
    ...defaultButtonStyles.root,
    backgroundColor: colorVariables.backgroundPrimaryColor,
    color: colorVariables.actionPrimaryColor,
    fill: colorVariables.actionPrimaryColor,
  },
  endIcon: {
    '&:hover': {
      fill: colorVariables.errorColor,
    },
  },
};

export default null;
