import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@mui/material';

const PopoverMenu = (props) => {
  const { children } = props;
  delete props.children;
  return <Popover {...props}>{children}</Popover>;
};

PopoverMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  classes: PropTypes.object,
  children: PropTypes.object.isRequired,
};

export default PopoverMenu;
