import MuiBackdrop from '@mui/material/Backdrop';
import MuiRadio from '@mui/material/Radio';
import MuiTypography from '@mui/material/Typography';
import MuiButton from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';

export const Backdrop = withStyles(MuiBackdrop, {
  root: {
    backgroundColor: 'rgba(12,28,45,0.5)',
  },
});

export const Typography = withStyles(MuiTypography, {
  root: {
    color: '#5E5E5E',
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: '0',
    lineHeight: '21px',
    padding: '4px',
    fontFamily: 'inherit',
  },
});

export const Radio = withStyles(MuiRadio, (_theme, _params, classes) => ({
  root: {
    padding: 0,
    color: '#758CA5',

    [`&.${classes.checked}`]: {
      color: '#657DD9',
    },
  },
  checked: {},
}));

export const selectStyles = () => ({
  indicatorSeparator: () => null,
  container: (styles) => ({
    ...styles,
    width: '100%',
    height: '32px',
    marginBottom: '8px',
  }),
  control: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '32px',
    border: '1px solid #758CA580',
    borderColor: 'none',
    borderRadius: '5px',
    boxShadow: 'none',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#758CA5',
    marginRight: '12px',
    padding: 0,
    transform: 'scale(0.8)',
  }),
  placeholder: (styles) => ({
    ...styles,
    paddingLeft: '8px',
    color: '#5E5E5E',
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: '9999',
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: '4px',
  }),
  menuList: (styles) => ({
    ...styles,
    height: '168px',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#5E5E5E',
  }),
  option: (styles, state) => {
    const selectedStyle = state.isSelected
      ? {
          fontWeight: 'bold',
          backgroundColor: 'rgba(93,127,200,0.1)',
        }
      : null;

    return {
      ...styles,
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      color: '#5E5E5E',
      fontFamily: 'Helvetica Neue',
      fontSize: '14px',
      lineHeight: '17px',
      ...selectedStyle,

      ':hover': {
        backgroundColor: '#DEEBFF',
      },
    };
  },
});

export const Button = withStyles(MuiButton, {
  root: {
    textTransform: 'none',
  },
});

export default null;
