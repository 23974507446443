import React from 'react';
import HelpIconTooltip from 'Components/Shared/HelpIconTooltip/HelpIconTooltip';
import SortingIcon from 'Components/Atoms/SortingIcon/SortingIcon';
import { safeToUpperCase } from 'Utils/safeMethods';
import './HeadersWithTooltips.scss';
import { trackedButtons, trackedEvents } from 'Utils/consts';
import { trackEvent } from 'Utils/analytics';

const getHeadersHelpIcon = (item) => {
  if (item.helpText) {
    return (
      <HelpIconTooltip tooltipText={item.helpText} />
    );
  }
  return null;
};

const handleSortingsClick = (value, loadList, setOrderSorting) => {
  trackEvent(trackedEvents.buttonClick, { button: trackedButtons.sortingManagement, value });
  setOrderSorting(value);
  loadList();
};

const getHeadersSortingIcon = (item, sorting, setOrderSorting, loadList) => {
  if (item.sorting) {
    return (
      <div className="sortingIcon">
        <SortingIcon
          isSortingIconClicked={item.sorting === sorting}
          sortingOnClick={value => handleSortingsClick(value, loadList, setOrderSorting)}
          sortingName={item.sorting}
        />
      </div>
    );
  }
  return null;
};

export const getHeadersWithTooltips = (
  headerItems,
  sorting,
  setOrderSorting,
  loadList,
) => headerItems.map(item => (
  <div className="labelWithIconDiv">
    {getHeadersSortingIcon(item, sorting, setOrderSorting, loadList)}
    <div className="labelIcon">{safeToUpperCase(item.label)}</div>
    {getHeadersHelpIcon(item)}
  </div>
));

export default null;
