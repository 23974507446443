/* eslint-disable react/prop-types */
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { ReactComponent as NoIndustrialOrders } from 'Common/Assets/NoIndustrialOrders.svg';
import { ReactComponent as LeftArrowIcon } from 'Common/Assets/leftArrow.svg';
import { stepStatusId } from 'Utils/consts';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ResultResponse from 'Components/Atoms/ResultResponse';
import OrderInformationCard from 'Components/Organisms/OrderInformationCard/index';
import langValues from 'Config/i18n/index';

import TimelineAdministrativePDA from 'Components/Organisms/TimelineAdministrativePDA/index';
import './OrderDetailPDA.scss';
import Skelleton from './Skelleton';

/*eslint-disable*/

const OrderDetailPDA = ({
  params,
  loadOrderById,
  order = null,
  clearDuck,
  pda,
  loadOrderByIdPda,
  clearDuckPda,
  loading,
  currentActiveTab,
  requestSuccess,
  changeSelectedIndustrialOrder,
  ...props
}) => {
  // const trackPageView = (orderType) => {
  //   const industrialOrder = {
  //     brandName: null,
  //     modelName: null,
  //   };
  //   if (order !== null) {
  //     const { industrialOrdersList } = order;
  //     if (industrialOrdersList.length > 0) {
  //       industrialOrder.brandName = industrialOrdersList[0].brandName;
  //       industrialOrder.modelName = industrialOrdersList[0].modelName;
  //     }
  //   }

  //   trackEvent(trackedEvents.pageView, {
  //     page: trackedPages.detalhes,
  //     orderType,
  //     brand: industrialOrder.brandName,
  //     model: industrialOrder.modelName,
  //   });
  // };

  const isFirstUpdate = useRef(true);
  const { orderId, contractCode, brandCode } = params;

  useLayoutEffect(() => {
    clearDuckPda();
    clearDuck();
    loadOrderByIdPda({ contractCode, brandCode }, () => {});

    if (Number(orderId)) {
      loadOrderById(orderId, () => {});
    }
  }, []);

  const industrialOrdersList = order ? order.industrialOrdersList : [];
  const steps = industrialOrdersList.length > 0 ? industrialOrdersList[0].dates : [];
  const stepsAdministrative = pda && pda.stepsList;
  const pdaContract = pda && pda.contractInfo;

  const selectFirstIndustrialOrder = () => {
    if (industrialOrdersList.length > 0) {
      changeSelectedIndustrialOrder(industrialOrdersList[0]);
    } else {
      changeSelectedIndustrialOrder(null);
    }
  };

  useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    } else if (requestSuccess) {
      selectFirstIndustrialOrder();
    }
  }, [requestSuccess, currentActiveTab]);

  const renderReturnButton = () => {
    const { location } = props;
    const { search } = location;
    const previousPage = new URLSearchParams(search).get('previousPage') || 'pedidos';
    return (
      <div className="orderDetail-returnButton">
        <span>
          <Link
            to={`/${previousPage}`}
            className="orderDetail-returnButton-text"
            // onClick={() => trackEvent(
            //   trackedEvents.buttonClick, { button: trackedButtons.returnToList },
            // )}
          >
            <LeftArrowIcon />
            {langValues.telaDetalhesPedidos.returnToList}
          </Link>
        </span>
      </div>
    );
  };

  const renderOrderInformation = () => (
    <OrderInformationCard pdaContract={pdaContract} isAdministrativePDA={pdaContract && steps.length <= 0} />
  );

  const renderIndustrialOrderSections = (isAdmintrative) => {
    if (order && order.statusId === stepStatusId.withoutOrder) {
      return (
        <ResultResponse
          Icon={NoIndustrialOrders}
          title={langValues.orderDetail.noIndustrialOrdersTitle}
          subtitle={langValues.orderDetail.noIndustrialOrdersSubtitle}
        />
      );
    }

    return (
      <TimelineAdministrativePDA steps={isAdmintrative ? stepsAdministrative : steps} isAdmintrative={isAdmintrative} />
    );
  };

  if (loading) {
    return <Skelleton />;
  }

  return (
    <main className="orderDetail">
      {renderReturnButton()}
      {renderOrderInformation()}
      {stepsAdministrative && stepsAdministrative.length > 0 && (
        <section className="timelineCard">
          <h1>Administrativa</h1>

          {renderIndustrialOrderSections(true)}
        </section>
      )}

      {(steps.length > 0 || order) && (
        <section className="timelineCard logistic">
          <h1>Logística</h1>

          {renderIndustrialOrderSections()}
        </section>
      )}
    </main>
  );
};

OrderDetailPDA.propTypes = {
  params: PropTypes.object.isRequired,
  order: PropTypes.object,
  loadOrderById: PropTypes.func.isRequired,
  clearDuck: PropTypes.func.isRequired,
  pda: PropTypes.object,
  loadOrderByIdPda: PropTypes.func.isRequired,
  clearDuckPda: PropTypes.func.isRequired,
  changeSelectedIndustrialOrder: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  currentActiveTab: PropTypes.bool.isRequired,
  requestSuccess: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }).isRequired,
};

export default OrderDetailPDA;
