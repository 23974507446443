import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { trackEvent } from 'Utils/analytics';
import { User } from 'Common/Helpers/AuthHelper';
import { roleConsts, toggleFeatureKeys, trackedEvents, trackedButtons } from 'Utils/consts';
import { Link } from 'react-router-dom';
import { TOGGLE_FEATURES } from 'Common/Helpers/FeaturesHelper';
import Divider from '@mui/material/Divider';
import Popper from '@mui/material/Popper';
import langValues from 'Config/i18n/index';
import { ReactComponent as HelpButtonIcon } from 'Common/Assets/HelpButtonIcon.svg';
import { Menu, MenuItem } from './styles';
import FeedbackModal from '../FeedbackModal';
import ReleaseNotesModal from '../ReleaseNotesModal';
import FeedbackTip from '../FeedbackTip/FeedbackTip';
import './HelpButton.scss';

const HelpButton = ({
  setFeedbackModalOpened,
  clearFeedbackState,
  declineFeedbackTip,
  setReleaseNotesModalOpened,
  clearReleaseNotesState,
  whenShouldOpenFeedbackTip,
}) => {
  useEffect(() => {
    clearFeedbackState();
    clearReleaseNotesState();
  }, []);
  const [buttonAnchorEl, setButtonAnchorEl] = useState(null);
  const [popperAnchorEl, setPopperAnchorEl] = useState(null);

  const checkTimeOpenedFeedback = () => {
    if (!whenShouldOpenFeedbackTip) return true;

    const currentUnixTime = moment().valueOf();
    return whenShouldOpenFeedbackTip <= currentUnixTime;
  };

  const divRef = useRef();
  useEffect(() => {
    if (divRef && checkTimeOpenedFeedback()) {
      setPopperAnchorEl(divRef.current);
    } else {
      setPopperAnchorEl(null);
    }
  }, [divRef, whenShouldOpenFeedbackTip]);

  const handleButtonClick = (event) => {
    setButtonAnchorEl(event.currentTarget);
    trackEvent(trackedEvents.buttonClick, { button: trackedButtons.openHelpButton });
  };

  const handleButtonClose = () => {
    setButtonAnchorEl(null);
  };

  const handleFeedbackClick = () => {
    handleButtonClose();
    setFeedbackModalOpened(true);
    trackEvent(trackedEvents.buttonClick, { button: trackedButtons.openFeedback });
  };

  const handleReleaseNotesClick = () => {
    handleButtonClose();
    setReleaseNotesModalOpened(true);

    trackEvent(trackedEvents.buttonClick, { button: trackedButtons.openReleaseNotes });
  };

  const renderHelpButton = () => (
    <button ref={divRef} type="button" className="menuItem helpButton" onClick={(e) => handleButtonClick(e)}>
      <HelpButtonIcon className="helpButton-icon" />
    </button>
  );

  const menuItems = [];

  const renderFAQMenuItem = () => {
    if (User.hasProfile(roleConsts.dealers)) {
      menuItems.push(
        <Link to="/faq" className="helpButton__faqLink">
          <MenuItem key="faq" onClick={handleButtonClose}>
            {langValues.commonQuestions}
          </MenuItem>
        </Link>
      );
      menuItems.push(<Divider sx={{ margin: '0px !important' }} key="dividerFaq" variant="middle" />);
    }
  };

  const renderReleaseNotes = () => {
    if (!User.hasProfile(roleConsts.employees)) {
      menuItems.push(<Divider sx={{ margin: '0px !important' }} key="dividerReleaseNotes" variant="middle" />);
      menuItems.push(
        <MenuItem key="releaseNotes" onClick={handleReleaseNotesClick}>
          {langValues.lastUpdates}
        </MenuItem>
      );
    }
  };

  const renderMenu = () => {
    renderFAQMenuItem();
    menuItems.push(
      <MenuItem key="feedback" onClick={handleFeedbackClick}>
        {langValues.feedback}
      </MenuItem>
    );
    renderReleaseNotes();

    return (
      <Menu
        id="helpButton-popover"
        anchorEl={buttonAnchorEl}
        keepMounted
        open={Boolean(buttonAnchorEl)}
        onClose={handleButtonClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {menuItems}
      </Menu>
    );
  };

  const renderFeedbackTip = () => (
    <Popper
      open={Boolean(popperAnchorEl)}
      anchorEl={popperAnchorEl}
      placement="left-end"
      style={{
        marginLeft: '64px',
      }}
      disablePortal={false}
      modifiers={[
        {
          name: 'flip',
          options: {
            fallbackPlacements: [],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            altBoundary: true,
          },
        },
      ]}
      className="helpButton__popover"
    >
      <FeedbackTip declineFeedbackTip={declineFeedbackTip} setFeedbackModalOpened={setFeedbackModalOpened} />
    </Popper>
  );

  return TOGGLE_FEATURES && TOGGLE_FEATURES[toggleFeatureKeys.helpButton] ? (
    <>
      {renderHelpButton()}
      {renderFeedbackTip()}
      {renderMenu()}
      <FeedbackModal />
      <ReleaseNotesModal />
    </>
  ) : null;
};

HelpButton.propTypes = {
  setFeedbackModalOpened: PropTypes.func.isRequired,
  clearFeedbackState: PropTypes.func.isRequired,
  setReleaseNotesModalOpened: PropTypes.func.isRequired,
  clearReleaseNotesState: PropTypes.func.isRequired,
  declineFeedbackTip: PropTypes.func.isRequired,
  whenShouldOpenFeedbackTip: PropTypes.number,
};

export default HelpButton;
