import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import langValues from 'Config/i18n/index';
import { emptyDate } from 'Utils/consts';
import { getDateAsText } from 'Utils/dateUtils';

import './UpdateDate.scss';

const UpdateDate = ({ lastUpdateDate, loadLastUpdateDate }) => {
  useEffect(() => {
    loadLastUpdateDate();
  }, []);

  let updateDateText = emptyDate;
  if (lastUpdateDate) {
    updateDateText = getDateAsText(new Date(lastUpdateDate));
  }

  return (
    <span className="updateDate">
      {langValues.lastUpdate}
      {updateDateText}
    </span>
  );
};

UpdateDate.propTypes = {
  loadLastUpdateDate: PropTypes.func.isRequired,
  lastUpdateDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default UpdateDate;
