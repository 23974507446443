import { connect } from 'react-redux';
import { loadUnregisteredData, loadUnregisteredVersions } from 'Infrastructure/Ducks/ParametersDuck';
import UnregisteredDataModal from './UnregisteredDataModal';

const mapStateToProps = (state) => {
  const { parametersDuck } = state;
  return { parametersDuck };
};

const mapDispatchToProps = (dispatch) => ({
  loadUnregisteredData: () => {
    dispatch(loadUnregisteredData());
  },
  loadUnregisteredVersions: () => {
    dispatch(loadUnregisteredVersions());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UnregisteredDataModal);
