import langValues from 'Config/i18n/index';
import React, { useEffect } from 'react';
import { trackEvent } from 'Utils/analytics';
import { trackedEvents } from 'Utils/consts';
import FAQQuestion from 'Components/FAQComponents/FAQQuestion';
import './FAQ.scss';

const FAQ = () => {
  useEffect(() => {
    trackEvent(trackedEvents.pageView, { page: 'FAQ' });
  }, []);

  const renderQuestions = () => {
    let questionNumber = 0;
    return langValues.faqQuestions.map((question) => {
      questionNumber += 1;
      return (
        <FAQQuestion
          key={questionNumber}
          questionNumber={questionNumber}
          questionText={question.questionText}
          answer={question.answer}
        />
      );
    });
  };

  return (
    <div id="faq">
      <header className="page-header">{langValues.commonQuestions}</header>
      <main className="faq__questions">{renderQuestions()}</main>
    </div>
  );
};

export default FAQ;
