import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import List from 'Components/Shared/List/List';
import {
  safeNormalizeString,
  safeToCapitalizeAllFirstLetters,
  safeToLowerCase,
  safeToUpperCase,
} from 'Utils/safeMethods';
import langValues from 'Config/i18n/index';
import ListRegionCities from 'Components/Molecules/ListRegionCities/ListRegionCities';
import { getHeadersWithTooltips } from 'Components/Molecules/HeadersWithTooltips/HeadersWithTooltips';
import SearchDropdown from 'Components/FilterComponents/SearchDropdown/SearchDropdown';
import ExportButton from 'Components/Shared/ExportButton/ExportButton';
import { trackEvent } from 'Utils/analytics';
import { trackedEvents, roleConsts } from 'Utils/consts';
import { User } from 'Common/Helpers/AuthHelper';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';
import SnackBarExport from 'Components/Atoms/SnackBarExport/SnackBarExport';
import ConfirmationModal from 'Components/Molecules/ConfirmationModal/ConfirmationModal';
import SnackbarTransactions from 'Components/Atoms/SnackbarTransactions/SnackbarTransactions';
import colorVariables from 'Utils/Styles/colorVariables';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';

const ForecastTransitTime = ({
  listRegion,
  exportCities,
  clearExportCities,
  loadListRegionWithCities,
  clearList,
  renderSkeleton,
  renderErrorMessage,
  saveTransitTime,
  clearErrorSaveTransitTime,
}) => {
  const {
    loadingRegionWithCities,
    errorLoadingRegionWithCities,
    regionWithCities,
    citiesExport,
    successSavingTransitTime,
    errorSavingTransitTime,
  } = listRegion;

  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [searchCity, setSearchCity] = useState('');
  const [updatableTransitTime, setUpdatableTransitTime] = useState({});
  const [editing, setEditing] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  useEffect(() => {
    clearList();
    loadListRegionWithCities();
  }, []);

  useEffect(() => {
    setUpdatableTransitTime(JSON.parse(JSON.stringify(regionWithCities)));
  }, [regionWithCities]);

  const getRegionCitiesListHeaders = () => {
    const { destinyTitle, destinations } = langValues.labelsTransitTime;
    const headerItems = [
      { label: '' },
      { label: safeToUpperCase(destinyTitle) },
      { label: safeToUpperCase(destinations[0]) },
      { label: safeToUpperCase(destinations[1]) },
      { label: safeToUpperCase(destinations[2]) },
    ];
    if (ENV_ENVIRONMENT === 'BR') {
      headerItems.push({ label: safeToUpperCase(destinations[3]) });
    }
    return getHeadersWithTooltips(headerItems);
  };

  const handleInputChanging = (district, localName, origin, value) => {
    const changedTransitTime = JSON.parse(JSON.stringify(updatableTransitTime));
    if (district) {
      // eslint-disable-next-line max-len
      const safeLocalName = Object.keys(changedTransitTime[district].cities).find(key => key.toLowerCase() === localName.toLowerCase());
      // eslint-disable-next-line max-len
      changedTransitTime[district].cities[safeLocalName].transitTime[safeToUpperCase(origin)] = value;
    } else {
      changedTransitTime[localName].transitTime[safeToUpperCase(origin)] = value;
    }
    setUpdatableTransitTime(changedTransitTime);
  };

  const getEditedTransitTime = () => {
    const editedTransitTime = [];
    Object.keys(updatableTransitTime).forEach((region) => {
      Object.keys(updatableTransitTime[region].transitTime).forEach((origin) => {
        // eslint-disable-next-line max-len
        if (updatableTransitTime[region].transitTime[origin] !== regionWithCities[region].transitTime[origin]) {
          editedTransitTime.push({
            origin,
            destinyState: region,
            dealerRef: updatableTransitTime[region].transitTime[origin],
          });
        }
      });

      Object.keys(updatableTransitTime[region].cities).forEach((city) => {
        Object.keys(updatableTransitTime[region].cities[city].transitTime).forEach((origin) => {
          // eslint-disable-next-line max-len
          if (updatableTransitTime[region].cities[city].transitTime[origin] !== regionWithCities[region].cities[city].transitTime[origin]) {
            editedTransitTime.push({
              origin,
              destinyState: region,
              destinyCity: city,
              dealerRef: updatableTransitTime[region].cities[city].transitTime[origin],
            });
          }
        });
      });
    });
    return editedTransitTime;
  };

  const handleNonChanging = () => {
    setEditing(false);
    setUpdatableTransitTime(JSON.parse(JSON.stringify(regionWithCities)));
  };

  const renderCancelingButton = editedTransitTimeExists => (
    <div className="cancelingButton">
      <MaterialButton
        onClick={
          editedTransitTimeExists
            ? () => setCancelModalOpen(true)
            : () => handleNonChanging()}
        buttonText={langValues.parametersTimeLabels.cancel}
        type="secondary"
        variant="outlined"
      />
    </div>
  );

  const renderSaveButton = editedTransitTimeExists => (
    <MaterialButton
      onClick={
        editedTransitTimeExists
          ? () => setSaveModalOpen(true)
          : () => handleNonChanging()
      }
      buttonText={langValues.parametersTimeLabels.save}
      type="primary"
      variant="contained"
    />
  );

  const renderEditingButtons = () => {
    if (User.get().userProfile.includes(roleConsts.admin)) {
      if (editing) {
        const editedTransitTimeExists = getEditedTransitTime().length > 0;
        return (
          <div className="editingButtons">
            {renderCancelingButton(editedTransitTimeExists)}
            {renderSaveButton(editedTransitTimeExists)}
          </div>
        );
      }
      return (
        <MaterialButton
          onClick={() => setEditing(true)}
          buttonText={langValues.parametersTimeLabels.edit}
          type="primary"
          variant="contained"
        />
      );
    }
    return null;
  };

  const handleCancelingConfirmation = () => {
    setUpdatableTransitTime(JSON.parse(JSON.stringify(regionWithCities)));
    setCancelModalOpen(false);
    setEditing(false);
  };

  const handleSavingConfirmation = async () => {
    const success = await saveTransitTime(getEditedTransitTime());
    setSaveModalOpen(false);
    if (success) {
      setEditing(false);
    }
  };

  const testEdited = (localName, transitTime, district, origin) => {
    if (district) {
      // eslint-disable-next-line max-len
      return transitTime[safeToUpperCase(origin)] !== regionWithCities[district].cities[localName].transitTime[safeToUpperCase(origin)];
    }
    // eslint-disable-next-line max-len
    return transitTime[safeToUpperCase(origin)] !== regionWithCities[localName].transitTime[safeToUpperCase(origin)];
  };

  const addRegionCitiesToList = (localName, transitTime, district) => {
    const { destinations } = langValues.labelsTransitTime;
    const regionCitiesDestinations = Object.values(destinations).map(item => ({
      description: item,
      parameter: transitTime[safeToUpperCase(item)] || transitTime[item] || null,
      isEdited: testEdited(localName, transitTime, district, item),
    }));
    const name = district ? safeToCapitalizeAllFirstLetters(localName) : safeToUpperCase(localName);
    const isSelected = name === selectedRegion;
    const regionCitiesItem = {
      name,
      destinations: regionCitiesDestinations,
      isSelected,
      setSelectedRegion,
      district,
    };
    return regionCitiesItem;
  };

  const getRegionCitiesListItems = () => {
    const regionCitiesMap = JSON.parse(JSON.stringify(updatableTransitTime));
    if (selectedRegion && selectedCity) {
      const cityValue = regionCitiesMap[selectedRegion].cities[selectedCity];
      return [addRegionCitiesToList(selectedCity, cityValue.transitTime, selectedRegion)];
    }

    const listRegionCities = [];
    Object.entries(regionCitiesMap).forEach(([region, value]) => {
      listRegionCities.push(addRegionCitiesToList(region, value.transitTime, null));
      const { cities } = value;
      const isSelectedRegion = selectedRegion && region === selectedRegion;

      if (isSelectedRegion) {
        const listCities = [];
        Object.entries(cities).forEach(([city, cityValue]) => {
          listCities.push(addRegionCitiesToList(city, cityValue.transitTime, selectedRegion));
        });
        listCities.sort((a, b) => (
          safeNormalizeString(a.name) > safeNormalizeString(b.name) ? 1 : -1
        ));
        listRegionCities.push(...listCities);
      }
    });
    return listRegionCities;
  };

  const getCityOption = (city, region, label, isSelected) => ({
    value: {
      city,
      region,
    },
    label,
    isSelected,
  });

  const getCities = () => {
    const regionCitiesMap = { ...regionWithCities };
    const listCities = [];

    Object.entries(regionCitiesMap).forEach(([region, value]) => {
      const { cities } = value;
      Object.keys(cities).forEach((city) => {
        const label = `${safeToCapitalizeAllFirstLetters(city)} - ${region}`;
        const smallCaseCity = safeToLowerCase(label);
        if (smallCaseCity.includes(safeToLowerCase(searchCity))) {
          const isCityEqualSelected = city === selectedCity;
          listCities.push(getCityOption(city, region, label, isCityEqualSelected));
        }
      });
    });
    // eslint-disable-next-line max-len
    listCities.sort((a, b) => (safeNormalizeString(a.value.city) > safeNormalizeString(b.value.city) ? 1 : -1));

    return listCities;
  };

  const renderCityFilter = () => {
    if (loadingRegionWithCities) {
      return <div className="skeleton-item" style={{ width: '260px', margin: '32px 0px' }} />;
    }

    if (errorLoadingRegionWithCities || !regionWithCities) return null;

    const citySelectorOptions = getCities();

    return (
      <SearchDropdown
        entries={citySelectorOptions}
        filtered={selectedCity !== null}
        handleTextChange={value => setSearchCity(value)}
        searchText={searchCity}
        handleItemSelection={(item) => {
          setSelectedRegion(item.region);
          setSelectedCity(item.city);
        }}
        handleClearSelection={() => {
          setSelectedRegion(null);
          setSelectedCity(null);
        }}
        placeholder={langValues.labelsTransitTime.filterCity}
      />
    );
  };

  const handleDownloadReport = (fileType) => {
    trackEvent(trackedEvents.dataExport, { format: fileType });
    exportCities(fileType);
  };

  const renderExportButton = () => <ExportButton handleDownloadReport={handleDownloadReport} />;

  const renderList = () => {
    if (loadingRegionWithCities) return renderSkeleton(10);
    if (errorLoadingRegionWithCities || !regionWithCities) {
      return renderErrorMessage();
    }
    return (
      <List
        header={getRegionCitiesListHeaders()}
        Type={ListRegionCities}
        childProps={{
          listItems: getRegionCitiesListItems(),
          isEditingList: editing,
          handleInputChanging,
        }}
      />
    );
  };

  return (
    <div className="modelVersionForecast-tableContent">
      <div className="modelVersionForecast-filter filterWithExport">
        {renderCityFilter()}
        <div className="rightSideTransitTime">
          {!editing ? renderExportButton() : null}
          {renderEditingButtons()}
        </div>
      </div>
      <div className="modelVersionForecast-tableWrapper">
        <div className={`modelVersionForecast-table tab1 ${ENV_ENVIRONMENT}`}>{renderList()}</div>
        <SnackBarExport open={citiesExport.error} onClose={clearExportCities} />
      </div>
      <ConfirmationModal
        isOpen={saveModalOpen}
        title={langValues.labelsTransitTime.save}
        text={langValues.labelsTransitTime.saveModalText}
        confirmButtonColor={colorVariables.actionPrimaryColor}
        confirmButtonText={langValues.save}
        onClose={() => setSaveModalOpen(false)}
        onConfirm={() => handleSavingConfirmation()}
        onCancel={() => setSaveModalOpen(false)}
      />
      <ConfirmationModal
        isOpen={cancelModalOpen}
        title={langValues.labelsTransitTime.discardModalTitle}
        text={langValues.labelsTransitTime.discardModalText}
        confirmButtonColor={colorVariables.errorColor}
        confirmButtonText={langValues.discard}
        onClose={() => setCancelModalOpen(false)}
        onConfirm={() => handleCancelingConfirmation()}
        onCancel={() => setCancelModalOpen(false)}
      />
      <SnackbarTransactions
        type="success"
        isOpen={successSavingTransitTime}
        message={langValues.labelsTransitTime.success}
      />
      <SnackbarTransactions
        type="failure"
        isOpen={errorSavingTransitTime}
        message={langValues.labelsTransitTime.error}
        onClose={clearErrorSaveTransitTime}
        shouldRenderOnCloseButton
      />
    </div>
  );
};

ForecastTransitTime.propTypes = {
  renderErrorMessage: PropTypes.func.isRequired,
  renderSkeleton: PropTypes.func.isRequired,
  listRegion: PropTypes.object.isRequired,
  loadListRegionWithCities: PropTypes.func.isRequired,
  clearList: PropTypes.func.isRequired,
  exportCities: PropTypes.func.isRequired,
  clearExportCities: PropTypes.func.isRequired,
  saveTransitTime: PropTypes.func.isRequired,
  clearErrorSaveTransitTime: PropTypes.func.isRequired,
};

export default ForecastTransitTime;
