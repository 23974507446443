import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as AdministracaoPublicaFederalIcon } from 'Common/Assets/ADMINISTRACAO_PUBLICA_FEDERAL.svg';
import { ReactComponent as AdministracaoPublicaEstadualIcon } from 'Common/Assets/ADMINISTRACAO_PUBLICA_ESTADUAL.svg';
import { ReactComponent as FornecedorFiasaIcon } from 'Common/Assets/FORNECEDOR_FIASA.svg';
import { ReactComponent as AutoEscolaIcon } from 'Common/Assets/AUTO_ESCOLA.svg';
import { ReactComponent as EmpresasFumageirasIcon } from 'Common/Assets/EMPRESAS_FUMAGEIRAS.svg';
import { ReactComponent as ProdutorRuralIcon } from 'Common/Assets/PRODUTOR_RURAL.svg';
import { ReactComponent as LocadoraIcon } from 'Common/Assets/LOCADORA.svg';
import { ReactComponent as FrotistasIcon } from 'Common/Assets/FROTISTAS.svg';
import { ReactComponent as AgropecuarisIcon } from 'Common/Assets/AGROPECUARIS.svg';
import { ReactComponent as TaxiIcon } from 'Common/Assets/taxi.svg';
import { ReactComponent as DefaultIcon } from 'Common/Assets/Default.svg';
import { ReactComponent as PCDIcon } from 'Common/Assets/pcd.svg';
import { ReactComponent as VarejoIcon } from 'Common/Assets/car.svg';
import CustomTooltip from 'Components/Atoms/Tooltip/CustomTooltip';
import langValues from 'Config/i18n/index';

import './ClientCategoryIcon.scss';

const mapClientCategory = {
  0: {
    categoryName: langValues.clientCategoryLabels.byId[0],
    icon: <AdministracaoPublicaFederalIcon />,
  },
  1: {
    categoryName: langValues.clientCategoryLabels.byId[1],
    icon: <VarejoIcon />,
  },
  2: {
    categoryName: langValues.clientCategoryLabels.byId[2],
    icon: <AdministracaoPublicaEstadualIcon />,
  },
  3: {
    categoryName: langValues.clientCategoryLabels.byId[3],
  },
  5: {
    categoryName: langValues.clientCategoryLabels.byId[5],
  },
  7: {
    categoryName: langValues.clientCategoryLabels.byId[7],
  },
  9: {
    categoryName: langValues.clientCategoryLabels.byId[9],
  },
  11: {
    categoryName: langValues.clientCategoryLabels.byId[11],
    icon: <FornecedorFiasaIcon />,
  },
  13: {
    categoryName: langValues.clientCategoryLabels.byId[13],
  },
  15: {
    categoryName: langValues.clientCategoryLabels.byId[15],
  },
  16: {
    categoryName: langValues.clientCategoryLabels.byId[16],
  },
  18: {
    categoryName: langValues.clientCategoryLabels.byId[18],
  },
  23: {
    categoryName: langValues.clientCategoryLabels.byId[23],
  },
  27: {
    categoryName: langValues.clientCategoryLabels.byId[27],
  },
  28: {
    categoryName: langValues.clientCategoryLabels.byId[28],
  },
  31: {
    categoryName: langValues.clientCategoryLabels.byId[31],
    icon: <TaxiIcon />,
  },
  32: {
    categoryName: langValues.clientCategoryLabels.byId[32],
  },
  34: {
    categoryName: langValues.clientCategoryLabels.byId[34],
    icon: <TaxiIcon />,
  },
  35: {
    categoryName: langValues.clientCategoryLabels.byId[35],
    icon: <AutoEscolaIcon />,
  },
  37: {
    categoryName: langValues.clientCategoryLabels.byId[37],
    icon: <EmpresasFumageirasIcon />,
  },
  40: {
    categoryName: langValues.clientCategoryLabels.byId[40],
  },
  41: {
    categoryName: langValues.clientCategoryLabels.byId[41],
  },
  45: {
    categoryName: langValues.clientCategoryLabels.byId[45],
  },
  50: {
    categoryName: langValues.clientCategoryLabels.byId[50],
    icon: <PCDIcon />,
  },
  51: {
    categoryName: langValues.clientCategoryLabels.byId[51],
    icon: <ProdutorRuralIcon />,
  },
  54: {
    categoryName: langValues.clientCategoryLabels.byId[54],
  },
  61: {
    categoryName: langValues.clientCategoryLabels.byId[61],
    icon: <LocadoraIcon />,
  },
  65: {
    categoryName: langValues.clientCategoryLabels.byId[65],
    icon: <FrotistasIcon />,
  },
  66: {
    categoryName: langValues.clientCategoryLabels.byId[66],
    icon: <AgropecuarisIcon />,
  },
  67: {
    categoryName: langValues.clientCategoryLabels.byId[67],
  },
};

const notFoundCategory = {
  categoryName: langValues.clientCategoryLabels.notIdentified,
  icon: <DefaultIcon />,
};

function ClientCategoryIcon({ clientCategory = '-1', orderType = '', renderTooltip = true }) {
  if (orderType === langValues.labelsOrderType.dealer) {
    return <span className="orderTypeText">-</span>;
  }
  const clientCategoryItem = mapClientCategory[clientCategory] || notFoundCategory;
  const icon = clientCategoryItem.icon || <DefaultIcon />;
  const { categoryName } = clientCategoryItem;

  const element = <div className="clientCategory">{icon}</div>;

  if (!renderTooltip) {
    return element;
  }

  return <CustomTooltip label={`${clientCategory} • ${categoryName}`}>{element}</CustomTooltip>;
}

ClientCategoryIcon.propTypes = {
  clientCategory: PropTypes.string,
  orderType: PropTypes.string,
  renderTooltip: PropTypes.bool,
};

export default ClientCategoryIcon;
