import { connect } from 'react-redux';

import { loadAuth, loadEmployeeAuth, loadClientAuth, initializeLogin } from 'Infrastructure/Ducks/Auth';
import { clearFilters } from 'Infrastructure/Ducks/FiltersDuck';
import { clearList } from 'Infrastructure/Ducks/ListPedidos';
import Login from './Login';

const mapStateToProps = (state) => {
  const { loginError, loginLoading, expiredSession } = state.auth;

  return {
    loginError,
    loginLoading,
    expiredSession,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadAuth: (data) => {
    dispatch(loadAuth(data));
  },
  loadEmployeeAuth: (data) => {
    dispatch(loadEmployeeAuth(data));
  },
  loadClientAuth: (data) => {
    dispatch(loadClientAuth(data));
  },
  intializeLogin: () => {
    dispatch(initializeLogin());
  },
  clearDucks: () => {
    dispatch(clearList());
    dispatch(clearFilters());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
