import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { withStyles } from 'tss-react/mui';

export const AccordionConstructor = (props) =>
  withStyles(MuiAccordion, {
    ...props,
  });

export const AccordionSummaryConstructor = (props) =>
  withStyles(MuiAccordionSummary, {
    ...props,
    root: {
      opacity: '1 !important',
      cursor: 'default',
    },
  });

export const AccordionDetailsConstructor = (props) =>
  withStyles(MuiAccordionDetails, {
    ...props,
  });

export default null;
