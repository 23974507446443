import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

import './MacroAreasHistoryGraph.scss';
import langValues from 'Config/i18n/index';
import { CircularProgress } from 'Components/HelpComponents/ResponseModal/styles';
import { getLabels } from './labels';

const MacroAreasHistoryGraph = ({ macroArea, data, loading, frequency }) => {
  const graphLabels = getLabels(Object.keys(data), frequency, loading);
  const dataOnTime = Object.values(data).map((item) => (loading ? 0 : item.sumOnTime));
  const dataLate = Object.values(data).map((item) => (loading ? 0 : item.sumLate));
  const listPercentOnTime = Object.values(data).map((item) => item.percentOnTime);

  const barGraphData = {
    labels: graphLabels,
    datasets: [
      {
        label: langValues.deliveredOnTime,
        backgroundColor: '#5CA1D8',
        data: dataOnTime,
        barPercentage: 0.7,
      },
      {
        label: langValues.deliveredLate,
        backgroundColor: '#FABA12',
        data: dataLate,
        barPercentage: 0.7,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: !loading,
        intersect: false,
        mode: 'index',
        backgroundColor: '#00163F',
        titleMarginBottom: 16,
        footerMarginTop: 10,
        bodySpacing: 6,
        padding: 16,
        callbacks: {
          label(tooltipItem, tooltipData) {
            let labelText = tooltipData.datasets[tooltipItem.datasetIndex].label || '';
            if (labelText) {
              labelText += ': ';
            }
            labelText += `${tooltipItem.yLabel}`;
            return labelText;
          },
          footer(tooltipItem) {
            const percent = listPercentOnTime[tooltipItem[0].dataIndex];
            return `${Math.round(percent * 100)}% ${langValues.deliveredOnTime.toLowerCase()}`;
          },
        },
      },
    },
    scales: {
      xAxes: {
        stacked: true,
        grid: {
          color: 'rgba(0, 0, 0, 0)',
        },
      },
      yAxes: {
        stacked: true,
        grid: {
          color: 'rgba(0, 0, 0, 0)',
        },
      },
    },
  };

  return (
    <article className="macroAreaHistoryGraph-wrapper">
      {loading ? <CircularProgress thickness={5.5} size={45} className="macroAreaHistoryGraph-loading" /> : null}
      <div className="macroAreaHistoryGraph">
        <h3 className="macroAreaHistoryGraph-title">{langValues.filterLabels.macroAreasId[macroArea]}</h3>
        <Bar data={barGraphData} options={options} />
      </div>
    </article>
  );
};

MacroAreasHistoryGraph.propTypes = {
  macroArea: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  frequency: PropTypes.string.isRequired,
};

export default MacroAreasHistoryGraph;
