import React, { useLayoutEffect, useState } from 'react';
import AverageInvoiceTimeUpdate from 'Components/Atoms/AverageInvoiceTimeUpdate';
import UpdateDate from 'Components/Atoms/UpdateDate/ForecastTransitTime';
import PageHeader from 'Components/Shared/PageHeader/index';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';
import { ReactComponent as EmptyState } from 'Common/Assets/empty_state.svg';
import { trackEvent } from 'Utils/analytics';
import {
  trackedEvents, trackedPages, roleConsts, environment,
} from 'Utils/consts';
import TabsSelector from 'Components/Molecules/TabsSelector/index';
import { User } from 'Common/Helpers/AuthHelper';
import langValues from 'Config/i18n/index';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import ForecastTransitTime from 'Components/Organisms/ForecastTransitTime';
import ForecastInvoice from 'Components/Organisms/ForecastInvoice';
import { ReactComponent as AlertTriangle } from 'Common/Assets/alert-triangle.svg';
import './ModelVersionForecast.scss';

const renderSkeleton = (nItens) => {
  const skeleton = [];
  for (let i = 0; i < nItens; i += 1) {
    skeleton.push(
      <div style={{ display: 'contents' }} key={i}>
        <div className="skeleton-item" key={`${i}-1`} />
        <div className="skeleton-item" key={`${i}-2`} />
        <div className="skeleton-item" key={`${i}-3`} />
      </div>,
    );
  }
  return skeleton;
};

const ModelVersionForecast = () => {
  const isUserDealer = User.hasProfile(roleConsts.dealers);
  const isEnvironmentBR = ENV_ENVIRONMENT === environment.BR;
  const isEnvironmentAR = ENV_ENVIRONMENT === environment.AR;

  const [currentActiveTab, setCurrentActiveTab] = useState(isEnvironmentBR ? 1 : 0);

  useLayoutEffect(() => {
    trackEvent(trackedEvents.pageView, { page: trackedPages.prazosMedios });
  }, []);

  const renderWarning = () => (
    <div className="transitTime-warning">
      <AlertTriangle />
      <p>{langValues.forecastLabels.transitTimeWarning}</p>
    </div>
  );

  const renderSideBarText = (subTitle, text) => (
    <div className="sideBarText" key={subTitle}>
      <p className="subTitle">{subTitle}</p>
      <p className="text">{text}</p>
    </div>
  );

  const renderSideBarContent = (contentValue) => {
    const sideBarContent = [...contentValue];
    return sideBarContent.map(item => renderSideBarText(item.subTitle, item.text));
  };

  const renderSideBar = () => {
    let sideBarContent;
    let shouldRenderWarning = false;
    if (currentActiveTab === 0) {
      if (isEnvironmentAR || isUserDealer) {
        sideBarContent = langValues.modelVersionSideBarText;
      } else {
        sideBarContent = langValues.modelVersionSideBarTextCustomercare;
      }
    } else {
      sideBarContent = langValues.regionCitiesSideBarText;
      shouldRenderWarning = true;
    }

    return (
      <div className="sideBar">
        <p className="sideBarTitle">{sideBarContent.title}</p>
        {shouldRenderWarning ? renderWarning() : null}
        <p className="sideBarSubTitle">{sideBarContent.subtitle}</p>
        {renderSideBarContent(sideBarContent.content)}
      </div>
    );
  };

  const renderErrorMessage = () => {
    const errorMessage = langValues.loadErrorMessage;
    return (
      <div className="modelVersionForecast-error">
        <EmptyState />
        <span className="font-subtitle2">{errorMessage}</span>
        <MaterialButton
          onClick={() => window.location.reload()}
          buttonText={langValues.reloadPage}
          variant="outlined"
        />
      </div>
    );
  };

  const renderTransitTimeForecast = () => (
    <ForecastTransitTime
      renderSkeleton={renderSkeleton}
      renderErrorMessage={renderErrorMessage}
    />
  );

  const renderInvoiceForecast = () => (
    <ForecastInvoice
      renderErrorMessage={renderErrorMessage}
      renderSkeleton={renderSkeleton}
    />
  );

  const renderContent = () => {
    if (currentActiveTab === 0) {
      return renderInvoiceForecast();
    }
    return renderTransitTimeForecast();
  };

  const renderTabsForAR = () => (
    <TabsSelector
      changeActiveTab={value => setCurrentActiveTab(value)}
      currentActiveTab={currentActiveTab}
      tabsList={[
        { id: 0, label: langValues.forecastLabels.invoiceForecast },
        { id: 1, label: langValues.forecastLabels.transitTimeForecast },
      ]}
    />
  );

  const renderTabsForBR = () => (isUserDealer ? (
    <TabsSelector
      changeActiveTab={() => { }}
      currentActiveTab={currentActiveTab}
      tabsList={[
        { id: 1, label: langValues.forecastLabels.transitTimeForecast },
      ]}
    />
  ) : (
    <TabsSelector
      changeActiveTab={() => { }}
      currentActiveTab={currentActiveTab}
      tabsList={[
        { id: 0, label: langValues.forecastLabels.invoiceForecast, disabled: true },
        { id: 1, label: langValues.forecastLabels.transitTimeForecast },
      ]}
    />
  ));

  const renderSubtitleHeader = () => {
    const isUserCustomerCareOrAdmin = User.hasProfile(roleConsts.customercare)
    || User.hasProfile(roleConsts.admin);
    if (currentActiveTab === 0 && isEnvironmentBR) {
      return <AverageInvoiceTimeUpdate />;
    }
    if (currentActiveTab === 1 && isUserCustomerCareOrAdmin) {
      return <UpdateDate />;
    }
    return null;
  };

  return (
    <div className="modelVersionForecast">
      <PageHeader
        title={<div className="settingsTitle">{langValues.modelVersionForecast}</div>}
        subtitle={renderSubtitleHeader()}
      />
      <div className="modelVersionForecast-content">
        {renderSideBar()}
        <div className="modelVersionForecast-mainContent">
          {isEnvironmentBR ? renderTabsForBR() : renderTabsForAR()}
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default ModelVersionForecast;
