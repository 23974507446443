import { connect } from 'react-redux';

import { loadList, clearList } from 'Infrastructure/Ducks/ListPedidos';
import PageHeader from './PageHeader';

const mapStateToProps = (state) => {
  const { list, filtersDuck } = state;

  return {
    list,
    isTabPDA: filtersDuck.isBilledFilter === 3,
  };
};

const mapDispatchToProps = dispatch => ({
  loadList: (data) => {
    dispatch(loadList(data));
  },
  clearList: () => {
    dispatch(clearList());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageHeader);
