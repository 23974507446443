import React from 'react';
import PropTypes from 'prop-types';
import { stepStatusId, environment, roleConsts } from 'Utils/consts';
import BlockPendencyInformation from 'Components/Molecules/BlockPendencyInformation/BlockPendencyInformation';
import InvoiceIcon from 'Components/Atoms/InvoiceIcon';
import langValues from 'Config/i18n/index';
import PhoneContact from 'Components/PhoneContact/index';
import DelayReasonsCard from 'Components/ComercialDetailsScreenComponents/PedidoComercial/DelayReasonsCard/DelayReasonsCard';
import OrderInformationItem from 'Components/Atoms/OrderInformationItem';
import InvoiceDownload from 'Components/Molecules/InvoiceDownload/InvoiceDownload';
import ToggleEnvironment from 'Components/Atoms/ToggleEnvironment';
import { ReactComponent as AlertIcon } from 'Common/Assets/alert-triangle.svg';
import { User } from 'Common/Helpers/AuthHelper';

import Timeline from '../Timeline';
import './TimelineCard.scss';

const TimelineCard = ({ selectedIndustrialOrder = null, order = null }) => {
  const { industrialOrder, loading } = selectedIndustrialOrder;
  const userPermissions = order && order.userPermissions ? order.userPermissions : {};
  const userIsClient = User.hasProfile(roleConsts.client);

  if (!selectedIndustrialOrder || !industrialOrder) {
    return null;
  }
  if (loading) {
    return <section className="timelineCard-skeleton" />;
  }

  const renderReprogrammingcard = () => (
    <section className="timelineCard-reprogrammingCard">
      <AlertIcon className="timelineCard-reprogrammingCard-alertIcon" />
      <div className="timelineCard-reprogrammingCard-div">
        <span className="font-subtitle2 timelineCard-reprogrammingCard-title">{langValues.attention}</span>
        <span className="font-small2 timelineCard-reprogrammingCard-text">
          {langValues.timeLineLabels.flagReprogrammedText}
        </span>
      </div>
    </section>
  );

  const renderInformationCard = (delayReasonsList) => {
    if (
      industrialOrder.flagReprogrammed &&
      selectedIndustrialOrder.industrialOrder.rules !== null &&
      selectedIndustrialOrder.industrialOrder.rules.estimated
    ) {
      return renderReprogrammingcard();
    }
    if (industrialOrder.statusId === stepStatusId.late && delayReasonsList.length > 0) {
      return <DelayReasonsCard delayReasonsList={delayReasonsList} />;
    }
    return <div />;
  };

  const renderInvoiceDownloadCardBlocked = () => (
    <section className="timelineCard-downloadInvoiceDiv">
      <div className="timelineCard-invoiceIconBlocked">
        <InvoiceIcon />
      </div>
      <span className="timelineCard-downloadInvoiceDiv-text">{langValues.timeLineLabels.invoiceDownloadBlocked}</span>
    </section>
  );

  const renderInvoice = () => {
    const modalData = {
      orderId: order.orderId,
      vin: industrialOrder.chassi,
      flagReprogrammed: industrialOrder.flagReprogrammed,
      industrialOrderId: industrialOrder.industrialOrderId,
    };

    if (industrialOrder.invoiceAvailable && !industrialOrder.dealerBlocked) {
      return <InvoiceDownload modalData={modalData} />;
    }
    if (industrialOrder.invoiceAvailable && industrialOrder.dealerBlocked) {
      return renderInvoiceDownloadCardBlocked();
    }
    return null;
  };

  const renderBlockPendencyFlag = () => {
    if (!industrialOrder) return null;
    return (
      <BlockPendencyInformation cantonmentDate={industrialOrder.cantonmentDate} industrialOrder={industrialOrder} />
    );
  };

  const renderCardHeader = () => {
    if (userIsClient) {
      return null;
    }
    let { vehicleCourtyard } = industrialOrder;
    vehicleCourtyard = vehicleCourtyard || undefined;
    const delayReasonsList = order.delayReasons.concat(industrialOrder.delayReasons);

    return (
      <div className="timelineCard-header">
        {renderInformationCard(delayReasonsList)}

        <div className="timelineCard-header-right">
          <OrderInformationItem label={langValues.labelsPedidoIndustrial.patio} text={vehicleCourtyard} />
          {userPermissions.renderBlockPendency ? renderBlockPendencyFlag() : null}
          <ToggleEnvironment env={environment.BR}>
            {userPermissions.renderCarVendas ? (
              <PhoneContact
                phoneLabel={langValues.carSalesLabel}
                phoneNumber={langValues.carSalesPhone}
                phoneSubText={langValues.carSalesSubText}
              />
            ) : null}
          </ToggleEnvironment>
        </div>
      </div>
    );
  };

  const renderCardFooter = () => {
    const { renderLegalText } = userPermissions;

    if (
      renderLegalText &&
      selectedIndustrialOrder.industrialOrder.rules !== null &&
      selectedIndustrialOrder.industrialOrder.rules.estimated
    ) {
      return <span className="font-small2">{langValues.legalTerm}</span>;
    }
    return null;
  };

  return (
    <>
      <section className="timelineCard">
        {renderCardHeader()}
        <Timeline />
        {renderCardFooter()}
      </section>
      <section className="invoiceDownload">
        {userPermissions.renderInvoiceDownload && !userIsClient ? renderInvoice() : null}
      </section>
    </>
  );
};

TimelineCard.propTypes = {
  selectedIndustrialOrder: PropTypes.object,
  order: PropTypes.object,
};

export default TimelineCard;
