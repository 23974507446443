/* eslint-disable class-methods-use-this */
import { fileTypes } from 'Utils/consts';
import RequestRepository from './RequestRepository';

class ParametersRepository {
  getStepParameters() {
    const parameters = 'modelsversions/steps';
    return RequestRepository.get(parameters);
  }

  putStepParameters(incomingData) {
    const parameters = 'modelsversions/steps';
    const body = incomingData;
    return RequestRepository.put(parameters, body);
  }

  async getModelsVersions() {
    const parameters = 'modelsversions/parameters';
    return RequestRepository.get(parameters);
  }

  async getUnregisteredVersions() {
    const parameters = 'modelsversions/unregistered';
    return RequestRepository.get(parameters);
  }

  async getUnregisteredData() {
    return RequestRepository.getUnregisteredData();
  }

  async getHistoricLogsOrder() {
    const parameters = 'modelsversions/historicLogsOrders';
    return RequestRepository.get(parameters);
  }

  async putModel(model) {
    const parameters = 'modelsversions/parameters/models';
    return RequestRepository.put(parameters, model);
  }

  async putVersion(version) {
    const parameters = 'modelsversions/parameters/versions';
    return RequestRepository.put(parameters, version);
  }

  async deleteModel(modelId) {
    const parameters = `modelsversions/parameters/models/${modelId}`;
    return RequestRepository.delete(parameters);
  }

  async deleteVersion(versionId, modelId) {
    const parameters = `modelsversions/parameters/models/${modelId}/versions/${versionId}`;
    return RequestRepository.delete(parameters);
  }

  async exportStep(fileType) {
    let config = {};
    if (fileType === fileTypes.csv) {
      config = {
        headers: {
          'Content-Disposition': 'attachment; filename = EasyTrackingData.csv;',
          'Content-Type': 'text/csv',
        },
      };
    } else {
      config = {
        headers: {
          'Content-Disposition': 'attachment; filename=EasyTrackingData.xlsx;',
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      };
    }
    const parameters = `modelsversions/steps/report?fileType=${fileType}`;
    const fileName = 'EasyTrackingData';

    return RequestRepository.export(parameters, config, fileName, fileType);
  }

  async getHistoricLogsTotal() {
    const delayedOrdersTotal = 'modelsversions/historicLogsOrdersTotal';
    return RequestRepository.get(delayedOrdersTotal);
  }
}

export default ParametersRepository;
