import React from 'react';
import PropTypes from 'prop-types';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import { environment } from 'Utils/consts';

function mascaraCnpj(cnpj) {
  if (!cnpj) {
    return null;
  }

  if (ENV_ENVIRONMENT === environment.AR) {
    return cnpj.replace(/(\d{2})(\d{8})(\d{1})/g, '$1-$2-$3');
  }
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
}

function CnpjText({ cnpj }) {
  return <span className="cpfText">{mascaraCnpj(cnpj)}</span>;
}

CnpjText.propTypes = {
  cnpj: PropTypes.string,
};

export default CnpjText;
