import { safeToUpperCase } from './safeMethods';

export const capitalizeText = text => ((typeof text === 'string' && text.length > 0)
  ? text.replace(/\b\w/g, l => safeToUpperCase(l)) : text);

export const getValidatedText = (text, numeric) => {
  let valid = text && text.length > 0;
  if (numeric) {
    valid = text >= 0.0;
  }
  return valid ? text : '-';
};

export const changeStringToAPattern = (text) => {
  if (text) {
    return text
      .replace(/(\.|#|-|\/)(\d)/g, '$2')
      .replace(/(\d)(\.|#|-|\/)/g, '$1')
      .trim()
      .replace(/(\s)/g, ' %');
  }
  return text;
};

export default null;
