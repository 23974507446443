import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CheckIcon } from 'Common/Assets/checkIcon.svg';
import { ReactComponent as ThreeDots } from 'Common/Assets/threeDotsIcon.svg';
import { stepStatusId } from 'Utils/consts';
import ColorVariables from 'Utils/Styles/colorVariables';
import './StepStatusCircle.scss';

const StepStatusCircle = ({ step, isCurrentStep, size = 40 }) => {
  let { status } = step;

  if (step.enable) {
    status = stepStatusId.delivered;
  }

  if ((status === stepStatusId.onTime && !isCurrentStep) || (!step.enable && !step.status)) {
    status = stepStatusId.notStarted;
  }

  const stepFull = (Icon, borderWidth) => (
    <div style={{ width: size, height: size, borderWidth }} className={`step-status-circle ${status}`}>
      {Icon ? <Icon width={0.6 * size} height={0.6 * size} /> : null}
    </div>
  );

  const stepPartial = (outerStroke, innerColor) => {
    const { actualNumberDays, idealNumberDays } = step;
    const progress = status === stepStatusId.ongoing ? 100 : (actualNumberDays / idealNumberDays) * 100;

    const strokeWidth = size / 10;
    const center = size / 2;
    const radius = size / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = ((100 - progress) / 100) * circumference;

    return (
      <div className="step-status-circle-progress" style={{ width: size, height: size }}>
        <svg className="step-status-circle-progress-circle" width={size} height={size}>
          <circle
            fill={innerColor || outerStroke}
            fillOpacity={innerColor ? 1 : 0.15}
            className="svg-circle-bg"
            cx={center}
            cy={center}
            r={radius - strokeWidth / 2}
            strokeWidth={strokeWidth}
          />
          <circle
            className="svg-circle"
            stroke={outerStroke}
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={offset}
          />
        </svg>
        <ThreeDots
          width={0.6 * size}
          height={0.6 * size}
          className="step-status-circle-progress-icon"
          fill={outerStroke}
        />
      </div>
    );
  };

  switch (status) {
    case stepStatusId.onTime:
    case stepStatusId.ongoing: {
      return stepPartial(ColorVariables.actionPrimaryColor, ColorVariables.actionSecondaryColor);
    }
    case stepStatusId.inRisk: {
      return stepPartial(ColorVariables.warningColor);
    }
    case stepStatusId.late: {
      const borderWidth = size / 10;
      return stepFull(ThreeDots, borderWidth);
    }
    case stepStatusId.delivered: {
      return stepFull(CheckIcon, 0);
    }
    case stepStatusId.notStarted: {
      const borderWidth = size / 20;
      return stepFull(null, borderWidth);
    }
    default:
      return null;
  }
};

StepStatusCircle.propTypes = {
  step: PropTypes.object.isRequired,
  isCurrentStep: PropTypes.bool.isRequired,
  size: PropTypes.number,
};

export default StepStatusCircle;
