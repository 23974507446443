import React from 'react';
import PropTypes from 'prop-types';

import langValues from 'Config/i18n/index';
import BrandLogo from 'Components/Shared/BrandLogo/index';
import TagOrderStatus from 'Components/Atoms/TagOrderStatus/index';
import './TitlePedidoComercial.scss';

function TitlePedidoComercial({ orderId = '---', orderType = 'COMERCIAL', brandId = null, status = null }) {
  const renderStatusAndBrand = () => {
    if (brandId !== null) {
      return (
        <div className="statusAndBrand">
          <TagOrderStatus statusId={status} />
          <BrandLogo brandId={brandId} />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="title-section">
      {renderStatusAndBrand()}
      <div className="title">
        <h1 className="titulo-pedido">
          {orderType === langValues.labelsOrderType.commercial
            ? langValues.labelsPedidoComercial.titulo
            : langValues.labelsPedidoComercial.tituloDealer}
        </h1>
        <h1 className="codigo">
          {langValues.labelsPedidoComercial.codigoPedido}
          {orderId}
        </h1>
      </div>
    </div>
  );
}

TitlePedidoComercial.propTypes = {
  orderId: PropTypes.string,
  orderType: PropTypes.string,
  brandId: PropTypes.number,
  status: PropTypes.string,
};

export default TitlePedidoComercial;
