import moment from 'moment';

import DashboardService from '../Services/DashboardService';

export const DUCK_NAME = 'dashboard';

export const INITIAL_STATE = () => ({
  brand: {
    lastUpdateDateTime: null,
    loading: false,
    success: false,
    error: null,
    data: [],
  },
  clientMacroCategory: {
    lastUpdateDateTime: null,
    loading: false,
    success: false,
    error: null,
    data: [],
  },
  macroArea: {
    lastUpdateDateTime: null,
    loading: false,
    success: false,
    error: null,
    data: [],
  },
  resumeHistory: {
    lastUpdateDateTime: null,
    loading: false,
    success: false,
    error: null,
    data: [],
  },
  macroAreaHistory: {
    loading: false,
    success: false,
    error: null,
    data: null,
  },
  kpiStepNumber: null,
});

export const LOAD_BRAND_STARTED = `${DUCK_NAME}/LOAD_BRAND_STARTED`;
export const LOAD_BRAND_SUCCEED = `${DUCK_NAME}/LOAD_BRAND_SUCCEED`;
export const LOAD_BRAND_FAILED = `${DUCK_NAME}/LOAD_BRAND_FAILED`;
export const loadBrandStarted = () => ({ type: LOAD_BRAND_STARTED });
export const loadBrandSucceed = data => ({ type: LOAD_BRAND_SUCCEED, data });
export const loadBrandFailed = error => ({ type: LOAD_BRAND_FAILED, error });

export const LOAD_CLIENTMACROCATEGORY_STARTED = `${DUCK_NAME}/LOAD_CLIENTMACROCATEGORY_STARTED`;
export const LOAD_CLIENTMACROCATEGORY_SUCCEED = `${DUCK_NAME}/LOAD_CLIENTMACROCATEGORY_SUCCEED`;
export const LOAD_CLIENTMACROCATEGORY_FAILED = `${DUCK_NAME}/LOAD_CLIENTMACROCATEGORY_FAILED`;
export const loadClientMacroCategoryStarted = () => ({ type: LOAD_CLIENTMACROCATEGORY_STARTED });
export const loadClientMacroCategorySucceed = data => ({
  type: LOAD_CLIENTMACROCATEGORY_SUCCEED, data,
});
export const loadClientMacroCategoryFailed = error => ({
  type: LOAD_CLIENTMACROCATEGORY_FAILED, error,
});

export const LOAD_MACROAREA_STARTED = `${DUCK_NAME}/LOAD_MACROAREA_STARTED`;
export const LOAD_MACROAREA_SUCCEED = `${DUCK_NAME}/LOAD_MACROAREA_SUCCEED`;
export const LOAD_MACROAREA_FAILED = `${DUCK_NAME}/LOAD_MACROAREA_FAILED`;
export const loadMacroAreaStarted = () => ({ type: LOAD_MACROAREA_STARTED });
export const loadMacroAreaSucceed = data => ({ type: LOAD_MACROAREA_SUCCEED, data });
export const loadMacroAreaFailed = error => ({ type: LOAD_MACROAREA_FAILED, error });

export const LOAD_MACROAREA_HISTORY_STARTED = `${DUCK_NAME}/LOAD_MACROAREA_HISTORY_STARTED`;
export const LOAD_MACROAREA_HISTORY_SUCCEED = `${DUCK_NAME}/LOAD_MACROAREA_HISTORY_SUCCEED`;
export const LOAD_MACROAREA_HISTORY_FAILED = `${DUCK_NAME}/LOAD_MACROAREA_HISTORY_FAILED`;
export const loadMacroAreaHistoryStarted = () => ({ type: LOAD_MACROAREA_HISTORY_STARTED });
export const loadMacroAreaHistorySucceed = data => ({ type: LOAD_MACROAREA_HISTORY_SUCCEED, data });
export const loadMacroAreaHistoryFailed = error => ({ type: LOAD_MACROAREA_HISTORY_FAILED, error });

export const TOGGLE_KPISTEPNUMBER = `${DUCK_NAME}/TOGGLE_KPISTEPNUMBER`;
export const toggleStepNumber = stepNumber => ({ type: TOGGLE_KPISTEPNUMBER, stepNumber });

export const loadMacroAreaHistory = frequency => async (dispatch) => {
  dispatch(loadMacroAreaHistoryStarted());
  let data = {};
  try {
    const dashboardService = new DashboardService();
    data = await dashboardService.getMacroAreaHistory(frequency);
    dispatch(loadMacroAreaHistorySucceed(data));
  } catch (err) {
    dispatch(loadMacroAreaHistoryFailed(err));
  }
};

export const loadDashboardBrand = (searchString, selected, dates) => async (dispatch) => {
  dispatch(loadBrandStarted());
  let data = {};
  try {
    const dashboardService = new DashboardService();
    data = await dashboardService.getBrand(searchString, selected, dates);
    dispatch(loadBrandSucceed(data));
  } catch (err) {
    dispatch(loadBrandFailed(err));
  }
};

export const loadDashboardClientMacroCategory = (
  searchString,
  selected,
  dates,
) => async (dispatch) => {
  dispatch(loadClientMacroCategoryStarted());
  let data = {};
  try {
    const dashboardService = new DashboardService();
    data = await dashboardService.getClientMacroCategory(searchString, selected, dates);
    dispatch(loadClientMacroCategorySucceed(data));
  } catch (err) {
    dispatch(loadClientMacroCategoryFailed(err));
  }
};

export const loadDashboardMacroArea = (searchString, selected, dates) => async (dispatch) => {
  dispatch(loadMacroAreaStarted());
  let data = {};
  try {
    const dashboardService = new DashboardService();
    data = await dashboardService.getMacroArea(searchString, selected, dates);
    dispatch(loadMacroAreaSucceed(data));
  } catch (err) {
    dispatch(loadMacroAreaFailed(err));
  }
};

export const toggleKpiStepNumber = stepNumber => async (dispatch, getState) => {
  const stepNumberSelected = getState().dashboard.kpiStepNumber;
  dispatch(toggleStepNumber(stepNumberSelected === stepNumber ? null : stepNumber));
};

const reducer = (state = INITIAL_STATE(), action) => {
  switch (action.type) {
    case LOAD_BRAND_STARTED:
      return {
        ...state,
        brand: {
          ...state.brand,
          lastUpdateDateTime: moment().toISOString(),
          loading: true,
        },
      };
    case LOAD_BRAND_SUCCEED:
      return {
        ...state,
        brand: {
          ...state.brand,
          lastUpdateDateTime: moment().toISOString(),
          loading: false,
          success: true,
          error: null,
          data: action.data,
        },
      };
    case LOAD_BRAND_FAILED:
      return {
        ...state,
        brand: {
          ...state.brand,
          lastUpdateDateTime: moment().toISOString(),
          loading: false,
          success: false,
          error: action.error,
        },
      };
    case LOAD_CLIENTMACROCATEGORY_STARTED:
      return {
        ...state,
        clientMacroCategory: {
          ...state.clientMacroCategory,
          lastUpdateDateTime: moment().toISOString(),
          loading: true,
        },
      };
    case LOAD_CLIENTMACROCATEGORY_SUCCEED:
      return {
        ...state,
        clientMacroCategory: {
          ...state.clientMacroCategory,
          lastUpdateDateTime: moment().toISOString(),
          loading: false,
          success: true,
          error: null,
          data: action.data,
        },
      };
    case LOAD_CLIENTMACROCATEGORY_FAILED:
      return {
        ...state,
        clientMacroCategory: {
          ...state.clientMacroCategory,
          lastUpdateDateTime: moment().toISOString(),
          loading: false,
          success: false,
          error: action.error,
        },
      };
    case LOAD_MACROAREA_STARTED:
      return {
        ...state,
        macroArea: {
          ...state.macroArea,
          lastUpdateDateTime: moment().toISOString(),
          loading: true,
        },
      };
    case LOAD_MACROAREA_SUCCEED:
      return {
        ...state,
        macroArea: {
          ...state.macroArea,
          lastUpdateDateTime: moment().toISOString(),
          loading: false,
          success: true,
          error: null,
          data: action.data,
        },
      };
    case LOAD_MACROAREA_FAILED:
      return {
        ...state,
        macroArea: {
          ...state.macroArea,
          lastUpdateDateTime: moment().toISOString(),
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case LOAD_MACROAREA_HISTORY_STARTED:
      return {
        ...state,
        macroAreaHistory: {
          ...state.macroAreaHistory,
          loading: true,
          error: null,
        },
      };
    case LOAD_MACROAREA_HISTORY_SUCCEED:
      return {
        ...state,
        macroAreaHistory: {
          ...state.macroAreaHistory,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case LOAD_MACROAREA_HISTORY_FAILED:
      return {
        ...state,
        macroAreaHistory: {
          ...state.macroAreaHistory,
          loading: false,
          success: false,
          error: action.error,
        },
      };
    case TOGGLE_KPISTEPNUMBER:
      return {
        ...state,
        kpiStepNumber: action.stepNumber,
      };
    default:
      return state;
  }
};

export default reducer;
