import React, { Component } from 'react';
import PropTypes from 'prop-types';

import langValues from 'Config/i18n/index';
import SnackBar from 'Components/Shared/SnackBar';
import TransitTimeListItem from '../TransitTimeListItem';

import './TransitTime.scss';

export const renderListSkeleton = (nItens) => {
  const skeleton = [];
  for (let i = 0; i < nItens; i += 1) {
    skeleton.push(<TransitTimeListItem key={i} />);
  }
  return skeleton;
};
class TransitTimeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingItem: null,
    };
  }

  handleEditItem = (item) => {
    this.setState({
      editingItem: item,
    });
  };

  componentDidUpdate = (previousProps) => {
    const { editing, data } = this.props;
    const { editingItem } = this.state;
    if (data) {
      if (previousProps !== this.props) {
        if (editing && editingItem == null) {
          this.setState({ editingItem: Object.keys(data)[0] });
        } else if (!editing) {
          this.setState({ editingItem: null });
        }
      }
    }
  };

  renderList = (data, handleEditItem, errorLoading, errorInserting, loading) => {
    const { onChange, editing } = this.props;
    const { editingItem } = this.state;
    if (loading) return renderListSkeleton(10);
    if (errorLoading) {
      return <SnackBar message={langValues.settingsTransitTimeScreen.errorLoading} />;
    }
    if (errorInserting) {
      return <SnackBar message={langValues.settingsTransitTimeScreen.errorInserting} />;
    }
    return Object.keys(data).map((key) => (
      <TransitTimeListItem
        item={data[key]}
        key={key}
        currentEditing={editingItem}
        onClickItem={handleEditItem}
        onChange={onChange}
        editing={editing}
      />
    ));
  };

  render = () => {
    const { data, errorLoading, errorInserting, loading } = this.props;
    return <>{this.renderList(data, this.handleEditItem, errorLoading, errorInserting, loading)}</>;
  };
}

TransitTimeList.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  errorInserting: PropTypes.bool,
  errorLoading: PropTypes.bool,
  loading: PropTypes.bool,
  editing: PropTypes.bool.isRequired,
};

export default TransitTimeList;
