import React from 'react';
import { ReactComponent as InvoiceIconSvg } from 'Common/Assets/InvoiceIcon.svg';
import './InvoiceIcon.scss';

const InvoiceIcon = () => (
  <div className="invoiceIcon">
    <InvoiceIconSvg />
    <span className="font-small2">DANFe</span>
  </div>
);

export default InvoiceIcon;
