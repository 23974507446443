import UserService from 'Infrastructure/Services/UserService';

const INITIAL_STATE = () => ({
  macroAreas: [],
  isMacroAreaModalOpen: false,
  error: null,
  success: false,
  loading: false,
});

export const DUCK_NAME = 'userDuck';

const PUT_MACRO_AREAS_SUCCEED = `${DUCK_NAME}/PUT_MACRO_AREAS_SUCCEED`;
const PUT_MACRO_AREAS_FAILED = `${DUCK_NAME}/PUT_MACRO_AREAS_FAILED`;
const GET_MACRO_AREAS_STARTED = `${DUCK_NAME}/GET_MACRO_AREAS_STARTED`;
const GET_MACRO_AREAS_SUCCEED = `${DUCK_NAME}/GET_MACRO_AREAS_SUCCEED`;
const GET_MACRO_AREAS_FAILED = `${DUCK_NAME}/GET_MACRO_AREAS_FAILED`;

const putMacroAreasSucceed = () => ({ type: PUT_MACRO_AREAS_SUCCEED });
const putMacroAreasFailed = error => ({ type: PUT_MACRO_AREAS_FAILED, error });
const getMacroAreasStarted = () => ({ type: GET_MACRO_AREAS_STARTED });
const getMacroAreasSucceed = macroAreas => ({ type: GET_MACRO_AREAS_SUCCEED, macroAreas });
const getMacroAreasFailed = error => ({ type: GET_MACRO_AREAS_FAILED, error });

const SET_MACRO_AREA_MODAL_STATUS = `${DUCK_NAME}/SET_MACRO_AREA_MODAL_STATUS`;
export const setMacroAreaModalStatus = status => ({ type: SET_MACRO_AREA_MODAL_STATUS, status });

export const putMacroAreas = macroAreas => async (dispatch) => {
  const userService = new UserService();
  try {
    await userService.putMacroAreas(macroAreas);
    dispatch(putMacroAreasSucceed());
  } catch (err) {
    dispatch(putMacroAreasFailed(err));
  }
};

export const getMacroAreas = () => async (dispatch) => {
  const userService = new UserService();
  try {
    dispatch(getMacroAreasStarted());
    const macroAreas = await userService.getMacroAreas();
    dispatch(getMacroAreasSucceed(macroAreas));
    return Promise.resolve(macroAreas);
  } catch (err) {
    dispatch(getMacroAreasFailed(err));
    return Promise.reject(err);
  }
};

const reducer = (state = INITIAL_STATE(), action) => {
  switch (action.type) {
    case PUT_MACRO_AREAS_SUCCEED:
      return {
        ...state,
        success: true,
        error: false,
      };
    case PUT_MACRO_AREAS_FAILED:
      return {
        ...state,
        success: false,
        error: true,
      };
    case GET_MACRO_AREAS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case GET_MACRO_AREAS_SUCCEED:
      return {
        ...state,
        macroAreas: action.macroAreas,
        sucess: true,
        error: false,
        loading: false,
      };
    case GET_MACRO_AREAS_FAILED:
      return {
        ...state,
        sucess: false,
        error: false,
        loading: false,
      };
    case SET_MACRO_AREA_MODAL_STATUS:
      return {
        ...state,
        isMacroAreaModalOpen: action.status,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
