import { connect } from 'react-redux';

import FilterAccordionAhorro from './FilterAccordionAhorro';

const mapStateToProps = (state) => {
  const { selectedAhorroFilters } = state.filtersDuck;
  const isAnyFilterSelected = selectedAhorroFilters.some(v => !!v.value);

  return {
    isAnyFilterSelected,
  };
};

export default connect(mapStateToProps, {})(FilterAccordionAhorro);
