import React from 'react';
import PropTypes from 'prop-types';

import langValues from 'Config/i18n/index';

import TagOrderStatus from 'Components/Atoms/TagOrderStatus';
import Radio from '@mui/material/Radio';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { safeToLowerCase } from 'Utils/safeMethods';
import SystemUpdate from 'Components/Atoms/SystemUpdate';

import '../PedidoComercial/TitlePedidoComercial/invoiceDownload.scss';
import '../../OrderListComponents/ListItemPedidos/ListItemPedidos.scss';
import './ClientCommercialOrders.scss';

function ClientCommercialOrders({
  clientOrders = [],
  order,
  loading = true,
  changeSelectedOrder,
  totalOrdersNumber = 0,
  pageSize = 7,
  currentPage = 0,
  changeSelectedPage = (page) => {},
}) {
  const renderTitle = () => (
    <div className="titulo-pedido">
      <h3>{langValues.labelsPedidoComercial.yourOrders}</h3>
      <SystemUpdate />
    </div>
  );

  const renderOrderSelector = () => {
    const getModelName = (industrial) => {
      if (industrial) {
        return industrial.modelName;
      }
      return '-';
    };

    return (
      <div className="commercialOrderDetail-ordersSelector">
        {clientOrders.map((item) => (
          <div className="ordersSelectorItem" key={item.orderId} onClick={() => changeSelectedOrder(item)}>
            <Radio checked={item.orderId === order.orderId} value={item} className="radioButton" />
            <span className="font-subtitle2 orderId">{`#${item.orderId}`}</span>
            <div className="model">
              <span className="font-subtitle2 modelName">
                {safeToLowerCase(getModelName(item.industrialOrdersList[0]))}
              </span>
              <span className="font-small3 modelLabel">{langValues.model}</span>
            </div>
            <div className="tagStatus">
              <TagOrderStatus lateDays={item.maximumDelay} statusId={item.statusId} />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderPagination = () => {
    const numberOfPages = Math.ceil(totalOrdersNumber / pageSize);
    return (
      <div className="paginationFooter">
        <Stack spacing={2}>
          <Pagination
            count={numberOfPages}
            variant="outlined"
            shape="rounded"
            page={currentPage + 1}
            onChange={(event, value) => changeSelectedPage(value - 1)}
          />
        </Stack>
      </div>
    );
  };

  const renderBody = () => (
    <article className="clientCommercialOrders">
      {renderTitle()}
      {renderOrderSelector()}
      {renderPagination()}
    </article>
  );

  const renderSkeleton = (nItens) => {
    const skeleton = [];
    for (let i = 0; i < nItens; i += 1) {
      skeleton.push(<div key={i} />);
    }
    return skeleton;
  };

  const renderEmptyBody = () => (
    <article className="emptyClientCommercialOrders">
      <div className="title">
        <div className="empty" />
      </div>
      <div className="body">{renderSkeleton(7)}</div>
      <div className="pagination">
        <div className="empty" />
      </div>
    </article>
  );

  if (loading) {
    return renderEmptyBody();
  }
  return renderBody();
}

ClientCommercialOrders.propTypes = {
  clientOrders: PropTypes.array,
  changeSelectedOrder: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  totalOrdersNumber: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  changeSelectedPage: PropTypes.func,
  loading: PropTypes.bool,
};

export default ClientCommercialOrders;
