import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@mui/material';

import './BaseModal.scss';

const BaseModal = ({ ...props }) => {
  const { children, open, onClose = () => {}, className = '' } = props;

  return (
    <Modal open={open} onClose={onClose} className={`baseModal ${className}`}>
      <div className="baseModal-content">{children}</div>
    </Modal>
  );
};

BaseModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default BaseModal;
