import React, { Component } from 'react';
import PageHeader from 'Components/Shared/PageHeader/PageHeader';
import PropTypes from 'prop-types';
import { trackEvent } from 'Utils/analytics';
import {
  trackedEvents, roleConsts, trackedButtons, trackedPages,
} from 'Utils/consts';
import Select from 'react-select';
import MacroAreaHistoryGraph from 'Components/DashboardComponents/MacroAreasHistoryGraph/index';
import { ReactComponent as ErrorIcon } from 'Common/Assets/error.svg';
import OrderFilters from 'Components/Organisms/OrderFilters';
import { User } from 'Common/Helpers/AuthHelper';
import OrdersResume from 'Components/OrderListComponents/OrdersResume/index';
import langValues from 'Config/i18n/index';
import Dot from 'Components/Shared/Dot/Dot';
import { selectorStyle } from './styles';
import './DashboardOrders.scss';

const frequencySelectorOptions = [
  { value: 'DAY', label: langValues.frequencyLabels.diary },
  { value: 'WEEK', label: langValues.frequencyLabels.weekly },
  { value: 'MONTH', label: langValues.frequencyLabels.monthly },
];

class DashboardOrders extends Component {
  constructor(props) {
    super(props);
    const { filtersDuck, clearFilters } = this.props;
    const { appliedFilters, appliedDates } = filtersDuck;
    if (!appliedFilters || !appliedDates) {
      clearFilters();
    }
    this.state = {
      showFilter: false,
      selectedFrequency: { value: 'MONTH', label: langValues.frequencyLabels.monthly },
    };
  }

  loadDashboardData = () => {
    const {
      loadResume,
      loadMacroAreaHistory,
    } = this.props;

    const { filtersDuck } = this.props;
    const { appliedFilters, appliedDates, dealerId } = filtersDuck;
    const { selectedFrequency } = this.state;
    const searchString = '';

    const allFilters = { ...appliedFilters, dealerId };
    loadResume(searchString, allFilters, appliedDates);
    loadMacroAreaHistory(selectedFrequency.value);
  }

  applyFilter = () => {
    this.setState({ showFilter: false }, () => this.loadDashboardData());
  }

  componentDidMount = () => {
    const { loadModels } = this.props;
    loadModels();

    this.loadDashboardData();
  }

  renderCommercialOrdersDashboard = (list) => {
    const { data, loadingResume } = list;
    const { resume, resumeHistory } = data;
    return (
      <OrdersResume
        data={{ ...resume, resumeHistory, loadingResume }}
        dealer={User.hasProfile(roleConsts.dealers)}
      />
    );
  }

  handleFrequencyChange = (selectedOption) => {
    const { selectedFrequency } = this.state;
    if (selectedFrequency.value !== selectedOption.value) {
      this.setState({
        selectedFrequency: selectedOption,
      }, () => {
        this.loadDashboardData();
      });
      trackEvent(trackedEvents.buttonClick,
        { button: trackedButtons.historyFrequency, frequency: selectedOption.label });
    }
  }

  renderFrequencySelector = (isLoading) => {
    const { selectedFrequency } = this.state;

    return (
      <div className="macroAreasHistory-selectorWrapper">
        <Select
          options={frequencySelectorOptions}
          value={selectedFrequency}
          styles={selectorStyle}
          onChange={this.handleFrequencyChange}
          className="macroAreasHistory-select"
          isDisabled={isLoading}
          isSearchable={false}
        />
      </div>
    );
  }

  renderMacroAreaHistorySkeleton = () => (
    <>
      <div className="macroAreaHistory-skeleton-graph" />
      <div className="macroAreaHistory-skeleton-graph" />
      <div className="macroAreaHistory-skeleton-graph" />
      <div className="macroAreaHistory-skeleton-graph" />
    </>
  );

  renderDashboardContent = list => (
    <main className="dashboardPageOrders-content">
      {this.renderCommercialOrdersDashboard(list)}
    </main>
  )

  getTitle = () => (
      <>
        <div id="titleText">
          Dashboard
        </div>
        <div id="dotWraper">
          <Dot color="black" size="14px" />
        </div>
        <div id="dealerNameDiv" title={langValues.labelsDashboard.pedidos}>
          <span>
            {langValues.labelsDashboard.pedidos}
          </span>
        </div>
      </>
  )

  openDrawer = () => {
    const { showFilter } = this.state;
    if (!showFilter) {
      trackEvent(trackedEvents.buttonClick, { button: trackedButtons.filter });
    }
    this.setState({ showFilter: !showFilter });
  }

  renderMacroAreaGraphs(data, loading, error, selectedFrequency) {
    if (error) {
      return (
        <div className="macroAreasHistory-error">
          <ErrorIcon />
        </div>
      );
    }
    return (
      <div className="macroAreasHistory-wrapper">
        {!loading && data ? Object.entries(data).map(
          ([macroAreaName, graphData]) => (
            <MacroAreaHistoryGraph
              macroArea={macroAreaName}
              data={graphData}
              loading={loading}
              frequency={selectedFrequency.value}
              key={macroAreaName}
            />),
        ) : this.renderMacroAreaHistorySkeleton()}
      </div>
    );
  }

  render = () => {
    const { models, list } = this.props;
    const title = this.getTitle();
    return (
      <div className="dashboardPageOrders">
        <PageHeader
          title={<div className="settingsTitle">{title}</div>}
          FilterComponent={(
            <div className="dashboard-filters">
              <OrderFilters
                onConfirmFilter={this.applyFilter}
                models={models}
                pageName={trackedPages.dashboard}
                filterPlacement="bottom-end"
              />
            </div>
          )}
        />
        {this.renderDashboardContent(list)}
      </div>
    );
  }
}

DashboardOrders.propTypes = {
  models: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  filtersDuck: PropTypes.object.isRequired,
  loadModels: PropTypes.func.isRequired,
  loadResume: PropTypes.func.isRequired,
  loadMacroAreaHistory: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
};

export default DashboardOrders;
