import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import { User, Dealer, Token } from 'Common/Helpers/AuthHelper';
import { defaultStore } from '../index';
import { setExpiredModalOpen } from 'Infrastructure/Ducks/Auth';
import { ENV_CACHE_TIME } from 'Common/Helpers/ApiHelper';
import { roleConsts, idTokenErrorMessage, noCurrentUserMessage } from 'Utils/consts';

const axiosInterceptors = (store, instance) => {
  instance.interceptors.request.use(
    async (config) => {
      let { headers } = config;
      if (User.hasProfile(roleConsts.dealers)) {
        const dealerData = Dealer.get();
        if (dealerData && dealerData.dealerToken) {
          headers = {
            ...headers,
            Authorization: dealerData.dealerToken,
            userDpr: dealerData.userDpr,
            secondaryDealers: dealerData.secondaryDealersIds,
          };
        }
      } else if (User.hasOneOfProfiles([roleConsts.customercare, roleConsts.admin])) {
        const userData = Token.get();
        const idToken = userData.token;
        headers = { ...headers, Authorization: idToken };
      } else if (User.hasProfile(roleConsts.employees)) {
        const tokenData = Token.get();
        const { token } = tokenData;
        headers = { ...headers, currentEmployeeCPF: token };
      }
      config.headers = headers;
      return config;
    },
    (error) => {
      if (error.response.data.token.KEY === 'ERR_EXPIRED_TOKEN') {
        store.dispatch(setExpiredModalOpen());
      }
      return Promise.reject(error);
    }
  );
  instance.interceptors.response.use(
    (config) => config,
    (error) => {
      if (User && User.get()) {
        if (
          (error.response && error.response.status === 403) ||
          error.message === idTokenErrorMessage ||
          error === noCurrentUserMessage
        ) {
          store.dispatch(setExpiredModalOpen());
        }
      }
      return Promise.reject(error);
    }
  );
};

class NetworkConfig {
  static configureCache = async () => {
    const instance = setupCache(axios.create(), {
      ttl: ENV_CACHE_TIME,
      cachePredicate: {
        ignoreUrls: [
          '/parameters',
          '/dealerId',
          '/notifications',
          'customercare/macroarea',
          'customercare/modelsversions/averageInvoiceTime',
          'customercare/averageInvoiceTime/updateDate',
          'customercare/modelsversions/steps',
          'customercare/modelsversions/steps/updateDate',
          'customercare/modelsversions/parameters',
          'customercare/modelsversions/parameters/updateDate',
          'customercare/modelsversions/unregistered',
          'customercare/visionconfigurations/role/ROLE_CLIENT',
          'customercare/visionconfigurations/role/ROLE_EMPLOYEE',
          'customercare/visionconfigurations/role/ROLE_DEALER',
          'customercare/visionconfigurations/role/ROLE_CUSTOMERCARE',
          'customercare/visionconfigurations/role/ROLE_API_FULL_ACCESS',
          'customercare/visionconfigurations/updateDate',
        ],
      },
    });

    axiosInterceptors(defaultStore, instance);

    return instance;
  };

  static setupInterceptors = (store) => {
    axiosInterceptors(store, axios);
  };
}

export default NetworkConfig;
