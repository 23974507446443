import React from 'react';
import PropTypes from 'prop-types';
import { getShortDateAsNumbers } from 'Utils/dateUtils';
import langValues from 'Config/i18n/index';

import './DelayNotificationsItem.scss';
import { trackedButtons, trackedEvents } from 'Utils/consts';
import { trackEvent } from 'Utils/analytics';
import AlertIcon from '../AlertIcon/AlertIcon';

const DelayNotificationsItem = ({ notification, setFilterNotifications, onClose }) => {
  if (notification.type !== 'inRisk' && notification.type !== 'late') return null;

  const content = {
    title: null,
    text: null,
    notificationType: null,
  };

  if (notification.type === 'late') {
    content.title = 'Pedidos Atrasados';
    content.text = 'em atraso';
    content.notificationType = 'ERROR';
  } else {
    content.title = 'Pedidos com Risco de Atraso';
    content.text = 'em risco de atraso';
    content.notificationType = 'WARN';
  }

  const viewOrdersInManagementPage = async () => {
    const filters = {
      stepStatus: notification.type,
      macroArea: notification.macroArea,
      date: notification.date,
    };

    trackEvent(trackedEvents.buttonClick, { button: trackedButtons.delayNotification });

    await setFilterNotifications(filters);
    setTimeout(() => {
      window.location.pathname = '/acompanhamento';
    }, 200);
    onClose();
  };

  return (
    <button type="button" onClick={() => viewOrdersInManagementPage()} className={`delayNotifications-item ${notification.isNew ? 'isNew' : ''}`}>
      <AlertIcon type={content.notificationType} />
      <div className="delayNotifications-item-content">
        <span className="font-subtitle2 title">{content.title}</span>
        <span className="font-small2">
          A área de
          <strong> {langValues.delayNotifications.macroAreas[notification.macroArea]} </strong>
          está com
          <strong> {notification.count} pedidos </strong>
          {content.text}.
          Há <strong>{notification.countNew} novos pedidos</strong> {content.text}.
        </span>
        <span className="font-small3 date">{getShortDateAsNumbers(notification.date, true)}</span>
      </div>
    </button>
  );
};

DelayNotificationsItem.propTypes = {
  notification: PropTypes.object.isRequired,
  setFilterNotifications: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DelayNotificationsItem;
