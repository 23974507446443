import { connect } from 'react-redux';
import {
  setExportPdaFilter,
} from 'Infrastructure/Ducks/FiltersDuck';
import ExportOptionsPDA from './ExportOptionsPDA';

const mapStateToProps = (state) => {
  const { filtersDuck } = state;
  const {
    filtersExportPDA,
  } = filtersDuck;
  return {
    filtersExportPDA,
  };
};

const mapDispatchToProps = dispatch => ({
  setExportPdaFilter: (selected) => {
    dispatch(setExportPdaFilter(selected));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExportOptionsPDA);
