import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { createSearchParams } from 'react-router-dom';
import langValues from 'Config/i18n/index';
import { trackedEvents, trackedPages, trackedButtons } from 'Utils/consts';
import { trackEvent } from 'Utils/analytics';
import ParametersSteps from 'Components/Organisms/ParametersSteps';
import ParametersModelsVersions from 'Components/Organisms/ParametersModelsVersions';
import SubMenu from 'Components/Molecules/SubMenu';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ErrorComponent from 'Components/Atoms/ErrorComponent/ErrorComponent';
import './Parameters.scss';
import HistoricLogsList from 'Components/Organisms/HistoricLogsList/index';

const renderSkeleton = (nItens) => {
  const skeleton = [];
  for (let i = 0; i < nItens; i += 1) {
    skeleton.push(
      <div style={{ display: 'contents' }} key={i}>
        <div className="skeleton-item" key={`${i}-1`} />
        <div className="skeleton-item" key={`${i}-2`} />
        <div className="skeleton-item" key={`${i}-3`} />
      </div>
    );
  }
  return skeleton;
};

const Parameters = ({ navigate, location }) => {
  useLayoutEffect(() => {
    trackEvent(trackedEvents.pageView, { page: trackedPages.parametros });
  });

  const { search } = location;
  const currentActiveTab = Number(new URLSearchParams(search).get('tab')) || 0;
  const setCurrentActiveTab = (newTab) =>
    navigate({
      pathname: location.pathname,
      search: createSearchParams({ tab: newTab }).toString(),
    });

  const renderErrorMessage = () => <ErrorComponent />;

  const getOptionList = () => {
    const optionList = [
      {
        id: 0,
        label: langValues.settingsParametersScreen.stepsAndMacroAreas,
        icon: <ListAltRoundedIcon />,
      },
      {
        id: 1,
        label: langValues.settingsModelsVersions.modelsAndVersions,
        icon: <CalendarTodayOutlinedIcon />,
      },
      {
        id: 2,
        label: langValues.settingsParametersScreen.orderLogHistory,
        icon: <ListAltRoundedIcon />,
      },
    ];
    return optionList;
  };

  const getContent = () => {
    switch (currentActiveTab) {
      case 0: {
        return <ParametersSteps renderSkeleton={renderSkeleton} renderErrorMessage={renderErrorMessage} />;
      }
      case 1: {
        return <ParametersModelsVersions />;
      }
      case 2: {
        return <HistoricLogsList />;
      }
      default:
        return null;
    }
  };

  const handleSubMenuChange = (value) => {
    setCurrentActiveTab(value);
    const { label } = getOptionList().find((item) => item.id === value);
    trackEvent(trackedEvents.buttonClick, {
      button: trackedButtons.subMenuOptionParameters,
      optionLabel: label,
    });
  };

  return (
    <div className="parametersPage">
      <SubMenu
        currentActiveOption={currentActiveTab}
        changeActiveOption={(value) => handleSubMenuChange(value)}
        optionList={getOptionList()}
        title={{ label: langValues.settingsParametersScreen.title }}
      />
      {getContent()}
    </div>
  );
};

Parameters.propTypes = {
  navigate: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }).isRequired,
};

export default Parameters;
