import ListRegionRepository from 'Infrastructure/Repositories/ListRegionRepository';
import { safeToLowerCase } from 'Utils/safeMethods';
import langValues from 'Config/i18n/index';

class ListRegionService {
  constructor(listRepository = new ListRegionRepository()) {
    this.listRepository = listRepository;
  }

  mapRegion = data => data.reduce((acc, cur) => {
    const { regionParameters } = cur;
    const curValue = {
      destiny: cur.destiny,
    };
    langValues.labelsTransitTime.keys.forEach((destinationKey, index) => {
      const destination = langValues.labelsTransitTime.destinations[index];
      curValue[destinationKey] = regionParameters.find(item => safeToLowerCase(item.origin)
      === safeToLowerCase(destination)).dealerRef;
    });
    acc[cur.destiny] = curValue;
    return acc;
  }, {})

  async get() {
    try {
      const { data } = await this.listRepository.get();
      return this.mapRegion(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getCitiesWithRegions() {
    try {
      const { data } = await this.listRepository.getCitiesWithRegions();
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async put(incomingData) {
    try {
      await this.listRepository.put(incomingData);
      return this.mapRegion(incomingData);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async putTransitTime(incomingData) {
    try {
      await this.listRepository.putTransitTime(incomingData);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async exportCities(fileType) {
    return this.listRepository.exportCities(fileType);
  }
}

export default ListRegionService;
