import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Expansion from 'Components/Atoms/Expansion/Expansion';
import langValues from 'Config/i18n/index';
import TextListing from 'Components/Molecules/TextListing';
import { Checkbox } from 'Components/Atoms/DefaultCheckbox/Checkbox';
import { safeToCapitalizeAllFirstLetters, safeToUpperCase } from 'Utils/safeMethods';
import { testEquals } from 'Utils/arrayUtils';

const renderArrowIcon = (name, isSelected, setSelected, shouldRender) => {
  if (shouldRender) {
    return (
      <Expansion
        name={name}
        isSelected={isSelected}
        setSelected={setSelected}
      />
    );
  }
  return null;
};

const ListVisionConfiguration = (
  {
    listItems, setCurrentActiveModel, currentActiveModel, isEditing, setModelCheckBoxes,
    setVersionCheckBoxes, currentMenu,
  },
) => listItems.map((item) => {
  const isSelected = item.code === currentActiveModel;

  const handleNameNull = text => (text !== null ? safeToUpperCase(text) : '-');

  const renderRules = (rules, key) => {
    const rulesWithDescription = rules.map((rule) => {
      const textDescription = langValues.settingsVisionConfiguration.rules[rule];
      if (textDescription) {
        return (textDescription);
      }
      return ({
        text: rule,
        description: rule,
      });
    });

    if (!rules.includes('visible') && currentMenu === 'ROLE_API_FULL_ACCESS') {
      return null;
    }
    return (
      <TextListing
        texts={rulesWithDescription}
        quantity={4}
        key={key}
        rules={rules}
      />
    );
  };

  const renderModelRules = () => {
    if (item.hasChildren) {
      let areRulesEqual = true;
      let versionRules = null;
      item.children.forEach((version) => {
        if (versionRules === null) {
          versionRules = version.rules;
        } else if (areRulesEqual) {
          areRulesEqual = testEquals(versionRules, version.rules);
        }
      });

      if (areRulesEqual) {
        return renderRules(versionRules, item.code);
      }
      const mixedRules = ['differentRules'];
      return renderRules(mixedRules, item.code);
    }
    return renderRules(item.rules, item.code);
  };

  const renderCheckBox = (isChecked, isIndeterminated, onChange) => (
    isEditing
      ? (
        <td className="font-subtitle2 item-bolder">
          <Checkbox
            checked={isChecked}
            indeterminate={isIndeterminated}
            onChange={() => onChange()}
          />
        </td>
      )
      : null
  );

  const renderVersions = () => {
    if (isSelected) {
      return item.children.map(version => (
        <tr
          className="listVersionConfiguration-item children"
          key={`${version.code} ${version.parent} children`}
        >
          {renderCheckBox(version.isChecked, false, () => setVersionCheckBoxes(version))}
          <td className="font-subtitle2 item-bolder" />
          <td className="font-subtitle2 item-bolder">
            {handleNameNull(version.name)}
          </td>
          <td className="font-subtitle2 item-bolder">{version.code}</td>
          <td className="font-subtitle2 item-bolder">{renderRules(version.rules, version.code)}</td>
        </tr>
      ));
    }
    return null;
  };

  return (
    <Fragment key={item.code}>
      <tr className="listVersionConfiguration-item">
        {renderCheckBox(item.isChecked, item.isIndeterminated, () => setModelCheckBoxes(item))}
        <td className="font-subtitle2">
          {renderArrowIcon(item.code, isSelected, setCurrentActiveModel, item.hasChildren)}
        </td>
        <td className="font-subtitle2 item-bolder">
          {safeToCapitalizeAllFirstLetters(item.name)}
        </td>
        <td className="font-subtitle2 item-bolder">{item.code}</td>
        <td className="font-subtitle2 item-bolder">{renderModelRules()}</td>
      </tr>
      {renderVersions()}
    </Fragment>
  );
});

ListVisionConfiguration.propTypes = {
  listItems: PropTypes.array.isRequired,
};

export default ListVisionConfiguration;
