import React from 'react';
import PropTypes from 'prop-types';

import BaseModal from 'Components/Atoms/BaseModal/BaseModal';
import { ReactComponent as CloseIcon } from 'Common/Assets/closeIcon.svg';

import './ConfirmationModal.scss';
import langValues from 'Config/i18n/index';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';
import colorVariables from 'Utils/Styles/colorVariables';

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  onCancel,
  title,
  text,
  confirmButtonText = langValues.save,
  confirmButtonColor = colorVariables.actionPrimaryColor,
}) => (
  <BaseModal open={isOpen} onClose={onClose} className="confirmationModal">
    <div className="confirmationModal-wrapper">
      <CloseIcon className="confirmationModal-closeIcon" onClick={onClose} />
      <div className="confirmationModal-message">
        <h5 className="confirmationModal-title">{title}</h5>
        <p className="confirmationModal-text font-body2">{text}</p>
      </div>
      <div className="confirmationModal-actions">
        <MaterialButton
          sx={{ ':hover': { borderColor: '#0000003b' } }}
          onClick={onCancel}
          buttonText={langValues.cancel}
          type="secondary"
          variant="outlined"
        />
        <MaterialButton
          onClick={onConfirm}
          buttonText={confirmButtonText}
          type="primary"
          variant="contained"
          backgroundColor={confirmButtonColor}
        />
      </div>
    </div>
  </BaseModal>
);

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  confirmButtonText: PropTypes.string,
  confirmButtonColor: PropTypes.string,
};

export default ConfirmationModal;
