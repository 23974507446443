import { connect } from 'react-redux';
import {
  loadClientOrder, changeSelectedOrder, loadTotalClientOrderById, setCommercialDrawerOpen,
} from 'Infrastructure/Ducks/OrderDetailDuck';
import ClientOrderDetail from './ClientOrderDetail';

const mapStateToProps = (state) => {
  const { orderDetail } = state;
  return {
    selectedIndustrialOrder: orderDetail.selectedIndustrialOrder,
    order: orderDetail.order,
    clientOrders: orderDetail.clientOrders,
    loading: orderDetail.loading,
    error: orderDetail.error,
    total: orderDetail.total,
    pageSize: 7,
    isCommercialDrawerOpen: orderDetail.isCommercialDrawerOpen,
  };
};

const mapDispatchToProps = dispatch => ({
  loadClientOrder: (clientDocument, order, page, size) => {
    dispatch(loadClientOrder(clientDocument, order, page, size));
  },
  changeSelectedClientOrder: (order) => {
    dispatch(changeSelectedOrder(order));
  },
  loadTotalClientOrderById: (clientDocument) => {
    dispatch(loadTotalClientOrderById(clientDocument));
  },
  setCommercialDrawerOpen: (status) => {
    dispatch(setCommercialDrawerOpen(status));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientOrderDetail);
