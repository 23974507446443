import langValues from 'Config/i18n/index';
import { getDateAsNumbers } from 'Utils/dateUtils';

const getMonthLabels = (yearMonth) => {
  const year = yearMonth.toString().slice(0, 4);
  const month = yearMonth.toString().slice(4);
  const monthName = langValues.months[month - 1];
  return `${monthName}/${year}`;
};

const getWeekLabels = (yearWeek) => {
  const year = yearWeek.toString().slice(0, 4);
  const week = yearWeek.toString().slice(4);

  const date = new Date(year, 0, (1 + (week - 1) * 7));
  date.setDate(date.getDate() + (1 - date.getDay()));

  return getDateAsNumbers(date, true);
};

const getDayLabels = (yearMonthDay) => {
  const year = yearMonthDay.toString().slice(0, 4);
  const month = yearMonthDay.toString().slice(4, 6);
  const day = yearMonthDay.toString().slice(6, 8);

  return `${day}/${month}/${year}`;
};

export const getLabels = (data, frequency, loading) => data.map((item) => {
  if (loading) return '';
  if (frequency === 'DAY') {
    return getDayLabels(item);
  }
  if (frequency === 'WEEK') {
    return getWeekLabels(item);
  }
  if (frequency === 'MONTH') {
    return getMonthLabels(item);
  }

  return item;
});

export default null;
