import React from 'react';
import PropTypes from 'prop-types';
import Tab from 'Components/Atoms/Tab/Tab';
import { trackedEvents, trackedButtons } from 'Utils/consts';
import { trackEvent } from 'Utils/analytics';

import './TabsSelector.scss';

const TabsSelector = ({ currentActiveTab, changeActiveTab, tabsList }) => (
  <section className="tabs-selector">
    {tabsList.map(tab => (
      <Tab
        key={tab.id}
        tabText={tab.label}
        isActive={tab.id === currentActiveTab}
        isDisabled={tab.disabled}
        onTabClick={() => {
          changeActiveTab(tab.id);
          if (tab.id !== currentActiveTab) {
            trackEvent(trackedEvents.buttonClick, {
              button: trackedButtons.carouselTab,
              tabLabel: tab.label,
            });
          }
        }}
      />
    ))}
  </section>
);

TabsSelector.propTypes = {
  currentActiveTab: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  changeActiveTab: PropTypes.func.isRequired,
  tabsList: PropTypes.array.isRequired,
};

export default TabsSelector;
