import { Dealer, User } from 'Common/Helpers/AuthHelper';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import { environment } from 'Utils/consts';
import filterItems from './filterItems';

const getPanel = key => ({
  panelKey: key,
  panelName: filterItems.filterPanelTitles[key],
  panelItems: filterItems.filterPanels[key],
});

const getDealerPendenciesPanel = () => {
  const basePanel = getPanel('pendencies');
  const finalPanel = {
    ...basePanel,
    panelItems: { document: basePanel.panelItems.document },
  };

  return finalPanel;
};

const getDealerEmployeePanel = () => {
  const basePanel = getPanel('macroCategories');
  const finalPanel = {
    ...basePanel,
    panelItems: { employee: basePanel.panelItems.employee },
  };

  return finalPanel;
};

const getModelsPanel = (models) => {
  const basePanel = getPanel('models');

  const modelItems = {};

  if (models.data) {
    models.data.forEach((model) => {
      modelItems[model.modelId] = model.modelName;
    });
  }

  const finalPanel = {
    ...basePanel,
    panelItems: modelItems,
  };

  return finalPanel;
};

const customercareARPanels = models => [
  getPanel('status'),
  getPanel('steps'),
  getPanel('brands'),
  getModelsPanel(models),
  getPanel('orderType'),
];

const customercareARPanelsPDA = models => [
  getPanel('status'),
  getPanel('stepsAdministrative'),
  getPanel('steps'),
  getPanel('brands'),
  getModelsPanel(models),
  getPanel('orderType'),
];


const dealerARPanels = models => [
  getPanel('status'),
  getPanel('steps'),
  getPanel('brands'),
  getModelsPanel(models),
  getPanel('orderType'),
];

const customercareBRPanels = models => [
  getPanel('categories'),
  getPanel('status'),
  getPanel('pendencies'),
  getPanel('macroAreas'),
  getPanel('steps'),
  getPanel('brands'),
  getModelsPanel(models),
  getPanel('macroCategories'),
  getPanel('orderType'),
];

const dealerBRPanels = models => [
  getPanel('secondaryDealers'),
  getPanel('categories'),
  getPanel('status'),
  getDealerEmployeePanel(),
  getDealerPendenciesPanel(),
  getPanel('steps'),
  getPanel('brands'),
  getModelsPanel(models),
  getPanel('orderType'),
];

const delayBRPanels = models => [
  getPanel('macroAreas'),
  getPanel('statusDelay'),
  getPanel('stepsDelay'),
  getPanel('pendencies'),
  getModelsPanel(models),
  getPanel('brands'),
  getPanel('categories'),
  getPanel('macroCategories'),
  getPanel('orderType'),
];

const getFilterPanels = (isDelayManagement, models) => {
  const isDealer = Dealer.get();
  const isBR = ENV_ENVIRONMENT === environment.BR;

  if (isDelayManagement) {
    return delayBRPanels(models);
  }

  if (isDealer) {
    if (isBR) return dealerBRPanels(models);
    return dealerARPanels(models);
  }

  if (isBR) return customercareBRPanels(models);
  return User.canSeePDA() ? customercareARPanelsPDA(models) : customercareARPanels(models);
};

export default getFilterPanels;
