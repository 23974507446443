import React from 'react';
import PropTypes from 'prop-types';
import './TimelineAdministrative.scss';
import { stepStatusId, timeLine, deviceInfo } from 'Utils/consts';
import TimelineItem from 'Components/Molecules/TimelineItem';
import langValues from 'Config/i18n/index';

const TimelineAdministrativePDA = ({
  selectedIndustrialOrder,
  order,
  deviceType = deviceInfo.typeDesktop,
  steps = [],
  isAdmintrative = false,
}) => {
  const getOrderCreationStep = () => {
    const orderDate = order ? order.orderDate : null;
    return {
      stepId: { id: 'orderDate', description: langValues.timeLineLabels.orderCreation },
      status: stepStatusId.delivered,
      actualDate: orderDate,
    };
  };

  const getCurrentStep = (step) => {
    if (!isAdmintrative && selectedIndustrialOrder.industrialOrder)
      return selectedIndustrialOrder.industrialOrder.step === step.stepId.id;

    return step && step.sequence === 7 && step.enable;
  };

  const renderTimeline = () => {
    const industOrder = !isAdmintrative && selectedIndustrialOrder && selectedIndustrialOrder.industrialOrder;
    const isStepDealer = (step) => industOrder && step.stepId.id === timeLine.stepNames.concessionaria;

    return (
      <>
        {!isAdmintrative && (
          <TimelineItem
            key="orderDate"
            step={getOrderCreationStep()}
            isSingleStep
            hasDefaultFlag={!isAdmintrative && order ? order.transitTimeDefaultFlag : false}
            deviceType={deviceType}
            isPDA={isAdmintrative}
            isAdmintrative={isAdmintrative}
            rules={selectedIndustrialOrder.industrialOrder && selectedIndustrialOrder.industrialOrder.rules}
          />
        )}

        {steps.map((step, idx) => (
          <TimelineItem
            deviceType={deviceType}
            key={isAdmintrative ? step.sequence : step.stepId.id}
            step={step}
            isCurrentStep={getCurrentStep(step)}
            isSingleStep={idx === 0 && isAdmintrative}
            isPDA={isAdmintrative}
            isAdmintrative={isAdmintrative}
            hasDefaultFlag={!isAdmintrative && order ? order.transitTimeDefaultFlag : false}
            vehicleFleet={isStepDealer(step) ? selectedIndustrialOrder.industrialOrder.vehicleFleet : null}
            rules={selectedIndustrialOrder.industrialOrder && selectedIndustrialOrder.industrialOrder.rules}
            previouslyStepIsEnable={isAdmintrative && step.sequence === 10 && steps[steps.length - 2].enable}
          />
        ))}
      </>
    );
  };

  return (
    <article className={`timeline-card ${isAdmintrative && 'administrative'}`}>
      <section className="timeline-grid">{renderTimeline()}</section>
    </article>
  );
};

TimelineAdministrativePDA.propTypes = {
  selectedIndustrialOrder: PropTypes.object.isRequired,
  order: PropTypes.object,
  deviceType: PropTypes.string,
  steps: PropTypes.array,
  isAdmintrative: PropTypes.bool,
};

export default TimelineAdministrativePDA;
