/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
import ListPedidosIndustriaisService from '../Services/ListPedidosIndustriaisService';

export const DUCK_NAME = 'listPedidosIndustriais';

export const INITIAL_STATE = {
  loading: false,
  success: false,
  error: null,
  selectedIndustrialOrder: '',
  data: {
    listaPedidosIndustriais: [],
  },
};

export const LOAD_PEDIDOINDUSTRIAL_STARTED = `${DUCK_NAME}/LOAD_PEDIDOINDUSTRIAL_STARTED`;
export const LOAD_PEDIDOINDUSTRIAL_SUCCEED = `${DUCK_NAME}/LOAD_PEDIDOINDUSTRIAL_SUCCEED`;
export const LOAD_PEDIDOINDUSTRIAL_FAILED = `${DUCK_NAME}/LOAD_PEDIDOINDUSTRIAL_FAILED`;
export const LOAD_LIST_PEDIDOINDUSTRIAL_STARTED = `${DUCK_NAME}/LOAD_LIST_PEDIDOINDUSTRIAL_STARTED`;
export const LOAD_LIST_PEDIDOINDUSTRIAL_SUCCEED = `${DUCK_NAME}/LOAD_LIST_PEDIDOINDUSTRIAL_SUCCEED`;
export const LOAD_LIST_PEDIDOINDUSTRIAL_FAILED = `${DUCK_NAME}/LOAD_LIST_PEDIDOINDUSTRIAL_FAILED`;
export const UPDATE_LIST_PEDIDOINDUSTRIAL_STARTED = `${DUCK_NAME}/UPDATE_LIST_PEDIDOINDUSTRIAL_STARTED`;
export const UPDATE_LIST_PEDIDOINDUSTRIAL_SUCCEED = `${DUCK_NAME}/UPDATE_LIST_PEDIDOINDUSTRIAL_SUCCEED`;
export const UPDATE_LIST_PEDIDOINDUSTRIAL_FAILED = `${DUCK_NAME}/UPDATE_LIST_PEDIDOINDUSTRIAL_FAILED`;

export const loadPedidoIndustrialStarted = () => ({ type: LOAD_PEDIDOINDUSTRIAL_STARTED });
export const loadPedidoIndustrialSucceed = data => ({ type: LOAD_PEDIDOINDUSTRIAL_SUCCEED, data });
export const loadPedidoIndustrialFailed = error => ({ type: LOAD_PEDIDOINDUSTRIAL_FAILED, error });
export const loadListPedidosIndustraisStarted = () => ({ type: LOAD_LIST_PEDIDOINDUSTRIAL_STARTED });
export const loadListPedidosIndustraisSucceed = data => ({ type: LOAD_LIST_PEDIDOINDUSTRIAL_SUCCEED, data });
export const loadListPedidosIndustraisFailed = error => ({ type: LOAD_LIST_PEDIDOINDUSTRIAL_FAILED, error });
export const checkImageStarted = () => ({ type: LOAD_PEDIDOINDUSTRIAL_STARTED });
export const checkImageSucceed = data => ({ type: LOAD_PEDIDOINDUSTRIAL_SUCCEED, data });
export const checkImageFailed = error => ({ type: LOAD_PEDIDOINDUSTRIAL_FAILED, error });
export const updateListPedidosIndustrialStarted = () => ({ type: UPDATE_LIST_PEDIDOINDUSTRIAL_STARTED });
export const updateListPedidosIndustrialSucceed = data => ({ type: UPDATE_LIST_PEDIDOINDUSTRIAL_SUCCEED, data });
export const updateListPedidosIndustrialFailed = () => ({ type: UPDATE_LIST_PEDIDOINDUSTRIAL_FAILED });

export const updateListPedidosIndustriais = data => async (dispatch) => {
  dispatch(updateListPedidosIndustrialStarted());
  try {
    dispatch(updateListPedidosIndustrialSucceed(data));
  } catch (error) {
    dispatch(updateListPedidosIndustrialFailed());
  }
};

export const loadPedidoIndustrial = codigoPedidoIndustrial => async (dispatch) => {
  dispatch(loadPedidoIndustrialStarted());
  let data = {};
  try {
    data = codigoPedidoIndustrial;
    dispatch(loadPedidoIndustrialSucceed(data));
  } catch (error) {
    dispatch(loadPedidoIndustrialFailed(error));
  }
};

export const loadListPedidosIndustriais = codPedidoComercial => async (dispatch) => {
  dispatch(loadListPedidosIndustraisStarted());
  try {
    const listService = new ListPedidosIndustriaisService();
    const data = await listService.get(codPedidoComercial);
    const industrialOrderId = data.industrialOrdersList && data.industrialOrdersList.length > 0
      ? data.industrialOrdersList[0].industrialOrderId
      : '';
    data.selectedIndustrialOrder = industrialOrderId;
    dispatch(loadListPedidosIndustraisSucceed(data));
  } catch (err) {
    dispatch(loadListPedidosIndustraisFailed(err));
  }
};

export const loadEmployeeOrder = (employeeCPF, currentEmployeeCPF) => async (dispatch) => {
  dispatch(loadListPedidosIndustraisStarted());
  try {
    const listService = new ListPedidosIndustriaisService();
    const employeeOrder = await listService.getEmployeeOrder(employeeCPF, currentEmployeeCPF);
    const industrialOrderId = employeeOrder.industrialOrdersList && employeeOrder.industrialOrdersList.length > 0
      ? employeeOrder.industrialOrdersList[0].industrialOrderId
      : '';
    employeeOrder.selectedIndustrialOrder = industrialOrderId;
    dispatch(loadListPedidosIndustraisSucceed(employeeOrder));
  } catch (err) {
    dispatch(loadListPedidosIndustraisFailed(err));
  }
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_PEDIDOINDUSTRIAL_STARTED:
      return {
        ...state,
        selectedIndustrialOrder: '',
        loading: true,
      };
    case LOAD_PEDIDOINDUSTRIAL_SUCCEED:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        selectedIndustrialOrder: action.data,
      };
    case LOAD_PEDIDOINDUSTRIAL_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
        selectedIndustrialOrder: '',
      };
    case LOAD_LIST_PEDIDOINDUSTRIAL_SUCCEED:
      return {
        ...state,
        loading: false,
        success: true,
        data: { ...action.data },
        selectedIndustrialOrder: action.data.selectedIndustrialOrder,
        error: null,
      };
    case LOAD_LIST_PEDIDOINDUSTRIAL_STARTED:
      return {
        ...state,
        selectedIndustrialOrder: '',
        data: {
          listaPedidosIndustriais: [],
        },
        loading: true,
      };
    case UPDATE_LIST_PEDIDOINDUSTRIAL_STARTED:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_LIST_PEDIDOINDUSTRIAL_SUCCEED:
      return {
        ...state,
        loading: false,
        success: true,
        data: { ...action.data },
      };
    case LOAD_LIST_PEDIDOINDUSTRIAL_FAILED:
    case UPDATE_LIST_PEDIDOINDUSTRIAL_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
