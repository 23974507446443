import ListPedidosIndustriaisService from '../Services/ListPedidosIndustriaisService';
import ListPedidosService from '../Services/ListPedidosService';

export const DUCK_NAME = 'orderDetail';

export const SELECTED_INDUSTRIAL_ORDER_INITIAL_STATE = () => ({
  loading: false,
  industrialOrder: null,
});

export const INITIAL_STATE = () => ({
  loading: false,
  success: false,
  error: null,
  total: 0,
  selectedIndustrialOrder: SELECTED_INDUSTRIAL_ORDER_INITIAL_STATE(),
  currentActiveTab: 'all',
  order: null,
  clientOrders: [],
  isCommercialDrawerOpen: false,
});

export const LOAD_ORDER_BY_ID_STARTED = `${DUCK_NAME}/LOAD_ORDER_BY_ID_STARTED`;
export const LOAD_ORDER_BY_ID_SUCCEED = `${DUCK_NAME}/LOAD_ORDER_BY_ID_SUCCEED`;
export const LOAD_CLIENT_ORDER_BY_ID_SUCCEED = `${DUCK_NAME}/LOAD_CLIENT_ORDER_BY_ID_SUCCEED`;
export const LOAD_ORDER_BY_ID_FAILED = `${DUCK_NAME}/LOAD_ORDER_BY_ID_FAILED`;

export const LOAD_TOTAL_CLIENT_ORDER_BY_ID_SUCCEED = `${DUCK_NAME}/LOAD_TOTAL_CLIENT_ORDER_BY_ID_SUCCEED`;
export const LOAD_TOTAL_CLIENT_ORDER_BY_ID_FAILED = `${DUCK_NAME}/LOAD_TOTAL_CLIENT_ORDER_BY_ID_FAILED`;

export const loadOrderByIdStarted = () => ({ type: LOAD_ORDER_BY_ID_STARTED });
export const loadOrderByIdSucceed = data => ({ type: LOAD_ORDER_BY_ID_SUCCEED, data });
export const loadClientOrderByIdSucceed = (orders, initialOrder, industrialOrder) => ({
  type: LOAD_CLIENT_ORDER_BY_ID_SUCCEED, orders, initialOrder, industrialOrder,
});
export const loadOrderByIdFailed = error => ({ type: LOAD_ORDER_BY_ID_FAILED, error });

export const loadTotalClientOrderByIdSucceed = total => ({
  type: LOAD_TOTAL_CLIENT_ORDER_BY_ID_SUCCEED, total,
});
export const loadTotalClientOrderByIdFailed = () => ({
  type: LOAD_TOTAL_CLIENT_ORDER_BY_ID_FAILED,
});

export const CHANGE_SELECTED_INDUSTRIAL_ORDER_STARTED = `${DUCK_NAME}/CHANGE_SELECTED_INDUSTRIAL_ORDER_STARTED`;
export const CHANGE_SELECTED_INDUSTRIAL_ORDER_SUCCEED = `${DUCK_NAME}/CHANGE_SELECTED_INDUSTRIAL_ORDER_SUCCEED`;
export const CHANGE_SELECTED_ORDER_SUCCEED = `${DUCK_NAME}/CHANGE_SELECTED_ORDER_SUCCEED`;

export const SET_COMMERCIAL_DRAWER_OPEN = `${DUCK_NAME}/SET_COMMERCIAL_DRAWER_OPEN`;

export const changeSelectedIndustrialOrderStarted = data => (
  { type: CHANGE_SELECTED_INDUSTRIAL_ORDER_STARTED, data }
);
export const changeSelectedIndustrialOrderSucceed = () => (
  { type: CHANGE_SELECTED_INDUSTRIAL_ORDER_SUCCEED }
);
export const changeSelectedOrderSucceed = (order, industrialOrder) => (
  { type: CHANGE_SELECTED_ORDER_SUCCEED, order, industrialOrder }
);

export const CLEAR_DUCK = `${DUCK_NAME}/CLEAR_DUCK`;
export const clearDuck = () => ({ type: CLEAR_DUCK });

export const CHANGE_ACTIVE_TAB = `${DUCK_NAME}/CHANGE_ACTIVE_TAB`;
export const changeActiveTab = tabId => ({ type: CHANGE_ACTIVE_TAB, tabId });

export const setCommercialDrawerOpen = isOpen => (
  { type: SET_COMMERCIAL_DRAWER_OPEN, isOpen }
);

export const SET_UPDATE_DATE = `${DUCK_NAME}/SET_UPDATE_DATE`;
export const setUpdateDate = date => ({ type: SET_UPDATE_DATE, date });

export const loadOrderById = (orderId, callback) => async (dispatch) => {
  dispatch(loadOrderByIdStarted());
  try {
    const listService = new ListPedidosIndustriaisService();
    const data = await listService.get(orderId);

    callback(data.orderType);
    dispatch(loadOrderByIdSucceed(data));
  } catch (err) {
    dispatch(loadOrderByIdFailed(err));
  }
};

export const changeSelectedIndustrialOrder = newIndustrialOrder => async (dispatch) => {
  dispatch(changeSelectedIndustrialOrderStarted(newIndustrialOrder));
  setTimeout(() => dispatch(changeSelectedIndustrialOrderSucceed()), 1000);
};

export const changeSelectedOrder = order => async (dispatch) => {
  const { industrialOrdersList } = order;
  const industrialOrder = industrialOrdersList ? industrialOrdersList[0] : null;
  dispatch(changeSelectedOrderSucceed(order, industrialOrder));
};

export const loadEmployeeOrder = employeeCPF => async (dispatch) => {
  dispatch(loadOrderByIdStarted());
  try {
    const listService = new ListPedidosIndustriaisService();

    const orders = await listService.getEmployeeOrder(employeeCPF);

    const initialOrder = orders ? orders[0] : null;
    const { industrialOrdersList } = initialOrder;
    const industrialOrder = industrialOrdersList ? industrialOrdersList[0] : null;

    dispatch(loadClientOrderByIdSucceed(orders, initialOrder, industrialOrder));
  } catch (err) {
    dispatch(loadOrderByIdFailed(err));
  }
};

export const loadClientOrder = (clientDocument, order, page, size) => async (dispatch) => {
  dispatch(loadOrderByIdStarted());
  try {
    const listService = new ListPedidosIndustriaisService();

    const orders = await listService.getClientOrder(clientDocument, order, page, size);
    const initialOrderCode = order;
    let initialOrder = null;
    if (initialOrderCode && orders) {
      initialOrder = orders.find(item => item.orderId === initialOrderCode);
      if (initialOrder == null) {
        [initialOrder] = orders;
      }
    }

    const { industrialOrdersList } = initialOrder;
    const industrialOrder = industrialOrdersList ? industrialOrdersList[0] : null;

    dispatch(loadClientOrderByIdSucceed(orders, initialOrder, industrialOrder));
  } catch (err) {
    dispatch(loadOrderByIdFailed(err));
  }
};

export const loadTotalClientOrderById = clientDocument => async (dispatch) => {
  try {
    const listService = new ListPedidosService();
    const data = await listService.getClientOrderTotalById(clientDocument);
    dispatch(loadTotalClientOrderByIdSucceed(data));
  } catch (err) {
    dispatch(loadTotalClientOrderByIdFailed());
  }
};

const reducer = (state = INITIAL_STATE(), action) => {
  switch (action.type) {
    case LOAD_ORDER_BY_ID_SUCCEED:
      return {
        ...state,
        loading: false,
        success: true,
        order: action.data,
        error: null,
      };
    case LOAD_CLIENT_ORDER_BY_ID_SUCCEED:
      return {
        ...state,
        loading: false,
        success: true,
        order: action.initialOrder,
        selectedIndustrialOrder: {
          industrialOrder: action.industrialOrder,
          loading: false,
        },
        clientOrders: action.orders,
        error: null,
      };
    case LOAD_ORDER_BY_ID_STARTED:
      return {
        ...state,
        order: null,
        clientOrders: [],
        loading: true,
      };
    case LOAD_ORDER_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case CHANGE_SELECTED_INDUSTRIAL_ORDER_STARTED:
      return {
        ...state,
        selectedIndustrialOrder: {
          industrialOrder: action.data,
          loading: true,
        },
      };
    case CHANGE_SELECTED_INDUSTRIAL_ORDER_SUCCEED:
      return {
        ...state,
        selectedIndustrialOrder: {
          ...state.selectedIndustrialOrder,
          loading: false,
        },
      };
    case CHANGE_SELECTED_ORDER_SUCCEED:
      return {
        ...state,
        order: action.order,
        selectedIndustrialOrder: {
          industrialOrder: action.industrialOrder,
          loading: false,
        },
      };
    case CHANGE_ACTIVE_TAB:
      return {
        ...state,
        currentActiveTab: action.tabId,
      };
    case SET_UPDATE_DATE:
      return {
        ...state,
        lastSystemUpdate: action.date,
      };
    case LOAD_TOTAL_CLIENT_ORDER_BY_ID_SUCCEED:
      return {
        ...state,
        total: action.total,
      };
    case LOAD_TOTAL_CLIENT_ORDER_BY_ID_FAILED:
      return {
        ...state,
        total: 0,
      };
    case SET_COMMERCIAL_DRAWER_OPEN:
      return {
        ...state,
        isCommercialDrawerOpen: action.isOpen,
      };
    case CLEAR_DUCK:
      return {
        ...INITIAL_STATE(),
      };
    default:
      return state;
  }
};

export default reducer;
