import React, { Component } from 'react';
import PageHeader from 'Components/Shared/PageHeader/PageHeader';
import { trackEvent } from 'Utils/analytics';
import {
  trackedEvents,
} from 'Utils/consts';
import langValues from 'Config/i18n/index';
import Dot from 'Components/Shared/Dot/Dot';
import DashboardAccessReport from '../DashboardAccessReport';
import './DashboardAccesses.scss';

class DashboardAccesses extends Component {
  constructor(props) {
    super(props);
    trackEvent(trackedEvents.pageView, { page: 'Dashboard' });
  }

  getTitle = () => (
      <>
        <div id="titleText">
          Dashboard
        </div>
        <div id="dotWraper">
          <Dot color="black" size="14px" />
        </div>
        <div id="dealerNameDiv" title={langValues.labelsDashboard.acessos}>
          <span>
            {langValues.labelsDashboard.acessos}
          </span>
        </div>
      </>
  )

  render = () => {
    const title = this.getTitle();
    return (
      <>
        <div className="dashboardPageAccesses">
          <PageHeader
            title={<div className="settingsTitle">{title}</div>}
          />
          <DashboardAccessReport />
        </div>
      </>
    );
  }
}

export default DashboardAccesses;
