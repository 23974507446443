import React from 'react';
import PropTypes from 'prop-types';
// import UnregisteredVersionsModal from 'Components/Molecules/UnregisteredVersionsModal';
import UnregisteredDataModal from 'Components/Molecules/UnregisteredDataModal';
import DelayedOrders from 'Components/Molecules/DelayedOrders';
import ExportButton from '../ExportButton';
import Loader from '../Loader/index';

import './PageHeader.scss';

function PageHeader({
  title,
  subtitle,
  RightComponent,
  onChange = () => {},
  search = () => {},
  searchString = '',
  filterClick = () => {},
  filterApplied = false,
  shouldRenderExportButton,
  shouldRenderUnregisteredData,
  shouldRenderDelayedOrders,
  loadingExport,
  handleDownloadReport = () => {},
  FilterComponent,
  hasOneFileType = false,
  onApplyFilter,
  isTabPDA = false,
}) {
  const renderRightComponent = () => {
    if (RightComponent) {
      return (
        <div className="right-component">
          <RightComponent
            onChange={onChange}
            search={search}
            searchString={searchString}
            filterClick={filterClick}
            filterApplied={filterApplied}
          />
        </div>
      );
    }

    if (FilterComponent) {
      return FilterComponent;
    }
    return null;
  };

  const renderSubtitle = () => {
    if (subtitle) return <span className="subtitle">{subtitle}</span>;
    return null;
  };

  const renderExportButton = () =>
    shouldRenderExportButton ? (
      <ExportButton handleDownloadReport={handleDownloadReport} hasOneFileType={hasOneFileType} isTabPDA={isTabPDA} />
    ) : null;

  /*
  const renderUnregisteredVersionsModal = () =>
    shouldRenderUnregisteredVersions ? <UnregisteredVersionsModal /> : null;
  */

  const renderunregisteredDataModal = () => (shouldRenderUnregisteredData ? <UnregisteredDataModal /> : null);

  const renderDelayedOrders = () =>
    shouldRenderDelayedOrders ? <DelayedOrders handleConfirm={onApplyFilter} /> : null;

  const indicator = () => (loadingExport ? <Loader /> : null);

  return (
    <div className="page-header">
      <div className="header">
        <div className="header-top">
          <div className="title">{title}</div>
        </div>
        {renderSubtitle()}
      </div>
      <div className="rightSide">
        {indicator()}
        {renderunregisteredDataModal()}
        {renderDelayedOrders()}
        {renderExportButton()}
        {renderRightComponent()}
      </div>
    </div>
  );
}

PageHeader.propTypes = {
  title: PropTypes.object.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  RightComponent: PropTypes.elementType,
  FilterComponent: PropTypes.element,
  onChange: PropTypes.func,
  search: PropTypes.func,
  handleDownloadReport: PropTypes.func,
  searchString: PropTypes.string,
  filterClick: PropTypes.func,
  filterApplied: PropTypes.bool,
  loadingExport: PropTypes.bool,
  shouldRenderExportButton: PropTypes.bool,
  // shouldRenderUnregisteredVersions: PropTypes.bool,
  shouldRenderUnregisteredData: PropTypes.bool,
  shouldRenderDelayedOrders: PropTypes.bool,
  hasOneFileType: PropTypes.bool,
  onApplyFilter: PropTypes.func,
  isTabPDA: PropTypes.bool,
};

export default PageHeader;
