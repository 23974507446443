import DashboardRepository from 'Infrastructure/Repositories/DashboardRepository';
import BaseService from './BaseService';

class DashboardService {
  constructor(dashboardRepository = new DashboardRepository()) {
    this.dashboardRepository = dashboardRepository;
    this.baseService = new BaseService();
  }

  async getMacroAreaHistory(frequency) {
    try {
      const { data } = await this.dashboardRepository.getMacroAreaHistory(frequency);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getBrand(searchString, selected, dates) {
    const timestampDate = this.baseService.getTimestampDate(dates);
    try {
      const { data } = await this.dashboardRepository.getBrand(
        searchString,
        selected,
        timestampDate,
      );
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getClientMacroCategory(searchString, selected, dates) {
    const timestampDate = this.baseService.getTimestampDate(dates);
    try {
      const { data } = await this.dashboardRepository.getClientMacroCategory(
        searchString,
        selected,
        timestampDate,
      );
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getMacroArea(searchString, selected, dates) {
    const timestampDate = this.baseService.getTimestampDate(dates);
    try {
      const { data } = await this.dashboardRepository.getMacroArea(
        searchString,
        selected,
        timestampDate,
      );
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default DashboardService;
