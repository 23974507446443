import React from 'react';
import PropTypes from 'prop-types';

import langValues from 'Config/i18n/index';
import BrandLogo from 'Components/Shared/BrandLogo/BrandLogo';
import BaseIndustrialOrderDetail from '../BaseIndustrialOrderDetail';
import IndustrialOrderDetailBody from '../CarDetail';
import './ClientIndustrialOrderDetail.scss';

const ClientIndustrialOrderDetail = ({ industrialOrder, subtitle }) => (
  <BaseIndustrialOrderDetail
    title={langValues.orderDetail.clientOrderDetailTitle}
    body={(
      <IndustrialOrderDetailBody industrialOrder={industrialOrder} />
    )}
    logo={<BrandLogo brandId={industrialOrder.brandId} />}
    subtitle={subtitle}
    classNamePrefix="client"
    industrialOrder={industrialOrder}
  />
);

ClientIndustrialOrderDetail.propTypes = {
  industrialOrder: PropTypes.object.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default ClientIndustrialOrderDetail;
