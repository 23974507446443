import React from 'react';
import langValues from 'Config/i18n/index';
import { ReactComponent as EmptyState } from 'Common/Assets/empty_state.svg';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';

import './ErrorComponent.scss';

const ErrorComponent = () => {
  const errorMessage = langValues.loadErrorMessage;
  return (
    <div className="error-component">
      <EmptyState />
      <span className="font-subtitle2">{errorMessage}</span>
      <MaterialButton
        onClick={() => window.location.reload()}
        buttonText={langValues.reloadPage}
        variant="outlined"
      />
    </div>
  );
};

export default ErrorComponent;
