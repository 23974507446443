import FeaturesService from '../Services/FeaturesService';

export const DUCK_NAME = 'featuresDuck';

const INITIAL_STATE = () => ({
  features: [],
  viewedFeatures: [],
  showEmployeeFeature: true,
});

const LOAD_FEATURES_SUCCEED = `${DUCK_NAME}/LOAD_FEATURES_SUCCEED`;
const SET_FEATURES_VIEWED = `${DUCK_NAME}/SET_FEATURES_VIEWED`;
const SET_EMPLOYEE_FEATURE_VIEWED = `${DUCK_NAME}/SET_EMPLOYEE_FEATURE_VIEWED`;
const CLEAR_FEATURES = `${DUCK_NAME}/CLEAR_FEATURES`;
const CLEAR_STATE = `${DUCK_NAME}/CLEAR_STATE`;

export const loadFeaturesSucceed = features => (
  { type: LOAD_FEATURES_SUCCEED, features }
);

export const setFeaturesViewed = () => ({ type: SET_FEATURES_VIEWED });

export const clearState = () => ({ type: CLEAR_STATE });

export const clearFeatures = () => ({ type: CLEAR_FEATURES });

export const setEmployeeFeatureViewed = () => ({ type: SET_EMPLOYEE_FEATURE_VIEWED });

export const loadFeatures = () => async (dispatch) => {
  try {
    const featuresService = new FeaturesService();
    const features = await featuresService.getFeatures();
    dispatch(loadFeaturesSucceed(features));
  } catch (err) {
    dispatch(clearFeatures());
  }
};

const reducer = (state = INITIAL_STATE(), action) => {
  switch (action.type) {
    case LOAD_FEATURES_SUCCEED:
      return {
        ...state,
        features: action.features.filter(feature => !state.viewedFeatures.includes(feature.id)),
      };
    case SET_FEATURES_VIEWED:
      return {
        ...state,
        viewedFeatures: state.viewedFeatures.concat(state.features.map(feature => feature.id)),
        features: [],
      };
    case SET_EMPLOYEE_FEATURE_VIEWED:
      return {
        ...state,
        showEmployeeFeature: false,
      };
    case CLEAR_FEATURES:
      return {
        ...INITIAL_STATE(),
        showEmployeeFeature: state.showEmployeeFeature,
      };
    case CLEAR_STATE:
      return INITIAL_STATE();
    default:
      return state;
  }
};

export default reducer;
