import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BaseModal from 'Components/Atoms/BaseModal/BaseModal';
import { ReactComponent as ChassiIcon } from 'Common/Assets/chassiCarIcon.svg';
import Button from '@mui/material/Button';
import MaterialButton from '../MaterialButton/MaterialButton';
import './ChassiInformationIcon.scss';

function ChassiInformationIcon({ chassi }) {
  const [chassiModalOpen, setChassiModalOpen] = useState(false);

  const handleInformationButtonClick = () => {
    setChassiModalOpen(true);
  };

  const handleOkButtonClick = () => {
    setChassiModalOpen(false);
  };

  return (
    <>
      <Button className="chassiInfo-button" variant="outlined" onClick={handleInformationButtonClick}>
        <>
          <ChassiIcon />
          <span className="font-small2">Ver Chassi</span>
        </>
      </Button>
      <BaseModal open={chassiModalOpen}>
        <div className="chassiModal">
          <div className="chassiDescription">
            <div className="chassiIcon">
              <ChassiIcon />
            </div>
            <div className="chassiBodyText">
              <span className="chassiTextTitle">Chassi</span>
              <h6 className="chassiNumber">{chassi || 'Indisponível'}</h6>
            </div>
          </div>
          <MaterialButton onClick={() => handleOkButtonClick()} buttonText="OK" variant="outlined" />
        </div>
      </BaseModal>
    </>
  );
}

ChassiInformationIcon.propTypes = {
  chassi: PropTypes.string,
};

export default ChassiInformationIcon;
