import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

function PublicPages({ component: Component, logged, ...props }) {
  return (
    <>
      {!logged ? <Component {...props} /> : <Navigate to={{ pathname: '/pedidos' }} state={{ from: props.location }} />}
    </>
  );
}

PublicPages.propTypes = {
  logged: PropTypes.bool.isRequired,
  component: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }).isRequired,
};

export default PublicPages;
