import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import { fontSubtitle2 } from 'Utils/Styles/fontVariables';
import colorVariables from 'Utils/Styles/colorVariables';

const useStyles = makeStyles()((_theme, { borderColor }) => ({
  root: {
    fontSize: '10px',
    borderColor: borderColor || 'rgba(0, 0, 0, 0.23)',
  },
}));

const usePrimaryStyles = makeStyles()((_theme, { backgroundColor }) => ({
  root: {
    ...fontSubtitle2,
    textTransform: 'unset',
    background: backgroundColor || colorVariables.actionPrimaryColor,
    color: colorVariables.actionSecondaryColor,
    padding: '8px 16px',
    '&:hover': {
      background: backgroundColor || colorVariables.actionPrimaryColor,
      opacity: 0.8,
    },
  },
}));

const usePrimaryDisabledStyles = makeStyles()(() => ({
  root: {
    ...fontSubtitle2,
    textTransform: 'unset',
    background: colorVariables.inactivePrimaryStateColor,
    color: '#ffffff',
    padding: '8px 16px',
    '&:hover': {
      opacity: 0.8,
    },
  },
}));

const useSecondaryDisabledStyles = makeStyles()(() => ({
  root: {
    ...fontSubtitle2,
    textTransform: 'unset',
    background: colorVariables.inactiveSecondaryStateColor,
    color: '#bebebe',
    padding: '8px 16px',
    '&:hover': {
      opacity: 0.8,
    },
  },
}));

const useSecondaryStyles = makeStyles()((_theme, { borderColor }) => ({
  root: {
    ...fontSubtitle2,
    textTransform: 'unset',
    color: colorVariables.textBlackColor,
    borderColor: borderColor || 'rgba(0, 0, 0, 0.23)',
  },
}));

const MaterialButton = ({
  buttonText,
  onClick,
  variant = null,
  type = '',
  disabled = false,
  backgroundColor = null,
  borderColor = null,
  ...props
}) => {
  let styles = useStyles({ borderColor });

  if (disabled) {
    switch (type) {
      case 'primary':
        styles = usePrimaryDisabledStyles();
        break;
      case 'secondary':
        styles = useSecondaryDisabledStyles();
        break;
      default:
        styles = usePrimaryDisabledStyles();
    }
  } else {
    switch (type) {
      case 'primary':
        styles = usePrimaryStyles({ backgroundColor });
        break;
      case 'secondary':
        styles = useSecondaryStyles({ borderColor });
        break;
      default:
        break;
    }
  }

  return (
    <Button
      onClick={onClick}
      variant={variant}
      className={styles.classes.root}
      disableElevation
      disabled={disabled}
      {...props}
    >
      {buttonText}
    </Button>
  );
};

MaterialButton.propTypes = {
  buttonText: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
};

export default MaterialButton;
