import React from 'react';
import PropTypes from 'prop-types';

import { Bar } from 'react-chartjs-2';
import langValues from 'Config/i18n/index';

const createYearMonthLabel = (year, month) => {
  const monthName = langValues.months[month - 1];
  return `${monthName}/${year}`;
};

const getGraphLabels = (yearMonthsList) => {
  const graphLabels = yearMonthsList.map((yearMonth) => {
    const year = yearMonth.toString().slice(0, 4);
    const month = yearMonth.toString().slice(4);
    return createYearMonthLabel(year, month);
  });

  return graphLabels;
};

const KpiCsiHistory = ({ data }) => {
  const graphLabels = getGraphLabels(data.yearMonthsList);

  const barGraphData = {
    labels: graphLabels,
    datasets: [
      {
        label: 'Entregues em Dia',
        backgroundColor: '#949EAD',
        data: data.dataSetOnTime,
      },
      {
        label: 'Entregues em Atraso',
        backgroundColor: '#142544',
        data: data.dataSetLate,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'center',
        onClick: (e) => e.native.stopPropagation(),
        labels: {
          color: '#758CA5',
          font: { family: 'Helvetica', weight: 'bold', size: 16, lineHeight: '19px' },
          boxWidth: 16,
          padding: 48,
        },
      },
    },
    scales: {
      xAxes: {
        stacked: true,
      },
      yAxes: {
        stacked: true,
      },
    },
  };

  return <Bar data={barGraphData} options={options} />;
};

KpiCsiHistory.propTypes = {
  data: PropTypes.object.isRequired,
};

export default KpiCsiHistory;
