import React from 'react';
import PropTypes from 'prop-types';
import SingleItemAccordion from 'Components/Shared/SingleItemAccordion/SingleItemAccordion';

import { ReactComponent as PlusIcon } from 'Common/Assets/plusSign.svg';
import { ReactComponent as MinusIcon } from 'Common/Assets/minusSign.svg';

import OrderInformationCardDetail from 'Components/Molecules/OrderInformationCardDetail/OrderInformationCardDetail';
import OrderInformationCardResume from 'Components/Molecules/OrderInformationCardResume/OrderInformationCardResume';

import './OrderInformationCard.scss';
import { trackEvent } from 'Utils/analytics';
import { trackedEvents, trackedButtons } from 'Utils/consts';
import langValues from 'Config/i18n/index';
import accordionStyle from './accordionStyle';

const OrderInformationCard = ({ order, loading = true, isAdministrativePDA, pdaContract }) => {
  if ((!order && !pdaContract) || loading) {
    return <div className="orderInformationCard-skeleton" />;
  }

  const industrialOrdersList = pdaContract && isAdministrativePDA ? [] : order.industrialOrdersList;
  const industrialOrder = industrialOrdersList.length === 0 ? {} : industrialOrdersList[0];

  const AccordionExpansionIcon = () => (
    <div className="orderInformationCard-accordionExpansionButton">
      <PlusIcon className="plusSign" />
      <MinusIcon className="minusSign" />
      <span className="seeMore font-small2">{langValues.telaDetalhesPedidos.seeMore}</span>
      <span className="hide font-small2">{langValues.telaDetalhesPedidos.hide}</span>
    </div>
  );

  const onChangeCallback = (event, expanded) => {
    if (expanded) {
      trackEvent(trackedEvents.buttonClick, { button: trackedButtons.expandDetails });
    }
  };

  return (
    <div className="orderInformationCard-wrapper">
      <SingleItemAccordion
        expandMoreIcon={!isAdministrativePDA ? <AccordionExpansionIcon /> : null}
        accordionSummary={
          <OrderInformationCardResume
            order={order}
            industrialOrder={industrialOrder}
            isPDA={pdaContract}
            pdaContract={pdaContract}
          />
        }
        accordionDetails={
          !isAdministrativePDA ? <OrderInformationCardDetail order={order} industrialOrder={industrialOrder} /> : null
        }
        onChangeCallback={onChangeCallback}
        styles={accordionStyle}
      />
    </div>
  );
};

OrderInformationCard.propTypes = {
  order: PropTypes.object,
  pdaContract: PropTypes.object,
  loading: PropTypes.bool,
  isAdministrativePDA: PropTypes.bool,
};

export default OrderInformationCard;
