import RequestRepository from 'Infrastructure/Repositories/RequestRepository';

class SystemUpdateService {
  getLastSystemUpdate = async () => {
    try {
      const urlPath = 'updateDate';
      const { data } = await RequestRepository.get(urlPath);
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getLastAverageInvoiceTimeUpdate = async () => {
    try {
      const urlPath = 'averageInvoiceTime/updateDate';
      const { data } = await RequestRepository.get(urlPath);
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getLastParametersStepsUpdate = async () => {
    try {
      const urlPath = 'modelsversions/steps/updateDate';
      const { data } = await RequestRepository.get(urlPath);
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getLastParametersModelsVersionsUpdate = async () => {
    try {
      const urlPath = 'modelsversions/parameters/updateDate';
      const { data } = await RequestRepository.get(urlPath);
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getLastForecastTransitTimeUpdate = async () => {
    try {
      const urlPath = 'parameters/regionsWithCities/updateDate';
      const { data } = await RequestRepository.get(urlPath);
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getLastVisionConfigurationUpdate = async () => {
    try {
      const urlPath = 'visionconfigurations/updateDate';
      const { data } = await RequestRepository.get(urlPath);
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default SystemUpdateService;
