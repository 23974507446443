import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BaseModal from 'Components/Atoms/BaseModal/BaseModal';
import langValues from 'Config/i18n/index';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';
import { ReactComponent as StellantisLogo } from 'Common/Assets/logo_stellantis.svg';
import { Checkbox } from 'Components/Atoms/DefaultCheckbox/Checkbox';
import './MacroAreaUser.scss';

const INITIAL_MACRO_AREA = [
  {
    checkboxState: false,
    label: 'GESTÃO DE PEDIDOS',
    value: 'orderManagement',
  },
  {
    checkboxState: false,
    label: 'SALES OPERATION',
    value: 'salesOperation',
  },
  {
    checkboxState: false,
    label: 'DISTRIBUIÇÃO DE VEICULOS',
    value: 'salesDistribution',
  },
  {
    checkboxState: false,
    label: 'DEALER',
    value: 'dealer',
  },
];

const MacroAreaUserModal = ({
  isModalOpen, onClose, putMacroAreas, getMacroAreas,
}) => {
  const [macroAreas, setMacroAreas] = useState([...INITIAL_MACRO_AREA]);

  const alterCheckboxState = (macroAreaId) => {
    const newMacroAreas = [...macroAreas];
    const filteredMacroAreas = newMacroAreas.find(item => item.value === macroAreaId);

    filteredMacroAreas.checkboxState = !filteredMacroAreas.checkboxState;

    setMacroAreas(newMacroAreas);
  };

  const fetchMacroAreas = async () => {
    const stateMacroAreas = await getMacroAreas(macroAreas);
    const newMacroAreas = INITIAL_MACRO_AREA.reduce((acc, cur) => {
      const macroAreaItem = { ...cur };
      if (stateMacroAreas.includes(macroAreaItem.value)) {
        macroAreaItem.checkboxState = true;
      }
      acc.push(macroAreaItem);
      return acc;
    }, []);

    setMacroAreas(newMacroAreas);
  };

  useEffect(() => {
    if (isModalOpen) fetchMacroAreas();
  }, [isModalOpen]);

  const onConfirmButtonClick = () => {
    const macroAreasList = macroAreas.reduce((acc, cur) => {
      if (cur.checkboxState) acc.push(cur.value);
      return acc;
    }, []);
    putMacroAreas(macroAreasList);
    onClose();
  };

  const onDeclineButtonClick = () => {
    putMacroAreas([]);
    onClose();
  };

  const renderBackgroundStellantis = () => (
    <div className="background">
      <div className="textComponent">
        <div className="title-box">
          <div className="fcaIconEasy">
            <StellantisLogo className="logo-stellantis" />
            <div className="divisao-logo" />
            <div className="easy-tracking-text">{langValues.macroAreaUser.easyTracking}</div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <BaseModal open={isModalOpen} onClose={onClose}>
      <div className="macroAreaUser-modal">
        {renderBackgroundStellantis()}
        <div className="macroAreaUser-modal-content">
          <h5 className="title-modal">{langValues.macroAreaUser.title}</h5>
          <div className="text-modal">{langValues.macroAreaUser.infoText()}</div>
          <div className="macroAreaUser-items">
            {macroAreas.map(item => (
              <div className="checkbox-macroArea" key={item.value}>
                <Checkbox
                  classes={{ colorSecondary: 'checkbox-item' }}
                  checked={item.checkboxState}
                  onChange={() => alterCheckboxState(item.value)}
                  disableRipple
                />
                <span className="checkbox-description font-initials">{item.label}</span>
              </div>))}
          </div>
          <footer className="macroAreaUser-buttons">
            <button type="button" className="macroAreaUser-buttons-decline" onClick={onDeclineButtonClick}>
              {langValues.macroAreaUser.noAlert}
            </button>
            <MaterialButton
              type="primary"
              buttonText={langValues.macroAreaUser.confirm}
              variant="contained"
              onClick={onConfirmButtonClick}
            />
          </footer>
        </div>
      </div>
    </BaseModal>
  );
};

MacroAreaUserModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  putMacroAreas: PropTypes.func.isRequired,
  getMacroAreas: PropTypes.func.isRequired,
};

export default MacroAreaUserModal;
