import React from 'react';

import { withStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';

import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from 'Common/Assets/closeIcon.svg';

import './TextFieldInput.scss';
import colorVariables from 'Utils/Styles/colorVariables';

const styles = (theme) => ({
  root: {},
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
});

function TextFieldInput({ onChange = (content) => {}, label, savedTextContent = '', ...props }) {
  const handleChange = (textContent) => {
    onChange(textContent);
  };
  const { style } = props;
  const defaultStyle = {
    width: '100%',
    color: '#758CA5',
    fontSize: '14px',
  };

  const stylesInput = style ? { ...style, ...defaultStyle } : defaultStyle;

  return (
    <div className="search-component">
      <TextField
        variant="standard"
        style={{
          width: '292px',
          textAlign: 'center',
          margin: '0px',
        }}
        InputProps={{
          style: stylesInput,
          endAdornment: (
            <div className="searchInput-endAdornment">
              {savedTextContent && savedTextContent.length > 0 ? (
                <>
                  <CloseIcon
                    fill={colorVariables.textPrimaryColor}
                    cursor="pointer"
                    onClick={() => {
                      handleChange('');
                    }}
                  />
                </>
              ) : null}
            </div>
          ),
        }}
        placeholder={label}
        margin="normal"
        value={savedTextContent}
        onChange={(event) => {
          handleChange(event.target.value);
        }}
        {...props}
      />
    </div>
  );
}

TextFieldInput.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  savedTextContent: PropTypes.string,
  style: PropTypes.object,
};

export default withStyles(TextFieldInput, styles);
