import ModelVersionRepository from 'Infrastructure/Repositories/ModelVersionRepository';

class ModelVersionService {
  constructor(modelVersionRepository = new ModelVersionRepository()) {
    this.modelVersionRepository = modelVersionRepository;
  }

  async getModels() {
    try {
      const { data } = await this.modelVersionRepository.getModels();
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getModelsVersions() {
    try {
      const { data } = await this.modelVersionRepository.getModelsVersions();
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getAverageInvoiceTime() {
    try {
      const { data } = await this.modelVersionRepository.getAverageInvoiceTime();
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getAverageInvoiceTimeDownload() {
    try {
      await this.modelVersionRepository.getAverageInvoiceTimeDownload();
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async uploadAverageInvoiceTime(file) {
    try {
      await this.modelVersionRepository.uploadInvoiceTime(file);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default ModelVersionService;
