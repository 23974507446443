import RequestRepository from '../Repositories/RequestRepository';

class FeaturesService {
  getFeatures = async () => {
    try {
      const urlPath = 'features';
      const { data } = await RequestRepository.get(urlPath);
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
}

export default FeaturesService;
