import React from 'react';
import PropTypes from 'prop-types';

function multiplyByHundred(value) {
  if (typeof value === 'number') {
    return value * 100;
  }
  return value;
}

function PercentField({ value = 0 }) {
  let percent = value;
  if (typeof value === 'number') {
    percent = multiplyByHundred(value).toFixed(2);
  }
  return <span className="percentField">{`${percent}%`}</span>;
}

PercentField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PercentField;
