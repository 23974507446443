import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from 'Utils/analytics';
import { trackedEvents, trackedButtons } from 'Utils/consts';
import { Modal, FormLabel, FormControlLabel, RadioGroup } from '@mui/material';
import langValues from 'Config/i18n/index';
import { Backdrop, Typography, Radio, Button } from './styles';
import ResponseModal from '../ResponseModal';
import './FeedbackModal.scss';

const npsAvaliations = ['1', '2', '3', '4', '5'];

const FeedbackModal = ({ isFeedbackModalOpened, setFeedbackModalOpened, setResponseModalOpened, saveFeedback }) => {
  const [npsAvaliation, setNpsAvaliation] = useState(undefined);
  const [description, setDescription] = useState('');

  const renderNpsRadioButtons = () =>
    npsAvaliations.map((value) => (
      <FormControlLabel
        key={value}
        labelPlacement="bottom"
        className="radioLabel"
        value={value.toString()}
        control={<Radio color="primary" />}
        label={<Typography>{value.toString()}</Typography>}
      />
    ));

  const renderNpsField = () => (
    <div className="feedbackModal__nps">
      <FormLabel component="legend" className="fieldLabel">
        {langValues.feedbackLabels.aboutEasyTracking}
      </FormLabel>
      <RadioGroup
        className="feedbackModal__radioGroup"
        row
        value={npsAvaliation ? npsAvaliation.toString() : undefined}
        onChange={(event) => setNpsAvaliation(parseInt(event.target.value, 10))}
      >
        {renderNpsRadioButtons()}
        <div className="avaliationLegend">
          <FormLabel component="legend" className="avaliationLabel labelLeft">
            {langValues.feedbackLabels.nps.bad}
          </FormLabel>
          <FormLabel component="legend" className="avaliationLabel labelRight">
            {langValues.feedbackLabels.nps.veryGood}
          </FormLabel>
        </div>
      </RadioGroup>
    </div>
  );

  const renderDescription = () => (
    <div className="feedbackModal__description">
      <FormLabel component="legend" className="fieldLabel">
        {langValues.feedbackLabels.comment}
      </FormLabel>
      <textarea
        maxLength={3000}
        className="feedbackModal__textarea"
        value={description}
        onChange={(event) => setDescription(event.target.value)}
      />
    </div>
  );

  const renderWarning = () => (
    <div className="feedbackModal__warning">
      <span>{langValues.feedbackLabels.warning}</span>
    </div>
  );

  const clearAllFields = () => {
    setNpsAvaliation(undefined);
    setDescription('');
  };

  const closeFeedbackModal = () => {
    setFeedbackModalOpened(false);
    clearAllFields();

    trackEvent(trackedEvents.buttonClick, { button: trackedButtons.cancelFeedback });
  };

  const onSendFeedbackClick = () => {
    const feedback = {
      npsAvaliation,
      description,
    };
    saveFeedback(feedback);
    setFeedbackModalOpened(false);
    setResponseModalOpened(true);

    trackEvent(trackedEvents.givenFeedback, {
      rating: npsAvaliation,
      description,
    });
  };

  const renderButtons = () => (
    <>
      <Button key="cancel" className="feedbackModal__cancel" onClick={() => closeFeedbackModal()}>
        Cancelar
      </Button>
      <Button key="sendFeedback" className="feedbackModal__send" onClick={() => onSendFeedbackClick()}>
        {langValues.feedbackLabels.sendFeedback}
      </Button>
    </>
  );

  return (
    <>
      <Modal
        BackdropComponent={Backdrop}
        open={isFeedbackModalOpened}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            closeFeedbackModal();
          }
        }}
        style={{ display: 'flex' }}
      >
        <div id="feedbackModal">
          <header>
            <h1>{langValues.feedbackLabels.title}</h1>
            <p className="feedbackModal__intro">{langValues.feedbackLabels.subtitle}</p>
          </header>
          <div className="feedbackModal__body">
            {renderNpsField()}
            {renderDescription()}
            {renderWarning()}
          </div>
          <div className="feedbackModal__footer">{renderButtons()}</div>
        </div>
      </Modal>
      <ResponseModal clearFeedbackFields={clearAllFields} />
    </>
  );
};

FeedbackModal.propTypes = {
  isFeedbackModalOpened: PropTypes.bool.isRequired,
  setFeedbackModalOpened: PropTypes.func.isRequired,
  setResponseModalOpened: PropTypes.func.isRequired,
  feedbackCategories: PropTypes.array.isRequired,
  saveFeedback: PropTypes.func.isRequired,
};

export default FeedbackModal;
