import ListPedidosIndustriaisService from '../Services/ListPedidosIndustriaisService';
import ListPedidosService from '../Services/ListPedidosService';

export const DUCK_NAME = 'orderDetailPda';

export const INITIAL_STATE = () => ({
  loading: false,
  success: false,
  error: null,
  total: 0,
  pda: null,
});

export const LOAD_ORDER_BY_ID_STARTED = `${DUCK_NAME}/LOAD_ORDER_BY_ID_STARTED`;
export const LOAD_ORDER_BY_ID_SUCCEED = `${DUCK_NAME}/LOAD_ORDER_BY_ID_SUCCEED`;
export const LOAD_ORDER_BY_ID_FAILED = `${DUCK_NAME}/LOAD_ORDER_BY_ID_FAILED`;


export const loadOrderByIdStarted = () => ({ type: LOAD_ORDER_BY_ID_STARTED });
export const loadOrderByIdSucceed = data => ({ type: LOAD_ORDER_BY_ID_SUCCEED, data });

export const loadOrderByIdFailed = error => ({ type: LOAD_ORDER_BY_ID_FAILED, error });

export const CLEAR_DUCK = `${DUCK_NAME}/CLEAR_DUCK`;
export const clearDuck = () => ({ type: CLEAR_DUCK });

export const loadOrderById = (params, callback) => async (dispatch) => {
  dispatch(loadOrderByIdStarted());
  try {
    const listService = new ListPedidosIndustriaisService();
    const data = await listService.getPda(params);

    dispatch(loadOrderByIdSucceed(data));
  } catch (err) {
    dispatch(loadOrderByIdFailed(err));
  }
};


const reducer = (state = INITIAL_STATE(), action) => {
  switch (action.type) {
    case LOAD_ORDER_BY_ID_SUCCEED:
      return {
        ...state,
        loading: false,
        success: true,
        pda: action.data,
        error: null,
      };
    case LOAD_ORDER_BY_ID_STARTED:
      return {
        ...state,
        pda: null,
        loading: true,
      };
    case LOAD_ORDER_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case CLEAR_DUCK:
      return {
        ...INITIAL_STATE(),
      };
    default:
      return state;
  }
};

export default reducer;
