import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import langValues from 'Config/i18n/index';
import { fileTypes, trackedEvents, trackedButtons } from 'Utils/consts';
import { trackEvent } from 'Utils/analytics';
import { withStyles } from 'tss-react/mui';
import { ReactComponent as ArrowUpIcon } from 'Common/Assets/arrow_up.svg';
import ExportOptionsPDA from 'Components/Molecules/ExportOptionsPDA';

const ExpButtom = withStyles(Button, {
  root: {
    height: '34px',
    width: '112px',
    borderRadius: '3px',
    boxSizing: 'border-box',
    border: '1px solid rgba(117,140,165,0.5)',
    backgroundColor: '#FFFFFF',
  },
});

const ExportMenuItem = withStyles(MenuItem, {
  root: {
    cursor: 'pointer',
    border: 'none',
    fontFamily: 'Helvetica Neue',
    fontSize: '14px',
    color: '#5E5E5E',
    padding: '11px 16px',
    '&:hover': {
      backgroundColor: 'rgba(93, 127, 200, 0.1)',
    },
  },
});

const ExportButton = ({ handleDownloadReport, hasOneFileType, isTabPDA }) => {
  const [shouldRenderExportDropdown, setShouldRenderExportDropdown] = useState(false);
  const [exportAnchorEl, setExportAnchorEl] = useState();

  const onExportButtonClick = (event) => {
    if (event.key === 'Tab') {
      return;
    }
    setShouldRenderExportDropdown(!shouldRenderExportDropdown);
    setExportAnchorEl(!shouldRenderExportDropdown ? event.currentTarget : null);
  };

  const handleExportButtonClick = (e) => {
    if (hasOneFileType) {
      handleDownloadReport(fileTypes.csv);
    } else {
      trackEvent(trackedEvents.buttonClick, { button: trackedButtons.export });
      onExportButtonClick(e);
    }
  };

  const onMenuItemClick = (fileType) => {
    setShouldRenderExportDropdown(!shouldRenderExportDropdown);
    handleDownloadReport(fileType);
  };

  const exportCallbackPDA = (type) => {
    onMenuItemClick(fileTypes[type]);
  };

  return (
    <>
      <div className="export-buttom">
        <ExpButtom type="button" onClick={(e) => handleExportButtonClick(e)}>
          <div className="inner-button">
            <ArrowUpIcon className="arrow-up-icon" />
            <span className="exportar">{langValues.export}</span>
          </div>
        </ExpButtom>
      </div>
      <Menu
        onClose={(e) => {
          onExportButtonClick(e);
        }}
        open={shouldRenderExportDropdown}
        anchorEl={exportAnchorEl}
        keepMounted
        //getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {isTabPDA ? (
          <ExportOptionsPDA exportCallbackPDA={exportCallbackPDA} />
        ) : (
          <>
            <ExportMenuItem
              onClick={() => {
                onMenuItemClick(fileTypes.xlsx);
              }}
            >
              {' '}
              {langValues.exportAsExcel}
            </ExportMenuItem>
            <ExportMenuItem
              onClick={() => {
                onMenuItemClick(fileTypes.csv);
              }}
            >
              {' '}
              {langValues.exportAsCsv}
            </ExportMenuItem>
          </>
        )}
      </Menu>
    </>
  );
};

ExportButton.propTypes = {
  handleDownloadReport: PropTypes.func.isRequired,
  hasOneFileType: PropTypes.bool,
  isTabPDA: PropTypes.bool,
};

export default ExportButton;
