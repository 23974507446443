import { connect } from 'react-redux';

import {
  loadResume, clearList,
} from 'Infrastructure/Ducks/ListPedidos';
import {
  loadMacroAreaHistory,
} from 'Infrastructure/Ducks/Dashboard';
import { loadModels } from 'Infrastructure/Ducks/ModelVersion';
import { clearFilters } from 'Infrastructure/Ducks/FiltersDuck';
import DashboardOrders from './DashboardOrders';

const mapStateToProps = (state) => {
  const {
    list, dashboard, modelVersion, filtersDuck, macroAreaHistory,
  } = state;
  return {
    list, dashboard, models: modelVersion.models, filtersDuck, macroAreaHistory,
  };
};

const mapDispatchToProps = dispatch => ({
  loadResume: (searchString, selected, filterDates) => {
    dispatch(loadResume(searchString, selected, filterDates));
  },
  clearList: () => {
    dispatch(clearList());
  },
  loadModels: () => {
    dispatch(loadModels());
  },
  loadMacroAreaHistory: (frequency) => {
    dispatch(loadMacroAreaHistory(frequency));
  },
  clearFilters: () => {
    dispatch(clearFilters());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardOrders);
