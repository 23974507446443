import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Expansion from 'Components/Atoms/Expansion/Expansion';
import { safeToCapitalizeAllFirstLetters, safeToLowerCase, safeToUpperCase } from 'Utils/safeMethods';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import colorVariables from 'Utils/Styles/colorVariables';
import langValues from 'Config/i18n/index';
import { User } from 'Common/Helpers/AuthHelper';
import { roleConsts, environment } from 'Utils/consts';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import './ListParametersModelsVersions.scss';

const ListParametersModelsVersions = ({
  listItems,
  activeModel,
  setActiveModel,
  handleAddNewVersion,
  handleEditVersion,
  handleEditModel,
  handleDeleteVersion,
  handleDeleteModel,
}) =>
  listItems.map((item) => {
    const renderArrowIcon = (name, isSelected, setSelected, shouldRender) => {
      if (shouldRender) {
        return <Expansion name={name} isSelected={isSelected} setSelected={setSelected} />;
      }
      return null;
    };

    const renderArrow = () => {
      const isSelected = item.code === activeModel;
      return (
        <td className="font-subtitle2">{renderArrowIcon(item.code, isSelected, setActiveModel, item.hasChildren)}</td>
      );
    };

    const renderName = () => (
      <td className="font-subtitle2 item-bolder parameters-steps-version-padding">
        {item.parentCode ? safeToUpperCase(item.name) : safeToCapitalizeAllFirstLetters(item.name)}
      </td>
    );

    const renderCode = () => <td className="font-subtitle2 item-bolder">{item.code}</td>;

    const renderOrigin = () => (
      <td className="font-subtitle2 item-bolder origin-text">{safeToLowerCase(item.origin)}</td>
    );

    const renderBrand = () => <td className="font-subtitle2 item-bolder origin-text">{item.brand}</td>;

    const renderAddVersionButton = () => {
      if (item.parentCode) return null;
      const contentClassName = ENV_ENVIRONMENT === environment.AR ? '' : '';

      const buttonContent = (
        <span className={`parametersModelsVersions-addButton ${contentClassName}`}>
          <AddIcon />
          <p className="font-small3">{langValues.settingsModelsVersions.newVersion}</p>
        </span>
      );

      return (
        <MaterialButton
          onClick={() => handleAddNewVersion(item)}
          buttonText={buttonContent}
          variant="outlined"
          borderColor={colorVariables.actionPrimaryColor}
          // disabled={ENV_ENVIRONMENT === environment.AR}
          type="secondary"
        />
      );
    };

    const renderButtons = () => {
      if (!User.get().userProfile.includes(roleConsts.admin)) return <td />;

      const shouldEditOrDeleteVersion = ENV_ENVIRONMENT === environment.BR;
      const buttonsClassName = ENV_ENVIRONMENT === environment.AR && item.parentCode ? 'disabled' : '';

      const handleEdit = () => {
        if (item.parentCode) {
          if (shouldEditOrDeleteVersion) {
            handleEditVersion(item);
          }
          return;
        }
        handleEditModel(item);
      };

      const handleDelete = () => {
        if (item.parentCode) {
          if (shouldEditOrDeleteVersion) {
            handleDeleteVersion(item);
          }
          return;
        }
        handleDeleteModel(item);
      };

      return (
        <td className={`font-subtitle2 parametersModelsVersions-listItem-buttons ${buttonsClassName}`}>
          {renderAddVersionButton()}
          <EditIcon onClick={() => handleEdit()} />
          <DeleteIcon onClick={() => handleDelete()} />
        </td>
      );
    };

    const childClass = item.parentCode ? 'item-child' : '';

    return (
      <Fragment key={item.code}>
        <tr className={`parametersModelsVersions-listItem ${childClass}`}>
          {renderArrow()}
          {renderName()}
          {renderCode()}
          {renderOrigin()}
          {renderBrand()}
          {renderButtons()}
        </tr>
      </Fragment>
    );
  });

ListParametersModelsVersions.propTypes = {
  listItems: PropTypes.array.isRequired,
  activeModel: PropTypes.string,
  setActiveModel: PropTypes.func.isRequired,
  handleAddNewVersion: PropTypes.func.isRequired,
  handleEditVersion: PropTypes.func.isRequired,
  handleEditModel: PropTypes.func.isRequired,
  handleDeleteVersion: PropTypes.func.isRequired,
  handleDeleteModel: PropTypes.func.isRequired,
};

export default ListParametersModelsVersions;
