import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import List from 'Components/Shared/List';
import PageHeader from 'Components/Shared/PageHeader';
import TransitTimeList from 'Components/TransitTimeListComponents/TransitTimeList';

import { ReactComponent as EditIcon } from 'Common/Assets/edit_paper.svg';
import { ReactComponent as CloseIcon } from 'Common/Assets/close-cross.svg';

import Modal from '@mui/material/Modal';
import { User } from 'Common/Helpers/AuthHelper';
import './TransitTime.scss';
import { trackedEvents, trackedPages, trackedButtons, roleConsts } from 'Utils/consts';
import { trackEvent } from 'Utils/analytics';
import langValues from 'Config/i18n/index';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';

const styles = () => ({
  root: {
    height: '32px',
    width: 'fit-content',
    borderRadius: '3px',
    backgroundColor: '#657DD9',
  },
  disabled: {
    backgroundColor: '#BABEC9',
  },
});
class TransitTime extends Component {
  constructor(props) {
    super(props);

    trackEvent(trackedEvents.pageView, { page: trackedPages.transitTime });

    this.state = {
      data: {},
      toSave: {},
      editing: false,
      openModal: false,
      openCancelModal: false,
    };
  }

  componentDidMount = () => {
    const { loadList } = this.props;
    loadList();
    this.configureState();
  };

  configureState = () => {
    const { listRegion } = this.props;

    this.setState({
      data: { ...listRegion.data },
      toSave: {},
    });
  };

  componentDidUpdate = (previousProps) => {
    if (previousProps !== this.props) {
      this.configureState();
    }
  };

  getDestinations = () => {
    const destinations = {};
    langValues.labelsTransitTime.keys.forEach((destinationKey, index) => {
      destinations[destinationKey] = langValues.labelsTransitTime.destinations[index];
    });
    return destinations;
  };

  saveRegionData = () => {
    const { toSave } = this.state;
    const { saveData } = this.props;
    let mappedData = [];
    const destinations = this.getDestinations();
    Object.keys(toSave).map((item) => {
      const regionParameters = Object.keys(destinations).map((key) => {
        const destination = destinations[key];
        return {
          origin: destination.toUpperCase(),
          dealerRef: Number(toSave[item][key]),
        };
      });
      const requestItem = {
        destiny: toSave[item].destiny,
        regionParameters,
      };
      mappedData = [...mappedData, requestItem];
      return toSave[item];
    });
    saveData(mappedData);
    this.setState({ editing: false, openModal: false });

    trackEvent(trackedEvents.buttonClick, { button: trackedButtons.saveTransitTime });
  };

  onSave = (data) => {
    this.setState(data);
  };

  saveChangesHandleClick = () => {
    const { editing } = this.state;
    if (editing) {
      this.setState({
        openModal: true,
      });
    } else {
      this.setState({ editing: true });
      trackEvent(trackedEvents.buttonClick, { button: trackedButtons.editTransitTime });
    }
  };

  cancelChangesHandleClick = () => {
    this.setState({
      openCancelModal: true,
    });
  };

  cancelEdit = () => {
    const { listRegion } = this.props;
    this.setState({
      data: { ...listRegion.data },
      editing: false,
      openModal: false,
      openCancelModal: false,
    });
  };

  openCancelModal = () => {
    this.setState({
      openCancelModal: true,
    });
  };

  renderCancelModal = (openModal, classes) => (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={openModal}
      onClose={this.handleCloseModal}
      style={{ display: 'flex' }}
    >
      <div id="transitTimeModal">
        <div id="transitTime-closeModal">
          <CloseIcon onClick={this.handleCloseModal} />
        </div>
        <div id="transitTime-modalMessage">
          <span id="transitTime-lightMessage">{langValues.transitTimeLabels.cancelModalMessageLight}</span>
          <span id="transitTime-boldMessage">{langValues.transitTimeLabels.cancelModalMessageBold}</span>
        </div>
        <div id="transitTime-modalButtons">
          <div id="transitTime-modalButtonsWrapper">
            <div id="transitTime-cancelButton">
              <Button
                className="edit-button"
                style={{
                  height: '32px',
                  border: '1px solid rgba(117,140,165,0.5)',
                  borderRadius: '3px',
                  backgroundColor: '#FFFFFF',
                  marginRight: '8px',
                }}
                onClick={this.handleCloseModal}
              >
                <p className="cancel-listRegion">{langValues.no}</p>
              </Button>
            </div>
            <div id="transitTime-saveButton">
              <Button classes={{ root: classes.root, disabled: classes.disabled }} onClick={this.cancelEdit}>
                <p className="saveChanges-listRegion">{langValues.yes}</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

  renderSaveModal = (openModal, classes) => (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={openModal}
      onClose={this.handleCloseModal}
      id="saveModalTransitTime"
      style={{ display: 'flex' }}
    >
      <div id="transitTimeModal">
        <div id="transitTime-closeModal">
          <CloseIcon onClick={this.handleCloseModal} />
        </div>
        <div id="transitTime-modalMessage">
          <span id="transitTime-lightMessage">{langValues.transitTimeLabels.modalMessageLight}</span>
          <span id="transitTime-boldMessage">{langValues.transitTimeLabels.modalMessageBold}</span>
        </div>
        <div id="transitTime-modalButtons">
          <div id="transitTime-modalButtonsWrapper">
            <div id="transitTime-cancelButton">
              <Button
                className="edit-button"
                style={{
                  height: '32px',
                  border: '1px solid rgba(117,140,165,0.5)',
                  borderRadius: '3px',
                  backgroundColor: '#FFFFFF',
                  marginRight: '8px',
                }}
                onClick={this.cancelEdit}
              >
                <p className="cancel-listRegion">{langValues.transitTimeLabels.cancel}</p>
              </Button>
            </div>
            <div id="transitTime-saveButton">
              <Button classes={{ root: classes.root, disabled: classes.disabled }} onClick={this.saveRegionData}>
                <p className="saveChanges-listRegion">{langValues.transitTimeLabels.saveModal}</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

  handleChange = (id, item) => {
    const { data, toSave } = this.state;
    data[id] = item;
    toSave[id] = item;
    this.setState({ ...data, ...toSave });
  };

  renderButtonDiv = () => {
    const { toSave } = this.state;
    const edited = Object.keys(toSave).length > 0;
    const { editing } = this.state;
    return editing ? this.renderSaveButtonDiv(edited) : this.renderEditButtonDiv();
  };

  handleCloseModal = () => {
    this.setState({
      openModal: false,
      openCancelModal: false,
    });
  };

  renderSaveButtonDiv = (edited) => {
    const { classes } = this.props;
    return (
      <div className="settingsRegion-buttonDiv">
        <div className="settingsRegion-buttonWrapper">
          <Button
            className="edit-button"
            style={{
              height: '32px',
              border: '1px solid rgba(117,140,165,0.5)',
              borderRadius: '3px',
              backgroundColor: '#FFFFFF',
              marginRight: '8px',
            }}
            onClick={this.openCancelModal}
          >
            <p className="cancel-listRegion">{langValues.transitTimeLabels.cancel}</p>
          </Button>
          <Button
            id="saveButtonTransitTime"
            classes={{ root: classes.root, disabled: classes.disabled }}
            disabled={!edited}
            onClick={this.saveChangesHandleClick}
          >
            <p className="saveChanges-listRegion">{langValues.transitTimeLabels.save}</p>
          </Button>
        </div>
      </div>
    );
  };

  renderEditButtonDiv = () => {
    if (User.get().userProfile.includes(roleConsts.admin)) {
      return (
        <div className="settingsRegion-buttonDiv">
          <div className="settingsRegion-buttonWrapper">
            <Button
              className="edit-button"
              style={{
                height: '32px',
                width: '101px',
                border: '1px solid rgba(117,140,165,0.5)',
                borderRadius: '3px',
                backgroundColor: '#FFFFFF',
                padding: '0px 8px',
              }}
              onClick={this.saveChangesHandleClick}
            >
              <p className="edit-listRegion">{langValues.transitTimeLabels.edit}</p>
              <div className="edit-icon-div">
                <EditIcon />
              </div>
            </Button>
          </div>
        </div>
      );
    }
    return null;
  };

  renderContent = (errorInserting, errorLoading, loading, editing) => {
    const { data } = this.state;
    return (
      <div className={`content transitTime ${ENV_ENVIRONMENT}`}>
        <List
          childProps={{
            data: { ...data },
            errorInserting,
            errorLoading,
            loading,
            editing,
            onClickItem: this.onSave,
            onChange: this.handleChange,
          }}
          header={[langValues.labelsTransitTime.destinyTitle, ...langValues.labelsTransitTime.destinations]}
          Type={TransitTimeList}
        />
      </div>
    );
  };

  render() {
    const { listRegion, classes } = this.props;
    const { editing, openModal, openCancelModal } = this.state;
    const { errorInserting, errorLoading, loading } = listRegion;
    return (
      <div className="transitTime">
        <PageHeader
          title={
            <div className="settingsTitle">
              {langValues.configurations} <div className="titleDot" /> {langValues.settingsTransitTimeScreen.title}
            </div>
          }
          RightComponent={() => this.renderButtonDiv()}
        />
        {this.renderContent(errorInserting, errorLoading, loading, editing)}
        {this.renderCancelModal(openCancelModal, classes)}
        {this.renderSaveModal(openModal, classes)}
      </div>
    );
  }
}

TransitTime.propTypes = {
  loadList: PropTypes.func.isRequired,
  listRegion: PropTypes.object.isRequired,
  saveData: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(TransitTime, styles);
