function hasSome(expected, result) {
  let areEquals = true;
  expected.forEach((item) => {
    const hasMatch = result.some(element => element === item);
    if (!hasMatch) {
      areEquals = false;
    }
  });
  return areEquals;
}

export function testEquals(expected, result) {
  if (expected.length !== result.length) {
    return false;
  }
  if (!hasSome(expected, result)) {
    return false;
  }
  if (!hasSome(result, expected)) {
    return false;
  }
  return true;
}

export default null;
