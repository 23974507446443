import { connect } from 'react-redux';
import { loadVisionConfigurationUpdate } from 'Infrastructure/Ducks/SystemUpdateDuck';
import UpdateDate from './UpdateDate';

const mapStateToProps = (state) => {
  const { lastVisionConfigurationUpdate } = state.systemUpdate;
  return { lastUpdateDate: lastVisionConfigurationUpdate };
};

const mapDispatchToProps = dispatch => ({
  loadLastUpdateDate: () => {
    dispatch(loadVisionConfigurationUpdate());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateDate);
