import { connect } from 'react-redux';
import {
  loadRules,
  subMenuList,
  setSubMenuOpen,
  setCurrentActiveSubMenu,
} from 'Infrastructure/Ducks/VisionConfigurationDuck';
import VisionConfigurationSubMenu from './VisionConfigurationSubMenu';

const mapStateToProps = (state) => {
  const { isSubMenuOpen, currentActiveSubMenu } = state.visionConfigurationDuck;
  return {
    isSubMenuOpen,
    subMenuList,
    currentActiveSubMenu: currentActiveSubMenu || 0,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadRules: (role) => {
    dispatch(loadRules(role));
  },
  setSubMenuOpen: (status) => {
    dispatch(setSubMenuOpen(status));
  },
  setCurrentActiveSubMenu: (current) => {
    dispatch(setCurrentActiveSubMenu(current));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VisionConfigurationSubMenu);
