import { connect } from 'react-redux';
import { loadOrderById, clearDuck } from 'Infrastructure/Ducks/OrderDetailDuck';

import OrderDetail from './OrderDetail';

const mapStateToProps = (state) => {
  const { orderDetail } = state;
  return {
    order: orderDetail.order,
    selectedIndustrialOrder: orderDetail.selectedIndustrialOrder,
    loading: orderDetail.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadOrderById: (orderId, callback) => {
    dispatch(loadOrderById(orderId, callback));
  },
  clearDuck: () => {
    dispatch(clearDuck());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
