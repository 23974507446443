import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SliderCarousel from 'Components/Shared/SliderCarousel/SliderCarousel';
import { ReactComponent as ArrowIcon } from 'Common/Assets/arrow.svg';
import colorVariables from 'Utils/Styles/colorVariables';

import './IndustrialOrdersSlider.scss';
import { maxNumberIndustrialOrdersInSlider, trackedEvents, trackedButtons } from 'Utils/consts';
import { trackEvent } from 'Utils/analytics';

const Arrow = (properties) => {
  const {
    className, style, onClick, rotate,
  } = properties;
  return (
    <ArrowIcon
      className={className}
      style={{
        ...style,
        fill: (onClick ? colorVariables.actionPrimaryColor
          : colorVariables.inactivePrimaryStateColor),
        cursor: (onClick ? 'pointer' : 'default'),
        transform: (rotate ? 'rotate(180deg) translate(0, 50%)' : 'translate(0, -50%)'),
      }}
      onClick={() => {
        if (onClick) {
          onClick();
        }
        trackEvent(
          trackedEvents.buttonClick, { button: trackedButtons.carouselArrow },
        );
      }}
    />
  );
};

const IndustrialOrdersSlider = ({ industrialCards, currentActiveTab }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, setSliderRef] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      if (sliderRef) {
        try {
          sliderRef.slickGoTo(0, false);
        } catch {
          setSliderRef(null);
        }
      }
    }, 1000);
  }, [currentActiveTab]);

  const settings = {
    dots: industrialCards.length <= maxNumberIndustrialOrdersInSlider,
    infinite: false,
    draggable: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <Arrow />,
    prevArrow: <Arrow rotate />,
    beforeChange: (prev, next) => setCurrentSlide(next / 4),
    appendDots: dots => (
      <div style={{ position: 'relative', bottom: '0' }}>
        <ul className="industrialOrdersSlider-bulletList"> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div style={{
        borderRadius: '50%',
        border: 'none',
        width: '8px',
        height: '8px',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        color: colorVariables.actionSecondaryColor,
        backgroundColor: (currentSlide === i
          ? colorVariables.actionPrimaryColor : colorVariables.inactivePrimaryStateColor),
      }}
      />
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <SliderCarousel
      setSliderRef={setSliderRef}
      settings={settings}
    >
      {industrialCards}
    </SliderCarousel>
  );
};

IndustrialOrdersSlider.propTypes = {
  industrialCards: PropTypes.array.isRequired,
  currentActiveTab: PropTypes.string.isRequired,
};

export default IndustrialOrdersSlider;
