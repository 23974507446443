/* eslint-disable class-methods-use-this */
import RequestRepository from './RequestRepository';

class ModelVersionRepository {
  getModelsVersions() {
    return RequestRepository.get('modelsversions');
  }

  getModels() {
    return RequestRepository.get('models');
  }

  getAverageInvoiceTime() {
    return RequestRepository.get('modelsversions/averageInvoiceTime');
  }

  getAverageInvoiceTimeDownload() {
    return RequestRepository.exportOnlyCsv('modelsversions/averageInvoiceTime/report', 'Prazos Médios');
  }

  uploadInvoiceTime(file) {
    return RequestRepository.uploadFile('modelsversions/averageInvoiceTime', file);
  }
}

export default ModelVersionRepository;
