import React, { useState } from 'react';
import PropTypes from 'prop-types';
import langValues from 'Config/i18n/index';
import defaultCar from 'Common/Assets/imagemFallBack.png';
import ReactImageFallback from 'react-image-fallback';
import './CarDetailImage.scss';

const CarDetailImage = ({ industrialOrder }) => {
  const [errorLoadedMedia, setErrorLoadedMedia] = useState(false);

  return industrialOrder ? (
    <div className="imagem-boxMobile">
      <ReactImageFallback
        src={industrialOrder.imageUrl}
        fallbackImage={defaultCar}
        initialImage={defaultCar}
        onError={() => {
          setErrorLoadedMedia(true);
        }}
        alt={langValues.orderInformation.carName}
        className="car-image"
      />
      {errorLoadedMedia ? null : <span className="warning">{langValues.orderInformation.warning}</span>}
    </div>
  ) : null;
};

CarDetailImage.propTypes = {
  industrialOrder: PropTypes.object,
};

export default CarDetailImage;
