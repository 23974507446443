import React from 'react';
import PropTypes from 'prop-types';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { safeNormalizeString } from 'Utils/safeMethods';

import {
  Checkbox,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControlLabel,
} from 'Components/Atoms/FormControltems/styles';

import FilterLabel from '../FilterLabel';

import './FilterSection.scss';

const FilterSection = ({ panel, isExpanded, selected, setExpanded, selectAll, selectItem }) => {
  const isPartialCheck = selected.length > 0;
  const isFullyChecked = Object.keys(panel.panelItems).length === selected.length;
  const { panelKey } = panel;

  let icon = <CheckBoxOutlineBlankIcon className="checkbox-filter" fontSize="small" />;
  if (isPartialCheck) {
    icon = <IndeterminateCheckBoxIcon className="checkbox-filter" fontSize="small" />;
  }

  const renderOption = (key, label) => {
    const isChecked = selected.includes(key);

    return (
      <div key={`${panelKey}_${key}`} className="filterOption">
        <FormControlLabel
          control={
            <Checkbox
              className="checkbox-filter"
              checked={isChecked}
              onChange={() => selectItem(panelKey, key)}
              value={key}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              color="primary"
            />
          }
          label={<FilterLabel itemKey={key} label={label} panelKey={panelKey} />}
        />
      </div>
    );
  };

  const getPanelsOrdered = () => {
    const panels = Object.entries(panel.panelItems);
    if (panel.panelKey === 'models') {
      panels.sort((a, b) => (safeNormalizeString(a[1]) > safeNormalizeString(b[1]) ? 1 : -1));
    }
    return panels;
  };

  const renderFilterOptions = () => {
    const panels = getPanelsOrdered();
    return <ExpansionPanelDetails>{panels.map(([key, label]) => renderOption(key, label))}</ExpansionPanelDetails>;
  };

  return (
    <ExpansionPanel expanded={isExpanded}>
      <ExpansionPanelSummary onClick={() => setExpanded(panelKey)} expandIcon={<ExpandMoreIcon />}>
        <div className="sectionHeader">
          <div className="sectionTitle">
            <Checkbox
              className="checkbox-filter"
              color="primary"
              onClick={(e) => {
                selectAll(panelKey, Object.keys(panel.panelItems));
                e.stopPropagation();
              }}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              icon={icon}
              checked={isFullyChecked}
            />
            <div className="section-filter-title">
              <p>{panel.panelName}</p>
            </div>
          </div>
        </div>
      </ExpansionPanelSummary>
      {renderFilterOptions()}
    </ExpansionPanel>
  );
};

FilterSection.propTypes = {
  panel: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  selected: PropTypes.array.isRequired,
  setExpanded: PropTypes.func.isRequired,
  selectAll: PropTypes.func.isRequired,
  selectItem: PropTypes.func.isRequired,
};

export default FilterSection;
