import React from 'react';

import { withStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';

import PropTypes from 'prop-types';

import { ReactComponent as SearchIcon } from 'Common/Assets/search.svg';
import { ReactComponent as CloseIcon } from 'Common/Assets/closeIcon.svg';

import './SearchInput.scss';
import colorVariables from 'Utils/Styles/colorVariables';

import { trackedEvents, trackedButtons } from 'Utils/consts';
import { trackEvent } from 'Utils/analytics';

const styles = (theme) => ({
  root: {},
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
});

function handleChange(searchString, onChange) {
  onChange(searchString.replace('', ''), 'searchString');
}

const SearchInput = ({ onChange, searchString, search, placeholder, onClear }) => (
  <div className="search-component">
    <TextField
      variant="standard"
      style={{
        textAlign: 'center',
        margin: '0px',
      }}
      InputProps={{
        style: {
          width: '100%',
          color: '#758CA5',
          fontSize: '14px',
        },
        endAdornment: (
          <div className="searchInput-endAdornment">
            {searchString && searchString.length > 0 ? (
              <>
                <CloseIcon
                  fill={colorVariables.textPrimaryColor}
                  cursor="pointer"
                  onClick={async () => {
                    await handleChange('', onChange);
                    onClear();
                  }}
                />
                <div className="searchInput-separator" />
              </>
            ) : null}
            <SearchIcon
              cursor="pointer"
              onClick={() => {
                trackEvent(trackedEvents.buttonClick, {
                  button: trackedButtons.search,
                  textString: searchString,
                });
                search();
              }}
            />
          </div>
        ),
      }}
      placeholder={placeholder}
      margin="normal"
      value={searchString || ''}
      onChange={(event) => {
        handleChange(event.target.value, onChange);
      }}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          search();
        }
      }}
    />
  </div>
);

SearchInput.propTypes = {
  search: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  searchString: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
};

export default withStyles(SearchInput, styles);
