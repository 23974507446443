import MuiBackdrop from '@mui/material/Backdrop';
import { withStyles } from 'tss-react/mui';

export const Backdrop = withStyles(MuiBackdrop, {
  root: {
    backgroundColor: 'rgba(12,28,45,0.5)',
  },
});

export default null;
