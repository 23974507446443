/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import { ordersConsts, fileTypes } from 'Utils/consts';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import { User } from 'Common/Helpers/AuthHelper';
import RequestRepository from './RequestRepository';

class ListPedidosRepository {
  getList(page, searchString, selected, dates, isBilledFilter, size = ordersConsts.pageSize, isPlanAhorro, searchStringAhorro, ahorroFilters, getNewPdaEndpoint) {
    const method = User.canSeePDA() ? 'ordersPDA?' : 'orders?';
    const paginacao = (page >= 0) ? `page=${page}&size=${size}` : '';
    const fileType = null;
    const parameters = this.buildParametersString(
      searchString,
      selected,
      method,
      paginacao,
      dates,
      fileType,
      isBilledFilter,
      null,
      null,
      isPlanAhorro,
      searchStringAhorro,
      ahorroFilters,
    );

    return RequestRepository.get(parameters);
  }

  getResume(searchString, selected, dates, size = ordersConsts.pageSize) {
    const method = 'resume?';
    const paginacao = '';
    const parameters = this.buildParametersString(searchString, selected, method, paginacao, dates);

    return RequestRepository.get(parameters);
  }

  export(
    searchString,
    selected,
    dates,
    isBilledFilter,
    stepStatus,
    method,
    fileName,
    fileType,
    sorting,
    isPlanAhorro,
    searchStringAhorro,
    ahorroFilters,
    filtersExportPDA,
  ) {
    let config = {};
    if (fileType === fileTypes.csv) {
      config = {
        headers: {
          'Content-Disposition': `attachment; filename=${fileName}.csv;`,
          'Content-Type': 'text/csv',
        },
      };
    } else {
      config = {
        headers: {
          'Content-Disposition': `attachment; filename=${fileName}.xlsx;`,
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      };
    }

    const paginacao = '';
    const parameters = this.buildParametersString(
      searchString,
      selected,
      method,
      paginacao,
      dates,
      fileType,
      isBilledFilter,
      stepStatus,
      sorting,
      isPlanAhorro,
      searchStringAhorro,
      ahorroFilters,
      filtersExportPDA,
    );

    return RequestRepository.export(parameters, config, fileName, fileType);
  }

  getExportCountPda(filtersExportPDA) {
    const parameters = this.buildExportAhorroFilters(filtersExportPDA);

    return RequestRepository.get(`ordersPDA/countDataReport?${parameters}`);
  }

  getResumeHistory = (monthBegin, monthEnd) => {
    const areMonthsFilled = monthBegin != null && monthEnd != null;
    const urlParameters = areMonthsFilled ? `monthBegin=${monthBegin}&monthEnd=${monthEnd}` : '';
    return RequestRepository.get(`history?${urlParameters}`);
  }

  buildParametersString = (
    searchString,
    selected,
    method,
    paginacao,
    dates,
    fileType,
    isBilledFilter = null,
    stepStatus = null,
    sorting = null,
    isPlanAhorro = null,
    searchStringAhorro = '',
    ahorroFilters = [],
    filtersExportPDA = {},
  ) => {
    const busca = (searchString === '') ? '' : `&filter=${encodeURIComponent(searchString)}`;
    const buscaAhorro = (!searchStringAhorro || searchStringAhorro === '') ? '' : `&filter=${encodeURIComponent(searchStringAhorro.toLowerCase().replaceAll('-', ''))}`;
    const ahrFilter = this.buildAhorroFilters(ahorroFilters);

    const {
      categories,
      status,
      pendencies,
      macroAreas,
      steps,
      stepsAdministrative,
      brands,
      models,
      macroCategories,
      orderType,
      secondaryDealers,
      dealerId,
    } = selected;

    const {
      startDate,
      endDate,
      isUsingDateFilter,
    } = dates;

    const dealersList = dealerId ? dealerId.split(';') : [];

    const filter10 = fileType ? `&fileType=${fileType}` : '';
    if (fileType && isPlanAhorro) {
      const exportFilters = this.buildExportAhorroFilters(filtersExportPDA);
      const parameters = `${method}${paginacao}${filter10}&${exportFilters}&isPDA=${true}`;

      return parameters;
    }

    if (isPlanAhorro) {
      const parameters = `${method}${paginacao}&isPDA=${true}${buscaAhorro}${ahrFilter}`;

      return parameters;
    }

    const filter1 = (categories.length === 0) ? '' : categories.map(element => (`&clientCategory=${element}`)).join('');
    const filter2 = (status.length === 0) ? '' : status.map(element => (`&status=${element}`)).join('');
    const filter3 = (pendencies.length === 0) ? '' : pendencies.map(element => (`&pendency=${element}`)).join('');
    const filter4 = (macroAreas.length === 0) ? '' : macroAreas.map(element => (`&macroArea=${element}`)).join('');
    const filter5 = (brands.length === 0) ? '' : brands.map(element => (`&brand=${element}`)).join('');
    const filter6 = (models.length === 0) ? '' : models.map(element => (`&model=${element}`)).join('');
    const filter7 = (macroCategories.length === 0) ? '' : macroCategories.map(element => (`&clientMacroCategory=${element}`)).join('');
    const filter8 = (startDate && isUsingDateFilter) ? `&minDate=${startDate}` : '';
    const filter9 = (endDate && isUsingDateFilter) ? `&maxDate=${endDate}` : '';
    const filter11 = (orderType.length === 0) ? '' : orderType.map(element => (`&orderTypes=${element}`)).join('');
    const filter12 = (secondaryDealers.length === 0) ? '' : secondaryDealers.map(element => (`&dealerId=${element}`)).join('');
    const filter13 = (isBilledFilter !== null && !isPlanAhorro) ? `&isBilled=${isBilledFilter}` : '';
    const filter14 = stepStatus !== null ? `&stepStatus=${stepStatus}` : '';
    const filter15 = (steps.length === 0) ? '' : steps.map(element => (`&steps=${element}`)).join('');
    const filter16 = (dealersList.length === 0) ? '' : dealersList.map(element => (`&dealerId=${element.trim()}`)).join('');
    const filter17 = sorting !== null ? `&sorting=${sorting}` : '';
    const filter18 = (stepsAdministrative.length === 0) ? '' : stepsAdministrative.map(element => (`&stepsAdministrative=${element}`)).join('');
    const parameters = `${method}${paginacao}${busca}${filter1}${filter2}${filter3}${filter4}${filter5}${filter6}${filter7}${filter8}${filter9}${filter10}${filter11}${filter12}${filter13}${filter14}${filter15}${filter16}${filter17}${filter18}`;

    return parameters;
  }

  buildAhorroFilters = (filters) => {
    let filter = '';

    const FILTER_KEY = {
      Cuit: 'cuit',
      'Orden comercial': 'commercialOrder',
      'Orden industrial': 'industrialOrder',
      Marca: 'brandOrder',
      Solicitud: 'contractCode',
      Grupo: 'groupCode',
      Orden: 'order',
    };

    if (filters && filters.length > 0) {
      filters.map(({ name, value }) => {
        if (value && value !== '') {
          filter = `${filter}&${FILTER_KEY[name]}=${value.replaceAll('-', '')}`;
        }

        return filter;
      });
    }

    return filter;
  }

  buildExportAhorroFilters = (filters) => {
    if (filters.Marca) {
      return `&brandOrder=${filters.Marca}&groupCodeFrom=${filters.groupCodeFrom}&groupCodeTo=${filters.groupCodeTo}`;
    }

    return '';
  }

  getClientTotalOrdersById(clientDocument) {
    const parameters = `${clientDocument}/order/total`;
    return RequestRepository.get(parameters);
  }
}

export default ListPedidosRepository;
