import ChamadoSiebelService from 'Infrastructure/Services/ChamadoSiebelService';

export const DUCK_NAME = 'siebel';

export const INITIAL_STATE = {
  loading: false,
  insertError: false,
  insertSucces: false,
  data: {},
};

export const CHAMADO_SIEBEL_STARTED = `${DUCK_NAME}/CHAMADO_SIEBEL_STARTED`;
export const CHAMADO_SIEBEL_SUCCEED = `${DUCK_NAME}/CHAMADO_SIEBEL_SUCCEED`;
export const CHAMADO_SIEBEL_FAILED = `${DUCK_NAME}/CHAMADO_SIEBEL_FAILED`;
export const CHAMADO_SIEBEL_RESET = `${DUCK_NAME}/CHAMADO_SIEBEL_RESET`;

export const chamadoSiebelStarted = () => ({ type: CHAMADO_SIEBEL_STARTED });
export const chamadoSiebelSucceed = data => ({ type: CHAMADO_SIEBEL_SUCCEED, data });
export const chamadoSiebelFailed = error => ({ type: CHAMADO_SIEBEL_FAILED, error });
export const resetState = () => ({ type: CHAMADO_SIEBEL_RESET });

export const chamadoSiebel = data => async (dispatch) => {
  const siebelService = new ChamadoSiebelService();
  dispatch(chamadoSiebelStarted());
  try {
    await siebelService.put(data);
    dispatch(chamadoSiebelSucceed(data));
  } catch (err) {
    dispatch(chamadoSiebelFailed(err));
  }
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHAMADO_SIEBEL_STARTED:
      return {
        ...state,
        loading: true,
        insertError: false,
        insertSucces: false,
      };
    case CHAMADO_SIEBEL_FAILED:
      return {
        ...state,
        loading: false,
        insertError: true,
        insertSucces: false,
        error: action.error,
      };
    case CHAMADO_SIEBEL_SUCCEED:
      return {
        ...state,
        loading: false,
        insertError: false,
        insertSucces: true,
        data: action.data,
      };
    case CHAMADO_SIEBEL_RESET:
      return {
        ...state,
        loading: false,
        insertError: false,
        insertSucces: false,
      };
    default:
      return state;
  }
};

export default reducer;
