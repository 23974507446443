import React from 'react';
import PropTypes from 'prop-types';
import langValues from 'Config/i18n/index';
import DrawerSubMenu from '../BaseDrawerSubMenu/DrawerSubMenu';

const VisionConfigurationSubMenu = ({
  loadRules, subMenuList, changePage,
  isSubMenuOpen, setSubMenuOpen,
  setCurrentActiveSubMenu, currentActiveSubMenu,
}) => {
  const accessVisionPage = (id) => {
    setCurrentActiveSubMenu(id);
    changePage();
  };

  return (
    <DrawerSubMenu
      load={loadRules}
      title={langValues.settingsVisionConfiguration.title}
      isSubMenuOpen={isSubMenuOpen}
      setSubMenuOpen={setSubMenuOpen}
      subMenuList={subMenuList}
      currentActiveSubMenu={currentActiveSubMenu}
      setCurrentActiveSubMenu={id => accessVisionPage(id)}
    />
  );
};

VisionConfigurationSubMenu.propTypes = {
  loadRules: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  setSubMenuOpen: PropTypes.func.isRequired,
  isSubMenuOpen: PropTypes.bool.isRequired,
  subMenuList: PropTypes.array.isRequired,
  currentActiveSubMenu: PropTypes.number.isRequired,
  setCurrentActiveSubMenu: PropTypes.func.isRequired,
};

export default VisionConfigurationSubMenu;
