import { connect } from 'react-redux';
import { setFeedbackModalOpened, declineFeedbackTip, clearFeedbackState } from 'Infrastructure/Ducks/FeedbackDuck';
import { setReleaseNotesModalOpened, clearReleaseNotesState } from 'Infrastructure/Ducks/ReleaseNotesDuck';
import HelpButton from './HelpButton';

const mapStateToProps = (state) => {
  const { feedbackDuck } = state;
  const { whenShouldOpenFeedbackTip } = feedbackDuck;
  return { whenShouldOpenFeedbackTip };
};

const mapDispatchToProps = (dispatch) => ({
  setFeedbackModalOpened: (isFeedbackModalOpened) => {
    dispatch(setFeedbackModalOpened(isFeedbackModalOpened));
  },
  clearFeedbackState: () => {
    dispatch(clearFeedbackState());
  },
  setReleaseNotesModalOpened: (isLastUpdatesModalOpened) => {
    dispatch(setReleaseNotesModalOpened(isLastUpdatesModalOpened));
  },
  clearReleaseNotesState: () => {
    dispatch(clearReleaseNotesState());
  },
  declineFeedbackTip: () => {
    dispatch(declineFeedbackTip());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HelpButton);
