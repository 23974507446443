import React from 'react';
import PropTypes from 'prop-types';

import './DealerInformation.scss';
import { safeToLowerCase, safeToUpperCase } from 'Utils/safeMethods';
import langValues from 'Config/i18n/index';

const DealerInformation = ({ dealerTypeLabel = langValues.dealer, dealerId, dealerName, dealerCity, dealerState }) => {
  const dealerNameFinal = dealerName ? safeToLowerCase(dealerName) : '-';
  const dealerCityFinal = dealerCity ? safeToLowerCase(dealerCity) : '-';
  const dealerStateFinal = dealerState ? safeToUpperCase(dealerState) : '-';
  const dealerLocation = `${dealerCityFinal}/${dealerStateFinal}`;
  const dealerNameAndLocation = `${dealerNameFinal} ${langValues.labelsPedidoComercial.middleDot}
  ${dealerLocation}`;
  const dealerIdText = dealerId ? `cod. ${dealerId}` : 'cod. -';
  return (
    <div className="dealerInformation">
      <span className="font-subtitle2 dealerLabel">{dealerTypeLabel}</span>
      <span className="font-small2 dealerInformation">{dealerNameAndLocation}</span>
      <span className="font-small2 dealerId">{dealerIdText}</span>
    </div>
  );
};

DealerInformation.propTypes = {
  dealerTypeLabel: PropTypes.string,
  dealerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dealerName: PropTypes.string,
  dealerCity: PropTypes.string,
  dealerState: PropTypes.string,
};

export default DealerInformation;
