import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { User } from 'Common/Helpers/AuthHelper';
import List from 'Components/Shared/List/List';
import ListModelVersionForecast from 'Components/Molecules/ListModelVersionForecast/ListModelVersionForecast';
import { safeNormalizeString, safeToLowerCase } from 'Utils/safeMethods';
import langValues from 'Config/i18n/index';
import {
  trackedButtons,
  trackedEvents,
  environment,
  roleConsts,
} from 'Utils/consts';
import { trackEvent } from 'Utils/analytics';
import RadioFilter from 'Components/FilterComponents/RadioFilter/RadioFilter';
import { getHeadersWithTooltips } from 'Components/Molecules/HeadersWithTooltips/HeadersWithTooltips';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import DownloadButton from 'Components/Molecules/DownloadButton/DownloadButton';
import UploadButton from 'Components/Molecules/UploadButton/UploadButton';


const ForecastInvoice = ({
  renderErrorMessage, renderSkeleton, modelVersionForecast, loadAverageInvoiceTime,
  downloadAverageInvoice, averageInvoiceDownload, uploadAverageInvoice, averageInvoiceUpload,
  resetUploadInvoiceError, resetDownloadInvoiceError,
}) => {
  useLayoutEffect(() => {
    loadAverageInvoiceTime();
  }, []);
  const [selectedModel, setSelectedModel] = useState(null);

  const { loading, error, data } = modelVersionForecast;
  const {
    isDownloading,
    errorDownloading,
  } = averageInvoiceDownload;

  const {
    isUploading,
    successUploading,
    errorUploading,
  } = averageInvoiceUpload;


  const changeSelectedValue = (newSelectedModel) => {
    setSelectedModel(newSelectedModel);
    trackEvent(trackedEvents.buttonClick, {
      button: trackedButtons.selectModelForecast,
      label: newSelectedModel ? newSelectedModel.label : langValues.clear,
    });
  };

  const isUserDealerBR = User.get().userProfile.includes(roleConsts.dealers)
    && ENV_ENVIRONMENT === environment.BR;

  const isEnvironmentAR = ENV_ENVIRONMENT === environment.AR;

  const customercareBR = !isUserDealerBR && !isEnvironmentAR ? 'customercarebr' : null;

  const renderModelsFilter = () => {
    if (loading) {
      return <div className="skeleton-item" style={{ width: '500px', margin: '32px 0px' }} />;
    }

    if (error || !data || !data.modelList) return null;

    const modelsSelectorOptions = data.modelList.map(value => ({
      value: value.code,
      label: value.name,
    }));

    modelsSelectorOptions.sort((a, b) => (
      safeNormalizeString(a.label) > safeNormalizeString(b.label) ? 1 : -1
    ));

    return (
      <RadioFilter
        entries={modelsSelectorOptions}
        handleChangeSelect={changeSelectedValue}
        selected={selectedModel}
        filterName={langValues.filterLabels.models}
        showCode
      />
    );
  };

  const onUpload = (event) => {
    const file = event.target.files[0];
    uploadAverageInvoice(file);
    document.getElementById('upload-button').value = '';
  };

  const renderTransactionButtons = () => {
    if (User.get().userProfile.includes(roleConsts.admin) && !isEnvironmentAR) {
      return (
        <div className="modelVersionForecast-buttons">
          <div className="download">
            <DownloadButton
              loading={isDownloading}
              download={() => downloadAverageInvoice()}
              error={errorDownloading}
              resetError={resetDownloadInvoiceError}
              titleSnackbarError={langValues.forecastLabels.titleSnackbarErrorDownload}
            />
          </div>
          <div className="upload">
            <UploadButton
              isUploading={isUploading}
              successUploading={successUploading}
              errorUploading={errorUploading}
              onUpload={onUpload}
              resetError={resetUploadInvoiceError}
              titleSnackbarError={langValues.forecastLabels.titleSnackbarErrorUpload}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  const renderModel = (code, name) => (
    <div className="modelVersionForecast-model">
      <div className="modelName">{safeToLowerCase(name)}</div>
      <div className="modelCode">
        <span className="modelCodeSpan">
          cod. <strong>{code}</strong>
        </span>
      </div>
    </div>
  );

  const getAverageInvoiceTimeListHeaders = () => {
    const { headerItems } = langValues.modelVersionLabels;
    if (isUserDealerBR) {
      delete headerItems[2];
    }
    if (isEnvironmentAR) {
      delete headerItems[3];
    }
    return getHeadersWithTooltips(headerItems);
  };

  const getAverageInvoiceTimeListItems = () => {
    const { stepTimeList } = data;
    const filteredStepTimeList = selectedModel
      ? stepTimeList.filter(item => item.modelCode === selectedModel)
      : stepTimeList;

    const listModelVersions = filteredStepTimeList.map(item => ({
      ...item,
      model: renderModel(item.modelCode, item.modelName),
    }));

    listModelVersions.sort((a, b) => (
      safeNormalizeString(a.modelName) > safeNormalizeString(b.modelName) ? 1 : -1
    ));
    return listModelVersions;
  };

  const renderList = () => {
    if (loading) return renderSkeleton(10);
    if (error || !data) {
      return renderErrorMessage();
    }
    return (
      <List
        header={getAverageInvoiceTimeListHeaders()}
        Type={ListModelVersionForecast}
        childProps={{ listItems: getAverageInvoiceTimeListItems() }}
      />
    );
  };

  return (
    <div className="modelVersionForecast-tableContent">
      <div className="modelVersionForecast-filter">
        {renderModelsFilter()}
        {renderTransactionButtons()}
      </div>
      <div className="modelVersionForecast-tableWrapper">
        <div className={`modelVersionForecast-table tab0 ${customercareBR}`}>
          {renderList()}
        </div>
      </div>
    </div>
  );
};

ForecastInvoice.propTypes = {
  renderErrorMessage: PropTypes.object.isRequired,
  renderSkeleton: PropTypes.object.isRequired,
  modelVersionForecast: PropTypes.object.isRequired,
  loadAverageInvoiceTime: PropTypes.func.isRequired,
  downloadAverageInvoice: PropTypes.func.isRequired,
  uploadAverageInvoice: PropTypes.func.isRequired,
  resetUploadInvoiceError: PropTypes.func.isRequired,
  resetDownloadInvoiceError: PropTypes.func.isRequired,
  averageInvoiceDownload: PropTypes.object.isRequired,
  averageInvoiceUpload: PropTypes.object.isRequired,
};

export default ForecastInvoice;
