import React from 'react';
import PropTypes from 'prop-types';
import ClientCategoryIcon from 'Components/ComercialDetailsScreenComponents/PedidoComercial/DadosCliente/ClientCategoryIcon/index';

import './FilterLabel.scss';
import { safeToCapitalizeAllFirstLetters } from 'Utils/safeMethods';

const getCategoriesIcon = key => <ClientCategoryIcon clientCategory={key} renderTooltip={false} />;

const getCategoriesText = (key, label) => (
  <div className="filter-label-categories">
    <p>{key}</p>
    <span>•</span>
    <p>{label}</p>
  </div>
);

const getModelsLabel = (key, label) => (
  <>
    <span>{safeToCapitalizeAllFirstLetters(label)}</span>
    <div className="modelCode">
      <span className="modelCodeSpan">cod. </span>
      <span className="modelCodeNumberSpan">{key}</span>
    </div>
  </>
);

const getStatusLabel = (key, label) => (
  <div className={`filter-label-${key}`}>
    <p className="status-text">
      {label}
    </p>
  </div>
);

const FilterLabel = ({ panelKey, itemKey, label }) => {
  let text;
  let icon;

  switch (panelKey) {
    case 'categories':
      text = getCategoriesText(itemKey, label);
      icon = getCategoriesIcon(itemKey);
      break;
    case 'models':
      text = getModelsLabel(itemKey, label);
      break;
    case 'statusDelay':
    case 'status':
      text = getStatusLabel(itemKey, label);
      break;
    default:
      text = label;
      break;
  }

  return (
    <div className="labelFilter">
      {icon}
      <span>{text}</span>
    </div>
  );
};


FilterLabel.propTypes = {
  panelKey: PropTypes.string.isRequired,
  itemKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  label: PropTypes.string.isRequired,
};

export default FilterLabel;
