import { connect } from 'react-redux';
import {
  setSelectedFilters, setAppliedFilters, setDealerIdFilter,
  clearFilters, setDelaySelectedFilters, setDelayAppliedFilters,
} from 'Infrastructure/Ducks/FiltersDuck';
import FilterAccordion from './FilterAccordion';

const mapStateToProps = (state) => {
  const { filtersDuck } = state;
  const {
    selectedFilters, selectedDelayFilters, appliedFilters, appliedDelayFilters, dealerId,
  } = filtersDuck;
  return {
    selectedFilters, selectedDelayFilters, appliedFilters, appliedDelayFilters, dealerId,
  };
};

const mapDispatchToProps = dispatch => ({
  setSelectedFilters: (selected) => {
    dispatch(setSelectedFilters(selected));
  },
  setDelaySelectedFilters: (selected) => {
    dispatch(setDelaySelectedFilters(selected));
  },
  setAppliedFilters: (selected) => {
    dispatch(setAppliedFilters(selected));
  },
  setDelayAppliedFilters: (selected) => {
    dispatch(setDelayAppliedFilters(selected));
  },
  setDealerIdFilter: (selected) => {
    dispatch(setDealerIdFilter(selected));
  },
  clearFilters: () => {
    dispatch(clearFilters());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterAccordion);
