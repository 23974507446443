import PropTypes from 'prop-types';
import React from 'react';
import ReactImageFallback from 'react-image-fallback';
import defaultCar from 'Common/Assets/imagemFallBack.png';
import langValues from 'Config/i18n/index';
import { safeDealerStateToUpperCase, safeToLowerCase } from 'Utils/safeMethods';
import TagOrderStatus from 'Components/Atoms/TagOrderStatus';
import CpfText from 'Components/Formatters/CpfText/index';
import CnpjText from 'Components/Formatters/CnpjText/index';
import { orderListConsts, environment, stepStatusId } from 'Utils/consts';
import NomeCliente from 'Components/ComercialDetailsScreenComponents/PedidoComercial/DadosCliente/NomeCliente/index';
import ClientCategoryIcon from 'Components/ComercialDetailsScreenComponents/PedidoComercial/DadosCliente/ClientCategoryIcon/index';
import { ReactComponent as CallMadeIcon } from 'Common/Assets/callmadeicon.svg';
import ToggleEnvironment from 'Components/Atoms/ToggleEnvironment';
import { ReactComponent as DadosConcessionariaIcon } from 'Common/Assets/dados_concessionaria.svg';
import BrandLogo from 'Components/Shared/BrandLogo';
import StepStatusCircle from 'Components/Atoms/StepStatusCircle/StepStatusCircle';
import { getShortDateAsNumbers } from 'Utils/dateUtils';
import colorVariables from 'Utils/Styles/colorVariables';

import './DelayManagementDetails.scss';

const DelayManagementDetails = ({ selectedOrder }) => {
  const renderItem = item => item || langValues.notIdentified;

  const renderHeader = () => (
    <div className="cardOrder-header">
      <div className="tagOrder-header">
        <TagOrderStatus statusId={selectedOrder.status} lateDays={selectedOrder.lateDays} />
        <BrandLogo brandId={selectedOrder.brandId} className="brand-logo" />
      </div>
      <h4 className="title-industrialOrder">{`${langValues.delayManagementDetails.industrialOrderTypeText} #${selectedOrder.industrialOrderId}`}</h4>
      <a className="subtitle-order" href={`/detalhes/${selectedOrder.commercialOrderId}?previousPage=acompanhamento`}>
        <span className="font-body2"> {`${safeToLowerCase(selectedOrder.orderType)} #${selectedOrder.commercialOrderId}`}</span>
        <CallMadeIcon className="commercialOrderCallMade-icon" />
      </a>
    </div>
  );

  const renderClientName = () => (
    <NomeCliente clientName={safeToLowerCase(selectedOrder.clientName)} />
  );

  const renderNameDealer = () => (
    <>
      <span className="dealerName">
        {safeToLowerCase(renderItem(selectedOrder.sellerDealer.name))}
      </span>
      <span className="dot">
        {langValues.labelsPedidoComercial.middleDot}
      </span>
      <span className="dealerCounty">
        {`${safeToLowerCase(renderItem(selectedOrder.sellerDealer.county))} - `}
      </span>
      <span className="dealterState">
        {` ${safeDealerStateToUpperCase(renderItem(selectedOrder.sellerDealer.uf))}`}
      </span>
    </>
  );


  const renderDealerInfo = () => (
    <div>
      {selectedOrder.sellerDealer
        ? renderNameDealer()
        : langValues.dealerLabels.detailsNotIdentified}
    </div>
  );


  const renderClientDocument = () => {
    if (selectedOrder.personTypeId === orderListConsts.naturalPerson) {
      return <CpfText cpf={selectedOrder.clientDocument} />;
    }
    return <CnpjText cnpj={selectedOrder.clientDocument} />;
  };

  const renderBody = () => (
    <div className="cardOrder-body">
      <div className="image-vehicle">
        <ReactImageFallback
          src={selectedOrder.imageUrl}
          fallbackImage={defaultCar}
          initialImage={defaultCar}
          alt={langValues.orderInformation.carName}
          className="car-image"
        />
      </div>
      <div className="orderInformationCard-client">
        <ToggleEnvironment env={environment.BR}>
          <ClientCategoryIcon
            clientCategory={selectedOrder.clientCategoryId}
            orderType={selectedOrder.orderType}
            renderTooltip
          />
        </ToggleEnvironment>
        <div className="orderInformationCard-clientName">
          {renderClientName()}
          <span className="font-small1 orderInformationCard-clientDocument">{renderClientDocument()}</span>
        </div>
      </div>
      <div className="orderInformationCard-dealer">
        <DadosConcessionariaIcon />
        <div className="orderInformationCard-dealerTitle">
          <span className="font-small1 orderInformatioCard-dealer">{langValues.delayManagementDetails.dealerTitle}</span>
          <span className="font-small2 orderInformationCard-dealerInfo">
            {renderDealerInfo()}
          </span>
        </div>
      </div>
    </div>
  );

  const renderStepForessen = () => {
    const textDate = selectedOrder.stepForeseenDate
      ? getShortDateAsNumbers(selectedOrder.stepForeseenDate)
      : langValues.delayManagementDetails.returnEmpty;

    return (
      <div className={`foreseen-title font-small3 ${selectedOrder.stepStatus}`}>
        {`${langValues.delayManagementDetails.forecastDelivery} ${textDate}`}
      </div>
    );
  };

  const renderOrderDelayReasons = () => {
    if (selectedOrder.orderDelayReasonList.length > 0) {
      return (
        <div className="orderDelayReasons">
          <div className="reason-title font-small2">{langValues.delayManagementDetails.reasonLateTitle}</div>
          <ul className="reasonListing font-small2">
            {selectedOrder.orderDelayReasonList.map(item => (
              <li>{item}</li>
            ))}
          </ul>
        </div>
      );
    }

    return null;
  };

  const renderLine = () => {
    let strokeColor = colorVariables.errorColor;

    if (selectedOrder.stepStatus === stepStatusId.inRisk) {
      strokeColor = colorVariables.warningColor;
    }

    return (
      <span className="statusIcon-line">
        <svg width="4" height="44" viewBox="0 0 4 44" fill="none">
          <line x1="2" y1="2" x2="2" y2="42" stroke={strokeColor} strokeWidth="4" strokeLinecap="round" strokeDasharray="8 10" />
        </svg>
      </span>
    );
  };


  const renderFooter = () => (
    <div className={`status ${selectedOrder.stepStatus}`}>
      <div className="stepInformation">
        <div className="statusIcon">
          {renderLine()}
          <StepStatusCircle
            step={{ status: selectedOrder.stepStatus }}
            isCurrentStep
            size={32}
          />
        </div>
        <div className="status-step">
          <TagOrderStatus statusId={selectedOrder.stepStatus} lateDays={selectedOrder.stepLateDays} className="tagOrderStatus" />
          <span className="status-title font-body2">{selectedOrder.stepDescription}</span>
          {renderStepForessen()}
          <div className="overdueData font-small3">
            <span className="overdueDate-title">{langValues.delayManagementDetails.insertedStepLate}</span>
            <span className="overdueDate-data">{getShortDateAsNumbers(selectedOrder.dateLate)}</span>
          </div>
        </div>
      </div>
      {renderOrderDelayReasons()}
    </div>
  );

  const renderCardContent = () => {
    if (selectedOrder) {
      return (
        <>
          {renderHeader()}
          {renderBody()}
          <hr className="line-division" />
          {renderFooter()}
        </>
      );
    }
    return null;
  };

  return (
    <section className="delayManagementDetails">
      {renderCardContent()}
    </section>
  );
};

DelayManagementDetails.propTypes = {
  selectedOrder: PropTypes.object.isRequired,
};

export default DelayManagementDetails;
