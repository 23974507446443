import React from 'react';
import PropTypes from 'prop-types';
import langValues from 'Config/i18n/index';
import Selector from 'Components/Atoms/Selector/Selector';
import { User } from 'Common/Helpers/AuthHelper';
import { roleConsts } from 'Utils/consts';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import EditTemplateModal from 'Components/Atoms/EditTemplateModal/EditTemplateModal';
import './ModelModal.scss';

const ModelModal = ({ isOpen, modalType, saveModel, cancelModal, model, setModel }) => {
  const [userBrands, setUserBrands] = React.useState([]);
  const { name, code, origin, brand } = model;
  const { setName, setCode, setOrigin, setBrand } = setModel;

  const originItems = langValues.labelsTransitTime.destinations.map((item, key) => ({ value: key, label: item }));

  const brandsItems = langValues.labelsTransitTime.brands.map((item, key) => ({ value: key, label: item }));

  const isModelCodeDisabled = modalType === 'edit';
  const titleModal = isModelCodeDisabled ? langValues.modelModal.edit : langValues.modelModal.add;

  const mapUserBrandsByRole = () => {
    let brands = [];
    const canSeePeugeot = User.canSeePeugeot();
    const canSeeCitroen = User.canSeeCitroen();
    const removeRolesFromAnotherEnviroment = ENV_ENVIRONMENT === 'BR' ? 'AR' : 'BR';
    const roles = User.get()
      ? User.get().userProfile.filter((role) => !role.includes(`_${removeRolesFromAnotherEnviroment}`))
      : '';

    const hasRoleBr = roles.includes(roleConsts.br) && ENV_ENVIRONMENT === 'BR';
    const hasRoleAr = roles.includes(roleConsts.ar) && ENV_ENVIRONMENT === 'AR';

    if (brandsItems.length > 0) {
      if (hasRoleBr || hasRoleAr) {
        const defaultBrands = brandsItems.filter(({ label }) => label.includes('FCA'))[0];
        brands = [...brands, defaultBrands];
      }
      if (canSeeCitroen) {
        const citroenBrand = brandsItems.filter(({ label }) => label.includes('CITROEN'))[0];
        brands = [...brands, citroenBrand];
      }
      if (canSeePeugeot) {
        const peugeotBrand = brandsItems.filter(({ label }) => label.includes('PEUGEOT'))[0];
        brands = [...brands, peugeotBrand];
      }
    }

    const sortable = brands.sort((a, b) => {
      const nameA = a.label.toUpperCase();
      const nameB = b.label.toUpperCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;

      return 0;
    });
    setUserBrands(sortable);
  };

  React.useEffect(() => {
    mapUserBrandsByRole();
  }, [brandsItems.length]);

  const getBrandValue = () => {
    if (userBrands.length === 1 && !brand) {
      setBrand(userBrands[0].label);
    }

    return {
      value: langValues.labelsTransitTime.brands.indexOf(brand),
      label: brand,
    };
  };

  const renderLabels = () => (
    <div className="modelModal">
      <div className="modelModal-labels">
        <div className="modelModal-input">
          <span className="modelModal-title">{langValues.model}</span>
          <input
            className="modelModal-inputVersion"
            type="text"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </div>
        <div className="modelModal-input">
          <span className="modelModal-title">{langValues.code}</span>
          <input
            className="modelModal-inputCode"
            type="text"
            disabled={isModelCodeDisabled}
            value={code}
            onChange={(event) => setCode(event.target.value)}
          />
        </div>
      </div>
      <div className="modelModal-selector">
        <span className="modelModal-title">{langValues.origin}</span>
        <Selector
          value={{
            value: langValues.labelsTransitTime.destinations.indexOf(origin),
            label: origin,
          }}
          options={originItems}
          closeMenuOnSelect
          handleChangeSelect={(item) => setOrigin(item.label)}
        />
      </div>
      <div className="modelModal-selector">
        <span className="modelModal-title">{langValues.brand}</span>
        <Selector
          value={getBrandValue()}
          options={userBrands}
          closeMenuOnSelect
          isDisabled={userBrands.length === 1}
          handleChangeSelect={(item) => setBrand(item.label)}
        />
      </div>
    </div>
  );

  return (
    <EditTemplateModal
      isOpen={isOpen}
      title={titleModal}
      input={renderLabels()}
      saveAction={() => saveModel()}
      cancelAction={() => cancelModal()}
    />
  );
};

ModelModal.propTypes = {
  cancelModal: PropTypes.func.isRequired,
  saveModel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalType: PropTypes.string.isRequired,
  model: PropTypes.object.isRequired,
  setModel: PropTypes.object.isRequired,
};

export default ModelModal;
