import React from 'react';
import PropTypes from 'prop-types';
import CustomTooltip from 'Components/Atoms/Tooltip/CustomTooltip';
import './TextListing.scss';

const TextListing = ({ texts = [], quantity = 3, rules = [] }) => {
  const orderedByText = texts.sort((a, b) => (a.text > b.text ? 1 : -1));

  const renderTooltipText = (tooltipText) => (
    <div className="hiddenList" key={tooltipText}>
      <span>{tooltipText}</span>
    </div>
  );

  const renderOneText = (item) => (
    <CustomTooltip label={renderTooltipText(item.description)} placement="bottom" key={`label ${item.text}`}>
      <div className={`singleText ${rules.includes('visible') ? 'visible' : ''}`} style={item.style} key={item.text}>
        <span>{item.text}</span>
      </div>
    </CustomTooltip>
  );

  const renderTexts = () => {
    const showableTexts = orderedByText.slice(0, quantity);
    return showableTexts.map((item) => renderOneText(item));
  };

  const hiddenTexts = orderedByText.slice(quantity);

  const renderHiddenList = () => (
    <div className="hiddenList">
      {hiddenTexts.map((item) => (
        <div className="hiddenListLine" key={`hidden ${item.text}`}>
          <span>•</span>
          <span>{item.text}</span>
        </div>
      ))}
    </div>
  );

  const renderShowMore = () => {
    if (hiddenTexts.length > 0) {
      return (
        <CustomTooltip label={renderHiddenList()} placement="bottom">
          <div className="singleText">
            <span>...</span>
          </div>
        </CustomTooltip>
      );
    }
    return null;
  };

  const renderContent = () => {
    if (texts.length > 0) {
      return (
        <div className="textListingContent">
          {renderTexts()}
          {renderShowMore()}
        </div>
      );
    }
    return <span>-</span>;
  };

  return <div className="textListing">{renderContent()}</div>;
};

TextListing.propTypes = {
  texts: PropTypes.array,
  rules: PropTypes.array,
  quantity: PropTypes.number,
};

export default TextListing;
