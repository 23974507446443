const accordionStyle = {
  accordionStyle: {
    root: {
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  },
  accordionSummaryStyle: {
    root: {
      padding: '0px 32px 0px 16px',
    },
    content: {
      margin: 0,
      '&$expanded': {
        margin: '0px',
      },
    },
    expanded: { },
    expandIcon: {
      transform: 'unset',
      '&$expanded': {
        transform: 'unset',
      },
    },
  },
  accordionDetailsStyle: {
    root: {
      padding: '0px 16px 16px 16px',
    },
  },
};

export default accordionStyle;
