import {
  loadLastForecastTransitTimeUpdate,
} from 'Infrastructure/Ducks/SystemUpdateDuck';
import ListRegionService from '../Services/ListRegionService';

export const DUCK_NAME = 'listRegion';

export const INITIAL_STATE = {
  loading: false,
  success: false,
  errorInserting: null,
  errorLoading: null,
  errorSavingTransitTime: null,
  successSavingTransitTime: null,
  data: {},
  regionWithCities: {},
  loadingRegionWithCities: false,
  successRegionWithCities: false,
  errorLoadingRegionWithCities: null,
  citiesExport: {
    loading: false,
    error: null,
  },
};

export const LOAD_LIST_STARTED = `${DUCK_NAME}/LOAD_LIST_STARTED`;
export const LOAD_LIST_REGION_WITH_CITIES_STARTED = `${DUCK_NAME}/LOAD_LIST_REGION_WITH_CITIES_STARTED`;
export const SAVE_LIST_STARTED = `${DUCK_NAME}/SAVE_LIST_STARTED`;
export const LOAD_LIST_SUCCEED = `${DUCK_NAME}/LOAD_LIST_SUCCEED`;
export const LOAD_LIST_REGION_WITH_CITIES_SUCCEED = `${DUCK_NAME}/LOAD_LIST_REGION_WITH_CITIES_SUCCEED`;
export const SAVE_LIST_SUCCEED = `${DUCK_NAME}/SAVE_LIST_SUCCEED`;
export const LOAD_LIST_FAILED = `${DUCK_NAME}/LOAD_LIST_FAILED`;
export const LOAD_LIST_REGION_WITH_CITIES_FAILED = `${DUCK_NAME}/LOAD_LIST_REGION_WITH_CITIES_FAILED`;
export const SAVE_LIST_FAILED = `${DUCK_NAME}/SAVE_LIST_FAILED`;
export const CLEAR_LIST = `${DUCK_NAME}/CLEAR_LIST`;

export const SAVE_TRANSIT_TIME_SUCCEED = `${DUCK_NAME}/SAVE_TRANSIT_TIME_SUCCEED`;
export const SAVE_TRANSIT_TIME_FAILED = `${DUCK_NAME}/SAVE_TRANSIT_TIME_FAILED`;
export const CLEAR_ERROR_SAVE_TRANSIT_TIME = `${DUCK_NAME}/CLEAR_ERROR_SAVE_TRANSIT_TIME`;
export const CLEAR_SUCCESS_SAVE_TRANSIT_TIME = `${DUCK_NAME}/CLEAR_SUCCESS_SAVE_TRANSIT_TIME`;

export const EXPORT_CITIES_STARTED = `${DUCK_NAME}/EXPORT_CITIES_STARTED`;
export const EXPORT_CITIES_SUCCEED = `${DUCK_NAME}/EXPORT_CITIES_SUCCEED`;
export const EXPORT_CITIES_FAILED = `${DUCK_NAME}/EXPORT_CITIES_FAILED`;
export const CLEAR_EXPORT_CITIES = `${DUCK_NAME}/CLEAR_EXPORT_CITIES`;

export const loadListStarted = () => ({ type: LOAD_LIST_STARTED });
export const loadListRegionWithCitiesStarted = () => ({
  type: LOAD_LIST_REGION_WITH_CITIES_STARTED,
});
export const saveListStarted = () => ({ type: SAVE_LIST_STARTED });
export const loadListSucceed = data => ({ type: LOAD_LIST_SUCCEED, data });
export const loadListRegionWithCitiesSucceed = data => ({
  type: LOAD_LIST_REGION_WITH_CITIES_SUCCEED, data,
});
export const saveListSucceed = data => ({ type: SAVE_LIST_SUCCEED, data });
export const loadListFailed = error => ({ type: LOAD_LIST_FAILED, error });
export const loadListRegionWithCitiesFailed = error => ({
  type: LOAD_LIST_REGION_WITH_CITIES_FAILED, error,
});
export const saveListFailed = error => ({ type: SAVE_LIST_FAILED, error });
export const clearListAction = () => ({ type: CLEAR_LIST });

export const saveTransitTimeSucceed = () => ({ type: SAVE_TRANSIT_TIME_SUCCEED });
export const saveTransitTimeFailed = error => ({ type: SAVE_TRANSIT_TIME_FAILED, error });
export const clearErrorSaveTransitTime = () => ({ type: CLEAR_ERROR_SAVE_TRANSIT_TIME });
export const clearSuccessSaveTransitTime = () => ({ type: CLEAR_SUCCESS_SAVE_TRANSIT_TIME });

export const exportCitiesStarted = () => ({ type: EXPORT_CITIES_STARTED });
export const exportCitiesSucceed = () => ({ type: EXPORT_CITIES_SUCCEED });
export const exportCitiesFailed = error => ({ type: EXPORT_CITIES_FAILED, error });
export const clearExportCitiesAction = () => ({ type: CLEAR_EXPORT_CITIES });

export const loadList = () => async (dispatch) => {
  dispatch(loadListStarted());
  let data = {};
  try {
    const listService = new ListRegionService();
    data = await listService.get();
    dispatch(loadListSucceed(data));
  } catch (err) {
    dispatch(loadListFailed(err));
  }
};

export const loadListRegionWithCities = () => async (dispatch) => {
  dispatch(loadListRegionWithCitiesStarted());
  let data = {};
  try {
    const listService = new ListRegionService();
    data = await listService.getCitiesWithRegions();
    dispatch(loadListRegionWithCitiesSucceed(data));
  } catch (err) {
    dispatch(loadListRegionWithCitiesFailed(err));
  }
};

export const saveTransitTime = transitTime => async (dispatch) => {
  try {
    const listService = new ListRegionService();
    await listService.putTransitTime(transitTime);
    dispatch(saveTransitTimeSucceed());
    dispatch(loadListRegionWithCities());
    dispatch(loadLastForecastTransitTimeUpdate());
    setTimeout(() => {
      dispatch(clearSuccessSaveTransitTime());
    }, 3000);
    return true;
  } catch (err) {
    dispatch(saveTransitTimeFailed(err));
    return false;
  }
};

export const saveData = data => async (dispatch) => {
  dispatch(saveListStarted());

  try {
    const listService = new ListRegionService();
    const result = await listService.put(data);
    dispatch(saveListSucceed(result));
  } catch (err) {
    dispatch(saveListFailed(err));
  }
};

export const exportCities = fileType => async (dispatch) => {
  dispatch(exportCitiesStarted());
  const listRegionService = new ListRegionService();

  try {
    await listRegionService.exportCities(fileType);
    dispatch(exportCitiesSucceed());
  } catch (err) {
    dispatch(exportCitiesFailed(err));
  }
};

export const clearList = () => async (dispatch) => {
  dispatch(clearListAction());
};

export const clearExportCities = () => async (dispatch) => {
  dispatch(clearExportCitiesAction());
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_LIST_STARTED:
      return {
        ...state,
        loading: true,
        success: false,
        errorInserting: null,
        errorLoading: null,
      };
    case LOAD_LIST_REGION_WITH_CITIES_STARTED:
      return {
        ...state,
        loadingRegionWithCities: true,
        successRegionWithCities: false,
        errorLoadingRegionWithCities: null,
      };
    case LOAD_LIST_SUCCEED:
      return {
        ...state,
        loading: false,
        success: true,
        errorInserting: null,
        errorLoading: null,
        data: {
          ...action.data,
        },
      };
    case LOAD_LIST_REGION_WITH_CITIES_SUCCEED:
      return {
        ...state,
        loadingRegionWithCities: false,
        successRegionWithCities: true,
        errorLoadingRegionWithCities: null,
        regionWithCities: {
          ...action.data.regionWithCities,
        },
      };
    case SAVE_LIST_SUCCEED:
      return {
        ...state,
        loading: false,
        success: true,
        errorInserting: null,
        errorLoading: null,
        data: {
          ...state.data,
          ...action.data,
        },
      };
    case SAVE_LIST_FAILED:
      return {
        ...state,
        loading: false,
        success: true,
        errorInserting: action.error,
        errorLoading: null,
      };
    case LOAD_LIST_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        errorInserting: null,
        errorLoading: action.error,
      };
    case LOAD_LIST_REGION_WITH_CITIES_FAILED:
      return {
        ...state,
        loadingRegionWithCities: false,
        successRegionWithCities: false,
        errorLoadingRegionWithCities: action.error,
      };
    case SAVE_TRANSIT_TIME_FAILED:
      return {
        ...state,
        errorSavingTransitTime: action.error,
      };
    case SAVE_TRANSIT_TIME_SUCCEED:
      return {
        ...state,
        successSavingTransitTime: true,
      };
    case CLEAR_ERROR_SAVE_TRANSIT_TIME:
      return {
        ...state,
        errorSavingTransitTime: null,
      };
    case CLEAR_SUCCESS_SAVE_TRANSIT_TIME:
      return {
        ...state,
        successSavingTransitTime: null,
      };
    case EXPORT_CITIES_STARTED:
      return {
        ...state,
        citiesExport: {
          loading: true,
          error: null,
        },
      };
    case EXPORT_CITIES_SUCCEED:
    case CLEAR_EXPORT_CITIES:
      return {
        ...state,
        citiesExport: {
          loading: false,
          error: null,
        },
      };
    case EXPORT_CITIES_FAILED:
      return {
        ...state,
        citiesExport: {
          loading: false,
          error: action.error,
        },
      };
    case CLEAR_LIST:
      return {
        ...state,
        loading: false,
        success: false,
        errorInserting: null,
        errorLoading: null,
        errorSavingTransitTime: null,
        successSavingTransitTime: null,
        data: {},
        regionWithCities: {},
        loadingRegionWithCities: false,
        successRegionWithCities: false,
        errorLoadingRegionWithCities: null,
        citiesExport: {
          loading: false,
          error: null,
        },
      };
    default:
      return state;
  }
};

export default reducer;
