
import { connect } from 'react-redux';
import { changeActiveTab, changeSelectedIndustrialOrder } from 'Infrastructure/Ducks/OrderDetailDuck';
import IndustrialOrdersList from './IndustrialOrdersList';

const mapStateToProps = (state) => {
  const { orderDetail } = state;
  const { currentActiveTab, success, loading } = orderDetail;
  return { currentActiveTab, requestSuccess: success, requestLoading: loading };
};

const mapDispatchToProps = dispatch => ({
  changeSelectedIndustrialOrder: (industrialOrder) => {
    dispatch(changeSelectedIndustrialOrder(industrialOrder));
  },
  changeActiveTab: (tabId) => {
    dispatch(changeActiveTab(tabId));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndustrialOrdersList);
