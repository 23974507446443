import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import { environment } from 'Utils/consts';
import PTBR from './Pt-br';
import ES from './Es';

const languagesMap = {
  [environment.BR]: PTBR,
  [environment.AR]: ES,
};

const langValues = languagesMap[ENV_ENVIRONMENT] || PTBR;
export default langValues;
