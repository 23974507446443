import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import langValues from 'Config/i18n/index';
import { emptyDate } from 'Utils/consts';
import { getDateAsText } from 'Utils/dateUtils';

import './AverageInvoiceTimeUpdate.scss';

const AverageInvoiceTimeUpdate = ({ lastAverageInvoiceTimeUpdate = null, loadLastAverageInvoiceTimeUpdate }) => {
  useEffect(() => {
    loadLastAverageInvoiceTimeUpdate();
  }, []);

  let updateDateText = emptyDate;
  if (lastAverageInvoiceTimeUpdate) {
    updateDateText = getDateAsText(new Date(lastAverageInvoiceTimeUpdate));
  }

  return (
    <span className="averageInvoiceTimeUpdateDate">
      {langValues.lastUpdate}
      {updateDateText}
    </span>
  );
};

AverageInvoiceTimeUpdate.propTypes = {
  loadLastAverageInvoiceTimeUpdate: PropTypes.func.isRequired,
  lastAverageInvoiceTimeUpdate: PropTypes.string,
};

export default AverageInvoiceTimeUpdate;
