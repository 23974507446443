import moment from 'moment';

import FeedbackService from '../Services/FeedbackService';

export const DUCK_NAME = 'feedbackDuck';

const RESPONSE_INITIAL_STATE = () => ({
  loading: false,
  error: false,
  success: false,
});

const INITIAL_STATE = () => ({
  isFeedbackModalOpened: false,
  isResponseModalOpened: false,
  feedbackCategories: [],
  feedbackCategoriesResponse: RESPONSE_INITIAL_STATE(),
  feedbackResponse: RESPONSE_INITIAL_STATE(),
  feedback: {},
  whenShouldOpenFeedbackTip: undefined,
});

const SET_FEEDBACK_MODAL_OPENED = `${DUCK_NAME}/SET_FEEDBACK_MODAL_OPENED`;
const SET_RESPONSE_MODAL_OPENED = `${DUCK_NAME}/SET_RESPONSE_MODAL_OPENED`;
const SAVE_FEEDBACK_SUCCEED = `${DUCK_NAME}/SAVE_FEEDBACK_SUCCEED`;
const SAVE_FEEDBACK_STARTED = `${DUCK_NAME}/SAVE_FEEDBACK_STARTED`;
const SAVE_FEEDBACK_FAILED = `${DUCK_NAME}/SAVE_FEEDBACK_FAILED`;
const DECLINE_FEEDBACK_TIP = `${DUCK_NAME}/DECLINE_FEEDBACK_TIP`;
const CLEAR_STATE = `${DUCK_NAME}/CLEAR_STATE`;

const saveFeedbackFailed = error => ({ type: SAVE_FEEDBACK_FAILED, error });
const saveFeedbackStarted = feedback => ({ type: SAVE_FEEDBACK_STARTED, feedback });
const saveFeedbackSucceed = () => ({ type: SAVE_FEEDBACK_SUCCEED });

export const clearFeedbackState = () => ({ type: CLEAR_STATE });

export const setFeedbackModalOpened = isFeedbackModalOpened => (
  { type: SET_FEEDBACK_MODAL_OPENED, isFeedbackModalOpened }
);

export const setResponseModalOpened = isResponseModalOpened => (
  { type: SET_RESPONSE_MODAL_OPENED, isResponseModalOpened }
);

export const declineFeedbackTip = () => (
  { type: DECLINE_FEEDBACK_TIP }
);

export const saveFeedback = feedback => async (dispatch) => {
  dispatch(saveFeedbackStarted(feedback));
  try {
    const feedbackService = new FeedbackService();
    await feedbackService.putFeedback(feedback);
    dispatch(saveFeedbackSucceed());
  } catch (err) {
    dispatch(saveFeedbackFailed());
  }
};

const reducer = (state = INITIAL_STATE(), action) => {
  switch (action.type) {
    case SET_FEEDBACK_MODAL_OPENED:
      return {
        ...state,
        isFeedbackModalOpened: action.isFeedbackModalOpened,
        whenShouldOpenFeedbackTip: moment().valueOf() + 10 * 24 * 60 * 60 * 1000,
      };
    case SET_RESPONSE_MODAL_OPENED:
      return {
        ...state,
        isResponseModalOpened: action.isResponseModalOpened,
      };
    case SAVE_FEEDBACK_STARTED:
      return {
        ...state,
        feedbackResponse: {
          loading: true, error: false, success: false,
        },
        feedback: { ...action.feedback },
        whenShouldOpenFeedbackTip: moment().valueOf() + 30 * 24 * 60 * 60 * 1000,
      };
    case SAVE_FEEDBACK_FAILED:
      return {
        ...state,
        feedbackResponse: {
          loading: false, error: true, success: false,
        },
      };
    case SAVE_FEEDBACK_SUCCEED:
      return {
        ...state,
        feedbackResponse: {
          loading: false, error: false, success: true,
        },
      };
    case DECLINE_FEEDBACK_TIP:
      return {
        ...state,
        whenShouldOpenFeedbackTip: moment().valueOf() + 10 * 24 * 60 * 60 * 1000,
      };
    case CLEAR_STATE:
      return {
        ...INITIAL_STATE(),
        whenShouldOpenFeedbackTip: state.whenShouldOpenFeedbackTip,
      };
    default:
      return state;
  }
};

export default reducer;
