import React from 'react';
import PropTypes from 'prop-types';
import './BaseIndustrialOrderDetail.scss';
import CarDetailImage from 'Components/Atoms/CarDetailImage/CarDetailImage';

function BaseIndustrialOrderDetail({
  status,
  title,
  logo = null,
  body = null,
  classNamePrefix = '',
  subtitle = null,
  industrialOrder = null,
}) {
  return (
    <div className={`${classNamePrefix} piCard`}>
      <section>
        {status ? <div className={`${classNamePrefix} statusPI`}>{status}</div> : null}
        {title ? (
          <div className={`${classNamePrefix} cabecalhoPI`}>
            <div className={`${classNamePrefix} titulo`}>
              <h1 className={`${classNamePrefix} tituloPI`}>{title}</h1>
              {logo}
            </div>
            <span className="font-small2">{subtitle}</span>
          </div>
        ) : null}
        <div className="industrailOrderDetail__body">{body}</div>
      </section>
      <div>
        <CarDetailImage industrialOrder={industrialOrder} />
      </div>
    </div>
  );
}

BaseIndustrialOrderDetail.propTypes = {
  status: PropTypes.object,
  industrialOrder: PropTypes.object,
  title: PropTypes.object,
  subtitle: PropTypes.string,
  logo: PropTypes.object,
  body: PropTypes.object,
  classNamePrefix: PropTypes.string,
};

export default BaseIndustrialOrderDetail;
