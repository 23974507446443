import DelayedOrdersRepository from 'Infrastructure/Repositories/DelayedOrdersRepository';

class DelayedOrdersService {
  constructor(delayedOrdersRepository = new DelayedOrdersRepository()) {
    this.delayedOrdersRepository = delayedOrdersRepository;
  }

  async getDelayedOrders() {
    try {
      const { data } = await this.delayedOrdersRepository.getDelayedOrders();
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getDelayedOrdersTotal() {
    try {
      const { data } = await this.delayedOrdersRepository.getDelayedOrdersTotal();
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default DelayedOrdersService;
