import React from 'react';
import PropTypes from 'prop-types';

import './InformationTooltipPDA.scss';
import { Divider } from '@mui/material';

const InformationTooltipPDA = ({ step }) => {
  const linkPactoClaro = (step.informationHeader || []).filter((i) => i.name === 'Link Pacto Claro');
  const toSplit = step.messageClient && step.messageClient.includes('\r\n') ? '\r\n' : '\n';
  const message = (step.messageClient || '').split(toSplit);
  const messageInt = (step.messageIntern || '').split('\r\n');

  const overflow = step.sequence === 9 || step.sequence === 3;

  function urlify(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    return `${text.replace(urlRegex, (url) => `<a href="${url}" rel="noopener noreferrer" target="_blank">${url}</a>`)}`;
  }

  return (
    <div className={`info-pda ${overflow && 'overflow'}`}>
      <p className="info-pda--head">{step.name}</p>

      {(step.informationHeader || [])
        .filter((i) => i.name !== 'Link Pacto Claro')
        .map(({ name, value }) => (
          <div className="info-pda--list" key={name + value}>
            <p className="info-pda--list--name">{name}</p>
            <p className="info-pda--list--bold">{value}</p>
          </div>
        ))}

      {step.messageIntern && (
        <>
          <Divider className="info-pda--divisor" />
          <p className="info-pda--head">Información interna</p>

          <div className="info-pda--client">
            {messageInt.map((m) => (
              <p dangerouslySetInnerHTML={{ __html: urlify(m) || '' }} key={m} />
            ))}
          </div>
        </>
      )}

      <Divider className="info-pda--divisor" />
      <p className="info-pda--head">Información el cliente</p>

      <div className="info-pda--client">
        {message.map((m) => (
          <p dangerouslySetInnerHTML={{ __html: urlify(m) || 'No hay información para mostrar!' }} key={m} />
        ))}
      </div>

      {step.sequence === 1 && (
        <a href={linkPactoClaro[0].value} rel="noopener noreferrer" target="_blank">
          {linkPactoClaro[0].value}
        </a>
      )}
    </div>
  );
};

InformationTooltipPDA.propTypes = {
  step: PropTypes.object.isRequired,
};

export default InformationTooltipPDA;
