/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as PenDocIcon } from 'Common/Assets/penDoc.svg';
import { ReactComponent as PenFinIcon } from 'Common/Assets/commercialPen.svg';
import { ReactComponent as PendConcIcon } from 'Common/Assets/pendConc.svg';
import { ReactComponent as CalendarIcon } from 'Common/Assets/calendar.svg';
import { ReactComponent as OrderSwap } from 'Common/Assets/orders_swap.svg';
import { ReactComponent as PenBlockIcon } from 'Common/Assets/bloqueio_de_qualidade.svg';
import { ReactComponent as OutboundBlockIcon } from 'Common/Assets/outboundBlock.svg';

import ToggleEnvironment from 'Components/Atoms/ToggleEnvironment';
import langValues from 'Config/i18n/index';

import { safeToLowerCase } from 'Utils/safeMethods';

import './ListItemPedidos.scss';
import DataExtenso from 'Components/Formatters/DataExtenso';
import CpfText from 'Components/Formatters/CpfText';
import { orderListConsts, environment } from 'Utils/consts';
import CnpjText from 'Components/Formatters/CnpjText';

import TagOrderStatus from 'Components/Atoms/TagOrderStatus';
import ClientCategoryIcon from 'Components/ComercialDetailsScreenComponents/PedidoComercial/DadosCliente/ClientCategoryIcon/index';
import CustomTooltip from 'Components/Atoms/Tooltip/CustomTooltip';

class ListItemPedidos extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  numberRequestsText = (numberRequests) => {
    if (numberRequests > 0) {
      return `${numberRequests} ${numberRequests > 1 ? langValues.listPedidosLabels.pedidos : langValues.listPedidosLabels.pedido}`;
    }
    return langValues.listPedidosLabels.noOrder;
  };

  renderNumberRequestsDiv = (numberRequests) => <div id="nRequests">{this.numberRequestsText(numberRequests)}</div>;

  renderPenConc = (report) =>
    report ? (
      <td className="iconDiv">
        <div id="icon">
          <PendConcIcon />
        </div>
      </td>
    ) : null;

  renderClientCategoryDiv = (item) => (
    <ToggleEnvironment env={environment.BR}>
      <td className="typeIcon">
        <ClientCategoryIcon clientCategory={item.clientCategoryId} orderType={item.orderType} />
      </td>
    </ToggleEnvironment>
  );

  getOrderTypeLabels = (orderType) => {
    const orderTypeLabels = {
      tooltip: null,
      text: null,
    };
    switch (orderType) {
      case langValues.labelsOrderType.dealer:
        orderTypeLabels.tooltip = langValues.labelsOrderType.dealerTooltip;
        orderTypeLabels.text = langValues.labelsOrderType.dealerKey;
        break;
      case langValues.labelsOrderType.employee:
        orderTypeLabels.tooltip = langValues.labelsOrderType.employeeTooltip;
        orderTypeLabels.text = langValues.labelsOrderType.employeeKey;
        break;
      case langValues.labelsOrderType.directSale:
      case langValues.labelsOrderType.directSaleKey:
      case langValues.labelsOrderType.commercial:
        orderTypeLabels.tooltip = langValues.labelsOrderType.directSaleTooltip;
        orderTypeLabels.text = langValues.labelsOrderType.directSaleKey;
        break;
      case langValues.labelsOrderType.savingsPlan:
        orderTypeLabels.tooltip = langValues.labelsOrderType.savingsPlanTooltip;
        orderTypeLabels.text = langValues.labelsOrderType.savingsPlanKey;
        break;
      case langValues.labelsOrderType.notSold:
        orderTypeLabels.tooltip = langValues.labelsOrderType.notSoldToolTip;
        orderTypeLabels.text = langValues.labelsOrderType.notSoldKey;
        break;
      default:
        break;
    }

    return orderTypeLabels;
  };

  renderOrderTypeDiv = (item) => {
    const type = item.contractCode ? langValues.labelsOrderType.savingsPlan : item.orderType;
    const orderTypeLabels = this.getOrderTypeLabels(type);

    return (
      <td className="orderTypeDiv">
        <span className="orderTypeText" title={orderTypeLabels.tooltip}>
          {orderTypeLabels.text}
        </span>
      </td>
    );
  };

  renderRequestComDiv = (item) => (
    <td className="requestComDiv">
      <span id="requestNumber">{`#${item.orderId}`}</span>
      <div id="date">
        <DataExtenso date={item.orderDate} />
      </div>
    </td>
  );

  renderClienteDocField = (item) => {
    if (item.personTypeId === orderListConsts.naturalPerson) {
      return <CpfText cpf={item.clientDocument} />;
    }
    return <CnpjText cnpj={item.clientDocument || (item.cuitClientCode || '').toString() || ''} />;
  };

  renderClientDataDiv = (item) => (
    <td className="clientDataDiv">
      <span
        className="clientName"
        title={safeToLowerCase(item.clientName || item.fullname || '').replace(/(^|\s)\S/g, (l) => l.toUpperCase())}
      >
        {safeToLowerCase(item.clientName || item.fullname || '')}
      </span>
      <div id="clientDoc">{this.renderClienteDocField(item)}</div>
    </td>
  );

  renderDeliveryLimitDiv = (item) => (
    <td className="deliveryLimit">
      <div id="calendar-icon">
        <CalendarIcon />
      </div>
      <div id="limitDeliveryDate">
        {item.foreseenDate ? <DataExtenso date={item.foreseenDate} /> : langValues.listPedidosLabels.noForecast}
      </div>
    </td>
  );

  getOnCallSiebel = (data) => {
    if (data) {
      const hasSiebel = data.countProtocol;

      return hasSiebel;
    }
    return false;
  };

  renderLateDaysDiv = (item) => (
    <td className="lateDaysDiv">
      {item.numberOrders > 0 ? <TagOrderStatus lateDays={item.maximumDelay} statusId={item.statusId} /> : null}
    </td>
  );

  renderRequestsDiv = (item) => (
    <ToggleEnvironment env={environment.BR}>
      <td className="numberRequests">{this.renderNumberRequestsDiv(item.numberOrders)}</td>
    </ToggleEnvironment>
  );

  renderIcon = (iconCondition, Icon, iconDescription) => {
    if (!iconCondition) {
      return null;
    }

    return (
      <div className="iconDiv">
        <CustomTooltip label={iconDescription}>
          <div id="icon">
            <Icon />
          </div>
        </CustomTooltip>
      </div>
    );
  };

  renderAlertsDiv = (item, dealer) => (
    <td className="alertsDiv">
      {item.numberOrders > 0 ? (
        <div className="alertsDivWrapper">
          {dealer
            ? null
            : this.renderIcon(item.orderSwapFlag, OrderSwap, langValues.orderInformationBlock.orderSwapFlag)}
          {this.renderIcon(
            item.clientFinancialPendencyFlag,
            PenFinIcon,
            langValues.orderInformationBlock.clientFinancialPendencyFlag
          )}
          {this.renderIcon(
            item.clientDocumentPendencyFlag,
            PenDocIcon,
            langValues.orderInformationBlock.clientDocumentPendencyFlag
          )}
          {this.renderIcon(item.dealerPendencyFlag, PendConcIcon, langValues.orderInformationBlock.dealerPendency)}
          {this.renderIcon(item.commercialBlockFlag, PenFinIcon, langValues.orderInformationBlock.commercialBlockLabel)}
          {this.renderIcon(item.qualityBlockFlag, PenBlockIcon, langValues.orderInformationBlock.qualityBlockLabel)}
          {this.renderIcon(
            item.outbountBlockFlag,
            OutboundBlockIcon,
            langValues.orderInformationBlock.outbountBlockLabel
          )}
        </div>
      ) : null}
    </td>
  );

  renderEmptyListItem = () => (
    <div id="empty-list-item-pedidos" className="list-item-pedidos">
      <div id="emptyTypeIcon" className="typeIcon" />
      <div className="emptyData" />
    </div>
  );

  renderSolicitudDiv = (item) => <td className="solicitudDiv">{item.contractCode || ''}</td>;

  renderAhorroOrderTypeDiv = (item) => (
    <td className="ahorroOrderDiv" title={langValues.labelsOrderType.savingsPlanTooltip}>
      {langValues.labelsOrderType.savingsPlanKey}
    </td>
  );

  renderGroupDiv = (item) => <td className="groupDiv">{item.groupCode || ''}</td>;

  renderOrderDiv = (item) => <td className="orderDiv">{item.order || ''}</td>;

  renderCommercialOrderDiv = (item) => <td className="commercialOrderDiv">{item.factoryId || item.orderId || ''}</td>;

  renderListComponentAhorro = (item) => {
    if (item) {
      const code = item.contractCode
        ? `${item.contractCode}/${item.contractBrandCode}/${item.orderId || 'null'}`
        : item.orderId;
      const detailsRedir = item.contractCode ? 'detalhes-pda' : 'detalhes';
      const href = `/${detailsRedir}/${code}?previousPage=pedidos`;
      return (
        <a href={href} style={{ display: 'contents', textDecoration: 'none', color: '#657DD9', fontWeight: 700 }}>
          <tr className="list-item-pedidos anchor-list-item">
            {this.renderAhorroOrderTypeDiv(item)}
            {this.renderSolicitudDiv(item)}
            {this.renderGroupDiv(item)}
            {this.renderOrderDiv(item)}
            {this.renderClientDataDiv(item)}
            {this.renderCommercialOrderDiv(item)}
          </tr>
        </a>
      );
    }
    return this.renderEmptyListItem();
  };

  renderListComponent = (item, dealer) => {
    if (item) {
      const isPDA = !!item.contractCode || !!item.contractBrandCode;

      const code = `${item.contractCode}/${item.contractBrandCode}`;
      const id = item.orderId;
      const codeAndOrderId = id ? `${code}/${id}` : `${code}/null`;

      const href = `/${isPDA ? 'detalhes-pda' : 'detalhes'}/${isPDA ? codeAndOrderId : item.orderId}?previousPage=pedidos`;
      return (
        <a href={href} style={{ display: 'contents', textDecoration: 'none' }}>
          <tr className="list-item-pedidos anchor-list-item">
            {this.renderOrderTypeDiv(item)}
            {this.renderClientCategoryDiv(item)}
            {item.orderId ? this.renderRequestComDiv(item) : <td className="requestComDiv" />}
            {this.renderClientDataDiv(item)}
            {this.renderDeliveryLimitDiv(item)}
            {this.renderLateDaysDiv(item)}
            {this.renderAlertsDiv(item, dealer)}
            {this.renderRequestsDiv(item)}
          </tr>
        </a>
      );
    }
    return this.renderEmptyListItem();
  };

  render = () => {
    const { item, dealer, isPlanAhorro } = this.props;

    if (isPlanAhorro) {
      return this.renderListComponentAhorro(item);
    }

    return this.renderListComponent(item, dealer);
  };
}

ListItemPedidos.propTypes = {
  item: PropTypes.object,
  dealer: PropTypes.bool,
  isPlanAhorro: PropTypes.bool,
};

export default ListItemPedidos;
