import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@mui/material';
import { CircularProgress } from 'Components/HelpComponents/ResponseModal/styles';
import { ReactComponent as AlertIcon } from 'Common/Assets/alert-triangle-request.svg';
import { ReactComponent as CheckIcon } from 'Common/Assets/check-circle-request.svg';
import './InvoiceDownloadModal.scss';
import { trackedEvents, trackedButtons, roleConsts } from 'Utils/consts';
import { trackEvent } from 'Utils/analytics';
import { User } from 'Common/Helpers/AuthHelper';
import langValues from 'Config/i18n/index';
import { Checkbox } from './styles';

const InvoiceDownloadModal = ({
  open,
  onClose,
  invoiceDuck,
  downloadInvoice,
  data,
  clearInvoiceDuck,
  downloadInvoiceEmployee,
}) => {
  const [checkboxState, setCheckboxState] = useState(false);
  const { loading, success, error } = invoiceDuck;

  const closeModal = () => {
    onClose();
    clearInvoiceDuck();
    setCheckboxState(false);
  };

  const onClickDownloadInvoice = () => {
    if (checkboxState) {
      if (User.hasProfile(roleConsts.employees)) {
        downloadInvoiceEmployee(data.orderId);
      } else {
        downloadInvoice(data.industrialOrderId, data.orderId, data.vin);
      }
      trackEvent(trackedEvents.buttonClick, { button: trackedButtons.downloadInvoice });
    }
  };

  const modalContent = () => (
    <>
      <span className="invoiceDownloadModal-juridico">
        {User.hasProfile(roleConsts.employees)
          ? langValues.invoiceLabels.employeeLegalText
          : langValues.invoiceLabels.legalText}
      </span>
      <div className="invoiceDownloadModal-checkboxSection">
        <Checkbox
          classes={{ root: 'colorSecondary' }}
          disableRipple
          checked={checkboxState}
          onChange={() => setCheckboxState(!checkboxState)}
        />
        <span className="invoiceDownloadModal-termsagree">{langValues.invoiceLabels.termsAgree}</span>
      </div>
      <Button
        onClick={onClickDownloadInvoice}
        disableFocusRipple
        className={`invoiceDownloadModal-startButton ${checkboxState ? 'enabled' : 'disabled'}`}
      >
        {langValues.invoiceLabels.downloadInvoice}
      </Button>
    </>
  );

  const modalContentFeedbak = (icon, title, text, button) => (
    <>
      {icon}
      <div className="invoiceDownloadModal-feedback">
        <h1>{title}</h1>
        <span>{text}</span>
        {button}
      </div>
    </>
  );

  const renderModalContent = () => {
    let icon = null;
    let title = '';
    let text = '';
    let isFeedbackContent = false;
    let button = null;

    const closeButton = (
      <Button
        onClick={closeModal}
        disableFocusRipple
        variant="outlined"
        className="invoiceDownloadModal-closeModalButton"
      >
        Fechar
      </Button>
    );

    if (loading) {
      icon = <CircularProgress thickness={5.5} size={45} />;
      title = langValues.invoiceLabels.downloadLoadingTitle;
      text = langValues.invoiceLabels.downloadLoadingText;
      isFeedbackContent = true;
    } else if (success) {
      icon = <CheckIcon />;
      title = langValues.invoiceLabels.downloadSuccessTitle;
      text = langValues.invoiceLabels.downloadSuccessText;
      isFeedbackContent = true;
      button = closeButton;
    } else if (error) {
      const { status } = error.response;
      icon = <AlertIcon />;
      isFeedbackContent = true;
      if (status === 404) {
        title = langValues.invoiceLabels.invoiceNotFoundTitle;
        text = langValues.invoiceLabels.invoiceNotFoundText;
        button = closeButton;
      } else {
        title = langValues.invoiceLabels.downloadErrorTitle;
        text = langValues.invoiceLabels.downloadErrorText;
        button = (
          <Button
            onClick={() => downloadInvoice(data.industrialOrderId, data.orderId, data.vin)}
            disableFocusRipple
            className="invoiceDownloadModal-startButton enabled retry"
          >
            Tentar novamente
          </Button>
        );
      }
    }

    if (isFeedbackContent) return modalContentFeedbak(icon, title, text, button);
    return modalContent();
  };

  return (
    <Modal
      open={open}
      className="invoiceDownloadModal-modal"
      onClose={(event, reason) => {
        if (!loading || reason !== 'backdropClick') {
          closeModal();
        }
      }}
    >
      <div className="invoiceDownloadModal-wrapper">{renderModalContent()}</div>
    </Modal>
  );
};

InvoiceDownloadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  downloadInvoice: PropTypes.func.isRequired,
  clearInvoiceDuck: PropTypes.func.isRequired,
  invoiceDuck: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  downloadInvoiceEmployee: PropTypes.func.isRequired,
};

export default InvoiceDownloadModal;
