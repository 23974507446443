import React from 'react';
import PropTypes from 'prop-types';
import InfoText from 'Components/Formatters/InfoText';
import { safeToLowerCase } from 'Utils/safeMethods';
import { capitalizeText, getValidatedText } from 'Utils/stringUtils';
import langValues from 'Config/i18n/index';
import './CarDetail.scss';

const CarDetail = ({ industrialOrder, children = null }) => {
  const renderText = (text, numeric) => {
    const validatedText = getValidatedText(text, numeric);
    return safeToLowerCase(validatedText);
  };

  function listOptionals(optionals) {
    if (optionals && optionals.length > 0) {
      return optionals.map((optional) => {
        const optionalText = `${optional.optionalId} - ${optional.description}`;
        return (
          <div key={optional.optionalId} title={capitalizeText(optionalText)} className="text-optional">
            {optionalText}
          </div>
        );
      });
    }
    return <div className="text-optional">-</div>;
  }

  function renderOptionals(optionals) {
    return (
      <div className="optionals-wrapper">
        {listOptionals(optionals)}
        <div className="label-optional">{langValues.optionals}</div>
      </div>
    );
  }

  return industrialOrder ? (
    <div className="details">
      <div className="details-box">
        <div className="linha">
          <InfoText
            key="modelName"
            text={renderText(industrialOrder.modelName)}
            label={langValues.labelsPedidoIndustrial.modelo}
            style={{ width: '69px', marginRight: '24px' }}
          />
          <InfoText
            key="modelYear"
            text={renderText(industrialOrder.modelYear, true)}
            label={langValues.labelsPedidoIndustrial.ano}
            style={{ width: '59px', marginRight: '40px' }}
          />
          <InfoText
            key="versionName"
            text={renderText(industrialOrder.versionName)}
            label={langValues.labelsPedidoIndustrial.versao}
          />
        </div>
        <div className="linha">
          <InfoText
            key="externalColor"
            text={renderText(industrialOrder.externalColorDescription)}
            label={langValues.labelsPedidoIndustrial.cor}
            style={{ width: '160px', marginRight: '32px' }}
          />
          <InfoText
            key="internalColor"
            text={renderText(industrialOrder.internalColorDescription)}
            label={langValues.labelsPedidoIndustrial.revestimento}
          />
        </div>
        <div className="linha">
          {renderOptionals(industrialOrder.optionals)}
          {children}
        </div>
      </div>
    </div>
  ) : null;
};

CarDetail.propTypes = {
  industrialOrder: PropTypes.object.isRequired,
  children: PropTypes.object,
};

export default CarDetail;
