import React from 'react';
import PropTypes from 'prop-types';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import langValues from 'Config/i18n/index';
import TextFieldInput from 'Components/Atoms/TextFieldInput';

import {
  Checkbox,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from 'Components/Atoms/FormControltems/styles';

const FilterSectionDealer = ({ dealerId, panelName, panelKey, isExpanded, setDealerId, setExpanded }) => {
  const isChecked = !!dealerId && dealerId.length > 0;

  const icon = <CheckBoxOutlineBlankIcon className="checkbox-filter" fontSize="small" />;

  const renderDetails = () => (
    <ExpansionPanelDetails>
      <div key={`${panelKey}`} className="filterOption searchBoxOption">
        <TextFieldInput
          variant="standard"
          savedTextContent={dealerId}
          label={langValues.filterLabels.dealerIdPlaceholder}
          onChange={(value) => setDealerId(value)}
        />
      </div>
    </ExpansionPanelDetails>
  );

  return (
    <ExpansionPanel expanded={isExpanded}>
      <ExpansionPanelSummary onClick={() => setExpanded(panelKey)} expandIcon={<ExpandMoreIcon />}>
        <div className="sectionHeader">
          <div className="sectionTitle">
            <Checkbox
              className="checkbox-filter"
              color="primary"
              onClick={(e) => {
                if (isChecked) setDealerId('');
                e.stopPropagation();
              }}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              icon={icon}
              checked={isChecked}
            />
            <div className="section-filter-title">
              <p>{panelName}</p>
            </div>
          </div>
        </div>
      </ExpansionPanelSummary>
      {renderDetails()}
    </ExpansionPanel>
  );
};

FilterSectionDealer.propTypes = {
  dealerId: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  panelName: PropTypes.string.isRequired,
  panelKey: PropTypes.string.isRequired,
  setDealerId: PropTypes.func.isRequired,
  setExpanded: PropTypes.func.isRequired,
};

export default FilterSectionDealer;
