import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import TextFieldInput from 'Components/Atoms/TextFieldInput/TextFieldInput';
import FilterAccordionAhorro from 'Components/Molecules/FilterAccordionAhorro';
import './OrderAhorroFilters.scss';
import langValues from 'Config/i18n/index';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';
import SearchInputAhorro from 'Components/Molecules/SearchInputAhorro/SearchInputAhorro';
import Selector from 'Components/Atoms/Selector/Selector';
import RequestRepository from 'Infrastructure/Repositories/RequestRepository';

const FILTERS_LENGTH = {
  Cuit: '11',
  'Orden comercial': '9',
  'Orden industrial': '12',
  Solicitud: '8',
  Grupo: '5',
  Orden: '3',
};

const OrderAhorroFilters = ({
  onConfirmFilter,
  clearList = () => {},
  setSelectedAhorroFilters = (search, filters) => {},
  selectedAhorroFilters,
  clearAhorroFilters = () => {},
  searchAhorroString = '',
  setPageNumber = (page) => {},
}) => {
  const [filters, setFilters] = useState(selectedAhorroFilters);
  const [search, setSearch] = useState(searchAhorroString);
  const [disableFields2, setDisableFields2] = useState(true);
  const [brands, setBrands] = useState([]);

  const hasMoreThanOneFilterApplied = filters.filter((f) => !!f.value).length >= 2;
  const cuitOrOrdenFilled = filters.some((f) => (f.name === 'Cuit' || f.name === 'Orden comercial') && !!f.value);

  function delay(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const handleFilters = (val, filter) => {
    const newVal = {
      name: filter,
      value: val,
    };

    setFilters((prevVal) => prevVal.map((prev) => (prev.name === filter ? newVal : prev)));
  };

  const filtersChanged = () => {
    const hasFiltersChanged = selectedAhorroFilters.some(
      ({ name, value }) => filters.filter((f) => f.name === name)[0].value !== value
    );
    const inputChanged = searchAhorroString !== search;

    return inputChanged || hasFiltersChanged;
  };

  const onApplyFilter = async () => {
    const hasFiltersChanged = filtersChanged();

    if (hasFiltersChanged) {
      await setPageNumber(0);
    }

    const toFilter = filters.filter((filter) => !!filter.value);
    setSelectedAhorroFilters(search, filters);

    await delay(200);
    onConfirmFilter(search, toFilter);
  };

  const handleClear = async () => {
    setSearch('');
    await setPageNumber(0);
    clearList();
    clearAhorroFilters();

    await delay(200);
    onConfirmFilter('', []);
  };

  const handleClearInput = async () => {
    setSearch('');
    setSelectedAhorroFilters('', filters);

    await delay(200);
    onConfirmFilter('', filters);
  };

  const checkIfFilterItemIsFilled = useCallback(
    (name) => {
      const filterVal = filters.filter((f) => f.name === name)[0].value;
      const isFilled = filterVal || filterVal !== '';

      return !!isFilled;
    },
    [filters]
  );

  const isGroupFilterFilled = checkIfFilterItemIsFilled('Grupo');
  const isSolicitudFilterFilled = checkIfFilterItemIsFilled('Solicitud');
  const isOrdenFilterFilled = checkIfFilterItemIsFilled('Orden');
  const isOrdenIndustrialFilterFilled = checkIfFilterItemIsFilled('Orden industrial');

  const popoverContent = (setAnchorEl) => {
    const fields1 = filters.slice(0, 3);
    const fields2 = filters.slice(3);
    const marcaValue = filters.filter((v) => v.name === 'Marca')[0].value;

    const CAN_FILTER =
      (!disableFields2 && hasMoreThanOneFilterApplied) || cuitOrOrdenFilled || isOrdenIndustrialFilterFilled;

    return (
      <div className="ahorro-filter-fields">
        <h6>Filtros</h6>

        <div className="ahorro-filter-fields-fields1">
          {fields1.map((opt, idx) => (
            <div key={opt.name + idx}>
              <p>{opt.name}</p>
              <TextFieldInput
                id={opt.name}
                label=""
                type="tel"
                savedTextContent={opt.value}
                onChange={(val) => handleFilters(val, opt.name)}
                style={{ height: '40px', marginTop: '4px' }}
                variant="outlined"
                inputProps={{ maxLength: FILTERS_LENGTH[opt.name] }}
              />
            </div>
          ))}

          <div></div>
        </div>
        <div className={`ahorro-filter-fields-fields2`}>
          {fields2.map((opt, idx) => (
            <div
              key={opt.name + idx}
              className={`${opt.name === 'Orden' && !isGroupFilterFilled && 'disabled-ahorro'} ${disableFields2 && opt.name !== 'Marca' && 'disabled'}`}
            >
              <p>{opt.name}</p>
              {opt.name === 'Marca' ? (
                <Selector
                  options={brands}
                  value={brands.filter((f) => f.value === marcaValue)}
                  closeMenuOnSelect={true}
                  defaultValue={null}
                  handleChangeSelect={({ value }) => handleFilters(value, 'Marca')}
                />
              ) : (
                <TextFieldInput
                  id={opt.name}
                  label=""
                  type="tel"
                  savedTextContent={opt.value}
                  onChange={(val) => handleFilters(val, opt.name)}
                  style={{ height: '40px', marginTop: '4px' }}
                  variant="outlined"
                  inputProps={{ maxLength: FILTERS_LENGTH[opt.name] }}
                  disabled={disableFields2 || (opt.name === 'Orden' && !isGroupFilterFilled)}
                />
              )}
            </div>
          ))}
        </div>

        <div className="ahorro-filter-fields-buttons">
          <button
            type="button"
            className="cleanAll-text"
            onClick={() => {
              handleClear();
              setAnchorEl(null);
            }}
          >
            {langValues.clearSelection}
          </button>
          <MaterialButton
            onClick={
              CAN_FILTER
                ? () => {
                    onApplyFilter();
                    setAnchorEl(null);
                  }
                : () => {}
            }
            buttonText={langValues.apply}
            type="primary"
            style={CAN_FILTER ? {} : { opacity: 0.6, cursor: 'default' }}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    const isMarcaSelected = filters.some(({ name, value }) => name === 'Marca' && value);

    if (isMarcaSelected && disableFields2) {
      setDisableFields2(false);
    }

    if (!isMarcaSelected && !disableFields2) {
      setDisableFields2(true);
    }
  }, [filters]);

  useEffect(() => {
    if (isOrdenFilterFilled && !isGroupFilterFilled) {
      setDisableFields2(true);
      handleFilters('', 'Orden');
    }
  }, [isGroupFilterFilled, isSolicitudFilterFilled]);

  useEffect(() => {
    const getBrands = async () => {
      const { data } = await RequestRepository.get('brands');
      const formatData =
        data && data.length > 0
          ? data.map(({ brandCode, brandDescription }) => ({ label: brandDescription, value: brandCode }))
          : [];

      setBrands(formatData);
    };

    getBrands();
  }, []);

  return (
    <div className="ordersFiltersButtons ahorro">
      <FilterAccordionAhorro popoverContent={popoverContent} handleClear={handleClear} />
      <SearchInputAhorro
        setSearch={setSearch}
        search={search}
        handleSearch={onApplyFilter}
        handleClear={handleClearInput}
      />
    </div>
  );
};

OrderAhorroFilters.propTypes = {
  clearList: PropTypes.func,
  setSelectedAhorroFilters: PropTypes.func,
  selectedAhorroFilters: PropTypes.array,
  clearAhorroFilters: PropTypes.func,
  setPageNumber: PropTypes.func,
  searchAhorroString: PropTypes.string,
};

export default OrderAhorroFilters;
