/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */

import RequestRepository from './RequestRepository';

class DashboardRepository {
  makeUrl(searchString, selected, dates) {
    const busca = (!searchString || searchString === '') ? '' : `&filter=${encodeURIComponent(searchString)}`;

    const {
      categories,
      status,
      pendencies,
      macroAreas,
      steps,
      brands,
      models,
      macroCategories,
      orderType,
      secondaryDealers,
      dealerId,
    } = selected;

    const {
      startDate,
      endDate,
      isUsingDateFilter,
    } = dates;

    const dealersList = dealerId ? dealerId.split(';') : [];

    const filter1 = (categories.length === 0) ? '' : categories.map(element => (`&clientCategory=${element}`)).join('');
    const filter2 = (status.length === 0) ? '' : status.map(element => (`&status=${element}`)).join('');
    const filter3 = (pendencies.length === 0) ? '' : pendencies.map(element => (`&pendency=${element}`)).join('');
    const filter4 = (macroAreas.length === 0) ? '' : macroAreas.map(element => (`&macroArea=${element}`)).join('');
    const filter5 = (brands.length === 0) ? '' : brands.map(element => (`&brand=${element}`)).join('');
    const filter6 = (models.length === 0) ? '' : models.map(element => (`&model=${element}`)).join('');
    const filter7 = (macroCategories.length === 0) ? '' : macroCategories.map(element => (`&clientMacroCategory=${element}`)).join('');
    const filter8 = (startDate && isUsingDateFilter) ? `&minDate=${startDate}` : '';
    const filter9 = (endDate && isUsingDateFilter) ? `&maxDate=${endDate}` : '';
    const filter11 = (orderType.length === 0) ? '' : orderType.map(element => (`&orderTypes=${element}`)).join('');
    const filter12 = (secondaryDealers.length === 0) ? '' : secondaryDealers.map(element => (`&dealerId=${element}`)).join('');
    const filter15 = (steps.length === 0) ? '' : steps.map(element => (`&steps=${element}`)).join('');
    const filter16 = (dealersList.length === 0) ? '' : dealersList.map(element => (`&dealerId=${element}`)).join('');
    const parameters = `${busca}${filter1}${filter2}${filter3}${filter4}${filter5}${filter6}${filter7}${filter8}${filter9}${filter11}${filter12}${filter15}${filter16}`;

    return parameters;
  }

  getBrand(searchString, selected, dates) {
    return RequestRepository.get(`dashboard/brands?${this.makeUrl(searchString, selected, dates)}`);
  }

  getClientMacroCategory(searchString, selected, dates) {
    return RequestRepository.get(`dashboard/clientmacrocategories?${this.makeUrl(searchString, selected, dates)}`);
  }

  getMacroArea(searchString, selected, dates) {
    return RequestRepository.get(`dashboard/macroareas?${this.makeUrl(searchString, selected, dates)}`);
  }

  getMacroAreaHistory(frequency) {
    return RequestRepository.get(`dashboard/macroareas/history?frequency=${frequency}`);
  }
}

export default DashboardRepository;
