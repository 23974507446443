import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'tss-react/mui';
import Tooltip from '@mui/material/Tooltip';
import colorVariables from 'Utils/Styles/colorVariables';
import { fontSmall2 } from 'Utils/Styles/fontVariables';

const styles = () => ({
  tooltip: {
    ...fontSmall2,
    backgroundColor: 'rgba(12, 28, 45, 0.8)',
    color: colorVariables.actionSecondaryColor,
    whiteSpace: 'break-spaces',
    textAlign: 'center',
    borderRadius: '10px',
  },
  arrow: {
    '&::before': {
      backgroundColor: colorVariables.textBlackColor,
      opacity: 0.8,
    },
  },
});

const StyledTooltip = withStyles(Tooltip, styles);

const CustomTooltip = ({ label, children, placement = 'top', arrow = true }) => (
  <StyledTooltip title={label} placement={placement} arrow={arrow}>
    {children}
  </StyledTooltip>
);

CustomTooltip.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.object.isRequired,
  placement: PropTypes.string,
  arrow: PropTypes.bool,
};

export default CustomTooltip;
