import RequestRepository from './RequestRepository';

class VisionConfigurationRepository {
  static async getConfigurations(role) {
    const url = `visionconfigurations/role/${role}`;
    return RequestRepository.get(url);
  }

  static async updateVisionConfigurations(body) {
    const url = 'visionconfigurations/change';
    const headers = {
      Accept: 'text/plain',
    };
    try {
      await RequestRepository.post(url, body, headers);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default VisionConfigurationRepository;
