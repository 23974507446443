import { connect } from 'react-redux';
import {
  changeSelectedOrder, loadList, clearList, loadTotal,
} from 'Infrastructure/Ducks/OrderManagementDuck';
import { setStepStatusFilter, setOrderSorting } from 'Infrastructure/Ducks/FiltersDuck';
import DelayManagementList from './DelayManagementList';

const mapStateToProps = (state) => {
  const { orderManagement, filtersDuck, modelVersion } = state;
  return { orderManagement, filtersDuck, models: modelVersion.models };
};

const mapDispatchToProps = dispatch => ({
  changeSelectedOrder: (order) => {
    dispatch(changeSelectedOrder(order));
  },
  loadList: (page, stepStatus, filters) => {
    dispatch(loadList(page, stepStatus, filters));
    if (page === 0) {
      dispatch(loadTotal(stepStatus, filters));
    }
  },
  setStepStatusFilter: (selected) => {
    dispatch(setStepStatusFilter(selected));
  },
  setOrderSorting: (sorting) => {
    dispatch(setOrderSorting(sorting));
  },
  clearList: () => {
    dispatch(clearList());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DelayManagementList);
