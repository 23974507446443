import { connect } from 'react-redux';
import { loadLastParametersModelsVersionsUpdate } from 'Infrastructure/Ducks/SystemUpdateDuck';
import UpdateDate from './UpdateDate';

const mapStateToProps = (state) => {
  const { lastParametersModelsVersionsUpdate } = state.systemUpdate;
  return { lastUpdateDate: lastParametersModelsVersionsUpdate };
};

const mapDispatchToProps = dispatch => ({
  loadLastUpdateDate: () => {
    dispatch(loadLastParametersModelsVersionsUpdate());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateDate);
