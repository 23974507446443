import React from 'react';
import PropTypes from 'prop-types';
import { es } from 'date-fns/locale/es';
import { ptBR } from 'date-fns/locale/pt-BR';
import { environment } from 'Utils/consts';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker as KeyboardDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import './DatePicker.scss';
import langValues from 'Config/i18n';

const DatePicker = ({ selectedDate, handleDateChange, label }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ENV_ENVIRONMENT === environment.BR ? ptBR : es}>
      <KeyboardDatePicker
        format="dd/MM/yyyy"
        label={label}
        value={new Date(selectedDate)}
        sx={{ marginTop: '16px' }}
        onChange={(e) => {
          console.log(e);
          handleDateChange(e);
        }}
        slotProps={{
          textField: (props) => ({
            inputProps: {
              ...props.inputProps,
              'aria-label': 'change date',
            },
            variant: 'standard',
          }),
        }}
        className="keyBoardDatePicker"
      />
    </LocalizationProvider>
  );
};

DatePicker.propTypes = {
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  label: PropTypes.string.isRequired,
  handleDateChange: PropTypes.func.isRequired,
};

export default DatePicker;
