import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Expansion from 'Components/Atoms/Expansion/Expansion';
import InputNumber from 'Components/Atoms/InputNumber/InputNumber';
import langValues from 'Config/i18n/index';
import { safeToCapitalizeAllFirstLetters, safeToUpperCase } from 'Utils/safeMethods';

const renderArrowIcon = (name, isSelected, setSelected, shouldRender) => {
  if (shouldRender) {
    return (
      <Expansion
        name={name}
        isSelected={isSelected}
        setSelected={setSelected}
      />
    );
  }
  return null;
};

const ListParametersSteps = (
  {
    listItems,
    currentSteps,
    isEditingList,
  },
) => listItems.map((item) => {
  const isSelected = !!item.children;

  const renderInputDays = (
    number,
    isEdited,
    handleInputChanging,
    modelCode,
    versionCode,
    stepId,
  ) => {
    const safeNumber = number !== null ? number : '';

    return (
      <InputNumber
        value={safeNumber}
        onChange={value => handleInputChanging(
          modelCode,
          versionCode,
          stepId,
          value,
        )}
        isEdited={isEdited}
      />
    );
  };

  const renderTextDays = (text) => {
    if (text !== null) {
      if (text > 1) {
        return `${text} ${langValues.days}`;
      }
      return `${text} ${langValues.day}`;
    }
    return '-';
  };

  const getDestinationDays = (
    text,
    isEdited,
    handleInputChanging,
    modelCode,
    versionCode,
    stepId,
  ) => {
    if (isEditingList) {
      return renderInputDays(
        text,
        isEdited,
        handleInputChanging,
        modelCode,
        versionCode,
        stepId,
      );
    }
    return renderTextDays(text);
  };

  const renderSteps = (entry) => {
    const versionCode = entry.parent ? entry.code : '000';
    return currentSteps.map((element) => {
      let actualStep = entry.steps.find(singleStep => singleStep.step === element);
      if (!actualStep) {
        actualStep = {
          ...actualStep,
          parameter: null,
        };
      }
      return (
        <td key={`${element} ${entry.code} ${entry.parent}`} className="font-subtitle2">
          {getDestinationDays(
            actualStep.parameter,
            actualStep.isEdited,
            entry.handleInputChanging,
            item.code,
            versionCode,
            element,
          )}
        </td>
      );
    });
  };

  const handleNameNull = text => (text !== null ? safeToUpperCase(text) : '-');

  const renderVersions = () => {
    if (isSelected) {
      return (
        item.children.map(element => (
          <tr
            className="ListParametersSteps-item children"
            key={`${element.code} ${element.parent} children`}
          >
            <td className="font-subtitle2" />
            <td className="font-subtitle2 item-bolder parameters-steps-version-padding">{handleNameNull(element.name)}</td>
            <td className="font-subtitle2 item-bolder">{element.code}</td>
            {renderSteps(element)}
          </tr>
        ))
      );
    }
    return null;
  };

  return (
    <Fragment key={item.code}>
      <tr className="ListParametersSteps-item">
        <td className="font-subtitle2">
          {renderArrowIcon(item.code, isSelected, item.setCurrentActiveModel, item.hasChildren)}
        </td>
        <td className="font-subtitle2 item-bolder">
          {safeToCapitalizeAllFirstLetters(item.name)}
        </td>
        <td className="font-subtitle2 item-bolder">{item.code}</td>
        {renderSteps(item)}
      </tr>
      {renderVersions()}
    </Fragment>
  );
});

ListParametersSteps.propTypes = {
  listItems: PropTypes.array.isRequired,
};

export default ListParametersSteps;
