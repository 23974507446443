import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TabsSelector from 'Components/Molecules/TabsSelector/index';
import { CircularProgress } from 'Components/HelpComponents/ResponseModal/styles';
import { ReactComponent as EmptyState } from 'Common/Assets/empty_state.svg';
import { stepStatusId, environment } from 'Utils/consts';
import IndustrialOrdersSlider from 'Components/Molecules/IndustrialOrdersSlider';
import IndustrialOrderCard from 'Components/Molecules/IndustrialOrderCard';
import ResultResponse from 'Components/Atoms/ResultResponse';
import langValues from 'Config/i18n/index';
import ToggleEnvironment from 'Components/Atoms/ToggleEnvironment';

import './IndustrialOrdersList.scss';

const MIN_NUMBER_OF_CARDS_IN_CAROUSEL = 4;

const IndustrialOrdersList = ({
  industrialOrdersList,
  changeSelectedIndustrialOrder,
  currentActiveTab = 'all',
  changeActiveTab,
  requestSuccess = false,
  requestLoading,
}) => {
  const isFirstUpdate = useRef(true);

  const getFilteredIndustrialOrdersList = () => {
    if (!currentActiveTab || currentActiveTab === stepStatusId.allStatus) {
      return industrialOrdersList;
    }
    return industrialOrdersList.filter((item) => item.statusId === currentActiveTab);
  };

  const filteredList = getFilteredIndustrialOrdersList();

  const selectFirstIndustrialOrder = () => {
    if (filteredList.length > 0) {
      changeSelectedIndustrialOrder(filteredList[0]);
    } else {
      changeSelectedIndustrialOrder(null);
    }
  };

  useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
    } else if (requestSuccess) {
      selectFirstIndustrialOrder();
    }
  }, [requestSuccess, currentActiveTab]);

  const mapIndustrialOrderToCard = () =>
    filteredList.map((industrialOrder) => (
      <IndustrialOrderCard key={industrialOrder.industrialOrderId} industrialOrder={industrialOrder} />
    ));

  const industrialOrdersListSection = () => {
    const industrialCards = mapIndustrialOrderToCard();
    let className = 'industrialOrderList';
    let content = null;

    if (requestLoading) {
      className += '-centered';
      content = <CircularProgress />;
    } else if (filteredList.length === 0) {
      className += '-centered';
      content = <ResultResponse Icon={EmptyState} title={langValues.noResults} subtitle={langValues.noOrdersFound} />;
    } else if (filteredList.length <= MIN_NUMBER_OF_CARDS_IN_CAROUSEL) {
      className += '-simpleList';
      content = industrialCards;
    } else {
      className += '-slider';
      content = <IndustrialOrdersSlider industrialCards={industrialCards} currentActiveTab={currentActiveTab} />;
    }
    return <div className={className}>{content}</div>;
  };

  const tabsList = [
    { id: stepStatusId.allStatus, label: langValues.all },
    { id: stepStatusId.late, label: langValues.statusTabLabel.late },
    { id: stepStatusId.inRisk, label: langValues.statusTabLabel.lateRisk },
    { id: stepStatusId.onTime, label: langValues.statusTabLabel.onTime },
    { id: stepStatusId.delivered, label: langValues.statusTabLabel.delivered },
  ];

  return (
    <ToggleEnvironment env={environment.BR}>
      <article className="industrialOrderList">
        <TabsSelector changeActiveTab={changeActiveTab} currentActiveTab={currentActiveTab} tabsList={tabsList} />
        <section className="industrialOrderList-list">{industrialOrdersListSection()}</section>
      </article>
    </ToggleEnvironment>
  );
};

IndustrialOrdersList.propTypes = {
  industrialOrdersList: PropTypes.array.isRequired,
  changeSelectedIndustrialOrder: PropTypes.func.isRequired,
  changeActiveTab: PropTypes.func.isRequired,
  currentActiveTab: PropTypes.string,
  requestSuccess: PropTypes.bool,
  requestLoading: PropTypes.bool,
};

export default IndustrialOrdersList;
