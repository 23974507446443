/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageHeader from 'Components/Shared/PageHeader';
import SystemUpdate from 'Components/Atoms/SystemUpdate';
import ListPedidos, { listPedidosSkeleton } from 'Components/OrderListComponents/ListPedidos/ListPedidos';
import { ReactComponent as EmptyState } from 'Common/Assets/empty_state.svg';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import { roleConsts, trackedEvents, trackedPages, trackedButtons } from 'Utils/consts';
import { Dealer, User } from 'Common/Helpers/AuthHelper';
import { safeToUpperCase, safeToLowerCase } from 'Utils/safeMethods';
import HelpIconTooltip from 'Components/Shared/HelpIconTooltip/index';
import LabelWithHelpIcon from 'Components/OrderListComponents/LabelWithHelpIcon';
import List from 'Components/Shared/List/List';
import Dot from 'Components/Shared/Dot/Dot';
import SnackBar from 'Components/Shared/SnackBar/index';
import { trackEvent } from 'Utils/analytics';
import TabsSelector from 'Components/Molecules/TabsSelector/index';
import OrderFilters from 'Components/Organisms/OrderFilters';
import OrderAhorroFilters from 'Components/Organisms/OrderAhorroFilters/index';
import langValues from 'Config/i18n/index';
import SnackBarExport from 'Components/Atoms/SnackBarExport/SnackBarExport';
import { Button } from './styles';
import './Pedidos.scss';
/*eslint-disable*/

const tabsList = [
  { id: 0, label: langValues.all, value: null },
  { id: 1, label: langValues.invoiced, value: true },
  { id: 2, label: langValues.notInvoiced, value: false },
];

class Pedidos extends Component {
  constructor(props) {
    super(props);
    trackEvent(trackedEvents.pageView, { page: trackedPages.pedidos });

    const { filtersDuck, list, clearFilters, clearList } = this.props;
    const { appliedFilters, appliedDates } = filtersDuck;
    if (!appliedFilters || !appliedDates) {
      clearFilters();
    }
    if (!list.data) {
      clearList();
    }
  }

  renderToolTipDiv = (upperText, lowerText) => (
    <div className="pedidosToolTipDiv">
      <span className="lightText">{upperText}</span>
      <span className="boldText">{lowerText}</span>
    </div>
  );

  clearList = () => {
    const { clearList } = this.props;
    clearList();
  };

  loadPageData = async () => {
    this.clearList();
    this.loadOrders(0);
  };

  loadModels = async () => {
    const { loadModels } = this.props;
    await loadModels();
  };

  applyFilter = () => {
    this.loadPageData();
  };

  applyAhorroFilter = (searchString, ahorroFilters) => {
    const { list } = this.props;
    const { page } = list;

    this.clearList();
    this.loadOrders(page, searchString, ahorroFilters);
  };

  componentDidMount = () => {
    const { setIsToolTipOpen, filtersDuck } = this.props;
    const { isToolTipOpen } = filtersDuck;

    if (isToolTipOpen) {
      setIsToolTipOpen();
    }
    this.loadPageData();
    this.loadModels();
  };

  componentDidUpdate = (previousProps) => {
    const { filtersDuck } = this.props;
    const { isBilledFilter } = filtersDuck;

    if (previousProps.filtersDuck.isBilledFilter !== isBilledFilter) {
      this.applyFilter();
    }
  };

  renderList = (data, header) => {
    const { models, isPlanAhorro } = this.props;
    return (
      <>
        <section className="pedidos-list">
          <div className="pedidos-filters">
            {isPlanAhorro ? (
              <OrderAhorroFilters onConfirmFilter={this.applyAhorroFilter} />
            ) : (
              <OrderFilters
                onConfirmFilter={this.applyFilter}
                models={models}
                pageName={trackedPages.pedidos}
                filterPlacement="bottom-start"
                renderSearch
              />
            )}
          </div>
          {data.length > 0 ? (
            <List
              header={header}
              childProps={{
                dealer: User.hasProfile(roleConsts.dealers),
                data,
                isPlanAhorro,
              }}
              Type={ListPedidos}
            />
          ) : null}
        </section>
        {data.length === 0 ? (
          <div id="emptyState">
            <div id="emptyState-content">
              <EmptyState />
              <span id="emptyState-text">{langValues.noOrdersFound}</span>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  renderSkeleton = (numeroItens) => listPedidosSkeleton(numeroItens);

  renderListOrders = (data, loading, error) => {
    if (loading) return this.renderSkeleton(10);
    if (error) return <SnackBar message={langValues.telaPedidosLabels.errorCarregarLista} />;

    const { isPlanAhorro } = this.props;
    const header = isPlanAhorro ? 'listPedidosHeadersTitleAhorro' : 'listPedidosHeadersTitle';

    return this.renderList(
      data,
      langValues.telaPedidosLabels[header].map((item, index) => {
        if (langValues.telaPedidosLabels.listPedidosHeadersTooltip[index]) {
          return (
            <LabelWithHelpIcon
              text={item}
              Icon={() => (
                <HelpIconTooltip
                  tooltipText={this.renderToolTipDiv(
                    langValues.telaPedidosLabels.listPedidosHeadersTooltip[index].upperText,
                    langValues.telaPedidosLabels.listPedidosHeadersTooltip[index].lowerText
                  )}
                />
              )}
            />
          );
        }
        return safeToUpperCase(item);
      })
    );
  };

  renderTotalVisibleOrders = (totalVisible, total, hasStatusFilter) =>
    hasStatusFilter && !User.hasProfile(roleConsts.admin) ? null : (
      <div className="totalVisibleOrdersDiv">
        <span className="totalVisibleOrdersSpan">{langValues.totalVisibleOrders(totalVisible, total)}</span>
      </div>
    );

  renderLoadMore = (total, pageNumber, pageSize, loadingPage, error) => {
    const { filtersDuck, list } = this.props;
    const { page } = list;
    const { appliedFilters } = filtersDuck;
    const hasStatusFilter = appliedFilters.status.length > 0;
    if (total && total > 0) {
      const totalVisible = (pageNumber + 1) * pageSize;
      const visible = totalVisible < total;
      if (!error) {
        if (visible) {
          return (
            <div className="carregarMaisDiv">
              {loadingPage ? (
                this.renderSkeleton(4)
              ) : (
                <div className="carregarMaisButtonDiv">
                  <Button
                    variant="outlined"
                    sx={{ color: '#000000de', borderColor: '#0000003b', ':hover': { borderColor: '#0000003b' } }}
                    onClick={() => {
                      this.loadOrders(page + 1);
                      trackEvent(trackedEvents.buttonClick, { button: trackedButtons.loadMore });
                    }}
                  >
                    {langValues.telaPedidosLabels.botaoCarregarMais}
                  </Button>
                </div>
              )}
              {this.renderTotalVisibleOrders(totalVisible, total, hasStatusFilter)}
            </div>
          );
        }
      }
    }
    return null;
  };

  loadOrders = async (page) => {
    const { loadList, filtersDuck, setPageNumber, isPlanAhorro } = this.props;

    const {
      appliedFilters,
      appliedDates,
      isBilledFilter,
      dealerId,
      searchOrdersString,
      searchAhorroString,
      selectedAhorroFilters,
    } = filtersDuck;
    const isBilledFilterSelected = tabsList.filter((tab) => tab.id === isBilledFilter);
    const isBilledFilterValue =
      isBilledFilterSelected.length > 0 && !isPlanAhorro ? isBilledFilterSelected[0].value : null;
    const allFilters = { ...appliedFilters, dealerId };
    await setPageNumber(page);

    const getNewPdaEndpoint = filtersDuck.isBilledFilter === 0 || filtersDuck.isBilledFilter === 3;

    return await loadList(
      page,
      searchOrdersString,
      allFilters,
      appliedDates,
      isBilledFilterValue,
      isPlanAhorro,
      searchAhorroString,
      selectedAhorroFilters,
      getNewPdaEndpoint
    );
  };

  renderBilledFilterTabs = (error) => {
    const { filtersDuck } = this.props;
    const currentActiveTab = filtersDuck.isBilledFilter;
    const { changeActiveTab } = this.props;

    const CAN_SEE_PDA = User.canSeePDA();

    if (error) return null;

    const tabs = CAN_SEE_PDA ? [...tabsList, { id: 3, label: 'PLAN DE AHORRO', value: false }] : tabsList;

    return <TabsSelector changeActiveTab={changeActiveTab} currentActiveTab={currentActiveTab} tabsList={tabs} />;
  };

  renderContent = (data, loading, loadingPage, error) => (
    <div className={`content ${ENV_ENVIRONMENT}`}>
      {this.renderBilledFilterTabs(error)}
      {this.renderListOrders(data.listOrder, loading, error)}
      {this.renderLoadMore(data.total, data.pageNumber, data.pageSize, loadingPage, error)}
    </div>
  );

  handleDownloadReport = async (fileType) => {
    const { exportList, filtersDuck, isPlanAhorro, loadExportCountListPDA } = this.props;
    const { searchOrdersString, filtersExportPDA } = filtersDuck;
    const isBilledFilterSelected = tabsList.filter((tab) => tab.id === filtersDuck.isBilledFilter);
    const isBilledFilterValue = isBilledFilterSelected.length > 0 ? isBilledFilterSelected[0].value : null;
    const filters = { ...filtersDuck, isBilledFilter: isBilledFilterValue };

    trackEvent(trackedEvents.dataExport, { format: fileType });

    if (isPlanAhorro) {
      const data = await loadExportCountListPDA(filtersExportPDA);
      if (!!data) {
        exportList(searchOrdersString, filters, fileType, isPlanAhorro, filtersExportPDA);
      }
      return;
    }

    exportList(searchOrdersString, filters, fileType, isPlanAhorro, filtersExportPDA);
  };

  render = () => {
    const { list, setExportErrorFalse } = this.props;
    const { data, loading, loadingPage, loadingExport, error, exportError, exportCountError } = list;
    let titulo = <>{langValues.telaPedidosLabels.screenTitle}</>;
    if (User.hasProfile(roleConsts.dealers)) {
      titulo = (
        <>
          <div id="titleText">{titulo}</div>
          <div id="dotWraper">
            <Dot color="black" size="14px" />
          </div>
          <div id="dealerNameDiv" title={Dealer.get().dealerName}>
            <span>{safeToLowerCase(Dealer.get().dealerName)}</span>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="pedidos">
          <PageHeader
            title={titulo}
            subtitle={<SystemUpdate />}
            filterClick={this.openDrawer}
            filterApplied={false}
            shouldRenderExportButton
            loadingExport={loadingExport}
            handleDownloadReport={this.handleDownloadReport}
            onApplyFilter={this.applyFilter}
            shouldRenderDelayedOrders={
              User.get().userProfile.includes(roleConsts.admin) || User.get().userProfile.includes(roleConsts.dealers)
            }
          />
          {this.renderContent(data, loading, loadingPage, error)}
          <SnackBarExport
            open={exportError || exportCountError}
            onClose={setExportErrorFalse}
            message={exportCountError}
          />
        </div>
      </>
    );
  };
}

Pedidos.propTypes = {
  list: PropTypes.object.isRequired,
  models: PropTypes.object.isRequired,
  loadList: PropTypes.func.isRequired,
  exportList: PropTypes.func.isRequired,
  clearList: PropTypes.func.isRequired,
  filtersDuck: PropTypes.object.isRequired,
  clearFilters: PropTypes.func.isRequired,
  changeActiveTab: PropTypes.func.isRequired,
  setExportErrorFalse: PropTypes.func.isRequired,
  setIsToolTipOpen: PropTypes.func.isRequired,
  loadExportCountListPDA: PropTypes.func.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  isPlanAhorro: PropTypes.bool.isRequired,
};

export default Pedidos;
