import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Expand } from 'Common/Assets/expand.svg';
import { ReactComponent as Retract } from 'Common/Assets/retract.svg';
import './Expansion.scss';

const Expansion = ({ name, isSelected, setSelected }) => {
  if (isSelected) {
    return (
      <Retract
        onClick={() => setSelected(null)}
        className="expandIcon"
      />
    );
  }
  return (
    <Expand
      onClick={() => setSelected(name)}
      className="expandIcon"
    />
  );
};

Expansion.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  isSelected: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default Expansion;
