/* eslint-disable */
import ParametersService from '../Services/ParametersService';

export const DUCK_NAME = 'historicLogsOrders';

export const INITIAL_STATE = () => ({
  orders: [],
  total: null,
  selectedOrder: null,
  loading: false,
  loadingPage: false,
  error: 'INITIAL_STATE null error',
});

const LOAD_LIST_STARTED = `${DUCK_NAME}/LOAD_LIST_STARTED`;
const LOAD_LIST_PAGE_STARTED = `${DUCK_NAME}/LOAD_LIST_PAGE_STARTED`;
const LOAD_LIST_SUCCEED = `${DUCK_NAME}/LOAD_LIST_SUCCEED`;
const LOAD_LIST_PAGE_SUCCEED = `${DUCK_NAME}/LOAD_LIST_PAGE_SUCCEED`;
const LOAD_LIST_FAILED = `${DUCK_NAME}/LOAD_LIST_FAILED`;
const LOAD_TOTAL_RESULT = `${DUCK_NAME}/LOAD_TOTAL_RESULT`;
const CHANGE_SELECTED_ORDER = `${DUCK_NAME}/CHANGE_SELECTED_ORDER`;
const CLEAR_LIST = `${DUCK_NAME}/CLEAR_LIST`;

const CLEAR_DUCK = `${DUCK_NAME}/CLEAR_DUCK`;

const loadListStarted = () => ({ type: LOAD_LIST_STARTED });
const loadListPageStarted = () => ({ type: LOAD_LIST_PAGE_STARTED });
const loadListSucceed = data => ({ type: LOAD_LIST_SUCCEED, data });
const loadListFailed = error => ({ type: LOAD_LIST_FAILED, error });
const loadListPageSucceed = data => ({ type: LOAD_LIST_PAGE_SUCCEED, data });
const loadTotalResult = data => ({ type: LOAD_TOTAL_RESULT, data });
const clearListAction = () => ({ type: CLEAR_LIST });


export const loadTotal = (stepStatus, filters) => async (dispatch) => {
  const service = new ParametersService();
  const data = await service.getTotal(stepStatus, filters);
  dispatch(loadTotalResult(data));
};

export const clearList = () => async (dispatch) => {
  dispatch(clearListAction());
};

export const loadList = (page, stepStatus, filters) => async (dispatch) => {
  if (page === 0) {
    dispatch(loadListStarted());
  } else {
    dispatch(loadListPageStarted());
  }
  try {
    const service = new ParametersService();
    const data = await service.getList(page, stepStatus, filters);
    if (page === 0) {
      dispatch(loadListSucceed(data));
    } else {
      dispatch(loadListPageSucceed(data));
    }
  } catch (err) {
    dispatch(loadListFailed(err));
  }
};

export const changeSelectedOrder = newSelectedOrder => async (dispatch) => {
  dispatch({ type: CHANGE_SELECTED_ORDER, newSelectedOrder });
};

const reducer = (state = INITIAL_STATE(), action) => {
  switch (action.type) {
    case LOAD_LIST_STARTED:
      return {
        ...INITIAL_STATE(),
        loading: true,
      };
    case LOAD_LIST_PAGE_STARTED:
      return {
        ...state,
        loadingPage: true,
        error: 'LOAD_LIST_PAGE_STARTED null error',
      };
    case LOAD_LIST_SUCCEED:
      console.log('action:', action);
      return {
        ...state,
        orders: action.data,
        selectedOrder: action.data && action.data.length > 0 ? action.data[0] : null,
        loading: false,
        loadingPage: false,
        error: 'LOAD_LIST_SUCCEED null error',
      };
    case LOAD_LIST_PAGE_SUCCEED:
      return {
        ...state,
        orders: [...state.orders, ...action.data],
        loadingPage: false,
        error: 'LOAD_LIST_PAGE_SUCCEED null error',
      };
    case LOAD_LIST_FAILED:
      return {
        ...state,
        orders: [],
        loading: false,
        loadingPage: false,
        error: action.error,
      };
    case LOAD_TOTAL_RESULT:
      return {
        ...state,
        total: action.data,
      };
    case CHANGE_SELECTED_ORDER:
      return {
        ...state,
        selectedOrder: action.newSelectedOrder,
      };
    case CLEAR_DUCK:
      return {
        ...INITIAL_STATE(),
      };
    default:
      return state;
  }
};

export default reducer;
