import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import DelayNotificationsItem from 'Components/Atoms/DelayNotificationsItem/DelayNotificationsItem';
import TabsSelector from 'Components/Molecules/TabsSelector/TabsSelector';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';
import { ReactComponent as CloseIcon } from 'Common/Assets/close-cross.svg';
import { ReactComponent as EmptyState } from 'Common/Assets/empty_state.svg';
import langValues from 'Config/i18n/index';

import './DelayNotificationsDrawer.scss';

const tabsList = [
  { id: 0, label: langValues.all, value: ['inRisk', 'late'] },
  { id: 1, label: langValues.labelsRequestResume.late, value: ['late'] },
  { id: 2, label: langValues.labelsRequestResume.risk, value: ['inRisk'] },
];

const DelayNotificationsDrawer = ({
  drawerOpen,
  onClose,
  macroAreaNotifications = [],
  loadMacroAreaNotifications,
  newMacroAreaNotificationIds = [],
  setMacroAreaModalOpen,
  getMacroAreas,
  loadingMacroAreas,
  setFilterNotifications,
}) => {
  const [currentActiveTab, changeActiveTab] = useState(0);
  const [hasNoMacroAreas, setHasNoMacroAreas] = useState(false);

  const fetchMacroAreas = async () => {
    const macroAreas = await getMacroAreas();
    if (!macroAreas || macroAreas.length === 0) {
      setHasNoMacroAreas(true);
    } else {
      setHasNoMacroAreas(false);
    }
  };

  useEffect(() => {
    loadMacroAreaNotifications();
    if (drawerOpen) fetchMacroAreas();
  }, [drawerOpen]);

  const tabValue = tabsList.find((tab) => tab.id === currentActiveTab).value;

  const mappedNotifications = macroAreaNotifications.map((notification) => {
    const newNotification = { ...notification };
    if (newMacroAreaNotificationIds.includes(notification.id)) {
      newNotification.isNew = true;
    } else {
      newNotification.isNew = false;
    }
    return newNotification;
  });

  const filteredNotifications = mappedNotifications.filter((notification) => tabValue.includes(notification.type));

  let drawerContent;

  if (filteredNotifications.length === 0) {
    if (loadingMacroAreas) {
      drawerContent = null;
    } else if (hasNoMacroAreas) {
      drawerContent = (
        <div className="delayNotifications-emptyState">
          <EmptyState />
          <h5>Nenhuma macro-área selecionada.</h5>
          <p className="font-subtitle2">
            Para ativar o recebimento de notificações, é necessário selecionar pelo menos uma macro-área.
          </p>
          <MaterialButton
            onClick={() => {
              onClose();
              setMacroAreaModalOpen();
            }}
            sx={{ color: '#000000de', borderColor: '#0000003b', ':hover': { borderColor: '#0000003b' } }}
            buttonText="Configurar notificações"
            variant="outlined"
          />
        </div>
      );
    } else {
      drawerContent = (
        <div className="delayNotifications-emptyState">
          <EmptyState />
          <h5>Nenhuma notificação.</h5>
        </div>
      );
    }
  } else {
    drawerContent = (
      <div className="delayNotifications-notificationsList">
        {filteredNotifications.map((notification) => (
          <DelayNotificationsItem
            key={notification.id}
            notification={notification}
            setFilterNotifications={setFilterNotifications}
            onClose={onClose}
          />
        ))}
      </div>
    );
  }

  return (
    <Drawer anchor="left" open={drawerOpen} onClose={onClose}>
      <section className="delayNotifications-drawerContent">
        <div className="delayNotifications-header">
          <h5>Notificações</h5>
          <CloseIcon onClick={onClose} />
        </div>
        <TabsSelector changeActiveTab={changeActiveTab} currentActiveTab={currentActiveTab} tabsList={tabsList} />
        {drawerContent}
      </section>
    </Drawer>
  );
};

DelayNotificationsDrawer.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  loadingMacroAreas: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  loadMacroAreaNotifications: PropTypes.func.isRequired,
  setMacroAreaModalOpen: PropTypes.func.isRequired,
  setFilterNotifications: PropTypes.func.isRequired,
  getMacroAreas: PropTypes.func.isRequired,
  macroAreaNotifications: PropTypes.array,
  newMacroAreaNotificationIds: PropTypes.array,
};

export default DelayNotificationsDrawer;
