import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import langValues from 'Config/i18n/index';
import { ReactComponent as AlertTriangle } from 'Common/Assets/alert-triangle.svg';
import { ReactComponent as Expand } from 'Common/Assets/expand.svg';
import { ReactComponent as Retract } from 'Common/Assets/retract.svg';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import './UnregisteredDataModal.scss';
import AccordionList from 'Components/Atoms/AccordionList/AccordionList';

const UnregisteredDataModal = ({ parametersDuck, loadUnregisteredData, loadUnregisteredVersions }) => {
  useEffect(() => {
    loadUnregisteredData();
    loadUnregisteredVersions();
  }, []);

  const [unregisteredData, setUnregisteredData] = useState(parametersDuck.unregisteredData || []);
  const [unregisteredVersions, setUnregisteredVersions] = useState(parametersDuck.unregisteredVersions || []);

  useEffect(() => {
    setUnregisteredData(parametersDuck.unregisteredData);
  }, [parametersDuck.unregisteredData]);

  useEffect(() => {
    setUnregisteredVersions(parametersDuck.unregisteredVersions);
  }, [parametersDuck.unregisteredVersions]);

  const [anchorEl, setAnchorEl] = useState(null);
  const divRef = useRef();

  const renderArrowIcon = () => {
    if (anchorEl) {
      return <Retract onClick={() => setAnchorEl(null)} className="expandArrowIcon" />;
    }
    return <Expand onClick={() => setAnchorEl(divRef.current)} className="expandArrowIcon" />;
  };

  const renderDataWarning = () => {
    const unregVersions = unregisteredVersions.length;
    const unregDealers = unregisteredData.length;
    let text;
    if ((unregDealers === 0 && unregVersions === 1) || (unregDealers === 1 && unregVersions === 0)) {
      text = langValues.unregisteredDataModal.warningTitleSingular;
    } else if (unregDealers >= 1 || unregVersions >= 1) {
      text = langValues.unregisteredDataModal.warningTitlePlural;
    } else {
      return (text = langValues.unregisteredDataModal.warningTitlePlural);
    }

    return (
      <div ref={divRef} className="unregisteredDataWarning">
        <p>
          <AlertTriangle /> {text} {renderArrowIcon()}{' '}
        </p>
      </div>
    );
  };

  const getGroupVersions = () => {
    const groupVersions = [];
    parametersDuck.unregisteredVersions.forEach((item) => {
      const hasModel = groupVersions.some((element) => element.modelId === item.modelId);
      const version = {
        versionId: item.versionId,
        versionName: item.versionName,
      };
      if (hasModel) {
        const index = groupVersions.findIndex((element) => element.modelId === item.modelId);
        const model = groupVersions[index];
        const hasVersion = model.children.some((element) => element.versionId === item.versionId);
        if (!hasVersion) {
          groupVersions[index].children.push(version);
        }
      } else {
        const model = {
          modelId: item.modelId,
          modelName: item.modelName,
          children: [],
        };
        model.children.push(version);
        groupVersions.push(model);
      }
    });

    return groupVersions;
  };

  const renderList = () => {
    const groupVersions = getGroupVersions();

    const renderListVersions = (item) =>
      item.children.map((element, key) => (
        <span key={`version-${key}`} className="version-content">
          <b>{element.versionId}</b> - {element.versionName}
          {key === item.children.length - 1 ? '.' : '; '}
        </span>
      ));

    const renderListModel = () =>
      groupVersions.map((item, index) => (
        <div key={`model-${index}`} className="unregisteredData-modelVersions">
          <p className="model-content">
            {item.modelId} - {item.modelName}
          </p>
          {renderListVersions(item)}
        </div>
      ));

    const renderListDealers = () =>
      unregisteredData.map((item, index) => (
        <div key={`dealer-${index}`} className="unregisteredData-modelVersions">
          <p className="model-content">
            {' '}
            Dealer id - <b>{item}</b> {langValues.unregisteredDataModal.unregisteredDealers}
          </p>
        </div>
      ));

    return (
      <div className="unregisteredData-content">
        {unregisteredData.length > 0 && (
          <AccordionList onLoad={renderListDealers} origin={langValues.unregisteredDataModal.unregisteredDealers} />
        )}
        {groupVersions.length > 0 && (
          <AccordionList onLoad={renderListModel} origin={langValues.unregisteredDataModal.unregisteredModels} />
        )}
      </div>
    );
  };

  const renderPopperContent = () => (
    <section className="unregisteredData-section">
      <div className="unregisteredData-header">
        <h6>{langValues.unregisteredDataModal.subtitle}</h6>
      </div>
      {renderList()}
    </section>
  );

  const renderPopper = () => (
    <Popper
      id="unregisteredData-popper"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="bottom-end"
      disablePortal={false}
      modifiers={[
        {
          name: 'flip',
          options: {
            fallbackPlacements: [],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            altBoundary: true,
          },
        },
      ]}
    >
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <article className="unregisteredData-article">{renderPopperContent()}</article>
      </ClickAwayListener>
    </Popper>
  );
  return (
    <div className="unregisteredDataModal">
      {(unregisteredData.length > 0 || unregisteredVersions.length > 0) && renderDataWarning()}
      {(unregisteredData.length > 0 || unregisteredVersions.length > 0) && renderPopper()}
    </div>
  );
};

UnregisteredDataModal.propTypes = {
  loadUnregisteredData: PropTypes.func.isRequired,
  loadUnregisteredVersions: PropTypes.func.isRequired,
  parametersDuck: PropTypes.object.isRequired,
};

export default UnregisteredDataModal;
