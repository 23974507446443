import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import NetworkConfig from 'Config/NetworkConfig';

import * as serviceWorker from 'Config/ServiceWorker';
import configureStore from 'Config/Store';

import App from './App';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ErrorBoundary from './App/PublicPages/ErrorBoundary';

const theme = createTheme({
  typography: {
    fontFamily: '"Helvetica Neue", sans-serif',
    // fontSize: '14px',
    // color: '#5E5E5E',
  },
});

const { persistor, store } = configureStore();
export const defaultStore = store;

const main = (target) => {
  NetworkConfig.setupInterceptors(store);
  const root = createRoot(target);
  root.render(
    <Provider store={store}>
      <PersistGate loading={<div>loading...</div>} persistor={persistor}>
        <ErrorBoundary>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  );
};

main(document.getElementById('root'));

serviceWorker.unregister();
