import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TagOrderStatus from 'Components/Atoms/TagOrderStatus/index';
import { safeToLowerCase } from 'Utils/safeMethods';
import BrandLogo from 'Components/Shared/BrandLogo/index';
import { getDateAsNumbers } from 'Utils/dateUtils';

import { ReactComponent as PenDocIcon } from 'Common/Assets/penDocIcon.svg';
import { ReactComponent as PenFinIcon } from 'Common/Assets/commercialPen.svg';
import { ReactComponent as PendConcIcon } from 'Common/Assets/pendConc.svg';
import { ReactComponent as PenBlockIcon } from 'Common/Assets/bloqueio_de_qualidade.svg';
import { ReactComponent as TrocaPedidos } from 'Common/Assets/trocaPedidos.svg';

import NomeCliente from 'Components/ComercialDetailsScreenComponents/PedidoComercial/DadosCliente/NomeCliente/index';
import CpfText from 'Components/Formatters/CpfText/index';
import CnpjText from 'Components/Formatters/CnpjText/index';
import { orderListConsts, environment, roleConsts } from 'Utils/consts';
import OrderInformationItem from 'Components/Atoms/OrderInformationItem/index';
import { Dealer, User } from 'Common/Helpers/AuthHelper';
import langValues from 'Config/i18n/index';
import ClientCategoryIcon from 'Components/ComercialDetailsScreenComponents/PedidoComercial/DadosCliente/ClientCategoryIcon/index';
import DealerInformation from 'Components/Atoms/DealerInformation/DealerInformation';
import './OrderInformationCardResume.scss';
import { renderSwapModal } from 'Components/ComercialDetailsScreenComponents/PedidoComercial/DadosCliente/Pendencia/Pendencia';
import ToggleEnvironment from 'Components/Atoms/ToggleEnvironment';
import CustomTooltip from 'Components/Atoms/Tooltip/CustomTooltip';

const OrderInformationCardResume = ({ order, industrialOrder, isPDA, pdaContract }) => {
  const [openModal, setOpenModal] = useState(false);
  const changeOpenModalState = () => {
    setOpenModal(!openModal);
  };

  const renderClientName = () => (
    <NomeCliente clientName={safeToLowerCase((order && order.clientName) || (pdaContract && pdaContract.fullName))} />
  );

  const renderClientDocument = () => {
    if (order && order.personTypeId === orderListConsts.naturalPerson) {
      return <CpfText cpf={order.clientDocument} />;
    }
    return <CnpjText cnpj={(order && order.clientDocument) || (pdaContract && pdaContract.cuit.toString())} />;
  };

  const modelAndManufactureYear = (modelYear, manufactureYear) => {
    const modelYearText = modelYear ? safeToLowerCase(modelYear.toString()) : ' - ';
    const manufactureYearText = manufactureYear ? safeToLowerCase(manufactureYear.toString()) : ' - ';
    return `${manufactureYearText}/${modelYearText}`;
  };

  const renderModelInformation = () => (
    <>
      <OrderInformationItem
        text={safeToLowerCase(industrialOrder.modelName)}
        label={langValues.orderInformation.model}
      />
      <OrderInformationItem
        text={modelAndManufactureYear(industrialOrder.modelYear, industrialOrder.manufactureYear)}
        label={langValues.orderInformation.modelAndManufactureYear}
      />
    </>
  );

  const renderDealerInformation = () => {
    if (Dealer && Dealer.get()) {
      return (
        <>
          <OrderInformationItem
            text={order.salesCommission ? `${order.salesCommission.toFixed(2)}%` : '-'}
            label={langValues.orderInformation.commission}
          />
          <OrderInformationItem
            text={order.discount ? `${order.discount.toFixed(2)}%` : '-'}
            label={langValues.orderInformation.discount}
          />
        </>
      );
    }
    return (
      <DealerInformation
        dealerTypeLabel={langValues.labelsPedidoComercial.concessionariaPedido}
        dealerId={order.sellerDealer.id}
        dealerName={order.sellerDealer.name}
        dealerCity={order.sellerDealer.county}
        dealerState={order.sellerDealer.uf}
      />
    );
  };

  const renderIcon = (iconCondition, Icon, iconDescription, renderModal) => (
    <>
      {iconCondition ? (
        <div className="orderInformationBlock-dealer">
          <Icon
            style={renderModal ? { cursor: 'pointer' } : {}}
            onClick={() => (renderModal ? changeOpenModalState() : null)}
          />
          <span>{iconDescription}</span>
        </div>
      ) : null}
    </>
  );

  const renderPendoc = () => (
    <>
      {order.clientDocumentPendencyFlag && order.causalCode ? (
        <CustomTooltip label={order.causalReason} placement="bottom">
          <div className="orderPendocTooltip">
            {renderIcon(
              order.clientDocumentPendencyFlag,
              PenDocIcon,
              `${langValues.orderInformationBlock.clientDocumentPendencyFlag} ${order.causalCode}`
            )}
          </div>
        </CustomTooltip>
      ) : (
        renderIcon(
          order.clientDocumentPendencyFlag,
          PenDocIcon,
          langValues.orderInformationBlock.clientDocumentPendencyFlag
        )
      )}
    </>
  );

  const renderClientInformation = () => (
    <>
      <div className="orderInformationCard-client">
        <ToggleEnvironment env={environment.BR}>
          <ClientCategoryIcon
            clientCategory={order && order.clientCategoryId}
            orderType={(order && order.orderType) || pdaContract.orderType}
            renderTooltip
          />
        </ToggleEnvironment>
        <div className="orderInformationCard-clientName">
          {renderClientName()}
          <span className="font-small2 orderInformationCard-clientDocument">{renderClientDocument()}</span>
        </div>
      </div>
      {!isPDA && (
        <div className="orderInformationCard-alerts">
          {renderSwapModal(openModal, changeOpenModalState, order)}
          {renderIcon(
            order.clientFinancialPendencyFlag,
            PenFinIcon,
            langValues.orderInformationBlock.clientFinancialPendencyFlag
          )}
          {renderPendoc()}
          {renderIcon(order.dealerPendencyFlag, PendConcIcon, langValues.orderInformationBlock.dealerPendency)}
          {renderIcon(order.blockPendencyFlag, PenBlockIcon)}
          {renderIcon(order.orderSwapFlag, TrocaPedidos, langValues.orderInformationBlock.orderSwapFlag)}
        </div>
      )}
    </>
  );

  const renderCardTitle = () => {
    const isClient = User.hasProfile(roleConsts.client);
    const orderTypeText = isClient
      ? langValues.order
      : langValues.orderInformation.typeLabel[safeToLowerCase(order && order.orderType)];

    let brandId;

    if (order && order.orderBrandModelVersionList[0]) {
      brandId = parseInt(order.orderBrandModelVersionList[0].brandId, 10);
    }
    return (
      <>
        {(!isPDA || order) && <TagOrderStatus statusId={order.statusId} lateDays={order.maximumDelay} />}
        <div>
          <h3>{`${safeToLowerCase((pdaContract && pdaContract.orderType) || orderTypeText)} #${(order && order.orderId) || pdaContract.solicitud}`}</h3>
          <BrandLogo brandId={brandId} />
        </div>
        <span className="font-small2">{`${langValues.orderInformation.createdOn} ${getDateAsNumbers((order && order.orderDate) || pdaContract.createdAt)}`}</span>
      </>
    );
  };
  return (
    <button
      type="button"
      style={{
        display: 'contents',
        textAlign: 'left',
        userSelect: 'text',
        cursor: 'auto',
      }}
      onClick={(event) => event.stopPropagation()}
    >
      <section className={`orderInformationCard ${isPDA && 'pda'} ${!order && 'no-order'}`}>
        <div className="orderInformationCard-title">{renderCardTitle()}</div>
        {!isPDA && order && <div className="orderInformationCard-client-wrapper">{renderClientInformation()}</div>}
        {isPDA ? (
          <div className={`orderInformationCard-dealer pda ${!order && 'no-order'}`}>
            <div>
              <div className="orderInformationCard-client-wrapper">{renderClientInformation()}</div>
            </div>

            <div className="grupo-orden">
              <span className="font-subtitle2">
                {pdaContract.groupCode || '--'} / {pdaContract.order || '--'}
              </span>
              <span className="font-small3 orderInformationItem-label">Grupo y Orden</span>
            </div>

            {!order && (
              <div className="marca">
                <span className="font-subtitle2">{pdaContract.brandName || '--'}</span>
                <span className="font-small3 orderInformationItem-label">Marca</span>
              </div>
            )}

            {pdaContract.solicitud && !order && (
              <div className="solicitud">
                <span className="font-subtitle2">{pdaContract.solicitud || '--'}</span>
                <span className="font-small3 orderInformationItem-label">Solicitud</span>
              </div>
            )}
          </div>
        ) : (
          <ToggleEnvironment env={environment.BR}>
            <div className="orderInformationCard-dealer">{renderDealerInformation()}</div>
          </ToggleEnvironment>
        )}
        {isPDA && order && (
          <div className="orderInformationCard-model pda">
            {order && <div className="-model">{renderModelInformation()}</div>}

            <div className="marca-solicitud">
              <div className="marca">
                <span className="font-subtitle2">{pdaContract.brandName || '--'}</span>
                <span className="font-small3 orderInformationItem-label">Marca</span>
              </div>
              <div className="solicitud">
                <span className="font-subtitle2">{pdaContract.solicitud || '--'}</span>
                <span className="font-small3 orderInformationItem-label">Solicitud</span>
              </div>
            </div>
          </div>
        )}
        {!isPDA && <div className="orderInformationCard-model">{renderModelInformation()}</div>}
      </section>
    </button>
  );
};

OrderInformationCardResume.propTypes = {
  order: PropTypes.object.isRequired,
  pdaContract: PropTypes.object,
  industrialOrder: PropTypes.object.isRequired,
  isPDA: PropTypes.bool,
};

export default OrderInformationCardResume;
