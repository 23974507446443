import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactImageFallback from 'react-image-fallback';
import BaseModal from 'Components/Atoms/BaseModal/BaseModal';
import { ReactComponent as CloseIcon } from 'Common/Assets/close-cross.svg';
import { ReactComponent as Arrow } from 'Common/Assets/arrowWithElipse.svg';
import { User } from 'Common/Helpers/AuthHelper';
import parse from 'html-react-parser';
import { roleConsts } from 'Utils/consts';
import FeatureDefaultImage from 'Common/Assets/novidades.svg';
import LoadingGif from 'Common/Assets/spin.gif';
import './FeaturesModal.scss';

const FeaturesModal = ({ features, loadFeatures, setFeaturesViewed }) => {
  useEffect(() => {
    if (!User.hasProfile(roleConsts.employees)) {
      loadFeatures();
    }
  }, []);
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);

  const numberFeatures = features.length;

  if (numberFeatures === 0) return null;

  const currentFeature = features[currentFeatureIndex];

  const dots = features.map((_feature, index) => {
    const isCurrent = index === currentFeatureIndex;
    return <div className={`featuresModal-dotsItem ${isCurrent ? 'current' : ''}`} />;
  });

  const goToNextFeature = () => {
    let nextFeatureIndex = currentFeatureIndex + 1;
    const isLastFeature = currentFeatureIndex === numberFeatures - 1;
    if (isLastFeature) {
      nextFeatureIndex = 0;
    }
    setCurrentFeatureIndex(nextFeatureIndex);
  };

  const returnToPreviousFeature = () => {
    let previousFeatureIndex = currentFeatureIndex - 1;
    const isFirstFeature = currentFeatureIndex === 0;
    if (isFirstFeature) {
      previousFeatureIndex = numberFeatures - 1;
    }
    setCurrentFeatureIndex(previousFeatureIndex);
  };

  const LoadingImage = () => (
    <div className="featuresModal-loading-gif">
      <img src={LoadingGif} alt="Carregando" />
    </div>
  );

  const parseHtmlString = (inputString) => parse(inputString);

  const renderFeatureText = (releaseDetailedText) => {
    if (!releaseDetailedText) {
      return null;
    }
    return parseHtmlString(releaseDetailedText);
  };

  return (
    <BaseModal open>
      <article className="featuresModal">
        <CloseIcon className="featuresModal-closeIcon" onClick={setFeaturesViewed} />
        <section className="featuresModal-imageWrapper">
          {numberFeatures === 1 ? null : (
            <>
              <Arrow className="featuresModal-arrow" onClick={returnToPreviousFeature} />
              <Arrow className="featuresModal-arrow rotate" onClick={goToNextFeature} />
            </>
          )}
          <ReactImageFallback
            src={currentFeature.imageUrl}
            fallbackImage={FeatureDefaultImage}
            initialImage={<LoadingImage />}
            alt={currentFeature.category}
            className="featuresModal-image"
          />
        </section>
        <section className="featuresModal-content">
          <span className="font-body2 featuresModal-category">{currentFeature.category}</span>
          <h5 className="featuresModal-title">{currentFeature.title}</h5>
          <span className="font-body2 featuresModal-subtitle">{currentFeature.caption}</span>
          <span className="font-body2 featuresModal-text">{renderFeatureText(currentFeature.text)}</span>
          {numberFeatures === 1 ? null : <div className="featuresModal-dots">{dots}</div>}
        </section>
      </article>
    </BaseModal>
  );
};

FeaturesModal.propTypes = {
  features: PropTypes.array.isRequired,
  loadFeatures: PropTypes.func.isRequired,
  setFeaturesViewed: PropTypes.func.isRequired,
};

export default FeaturesModal;
