import langValues from 'Config/i18n/index';

export const parseDateUTCtoLocalTimezone = (date) => {
  const timeZoneOffserMinutes = date.getTimezoneOffset();
  const timeZoneOffsetMilliseconds = timeZoneOffserMinutes * 60 * 1000;
  const dateMilliseconds = date.getTime();
  return new Date(dateMilliseconds - timeZoneOffsetMilliseconds);
};

export const parseValoresData = (valor) => {
  if (valor !== 0 && !valor) return '--';
  const zero = (valor < 10) ? '0' : '';
  return `${zero}${valor}`;
};

export const parseMonth = month => (
  langValues.fullMonths[month] || '--'
);

export const parseShortMonth = month => (
  langValues.months[month] || '--'
);

export const getDateAsText = date => (
  `${parseValoresData(date.getDate())} de
  ${parseMonth(date.getMonth())} de
  ${parseValoresData(date.getFullYear())} ${langValues.as}
  ${parseValoresData(date.getHours())}:${parseValoresData(date.getMinutes())}`
);

export const getDateAsMediumText = date => (
  `${parseValoresData(date.getDate())} de
  ${parseMonth(date.getMonth())},
  ${parseValoresData(date.getFullYear())}`
);

export const getFullDateAsShortText = date => (
  `${parseValoresData(date.getDate())}/${parseValoresData(date.getMonth() + 1)}/${parseValoresData(date.getFullYear())} ${langValues.as} ${parseValoresData(date.getHours())}:${parseValoresData(date.getMinutes())}`
);

export const getDateAsShortText = date => (
  `${parseValoresData(date.getDate())}
  ${parseShortMonth(date.getMonth())}
  ${parseValoresData(date.getFullYear())}`
);

export const getTimestampWithoutHours = (date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return (newDate.getTime());
};

export const formatData = (dateText, separetedWithBars) => {
  const data = dateText;
  const day = parseValoresData(data.getDate());
  const month = separetedWithBars ? `/${parseValoresData(data.getMonth() + 1)}/` : langValues.months[data.getMonth()];
  const year = data.getFullYear();
  return separetedWithBars ? `${day}${month}${year}` : `${day} ${month} ${year}`;
};

export const formatDateWithoutYear = (date) => {
  const data = date;
  const day = parseValoresData(data.getDate());
  const month = parseValoresData(data.getMonth() + 1);
  return `${day}/${month}`;
};

export const separateDateWithDash = (date) => {
  const day = parseValoresData(date.getDate());
  const month = parseValoresData(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export const getDateAsNumbers = (date, separetedWithBars) => {
  const dateText = (date) ? new Date(date) : null;
  return dateText ? formatData(dateText, separetedWithBars) : '--/--/----';
};

export const formatShortData = (dateText, separetedWithBars) => {
  const data = dateText;
  const day = parseValoresData(data.getDate());
  const month = separetedWithBars ? `/${parseValoresData(data.getMonth() + 1)}/` : langValues.months[data.getMonth()];
  const year = data.getFullYear().toString().substr(-2);
  return separetedWithBars ? `${day}${month}${year}` : `${day} ${month} ${year}`;
};

export const getShortDateAsNumbers = (date, separetedWithBars) => {
  const dateText = (date) ? new Date(date) : null;
  return dateText ? formatShortData(dateText, separetedWithBars) : '--/--/--';
};

export default null;
