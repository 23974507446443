import { connect } from 'react-redux';
import {
  setSelectedDates, setAppliedDates, clearDateFilters, clearDelayDateFilters,
  setDelaySelectedDates, setDelayAppliedDates,
} from 'Infrastructure/Ducks/FiltersDuck';
import FilterDates from './FilterDates';

const mapStateToProps = (state) => {
  const { filtersDuck } = state;
  const {
    selectedDates, selectedDelayDates, appliedDates, appliedDelayDates,
  } = filtersDuck;
  return {
    currentDuckState: {
      selectedDates, selectedDelayDates, appliedDates, appliedDelayDates,
    },
  };
};

const mapDispatchToProps = dispatch => ({
  setSelectedDates: (selected) => {
    dispatch(setSelectedDates(selected));
  },
  setDelaySelectedDates: (selected) => {
    dispatch(setDelaySelectedDates(selected));
  },
  setAppliedDates: (selected) => {
    dispatch(setAppliedDates(selected));
  },
  setDelayAppliedDates: (selected) => {
    dispatch(setDelayAppliedDates(selected));
  },
  clearDateFilters: () => {
    dispatch(clearDateFilters());
  },
  clearDelayDateFilters: () => {
    dispatch(clearDelayDateFilters());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterDates);
