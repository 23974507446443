import { connect } from 'react-redux';
import { updateViewedMacroAreaNotifications } from 'Infrastructure/Ducks/NotificationDuck';
import DelayNotificationsButton from './DelayNotificationsButton';

const mapStateToProps = (state) => {
  const { newMacroAreaNotificationIds } = state.notificationDuck;
  return { newMacroAreaNotificationIds };
};

const mapDispatchToProps = dispatch => ({
  updateViewedMacroAreaNotifications: () => dispatch(updateViewedMacroAreaNotifications()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DelayNotificationsButton);
