import { connect } from 'react-redux';
import {
  loadFeatures, setFeaturesViewed,
} from 'Infrastructure/Ducks/FeaturesDuck';
import FeaturesModal from './FeaturesModal';

const mapStateToProps = (state) => {
  const { features } = state.featuresDuck;
  return { features };
};

const mapDispatchToProps = dispatch => ({
  loadFeatures: () => dispatch(loadFeatures()),
  setFeaturesViewed: () => dispatch(setFeaturesViewed()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeaturesModal);
