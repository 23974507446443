import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as AlertIcon } from 'Common/Assets/alert-triangle.svg';

import { trackedEvents, trackedButtons } from 'Utils/consts';
import { trackEvent } from 'Utils/analytics';

import NotificationProvider from '../NotificationProvider';
import './NotificationButton.scss';

const NotificationButton = ({
  loadNotifications,
  currentNotifications = [],
  viewedNotificationIds,
  updateViewedNotifications,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef();

  useEffect(() => {
    loadNotifications();
  }, []);

  useEffect(() => {
    currentNotifications.forEach((notification) => {
      if (viewedNotificationIds && !viewedNotificationIds.includes(notification.id)) {
        setAnchorEl(anchorRef.current);
      }
    });
  }, [currentNotifications]);

  const handleButtonClick = () => {
    trackEvent(trackedEvents.buttonClick, { button: trackedButtons.openNotification });
    if (currentNotifications.length > 0) {
      setAnchorEl(anchorRef.current);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    updateViewedNotifications();
  };

  if (!currentNotifications || currentNotifications.length === 0) {
    return null;
  }

  const notificationType = currentNotifications[0].type;

  return (
    <>
      <button
        className={`notificationButton ${notificationType}`}
        type="button"
        onClick={() => handleButtonClick()}
        ref={anchorRef}
      >
        <AlertIcon />
      </button>
      <NotificationProvider anchorEl={anchorEl} handleClose={handleClose} notifications={currentNotifications} />
    </>
  );
};

NotificationButton.propTypes = {
  loadNotifications: PropTypes.func.isRequired,
  updateViewedNotifications: PropTypes.func.isRequired,
  currentNotifications: PropTypes.array,
  viewedNotificationIds: PropTypes.array,
};

export default NotificationButton;
