import React from 'react';
import PropTypes from 'prop-types';
import './HistoricLogsOrdersListItem.scss';

const itemRender = (id) => <td className="delayManagementList-orderId">{id}</td>;

const itemRenderData = (date) => {
  let data1 = date.split('-').reverse().join('/');
  return <td className="delayManagementList-orderId">{data1}</td>;
};

const HistoricLogsOrderListItem = ({ listItems, selectedId, changeSelectedOrder, loading }) => {
  if (listItems === null) {
    return <tr></tr>;
  } else {
    return listItems.map((item) => {
      const isSelected = item.industrialOrderId === selectedId;
      return (
        <tr>
          {itemRender(item.industrialOrderId)}
          {itemRender(item.comercialOrderId)}
          {itemRender(item.mvs)}
          {itemRender(item.chassi)}
          {itemRenderData(item.date)}
          {itemRender(item.orderStatus)}
        </tr>
      );
    });
  }
};

HistoricLogsOrderListItem.propTypes = {
  listItems: PropTypes.array,
};

export default HistoricLogsOrderListItem;
