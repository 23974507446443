import RequestRepository from 'Infrastructure/Repositories/RequestRepository';

class UserService {
  putMacroAreas = async (macroAreas) => {
    const parameters = 'macroarea';
    const body = macroAreas;
    try {
      const { result } = await RequestRepository.put(parameters, body);
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  getMacroAreas = async () => {
    const parameters = 'macroarea';
    try {
      const { data } = await RequestRepository.get(parameters);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default UserService;
