import moment from 'moment';
import { ordersConsts } from 'Utils/consts';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';

import { User } from 'Common/Helpers/AuthHelper';
import ListPedidosService from '../Services/ListPedidosService';

export const DUCK_NAME = 'list';

export const INITIAL_STATE = {
  lastUpdateDateTime: null,
  loading: false,
  loadingResume: false,
  loadingPage: false,
  success: false,
  error: null,
  page: 0,
  data: {
    listOrder: [],
    resume: null,
    resumeHistory: null,
  },
  exportError: false,
  exportCountError: null,
  loadingExport: false,
};

export const LOAD_LIST_STARTED = `${DUCK_NAME}/LOAD_LIST_STARTED`;
export const LOAD_LIST_PAGE_STARTED = `${DUCK_NAME}/LOAD_LIST_PAGE_STARTED`;
export const LOAD_LIST_SUCCEED = `${DUCK_NAME}/LOAD_LIST_SUCCEED`;
export const LOAD_LIST_FAILED = `${DUCK_NAME}/LOAD_LIST_FAILED`;
export const CLEAR_LIST = `${DUCK_NAME}/CLEAR_LIST`;
export const LOAD_LIST_PAGE_SUCCEED = `${DUCK_NAME}/LOAD_LIST_PAGE_SUCCEED`;

export const LOAD_RESUME_STARTED = `${DUCK_NAME}/LOAD_RESUME_STARTED`;
export const LOAD_RESUME_SUCCEED = `${DUCK_NAME}/LOAD_RESUME_SUCCEED`;
export const LOAD_RESUME_FAILED = `${DUCK_NAME}/LOAD_RESUME_FAILED`;

export const EXPORT_LIST_STARTED = `${DUCK_NAME}/EXPORT_LIST_STARTED`;
export const EXPORT_LIST_SUCCEED = `${DUCK_NAME}/EXPORT_LIST_SUCCEED`;
export const EXPORT_LIST_FAILED = `${DUCK_NAME}/EXPORT_LIST_FAILED`;

export const EXPORT_COUNT_LIST_PDA_STARTED = `${DUCK_NAME}/EXPORT_COUNT_LIST_PDA_STARTED`;
export const EXPORT_COUNT_LIST_PDA_SUCCEED = `${DUCK_NAME}/EXPORT_COUNT_LIST_PDA_SUCCEED`;
export const EXPORT_COUNT_LIST_PDA_FAILED = `${DUCK_NAME}/EXPORT_COUNT_LIST_PDA_FAILED`;

export const SET_EXPORT_ERROR = `${DUCK_NAME}/SET_EXPORT_ERROR`;
export const SET_PAGE_NUMBER = `${DUCK_NAME}/SET_PAGE_NUMBER`;

export const LOAD_RESUME_HISTORY_STARTED = `${DUCK_NAME}/LOAD_RESUME_HISTORY_STARTED`;
export const LOAD_RESUME_HISTORY_SUCCEED = `${DUCK_NAME}/LOAD_RESUME_HISTORY_SUCCEED`;
export const LOAD_RESUME_HISTORY_FAILED = `${DUCK_NAME}/LOAD_RESUME_HISTORY_FAILED`;
export const loadResumeHistoryStarted = () => ({ type: LOAD_RESUME_HISTORY_STARTED });
export const loadResumeHistorySucceed = data => ({ type: LOAD_RESUME_HISTORY_SUCCEED, data });
export const loadResumeHistoryFailed = error => ({ type: LOAD_RESUME_HISTORY_FAILED, error });

export const loadListStarted = () => ({ type: LOAD_LIST_STARTED });
export const loadListPageStarted = () => ({ type: LOAD_LIST_PAGE_STARTED });
export const loadListSucceed = data => ({ type: LOAD_LIST_SUCCEED, data });
export const loadListFailed = error => ({ type: LOAD_LIST_FAILED, error });
export const clearListAction = () => ({ type: CLEAR_LIST });
export const loadListPageSucceed = data => ({ type: LOAD_LIST_PAGE_SUCCEED, data });

export const loadResumeStarted = () => ({ type: LOAD_RESUME_STARTED });
export const loadResumeSucceed = data => ({ type: LOAD_RESUME_SUCCEED, data });
export const loadResumeFailed = error => ({ type: LOAD_RESUME_FAILED, error });

export const exportListStarted = () => ({ type: EXPORT_LIST_STARTED });
export const exportListSucceed = data => ({ type: EXPORT_LIST_SUCCEED, data });
export const exportListFailed = error => ({ type: EXPORT_LIST_FAILED, error });

export const exportCountListPdaStarted = () => ({ type: EXPORT_COUNT_LIST_PDA_STARTED });
export const exportCountListPdaSucceed = () => ({ type: EXPORT_COUNT_LIST_PDA_SUCCEED });
export const exportCountListPdaFailed = error => ({ type: EXPORT_COUNT_LIST_PDA_FAILED, error });

export const setExportError = value => ({ type: SET_EXPORT_ERROR, value });
export const setPageNumber = value => ({ type: SET_PAGE_NUMBER, value });

export const loadExportCountListPDA = filtersExportPDA => async (dispatch) => {
  dispatch(exportCountListPdaStarted());
  try {
    const listService = new ListPedidosService();
    await listService.getExportCountPda(filtersExportPDA);
    dispatch(exportCountListPdaSucceed());

    return true;
  } catch (err) {
    dispatch(exportCountListPdaFailed(err.response.data || 'reduce group export fields'));
    return false;
  }
};

export const loadResumeHistory = (monthBegin, monthEnd) => async (dispatch) => {
  dispatch(loadResumeHistoryStarted());
  let data = {};
  try {
    const listService = new ListPedidosService();
    data = await listService.getResumeHistory(monthBegin, monthEnd);
    dispatch(loadResumeHistorySucceed(data));
  } catch (err) {
    dispatch(loadResumeHistoryFailed(err));
  }
};

export const loadList = (
  page,
  searchString,
  selected,
  dates,
  isBilledFilter,
  isPlanAhorro,
  searchStringAhorro,
  ahorroFilters,
  getNewPdaEndpoint,
) => async (dispatch) => {
  if (page === 0) dispatch(loadListStarted());
  else dispatch(loadListPageStarted());
  let data = {};
  try {
    const listService = new ListPedidosService();
    data = await listService.getList(page, searchString, selected, dates, isBilledFilter, isPlanAhorro, searchStringAhorro, ahorroFilters, getNewPdaEndpoint);
    const hasStatusFilter = selected.status.length > 0;
    const objectToGet = User.canSeePDA() ? 'listOrdersPDA' : 'listOrder';
    if (hasStatusFilter && data[objectToGet].length < 10 && data[objectToGet].length < data.total) {
      let newPage = page + 1;
      // eslint-disable-next-line max-len
      for (newPage; newPage < data.total / ordersConsts.pageSize && data[objectToGet].length < 10; newPage += 1) {
        // eslint-disable-next-line
        const newData = await listService.getList(newPage, searchString, selected, dates, isBilledFilter, isPlanAhorro, searchStringAhorro, ahorroFilters, getNewPdaEndpoint);
        if (
          newData[objectToGet] !== undefined
          && newData[objectToGet] !== null
          && newData[objectToGet].length > 0
        ) {
          const newData2 = User.canSeePDA() ? [...data.listOrdersPDA, ...newData.listOrdersPDA] : [...data.listOrder, ...newData.listOrder];
          data.listOrder = newData2;
        }
      }
      dispatch(setPageNumber(newPage));
    }
    if (User.canSeePDA()) {
      data.listOrder = data.listOrdersPDA;
    }
    if (page === 0) dispatch(loadListSucceed(data));
    else dispatch(loadListPageSucceed(data));
  } catch (err) {
    dispatch(loadListFailed(err));
  }
};

export const loadResume = (searchString, selected, dates) => async (dispatch) => {
  dispatch(loadResumeStarted());
  let data = {};

  try {
    const listService = new ListPedidosService();
    data = await listService.getResume(searchString, selected, dates);
    dispatch(loadResumeSucceed(data));
  } catch (err) {
    dispatch(loadResumeFailed(err));
  }
};

const exportAndDispatch = exportFunc => async (dispatch) => {
  dispatch(exportListStarted());
  try {
    const result = await exportFunc();
    dispatch(exportListSucceed(result));
  } catch (err) {
    dispatch(exportListFailed(err));
  }
};

export const exportList = (searchString, filters, fileType, isPlanAhorro, filtersExportPDA) => {
  const listService = new ListPedidosService();
  return exportAndDispatch(() => listService.export(searchString, filters, fileType, isPlanAhorro, filtersExportPDA));
};

export const exportLateOrdersList = (searchString, filters, fileType) => {
  const listService = new ListPedidosService();
  return exportAndDispatch(() => listService.exportLateOrders(searchString, filters, fileType));
};

export const clearList = () => async (dispatch) => {
  dispatch(clearListAction());
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_LIST_STARTED:
      return {
        ...state,
        lastUpdateDateTime: moment().toISOString(),
        loadingPage: false,
        loading: true,
      };
    case LOAD_RESUME_STARTED:
      return {
        ...state,
        lastUpdateDateTime: moment().toISOString(),
        loadingResume: true,
      };
    case LOAD_RESUME_SUCCEED:
      return {
        ...state,
        loadingResume: false,
        data: {
          ...state.data,
          resume: { ...action.data },
          resumeHistory: state.data.resumeHistory,
          listOrder: [
            ...state.data.listOrder,
          ],
        },
      };
    case LOAD_LIST_SUCCEED:
    case LOAD_LIST_PAGE_SUCCEED:
      return {
        ...state,
        lastUpdateDateTime: moment().toISOString(),
        loading: false,
        loadingPage: false,
        success: true,
        error: null,
        data: {
          ...action.data,
          resume: { ...state.data.resume },
          resumeHistory: state.data.resumeHistory,
          listOrder: [
            ...state.data.listOrder,
            ...action.data.listOrder],
        },
      };
    case LOAD_RESUME_FAILED:
    case LOAD_LIST_FAILED:
      return {
        ...state,
        lastUpdateDateTime: moment().toISOString(),
        loading: false,
        loadingPage: false,
        error: action.error,
      };
    case LOAD_LIST_PAGE_STARTED:
      return {
        ...state,
        lastUpdateDateTime: moment().toISOString(),
        loadingPage: true,
        error: action.error,
      };
    case EXPORT_LIST_STARTED:
      return {
        ...state,
        loadingExport: true,
      };
    case EXPORT_LIST_SUCCEED:
      return {
        ...state,
        loadingExport: false,
        exportError: false,
      };
    case EXPORT_LIST_FAILED:
      return {
        ...state,
        loadingExport: false,
        exportError: true,
      };
    case EXPORT_COUNT_LIST_PDA_STARTED:
      return {
        ...state,
        loadingExport: true,
        exportCountError: null,
      };
    case EXPORT_COUNT_LIST_PDA_SUCCEED:
      return {
        ...state,
        loadingExport: false,
        exportCountError: null,
      };
    case EXPORT_COUNT_LIST_PDA_FAILED:
      return {
        ...state,
        loadingExport: false,
        exportCountError: action.error,
      };
    case SET_EXPORT_ERROR:
      return {
        ...state,
        exportError: action.value,
        exportCountError: null,
      };
    case SET_PAGE_NUMBER:
      return {
        ...state,
        page: action.value,
      };
    case LOAD_RESUME_HISTORY_STARTED:
      return {
        ...state,
      };
    case LOAD_RESUME_HISTORY_SUCCEED:
      return {
        ...state,
        data: {
          ...state.data,
          resumeHistory: action.data,
        },
      };
    case LOAD_RESUME_HISTORY_FAILED:
      return {
        ...state,
        data: {
          ...state.data,
          resumeHistory: null,
        },
        error: action.error,
      };
    case CLEAR_LIST:
      return {
        ...state,
        data: {
          listOrder: [],
          resume: null,
          resumeHistory: null,
        },
        loadingExport: false,
        exportCountError: null,
      };
    default:
      return state;
  }
};

export default reducer;
