
import { connect } from 'react-redux';

import { loadAuth } from 'Infrastructure/Ducks/Auth';
import UserEntry from './UserEntry';

const mapDispatchToProps = dispatch => ({
  loadAuth: (data) => {
    dispatch(loadAuth(data));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(UserEntry);
