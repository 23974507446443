import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import langValues from 'Config/i18n/index';
import { ReactComponent as AlertTriangle } from 'Common/Assets/alert-triangle.svg';
import { ReactComponent as Expand } from 'Common/Assets/expand.svg';
import { ReactComponent as Retract } from 'Common/Assets/retract.svg';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import './delayedOrders.scss';

const DelayedOrders = ({
  delayedOrders,
  delayedOrdersTotal,
  setDealerIdFilter,
  handleConfirm,
  loadDelayedOrders,
  loadDelayedOrdersTotal,
  setStepFilter,
}) => {
  useEffect(() => {
    loadDelayedOrders();
    loadDelayedOrdersTotal();
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const divRef = useRef();

  const openPopper = (param) => {
    setAnchorEl(param);
  };

  const renderArrowIcon = () => {
    if (anchorEl) {
      return <Retract onClick={() => setAnchorEl(null)} className="expandArrowIcon" />;
    }
    return <Expand onClick={() => openPopper(divRef.current)} className="expandArrowIcon" />;
  };

  const renderVersionsWarning = () => {
    const size = delayedOrdersTotal;
    let text;
    if (size > 1) {
      text = langValues.delayedOrdersComponent.warningTitlePlural;
    } else if (size === 1) {
      text = langValues.delayedOrdersComponent.warningTitleSingular;
    } else {
      return null;
    }

    return (
      <div ref={divRef} className="delayedOrdersWarning">
        <AlertTriangle />
        <p>
          {size}
          {text}
        </p>
        {renderArrowIcon()}
      </div>
    );
  };

  const applyFilter = async (param) => {
    setAnchorEl(null);
    setDealerIdFilter(param);
    await setStepFilter();
    handleConfirm();
  };

  const renderList = () => {
    const renderListVersions = () =>
      delayedOrders.map((delayedOrder, index) => (
        <div className="delayedOrders-dealer" key={index}>
          <div>
            <button type="button" className="dealer-name" onClick={() => applyFilter(delayedOrder.dealerCode)}>
              <b>
                {delayedOrder.dealerCode} - {delayedOrder.dealerName}
              </b>
            </button>
          </div>
          <span className="quantity-delayed">
            <b>
              {langValues.delayedOrdersComponent.subTitle}: {delayedOrder.quantityDelayed}
            </b>
          </span>
        </div>
      ));
    return <div className="delayedOrders-content">{renderListVersions()}</div>;
  };

  const renderPopperContent = () => (
    <section className="delayedOrders-section">
      <div className="delayedOrders-header">
        <h6>{langValues.delayedOrdersComponent.title}</h6>
      </div>
      {delayedOrders ? renderList() : ''}
    </section>
  );

  const renderPopper = () => (
    <Popper
      id="delayedOrders-popper"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="bottom-end"
      disablePortal={false}
      modifiers={[
        {
          name: 'flip',
          options: {
            fallbackPlacements: [],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            altBoundary: true,
          },
        },
      ]}
    >
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <article className="delayedOrders-article">{renderPopperContent()}</article>
      </ClickAwayListener>
    </Popper>
  );

  return (
    <div className="delayedOrders">
      {renderVersionsWarning()}
      {renderPopper()}
    </div>
  );
};

DelayedOrders.propTypes = {
  delayedOrders: PropTypes.array,
  delayedOrdersTotal: PropTypes.number.isRequired,
  setDealerIdFilter: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  loadDelayedOrders: PropTypes.func.isRequired,
  loadDelayedOrdersTotal: PropTypes.func.isRequired,
  setStepFilter: PropTypes.func.isRequired,
};

export default DelayedOrders;
