import { connect } from 'react-redux';
import {
  loadStepParameters,
  saveStepParameters,
  clearErrorSaveStepParameters,
  clearStepParameters,
  exportStep,
  clearStepParametersExport,
} from 'Infrastructure/Ducks/ParametersDuck';
import ParametersSteps from './ParametersSteps';

const mapStateToProps = (state) => {
  const { parametersDuck } = state;
  return { parametersDuck };
};

const mapDispatchToProps = (dispatch) => ({
  loadStepParameters: () => {
    dispatch(loadStepParameters());
  },
  saveStepParameters: (stepParameters) => dispatch(saveStepParameters(stepParameters)),
  clearErrorSaveStepParameters: () => {
    dispatch(clearErrorSaveStepParameters());
  },
  clearStepParameters: () => {
    dispatch(clearStepParameters());
  },
  exportStep: (fileType) => {
    dispatch(exportStep(fileType));
  },
  clearStepParametersExport: () => {
    dispatch(clearStepParametersExport());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ParametersSteps);
