/* eslint-disable */
import { connect } from 'react-redux';

import {
  changeSelectedOrder, loadList, clearList, loadTotal,
} from 'Infrastructure/Ducks/HistoricLogsOrdersDuck';
import { setStepStatusFilter, setOrderSorting } from 'Infrastructure/Ducks/FiltersDuck';
import HistoricLogsList from './HistoricLogsList';

const mapStateToProps = (state) => {
  const { historicLogsOrders, filtersDuck, modelVersion } = state;
  return { historicLogsOrders, filtersDuck, models: modelVersion.models };

};

const mapDispatchToProps = dispatch => ({
  changeSelectedOrder: (order) => {
    dispatch(changeSelectedOrder(order));
  },
  loadList: (page, stepStatus, filters) => {
    dispatch(loadList(page, stepStatus, filters));
    if (page === 0) {
      dispatch(loadTotal(stepStatus, filters));
    }
  },
  setStepStatusFilter: (selected) => {
    dispatch(setStepStatusFilter(selected));
  },
  setOrderSorting: (sorting) => {
    dispatch(setOrderSorting(sorting));
  },
  clearList: () => {
    dispatch(clearList());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HistoricLogsList);
