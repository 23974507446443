import { connect } from 'react-redux';
import { setSearchOrdersString, setSearchDelayTrackingString, setIsToolTipOpen } from 'Infrastructure/Ducks/FiltersDuck';
import FilterSearch from './FilterSearch';

const mapStateToProps = (state) => {
  const { filtersDuck } = state;
  const { searchOrdersString, searchDelayTrackingString, isToolTipOpen } = filtersDuck;
  return {
    filters: { searchOrdersString, searchDelayTrackingString, isToolTipOpen },
  };
};

const mapDispatchToProps = dispatch => ({
  setSearchOrdersString: (selected) => {
    dispatch(setSearchOrdersString(selected));
  },
  setSearchDelayTrackingString: (selected) => {
    dispatch(setSearchDelayTrackingString(selected));
  },
  setIsToolTipOpen: (isToolTipOpen) => {
    dispatch(setIsToolTipOpen(isToolTipOpen));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterSearch);
