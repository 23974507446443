import React, { Component } from 'react';
import langValues from 'Config/i18n/index';
import { ReactComponent as PackageIcon } from 'Common/Assets/package.svg';
// import TouchAppRoundedIcon from '@mui/icons-material/TouchAppRounded';
import { trackEvent } from 'Utils/analytics';
import { trackedEvents, environment, trackedButtons } from 'Utils/consts';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import DashboardOrders from 'Components/Organisms/DashboardOrders';
import DashboardAccesses from 'Components/Organisms/DashboardAccesses';
import SubMenu from 'Components/Molecules/SubMenu';
import './Dashboard.scss';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    trackEvent(trackedEvents.pageView, { page: 'Dashboard' });
    this.state = {
      currentActiveTab: 0,
    };
  }

  getOptionList = () => {
    const optionList = [
      { id: 0, label: langValues.labelsDashboard.pedidos, icon: <PackageIcon /> },
      // { id: 1, label: langValues.labelsDashboard.acessosOpcao, icon: <TouchAppRoundedIcon /> },
    ];
    if (ENV_ENVIRONMENT === environment.AR) {
      return optionList.filter((option) => option.id !== 1);
    }
    return optionList;
  };

  getContent = (currentActiveTab) => {
    switch (currentActiveTab) {
      case 0: {
        return <DashboardOrders />;
      }
      case 1: {
        return <DashboardAccesses />;
      }
      default:
        return null;
    }
  };

  render = () => {
    const { currentActiveTab } = this.state;
    return (
      <div className="dashboardPage">
        <SubMenu
          currentActiveOption={currentActiveTab}
          changeActiveOption={(value) => {
            this.setState({ currentActiveTab: value });
            const { label } = this.getOptionList().find((item) => item.id === value);
            trackEvent(trackedEvents.buttonClick, {
              button: trackedButtons.subMenuOptionDashboard,
              optionLabel: label,
            });
          }}
          optionList={this.getOptionList()}
          title={{
            label: 'Dashboard',
          }}
        />
        {this.getContent(currentActiveTab)}
      </div>
    );
  };
}

export default Dashboard;
