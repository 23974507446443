import React from 'react';
import PropTypes from 'prop-types';
import { AccordionConstructor, AccordionSummaryConstructor, AccordionDetailsConstructor } from './styles';

const SingleItemAccordion = ({ ...props }) => {
  const { expandMoreIcon, accordionSummary, accordionDetails, styles, onChangeCallback = () => {} } = props;

  const Accordion = AccordionConstructor(styles.accordionStyle);
  const AccordionSummary = AccordionSummaryConstructor(styles.accordionSummaryStyle);
  const AccordionDetails = AccordionDetailsConstructor(styles.accordionDetailsStyle);

  return (
    <Accordion
      onChange={onChangeCallback}
      sx={{
        '& .MuiAccordionSummary-expandIconWrapper': {
          transform: 'none !important',
        },
      }}
      disableGutters
    >
      <AccordionSummary
        expandIcon={expandMoreIcon}
        aria-label="Expand"
        aria-controls="panelSummary"
        disabled={!expandMoreIcon}
      >
        {accordionSummary}
      </AccordionSummary>
      <AccordionDetails aria-controls="panelDetails">{accordionDetails}</AccordionDetails>
    </Accordion>
  );
};

SingleItemAccordion.propTypes = {
  expandMoreIcon: PropTypes.object.isRequired,
  accordionSummary: PropTypes.object.isRequired,
  accordionDetails: PropTypes.object.isRequired,
  styles: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func,
};

export default SingleItemAccordion;
