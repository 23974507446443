const INITIAL_FILTERS_STATE = () => ({
  categories: [],
  status: [],
  pendencies: [],
  macroAreas: [],
  brands: [],
  models: [],
  steps: [],
  stepsAdministrative: [],
  macroCategories: [],
  orderType: [],
  secondaryDealers: [],
  statusDelay: [],
  stepsDelay: [],
});

const INITIAL_DATES_STATE = () => ({
  startDate: new Date(),
  endDate: new Date(),
  isUsingDateFilter: false,
});

const INITIAL_AHORRO_FILTER_STATE = [
  {
    name: 'Cuit',
    value: '',
  },
  {
    name: 'Orden comercial',
    value: '',
  },
  {
    name: 'Orden industrial',
    value: '',
  },
  {
    name: 'Marca',
    value: '',
  },
  {
    name: 'Solicitud',
    value: '',
  },
  {
    name: 'Grupo',
    value: '',
  },
  {
    name: 'Orden',
    value: '',
  },
];

export const INITIAL_STATE = () => ({
  selectedFilters: INITIAL_FILTERS_STATE(),
  selectedAhorroFilters: INITIAL_AHORRO_FILTER_STATE,
  selectedDelayFilters: INITIAL_FILTERS_STATE(),
  selectedDates: INITIAL_DATES_STATE(),
  selectedDelayDates: INITIAL_DATES_STATE(),
  appliedFilters: INITIAL_FILTERS_STATE(),
  appliedDelayFilters: INITIAL_FILTERS_STATE(),
  appliedDates: INITIAL_DATES_STATE(),
  appliedDelayDates: INITIAL_DATES_STATE(),
  dealerId: '',
  searchOrdersString: '',
  searchAhorroString: '',
  searchDelayTrackingString: '',
  isBilledFilter: 0,
  isPlanAhorro: false,
  stepStatus: null,
  sorting: null,
  isToolTipOpen: null,
  filtersExportPDA: {},
});


export const getFiltersSelected = selected => ({
  categories: [...selected.categories],
  status: [...selected.status],
  pendencies: [...selected.pendencies],
  macroAreas: [...selected.macroAreas],
  brands: [...selected.brands],
  models: [...selected.models],
  steps: [...selected.steps],
  stepsAdministrative: [...selected.stepsAdministrative],
  macroCategories: [...selected.macroCategories],
  orderType: [...selected.orderType],
  secondaryDealers: [...selected.secondaryDealers],
  statusDelay: [...selected.statusDelay],
  stepsDelay: [...selected.stepsDelay],
});

const setStepDelayedOrders = ({
  categories: [],
  status: [],
  pendencies: [],
  macroAreas: [],
  brands: [],
  models: [],
  steps: [
    'dealer',
    'refactored',
  ],
  stepsAdministrative: [],
  macroCategories: [],
  orderType: [],
  secondaryDealers: [],
  statusDelay: [],
  stepsDelay: [],
});


export const DUCK_NAME = 'filtersDuck';

const SET_DEALER_ID_FILTER = `${DUCK_NAME}/SET_DEALER_ID_FILTER`;
const SET_SEARCH_ORDERS_STRING = `${DUCK_NAME}/SET_SEARCH_ORDERS_STRING`;
const SET_SEARCH_DELAY_TRACKING_STRING = `${DUCK_NAME}/SET_SEARCH_DELAY_TRACKING_STRING`;
const SET_SELECTED_FILTERS = `${DUCK_NAME}/SET_SELECTED_FILTERS`;
const SET_SELECTED_AHORRO_FILTERS = `${DUCK_NAME}/SET_SELECTED_AHORRO_FILTERS`;
const SET_DELAY_SELECTED_FILTERS = `${DUCK_NAME}/SET_DELAY_SELECTED_FILTERS`;
const SET_SELECTED_DATES = `${DUCK_NAME}/SET_SELECTED_DATES`;
const SET_DELAY_SELECTED_DATES = `${DUCK_NAME}/SET_DELAY_SELECTED_DATES`;
const SET_APPLIED_FILTERS = `${DUCK_NAME}/SET_APPLIED_FILTERS`;
const SET_DELAY_APPLIED_FILTERS = `${DUCK_NAME}/SET_DELAY_APPLIED_FILTERS`;
const SET_APPLIED_DATES = `${DUCK_NAME}/SET_APPLIED_DATES`;
const SET_DELAY_APPLIED_DATES = `${DUCK_NAME}/SET_DELAY_APPLIED_DATES`;
const SET_IS_BILLED_FILTER = `${DUCK_NAME}/SET_IS_BILLED_FILTER`;
const SET_IS_PLAN_AHORRO = `${DUCK_NAME}/SET_IS_PLAN_AHORRO`;
const SET_STEP_STATUS_FILTER = `${DUCK_NAME}/SET_STEP_STATUS_FILTER`;
const SET_STEP_AS_DEALER_FILTER = `${DUCK_NAME}/SET_STEP_AS_DEALER_FILTER`;
const SET_FILTER_NOTIFICATIONS = `${DUCK_NAME}/SET_FILTER_NOTIFICATIONS`;
const SET_ORDER_SORTING = `${DUCK_NAME}/SET_ORDER_SORTING`;
const SET_IS_TOOLTIP_OPEN = `${DUCK_NAME}/SET_IS_TOOLTIP_OPEN`;
const CLEAR_FILTERS = `${DUCK_NAME}/CLEAR_FILTERS`;
const CLEAR_DATE_FILTERS = `${DUCK_NAME}/CLEAR_DATE_FILTERS`;
const CLEAR_DELAY_DATE_FILTERS = `${DUCK_NAME}/CLEAR_DELAY_DATE_FILTERS`;
const CLEAR_AHORRO_FILTERS = `${DUCK_NAME}/CLEAR_AHORRO_FILTERS`;
const SET_EXPORT_PDA_FILTER = `${DUCK_NAME}/SET_EXPORT_PDA_FILTER`;

export const setDealerIdFilter = dealerId => ({ type: SET_DEALER_ID_FILTER, dealerId });
export const setSearchOrdersString = searchOrdersString => ({
  type: SET_SEARCH_ORDERS_STRING, searchOrdersString,
});
export const setSearchDelayTrackingString = searchDelayTrackingString => ({
  type: SET_SEARCH_DELAY_TRACKING_STRING, searchDelayTrackingString,
});
export const setSelectedFilters = selected => ({ type: SET_SELECTED_FILTERS, selected });
export const setSelectedAhorroFilters = (search, filters) => ({ type: SET_SELECTED_AHORRO_FILTERS, filters, search });
export const setDelaySelectedFilters = selected => ({ type: SET_DELAY_SELECTED_FILTERS, selected });
export const setSelectedDates = selected => ({ type: SET_SELECTED_DATES, selected });
export const setDelaySelectedDates = selected => ({ type: SET_DELAY_SELECTED_DATES, selected });
export const setAppliedFilters = selected => ({ type: SET_APPLIED_FILTERS, selected });
export const setDelayAppliedFilters = selected => ({ type: SET_DELAY_APPLIED_FILTERS, selected });
export const setAppliedDates = selected => ({ type: SET_APPLIED_DATES, selected });
export const setDelayAppliedDates = selected => ({ type: SET_DELAY_APPLIED_DATES, selected });
export const setIsBilledFilter = isBilled => ({ type: SET_IS_BILLED_FILTER, isBilled });
export const setIsPlanAhorro = isAhorro => ({ type: SET_IS_PLAN_AHORRO, isAhorro });
export const setStepStatusFilter = stepStatus => ({ type: SET_STEP_STATUS_FILTER, stepStatus });
export const setStepFilter = step => ({ type: SET_STEP_AS_DEALER_FILTER, step });
export const setOrderSorting = sorting => ({ type: SET_ORDER_SORTING, sorting });
export const setFilterNotifications = filters => ({ type: SET_FILTER_NOTIFICATIONS, filters });
export const setIsToolTipOpen = isToolTipOpen => ({ type: SET_IS_TOOLTIP_OPEN, isToolTipOpen });
export const clearFilters = () => ({ type: CLEAR_FILTERS });
export const clearDateFilters = () => ({ type: CLEAR_DATE_FILTERS });
export const clearDelayDateFilters = () => ({ type: CLEAR_DELAY_DATE_FILTERS });
export const clearAhorroFilters = () => ({ type: CLEAR_AHORRO_FILTERS });
export const setExportPdaFilter = filtersExportPDA => ({ type: SET_EXPORT_PDA_FILTER, filtersExportPDA });

const reducer = (state = INITIAL_STATE(), action) => {
  switch (action.type) {
    case SET_DEALER_ID_FILTER:
      return {
        ...state,
        dealerId: action.dealerId,
      };
    case SET_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: getFiltersSelected(action.selected),
      };
    case SET_SELECTED_AHORRO_FILTERS:
      return {
        ...state,
        selectedAhorroFilters: action.filters,
        searchAhorroString: action.search,
      };
    case SET_DELAY_SELECTED_FILTERS:
      return {
        ...state,
        selectedDelayFilters: getFiltersSelected(action.selected),
      };
    case SET_SELECTED_DATES:
      return {
        ...state,
        selectedDates: { ...action.selected },
      };
    case SET_DELAY_SELECTED_DATES:
      return {
        ...state,
        selectedDelayDates: { ...action.selected },
      };
    case SET_APPLIED_DATES:
      return {
        ...state,
        appliedDates: { ...action.selected },
      };
    case SET_DELAY_APPLIED_DATES:
      return {
        ...state,
        appliedDelayDates: { ...action.selected },
      };
    case SET_APPLIED_FILTERS:
      return {
        ...state,
        appliedFilters: getFiltersSelected(action.selected),
      };
    case SET_DELAY_APPLIED_FILTERS:
      return {
        ...state,
        appliedDelayFilters: getFiltersSelected(action.selected),
      };
    case SET_IS_BILLED_FILTER:
      return {
        ...state,
        isBilledFilter: action.isBilled,
        isPlanAhorro: false,
      };
    case SET_IS_PLAN_AHORRO:
      return {
        ...state,
        isPlanAhorro: action.isAhorro,
        isBilledFilter: 3,
      };
    case SET_STEP_STATUS_FILTER:
      return {
        ...state,
        stepStatus: action.stepStatus,
      };
    case SET_STEP_AS_DEALER_FILTER:
      return {
        ...state,
        appliedFilters: setStepDelayedOrders,
        selectedFilters: setStepDelayedOrders,
      };
    case SET_ORDER_SORTING:
      return {
        ...state,
        sorting: action.sorting,
      };
    case SET_IS_TOOLTIP_OPEN:
      return {
        ...state,
        isToolTipOpen: action.isToolTipOpen,
      };
    case SET_SEARCH_ORDERS_STRING:
      return {
        ...state,
        searchOrdersString: action.searchOrdersString,
      };
    case SET_SEARCH_DELAY_TRACKING_STRING:
      return {
        ...state,
        searchDelayTrackingString: action.searchDelayTrackingString,
      };
    case SET_EXPORT_PDA_FILTER:
      return {
        ...state,
        filtersExportPDA: action.filtersExportPDA,
      };
    case SET_FILTER_NOTIFICATIONS:
      return {
        ...INITIAL_STATE(),
        stepStatus: action.filters.stepStatus,
        selectedDelayFilters: {
          ...INITIAL_FILTERS_STATE(),
          macroAreas: [action.filters.macroArea],
        },
        appliedDelayFilters: {
          ...INITIAL_FILTERS_STATE(),
          macroAreas: [action.filters.macroArea],
        },
        selectedDelayDates: {
          ...INITIAL_DATES_STATE(),
          startDate: action.filters.date,
          endDate: action.filters.date,
          isUsingDateFilter: true,
        },
        appliedDelayDates: {
          ...INITIAL_DATES_STATE(),
          startDate: action.filters.date,
          endDate: action.filters.date,
          isUsingDateFilter: true,
        },
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        selectedFilters: INITIAL_FILTERS_STATE(),
        selectedAhorroFilters: INITIAL_AHORRO_FILTER_STATE,
        selectedDelayFilters: INITIAL_FILTERS_STATE(),
        appliedFilters: INITIAL_FILTERS_STATE(),
        appliedDelayFilters: INITIAL_FILTERS_STATE(),
        dealerId: '',
        searchAhorroString: '',
      };
    case CLEAR_DATE_FILTERS:
      return {
        ...state,
        selectedDates: INITIAL_DATES_STATE(),
        appliedDates: INITIAL_DATES_STATE(),
      };
    case CLEAR_DELAY_DATE_FILTERS:
      return {
        ...state,
        selectedDelayDates: INITIAL_DATES_STATE(),
        appliedDelayDates: INITIAL_DATES_STATE(),
      };
    case CLEAR_AHORRO_FILTERS:
      return {
        ...state,
        selectedAhorroFilters: INITIAL_AHORRO_FILTER_STATE,
        searchAhorroString: '',
      };
    default:
      return state;
  }
};

export default reducer;
