import React from 'react';
import PropTypes from 'prop-types';
import { roleConsts } from 'Utils/consts';
import { User, Token } from 'Common/Helpers/AuthHelper';
import { Navigate } from 'react-router-dom';
import { withRouterInfo } from './withRouterInfo';

const DefaultRoute = (props) => {
  const { location } = props;
  if (User.hasOneOfProfiles([roleConsts.customercare, roleConsts.dealers])) {
    return <Navigate to={{ pathname: '/pedidos' }} state={{ from: location }} replace />;
  }
  if (User.hasProfile(roleConsts.client)) {
    return <Navigate to={{ pathname: '/clientes/pedido' }} state={{ from: location }} replace />;
  }
  if (User.hasProfile(roleConsts.employees)) {
    const tokenKey = Token.get();
    const { token } = tokenKey;
    return <Navigate to={{ pathname: `/empregados/${token}` }} state={{ from: location }} replace />;
  }
  return <Navigate to={{ pathname: '/parametros' }} state={{ from: location }} replace />;
};

DefaultRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }).isRequired,
};

export default withRouterInfo(DefaultRoute);
