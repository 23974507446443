import { connect } from 'react-redux';
import { changeSelectedIndustrialOrder } from 'Infrastructure/Ducks/OrderDetailDuck';
import IndustrialOrderCard from './IndustrialOrderCard';

const mapStateToProps = (state) => {
  const { orderDetail } = state;
  return {
    selectedIndustrialOrder: orderDetail.selectedIndustrialOrder.industrialOrder,
    loading: orderDetail.selectedIndustrialOrder.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  changeSelectedIndustrialOrder: (industrialOrder) => {
    dispatch(changeSelectedIndustrialOrder(industrialOrder));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndustrialOrderCard);
