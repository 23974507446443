import React, { Component } from 'react';
import PropTypes from 'prop-types';
import langValues from 'Config/i18n/index';
import TabsSelector from 'Components/Molecules/TabsSelector/TabsSelector';
import List from 'Components/Shared/List/List';
import { trackedPages } from 'Utils/consts';
import { getHeadersWithTooltips } from 'Components/Molecules/HeadersWithTooltips/HeadersWithTooltips';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';
import OrderFilters from 'Components/Organisms/OrderFilters';
import DelayManagementListItem from 'Components/Molecules/DelayManagementListItem/DelayManagementListItem';
import { ReactComponent as EmptyState } from 'Common/Assets/empty_state.svg';

import './DelayManagementList.scss';

class DelayManagementList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
    };
  }

  returnCurrentActiveTab = () => {
    const { tabsList, filtersDuck } = this.props;
    const { stepStatus } = filtersDuck;
    return tabsList.find((tab) => tab.value === stepStatus).id;
  };

  getListHeaders = () => {
    const { filtersDuck, setOrderSorting } = this.props;
    const { sorting } = filtersDuck;
    const { headerItems } = langValues.orderManagementLabels;
    return getHeadersWithTooltips(headerItems, sorting, setOrderSorting, this.applyFilter);
  };

  loadChangedIndustrialOrders = async (currentPage) => {
    const { loadList, filtersDuck } = this.props;
    const { appliedDelayFilters, appliedDelayDates, stepStatus, sorting, searchDelayTrackingString } = filtersDuck;
    const filterSearchDelayTrackingString =
      searchDelayTrackingString.trim() === '' ? null : searchDelayTrackingString.trim();
    const allSelectedFilters = {
      ...appliedDelayFilters,
      ...appliedDelayDates,
      sorting,
      filter: filterSearchDelayTrackingString,
    };
    await loadList(currentPage, stepStatus, allSelectedFilters);
  };

  clearList = () => {
    const { clearList } = this.props;
    clearList();
  };

  loadPageData = async () => {
    const { page } = this.state;
    this.clearList();
    this.loadChangedIndustrialOrders(page);
  };

  applyFilter = () => {
    this.setState({ page: 0 }, () => {
      this.loadPageData();
    });
  };

  changeActiveTab = (tabId, currentActiveTab) => {
    const { tabsList, setStepStatusFilter } = this.props;
    if (tabId !== currentActiveTab) {
      const status = tabsList[tabId].value;
      setStepStatusFilter(status);
      this.setState({ page: 0 }, () => {
        this.loadChangedIndustrialOrders(0);
      });
    }
  };

  increasePage() {
    const { page } = this.state;
    const currentPage = page + 1;
    this.loadChangedIndustrialOrders(currentPage);
    this.setState({ page: currentPage });
  }

  renderTotalVisibleOrders = (totalVisible, total) => (
    <div className="totalVisibleOrdersDiv">
      <span className="totalVisibleOrdersSpan">{langValues.totalVisibleOrders(totalVisible, total)}</span>
    </div>
  );

  renderLoadMore() {
    const { page } = this.state;
    const { orderManagement } = this.props;
    const { total } = orderManagement;
    if (!total || total < (page + 1) * 10 || orderManagement.loading) return null;

    const buttonText = orderManagement.loadingPage ? 'CARREGANDO...' : 'CARREGAR MAIS';
    return (
      <MaterialButton
        sx={{ color: '#000000de', borderColor: '#0000003b', ':hover': { borderColor: '#0000003b' } }}
        onClick={() => this.increasePage()}
        buttonText={buttonText}
        variant="outlined"
      />
    );
  }

  renderState() {
    const { orderManagement, changeSelectedOrder } = this.props;
    const { total } = orderManagement;

    if (orderManagement.orders.length > 0 || orderManagement.loading) {
      const selectedOrderId = orderManagement.selectedOrder ? orderManagement.selectedOrder.industrialOrderId : null;
      return (
        <>
          <List
            header={this.getListHeaders()}
            Type={DelayManagementListItem}
            childProps={{
              listItems: orderManagement.orders,
              selectedId: selectedOrderId,
              changeSelectedOrder,
              loading: orderManagement.loading,
            }}
          />
          {this.renderLoadMore()}
          {this.renderTotalVisibleOrders(orderManagement.orders.length, total)}
        </>
      );
    }

    return (
      <div className="delayManagementList-emptyState">
        <EmptyState />
        <h5>{langValues.managementPage.emptyResult}</h5>
      </div>
    );
  }

  render() {
    const { tabsList, models } = this.props;
    const currentActiveTab = this.returnCurrentActiveTab();

    return (
      <section className="delayManagementList">
        <TabsSelector
          changeActiveTab={(tabId) => this.changeActiveTab(tabId, currentActiveTab)}
          currentActiveTab={currentActiveTab}
          tabsList={tabsList}
        />
        <div className="delayManagementList-list">
          <div className="delayManagementList-filters">
            <OrderFilters
              onConfirmFilter={this.applyFilter}
              models={models}
              pageName={trackedPages.delayManagement}
              filterPlacement="bottom-start"
              renderSearch
            />
          </div>
          {this.renderState()}
        </div>
      </section>
    );
  }
}

DelayManagementList.propTypes = {
  tabsList: PropTypes.object.isRequired,
  orderManagement: PropTypes.object.isRequired,
  loadList: PropTypes.func.isRequired,
  changeSelectedOrder: PropTypes.func.isRequired,
  filtersDuck: PropTypes.object.isRequired,
  setStepStatusFilter: PropTypes.func.isRequired,
  setOrderSorting: PropTypes.func.isRequired,
  models: PropTypes.object.isRequired,
  clearList: PropTypes.func.isRequired,
};

export default DelayManagementList;
