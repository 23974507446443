import React from 'react';
import PropTypes from 'prop-types';
import './NomeCliente.scss';

function NomeCliente({ clientName }) {
  const clientNameWithoutNull = clientName || '';
  return (
    <span className="clientName" id="clientName" title={clientNameWithoutNull.replace(/(^|\s)\S/g, l => l.toUpperCase())}>
      {clientNameWithoutNull}
    </span>
  );
}

NomeCliente.propTypes = {
  clientName: PropTypes.string.isRequired,
};

export default NomeCliente;
