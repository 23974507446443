import React from 'react';
import PropTypes from 'prop-types';
import './Timeline.scss';
import { stepStatusId, timeLine, deviceInfo } from 'Utils/consts';
import TimelineItem from 'Components/Molecules/TimelineItem';
import TimelineItemMobile from 'Components/Molecules/TimelineItemMobile/index';
import langValues from 'Config/i18n/index';

const Timeline = ({ selectedIndustrialOrder, order, deviceType = deviceInfo.typeDesktop }) => {
  const getOrderCreationStep = () => {
    const orderDate = order ? order.orderDate : null;
    return {
      stepId: { id: 'orderDate', description: langValues.timeLineLabels.orderCreation },
      status: stepStatusId.delivered,
      actualDate: orderDate,
    };
  };

  const renderTimeline = () => {
    let steps = null;
    if (
      selectedIndustrialOrder &&
      !selectedIndustrialOrder.loading &&
      selectedIndustrialOrder.industrialOrder &&
      selectedIndustrialOrder.industrialOrder.dates
    ) {
      steps = selectedIndustrialOrder.industrialOrder.dates;
    } else {
      return null;
    }

    const isStepDealer = (step) => step.stepId.id === timeLine.stepNames.concessionaria;

    const TimelineStep = deviceType === deviceInfo.typeDesktop ? TimelineItem : TimelineItemMobile;

    return (
      <>
        <TimelineStep
          key="orderDate"
          step={getOrderCreationStep()}
          isSingleStep
          hasDefaultFlag={order ? order.transitTimeDefaultFlag : false}
          deviceType={deviceType}
          rules={selectedIndustrialOrder.industrialOrder.rules}
        />
        {steps.map((step) => (
          <TimelineStep
            deviceType={deviceType}
            key={step.stepId.id}
            step={step}
            isCurrentStep={selectedIndustrialOrder.industrialOrder.step === step.stepId.id}
            hasDefaultFlag={order ? order.transitTimeDefaultFlag : false}
            vehicleFleet={isStepDealer(step) ? selectedIndustrialOrder.industrialOrder.vehicleFleet : null}
            rules={selectedIndustrialOrder.industrialOrder.rules}
          />
        ))}
      </>
    );
  };

  return (
    <article className="timeline-card">
      <section className="timeline-grid">{renderTimeline()}</section>
    </article>
  );
};

Timeline.propTypes = {
  selectedIndustrialOrder: PropTypes.object,
  order: PropTypes.object,
  deviceType: PropTypes.string,
};

export default Timeline;
