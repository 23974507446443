import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { getDateAsNumbers, getShortDateAsNumbers } from 'Utils/dateUtils';
import { stepStatusId, deviceInfo } from 'Utils/consts';
import langValues from 'Config/i18n/index';

import './StepDate.scss';

const StepDate = ({
  step,
  status,
  rules,
  deviceType = deviceInfo.typeDesktop,
  isAdmintrative,
  previouslyStepIsEnable,
}) => {
  let stepStatus = status;
  const stepAdminConcluded = step.enable && langValues.stepDateStatus.done;
  const DONT_RENDER_DATES = isAdmintrative && (!step.conclusionDate || !step.enable);

  const getDates = () => {
    let statusText;
    let dateText;
    let finalDateText = null;

    if (step.flagCalculatedRealDate) {
      statusText = langValues.stepDateStatus.unavailable;
      dateText = null;
    } else if (step.status === stepStatusId.ongoing) {
      statusText = langValues.stepDateStatus.ongoing;
      dateText = null;
    } else if (((rules && !rules.estimated) || step.hiddenForeseenDate) && step.status !== 'delivered') {
      statusText = null;
      dateText = null;
    } else if (((rules && !rules.concluded) || step.hiddenForeseenDate) && step.status === 'delivered') {
      statusText = langValues.stepDateStatus.done;
      dateText = null;
    } else if (step.actualDate) {
      statusText = langValues.stepDateStatus.done;
      dateText =
        deviceType === deviceInfo.typeDesktop
          ? getDateAsNumbers(step.actualDate, true)
          : getShortDateAsNumbers(step.actualDate, true);
    } else if (step.foreseenDate) {
      statusText = langValues.stepDateStatus.foreseen;
      dateText =
        deviceType === deviceInfo.typeDesktop
          ? getDateAsNumbers(step.foreseenDate, true)
          : getShortDateAsNumbers(step.foreseenDate, true);
      if (step.finalForeseenDate) {
        finalDateText =
          deviceType === deviceInfo.typeDesktop
            ? getDateAsNumbers(step.finalForeseenDate, true)
            : getShortDateAsNumbers(step.finalForeseenDate, true);
      }
    } else {
      stepStatus = stepStatusId.late;
      statusText = langValues.stepDateStatus.noForeseen;
      dateText = null;
    }
    return { statusText, dateText, finalDateText };
  };

  const showCanceledPdaStatus = step.enable && step.sequence === 10 && !previouslyStepIsEnable;

  const getTextToRenderClass = useCallback(() => {
    if (showCanceledPdaStatus) {
      return stepStatusId.late;
    }

    return stepAdminConcluded ? stepStatusId.delivered : stepStatus;
  }, [step]);

  const renderText = (text, font) => <span className={`${font} ${getTextToRenderClass()}`}>{text}</span>;

  const renderLongDateText = (text1, text2, font) => (
    <span className={`${font} ${stepStatus}`}>
      {text1}
      <br />
      {text2}
    </span>
  );

  const renderStepDateStatus = (statusText) => (
    <div className="stepDate-status">
      {(statusText || stepAdminConcluded) && !DONT_RENDER_DATES
        ? renderText(showCanceledPdaStatus ? 'CANCELADO' : stepAdminConcluded || statusText, 'font-small3')
        : null}
    </div>
  );

  const renderStepDate = (dateText, finalDateText) => {
    let dateRenderText;

    if (finalDateText) {
      dateRenderText =
        deviceType === deviceInfo.typeDesktop
          ? renderText(`${dateText} a ${finalDateText}`, 'font-small2')
          : renderLongDateText(`Entre ${dateText.toString().substr(0, 5)}`, `e ${finalDateText}`, 'font-small2');
    } else {
      dateRenderText = renderText(dateText, 'font-small2');
    }

    if (stepAdminConcluded) {
      dateRenderText = renderText(getDateAsNumbers(step.conclusionDate, true), 'font-small2');
    }

    return (
      <div className={`stepDate ${finalDateText ? 'twoDates' : ''}`}>{DONT_RENDER_DATES ? '' : dateRenderText}</div>
    );
  };

  const { statusText, dateText, finalDateText } = getDates();

  return (
    <>
      {renderStepDateStatus(statusText)}
      {renderStepDate(dateText, finalDateText)}
    </>
  );
};

StepDate.propTypes = {
  step: PropTypes.object.isRequired,
  status: PropTypes.string,
  deviceType: PropTypes.string,
  isAdmintrative: PropTypes.bool,
  rules: PropTypes.object,
  previouslyStepIsEnable: PropTypes.bool,
};

export default StepDate;
