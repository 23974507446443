import ParametersRepository from 'Infrastructure/Repositories/ParametersRepository';
import { getTimestampWithoutHours } from 'Utils/dateUtils';
import RequestRepository from 'Infrastructure/Repositories/RequestRepository';
import { changeStringToAPattern } from 'Utils/stringUtils';

const generateFiltersWithTimeStampDates = (filters) => {
  const startDate = filters.startDate ? getTimestampWithoutHours(filters.startDate) : null;
  const endDate = filters.endDate ? getTimestampWithoutHours(filters.endDate) : null;
  const newFilters = {
    ...filters,
    minDate: filters.isUsingDateFilter ? startDate : null,
    maxDate: filters.isUsingDateFilter ? endDate : null,
  };
  return newFilters;
};

class ParametersService {
  constructor(parametersRepository = new ParametersRepository()) {
    this.parametersRepository = parametersRepository;
  }

  async getStepParameters() {
    try {
      const { data } = await this.parametersRepository.getStepParameters();
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async putStepParameters(incomingData) {
    try {
      await this.parametersRepository.putStepParameters(incomingData);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getModelsVersions() {
    try {
      const { data } = await this.parametersRepository.getModelsVersions();
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getUnregisteredVersions() {
    try {
      const { data } = await this.parametersRepository.getUnregisteredVersions();
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getUnregisteredData() {
    try {
      const { data } = await this.parametersRepository.getUnregisteredData();
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getHistoricLogsOrder() {
    try {
      const { data } = await this.parametersRepository.getHistoricLogsOrder();
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async putModel(model) {
    return this.parametersRepository.putModel(model);
  }

  async putVersion(version) {
    return this.parametersRepository.putVersion(version);
  }

  async deleteModel(modelId) {
    return this.parametersRepository.deleteModel(modelId);
  }

  async deleteVersion(versionId, modelId) {
    return this.parametersRepository.deleteVersion(versionId, modelId);
  }

  async exportStep(fileType) {
    return this.parametersRepository.exportStep(fileType);
  }

  getList = async (page, stepStatus, filters) => {
    try {
      const urlPath = 'modelsversions/historicLogsOrders';
      const newFilters = generateFiltersWithTimeStampDates(filters);
      const body = this.createRequestBody(stepStatus, newFilters, page);
      const { data } = await RequestRepository.put(urlPath, body);
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  getTotal = async (stepStatus, filters) => {
    try {
      const urlPath = 'modelsversions/historicLogsOrdersTotal';
      const newFilters = generateFiltersWithTimeStampDates(filters);
      const body = this.createRequestBody(stepStatus, newFilters);
      const { data } = await RequestRepository.put(urlPath, body);
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getHistoricLogsTotal() {
    try {
      const { data } = await this.parametersRepository.getHistoricLogsTotal();
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  createRequestBody = (stepStatus, filters, page) => ({
    page,
    size: 10,
    stepStatus,
    macroArea: filters.macroAreas,
    status: filters.statusDelay,
    steps: filters.stepsDelay,
    pendency: filters.pendencies,
    model: filters.models,
    brand: filters.brands,
    clientCategory: filters.categories,
    clientMacroCategory: filters.macroCategories,
    orderTypes: filters.orderType,
    minDate: filters.minDate,
    maxDate: filters.maxDate,
    sorting: filters.sorting,
    filter: changeStringToAPattern(filters.filter),
    dealerId: filters.dealerId ? filters.dealerId.split(';') : [],
  });
}

export default ParametersService;
