import React from 'react';
import PropTypes from 'prop-types';

import './OrderInformationItem.scss';

const OrderInformationItem = ({ text = '-', label, isDisabled }) => {
  const classDisabled = isDisabled ? 'disabled' : '';
  return (
    <div className="orderInformationItem">
      <span className={`font-subtitle2 orderInformationItem-text ${classDisabled}`}>{text}</span>
      <span className={`font-small3 orderInformationItem-label ${classDisabled}`}>{label}</span>
    </div>
  );
};

OrderInformationItem.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default OrderInformationItem;
