import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { ReactComponent as FleetIcon } from 'Common/Assets/carFleet.svg';
import { getFullDateAsShortText } from 'Utils/dateUtils';
import PopoverMenu from 'Components/Shared/Popover/PopoverMenu';
import { trackEvent } from 'Utils/analytics';
import { trackedButtons, trackedEvents } from 'Utils/consts';
import langValues from 'Config/i18n/index';
import OrderInformationItem from '../OrderInformationItem/index';
import useStyles from './useStyles';
import './FleetInformationButton.scss';

const FleetInformationButton = ({ vehicleFleet }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    trackEvent(trackedEvents.buttonClick, { button: trackedButtons.trackingVehicles });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { classes } = useStyles();

  const popoverContent = () => {
    const lastSeenDate = vehicleFleet.currentPositionDatetime;
    const fullShortDate = getFullDateAsShortText(new Date(lastSeenDate));
    const dateText = langValues.popoverDateText(lastSeenDate, fullShortDate);
    return (
      <section className="fleetButton-popoverContent">
        <div className="fleetButton-popoverContent-title">
          <h6>Localização atual</h6>
          <span className="font-small3">{dateText}</span>
        </div>
        <div className="fleetButton-popoverContent-fleetInformation">
          <OrderInformationItem label={langValues.city} text={vehicleFleet.currentCity || undefined} />
          <OrderInformationItem label={langValues.federatedUnit} text={vehicleFleet.currentState || undefined} />
          <OrderInformationItem label={langValues.shipping} text={vehicleFleet.fleet || undefined} />
        </div>
      </section>
    );
  };

  return (
    <>
      <PopoverMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.paper,
        }}
      >
        {popoverContent()}
      </PopoverMenu>
      <Button
        classes={{
          root: classes.root,
        }}
        className="fleetButton"
        onClick={handleClick}
      >
        <FleetIcon className="fleetIcon" />
        <span className="font-small3">{langValues.shippingDetail}</span>
      </Button>
    </>
  );
};

FleetInformationButton.propTypes = {
  vehicleFleet: PropTypes.object.isRequired,
};

export default FleetInformationButton;
