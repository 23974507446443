import { combineReducers } from 'redux';

import auth, { DUCK_NAME as AUTH_DUCK_NAME } from './Auth';
import list, { DUCK_NAME as LIST_DUCK_NAME } from './ListPedidos';
import dashboard, { DUCK_NAME as DASHBOARD_DUCK_NAME } from './Dashboard';
import modelVersion, { DUCK_NAME as MODEL_VERSION_DUCK_NAME } from './ModelVersion';
import siebel, { DUCK_NAME as SIEBEL_DUCK_NAME } from './ChamadoSiebel';
import listRegion, { DUCK_NAME as LIST_REGION_DUCK_NAME } from './ListRegion';
import parametersDuck, { DUCK_NAME as PARAMETERS_DUCK_NAME } from './ParametersDuck';
import historicLogsOrders, { DUCK_NAME as HISTORIC_LOGS_ORDERS_DUCK_NAME } from './HistoricLogsOrdersDuck';
import delayedOrdersDuck, { DUCK_NAME as DELAYED_ORDERS_DUCK_NAME } from './DelayedOrdersDuck';
import listPedidosIndustriais, { DUCK_NAME as LISTPEDIDOSINDUSTRIAIS_DUCK_NAME } from './ListPedidosIndustriais';
import filtersDuck, { DUCK_NAME as FILTERS_DUCK_NAME } from './FiltersDuck';
import feedbackDuck, { DUCK_NAME as FEEDBACK_DUCK_NAME } from './FeedbackDuck';
import releaseNotesDuck, { DUCK_NAME as RELEASE_NOTES_DUCK_NAME } from './ReleaseNotesDuck';
import invoiceDuck, { DUCK_NAME as INVOICE_DUCK_NAME } from './InvoiceDuck';
import orderDetailDuck, { DUCK_NAME as ORDER_DETAIL_DUCK_NAME } from './OrderDetailDuck';
import notificationDuck, { DUCK_NAME as NOTIFICATION_DUCK_NAME } from './NotificationDuck';
import featuresDuck, { DUCK_NAME as FEATURES_DUCK_NAME } from './FeaturesDuck';
import userDuck, { DUCK_NAME as USER_DUCK_NAME } from './UserDuck';
import systemUpdate, { DUCK_NAME as SYSTEM_UPDATE_DUCK_NAME } from './SystemUpdateDuck';
import orderManagement, { DUCK_NAME as ORDER_MANAGEMENT_DUCK } from './OrderManagementDuck';
import dashboardAccessDuck, { DUCK_NAME as DASHBOARD_ACCESS_DUCK } from './DashboardAccessReportDuck';
import visionConfigurationDuck, { DUCK_NAME as VISION_CONFIGURATION_DUCK } from './VisionConfigurationDuck';
import orderDetailPdaDuck, { DUCK_NAME as ORDER_DETAIL_PDA_DUCK_NAME } from './OrderDetailPdaDuck';

export const reducers = {
  [AUTH_DUCK_NAME]: auth,
  [LIST_DUCK_NAME]: list,
  [DASHBOARD_DUCK_NAME]: dashboard,
  [LISTPEDIDOSINDUSTRIAIS_DUCK_NAME]: listPedidosIndustriais,
  [SIEBEL_DUCK_NAME]: siebel,
  [LIST_REGION_DUCK_NAME]: listRegion,
  [PARAMETERS_DUCK_NAME]: parametersDuck,
  [HISTORIC_LOGS_ORDERS_DUCK_NAME]: historicLogsOrders,
  [DELAYED_ORDERS_DUCK_NAME]: delayedOrdersDuck,
  [MODEL_VERSION_DUCK_NAME]: modelVersion,
  [FILTERS_DUCK_NAME]: filtersDuck,
  [FEEDBACK_DUCK_NAME]: feedbackDuck,
  [RELEASE_NOTES_DUCK_NAME]: releaseNotesDuck,
  [INVOICE_DUCK_NAME]: invoiceDuck,
  [ORDER_DETAIL_DUCK_NAME]: orderDetailDuck,
  [ORDER_DETAIL_PDA_DUCK_NAME]: orderDetailPdaDuck,
  [NOTIFICATION_DUCK_NAME]: notificationDuck,
  [FEATURES_DUCK_NAME]: featuresDuck,
  [USER_DUCK_NAME]: userDuck,
  [SYSTEM_UPDATE_DUCK_NAME]: systemUpdate,
  [ORDER_MANAGEMENT_DUCK]: orderManagement,
  [DASHBOARD_ACCESS_DUCK]: dashboardAccessDuck,
  [VISION_CONFIGURATION_DUCK]: visionConfigurationDuck,
};

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === 'auth/LOAD_LOGOUT_STARTED') {
    newState = {
      featuresDuck: state.featuresDuck,
      feedbackDuck: state.feedbackDuck,
      releaseNotesDuck: state.releaseNotesDuck,
    };
  }

  return appReducer(newState, action);
};

export default rootReducer;
