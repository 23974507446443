import moment from 'moment';

import ModelVersionService from '../Services/ModelVersionService';

export const DUCK_NAME = 'modelVersion';

export const INITIAL_STATE = () => ({
  models: {
    data: [
      {
        modelId: 358,
        modelName: 'ARGO',
      },
      {
        modelId: 675,
        modelName: 'COMPASS',
      },
      {
        modelId: 359,
        modelName: 'CRONOS',
      },
      {
        modelId: 119,
        modelName: 'DOBLO',
      },
      {
        modelId: 560,
        modelName: 'DUCATO',
      },
      {
        modelId: 265,
        modelName: 'FIORINO',
      },
      {
        modelId: 604,
        modelName: 'GR CHEROKEE SRT',
      },
      {
        modelId: 615,
        modelName: 'GRAND CHEROKEE',
      },
      {
        modelId: 197,
        modelName: 'GRAND SIENA',
      },
      {
        modelId: 691,
        modelName: 'JOURNEY',
      },
      {
        modelId: 341,
        modelName: 'MOBI',
      },
      {
        modelId: 195,
        modelName: 'NOVO UNO',
      },
      {
        modelId: 374,
        modelName: 'PALIO WEEK',
      },
      {
        modelId: 570,
        modelName: 'RAM 2500',
      },
      {
        modelId: 611,
        modelName: 'RENEGADE',
      },
      {
        modelId: 578,
        modelName: 'STRADA',
      },
      {
        modelId: 226,
        modelName: 'TORO',
      },
      {
        modelId: 664,
        modelName: 'WRANGLER',
      },
      {
        modelId: 684,
        modelName: 'WRANGLER',
      },
      {
        modelId: 685,
        modelName: 'WRANGLER',
      },
    ],
  },
  modelVersionForecast: {
    loading: false,
    error: null,
    data: null,
  },
  averageInvoiceDownload: {
    isDownloading: false,
    successDownloading: false,
    errorDownloading: null,
  },
  averageInvoiceUpload: {
    isUploading: false,
    successUploading: false,
    errorUploading: null,
  },
});

export const LOAD_MODEL_STARTED = `${DUCK_NAME}/LOAD_MODEL_STARTED`;
export const LOAD_MODEL_SUCCEED = `${DUCK_NAME}/LOAD_MODEL_SUCCEED`;
export const LOAD_MODEL_FAILED = `${DUCK_NAME}/LOAD_MODEL_FAILED`;
export const loadModelStarted = () => ({ type: LOAD_MODEL_STARTED });
export const loadModelSucceed = data => ({ type: LOAD_MODEL_SUCCEED, data });
export const loadModelFailed = error => ({ type: LOAD_MODEL_FAILED, error });

export const LOAD_MVS_FORECAST_STARTED = `${DUCK_NAME}/LOAD_MVS_FORECAST_STARTED`;
export const LOAD_MVS_FORECAST_SUCCEED = `${DUCK_NAME}/LOAD_MVS_FORECAST_SUCCEED`;
export const LOAD_MVS_FORECAST_FAILED = `${DUCK_NAME}/LOAD_MVS_FORECAST_FAILED`;
export const loadMVSForecastStarted = () => ({ type: LOAD_MVS_FORECAST_STARTED });
export const loadMVSForecastSucceed = data => ({ type: LOAD_MVS_FORECAST_SUCCEED, data });
export const loadMVSForecastFailed = error => ({ type: LOAD_MVS_FORECAST_FAILED, error });

export const DOWNLOAD_AVERAGE_INVOICE_STARTED = `${DUCK_NAME}/DOWNLOAD_AVERAGE_INVOICE_STARTED`;
export const DOWNLOAD_AVERAGE_INVOICE_SUCCEED = `${DUCK_NAME}/DOWNLOAD_AVERAGE_INVOICE_SUCCEED`;
export const DOWNLOAD_AVERAGE_INVOICE_FAILED = `${DUCK_NAME}/DOWNLOAD_AVERAGE_INVOICE_FAILED`;
export const CLEAR_STATE_DOWNLOAD_INVOICE = `${DUCK_NAME}/CLEAR_STATE_DOWNLOAD_INVOICE`;
export const downloadAverageInvoiceStarted = () => ({ type: DOWNLOAD_AVERAGE_INVOICE_STARTED });
export const downloadAverageInvoiceSucceed = () => ({ type: DOWNLOAD_AVERAGE_INVOICE_SUCCEED });
export const downloadAverageInvoiceFailed = errorDownloading => ({
  type: DOWNLOAD_AVERAGE_INVOICE_FAILED, errorDownloading,
});
export const clearStateDownloadInvoice = () => ({ type: CLEAR_STATE_DOWNLOAD_INVOICE });

export const UPLOAD_AVERAGE_INVOICE_STARTED = `${DUCK_NAME}/UPLOAD_AVERAGE_INVOICE_STARTED`;
export const UPLOAD_AVERAGE_INVOICE_SUCCEED = `${DUCK_NAME}/UPLOAD_AVERAGE_INVOICE_SUCCEED`;
export const UPLOAD_AVERAGE_INVOICE_FAILED = `${DUCK_NAME}/UPLOAD_AVERAGE_INVOICE_FAILED`;
export const CLEAR_STATE_UPLOAD_INVOICE = `${DUCK_NAME}/CLEAR_STATE_UPLOAD_INVOICE`;
export const uploadAverageInvoiceStarted = () => ({ type: UPLOAD_AVERAGE_INVOICE_STARTED });
export const uploadAverageInvoiceSucceed = () => ({ type: UPLOAD_AVERAGE_INVOICE_SUCCEED });
export const uploadAverageInvoiceFailed = errorUploading => ({
  type: UPLOAD_AVERAGE_INVOICE_FAILED, errorUploading,
});
export const clearStateUploadInvoice = () => ({ type: CLEAR_STATE_UPLOAD_INVOICE });

export const loadModels = () => async (dispatch) => {
  dispatch(loadModelStarted());
  let data = {};
  try {
    const modelVersionService = new ModelVersionService();
    data = await modelVersionService.getModels();
    dispatch(loadModelSucceed(data));
  } catch (err) {
    dispatch(loadModelFailed(err));
  }
};

export const downloadAverageInvoice = () => async (dispatch) => {
  dispatch(downloadAverageInvoiceStarted());
  try {
    const modelVersionService = new ModelVersionService();
    await modelVersionService.getAverageInvoiceTimeDownload();
    dispatch(downloadAverageInvoiceSucceed());
  } catch (err) {
    dispatch(downloadAverageInvoiceFailed(err));
  }
};

export const uploadAverageInvoice = file => async (dispatch) => {
  dispatch(uploadAverageInvoiceStarted());
  try {
    const modelVersionService = new ModelVersionService();
    await modelVersionService.uploadAverageInvoiceTime(file);
    dispatch(uploadAverageInvoiceSucceed());
    setTimeout(() => {
      dispatch(clearStateUploadInvoice());
      window.location.reload();
    }, 3000);
  } catch (err) {
    dispatch(uploadAverageInvoiceFailed(err));
  }
};

export const loadAverageInvoiceTime = () => async (dispatch) => {
  dispatch(loadMVSForecastStarted());
  let data = {};
  try {
    const modelVersionService = new ModelVersionService();
    data = await modelVersionService.getAverageInvoiceTime();
    dispatch(loadMVSForecastSucceed(data));
  } catch (err) {
    dispatch(loadMVSForecastFailed(err));
  }
};

const reducer = (state = INITIAL_STATE(), action) => {
  switch (action.type) {
    case LOAD_MVS_FORECAST_STARTED:
      return {
        ...state,
        modelVersionForecast: {
          ...INITIAL_STATE().modelVersionForecast,
          loading: true,
        },
      };
    case LOAD_MODEL_STARTED:
      return {
        ...state,
        models: {
          ...INITIAL_STATE().models,
          loading: true,
        },
      };
    case LOAD_MODEL_SUCCEED:
      return {
        ...state,
        models: {
          ...state.models,
          lastUpdateDateTime: moment().toISOString(),
          loading: false,
          success: true,
          error: null,
          data: action.data,
        },
      };
    case LOAD_MODEL_FAILED:
      return {
        ...state,
        models: {
          ...state.models,
          lastUpdateDateTime: moment().toISOString(),
          loading: false,
          success: false,
          error: action.error,
        },
      };
    case LOAD_MVS_FORECAST_SUCCEED:
      return {
        ...state,
        modelVersionForecast: {
          loading: false,
          error: null,
          data: { ...action.data },
        },
      };
    case LOAD_MVS_FORECAST_FAILED:
      return {
        ...state,
        modelVersionForecast: {
          loading: false,
          error: { ...action.error },
          data: null,
        },
      };
    case DOWNLOAD_AVERAGE_INVOICE_STARTED:
      return {
        ...state,
        averageInvoiceDownload: {
          ...INITIAL_STATE().averageInvoiceDownload,
          isDownloading: true,
        },
      };
    case DOWNLOAD_AVERAGE_INVOICE_SUCCEED:
      return {
        ...state,
        averageInvoiceDownload: {
          isDownloading: false,
          successDownloading: true,
          errorDownloading: null,
        },
      };
    case DOWNLOAD_AVERAGE_INVOICE_FAILED:
      return {
        ...state,
        averageInvoiceDownload: {
          ...state.averageInvoiceDownload,
          isDownloading: false,
          errorDownloading: action.errorDownloading,
        },
      };
    case UPLOAD_AVERAGE_INVOICE_STARTED:
      return {
        ...state,
        averageInvoiceUpload: {
          isUploading: true,
          successUploading: false,
          errorUploading: null,
        },
      };
    case UPLOAD_AVERAGE_INVOICE_SUCCEED:
      return {
        ...state,
        averageInvoiceUpload: {
          isUploading: false,
          successUploading: true,
          errorUploading: null,
        },
      };
    case UPLOAD_AVERAGE_INVOICE_FAILED:
      return {
        ...state,
        averageInvoiceUpload: {
          isUploading: false,
          successUploading: false,
          errorUploading: action.errorUploading,
        },
      };
    case CLEAR_STATE_UPLOAD_INVOICE:
      return {
        ...state,
        averageInvoiceUpload: {
          ...INITIAL_STATE().averageInvoiceUpload,
        },
      };
    case CLEAR_STATE_DOWNLOAD_INVOICE:
      return {
        ...state,
        averageInvoiceDownload: {
          ...INITIAL_STATE().averageInvoiceDownload,
        },
      };

    default:
      return state;
  }
};


export default reducer;
