/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import PropTypes from 'prop-types';

import './OrdersResume.scss';
import langValues from 'Config/i18n/index';
import PercentField from 'Components/Formatters/PercentField/index';
import KpiGraph from 'Components/DashboardComponents/KpiGraph/index';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as EmptyState } from 'Common/Assets/empty_state.svg';
import KpiCsiHistory from 'Components/DashboardComponents/KpiCsiHistory/index';

function renderPercent(percent, key) {
  return (
    <div className="resume-percent" key={key}>
      <PercentField value={percent} />
    </div>
  );
}
const renderKpiCsiSection = data => (
  <section className="dashboard-order section-kpi-csi">
    <div className="dashboardMainTitle">
      <span className="dashbord-order-status-title">
        {langValues.labelsDashboard.kpiCsi}
      </span>
      <h2 className="dashboardPedidosComerciaisSubtitle">
        {data.totalDelivered} {langValues.labelsDashboard.pedidosEntregues}
      </h2>
    </div>
    {data.totalDelivered > 0
      ? (
        <KpiGraph
          data={{ total: data.totalDelivered, sumLate: data.sumDeliveredLate }}
          labels={[langValues.orderResume.kpiCsi.onTime, langValues.orderResume.kpiCsi.late]}
          percent={((1 - data.percentDeliveredLate) * 100).toFixed(2)}
        />
      )
      : (
        <div className="dashboard-kpi-empty">
          <EmptyState className="empty-state-icon" />
          <span> Nenhum pedido entregue encontrado </span>
        </div>
      )}
  </section>
);

const renderOrdersStatusItem = (label, sum, percent, textStyleType) => (
  <tr className="dashboard-order-status-item">
    <td>
      <div className={`resume-dot ${textStyleType}`} />
    </td>
    <td>
      <span className={`resume-title ${textStyleType}`}>{label}</span>
    </td>
    <td>
      <div className="resume-quantity"> {sum} {langValues.orderResume.orders} </div>
    </td>
    <td>
      {renderPercent(percent, label)}
    </td>
  </tr>
);

const renderOrdersStatusDiv = data => (
  <section className="dashboard-order section-status">
    <div className="dashboardMainTitle">
      <span className="dashbord-order-status-title">
        Status
      </span>
      <div className="dashboardPedidosComerciaisSubtitle">
        {data.totalCommercial} {langValues.labelsDashboard.pedidosEmAndamento}
      </div>
    </div>
    <table className="dashbord-order-status-table">
      <thead>
        <tr className="dashboard-order-status-item firstLine">
          <th>
            <span className="dashbord-order-status-subtitle">
              {langValues.orderResume.status}
            </span>
          </th>
          <th />
          <th>
            <span className="dashbord-order-status-subtitle">
              {langValues.orderResume.numOrders}
            </span>
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {
          renderOrdersStatusItem(
            langValues.labelsRequestResume.late,
            data.sumLate,
            data.percentLate, 'late',
          )
        }
        {
          renderOrdersStatusItem(
            langValues.labelsRequestResume.risk,
            data.sumRisk,
            data.percentRisk,
            'risk',
          )
        }
        {
          renderOrdersStatusItem(
            langValues.labelsRequestResume.beTimes,
            data.sumBeTimes,
            data.percentBeTimes,
            'beTimes',
          )
        }
      </tbody>
    </table>
  </section>
);

const renderKpiCsiHistorySection = (data) => {
  const dataSetLate = [];
  const dataSetOnTime = [];
  const yearMonthsList = [];

  data.forEach((month) => {
    dataSetLate.push(month.ordersDeliveredLate);
    dataSetOnTime.push(month.ordersDeliveredOnTime);
    yearMonthsList.push(month.yearMonth);
  });

  return (
    <div className="dashboardKpiCsiHistory">
      <span className="dashbord-order-status-title"> {langValues.orderResume.historySection} </span>
      <div className="dashboardKpiCsiHistory-graph">
        <KpiCsiHistory data={({ dataSetLate, dataSetOnTime, yearMonthsList })} />
      </div>
    </div>
  );
};


function renderOrdersResume(data) {
  return (
    <div className="dashboardPedidosComerciais">
      {data.loadingResume ? <Skeleton count={10} />
        : (
          <div className="resume-commercial-orders">
            {renderOrdersStatusDiv(data)}
            {renderKpiCsiSection({
              totalDelivered: data.totalDelivered,
              sumDeliveredLate: data.sumDeliveredLate,
              percentDeliveredLate: data.percentDeliveredLate,
            })}
          </div>
        )
      }
      {data.resumeHistory ? renderKpiCsiHistorySection(data.resumeHistory) : null}
    </div>
  );
}


function OrdersResume({
  data,
}) {
  return renderOrdersResume(data);
}


OrdersResume.propTypes = {
  data: PropTypes.object.isRequired,
};

export default OrdersResume;
