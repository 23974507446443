import colorVariables from 'Utils/Styles/colorVariables';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  paper: {
    boxShadow: 'none',
    border: '1px solid #E5EAEE',
    borderRadius: '3px',
    marginTop: '-4px',
  },
  root: {
    backgroundColor: 'white',
    border: `1px solid ${colorVariables.textSecondaryColor}`,
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

export default useStyles;
