import langValues from 'Config/i18n/index';
import { safeToCapitalizeAllFirstLetters } from 'Utils/safeMethods';
import { loadVisionConfigurationUpdate } from 'Infrastructure/Ducks/SystemUpdateDuck';
import VisionConfigurationService from '../Services/VisionConfigurationService';

export const DUCK_NAME = 'visionConfigurationDuck';

export const subMenuList = langValues.settingsVisionConfiguration.submenu.map((item, key) => (
  {
    id: key,
    label: safeToCapitalizeAllFirstLetters(item.roleText),
    value: item.roleId,
    isDisabled: item.isDisabled,
  }
));

const INITIAL_STATE = () => ({
  isSubMenuOpen: false,
  loading: false,
  error: false,
  rules: [],
  currentActiveSubMenu: null,
  update: {
    succeed: false,
    error: false,
  },
});

const LOAD_RULES_STARTED = `${DUCK_NAME}/LOAD_RULES_STARTED`;
const LOAD_RULES_SUCCEED = `${DUCK_NAME}/LOAD_RULES_SUCCEED`;
const LOAD_RULES_FAILED = `${DUCK_NAME}/LOAD_RULES_FAILED`;
const UPDATE_RULES_SUCCEED = `${DUCK_NAME}/UPDATE_RULES_SUCCEED`;
const UPDATE_RULES_FAILED = `${DUCK_NAME}/UPDATE_RULES_FAILED`;
const SET_SUB_MENU_OPEN = `${DUCK_NAME}/SET_SUB_MENU_OPEN`;
const SET_CURRENT_ACTIVE_SUB_MENU = `${DUCK_NAME}/SET_CURRENT_ACTIVE_SUB_MENU`;
const CLEAR_STATE = `${DUCK_NAME}/CLEAR_STATE`;
const CLEAR_UPDATE_SUCCESS = `${DUCK_NAME}/CLEAR_UPDATE_SUCCESS`;
const CLEAR_UPDATE_FAILURE = `${DUCK_NAME}/CLEAR_UPDATE_FAILURE`;

const loadRulesStarted = () => ({ type: LOAD_RULES_STARTED });
const loadRulesSucceed = rules => (
  { type: LOAD_RULES_SUCCEED, rules }
);
const updateRulesSucceed = () => ({ type: UPDATE_RULES_SUCCEED });
const loadRulesFailed = () => ({ type: LOAD_RULES_FAILED });
const updateRulesFailed = () => ({ type: UPDATE_RULES_FAILED });

export const clearRulesState = () => ({ type: CLEAR_STATE });
const clearUpdateSuccess = () => ({ type: CLEAR_UPDATE_SUCCESS });
export const clearUpdateFailure = () => ({ type: CLEAR_UPDATE_FAILURE });

export const setSubMenuOpen = status => ({ type: SET_SUB_MENU_OPEN, status });
export const setCurrentActiveSubMenu = current => ({ type: SET_CURRENT_ACTIVE_SUB_MENU, current });

export const loadRules = role => async (dispatch) => {
  dispatch(loadRulesStarted());
  try {
    const visionConfigurationService = new VisionConfigurationService();
    const rules = await visionConfigurationService.getRules(role);
    dispatch(loadRulesSucceed(rules));
  } catch (err) {
    dispatch(loadRulesFailed());
  }
};

export const updateRules = (body, role) => async (dispatch) => {
  try {
    const visionConfigurationService = new VisionConfigurationService();
    await visionConfigurationService.updateRules(body);
    dispatch(updateRulesSucceed());
    dispatch(loadRules(role));
    dispatch(loadVisionConfigurationUpdate());
    setTimeout(() => {
      dispatch(clearUpdateSuccess());
    }, 3000);
  } catch (err) {
    dispatch(updateRulesFailed());
  }
};

const reducer = (state = INITIAL_STATE(), action) => {
  switch (action.type) {
    case LOAD_RULES_STARTED:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case LOAD_RULES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        rules: [],
      };
    case LOAD_RULES_SUCCEED:
      return {
        ...state,
        loading: false,
        error: false,
        rules: [...action.rules],
      };
    case UPDATE_RULES_SUCCEED:
      return {
        ...state,
        update: {
          succeed: true,
          error: false,
        },
      };
    case UPDATE_RULES_FAILED:
      return {
        ...state,
        update: {
          ...state.update,
          error: true,
        },
      };
    case CLEAR_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          succeed: false,
        },
      };
    case CLEAR_UPDATE_FAILURE:
      return {
        ...state,
        update: {
          ...state.update,
          error: false,
        },
      };
    case SET_SUB_MENU_OPEN:
      return {
        ...state,
        isSubMenuOpen: action.status,
      };
    case SET_CURRENT_ACTIVE_SUB_MENU:
      return {
        ...state,
        currentActiveSubMenu: action.current,
      };
    case CLEAR_STATE:
      return INITIAL_STATE();
    default:
      return state;
  }
};

export default reducer;
