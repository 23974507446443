import React from 'react';
import PropTypes from 'prop-types';
import { trackedPages } from 'Utils/consts';
import FilterSearchHistoric from 'Components/Molecules/FilterSearchHistoric';
import './HistoricFilters.scss';

const HistoricFilters = ({ ...props }) => {
  console.log(props);
  const { onConfirmFilter, renderSearch, pageName } = props;

  const isPageDelayManamegent = pageName === trackedPages.delayManagement;

  return (
    <>
      {renderSearch ? (
        <FilterSearchHistoric handleConfirm={onConfirmFilter} isPageDelayManamegent={isPageDelayManamegent} />
      ) : null}
    </>
  );
};

HistoricFilters.propTypes = {
  onConfirmFilter: PropTypes.func.isRequired,
  renderSearch: PropTypes.bool,
  pageName: PropTypes.string,
};

export default HistoricFilters;
