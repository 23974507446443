import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import langValues from 'Config/i18n/index';
import PageHeader from 'Components/Shared/PageHeader/PageHeader';
import ParametersStepsUpdate from 'Components/Atoms/ParametersStepsUpdate';
import TabsSelector from 'Components/Molecules/TabsSelector/index';
import List from 'Components/Shared/List/List';
import ListParametersSteps from 'Components/Molecules/ListParametersSteps/ListParametersSteps';
import { getHeadersWithTooltips } from 'Components/Molecules/HeadersWithTooltips/HeadersWithTooltips';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';
import SnackbarTransactions from 'Components/Atoms/SnackbarTransactions/SnackbarTransactions';
import ConfirmationModal from 'Components/Molecules/ConfirmationModal/ConfirmationModal';
import colorVariables from 'Utils/Styles/colorVariables';
import { roleConsts, trackedEvents } from 'Utils/consts';
import { User } from 'Common/Helpers/AuthHelper';
import { safeNormalizeString } from 'Utils/safeMethods';
import { trackEvent } from 'Utils/analytics';
import SnackBarExport from 'Components/Atoms/SnackBarExport/SnackBarExport';
import './ParametersSteps.scss';

const DEFAULT_VERSION_CODE = '000';

const ParametersSteps = ({ ...props }) => {
  const {
    parametersDuck,
    loadStepParameters,
    renderErrorMessage,
    renderSkeleton,
    saveStepParameters,
    clearErrorSaveStepParameters,
    clearStepParameters,
    exportStep,
    clearStepParametersExport,
  } = props;

  useEffect(() => {
    clearStepParameters();
    loadStepParameters();
  }, []);

  const {
    stepParameters,
    successSavingStepParameters,
    errorSavingStepParameters,
    stepParametersExport,
  } = parametersDuck;

  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const [currentActiveModel, setCurrentActiveModel] = useState(null);
  const [updatableStepParameters, setUpdatableStepParameters] = useState([]);
  const [editing, setEditing] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  useEffect(() => {
    setUpdatableStepParameters(stepParameters);
  }, [stepParameters]);

  const tabsList = langValues.macroAreaSteps.map((item, key) => (
    {
      id: key,
      label: item.name,
      value: item.steps,
    }
  ));

  const getListContentGrid = () => {
    const numberSteps = tabsList.find(item => item.id === currentActiveTab).value.length;
    let grid = '0.2fr 0.75fr 1.6fr';
    for (let i = 0; i < numberSteps; i += 1) {
      grid += ' 0.8fr';
    }
    return {
      gridTemplateColumns: grid,
    };
  };

  const renderTabs = () => (
    <TabsSelector
      changeActiveTab={value => setCurrentActiveTab(value)}
      currentActiveTab={currentActiveTab}
      tabsList={tabsList}
    />
  );

  const getListHeaders = () => {
    const stepHeadersIds = tabsList.find(item => item.id === currentActiveTab).value;
    const listHeaders = [
      {
        label: ' ',
      },
      {
        label: langValues.model,
      },
      {
        label: langValues.code,
      },
      ...stepHeadersIds.map(
        item => (
          {
            label: langValues.parametersTimeLabels.steps[item],
            helpText: langValues.parametersTimeLabels.tooltipText[item],
          }
        ),
      ),
    ];

    return getHeadersWithTooltips(listHeaders);
  };

  const handleInputChanging = (modelCode, versionCode, stepId, value) => {
    const changedSteps = [...updatableStepParameters];
    const index = changedSteps.findIndex(
      item => item.modelCode === modelCode
        && item.versionCode === versionCode
        && item.stepId === stepId,
    );
    changedSteps[index] = {
      ...changedSteps[index],
      edited: value,
    };
    setUpdatableStepParameters(changedSteps);
  };

  const pushNewStep = (stepsList, item) => {
    const isItemEditedNotNull = item.edited !== undefined;
    stepsList.push({
      step: item.stepId,
      parameter: isItemEditedNotNull ? item.edited : item.parameter,
      isEdited: isItemEditedNotNull ? item.edited !== item.parameter : false,
    });
  };

  const getNewModel = item => ({
    name: item.modelName,
    code: item.modelCode,
    parent: null,
    setCurrentActiveModel,
    handleInputChanging,
    steps: [],
  });

  const getNewVersion = item => ({
    name: item.versionName,
    code: item.versionCode,
    parent: item.modelCode,
    handleInputChanging,
    hasChildren: false,
    steps: [],
  });

  const getListItems = () => {
    const stepHeadersIds = tabsList.find(item => item.id === currentActiveTab).value;
    const parametersList = [...updatableStepParameters];
    const tabListItems = parametersList.filter(item => (
      stepHeadersIds.some(step => step === item.stepId)
    ));
    tabListItems.sort((a, b) => (
      safeNormalizeString(a.modelName) > safeNormalizeString(b.modelName) ? 1 : -1
    ));
    const modelsList = [...new Set(tabListItems.map(item => item.modelCode))];
    return modelsList.reduce((accList, modelCode) => {
      const modelSteps = tabListItems.filter(
        item => item.modelCode === modelCode && item.versionCode === DEFAULT_VERSION_CODE,
      );

      const modelItem = modelSteps.reduce((accModel, cur) => {
        let model = accModel;
        if (Object.keys(model).length === 0) {
          model = getNewModel(cur);
        }

        pushNewStep(model.steps, cur);
        return model;
      }, {});

      const versionsSteps = tabListItems.filter(
        item => item.modelCode === modelCode && item.versionCode !== DEFAULT_VERSION_CODE,
      );

      if (currentActiveModel === modelCode) {
        const versionItems = versionsSteps.reduce((accVersion, cur) => {
          let version = accVersion.find(item => item.code === cur.versionCode) || {};
          if (Object.keys(version).length === 0) {
            version = getNewVersion(cur);
            accVersion.push(version);
          }

          pushNewStep(version.steps, cur);
          return accVersion;
        }, []);
        versionItems.sort((a, b) => (
          safeNormalizeString(a.name) > safeNormalizeString(b.name) ? 1 : -1
        ));
        modelItem.children = versionItems;
      }

      modelItem.hasChildren = versionsSteps.length > 0;
      return accList.concat([modelItem]);
    }, []);
  };

  const renderList = () => {
    const {
      errorLoadingStepParameters,
      loadingStepParameters,
    } = parametersDuck;
    if (loadingStepParameters) return renderSkeleton(10);
    if (errorLoadingStepParameters || !stepParameters || stepParameters.length === 0) {
      return renderErrorMessage();
    }

    const currentSteps = tabsList.find(item => item.id === currentActiveTab).value;

    return (
      <List
        header={getListHeaders()}
        Type={ListParametersSteps}
        childProps={{
          listItems: getListItems(),
          currentSteps,
          isEditingList: editing,
        }}
        listContentStyle={getListContentGrid()}
      />
    );
  };

  const getEditedParameters = () => {
    let editedStepParameters = updatableStepParameters.filter(
      item => item.edited && item.edited !== item.parameter,
    );

    editedStepParameters = editedStepParameters.map(item => (
      {
        modelName: item.modelName,
        modelCode: item.modelCode,
        versionName: item.versionName,
        versionCode: item.versionCode,
        stepId: item.stepId,
        parameter: item.edited,
      }
    ));

    return editedStepParameters;
  };

  const handleNonChanging = () => {
    setEditing(false);
    setUpdatableStepParameters(stepParameters);
  };

  const renderCancelingButton = editedParametersExists => (
    <MaterialButton
      onClick={
        editedParametersExists
          ? () => setCancelModalOpen(true)
          : () => handleNonChanging()
      }
      buttonText={langValues.parametersTimeLabels.cancel}
      type="secondary"
      variant="outlined"
    />
  );

  const renderSaveButton = editedParametersExists => (
    <MaterialButton
      onClick={
        editedParametersExists
          ? () => setSaveModalOpen(true)
          : () => handleNonChanging()
      }
      buttonText={langValues.parametersTimeLabels.save}
      type="primary"
      variant="contained"
    />
  );

  const renderEditingButtons = () => {
    if (User.get().userProfile.includes(roleConsts.admin)) {
      if (editing) {
        const editedParametersExists = getEditedParameters().length > 0;
        return (
          <div className="editingButtons">
            {renderCancelingButton(editedParametersExists)}
            {renderSaveButton(editedParametersExists)}
          </div>
        );
      }
      return (
        <MaterialButton
          onClick={() => setEditing(true)}
          buttonText={langValues.parametersTimeLabels.edit}
          type="primary"
          variant="contained"
        />
      );
    }
    return null;
  };

  const handleCancelingConfirmation = () => {
    setUpdatableStepParameters(stepParameters);
    setCancelModalOpen(false);
    setEditing(false);
  };

  const handleSavingConfirmation = async () => {
    const success = await saveStepParameters(getEditedParameters());
    setSaveModalOpen(false);
    if (success) {
      setEditing(false);
    }
  };

  const handleDownloadReport = (fileType) => {
    trackEvent(trackedEvents.dataExport, { format: fileType });
    exportStep(fileType);
  };

  return (
    <div className="parametersSteps">
      <PageHeader
        title={(
          <div className="title">
            {langValues.settingsParametersScreen.stepsAndMacroAreas}
          </div>
        )}
        subtitle={<ParametersStepsUpdate />}
        shouldRenderExportButton={!editing}
        shouldRenderUnregisteredVersions={User.get().userProfile.includes(roleConsts.admin)}
        shouldRenderUnregisteredData={User.get().userProfile.includes(roleConsts.admin)}
        loadingExport={stepParametersExport.loading}
        handleDownloadReport={handleDownloadReport}
        RightComponent={() => renderEditingButtons()}
      />
      <div className="content">
        {renderTabs()}
        <div className="wrapper">
          <div className="stepsTable">
            {renderList()}
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={saveModalOpen}
        title={langValues.settingsParametersScreen.saveModalTitle}
        text={langValues.settingsParametersScreen.saveModalText}
        confirmButtonColor={colorVariables.actionPrimaryColor}
        confirmButtonText={langValues.save}
        onClose={() => setSaveModalOpen(false)}
        onConfirm={() => handleSavingConfirmation()}
        onCancel={() => setSaveModalOpen(false)}
      />
      <ConfirmationModal
        isOpen={cancelModalOpen}
        title={langValues.settingsParametersScreen.discardModalTitle}
        text={langValues.settingsParametersScreen.discardModalText}
        confirmButtonColor={colorVariables.errorColor}
        confirmButtonText={langValues.discard}
        onClose={() => setCancelModalOpen(false)}
        onConfirm={() => handleCancelingConfirmation()}
        onCancel={() => setCancelModalOpen(false)}
      />
      <SnackbarTransactions
        type="success"
        isOpen={successSavingStepParameters}
        message={langValues.parametersTimeLabels.success}
      />
      <SnackbarTransactions
        type="failure"
        isOpen={errorSavingStepParameters}
        message={langValues.parametersTimeLabels.error}
        onClose={clearErrorSaveStepParameters}
        shouldRenderOnCloseButton
      />
      <SnackBarExport open={stepParametersExport.error} onClose={clearStepParametersExport} />
    </div>
  );
};

ParametersSteps.propTypes = {
  loadStepParameters: PropTypes.func.isRequired,
  parametersDuck: PropTypes.object.isRequired,
  renderErrorMessage: PropTypes.func.isRequired,
  renderSkeleton: PropTypes.func.isRequired,
  saveStepParameters: PropTypes.func.isRequired,
  clearErrorSaveStepParameters: PropTypes.func.isRequired,
  clearStepParameters: PropTypes.func.isRequired,
  exportStep: PropTypes.func.isRequired,
  clearStepParametersExport: PropTypes.func.isRequired,
};

export default ParametersSteps;
