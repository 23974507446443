import React from 'react';
import PropTypes from 'prop-types';
import langValues from 'Config/i18n/index';
import EditTemplateModal from 'Components/Atoms/EditTemplateModal/EditTemplateModal';
import './VersionModal.scss';

const VersionModal = ({ isOpen, modalType, saveVersion, cancelModal, version, setVersion }) => {
  const { name, code } = version;
  const { setName, setCode } = setVersion;

  const isVersionCodeDisabled = modalType === 'edit';
  const titleModal = isVersionCodeDisabled ? langValues.versionModal.edit : langValues.versionModal.add;

  const renderLabels = () => (
    <div className="versionModal-labels">
      <div className="versionModal-input">
        <span className="versionModal-title">{langValues.version}</span>
        <input
          className="versionModal-inputVersion"
          type="text"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </div>
      <div className="versionModal-input">
        <span className="versionModal-title">{langValues.code}</span>
        <input
          className="versionModal-inputCode"
          type="text"
          disabled={isVersionCodeDisabled}
          value={code}
          onChange={(event) => setCode(event.target.value)}
        />
      </div>
    </div>
  );

  return (
    <EditTemplateModal
      isOpen={isOpen}
      title={titleModal}
      input={renderLabels()}
      saveAction={() => saveVersion({ code, name })}
      cancelAction={() => cancelModal()}
    />
  );
};

VersionModal.propTypes = {
  cancelModal: PropTypes.func.isRequired,
  saveVersion: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalType: PropTypes.string.isRequired,
  version: PropTypes.object.isRequired,
  setVersion: PropTypes.object.isRequired,
};

export default VersionModal;
