import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DoubleIconButton from 'Components/Atoms/DoubleIconButton/DoubleIconButton';
import { ReactComponent as CloseIcon } from 'Common/Assets/closeIcon.svg';

import './FilterPopover.scss';

const FilterPopover = ({
  IconLeft,
  buttonText,
  active,
  popoverContent,
  handleIconRightClick = () => {},
  placement = 'bottom-start',
  popperOverflow = true,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const divRef = useRef();
  function handleOpenFilterMenu() {
    setAnchorEl(anchorEl ? null : divRef.current);
  }

  return (
    <div ref={divRef}>
      <DoubleIconButton
        text={buttonText}
        active={active}
        IconLeft={IconLeft}
        IconRight={active ? CloseIcon : undefined}
        onButtonClick={handleOpenFilterMenu}
        onIconRightClick={handleIconRightClick}
      />
      <Popper
        id={'simple-popover'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={placement}
        disablePortal={false}
        modifiers={[
          popperOverflow
            ? {
                name: 'preventOverflow',
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: true,
                  tether: true,
                  rootBoundary: 'document',
                  padding: 8,
                },
              }
            : {},
        ]}
      >
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <article>{popoverContent(setAnchorEl)}</article>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

FilterPopover.propTypes = {
  IconLeft: PropTypes.elementType.isRequired,
  buttonText: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  popoverContent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  handleIconRightClick: PropTypes.func,
  placement: PropTypes.any,
  popperOverflow: PropTypes.bool,
};

export default FilterPopover;
