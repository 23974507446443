import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { withStyles } from 'tss-react/mui';
import MuiCheckbox from '@mui/material/Checkbox';
import MuiFormControlLabel from '@mui/material/FormControlLabel';

export const ExpansionPanel = withStyles(Accordion, (_theme, _params, classes) => ({
  root: {
    borderStyle: 'none',
    borderWidth: '1px 0px 1px 0px',
    border: 'rgba(0,0,0,.125)',
    boxShadow: 'none',
    margin: 0,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    [`&.${classes.expanded}`]: {
      margin: 'auto',
    },
  },
  expanded: {},
}));

export const ExpansionPanelDetails = withStyles(AccordionDetails, {
  root: {
    display: 'block',
    padding: '8px 22px',
  },
});

export const ExpansionPanelDetailsSearchBox = withStyles(ExpansionPanelDetails, {
  root: {
    padding: '0px 24px 16px 24px',
  },
});

export const ExpansionPanelSummary = withStyles(AccordionSummary, (_theme, _params, classes) => ({
  root: {
    height: '56px',
    padding: '0px 24px 0px 24px',
  },
  content: {
    [`&.${classes.expanded}`]: {
      margin: '12px 0',
    },
  },
  expanded: {},
}));

export const Checkbox = withStyles(MuiCheckbox, {
  root: {
    width: '10px',
    padding: '12px',
  },
  checked: {
    color: '#657DD9 !important',
    width: '10px',
  },
  colorPrimary: {
    color: '#657DD9',
  },
});

export const FormControlLabel = withStyles(MuiFormControlLabel, {
  label: {
    typography: { useNextVariants: true },
  },
});

export const StylableFormControlLabel = (props) =>
  withStyles(FormControlLabel, {
    ...props,
  });
