import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SliderCarousel = ({ settings, setSliderRef, children }) => (
  <Slider {...settings} ref={slider => setSliderRef(slider)}>
    {children}
  </Slider>
);

SliderCarousel.propTypes = {
  settings: PropTypes.object.isRequired,
  setSliderRef: PropTypes.func.isRequired,
  children: PropTypes.array.isRequired,
};

export default SliderCarousel;
