import React from 'react';
import PropTypes from 'prop-types';
import { trackedEvents, trackedButtons } from 'Utils/consts';
import { trackEvent } from 'Utils/analytics';
import { getTimestampWithoutHours } from 'Utils/dateUtils';
import DatePicker from 'Components/Molecules/DatePicker/DatePicker';
import langValues from 'Config/i18n/index';
import { ReactComponent as CalendarIcon } from 'Common/Assets/calendarIcon.svg';
import FilterPopover from 'Components/Molecules/FilterPopover/FilterPopover';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';

const FilterDates = ({
  handleConfirm,
  filterPlacement,
  isPageDelayManamegent,
  setSelectedDates,
  setAppliedDates,
  clearDateFilters,
  clearDelayDateFilters,
  setDelaySelectedDates,
  setDelayAppliedDates,
  currentDuckState,
}) => {
  const { selectedDates, selectedDelayDates, appliedDates, appliedDelayDates } = currentDuckState;

  let currentSelectedDates;
  let currentAppliedDates;
  let setCurrentSelectedDates;
  let setCurrentAppliedDates;
  let clearDates;
  let filterDateTitle;
  let filterDateSubTitle;
  if (isPageDelayManamegent) {
    currentSelectedDates = { ...selectedDelayDates };
    currentAppliedDates = { ...appliedDelayDates };
    setCurrentSelectedDates = setDelaySelectedDates;
    setCurrentAppliedDates = setDelayAppliedDates;
    clearDates = clearDelayDateFilters;
    filterDateTitle = langValues.filterDates.titleDelay;
    filterDateSubTitle = langValues.filterDates.subtitleDelay;
  } else {
    currentSelectedDates = { ...selectedDates };
    currentAppliedDates = { ...appliedDates };
    setCurrentSelectedDates = setSelectedDates;
    setCurrentAppliedDates = setAppliedDates;
    clearDates = clearDateFilters;
    filterDateTitle = langValues.filterDates.title;
    filterDateSubTitle = langValues.filterDates.subtitle;
  }

  const handleStartDateChange = (newDate) => {
    const newSelectedDates = {
      ...currentSelectedDates,
      startDate: newDate,
    };
    setCurrentSelectedDates(newSelectedDates);
  };

  const handleEndDateChange = (newDate) => {
    const newSelectedDates = {
      ...currentSelectedDates,
      endDate: newDate,
    };
    setCurrentSelectedDates(newSelectedDates);
  };

  const createMixPanelFilterDatesEvent = () => {
    trackEvent(trackedEvents.filterApply, { selectedFilters: ['dateFilter'] });
  };

  const onApplyFilterDates = async () => {
    const newCurrentAppliedDates = {
      ...currentSelectedDates,
      isUsingDateFilter: true,
    };

    await setCurrentAppliedDates(newCurrentAppliedDates);
    createMixPanelFilterDatesEvent();
    handleConfirm();
  };

  const onClearFilterDates = async () => {
    await clearDates();
    trackEvent(trackedEvents.buttonClick, { button: trackedButtons.clearDatesFilter });
    handleConfirm();
  };

  const disabled =
    getTimestampWithoutHours(currentSelectedDates.startDate) > getTimestampWithoutHours(currentSelectedDates.endDate);

  const popover = (setAnchorEl) => (
    <section className="filterDates">
      <span className="font-subtitle1">{filterDateTitle}</span>
      <span className="font-body2 filterDateSubtitle">{filterDateSubTitle}</span>
      <div className="datePickers">
        <DatePicker
          handleDateChange={handleStartDateChange}
          selectedDate={currentSelectedDates.startDate}
          label={langValues.filterDates.initialDate}
        />
        <DatePicker
          handleDateChange={handleEndDateChange}
          selectedDate={currentSelectedDates.endDate}
          label={langValues.filterDates.finalDate}
        />
      </div>
      <div className="filterButton filterButtonDates">
        <button
          type="button"
          className="cleanAll-text"
          onClick={() => {
            onClearFilterDates();
            setAnchorEl(null);
          }}
        >
          {langValues.clear}
        </button>
        <MaterialButton
          onClick={() => {
            onApplyFilterDates();
            setAnchorEl(null);
          }}
          buttonText={langValues.apply}
          type="primary"
          disabled={disabled}
        />
      </div>
    </section>
  );

  const filterDatesButtonText = currentAppliedDates.isUsingDateFilter
    ? langValues.filterLabels.changeDates
    : langValues.filterLabels.filterDates;

  return (
    <FilterPopover
      IconLeft={CalendarIcon}
      buttonText={filterDatesButtonText}
      active={currentAppliedDates.isUsingDateFilter}
      handleIconRightClick={onClearFilterDates}
      placement={filterPlacement}
      popoverContent={popover}
    />
  );
};

FilterDates.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  filterPlacement: PropTypes.string.isRequired,
  isPageDelayManamegent: PropTypes.bool.isRequired,
  setSelectedDates: PropTypes.func.isRequired,
  setAppliedDates: PropTypes.func.isRequired,
  clearDateFilters: PropTypes.func.isRequired,
  clearDelayDateFilters: PropTypes.func.isRequired,
  setDelaySelectedDates: PropTypes.func.isRequired,
  setDelayAppliedDates: PropTypes.func.isRequired,
  currentDuckState: PropTypes.object.isRequired,
};

export default FilterDates;
