import moment from 'moment';
import { createSearchParams } from 'react-router-dom';
import AuthService from 'Infrastructure/Services/AuthService';
import { Token, User, Dealer } from 'Common/Helpers/AuthHelper';
import { roleConsts } from 'Utils/consts';

export const DUCK_NAME = 'auth';

const INITIAL_STATE = () => ({
  currentScreen: 'login',
  lastUpdateDateTime: null,
  data: {},
  logged: false,
  loginLoading: false,
  loginError: null,
  expiredSession: false,
  logoutLoading: false,
  logoutError: null,
  success: false,
  pageLoading: false,
  pageLoadingError: null,
  pageLoadingSuccess: false,
  isExpiredModalOpen: false,
});

export const CHANGE_SCREEN = `${DUCK_NAME}/CHANGE_SCREEN`;
export const changePageScreen = (newScreen) => ({ type: CHANGE_SCREEN, newScreen });

export const LOAD_AUTH_STARTED = `${DUCK_NAME}/LOAD_AUTH_STARTED`;
export const LOAD_AUTH_SUCCEED = `${DUCK_NAME}/LOAD_AUTH_SUCCEED`;
export const LOAD_AUTH_FAILED = `${DUCK_NAME}/LOAD_AUTH_FAILED`;
export const INITIALIZE_LOGIN = `${DUCK_NAME}/INITIALIZE_LOGIN`;
export const SET_EXPIRED_MODAL_OPEN = `${DUCK_NAME}/SET_EXPIRED_MODAL_OPEN`;

export const loadAuthStarted = () => ({ type: LOAD_AUTH_STARTED });
export const loadAuthSucceed = (data, newScreen) => ({ type: LOAD_AUTH_SUCCEED, data, newScreen });
export const loadAuthFailed = (error) => ({ type: LOAD_AUTH_FAILED, error });
export const initializeLogin = () => ({ type: INITIALIZE_LOGIN });
export const setExpiredModalOpen = () => ({ type: SET_EXPIRED_MODAL_OPEN });

export const loadAuth =
  ({ userName, password, userType, navigate, dealerUserId }) =>
  async (dispatch) => {
    dispatch(loadAuthStarted());
    const authService = new AuthService();
    try {
      const data = await authService.login({ userName, password, userType, dealerUserId });
      const { userData } = data;
      if (userData) {
        Token.save(userData);
        User.save(userData);
        if (userType === roleConsts.dealers) {
          const { dealerData } = data;
          Dealer.save(dealerData);
        }
        dispatch(loadAuthSucceed(data, 'pedidos'));
        navigate('/pedidos');
      }
    } catch (err) {
      window.localStorage.clear();
      dispatch(loadAuthFailed(err));
    }
  };

export const loadClientAuth =
  ({ clientDocument, orderCode, navigate, captchaToken }) =>
  async (dispatch) => {
    dispatch(loadAuthStarted());
    const authService = new AuthService();
    try {
      await authService.checkCaptcha(captchaToken);
      const data = await authService.clientLogin(clientDocument, orderCode);
      const { userData } = data;
      if (userData) {
        Token.save(userData);
        User.save(userData);
        dispatch(loadAuthSucceed(data.userData, 'clientes'));
        navigate('/clientes/pedido');
      }
    } catch (err) {
      window.localStorage.clear();
      dispatch(loadAuthFailed(err));
    }
  };

export const loadEmployeeAuth =
  ({ finalEmployeeCPF, userName, password, navigate, isAutoLogin }) =>
  async (dispatch) => {
    dispatch(loadAuthStarted());
    const authService = new AuthService();
    try {
      const employeeData = await authService.employeeCpfLogin(finalEmployeeCPF);
      const userToken = await authService.employeeUserLogin({ userName, password, userType: roleConsts.employees });
      const finalEmployeeData = employeeData.userData;
      finalEmployeeData.userToken = userToken.userToken;
      finalEmployeeData.tokenType = userToken.tokenType;
      if (finalEmployeeData && userToken) {
        Token.save(finalEmployeeData);
        User.save(finalEmployeeData);
        dispatch(loadAuthSucceed(employeeData, 'empregados'));
        navigate({
          pathname: `/empregados/${finalEmployeeCPF}`,
          search: createSearchParams({ isAutoLogin: isAutoLogin }).toString(),
        });
      }
    } catch (err) {
      window.localStorage.clear();
      dispatch(loadAuthFailed(err));
    }
  };

export const LOAD_LOGOUT_STARTED = `${DUCK_NAME}/LOAD_LOGOUT_STARTED`;
export const LOAD_LOGOUT_FAILED = `${DUCK_NAME}/LOAD_LOGOUT_FAILED`;
export const LOAD_LOGOUT_SUCCEED = `${DUCK_NAME}/LOAD_LOGOUT_SUCCEED`;
export const LOAD_EXPIRED_SESSION = `${DUCK_NAME}/LOAD_EXPIRED_SESSION`;

export const loadLogoutStarted = () => ({ type: LOAD_LOGOUT_STARTED });
export const loadLogoutSucceed = (data = {}) => ({
  type: LOAD_LOGOUT_SUCCEED,
  data,
});
export const loadLogoutFailed = (error) => ({ type: LOAD_LOGOUT_FAILED, error });
export const loadExpiredSession = (data = {}) => ({
  type: LOAD_EXPIRED_SESSION,
  data,
});

export const loadLogout = (expired) => async (dispatch) => {
  const authService = new AuthService();
  dispatch(loadLogoutStarted());
  try {
    const hasEmployeeRole = User.hasProfile(roleConsts.employees);
    await authService.logout();
    localStorage.clear();
    Token.remove();
    User.remove();
    Dealer.remove();
    if (expired) {
      dispatch(loadExpiredSession());
    } else {
      dispatch(loadLogoutSucceed());
    }
    if (window.location.pathname !== '/login' && !hasEmployeeRole) {
      window.location.pathname = '/login';
    } else if (window.location.pathname !== '/login/empregados' && hasEmployeeRole) {
      window.location.pathname = '/login/empregados';
    }
    return Promise.resolve({});
  } catch (error) {
    localStorage.clear();
    dispatch(loadLogoutFailed(error.response.data));
    return Promise.reject(error.response);
  }
};

export const checkAuth = () => async (dispatch, getState) => {
  const authService = new AuthService();
  try {
    const isLoading = getState().auth.loginLoading;

    if (!isLoading) {
      const authState = await authService.checkAuthState();
      if (!authState) {
        await dispatch(loadLogout(false));
      }
    }
  } catch (err) {
    dispatch(loadLogout(false));
  }
};

const reducer = (state = INITIAL_STATE(), action) => {
  switch (action.type) {
    case INITIALIZE_LOGIN:
      return INITIAL_STATE();
    case LOAD_AUTH_STARTED:
      return {
        ...state,
        lastUpdateDateTime: moment().toISOString(),
        loginLoading: true,
      };
    case LOAD_AUTH_SUCCEED:
      return {
        ...state,
        lastUpdateDateTime: moment().toISOString(),
        logged: true,
        loginLoading: false,
        loginError: null,
        success: true,
        data: {
          ...action.data,
        },
        currentScreen: action.newScreen,
      };
    case LOAD_AUTH_FAILED:
      return {
        ...state,
        lastUpdateDateTime: moment().toISOString(),
        logged: false,
        loginLoading: false,
        success: false,
        loginError: action.error,
      };

    case LOAD_LOGOUT_STARTED:
      return {
        ...state,
        logoutLoading: true,
      };
    case LOAD_LOGOUT_SUCCEED:
      return {
        ...INITIAL_STATE(),
        lastUpdateDateTime: moment().toISOString(),
      };
    case LOAD_EXPIRED_SESSION:
      return {
        ...INITIAL_STATE(),
        lastUpdateDateTime: moment().toISOString(),
        expiredSession: true,
      };
    case LOAD_LOGOUT_FAILED:
      return {
        ...state,
        logoutLoading: false,
        logoutError: action.error,
      };
    case CHANGE_SCREEN:
      return {
        ...state,
        currentScreen: action.newScreen,
      };
    case SET_EXPIRED_MODAL_OPEN:
      return {
        ...state,
        isExpiredModalOpen: true,
      };
    default:
      return state;
  }
};

export default reducer;
