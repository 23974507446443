import React from 'react';
import PropTypes from 'prop-types';
import SubMenu from 'Components/Molecules/SubMenu';
import DrawerVision from 'Components/Atoms/BaseDrawer';
import './DrawerSubMenu.scss';

const DrawerSubMenu = ({
  load, title,
  isSubMenuOpen, setSubMenuOpen,
  subMenuList, currentActiveSubMenu, setCurrentActiveSubMenu,
}) => {
  const changeTab = (id) => {
    setCurrentActiveSubMenu(id);
    load(subMenuList.find(item => item.id === id).value);
    setSubMenuOpen(false);
  };

  const Drawer = DrawerVision();

  return (
    <Drawer
      anchor="left"
      open={isSubMenuOpen}
      variant="temporary"
      BackdropProps={{ invisible: true }}
      onClose={() => setSubMenuOpen(false)}
    >
      <div className="drawerSubMenu">
        <SubMenu
          currentActiveOption={currentActiveSubMenu}
          changeActiveOption={id => changeTab(id)}
          optionList={subMenuList}
          title={{ label: title }}
        />
      </div>
    </Drawer>
  );
};

DrawerSubMenu.propTypes = {
  load: PropTypes.func.isRequired,
  setSubMenuOpen: PropTypes.func.isRequired,
  isSubMenuOpen: PropTypes.bool.isRequired,
  subMenuList: PropTypes.array.isRequired,
  currentActiveSubMenu: PropTypes.number.isRequired,
  setCurrentActiveSubMenu: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default DrawerSubMenu;
