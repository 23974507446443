import { connect } from 'react-redux';
import { setSubMenuOpen } from 'Infrastructure/Ducks/VisionConfigurationDuck';
import { setCommercialDrawerOpen } from 'Infrastructure/Ducks/OrderDetailDuck';
import App from './App';


const mapStateToProps = (state) => {
  const { currentScreen } = state.auth;
  return {
    currentScreen,
  };
};

const mapDispatchToProps = dispatch => ({
  setSubMenuOpen: (status) => {
    dispatch(setSubMenuOpen(status));
  },
  setCommercialDrawerOpen: (status) => {
    dispatch(setCommercialDrawerOpen(status));
  },
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
