import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as WhatsappIcon } from 'Common/Assets/whatsappsvg.svg';
import { roleConsts, trackedButtons, trackedEvents } from 'Utils/consts';
import './PhoneContact.scss';
import { Dealer, User } from 'Common/Helpers/AuthHelper';
import { trackEvent } from 'Utils/analytics';

const messageWhatsapp = (orderId) => {
  if (User.hasProfile(roleConsts.dealers)) {
    const { dealerId } = Dealer.get();
    return `&text=Olá,%20o%20código%20da%20minha%20concessionária%20é:%20${dealerId}`;
  }

  return `&text=Olá,%20gostaria%20de%20informações%20sobre%20o%20pedido%20${orderId}.`;
};

const phoneContactUserLink = (phoneNumber, orderId) => {
  let imageIcon = null;
  let phoneText = null;
  const phoneNumberFormatted = phoneNumber.replace(/[^\d]/gi, '');
  imageIcon = <WhatsappIcon className="phoneImage" />;
  phoneText = (
    <a
      href={`https://api.whatsapp.com/send?phone=${phoneNumberFormatted}${messageWhatsapp(orderId)}`}
      onClick={() => trackEvent(trackedEvents.buttonClick, { button: trackedButtons.whatsappCarVendas })}
    >
      {phoneNumber}
    </a>
  );

  return { imageIcon, phoneText };
};

const PhoneContact = ({ phoneSubText, phoneLabel, phoneNumber, orderId }) => {
  const { imageIcon, phoneText } = phoneContactUserLink(phoneNumber, orderId);
  return (
    <span className="phoneContact">
      {imageIcon}
      <div className="phoneText">
        <div className="phoneSalesCar">
          <span>{`${phoneLabel}: `}</span>
          {phoneText}
        </div>
        <span>{phoneSubText}</span>
      </div>
    </span>
  );
};

PhoneContact.propTypes = {
  phoneLabel: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  phoneSubText: PropTypes.string,
  orderId: PropTypes.string.isRequired,
};

export default PhoneContact;
