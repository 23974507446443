import ListPedidosRepository from 'Infrastructure/Repositories/ListPedidosRepository';
import { changeStringToAPattern } from 'Utils/stringUtils';
import BaseService from './BaseService';

class ListPedidosService {
  constructor(listRepository = new ListPedidosRepository()) {
    this.listRepository = listRepository;
    this.baseService = new BaseService();
  }

  async add({ id, text }) {
    try {
      const { data } = await this.listRepository.add({ id, text });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getList(page, searchString, selected, dates, isBilledFilter, isPlanAhorro, searchStringAhorro, ahorroFilters, getNewPdaEndpoint) {
    const timestampDate = this.baseService.getTimestampDate(dates);
    try {
      const { data } = await this.listRepository.getList(
        page, changeStringToAPattern(searchString), selected, timestampDate, isBilledFilter, 10, isPlanAhorro, searchStringAhorro, ahorroFilters, getNewPdaEndpoint,
      );
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getResume(searchString, selected, dates) {
    const timestampDate = this.baseService.getTimestampDate(dates);
    try {
      const { data } = await this.listRepository.getResume(searchString, selected, timestampDate);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async export(searchString, filters, fileType, isPlanAhorro, filtersExportPDA) {
    const fileName = 'EasyTrackingData';
    const method = isPlanAhorro ? 'ordersPDA/report?' : 'orders/report?';
    return this.baseService.export(searchString, filters, method, fileName, fileType, isPlanAhorro, filtersExportPDA);
  }

  async getExportCountPda(filtersExportPDA) {
    return this.baseService.getExportCountPda(filtersExportPDA);
  }

  async exportLateOrders(searchString, filters, fileType) {
    const fileName = 'OrdersLateData';
    const method = 'orders/late/report?';
    return this.baseService.export(searchString, filters, method, fileName, fileType, false);
  }

  async getResumeHistory(monthBegin, monthEnd) {
    try {
      const { data } = await this.listRepository.getResumeHistory(monthBegin, monthEnd);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getClientOrderTotalById(clientDocument) {
    try {
      const { data } = await this.listRepository.getClientTotalOrdersById(clientDocument);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default ListPedidosService;
