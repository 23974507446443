import React, { useState } from 'react';
import PropTypes from 'prop-types';

import langValues from 'Config/i18n/index';

import TitlePedidoComercial from 'Components/ComercialDetailsScreenComponents/PedidoComercial/TitlePedidoComercial/TitlePedidoComercial';
import CarDetailImage from 'Components/Atoms/CarDetailImage';
import SystemUpdate from 'Components/Atoms/SystemUpdate';
import CarDetailBox from 'Components/Molecules/CarDetailBox/index';
import DadosCliente from 'Components/ComercialDetailsScreenComponents/PedidoComercial/DadosCliente/DadosCliente';
import DadosConcessionaria from 'Components/ComercialDetailsScreenComponents/PedidoComercial/DadosConcessionaria/DadosConcessionaria';
import ResizeButton from 'Components/Atoms/ResizeButton/ResizeButton';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

import './ClientOrderMobile.scss';
import '../../ComercialDetailsScreenComponents/PedidoComercial/TitlePedidoComercial/invoiceDownload.scss';
import '../../OrderListComponents/ListItemPedidos/ListItemPedidos.scss';
import '../../ComercialDetailsScreenComponents/PedidoComercial/DadosCliente/Pendencia/Pendencia.scss';

function ClientOrderMobile({ order, industrialOrder }) {
  const [detailBoxOpen, setDetailBoxOpen] = useState(false);

  const renderSystemUpdate = () => <SystemUpdate />;

  const renderTitle = () => {
    const brandId =
      order.industrialOrdersList && order.industrialOrdersList.length > 0
        ? order.industrialOrdersList[0].brandId
        : null;
    return (
      <div className="titulo-pedido">
        <TitlePedidoComercial
          orderId={order.orderId}
          orderType={order.orderType}
          brandId={brandId}
          status={order.statusId}
        />
      </div>
    );
  };

  const renderCarDetailImage = () => <CarDetailImage industrialOrder={industrialOrder} />;

  const renderDadosCliente = () => (
    <div className="dados-cliente-pedido">
      <DadosCliente order={order} />
    </div>
  );

  const renderCarDetailBox = () => <CarDetailBox industrialOrder={industrialOrder} />;

  const renderConcessionariaEntrega = () => {
    const concessionaria = langValues.labelsPedidoComercial.concessionariaEntrega;
    return (
      <div className="concessionaria-entrega">
        <DadosConcessionaria type={concessionaria} order={order} delivery />
      </div>
    );
  };

  const renderContactDealer = () => (
    <div className="contactDealer">
      <ErrorOutlineRoundedIcon />
      <span>{langValues.labelsPedidoComercial.contateConcessionaria}</span>
    </div>
  );

  if (!order) {
    return (
      <article className="commercialOrderDetail">
        <div className="surroundingBodyDiv">
          <div className="data-pedido skeleton" />
          <div className="dados-cliente-pedido skeleton" />
          <div className="concessionaria-entrega skeleton" />
        </div>
      </article>
    );
  }

  return (
    <article className="commercialOrderDetail">
      {renderContactDealer()}
      <div className="surroundingBodyDiv">
        {renderTitle()}
        {renderSystemUpdate()}
        {renderCarDetailImage()}
        {renderDadosCliente()}
        {renderConcessionariaEntrega()}
        <div className={`${detailBoxOpen ? 'open' : 'closed'}`}>{renderCarDetailBox()}</div>
      </div>
      <div className="resizeButton-mobile">
        <ResizeButton isDetailBoxOpen={detailBoxOpen} setDetailBoxOpen={setDetailBoxOpen} />
      </div>
    </article>
  );
}

ClientOrderMobile.propTypes = {
  order: PropTypes.object,
  industrialOrder: PropTypes.object,
};

export default ClientOrderMobile;
