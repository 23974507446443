import React from 'react';
import PropTypes from 'prop-types';
import './ResultResponse.scss';

const ResultResponse = ({ Icon, title, subtitle }) => (
  <div className="resultResponse">
    <Icon />
    {title ? <h5 className="resultResponse-title">{title}</h5> : null}
    {subtitle ? <div className="resultResponse-subtitle font-small1">{subtitle}</div> : null}
  </div>
);

ResultResponse.propTypes = {
  Icon: PropTypes.object.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default ResultResponse;
