import { connect } from 'react-redux';
import {
  loadAverageInvoiceTime, downloadAverageInvoice,
  uploadAverageInvoice, clearStateUploadInvoice, clearStateDownloadInvoice,
} from 'Infrastructure/Ducks/ModelVersion';
import ForecastInvoice from './ForecastInvoice';

const mapStateToProps = (state) => {
  const {
    modelVersionForecast,
    averageInvoiceDownload,
    averageInvoiceUpload,
  } = state.modelVersion;
  return {
    modelVersionForecast,
    averageInvoiceDownload,
    averageInvoiceUpload,
  };
};

const mapDispatchToProps = dispatch => ({
  loadAverageInvoiceTime: () => {
    dispatch(loadAverageInvoiceTime());
  },
  downloadAverageInvoice: () => {
    dispatch(downloadAverageInvoice());
  },
  uploadAverageInvoice: (file) => {
    dispatch(uploadAverageInvoice(file));
  },
  resetUploadInvoiceError: () => {
    dispatch(clearStateUploadInvoice());
  },
  resetDownloadInvoiceError: () => {
    dispatch(clearStateDownloadInvoice());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForecastInvoice);
