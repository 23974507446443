import { connect } from 'react-redux';
import { loadOrderById as loadOrderByIdPda, clearDuck as clearDuckPda } from 'Infrastructure/Ducks/OrderDetailPdaDuck';
import { loadOrderById, clearDuck, changeSelectedIndustrialOrder } from 'Infrastructure/Ducks/OrderDetailDuck';

import OrderDetail from './OrderDetailPDA';

const mapStateToProps = (state) => {
  const { orderDetail, orderDetailPda } = state;

  const { currentActiveTab, success, loading } = orderDetail;

  return {
    order: orderDetail.order,
    selectedIndustrialOrder: orderDetail.selectedIndustrialOrder,
    pda: orderDetailPda.pda,
    loading: orderDetail.loading || orderDetailPda.loading,
    currentActiveTab,
    requestSuccess: success,
    requestLoading: loading,
  };
};

const mapDispatchToProps = dispatch => ({
  loadOrderById: (orderId, callback) => {
    dispatch(loadOrderById(orderId, callback));
  },
  clearDuck: () => {
    dispatch(clearDuck());
  },
  loadOrderByIdPda: (params, callback) => {
    dispatch(loadOrderByIdPda(params, callback));
  },
  clearDuckPda: () => {
    dispatch(clearDuckPda());
  },
  changeSelectedIndustrialOrder: (industrialOrder) => {
    dispatch(changeSelectedIndustrialOrder(industrialOrder));
  },
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderDetail);
