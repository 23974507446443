/* eslint-disable class-methods-use-this */
import { fileTypes } from 'Utils/consts';
import RequestRepository from './RequestRepository';

class ListRegionRepository {
  get() {
    const parameters = 'parameters/regions';
    return RequestRepository.get(parameters);
  }

  getCitiesWithRegions() {
    const parameters = 'parameters/regionsWithCities';
    return RequestRepository.get(parameters);
  }

  put(incomingData) {
    const parameters = 'parameters/regions';
    const body = incomingData;

    return RequestRepository.put(parameters, body);
  }

  putTransitTime(incomingData) {
    const parameters = 'parameters/regionsWithCities';
    const body = incomingData;
    return RequestRepository.put(parameters, body);
  }

  exportCities(fileType) {
    let config = {};
    if (fileType === fileTypes.csv) {
      config = {
        headers: {
          'Content-Disposition': 'attachment; filename = EasyTrackingTransitoCidades.csv;',
          'Content-Type': 'text/csv',
        },
      };
    } else {
      config = {
        headers: {
          'Content-Disposition': 'attachment; filename=EasyTrackingTransitoCidades.xlsx;',
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      };
    }
    const fileName = 'EasyTrackingTransitoCidades';
    const parameters = `parameters/cities/report?fileType=${fileType}`;

    return RequestRepository.export(parameters, config, fileName, fileType);
  }
}

export default ListRegionRepository;
