import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import { Dealer, User } from 'Common/Helpers/AuthHelper';
import { environment, roleConsts } from 'Utils/consts';

const getSecondaryDealers = () => {
  const dealer = Dealer.get();
  if (!dealer) return {};

  const { secondaryDealersIds, secondaryDealersNames } = Dealer.get();
  const secondaryDealers = {};
  secondaryDealersIds.forEach((dealerId, index) => {
    secondaryDealers[dealerId] = secondaryDealersNames[index];
  });

  return secondaryDealers;
};

const STEP_ADMINISTRATIVE = {
  pactoClaro: 'Pacto Claro',
  subscription: 'Suscripcion',
  confimationGO: 'Asignación Grupo/Orden',
  adjucation: 'Adjudicación',
  unitOrder: 'Pedido de Unidad',
  payedSpecialConcepts: 'Pago de Conceptos Especiales',
  invoiceAndUnitCE: 'Facturación + Unidad en Concesionario',
  patent: 'Patentamiento',
  unitDeliveryAndInsurance: 'Entrega de unidad + alta seguro',
  planCancelled: 'Liberación Prenda - Cancelación del Plan ',
};


const filtersBR = {
  filterPanelTitles: {
    categories: 'categorias',
    status: 'status',
    statusDelay: 'status do industrial',
    pendencies: 'pendências',
    macroAreas: 'macro áreas',
    brands: 'marcas',
    models: 'modelos',
    macroCategories: 'macro categorias',
    orderType: 'tipo de pedido',
    steps: 'etapas',
    stepsDelay: 'etapas',
    secondaryDealers: 'concessionárias da rede',
  },

  filterPanels: {
    categories: {
      2: 'Adm. Estadual',
      0: 'Adm. Federal',
      66: 'Agropecuária',
      35: 'Auto Escola',
      37: 'Empresa Fumageira',
      11: 'Fornecedor Fiasa',
      65: 'Frotista',
      61: 'Locadora',
      50: 'PCD',
      51: 'Produtor Rural',
      34: 'Taxista',
      1: 'Varejo',
    },
    status: {
      late: 'Atrasado',
      inRisk: 'Risco de Atraso',
      onTime: 'Em Dia',
      delivered: 'Entregue',
    },
    statusDelay: {
      late: 'Atrasado',
      inRisk: 'Risco de Atraso',
      onTime: 'Em Dia',
    },
    pendencies: {
      financial: 'Pendência Financeira',
      document: 'Pendência de Documentação',
      dealer: 'Concessionária Bloqueada',
      orderSwap: 'Troca de Pedido',
      qualityBlock: 'Bloqueio de Qualidade',
      outboundBlock: 'Bloqueio Outbound',
      commercialBlock: 'Bloqueio Comercial',
    },
    macroAreas: {
      orderManagement: 'Gestão de Pedidos',
      salesOperation: 'Sales Operation',
      salesDistribution: 'Distribuição de Veículos',
      dealer: 'Dealer',
    },
    steps: {
      production: 'Em produção',
      invoice: 'Aguardando Faturamento',
      dispatch: 'Aguardando Expedição',
      delivery: 'A caminho da concessionária',
      dealer: 'Na concessionária',
      refactored: 'Refaturando',
      client: 'Entregue ao cliente',
    },
    stepsDelay: {
      configuration: 'Impostação',
      assembly: 'Montagem',
      produced: 'Produção',
      invoice: 'Faturamento',
      payment: 'Pagamento',
      invoiceDelivery: 'Entrega da nota',
      dispatch: 'Expedição',
      dealer: 'Entrega à Concessionária',
      refactored: 'Refaturamento',
      delivered: 'Entrega ao Cliente',
    },
    brands: {
      fiat: 'FIAT',
      jeep: 'JEEP',
      ram: 'RAM',
    },
    macroCategories: {
      smallBusiness: 'Small Business',
      pcd: 'PCD',
      employee: 'Funcionários',
      keyAccount: 'Key Account',
    },
    orderType: {
      comercial: 'VENDA DIRETA',
      redesemocf: 'REDE SEM OCF',
      redecomocf: 'REDE COM OCF',
    },
    secondaryDealers: getSecondaryDealers(),
  },
};

const filtersAR = {
  filterPanelTitles: {
    categories: 'categorias',
    status: 'status',
    statusDelay: 'Status del Industrial',
    pendencies: 'pendientes',
    macroAreas: 'áreas macro',
    brands: 'marcas',
    models: 'modelos',
    macroCategories: 'Categorías macro',
    orderType: 'Tipo de Orden',
    steps: ENV_ENVIRONMENT === 'AR' && User.canSeePDA() ? 'Etapas Logística' : 'Etapas',
    stepsAdministrative: 'etapas administrativa',
  },

  filterPanels: {
    categories: {
      2: 'Adm. Estatal',
      0: 'Adm. Federal',
      66: 'Agroindustia',
      35: 'Autoescuela',
      37: 'Empresa Fumageira',
      11: 'Proveedor Fiasa',
      65: 'Conductor de flota',
      61: 'Empresa de alquiler',
      50: 'PCD',
      51: 'Productor Rural',
      34: 'Taxista',
      1: 'Al por menor',
    },
    status: {
      late: 'Retraso',
      inRisk: 'En riesgo',
      onTime: 'En Día',
      delivered: 'Entregado',
    },
    pendencies: {
      financial: 'Pendiente Financiera',
      document: 'Documentación Pendiente',
      dealer: 'Concesionario Bloqueado',
      orderSwap: 'Cambio de Orden',
      qualityBlock: 'Bloqueo de calidad',
      outboundBlock: 'Bloqueo Outbound',
      commercialBlock: 'Bloqueo Comercial',
    },
    macroAreas: {
      orderManagement: 'Gestión de Órdenes',
      salesOperation: 'Operación de Ventas',
      salesDistribution: 'Distribución de Vehículos',
      dealer: 'Dealer',
    },
    steps: {
      production: 'Em Producción',
      dispatch: 'Esperando llegar a la Aduana',
      waitAvailable: 'Esperando Disponibilidad',
      available: 'Disponible',
      invoice: 'Esperando Facturación',
      refactored: 'Esperando Refacturación',
      delivery: 'De Camino Al Concesionario',
      dealer: 'En El Concesionario',
    },
    stepsAdministrative: STEP_ADMINISTRATIVE,
    stepsDelay: {
      produced: 'En Producción',
      assigned: 'Asignado Sin Embarcar',
      traveling: 'En Viaje',
      customsPending: 'Pendiente de aduana',
      customsRelease: 'Liberado de Aduana',
      available: 'Disponible',
      invoice: 'Facturado',
      dispatch: 'Expedido a CE',
      dealer: 'Entregado a CE',
      delivered: 'Entregado a Cliente',
    },
    brands: {
      fiat: 'FIAT',
      jeep: 'JEEP',
      ram: 'RAM',
      abarth: 'ABARTH',
      lancia: 'LANCIA',
      alfaRomeo: 'ALFA ROMEO',
      masseratti: 'MASSERATTI',
    },
    macroCategories: {
      smallBusiness: 'Small Business',
      pcd: 'PCD',
      employee: 'Empleados',
      keyAccount: 'Key Account',
    },
    orderType: {
      dealer: 'CONCESIONARIOS',
      employee: 'EMPLEADOS',
      directSale: 'VENTA DIRECTA',
      savingsPlan: 'PLAN DE AHORRO',
      notSold: 'NO VENDIDO',
    },
  },
};

const filtersMap = {
  [environment.BR]: filtersBR,
  [environment.AR]: filtersAR,
};

const filterItems = filtersMap[ENV_ENVIRONMENT];
export default filterItems;
