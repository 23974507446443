import colorVariables from './colorVariables';

export const fontFamily = "'Helvetica Neue', sans-serif";

export const fontWeight = {
  Light: '300',
  Regular: 'normal',
  Medium: '500',
  Semibold: 'bold',
};

export const fontCommon = {
  color: colorVariables.textPrimaryColor,
  fontFamily,
  fontStyle: 'normal',
};

export const fontSubtitle1 = {
  ...fontCommon,
  fontWeight: fontWeight.Medium,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.1px',
};

export const fontSubtitle2 = {
  ...fontCommon,
  fontWeight: fontWeight.Medium,
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '0.1px',
};

export const fontBody1 = {
  ...fontCommon,
  fontWeight: fontWeight.Regular,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.1px',
};

export const fontBody2 = {
  ...fontCommon,
  fontWeight: fontWeight.Regular,
  fontSize: '14px',
  lineHeight: '22px',
  letterSpacing: '0.1px',
};

export const fontSmall1 = {
  ...fontCommon,
  fontWeight: fontWeight.Medium,
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '0.2px',
};

export const fontSmall2 = {
  ...fontCommon,
  fontWeight: fontWeight.Regular,
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '0.2px',
};

export const fontSmall3 = {
  ...fontCommon,
  fontWeight: fontWeight.Regular,
  fontSize: '10px',
  lineHeight: '14px',
  letterSpacing: '0.2px',
};

export const fontButton = {
  ...fontCommon,
  fontWeight: fontWeight.Medium,
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '0.2px',
};

export const fontInitials = {
  ...fontCommon,
  fontWeight: fontWeight.Semibold,
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0.2px',
};


export default null;
