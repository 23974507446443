import { connect } from 'react-redux';
import { setFeedbackModalOpened, setResponseModalOpened, saveFeedback } from 'Infrastructure/Ducks/FeedbackDuck';
import FeedbackModal from './FeedbackModal';

const mapStateToProps = (state) => {
  const { feedbackDuck } = state;
  const { isFeedbackModalOpened, feedbackCategories, feedbackResponse } = feedbackDuck;
  return { isFeedbackModalOpened, feedbackCategories, feedbackResponse };
};

const mapDispatchToProps = (dispatch) => ({
  setFeedbackModalOpened: (isFeedbackModalOpened) => {
    dispatch(setFeedbackModalOpened(isFeedbackModalOpened));
  },
  setResponseModalOpened: (isResponseModalOpened) => {
    dispatch(setResponseModalOpened(isResponseModalOpened));
  },
  saveFeedback: (feedback) => {
    dispatch(saveFeedback(feedback));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackModal);
