export const orderListConsts = {
  naturalPerson: 'PF',
  legalPerson: 'PJ',
};

export const ordersConsts = {
  pageSize: 10,
};

export const filtersConsts = {
  modelsId: 'modelsId',
  panelOptionType: {
    checkbox: 'checkbox',
    searchbox: 'searchbox',
  },
};

export const timeLine = {
  stepNames: {
    pedido: 'Pedido',
    impostacao: 'configuration',
    montagem: 'assembly',
    produzido: 'produced',
    faturado: 'invoice',
    entregaNota: 'invoiceDelivery',
    expedicao: 'dispatch',
    concessionaria: 'dealer',
    entrega: 'delivered',
    refaturamento: 'refactored',
    pagamento: 'payment',
  },
};

export const urlWCDForgotPassword = 'http://wcd.fcalatam.com/portaldpr/auth/login';

export const appCode = 'RAET'; // 'AR.EasyTracking';

export const titleEasyTracking = {
  easyTracking: 'EASY TRACKING',
};

export const orderDetail = {
  pedidoCriado: 'Pedido criado em',
  pedidoIndustrial: 'Pedido Industrial #',
  orderDetailTitle: 'Detalhes do pedido',
};

export const deviceInfo = {
  typeMobile: 'Mobile',
  typeDesktop: 'Desktop',
  desktopMinWidth: 812,
};

export const routeConsts = {
  dealers: 'dealers',
  employees: 'clients/employees',
  client: 'clients',
  customercare: 'customercare',
  admin: 'customercare',
  default: 'customercare',
  version: 'v1',
};

export const roleConsts = {
  dealers: 'ROLE_DEALER',
  customercare: 'ROLE_CUSTOMERCARE',
  employees: 'ROLE_EMPLOYEE',
  client: 'ROLE_CLIENT',
  default: 'ROLE_CUSTOMERCARE',
  api: 'ROLE_API_FULL_ACCESS',
  ar: 'ROLE_AR',
  br: 'ROLE_BR',
  admin: 'ROLE_ADMIN',
  invoice: 'ROLE_NF',
  notFound: 'NOT_FOUND',
  ROLE_BR_XPC: 'ROLE_BR_XPC',
  ROLE_BR_XPP: 'ROLE_BR_XPP',
  ROLE_AR_XPC: 'ROLE_AR_XPC',
  ROLE_AR_XPP: 'ROLE_AR_XPP',
  permittedDealers: ['WCD_USER'],
};

export const alertTypes = {
  error: 'error',
  warn: 'warn',
  info: 'info',
};

export const fileTypes = {
  csv: 'csv',
  xlsx: 'xlsx',
};

export const idTokenErrorMessage = 'Local storage is missing an ID Token, Please authenticate';
export const noCurrentUserMessage = 'No current user';

export const googleRecaptchaKeys = {
  site: '6LfpiFcdAAAAAGRyzeGMUYJ6q9P62qIbTqmQiyMn',
  secret: '6LfpiFcdAAAAAFptuP2SSI6RDzljPGKvjPr0K4l5',
};

export const toggleFeatureKeys = {
  dealer: 'orderTypeFilter',
  employee: 'employeeVision',
  helpButton: 'helpButton',
  invoiceDownload: 'invoiceDownload',
};

export const emptyDate = '-- de -- de -- às --:--';

export const trackedEvents = {
  pageView: 'Página visualizada',
  buttonClick: 'Botão clicado',
  filterApply: 'Filtro aplicado',
  dataExport: 'Dados exportados',
  kpiPerAreaClick: 'KPI por área selecionado',
  menuClick: 'Clique no menu',
  givenFeedback: 'Feedback enviado',
};

export const trackedPages = {
  pedidos: 'Pedidos',
  parametros: 'Parâmetros',
  transitTime: 'Transit time',
  login: 'Login',
  detalhes: 'Detalhes',
  funcionario: 'Funcionário',
  cliente: 'Cliente',
  prazosMedios: 'Prazos Médios',
  delayManagement: 'Acompanhamento',
  dashboard: 'Dashboard',
  visibilityControl: 'Controle de Visibilidade',
  historicLogsOrders: 'Histórico Logs Pedidos',
};

export const trackedButtons = {
  export: 'Export',
  exportTransitTime: 'Export Transit Time',
  filter: 'Filtro',
  clearFilters: 'Limpa Filtros',
  clearDatesFilter: 'Limpa Filtro de Data',
  graphs: 'Gráficos',
  loadMore: 'Carregar mais',
  tooltipList: 'Tooltip listagem',
  search: 'Busca',
  openFeedback: 'Abrir feedback',
  openReleaseNotes: 'Abrir release notes',
  cancelFeedback: 'Cancelar feedback',
  openLastUpdates: 'Abrir últimas atualizações',
  confirmSiebelCall: 'Confirmar chamado Siebel',
  returnToList: 'Retornar para listagem',
  editTransitTime: 'Editar transit time',
  saveTransitTime: 'Salvar transit time',
  editParameters: 'Editar parâmetros',
  saveParameters: 'Salvar parâmetros',
  openSwapModal: 'Abrir modal de trocas',
  historyFrequency: 'Alterar frequência do histórico',
  downloadInvoice: 'Baixar nota fiscal',
  openInvoiceModal: 'Abrir modal nota fiscal',
  carouselArrow: 'Seta carrossel de pedidos',
  expandDetails: 'Expandir detalhes do pedido',
  viewIndustrialOrder: 'Abrir pedido industrial',
  carouselTab: 'Tab carrossel de pedidos',
  subMenuOptionDashboard: 'Sub menu opção dashboard',
  subMenuOptionParameters: 'Sub menu opção parâmetros',
  trackingVehicles: 'Abrir tooltip tracking veículos',
  selectModelForecast: 'Seletor modelos prazos médios',
  whatsappCarVendas: 'Mensagem WhatsApp Car Vendas',
  openNotification: 'Abrir notificação',
  declineFeedback: 'Recusar Feedback',
  acceptFeedback: 'Aceitar Feedback',
  openHelpButton: 'Abrir Botão Ajuda',
  delayNotification: 'Notificação Atraso Macro Área',
  sortingManagement: 'Ordenação Acompanhamento',
};

export const stepStatusId = {
  onTime: 'onTime',
  inRisk: 'inRisk',
  delivered: 'delivered',
  late: 'late',
  notStarted: 'notStarted',
  withoutOrder: 'withoutOrder',
  allStatus: 'all',
  ongoing: 'ongoing',
};

export const googleAnalyticsDimensions = {
  email: 'dimension1',
  role: 'dimension2',
  brand: 'dimension3',
  name: 'dimension4',
  dealerId: 'dimension5',
  dealerName: 'dimension6',
  secondaryDealersIds: 'dimension7',
  regionalId: 'dimension8',
  regionalName: 'dimension9',
  secondaryDealersNames: 'dimension10',
  roleId: 'dimension11',
  roleDescription: 'dimension12',
};

export const maxNumberIndustrialOrdersInSlider = 200;

export const environment = {
  BR: 'BR',
  AR: 'AR',
  default: 'BR',
};
