import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'html-react-parser';
import './FAQQuestion.scss';

const FAQQuestion = ({ questionNumber, questionText, answer }) => {
  const numberText = questionNumber.toString().padStart(2, '0');
  const question = `${numberText} - ${questionText}`;
  return (
    <article className="faq__question_and_answer">
      <p className="faq__question">{ReactHtmlParser(question)}</p>
      <div className="faq__answer">{ReactHtmlParser(answer)}</div>
    </article>
  );
};

FAQQuestion.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  questionText: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export default FAQQuestion;
