
import RequestRepository from '../Repositories/RequestRepository';

class DashboardAccessReportService {
  getAccessReport = async (fromDate, toDate) => {
    try {
      const urlPath = `dashboard/regional?fromDate=${fromDate}&toDate=${toDate}`;
      const { data } = await RequestRepository.get(urlPath);
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default DashboardAccessReportService;
