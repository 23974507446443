import { connect } from 'react-redux';
import {
  setReleaseNotesModalOpened,
  loadReleaseNotes,
  clearReleaseNotesState,
} from 'Infrastructure/Ducks/ReleaseNotesDuck';
import ReleaseNotesModal from './ReleaseNotesModal';

const mapStateToProps = (state) => {
  const { releaseNotesDuck } = state;

  const { isReleaseNotesModalOpened, releaseNotesResponse, releaseNotes } = releaseNotesDuck;
  return {
    isReleaseNotesModalOpened,
    releaseNotesResponse,
    releaseNotes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setReleaseNotesModalOpened: (isReleaseNotesModalOpened) => {
    dispatch(setReleaseNotesModalOpened(isReleaseNotesModalOpened));
  },
  clearReleaseNotesState: () => {
    dispatch(clearReleaseNotesState());
  },
  loadReleaseNotes: () => {
    dispatch(loadReleaseNotes());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNotesModal);
