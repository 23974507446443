import { connect } from 'react-redux';
import { loadLastParametersStepsUpdate } from 'Infrastructure/Ducks/SystemUpdateDuck';
import ParametersStepsUpdate from './ParametersStepsUpdate';

const mapStateToProps = (state) => {
  const { lastParametersStepsUpdate } = state.systemUpdate;
  return { lastParametersStepsUpdate };
};

const mapDispatchToProps = dispatch => ({
  loadLastParametersStepsUpdate: () => dispatch(loadLastParametersStepsUpdate()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParametersStepsUpdate);
