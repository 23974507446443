import { User, Token } from 'Common/Helpers/AuthHelper';
import { roleConsts } from './consts';

export const formatterChamadoSiebel = (protocol) => {
  let current = protocol.replace(/\D/g, '');
  current = current.substring(0, Math.min(current.length, 11));

  return current.replace(/(\d{1})(\d{1,10})/g, '$1-$2');
};

export const validateChamadoSiebel = (protocol) =>
  typeof protocol === 'string' && protocol.length === 12 && formatterChamadoSiebel(protocol) === protocol;

export const getDefaultPath = () => {
  if (User.hasProfile(roleConsts.admin)) {
    return { pathname: '/parametros' };
  }
  if (User.hasProfile(roleConsts.employees)) {
    const tokenKey = Token.get();
    const { token } = tokenKey;
    return { pathname: `/empregados/${token}` };
  }
  if (User.hasProfile(roleConsts.client)) {
    return { pathname: '/clientes/pedido' };
  }
  return { pathname: '/pedidos' };
};
