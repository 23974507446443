import { connect } from 'react-redux';
import { loadDelayedOrdersTotal, loadDelayedOrders } from 'Infrastructure/Ducks/DelayedOrdersDuck';
import { setDealerIdFilter, setStepFilter } from 'Infrastructure/Ducks/FiltersDuck';
import DelayedOrders from './DelayedOrders';

const mapStateToProps = (state) => {
  const {
    delayedOrdersDuck: { delayedOrdersDuck, delayedOrders, delayedOrdersTotal },
  } = state;
  return {
    delayedOrdersDuck,
    delayedOrders,
    delayedOrdersTotal,
    setDealerIdFilter,
    setStepFilter,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadDelayedOrdersTotal: () => {
    dispatch(loadDelayedOrdersTotal());
  },
  loadDelayedOrders: () => {
    dispatch(loadDelayedOrders());
  },
  setDealerIdFilter: (selected) => {
    dispatch(setDealerIdFilter(selected));
  },
  setStepFilter: (selected) => {
    dispatch(setStepFilter(selected));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DelayedOrders);
