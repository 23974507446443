import CryptoJS from 'crypto-js';
import { User, Dealer } from 'Common/Helpers/AuthHelper';
import { roleConsts, googleAnalyticsDimensions, trackedEvents } from 'Utils/consts';
import sha256 from 'crypto-js/sha256';
import ReactGA from 'react-ga4';

export const trackEvent = (event, property) => {
  window.mixpanel.track(event, property);

  if (event !== trackedEvents.pageView) {
    const propertiesList = Object.values(property);
    const actionText = propertiesList[0];
    const labelText = propertiesList.length > 1 ? propertiesList[1] : null;
    ReactGA.event({
      category: event,
      action: actionText,
      label: labelText || '',
    });
  }
};

const getCurrentUserRole = () => {
  if (User.hasProfile(roleConsts.employees)) {
    return roleConsts.employees;
  }
  if (User.hasProfile(roleConsts.dealers)) {
    return roleConsts.dealers;
  }
  if (User.hasOneOfProfiles([roleConsts.customercare, roleConsts.admin])) {
    return roleConsts.customercare;
  }
  return roleConsts.notFound;
};

const getCurrentUserBrand = (userData) => {
  const { brand } = userData;
  const newBrand = brand || roleConsts.notFound;
  return newBrand;
};

const addUserPropertiesToGA = (identity) => {
  ReactGA.ga('set', googleAnalyticsDimensions.email, identity.email);
  ReactGA.ga('set', googleAnalyticsDimensions.role, identity.role);
  ReactGA.ga('set', googleAnalyticsDimensions.brand, identity.brand);
  ReactGA.ga('set', googleAnalyticsDimensions.name, identity.name);
};

const addDealerPropertiesToGA = (identity) => {
  ReactGA.ga('set', googleAnalyticsDimensions.dealerId, identity.dealerId);
  ReactGA.ga('set', googleAnalyticsDimensions.dealerName, identity.dealerName);
  ReactGA.ga('set', googleAnalyticsDimensions.secondaryDealersIds, identity.secondaryDealersIds);
  ReactGA.ga('set', googleAnalyticsDimensions.secondaryDealersNames, identity.secondaryDealersNames);
  ReactGA.ga('set', googleAnalyticsDimensions.regionalId, identity.regionalId);
  ReactGA.ga('set', googleAnalyticsDimensions.regionalName, identity.regionalName);
  ReactGA.ga('set', googleAnalyticsDimensions.roleId, identity.dealerRoleId);
  ReactGA.ga('set', googleAnalyticsDimensions.roleDescription, identity.dealerRoleDescription);
  ReactGA.ga('set', googleAnalyticsDimensions.sectorId, identity.sectorId);
  ReactGA.ga('set', googleAnalyticsDimensions.sectorName, identity.sectorName);
};

export const trackUserProperties = () => {
  const userData = User.get();
  if (userData) {
    const role = getCurrentUserRole();
    const brand = getCurrentUserBrand(userData);
    const hashEmail = CryptoJS.MD5(userData.email);
    const hashEmailString = hashEmail.toString(CryptoJS.enc.Hex);
    const hashNome = CryptoJS.MD5(userData.nome);
    const hashNomeString = hashNome.toString(CryptoJS.enc.Hex);
    window.mixpanel.identify(hashEmailString);
    ReactGA.set({ userId: sha256(hashEmailString) });
    const identity = { role, brand, email: hashEmailString, name: hashNomeString };
    addUserPropertiesToGA(identity);

    if (Dealer.get()) {
      const {
        dealerId,
        dealerName,
        secondaryDealersIds,
        secondaryDealersNames,
        regionalId,
        regionalName,
        roleId,
        roleDescription,
        sectorId,
        sectorName,
      } = Dealer.get();
      identity.dealerId = dealerId;
      identity.dealerName = dealerName;
      identity.secondaryDealersIds = secondaryDealersIds;
      identity.secondaryDealersNames = secondaryDealersNames;
      identity.regionalId = regionalId;
      identity.regionalName = regionalName;
      identity.dealerRoleId = roleId;
      identity.dealerRoleDescription = roleDescription;
      identity.sectorId = sectorId;
      identity.sectorName = sectorName;
      addDealerPropertiesToGA(identity);
    }

    window.mixpanel.people.set(identity);
  }
};

export default null;
