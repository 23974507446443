import { clearList, setPageNumber } from 'Infrastructure/Ducks/ListPedidos';
import { connect } from 'react-redux';
import {
  setSelectedAhorroFilters,
  clearAhorroFilters,
} from 'Infrastructure/Ducks/FiltersDuck';

import OrderAhorroFilters from './OrderAhorroFilters';

const mapStateToProps = (state) => {
  const { selectedAhorroFilters, searchAhorroString } = state.filtersDuck;

  return {
    selectedAhorroFilters,
    searchAhorroString,
  };
};

const mapDispatchToProps = dispatch => ({
  clearList: () => {
    dispatch(clearList());
  },
  setSelectedAhorroFilters: (search, filters) => {
    dispatch(setSelectedAhorroFilters(search, filters));
  },
  clearAhorroFilters: () => {
    dispatch(clearAhorroFilters());
  },
  setPageNumber: async (page) => {
    await dispatch(setPageNumber(page));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderAhorroFilters);
