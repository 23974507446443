import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ClientIndustrialOrderDetail from 'Components/ComercialDetailsScreenComponents/ClientIndustrialOrderDetail';
import ClientCommercialOrders from 'Components/ComercialDetailsScreenComponents/ClientCommercialOrders';
import ClientOrderMobile from 'Components/Organisms/ClientOrderMobile';
import DrawerVision from 'Components/Atoms/BaseDrawer';

import { deviceInfo, trackedEvents, trackedPages, stepStatusId } from 'Utils/consts';
import SnackBar from 'Components/Shared/SnackBar';
import { ReactComponent as NoIndustrialOrders } from 'Common/Assets/NoIndustrialOrders.svg';
import { trackEvent } from 'Utils/analytics';
import { User } from 'Common/Helpers/AuthHelper';
import langValues from 'Config/i18n/index';
import { getDateAsNumbers } from 'Utils/dateUtils';
import OrderInformationCard from 'Components/Organisms/OrderInformationCard/OrderInformationCard';
import Timeline from 'Components/Organisms/Timeline/index';
import ResultResponse from 'Components/Atoms/ResultResponse';
import './ClientOrderDetail.scss';

class ClientOrderDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceType: window.innerWidth > deviceInfo.desktopMinWidth ? deviceInfo.typeDesktop : deviceInfo.typeMobile,
      page: 0,
    };
    trackEvent(trackedEvents.pageView, { page: trackedPages.cliente });
    this.loadOrder(0);
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const { deviceType, page } = this.state;
    if (deviceType === deviceInfo.typeDesktop && window.innerWidth <= deviceInfo.desktopMinWidth) {
      this.setState({ page, deviceType: deviceInfo.typeMobile });
    } else if (deviceType === deviceInfo.typeMobile && window.innerWidth > deviceInfo.desktopMinWidth) {
      this.setState({ page, deviceType: deviceInfo.typeDesktop });
    }
  };

  handlePageChange = (value) => {
    const { deviceType } = this.state;
    this.setState({ page: value, deviceType });
    this.loadOrder(value);
  };

  loadOrder = (page) => {
    const { loadClientOrder, loadTotalClientOrderById, pageSize } = this.props;
    const user = User.get();
    loadClientOrder(user.clientDocument, user.orderCode, page, pageSize);
    loadTotalClientOrderById(user.clientDocument);
  };

  renderIndustrialDetails = () => {
    const { selectedIndustrialOrder, order = null } = this.props;
    const { industrialOrder } = selectedIndustrialOrder;
    const pedidoCriado = langValues.labelsPedidoComercial.dataPedido;
    return (
      <div className="pedidoIndustrial-component">
        <ClientIndustrialOrderDetail
          industrialOrder={industrialOrder}
          subtitle={`${pedidoCriado} ${getDateAsNumbers(order.orderDate, false)}`}
        />
      </div>
    );
  };

  handleChangeSelectedOrder = (order) => {
    const { changeSelectedClientOrder, setCommercialDrawerOpen = () => {} } = this.props;
    changeSelectedClientOrder(order);
    setCommercialDrawerOpen(false);
  };

  renderOrderList = () => {
    const {
      clientOrders,
      order = null,
      total,
      pageSize,
      isCommercialDrawerOpen = true,
      setCommercialDrawerOpen = () => {},
      loading,
    } = this.props;
    const { page, deviceType } = this.state;
    const style = {
      paper: {
        zIndex: '3',
        width: '391px',
        boxShadow: 'inset 8px 0px 10px -4px rgba(0, 0, 0, 0.08), 4px 0px 12px 0px rgba(0, 0, 0, 0.08)',
      },
    };
    if (deviceType === deviceInfo.typeMobile) {
      style.paperAnchorLeft = {
        left: '0px',
        top: '56px',
      };
      style.paper = {
        ...style.paper,
        width: '100%',
        height: 'calc(100% - 56px)',
        overflow: 'auto',
      };
    }
    const Drawer = DrawerVision(style);
    return (
      <Drawer
        anchor="left"
        open={isCommercialDrawerOpen}
        variant="temporary"
        BackdropProps={{ invisible: true }}
        onClose={() => setCommercialDrawerOpen(false)}
      >
        <div className="commercialClientListing">
          <ClientCommercialOrders
            clientOrders={clientOrders}
            changeSelectedOrder={(value) => this.handleChangeSelectedOrder(value)}
            order={order}
            totalOrdersNumber={total}
            pageSize={pageSize}
            currentPage={page}
            loading={loading}
            changeSelectedPage={(value) => this.handlePageChange(value)}
          />
        </div>
      </Drawer>
    );
  };

  renderOrderInformation = () => {
    const { clientOrders, order = null, selectedIndustrialOrder, changeSelectedClientOrder, loading } = this.props;
    const { industrialOrder } = selectedIndustrialOrder;
    const { deviceType } = this.state;
    if (deviceType === deviceInfo.typeMobile) {
      if (loading) {
        return this.renderIndustrialOrderInformationSkeleton();
      }
      return (
        <div className="pedidoComercial">
          <div className="commercialDetails">
            <ClientOrderMobile
              clientOrders={clientOrders}
              changeSelectedOrder={changeSelectedClientOrder}
              order={order}
              industrialOrder={industrialOrder}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  renderIndustrialOrderInformationSkeleton = () => (
    <div className="industrialOrderDetailEmpty">
      <div className="informationCard">
        <div className="empty" />
      </div>
      <div className="timeLineCard">
        <div className="empty" />
      </div>
    </div>
  );

  renderIndustrialOrderInformationMobileSkeleton = () => (
    <div className="timeLineMobileEmpty">
      <div className="empty" />
    </div>
  );

  renderTimeline = () => {
    const { order = null, selectedIndustrialOrder } = this.props;
    const { deviceType } = this.state;
    if (order && order.statusId === stepStatusId.withoutOrder) {
      return (
        <ResultResponse
          Icon={NoIndustrialOrders}
          title={langValues.orderDetail.noIndustrialOrdersTitle}
          subtitle={langValues.orderDetail.noIndustrialOrdersSubtitle}
        />
      );
    }

    return (
      <div className="timeline-body">
        <div id="timelineWrapper" className="timeline-client">
          <Timeline order={order} selectedIndustrialOrder={selectedIndustrialOrder} deviceType={deviceType} />
        </div>
        <span className="clientLegalTerm font-small2">{langValues.clientLegalTerm}</span>
      </div>
    );
  };

  renderIndustrialOrderInformation = () => {
    const { order = null, loading } = this.props;
    const { deviceType } = this.state;
    if (loading) {
      if (deviceType === deviceInfo.typeMobile) {
        return this.renderIndustrialOrderInformationMobileSkeleton();
      }
      return this.renderIndustrialOrderInformationSkeleton();
    }

    if (deviceType === deviceInfo.typeMobile) {
      return (
        <div className="industrialOrder">
          <div className="industrialDetailsDiv">{this.renderTimeline()}</div>
        </div>
      );
    }

    return (
      <div className="rightIndustrialCard">
        <OrderInformationCard order={order} loading={loading} />
        {this.renderTimeline()}
      </div>
    );
  };

  renderPedidoComercial = () => (
    <div className="commercialClientBody">
      {this.renderOrderList()}
      {this.renderOrderInformation()}
      {this.renderIndustrialOrderInformation()}
    </div>
  );

  render = () => {
    const { error = null } = this.props;
    return (
      <>{error ? <SnackBar message={langValues.detailsCommercial.errorLoading} /> : this.renderPedidoComercial()}</>
    );
  };
}

ClientOrderDetail.propTypes = {
  loadClientOrder: PropTypes.func.isRequired,
  loadTotalClientOrderById: PropTypes.func.isRequired,
  changeSelectedClientOrder: PropTypes.func.isRequired,
  clientOrders: PropTypes.array.isRequired,
  order: PropTypes.object,
  selectedIndustrialOrder: PropTypes.object.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  setCommercialDrawerOpen: PropTypes.func,
  isCommercialDrawerOpen: PropTypes.bool,
};

export default ClientOrderDetail;
