import axios from 'axios';
import { saveAs } from 'file-saver';
import { API_URL, ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';

import { User } from 'Common/Helpers/AuthHelper';

import { routeConsts, roleConsts } from 'Utils/consts';

import NetworkConfig from 'Config/NetworkConfig';

class RequestRepository {
  static async getUrlForUser(parameters) {
    let routeUser = routeConsts.default;

    if (User && User.get()) {
      if (User.hasProfile(roleConsts.dealers)) {
        routeUser = routeConsts.dealers;
      } else if (User.hasProfile(roleConsts.employees)) {
        routeUser = routeConsts.employees;
      } else if (User.hasProfile(roleConsts.client)) {
        routeUser = routeConsts.client;
      }
    }

    let url = `${API_URL}/${routeConsts.version}/${routeUser}`;

    if (parameters) {
      url += `/${parameters}`;
    }

    return url;
  }

  static async get(parameters) {
    const url = await this.getUrlForUser(parameters);
    return new Promise((resolve, reject) => {
      NetworkConfig.configureCache().then(async (api) => {
        await api.get(url).then(
          result => resolve(result),
        ).catch((error) => {
          reject(error);
        });
      });
    });
  }

  static async getUnregisteredData() {
    const url = `${API_URL}/${routeConsts.version}/${routeConsts.client}/newDealersCode/${ENV_ENVIRONMENT}`;
    return new Promise((resolve, reject) => {
      NetworkConfig.configureCache().then(async (api) => {
        await api.get(url).then(
          result => resolve(result),
        ).catch((error) => {
          reject(error);
        });
      });
    });
  }

  static async export(parameters, config, fileName, fileType) {
    const url = await this.getUrlForUser(parameters);

    return new Promise((resolve, reject) => {
      axios.get(url, { responseType: 'blob', headers: config.headers }).then(
        (result) => {
          saveAs(result.data, `${fileName}.${fileType}`);
          resolve(result);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  }

  static async exportOnlyCsv(path, fileName) {
    const url = await this.getUrlForUser(path);

    return new Promise((resolve, reject) => {
      axios.get(url, { responseType: 'blob' }).then(
        (result) => {
          saveAs(result.data, `${fileName}.csv`);
          resolve(result);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  }

  static async getDealerId(userDpr) {
    const url = `${API_URL}/${routeConsts.version}/${routeConsts.dealers}/dealerId`;
    const headers = { userDpr };
    return new Promise((resolve, reject) => {
      axios.get(url, { headers }).then(
        result => resolve(result),
      ).catch((error) => {
        reject(error);
      });
    });
  }

  static async put(parameters, body, headers = {}) {
    const url = await this.getUrlForUser(parameters);

    return new Promise((resolve, reject) => {
      axios.put(url, body, headers).then(
        result => resolve(result),
      ).catch((error) => {
        reject(error);
      });
    });
  }

  static async post(parameters, body, headers = {}) {
    const url = await this.getUrlForUser(parameters);

    return new Promise((resolve, reject) => {
      axios.post(url, body, headers).then(
        result => resolve(result),
      ).catch((error) => {
        reject(error);
      });
    });
  }

  static async delete(parameters, body, headers = {}) {
    const url = await this.getUrlForUser(parameters);

    return new Promise((resolve, reject) => {
      axios.delete(url, { data: body, headers }).then(
        result => resolve(result),
      ).catch((error) => {
        reject(error);
      });
    });
  }

  static async uploadFile(url, file) {
    const finalUrl = await this.getUrlForUser(url);

    const formData = new FormData();
    formData.append('file', file);

    return new Promise((resolve, reject) => {
      axios.put(finalUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  static async employeeUserAuth(username, password) {
    return this.auth(username, password, 'external');
  }

  static async customerCareAuth(username, password) {
    return this.auth(username, password, 'customercare');
  }

  static async dealerAuth(username, password) {
    return this.auth(username, password, 'dealer');
  }

  static async postAuthJson(username, password, url) {
    const requestBody = {
      username, password,
    };
    return new Promise((resolve, reject) => {
      const headers = {
        Accept: 'application/json',
      };
      axios.post(url, requestBody, { headers }).then(
        (result) => {
          resolve(result.data);
        },
      ).catch((error) => {
        reject(error);
      });
    });
  }

  static async auth(username, password, role) {
    const url = `${API_URL}/${routeConsts.version}/auth/${role}`;
    return this.postAuthJson(username, password, url);
  }

  static async getEmployee(employeeCPF) {
    const url = `${API_URL}/${routeConsts.version}/clients/employees`;
    return this.postAuthJson(employeeCPF, null, url);
  }

  static async getEmployeeOrder(employeeCPF) {
    const url = `${API_URL}/${routeConsts.version}/clients/employees/order`;
    return this.postAuthJson(employeeCPF, null, url);
  }

  static async clientAuth(clientDocument, orderCode) {
    const url = `${API_URL}/${routeConsts.version}/clients/${clientDocument}/order/${orderCode}`;
    return new Promise((resolve, reject) => {
      axios.get(url).then(
        result => resolve(result),
      ).catch((error) => {
        reject(error);
      });
    });
  }

  static async getClientOrder(clientDocument, order, page, size) {
    const url = `${API_URL}/${routeConsts.version}/clients/${clientDocument}/order`;
    const requestBody = {
      filter: order,
      page,
      size,
    };
    return new Promise((resolve, reject) => {
      axios.put(url, requestBody).then(
        result => resolve(result),
      ).catch((error) => {
        reject(error);
      });
    });
  }
}


export default RequestRepository;
