import React from 'react';
import PropTypes from 'prop-types';

import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import { environment } from 'Utils/consts';

import { safeToLowerCase, safeDealerStateToUpperCase } from 'Utils/safeMethods';
import { ReactComponent as DadosConcessionariaIcon } from 'Common/Assets/dados_concessionaria.svg';
import langValues from 'Config/i18n/index';

import './DadosConcessionaria.scss';
import CustomTooltip from 'Components/Atoms/Tooltip/CustomTooltip';

const renderItem = item => item || langValues.notIdentified;

const renderConcessionariaIcon = (
  ENV_ENVIRONMENT === environment.BR
    ? (
      <div className="concessionariaIcon">
        <DadosConcessionariaIcon />
      </div>
    )
    : null
);

const DadosConcessionaria = ({ type, order }) => {
  const renderName = () => (
    <>
      <span className="nomeConcessionaria">
        {safeToLowerCase(renderItem(order.deliveryDealer.name))}
      </span>
      <span className="dot">
        {langValues.labelsPedidoComercial.middleDot}
      </span>
      <span className="municipioConcessionaria">
        {`${safeToLowerCase(renderItem(order.deliveryDealer.county))} `}
        -
      </span>
      <span className="estadoConcessionaria">
        {` ${safeDealerStateToUpperCase(renderItem(order.deliveryDealer.uf))}`}
      </span>
    </>
  );

  const renderWebsite = () => {
    if (order.deliveryDealer.site) {
      return (
        <CustomTooltip label={langValues.dealerLabels.goToWebsite}>
          <a target="_blank" rel="noopener noreferrer" href={`https://${order.deliveryDealer.site}`}>
            {renderName()}
          </a>
        </CustomTooltip>
      );
    }
    return renderName();
  };

  const renderAddress = () => (
    <span className="enderecoConcessionaria">
      {safeToLowerCase(renderItem(order.deliveryDealer.address))}
    </span>
  );

  const renderMaps = () => {
    if (ENV_ENVIRONMENT === environment.BR) {
      let googleQuery;

      if (order.deliveryDealer.address) {
        googleQuery = `${order.deliveryDealer.address}-${order.deliveryDealer.county}`;
      } else if (order.deliveryDealer.latitude && order.deliveryDealer.longitude) {
        googleQuery = `${order.deliveryDealer.latitude},${order.deliveryDealer.longitude}`;
      }

      if (googleQuery) {
        return (
          <CustomTooltip label={langValues.dealerLabels.goToMaps}>
            <a target="_blank" rel="noopener noreferrer" href={`https://maps.google.com/maps?q=${googleQuery}`}>
              {renderAddress()}
            </a>
          </CustomTooltip>
        );
      }

      return renderAddress();
    }
    return null;
  };

  const renderPhone = () => (
    ENV_ENVIRONMENT === environment.BR
      ? (
        <div className="contato">
          <span className="contatoConcessionaria">
            <strong>
              {'Tel: '}
            </strong>
            {renderItem(order.deliveryDealer.contact)}
          </span>
        </div>
      )
      : null
  );

  const renderDealerCode = () => (
    ENV_ENVIRONMENT === environment.AR
      ? (
        <div className="code">
          <span className="codeDealer">
            {`cod. ${renderItem(order.deliveryDealer.id)}`}
          </span>
        </div>
      )
      : null
  );

  const renderContact = () => {
    if (order.deliveryDealer.contact) {
      return (
        <div className="contato">
          <CustomTooltip label={langValues.dealerLabels.goToPhone}>
            <a href={`tel:${order.deliveryDealer.contact}`}>
              {renderPhone()}
            </a>
          </CustomTooltip>
        </div>
      );
    }

    return renderPhone();
  };

  const renderDadosConcessionaria = () => {
    if (!order.deliveryDealer) {
      return (
        <div className="nome">
          {langValues.dealerLabels.detailsNotIdentified}
        </div>
      );
    }

    return (
      <>
        <div className="nome">
          {renderWebsite()}
        </div>
        <div className="endereco">
          {renderMaps()}
        </div>
        {renderContact()}
        {renderDealerCode()}
      </>
    );
  };

  return (
    <div className="concessionaria">
      {renderConcessionariaIcon}
      <div className="concessionariaInfo">
        <span className="tipo font-subtitle2">
          {type}
        </span>
        {renderDadosConcessionaria()}
      </div>
    </div>
  );
};

DadosConcessionaria.propTypes = {
  order: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default DadosConcessionaria;
