/* eslint-disable class-methods-use-this */
import RequestRepository from './RequestRepository';

class DealerRepository {
  async get(userDpr) {
    const dealerData = await RequestRepository.getDealerId(userDpr);
    return dealerData;
  }
}

export default DealerRepository;
