import { environment, roleConsts } from 'Utils/consts';

const { URL_PROTOCOL, API_BASE_URL, API_PREFIX, CACHE_TIME, ENVIRONMENT, LANG, SECURITY_LOGIN_URL } = window.env;
export const API_URL = `${URL_PROTOCOL}://${API_BASE_URL + API_PREFIX}`;
export const ENV_CACHE_TIME = CACHE_TIME;
export const ENV_ENVIRONMENT = ENVIRONMENT;
export const ENV_LANG = LANG;
export const ROLE_LOCATION = ENVIRONMENT === environment.BR ? roleConsts.br : roleConsts.ar;
export const ENV_SECURITY_LOGIN_URL = SECURITY_LOGIN_URL;

export default null;
