import React from 'react';
import PropTypes from 'prop-types';
import { getFullDateAsShortText, parseDateUTCtoLocalTimezone } from 'Utils/dateUtils';
import AlertIcon from '../AlertIcon/AlertIcon';
import './NotificationMessage.scss';

const NotificationMessage = ({ notification }) => (
  <div className="notificationMessage">
    <AlertIcon type={notification.type} />
    <div className="notificationMessage-body">
      <div className="notificationMessage-title font-subtitle2">
        {notification.title}
      </div>
      <div className="notificationMessage-message font-small2">
        {notification.message}
      </div>
      <div className="notificationMessage-date font-small3">
        {getFullDateAsShortText(parseDateUTCtoLocalTimezone(new Date(notification.creationDate)))}
      </div>
    </div>
  </div>
);


export default NotificationMessage;

NotificationMessage.propTypes = {
  notification: PropTypes.object.isRequired,
};
