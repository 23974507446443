/* eslint-disable class-methods-use-this */
import ListPedidosIndustriaisRepository from 'Infrastructure/Repositories/ListPedidosIndustriaisRepository';
import RequestRepository from 'Infrastructure/Repositories/RequestRepository';
import { User } from 'Common/Helpers/AuthHelper';
import { roleConsts } from 'Utils/consts';

class ListPedidosIndustriaisService {
  constructor(listRepository = new ListPedidosIndustriaisRepository()) {
    this.listRepository = listRepository;
  }

  checkIfDownloadInvoiceIsEnabled = (data) => {
    const isUserCustomerCare = User && User.get() && User.hasProfile(roleConsts.customercare);
    const isAbleToDownloadInvoice = User && User.get() && User.hasProfile(roleConsts.invoice);

    if (isUserCustomerCare && !isAbleToDownloadInvoice && data) {
      const { userPermissions } = data;
      if (userPermissions) {
        if (userPermissions.renderInvoiceDownload == null) {
          userPermissions.renderInvoiceDownload = false;
        }
      }
    }
  }

  async get(page, searchString, selected) {
    try {
      const { data } = await this.listRepository.get(page, searchString, selected);

      this.checkIfDownloadInvoiceIsEnabled(data);

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getPda(params) {
    try {
      const { data } = await this.listRepository.getPda(params);

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }


  async getEmployeeOrder(employeeCPF) {
    try {
      const data = await RequestRepository.getEmployeeOrder(employeeCPF);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getClientOrder(clientDocument, order, page, size) {
    try {
      const { data } = await RequestRepository.getClientOrder(clientDocument, order, page, size);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default ListPedidosIndustriaisService;
