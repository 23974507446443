import React from 'react';
import PropTypes from 'prop-types';

import langValues from 'Config/i18n/index';

import TagOrderStatus from 'Components/Atoms/TagOrderStatus';
import InvoiceDownload from 'Components/Molecules/InvoiceDownload/Index';
import { Token, User } from 'Common/Helpers/AuthHelper';
import Radio from '@mui/material/Radio';
import { roleConsts } from 'Utils/consts';
import { safeToLowerCase } from 'Utils/safeMethods';
import SystemUpdate from 'Components/Atoms/SystemUpdate';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import TitlePedidoComercial from './TitlePedidoComercial/TitlePedidoComercial';

import DadosCliente from './DadosCliente/DadosCliente';
import DadosConcessionaria from './DadosConcessionaria/DadosConcessionaria';

import '../PedidoComercial/TitlePedidoComercial/invoiceDownload.scss';
import '../../OrderListComponents/ListItemPedidos/ListItemPedidos.scss';
import './DadosCliente/Pendencia/Pendencia.scss';
import './PedidoComercial.scss';

function PedidoComercial({ clientOrders = [], order, industrialOrder, changeSelectedOrder }) {
  const renderSystemUpdate = () => <SystemUpdate />;

  const renderTitle = () => {
    if (clientOrders && clientOrders.length > 1) {
      return <h3>{langValues.labelsPedidoComercial.yourOrders}</h3>;
    }

    return (
      <div className="titulo-pedido">
        <TitlePedidoComercial orderId={order.orderId} orderType={order.orderType} />
      </div>
    );
  };

  const renderDadosCliente = () => (
    <div className="dados-cliente-pedido">
      <DadosCliente order={order} />
    </div>
  );

  const renderConcessionariaEntrega = () => {
    const concessionaria = langValues.labelsPedidoComercial.concessionariaEntrega;
    return (
      <div className="concessionaria-entrega">
        <DadosConcessionaria type={concessionaria} order={order} />
      </div>
    );
  };

  const renderContactDealer = () => (
    <div className="contactDealer">
      <ErrorOutlineRoundedIcon />
      <span>{langValues.labelsPedidoComercial.contateConcessionaria}</span>
    </div>
  );

  const renderInvoiceDownload = () => {
    const modalData = {
      orderId: order.orderId,
      cpf: Token.get(),
    };

    if (industrialOrder && industrialOrder.invoiceAvailable && !User.get().userProfile.includes(roleConsts.client)) {
      return <InvoiceDownload modalData={modalData} />;
    }

    return null;
  };

  const renderOrderSelector = () => {
    if (clientOrders.length === 1) return null;

    const getModelName = (industrial) => {
      if (industrial) {
        return industrial.modelName;
      }
      return '-';
    };

    return (
      <div className="commercialOrderDetail-ordersSelector">
        {clientOrders.map((item) => (
          <div className="ordersSelectorItem" key={item.orderId} onClick={() => changeSelectedOrder(item)}>
            <Radio checked={item.orderId === order.orderId} value={item} className="radioButton" />
            <span className="font-subtitle2 orderId">{`#${item.orderId}`}</span>
            <div className="model">
              <span className="font-subtitle2 modelName">
                {safeToLowerCase(getModelName(item.industrialOrdersList[0]))}
              </span>
              <span className="font-small3 modelLabel">{langValues.model}</span>
            </div>
            <div className="tagStatus">
              <TagOrderStatus lateDays={item.maximumDelay} statusId={item.statusId} />
            </div>
          </div>
        ))}
      </div>
    );
  };

  if (!order) {
    return (
      <article className="commercialOrderDetail">
        <div>
          <div className="status-pedido skeleton" />
          <div className="titulo-pedido skeleton" />
          <div className="dados-cliente-pedido skeleton" />
          <div className="concessionaria-entrega skeleton" />
        </div>
      </article>
    );
  }

  return (
    <article className="commercialOrderDetail">
      <div>
        {renderTitle()}
        {renderSystemUpdate()}
        {renderOrderSelector()}
        {renderDadosCliente()}
        {renderConcessionariaEntrega()}
        {renderInvoiceDownload()}
      </div>
      {renderContactDealer()}
    </article>
  );
}

PedidoComercial.propTypes = {
  clientOrders: PropTypes.array,
  changeSelectedOrder: PropTypes.func.isRequired,
  order: PropTypes.object,
  industrialOrder: PropTypes.object,
};

export default PedidoComercial;
