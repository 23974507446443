import React from 'react';
import PropTypes from 'prop-types';
import SnackbarContent from '@mui/material/SnackbarContent';
import Snackbar from '@mui/material/Snackbar';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';
import langValues from 'Config/i18n/index';
import { ReactComponent as ErrorIcon } from 'Common/Assets/alert-octagon.svg';

import './SnackBarExport.scss';

const SnackBarExport = ({ open = false, onClose, message }) => {
  const button = (
    <MaterialButton onClick={() => onClose()} buttonText={langValues.snackBarExport.close} variant="outlined" />
  );

  return (
    <Snackbar open={open} className="snackBarExport">
      <SnackbarContent
        message={
          <div className="snackBarExport-message">
            <span className="snackBarExport-icon">
              <ErrorIcon />
            </span>
            <span>{message || langValues.snackBarExport.message}</span>
          </div>
        }
        action={button}
        className="snackBarExport-modal"
      />
    </Snackbar>
  );
};

SnackBarExport.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string,
};

export default SnackBarExport;
