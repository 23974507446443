import { connect } from 'react-redux';
import {
  loadRules, subMenuList, setCurrentActiveSubMenu, updateRules, clearUpdateFailure,
} from 'Infrastructure/Ducks/VisionConfigurationDuck';
import VisionConfiguration from './VisionConfiguration';

const mapStateToProps = (state) => {
  const {
    error, loading, rules, currentActiveSubMenu, update,
  } = state.visionConfigurationDuck;
  return {
    error,
    loading,
    rules,
    subMenuList,
    currentActiveSubMenu,
    update,
  };
};

const mapDispatchToProps = dispatch => ({
  loadRules: (role) => {
    dispatch(loadRules(role));
  },
  updateRules: (body, role) => {
    dispatch(updateRules(body, role));
  },
  clearUpdateFailure: () => {
    dispatch(clearUpdateFailure());
  },
  setCurrentActiveSubMenu: (activeSubMenu) => {
    dispatch(setCurrentActiveSubMenu(activeSubMenu));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VisionConfiguration);
