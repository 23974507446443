import React from 'react';
import TagOrderStatus from 'Components/Atoms/TagOrderStatus/TagOrderStatus';
import { safeToLowerCase } from 'Utils/safeMethods';
import DateText from 'Components/Formatters/DateText/DateText';

import './DelayManagementListItem.scss';

const safeItem = item => (item || '-');

const itemIndustrialOrder = (id, status, lateDays) => (
  <td className="delayManagementList-orderId">
    <p className={`font-subtitle2 orderId item-bolder status-${status}`}>#{id}</p>
    <TagOrderStatus statusId={status} lateDays={lateDays} />
  </td>
);

const itemVin = vin => (
  <td className="font-small1">{safeItem(vin)}</td>
);

const itemModel = (modelName, versionId, modelYear, manufactureYear) => (
  <td className="delayManagementList-model">
    <p className="font-subtitle2 item-bolder modelName">{safeToLowerCase(modelName)}</p>
    <p className="font-subtitle2 versionInfo"><strong>{versionId}</strong> - {safeItem(modelYear)}/{safeItem(manufactureYear)}</p>
  </td>
);

const itemDate = date => (
  <td className="delayManagementList-date">
    <DateText date={date} />
  </td>
);

const itemStep = (description, status, lateDays) => (
  <td className="delayManagementList-step">
    <p className={`font-small1 delayManagementList-stepDescription status-${status}`}>{description}</p>
    <TagOrderStatus statusId={status} lateDays={lateDays} />
  </td>
);

const itemCourtyard = courtyard => (
  <td className="font-subtitle2">{safeItem(courtyard)}</td>
);

const renderLoadingItem = () => (
  <tr className="listModelVersionForecast-item emptyList">
    {[...Array(6).keys()].map(() => (
      <td className="item-empty" />
    ))}
  </tr>
);

const DelayManagementListItem = ({
  listItems,
  selectedId,
  changeSelectedOrder,
  loading,
}) => {
  if (loading) {
    return [...Array(10).keys()].map(() => renderLoadingItem());
  }

  return listItems.map((item) => {
    const isSelected = item.industrialOrderId === selectedId;


    return (
      <tr onClick={() => changeSelectedOrder(item)} className={`listModelVersionForecast-item ${isSelected ? 'selected' : ''}`}>
        {itemIndustrialOrder(item.industrialOrderId, item.status, item.lateDays)}
        {itemVin(item.vin)}
        {itemModel(item.modelName, item.versionId, item.modelYear, item.manufactureYear)}
        {itemDate(item.initialDeliveryDate)}
        {itemStep(item.stepDescription, item.stepStatus, item.stepLateDays)}
        {itemCourtyard(item.vehicleCourtyard)}
      </tr>
    );
  });
};

export default DelayManagementListItem;
