import MuiIconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import MuiMenuItem from '@mui/material/MenuItem';
import MuiTooltip from '@mui/material/Tooltip';
import { withStyles } from 'tss-react/mui';

export const IconButton = withStyles(MuiIconButton, {
  root: {
    '&:hover': {
      backgroundColor: '#ECEEF2',
      padding: '0',
    },
  },
});

export const Tooltip = withStyles(MuiTooltip, {
  tooltip: {
    maxWidth: '124px',
    width: 'fit-content',
    height: 'fit-content',
    padding: '8px 12px',
    backgroundColor: '#0C1C2D',
    color: '#FFFFFF',
    fontFamily: 'Helvetica Neue',
    fontSize: '10px',
    letterSpacing: '0',
    lineHeight: '15px',
  },
});

export const Menu = withStyles(Popover, {});

export const MenuItem = withStyles(MuiMenuItem, {
  root: {
    '&:hover': {
      backgroundColor: 'rgba(93,127,200,0.1)',
    },
    fontFamily: 'Helvetica Neue',
    color: '#5E5E5E',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '16px',
    padding: '18px 16px',
  },
});

export default null;
