import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PenBlockIcon } from 'Common/Assets/bloqueio_de_qualidade.svg';
import { ReactComponent as CommercialBlockIcon } from 'Common/Assets/commercialPen.svg';
import { ReactComponent as OutboundBlockIcon } from 'Common/Assets/outboundBlock.svg';
import blockLabels from './blockLabels';
import CustomTooltip from '../Tooltip/CustomTooltip';

export const getBlockLabel = (order) => {
  let label = '';
  if (order.commercialBlockFlag) {
    label += `${blockLabels.commercialBlockLabel}\n`;
  }
  if (order.qualityBlockFlag) {
    label += `${blockLabels.qualityBlockLabel}\n`;
  }
  if (order.outbountBlockFlag) {
    label += `${blockLabels.outbountBlockLabel}\n`;
  }
  return label;
};

const renderIcon = order => (
  <>
    {order.commercialBlockFlag ? (
      <CommercialBlockIcon />
    ) : null}
    {order.qualityBlockFlag ? (
      <PenBlockIcon />
    ) : null}
    {order.outbountBlockFlag ? (
      <OutboundBlockIcon />
    ) : null}
  </>
);

const BlockIconWithTooltip = ({ order }) => {
  const blockLabel = getBlockLabel(order);
  if (!blockLabel) return null;

  return (
    <CustomTooltip label={blockLabel}>
      <div>
        {renderIcon(order)}
      </div>
    </CustomTooltip>
  );
};

BlockIconWithTooltip.propTypes = {
  order: PropTypes.object.isRequired,
};

export default BlockIconWithTooltip;
