import MuiButton from '@mui/material/Button';
import MuiCircularProgress from '@mui/material/CircularProgress';
import { withStyles } from 'tss-react/mui';

export const Button = withStyles(MuiButton, {
  root: {
    textTransform: 'none',
  },
});

export const CircularProgress = withStyles(MuiCircularProgress, {
  colorPrimary: {
    color: '#D8D8D8',
  },
});

export default null;
