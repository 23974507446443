import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as AlertIcon } from 'Common/Assets/alert-triangle-request.svg';
import { ReactComponent as CheckIcon } from 'Common/Assets/check-circle-request.svg';
import { Modal } from '@mui/material';
import langValues from 'Config/i18n/index';
import { Button, CircularProgress } from './styles';
import './ResponseModal.scss';

const ResponseModal = ({
  isResponseModalOpened,
  setResponseModalOpened,
  setFeedbackModalOpened,
  feedbackResponse,
  feedback,
  saveFeedback,
  clearFeedbackFields,
}) => {
  const getMessages = () =>
    feedbackResponse.success ? langValues.feedbackLabels.success : langValues.feedbackLabels.error;

  const renderIcon = () => (feedbackResponse.success ? <CheckIcon /> : <AlertIcon />);

  const onCloseResponseClick = () => {
    clearFeedbackFields();
    setResponseModalOpened(false);
  };

  const onReturnClick = () => {
    setResponseModalOpened(false);
    setFeedbackModalOpened(true);
  };

  const renderButtonSuccess = (buttonText) => (
    <Button
      key="closeResponse"
      className="responseModal__button responseModal__close"
      onClick={() => onCloseResponseClick()}
    >
      {buttonText}
    </Button>
  );

  const renderButtonFailed = (buttonText) => (
    <>
      <Button key="cancel" className="responseModal__button responseModal__cancel" onClick={() => onReturnClick()}>
        {langValues.back}
      </Button>

      <Button
        key="retryResponse"
        className="responseModal__button responseModal__retry"
        onClick={() => saveFeedback(feedback)}
      >
        {buttonText}
      </Button>
    </>
  );

  const renderButton = (textButton) => (
    <div className="responseModal__buttons">
      {feedbackResponse.success ? renderButtonSuccess(textButton) : renderButtonFailed(textButton)}
    </div>
  );

  const renderLoading = () => (
    <>
      <div className="response-icon">
        <CircularProgress thickness={5.5} size={45} />
      </div>
      <header>{langValues.wait}</header>
      <section className="responseModal__fields">
        <p className="responseModal__subtitle">{langValues.feedbackLabels.sendingMessage}</p>
      </section>
    </>
  );

  const renderResponse = () => {
    const { title, subtitle, textButton } = getMessages();

    return (
      <>
        <div className="response-icon">{renderIcon()}</div>
        <header>{title}</header>
        <section className="responseModal__fields">
          <p className="responseModal__subtitle">{subtitle}</p>
          {renderButton(textButton)}
        </section>
      </>
    );
  };

  return (
    <Modal
      open={isResponseModalOpened}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setResponseModalOpened(false);
        }
      }}
      style={{ display: 'flex' }}
    >
      <div id="responseModal">{feedbackResponse.loading ? renderLoading() : renderResponse()}</div>
    </Modal>
  );
};

ResponseModal.propTypes = {
  isResponseModalOpened: PropTypes.bool.isRequired,
  setResponseModalOpened: PropTypes.func.isRequired,
  setFeedbackModalOpened: PropTypes.func.isRequired,
  feedbackResponse: PropTypes.object.isRequired,
  saveFeedback: PropTypes.func.isRequired,
  feedback: PropTypes.object.isRequired,
  clearFeedbackFields: PropTypes.func.isRequired,
};

export default ResponseModal;
