import React from 'react';
import PropTypes from 'prop-types';
import { getDateAsNumbers } from 'Utils/dateUtils';

function DataExtenso({ date = null, separetedWithBars = false }) {
  return <span className="dataExtenso">{getDateAsNumbers(date, separetedWithBars)}</span>;
}

DataExtenso.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  separetedWithBars: PropTypes.bool,
};

export default DataExtenso;
