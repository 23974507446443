import SystemUpdateService from 'Infrastructure/Services/SystemUpdateService';

export const DUCK_NAME = 'systemUpdate';

export const INITIAL_STATE = () => ({
  lastSystemUpdate: null,
  lastAverageInvoiceTimeUpdate: null,
  lastParametersStepsUpdate: null,
  lastParametersModelsVersionsUpdate: null,
  lastForecastTransitTimeUpdate: null,
  lastVisionConfigurationUpdate: null,
});

export const SET_UPDATE_DATE = `${DUCK_NAME}/SET_UPDATE_DATE`;
export const SET_AVERAGE_INVOICE_TIME_UPDATE_DATE = `${DUCK_NAME}/SET_AVERAGE_INVOICE_TIME_UPDATE_DATE`;
export const SET_PARAMETERS_STEPS_UPDATE = `${DUCK_NAME}/SET_PARAMETERS_STEPS_UPDATE`;
export const SET_PARAMETERS_MODELS_VERSIONS_UPDATE = `${DUCK_NAME}/SET_PARAMETERS_MODELS_VERSIONS_UPDATE`;
export const SET_FORECAST_TRANSIT_TIME_UPDATE = `${DUCK_NAME}/SET_FORECAST_TRANSIT_TIME_UPDATE`;
export const SET_VISION_CONFIGURATION_UPDATE = `${DUCK_NAME}/SET_VISION_CONFIGURATION_UPDATE`;

export const setUpdateDate = date => ({ type: SET_UPDATE_DATE, date });
export const setAverageInvoiceTimeUpdateDate = date => ({
  type: SET_AVERAGE_INVOICE_TIME_UPDATE_DATE, date,
});
export const setParametersStepsUpdateDate = date => ({
  type: SET_PARAMETERS_STEPS_UPDATE, date,
});
export const setParametersModelsVersionsUpdateDate = date => ({
  type: SET_PARAMETERS_MODELS_VERSIONS_UPDATE, date,
});
export const setForecastTransitTimeUpdateDate = date => ({
  type: SET_FORECAST_TRANSIT_TIME_UPDATE, date,
});
export const setVisionConfigurationUpdateDate = date => ({
  type: SET_VISION_CONFIGURATION_UPDATE, date,
});

export const CLEAR_DUCK = `${DUCK_NAME}/CLEAR_DUCK`;
export const clearDuck = () => ({ type: CLEAR_DUCK });

export const loadLastSystemUpdate = () => async (dispatch) => {
  try {
    const service = new SystemUpdateService();
    const data = await service.getLastSystemUpdate();
    dispatch(setUpdateDate(data.date));
  } catch (err) {
    dispatch(clearDuck());
  }
};

export const loadLastAverageInvoiceTimeUpdate = () => async (dispatch) => {
  try {
    const service = new SystemUpdateService();
    const data = await service.getLastAverageInvoiceTimeUpdate();
    dispatch(setAverageInvoiceTimeUpdateDate(data.date));
  } catch (err) {
    dispatch(clearDuck());
  }
};

export const loadLastParametersStepsUpdate = () => async (dispatch) => {
  try {
    const service = new SystemUpdateService();
    const data = await service.getLastParametersStepsUpdate();
    dispatch(setParametersStepsUpdateDate(data.date));
  } catch (err) {
    dispatch(clearDuck());
  }
};

export const loadLastParametersModelsVersionsUpdate = () => async (dispatch) => {
  try {
    const service = new SystemUpdateService();
    const data = await service.getLastParametersModelsVersionsUpdate();
    dispatch(setParametersModelsVersionsUpdateDate(data.date));
  } catch (err) {
    dispatch(clearDuck());
  }
};

export const loadLastForecastTransitTimeUpdate = () => async (dispatch) => {
  try {
    const service = new SystemUpdateService();
    const data = await service.getLastForecastTransitTimeUpdate();
    dispatch(setForecastTransitTimeUpdateDate(data.date));
  } catch (err) {
    dispatch(clearDuck());
  }
};

export const loadVisionConfigurationUpdate = () => async (dispatch) => {
  try {
    const service = new SystemUpdateService();
    const data = await service.getLastVisionConfigurationUpdate();
    dispatch(setVisionConfigurationUpdateDate(data.date));
  } catch (err) {
    dispatch(clearDuck());
  }
};

const reducer = (state = INITIAL_STATE(), action) => {
  switch (action.type) {
    case SET_UPDATE_DATE:
      return {
        ...state,
        lastSystemUpdate: action.date,
      };
    case SET_AVERAGE_INVOICE_TIME_UPDATE_DATE:
      return {
        ...state,
        lastAverageInvoiceTimeUpdate: action.date,
      };
    case SET_PARAMETERS_STEPS_UPDATE:
      return {
        ...state,
        lastParametersStepsUpdate: action.date,
      };
    case SET_PARAMETERS_MODELS_VERSIONS_UPDATE:
      return {
        ...state,
        lastParametersModelsVersionsUpdate: action.date,
      };
    case SET_FORECAST_TRANSIT_TIME_UPDATE:
      return {
        ...state,
        lastForecastTransitTimeUpdate: action.date,
      };
    case SET_VISION_CONFIGURATION_UPDATE:
      return {
        ...state,
        lastVisionConfigurationUpdate: action.date,
      };
    case CLEAR_DUCK:
      return {
        ...INITIAL_STATE(),
      };
    default:
      return state;
  }
};

export default reducer;
