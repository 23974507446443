import React from 'react';

export default {
  totalVisibleOrders: (totalVisible, total) => `Mostrando ${totalVisible} de ${total} Ordenes`,
  popoverDateText: (lastSeenDate, date) => (lastSeenDate ? `Actualizado en ${date}` : 'Fecha de actualización no disponible'),
  all: 'Todos',
  no: 'No',
  yes: 'Si',
  wait: 'Espere.',
  back: 'Vuelve',
  days: 'dias',
  day: 'dia',
  attention: 'Atención',
  save: 'Guardar',
  edit: 'Editar',
  discard: 'Descartar',
  invoiced: 'facturados',
  notInvoiced: 'no facturados',
  noOrdersFound: 'No hay Ordenes encontradas.',
  noResults: 'No hay resultados',
  noResultsFound: 'No hay resultado encontrado.',
  deliveredOnTime: 'Entregados a tiempo',
  deliveredLate: 'entregado tarde',
  noMessage: 'Sin mensaje',
  dealer: 'Concesionario',
  salesEmployees: 'Ventas a Empleados',
  screenTitle: 'Peticiones',
  loadMore: 'Carga más',
  cancel: 'Cancelar',
  delete: 'Eliminar',
  clear: 'Limpiar',
  clearSelection: 'Limpiar filtros',
  apply: 'Aplicar',
  filters: 'Filtros',
  optionals: 'Opcionales',
  selectModel: 'seleccionar un modelo',
  brands: 'Marcas',
  brand: 'Marca',
  model: 'Modelo',
  version: 'Versión',
  code: 'Codigo',
  order: 'Orden',
  origin: 'Origen',
  numOrders: 'Nº de Ordenes',
  modelVersion: 'Modelo y la versión',
  modelsVersions: 'Modelos y versiones',
  configurations: 'Configuraciones',
  parameters: 'Parámetros',
  macroAreas: 'Áreas macro',
  macroCategories: 'Categorías macro',
  orderExchangeHistory: 'Historial de intercambio de Ordenes',
  reloadPage: 'Recargar página',
  commonQuestions: 'FAQ - Preguntas frecuentes',
  lastUpdate: 'Última Actualización: ',
  lastUpdates: 'Últimas Actualizaciones',
  as: 'a las',
  saveChanges: 'Guardar ediciones',
  feedback: 'Feedback',
  confirm: 'Confirmar',
  export: 'Exportar',
  exportAsExcel: 'Exportar como Excel (.xlsx)',
  exportAsCsv: 'Exportar como CSV (.csv)',
  unavailableDate: 'Fecha no disponible',
  noForeseen: 'Sin previsión',
  insertError: 'Error al insertar',
  notIdentified: 'No identificado',
  unavailableData: 'Datos no Disponibles',
  loadErrorMessage: 'Algo salió mal al cargar la información.\nPor favor, inténtalo de nuevo.',
  renderError: 'Hubo un error al cargar esta página. Intente actualizar la página o inicie sesión nuevamente.',
  errorBoundaryTitle: '¡Ups!... Algo salió mal',
  errorBoundaryText: 'No se pudo acceder a la página solicitada. Puede intentar volver a iniciar sesión en su cuenta o volver a cargar la página.',
  notFoundIndustrialOrdersForFilters: 'No se encontraron Ordenes industriales para los filtros aplicados',
  modelVersionForecast: 'Plazos Medios',
  permissions: 'Permisos',
  download: 'Download',
  upload: 'Upload',

  city: 'CIUDAD',
  federatedUnit: 'UF',
  shipping: 'FLOTA',
  shippingDetail: 'DETALLES DE LA FROTA',

  frequencyLabels: {
    diary: 'Diario',
    weekly: 'Semanal',
    monthly: 'Mensual',
  },

  months: [
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic',
  ],
  fullMonths: [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ],

  dateLabels: {
    invalidDate: 'Formato de fecha no válido',
  },

  legalTerm: 'Las fechas presentadas son meras previsiones y no reflejan el caso concreto, por lo que no pueden considerarse compromisos. Las fechas esperadas cambiarán según el cumplimiento de cada etapa. Sistema de uso exclusivo del Concesionario, no compartible con terceros.',
  clientLegalTerm: 'Las fechas presentadas son meras previsiones y no reflejan el caso concreto, por lo que no pueden considerarse compromisos. Las fechas esperadas cambiarán según el cumplimiento de cada etapa.',
  employeePriceTable: 'Tabla de tiempos de producción promedio:',
  employeeSite: 'Portal del empleado',
  employeeSiteLink: 'http://svf.fiat.com.br/CapaVendasFuncionarios/IFrame.aspx?codRegra=30',

  versionModal: {
    add: 'Agregar versión',
    edit: 'Editar versión',
    version: 'Versión',
  },

  modelModal: {
    add: 'Agregar modelo',
    edit: 'Editar modelo',
  },

  carSalesLabel: 'Car Ventas',
  carSalesPhone: '+55 (31) 2123-8500',
  carSalesSubText: 'Servicio exclusivo por Whatsapp',
  callCenterEmployeePhone: '+55 (31) 2123-8200',

  parametros: {
    codModel: 'Cod. Modelo',
    newModel: 'NUEVO MODELO',
    headerText: [
      'Modelo',
      'Fuente',
    ],
  },

  modelVersionLabels: {
    headerItems: [
      { label: 'Modelo' },
      { label: 'Versión' },
      { label: 'Plazos Cumplidos' },
      { label: 'Expectativa de Facturación' },
    ],
  },

  forecastLabels: {
    invoiceForecast: 'Facturación',
    transitTimeForecast: 'Envío - Tránsito',
    transitTimeWarning: 'El plazo presentado no incluye el plazo de entrega de la factura y composición de la carga.',
  },

  modelVersionSideBarText: {
    title: 'Entenda como funciona',
    content: [
      {
        subTitle: '¿Qué son los plazos de facturación medios?',
        text: 'Es el plazo estimado que tardará la orden del cliente en ser facturado desde su inserción en el sistema Stellantis.',
      },
      {
        subTitle: '¿Cómo se calculan los plazos de facturación medios?',
        text: 'El tiempo medio de facturación se calcula utilizando Ordenes anteriores entregadas en los últimos 45 días.',
      },
      {
        subTitle: '¿Cuál es la tasa de actualización y la duración de plazos de facturación medios?',
        text: 'Los plazos de facturación medios se calculan, se ponen a disposición y se actualizan diariamente.',
      },
      {
        subTitle: 'Importante: transit time',
        text: 'Además del plazo de facturación, se debe tener en cuenta el tiempo de tránsito desde la fábrica hasta su concesionario. El tiempo de tránsito se cuenta a partir de la fecha de envío del vehículo en fábrica.',
      },
    ],
  },

  regionCitiesSideBarText: {
    title: 'Entenda como funciona',
    subtitle: 'La tabla representa el tiempo medio de Transit Time hasta el concesionario según el origen del vehículo. Para calcular el tiempo total de entrega del vehículo después de la facturación, es necesario agregar el tiempo de envío y de tránsito según origen.',
    content: [
      {
        subTitle: '¿Qué és el envío?',
        text: 'Es el tiempo que tarda el vehículo en ser enviado a fábrica tras la facturación. El plazo para despacho de vehículos es de 5 dias hábiles. Este tiempo se utiliza para trámites internos, como validación de facturas y composición de carga.',
      },
      {
        subTitle: '¿Qué és el Transit Time?',
        text: (
          <>
            Es el tiempo efectivo de transporte desde el origen hasta la empresa de entrega.
            Cada modelo tiene un origen definido, siendo:
            <ul>
              <li><b>Puerto:</b> modelos importados;</li>
              <li><b>Planta-Cordoba:</b> Cronos;</li>
              <li><b>Planta-Palomar:</b> a definir.</li>
            </ul>
          </>),
      },
    ],
  },

  parametersModelsVersions: {
    originHelpText: 'Origen de distribución del modelo',
  },

  orderManagementLabels: {
    headerItems: [
      {
        label: 'Industrial',
        helpText: 'O atraso do industrial é calculado a partir da somatória dos prazos médios de cada etapa.',
        sorting: 'orderByLateDays',
      },
      { label: 'Chassi' },
      { label: 'Modelo' },
      {
        label: 'Entrega Inicial Estimada',
        helpText: 'Entrega prevista a partir da data de criação do pedido.',
        sorting: 'orderByIdealDeliveryDate',
      },
      {
        label: 'Status Etapa',
        helpText: 'Atraso calculado a partir de prazos médios por etapa. Os prazos médios variam de acordo com Modelos e Versões.',
        sorting: 'orderByStepStatus',
      },
      { label: 'Pátio' },
    ],
  },

  invoiceLabels: {
    termsAgree: 'Declaro que he leído y acepto las condiciones de uso.',
    legalText: 'Al descargar este archivo, es necesario tener en cuenta que está expresamente prohibido el registro o financiamiento previo de cualquier vehículo, antes de que esté físicamente en el concesionario, siendo el uso íntegro de esta factura.\n\n*Al descargar la Electrónica Factura, los datos de este usuario serán recopilados y reservados para uso exclusivo del registro de descarga, y nada más.',
    employeeLegalText: 'Al descargar este archivo, es necesario tener en cuenta que está expresamente prohibido prelicenciar cualquier vehículo antes de que esté físicamente en el concesionario, siendo correcto el uso completo de esta Factura.',
    downloadSuccessTitle: 'Éxito en la obtención de la factura',
    downloadSuccessText: 'Tu descarga debería comenzar pronto',
    downloadErrorTitle: 'Algo salió mal al generar su factura',
    downloadErrorText: 'Actualiza la página y vuelve a intentarlo.',
    invoiceNotFoundTitle: 'La factura aún no está disponible',
    invoiceNotFoundText: 'Por favor, inténtelo de nuevo más tarde',
    downloadLoadingTitle: 'Espere',
    downloadLoadingText: 'Se está generando la factura',
    readyToDownload: 'Su factura está disponible para descargar.',
    downloadInvoice: 'Descarga Nota Fiscal',
    downloadNfe: 'Descarga NFe',
  },

  dealerLabels: {
    detailsNotIdentified: 'Detalles del concesionario no identificados',
    goToWebsite: 'Ir al sitio web del concesionario',
    goToMaps: 'Abrir ubicación en Google Maps',
    goToPhone: 'Llamar al concesionario',
  },

  clientCategoryLabels: {
    notIdentified: 'Categoría no identificada',
    byId: {
      0: 'Administración Pública Federal',
      1: 'Al por menor',
      2: 'Administración pública estatal',
      3: 'Administración Pública Municipal',
      5: 'Franquiciados unidos',
      7: 'Franquiciados de Hertz',
      9: 'Sorteo/Premios',
      11: 'Proveedor Fiasa',
      13: 'Compañía asociada',
      15: 'Diplomático/Embajada',
      16: 'Proveedores',
      18: 'Franquiciados Localiza',
      23: 'Donación',
      27: 'Concesionarias del Grupo Stellantis o CNHI',
      28: 'Dealer',
      31: 'Conductores de taxi sin exención',
      32: 'Localiza/Flota Total',
      34: 'Conductores de taxi con exención',
      35: 'Autoescuela',
      37: 'Empresas Fumageiras',
      40: 'Conductores de aplicación',
      41: 'Más comida',
      45: 'Empleado de Itaú/Bradesco/Santander',
      50: 'PCD',
      51: 'Productor rural',
      54: 'Empresa de alquiler Abla',
      61: 'Empresa de alquiler',
      65: 'Propietarios de flotas',
      66: 'Agroindustria',
      67: 'Ducato',
    },
  },

  telaPedidosLabels: {
    screenTitle: 'Ordenes',
    botaoCarregarMais: 'Carga más',
    listPedidosHeadersTitle: [
      'Tipo',
      // 'Categoria',
      'Orden',
      'Nombre del Cliente',
      'Entrega Actual',
      'Status',
      'Pendientes',
      // 'Nº Orden',
    ],
    listPedidosHeadersTitleAhorro: [
      'Tipo',
      'Solicitud',
      'Grupo',
      'Orden',
      'Nombre del cliente',
      'Orden Comercial',
    ],
    listPedidosHeadersTooltip: [
      '',
      '',
      '',
      { upperText: 'Término calculado en base a información actualizada del fabricante de automóviles.', lowerText: 'El plazo puede variar.' },
      '',
      '',
      '',
    ],
    errorCarregarLista: 'Se produjo un error al cargar la lista de Ordenes. Actualice la página.',
    carregarLista: 'Actualizar página',
    listTitle: 'Lista de Ordenes',

  },

  componenteCpfText: {
    cpfInvalido: 'CUIL no válido',
  },

  listPedidosLabels: {
    atendimento: 'Asistencia',
    atendimentos: 'Atenciones',
    emDia: 'En día',
    diasAtrasados: 'días con retrasos',
    atrasado: 'Retraso',
    diaAtrasado: 'día con retrasos',
    emRisco: 'Riesgo de retraso',
    pedidos: 'Ordenes',
    pedido: 'Orden',
    delivered: 'Entregado',
    late: 'Retraso',
    inRisk: 'Riesgo de retraso',
    withoutOrder: 'Sin orden industrial',
    noOrder: 'Sin orden',
    noForecast: 'Sin previsión',
    ongoing: 'En curso',
  },

  telaHomeLabels: {
    screenTitle: 'Home',
  },

  managementPage: {
    title: 'Seguimiento de retrasos',
    emptyResult: 'No hay ninguna solicitud para mostrar.',
  },

  delayManagementDetails: {
    industrialOrderTypeText: 'Orden Industrial',
    orderTitle: 'Comercial',
  },

  componentBuscarLabels: {
    placeholder: 'Búsqueda de cliente, CUIL, CUIT, chasis, orden comercial o industrial',
    placeholderDelay: 'Búsqueda de cliente, CUIL, CUIT, chasis o orden industrial',
  },

  loginTexts: {
    systemDescription: 'Sistema de seguimiento de Ordenes',
    loginTitle: 'Inicie sesión con su login de red.',
    submitButton: 'ACCESO',
    consultButton: 'CONSULTAR',
    welcomeText: 'Bienvenido(a)\na Easy Tracking',
    aboutSystemTitle: 'Acerca de Easy Tracking',
    aboutSystemText: () => <p>{'En línea con las estrategias de'} <strong>transformación digital</strong> {'de Stellantis y buscando la'} <strong>excelencia en la satisfacción de nuestros clientes</strong>{', compartimos de primera mano la herramienta Easy Tracking.\n\nPara uso exclusivo para clientes de Ventas a Empleados, será posible rastrear la orden de 0km, pasando por las'} <strong>previsiones de finalización</strong> {'de todas las fases del proceso de producción, facturación, envío, hasta la llegada al concesionario.\n\nPara acceder, basta con informar el número CUIL del comprador.'}</p>,
    aboutSystemResumed: () => <p>{'En línea con las estrategias de'} <strong>transformación digital</strong> {'de Stellantis y buscando la'} <strong>excelencia en la satisfacción de nuestros clientes</strong>{', compartimos de primera mano la herramienta Easy Tracking.\n\nPara uso exclusivo para clientes de Ventas a Empleados, será posible rastrear la orden de 0km, pasando por las'} <strong>previsiones de finalización</strong> {'de todas las fases del proceso de producción, facturación, envío, hasta la llegada al concesionario.'}</p>,
    trackYourOrder: 'Seguimiento de su orden on-line',
  },

  telaLogin: {
    loginInvalido: 'Nombre de usuario/contraseña no válido(s).',
    sessaoExpirada: 'Su sesión ha caducado. Inicie sesión de nuevo.',
    erroResetPassword: 'Algo salió mal. Verifique su nombre de usuario y contraseña o abra un ticket en Drive IT.',
    erroLoginSenhaIncorretos: 'Algo salió mal. Verifique su nombre de usuario y contraseña.',
    erroAcessoNegado: 'Acceso denegado. Comuníquese con su gerente de capacitación.',
    erroInterno: 'Algo salió mal. Por favor, inténtelo de nuevo más tarde.',
    clientBadRequest: 'Algo salió mal. Verifique el llenado de datos y vuelva a intentarlo. Si el error persiste, comuníquese con el concessionario.',
    cpfNaoEncontrado: 'No se encontró ninguna orden para el CUIL completado.',
    googleRecaptcha: 'Por favor, complete el captcha para continuar.',
    radioCustomerCare: 'Stellantis',
    radioDealer: 'Concessionario',
    radioClient: 'Cliente',
    radioEmployee: 'Empleado',
  },

  downloadButton: {
    titleSnackbarErrorDownload: 'Error al exportar la plantilla. Intente descargar de nuevo.',
    buttonTextError: 'Cerrar',
  },

  uploadButton: {
    buttonTextError: 'Cerrar',
    titleSnackbarSuccess: 'Plantilla importada correctamente.',
    titleSnackbarErrorUpload: 'Error al importar la plantilla. Intente cargar de nuevo.',
  },

  userEntry: {
    login: 'Ingresa tu UserID',
    loginDealer: 'Ingresa tu Email',
    password: 'Contraseña',
    forgotPassword: 'Olvide mi contraseña',
    clientLogin: 'CUIT o CUIL',
    clientLoginOrder: 'Número de la orden',
    customercareLogin: 'Ingresa tu CUIL',
    employeesLogin: 'Ingresa tu CUIL',
    sessionExpired: 'Su sesión ha caducado.\nInicie sesión de nuevo.',
    dealerCredentialMessage: 'Para acceder a Easy Tracking, será redirigido a Link Entry y volverá después de iniciar sesión.',
    employeeCredentialMessage: 'Rastree online o verifique su orden',
    clientCredentialMessage: 'Rastree online o verifique su orden',
    redirectMessages: {
      title: 'Aviso de redireccionamiento',
      subtitle: 'Para solicitar una nueva contraseña, será redirigido al panel de inicio de sesión de WCD.',
    },
  },

  telaDetalhesPedidos: {
    inRisk: 'COMPLETE SIN RETRASO',
    late: 'RETRASO EN EL PASO',
    estimatedTimeToStep: 'Tiempo estimado para etapa',
    stepStatus: {
      done: 'Concluido',
      late: 'Previsto',
      risk: 'Previsto',
      current: 'Previsto',
      empty: 'Previsto',
    },
    returnToList: 'Volver a la lista de Ordenes',
    seeMore: 'Ver Más',
    hide: 'Ocultar',
  },

  stepNames: {
    pedido: 'Orden',
    impostacao: 'configuration',
    montagem: 'assembly',
    produzido: 'produced',
    faturado: 'invoice',
    entregaNota: 'invoiceDelivery',
    expedicao: 'dispatch',
    concessionaria: 'dealer',
    entrega: 'delivered',
    refaturamento: 'refactored',
    pagamento: 'payment',
  },

  labelsPedidoComercial: {
    yourOrders: 'Sus Ordenes',
    titulo: 'Orden',
    tituloDealer: 'Orden',
    codigoPedido: '#',
    dataPedido: 'Orden creada en ',
    concessionariaEntrega: 'Orden del Concesionario',
    concessionariaPedido: 'Orden del concesionario',
    atrasoPedido: 'Razones del retraso en la orden',
    pendConc: 'pendConc',
    pendCli: 'pendCli',
    codigo: 'cod.',
    middleDot: ' \u00B7 ',
    salesCommission: 'Comisión',
    discount: 'Descuento',
    concessionariaEntregaInicial: 'Entrega Inicial Estimada',
    contateConcessionaria: 'En caso de duda, póngase en contacto con el concesionario responsable de la orden.',
  },

  labelsPedidoIndustrial: {
    modelo: 'Modelo',
    ano: 'Año',
    versao: 'Versión',
    cor: 'Color externo',
    revestimento: 'Revestimiento',
    grupoOpcionais: 'Grupo de opciones',
    opcionais: 'Opciones',
    chassi: 'Chasis',
    patio: 'Patio',
  },

  labelsRequestResume: {
    late: 'Retraso',
    risk: 'Riesgo de retraso',
    beTimes: 'En Día',
    pendencyDocs: 'Documentación pendiente',
    blockDealer: 'Concesionario bloqueado',
    orderSwap: 'Cambio de orden',
    blockClient: 'Pendiente financiera',
    pedidos: 'Ordenes',
  },

  labelsTransitTime: {
    destinyTitle: 'Destinos',
    destinations: ['Puerto', 'Planta-Cordoba', 'Planta-Palomar'],
    brands: ['FCA', 'CITROEN/DS', 'PEUGEOT'],
    keys: ['puerto', 'cordoba', 'palomar'],
    filterCity: 'Filtrar municipio',
    saveModalTitle: 'Guardar ediciones',
    saveModalText: (
      <>Sus cambios solo entrarán en vigencia el siguiente día hábil.
        <strong> ¿Quieres guardar los nuevos datos introducidos?</strong>
      </>
    ),
    discardModalTitle: 'Descartar ediciones',
    discardModalText: (
      <>Descartar implicará perder todos los cambios.
        <strong> ¿Quieres descartar?</strong>
      </>
    ),
    error: 'Error al guardar las ediciones. Inténtalo de nuevo.',
    success: 'Las ediciones se guardaron exitosamente.',
  },

  settingsTransitTimeScreen: {
    errorLoading: 'Error al cargar el contenido de la lista de Transit Time.',
    errorInserting: 'Error al insertar uno o más datos.',
    title: 'Transit Time',
  },

  settingsParametersScreen: {
    stepsAndMacroAreas: 'Etapas y Macro-Areas',
    title: 'Parámetros',
    saveModalTitle: 'Guardar ediciones',
    saveModalText: (
      <>Sus cambios solo entrarán en vigencia el siguiente día hábil.
        <strong> ¿Quieres guardar los nuevos datos introducidos?</strong>
      </>
    ),
    discardModalTitle: 'Descartar ediciones',
    discardModalText: (
      <>Descartar implicará perder todos los cambios.
        <strong> ¿Quieres descartar?</strong>
      </>
    ),
  },

  settingsModelsVersions: {
    modelsAndVersions: 'Modelos y Versiones',
    newModel: 'Nuevo modelo',
    newVersion: 'Nueva versión',
    deleteModelTitle: 'Eliminar modelo',
    // eslint-disable-next-line max-len
    deleteModelText: <p>La eliminación también eliminará todas las versiones de este modelo. No es possible revertir la acción. <b>¿Quieres eliminar?</b></p>,
    deleteVersionTitle: 'Eliminar versión',
    // eslint-disable-next-line max-len
    deleteVersionText: <p>Eliminar implicará eliminar solo la versión seleccionada. No es possible revertir la acción. <b>¿Quieres eliminar?</b></p>,
    successDeleteModel: 'Éxito al eliminar el modelo',
    errorDeleteModel: 'Error al eliminar el modelo. Inténtalo de nuevo.',
    successDeleteVersion: 'Éxito al eliminar la versión',
    errorDeleteVersion: 'Error al eliminar la versión. Inténtalo de nuevo.',
    successPutModel: 'Éxito en guardar el modelo',
    errorPutModel: 'Error al guardar el modelo. Inténtalo de nuevo.',
    successPutVersion: 'Éxito en guardar la versión',
    errorPutVersion: 'Error al guardar la versión. Inténtalo de nuevo.',
    fillBrandField: 'Por favor ingrese la marca del modelo.',
  },

  settingsVisionConfiguration: {
    title: 'Control de Visibilidad',
    permissionsTooltip: 'En Permisos, se muestra la información que se muestra actualmente para cada modelo y versión.',
    selectedItems: 'elementos seleccionados',
    selectModelVersion: 'Seleccione un modelo o versión que desee cambiar.',
    searchInput: 'Buscar modelo, versión o código',

    sideBar: {
      title: 'Entenda como funciona',
      titleEditing: 'Cambiar Permisos',
      warning: 'Habilitar o deshabilitar la visibilidad de cualquier elemento en un modelo cambiará la visibilidad de todas sus versiones.',
      content: [
        {
          subTitle: '¿Que es el control de visibilidad?',
          text: 'Es el control de permisos para visualizar información y funcionalidades de Easy Tracking, según perfiles de usuario, Modelos, Versiones y tipo de orden. Con él, es posible elegir artículo por artículo, lo que se debe mostrar para cada corte.',
        },
        {
          subTitle: '¿Qué permisos están disponibles?',
          // eslint-disable-next-line max-len
          text: <>Actualmente puedes controlar la visibilidad de 3 elementos:<ul><li>Fechas de finalización de los pasos de la línea de tiempo;</li><li>Status de retraso e riesgo de retraso, junto con el recuento de días;</li><li>Previsiones de finalización de pasos de la línea de tiempo.</li></ul></>,
        },
      ],
      contentApi: [
        {
          subTitle: '¿Que es el control de visibilidad?',
          text: 'Es el control de permisos para visualizar información y funcionalidades de Easy Tracking, según perfiles de usuario, Modelos, Versiones y tipo de orden. Con él, es posible elegir artículo por artículo, lo que se debe mostrar para cada corte.',
        },
        {
          subTitle: '¿Qué permisos están disponibles?',
          // eslint-disable-next-line max-len
          text: <>Actualmente puedes controlar la visibilidad de 3 elementos:<ul><li>Fechas de finalización de los pasos de la línea de tiempo;</li><li>Status de retraso e riesgo de retraso, junto con el recuento de días;</li><li>Previsiones de finalización de pasos de la línea de tiempo.</li><li>Disponibilización de los datos del vehículo al cliente</li></ul></>,
        },
      ],
    },

    submenu: [
      {
        roleText: 'CLIENTE FINAL',
        roleId: 'ROLE_CLIENT',
        isDisabled: false,
      },
      {
        roleText: 'EMPLEADO',
        roleId: 'ROLE_EMPLOYEE',
        isDisabled: true,
      },
      {
        roleText: 'Dealer',
        roleId: 'ROLE_DEALER',
        isDisabled: false,
      },
      {
        roleText: 'Customer Care',
        roleId: 'ROLE_CUSTOMERCARE',
        isDisabled: false,
      },
      {
        roleText: 'Api',
        roleId: 'ROLE_API_FULL_ACCESS',
        isDisabled: false,
      },
    ],

    dealerTooltip: 'Concesionarios',
    employeeTooltip: 'Empleados',
    directSaleTooltip: 'Ventas directas',
    savingsPlanTooltip: 'Plan de Ahorro',

    tabs: [
      {
        text: 'Concesionarios',
        key: 'dealer',
      },
      {
        text: 'Empleados',
        key: 'employee',
      },
      {
        text: 'Ventas directas',
        key: 'directSale',
      },
      {
        text: 'Plan de Ahorro',
        key: 'savingsPlan',
      },
    ],

    rules: {
      concluded: {
        text: 'Fechas de finalización',
        description: 'Visualización de fechas en las que se completaron los pasos.',
      },
      late: {
        text: 'Status de retraso',
        description: 'Visualización de status de retraso y riesgo de retraso, junto con el recuento de días para cada estado.',
      },
      estimated: {
        text: 'Fechas estimadas',
        description: 'Visualización de las fechas previstas para la finalización de cada paso.',
      },
      visible: {
        text: 'Datos del vehículo',
        description: 'Disponibilización de los datos del vehículo al cliente.',
      },
      differentRules: {
        text: 'Versiones con diferentes permisos',
        description: 'Una o más versiones de esta plantilla tienen diferentes permisos.',
        style: { backgroundColor: 'unset' },
      },
    },
  },

  macroAreaSteps: [
    {
      name: 'Producción',
      steps: [
        'produced',
      ],
    },
    {
      name: 'Importación',
      steps: [
        'assigned',
        'traveling',
        'customsPending',
        'customsRelease',
      ],
    },
    {
      name: 'Comercial',
      steps: [
        'available',
        'invoice',
        'refactored',
      ],
    },
    {
      name: 'Cliente',
      steps: [
        'dispatch',
        'delivered',
      ],
    },
  ],

  snackBarLabels: {
    updateScreen: 'Actualizar página',
    error: 'Error al guardar las ediciones. Inténtalo de nuevo.',
    success: 'Las ediciones se guardaron exitosamente.',
  },

  transitTimeLabels: {
    save: 'Guardar ediciones',
    cancel: 'Cancelar',
    saveModal: 'Guardar',
    edit: 'Editar',
    modalMessageLight: 'Sus cambios no se aplicarán hasta el siguiente día hábil.',
    modalMessageBold: '¿Quieres guardar los nuevos datos introducidos?',

    cancelModalMessageLight: 'Cancelar resultará en perder todos los cambios.',
    cancelModalMessageBold: '¿Quieres cancelar?',
  },

  unregisteredVersionModal: {
    warningTitleSingular: ' versión no está registrada.',
    warningTitlePlural: ' versiones no están registradas.',
    title: 'Versiones no registradas',
  },

  unregisteredDataModal: {
    noWarningTitle: ' Sin registros pendientes.',
    warningTitleSingular: ' Registro pendiente.',
    warningTitlePlural: ' Pendiente de registro.',
    title: 'Pendiente de registro.',
    subtitle: 'Pedientes',
    unregisteredDealers: 'Dealers no registrados',
    unregisteredModels: 'Modelos no registrados',
    unRegistered: ' no registrado',
  },

  delayedOrdersComponent: {
    warningTitleSingular: ' Ordenes el concesionario.',
    warningTitlePlural: ' Ordenes el concesionario.',
    title: 'Ordenes el concesionario',
    subTitle: 'Número de vehículos "Entregados al Concesionario"',
  },

  parametersTimeLabels: {
    save: 'Guardar ediciones',
    cancel: 'Cancelar',
    saveModal: 'Guardar',
    edit: 'Editar',
    newModel: 'Nuevo Modelo',
    modalMessageLight: 'Sus cambios no se aplicarán hasta el siguiente día hábil.',
    modalMessageBold: '¿Quieres guardar los nuevos datos introducidos?',

    cancelModalMessageLight: 'Cancelar resultará en perder todos los cambios.',
    cancelModalMessageBold: '¿Quieres cancelar?',

    modalMessageWrongData: 'Hay uno o más registros con un nombre de modelo y/o códigos de modelo no válido(s).',

    error: 'Error al guardar las ediciones. Inténtalo de nuevo.',
    success: 'Las ediciones se guardaron exitosamente.',

    steps: {
      produced: 'En Producción',
      assigned: 'Asignado Sin Embarcar',
      traveling: 'En Viaje',
      customsPending: 'Pendiente de aduana',
      customsRelease: 'Liberado de Aduana',
      available: 'Disponible',
      invoice: 'Facturado',
      dispatch: 'Expedido a CE',
      dealer: 'Entregado a CE',
      refactored: 'Refacturado',
      delivered: 'Entregado a Cliente',
    },

    tooltipText: {
      produced: 'Plazo estimado para la etapa de impostación.',
      assigned: 'Plazo estimado para la etapa de asignado sin embarcar.',
      traveling: 'Plazo estimado para la etapa de en viaje.',
      customsPending: 'Plazo estimado para la etapa de pendiente de aduana.',
      customsRelease: 'Plazo estimado para la etapa de liberado de aduana.',
      available: 'Plazo estimado para la etapa de disponibilidad.',
      invoice: 'Plazo estimado de facturación del modelo.',
      dispatch: 'Plazo estimado de expedición del modelo.',
      refactored: 'Plazo estimado de refacturación del modelo.',
      delivered: 'Plazo estimado de entrega del modelo.',
    },
  },

  detailsCommercial: {
    errorLoading: 'Se produjo un error al cargar los detalles de la orden comercial.',
    noIndustrialOrders: 'No existe orden industrial para esa orden comercial',
  },

  labelsDashboard: {
    deliveryHistory: 'Historial de Entrega',
    pedidos: 'Ordenes',
    acessos: 'Accesos',
    acessosOpcao: 'Accesos à plataforma',
    pedidosEntregues: 'Ordenes ENTREGADAS',
    pedidosEmAndamento: 'Ordenes EN CURSO',
    pedidosIndustriais: 'Ordenes Industriales',
    kpiCsi: 'Ordenes de VD entregadas',
    kpiPorArea: 'KPI por Área',
  },

  labelsOrderType: {
    dealer: 'dealer',
    employee: 'employee',
    directSale: 'directSale',
    savingsPlan: 'savingsPlan',
    notSold: 'notSold',
    others: 'others',
    dealerKey: 'DL',
    employeeKey: 'EM',
    directSaleKey: 'DS',
    savingsPlanKey: 'PA',
    notSoldKey: 'NV',
    othersKey: '',
    dealerTooltip: 'Concesionarios',
    employeeTooltip: 'Empleados',
    directSaleTooltip: 'Ventas directas',
    savingsPlanTooltip: 'Plan de Ahorro',
    notSoldToolTip: 'No vendido',
    othersToolTip: 'Otros',
  },

  orderInformationBlock: {
    dealerPendency: 'Concesionario bloqueado',
    clientDocumentPendencyFlag: 'PENDOC',
    orderSwapFlag: 'Ordenes de cambio',
    clientFinancialPendencyFlag: 'Pendiente financiera',
    commercialBlockLabel: 'Bloqueo Comercial',
    outbountBlockLabel: 'Bloqueo Outbound',
    qualityBlockLabel: 'Bloqueo de Calidad',
  },

  orderDetail: {
    pedidoCriado: 'Orden creada en',
    pedidoIndustrial: 'Orden industrial #',
    orderDetailTitle: 'Detalles de las Ordenes',
    clientOrderDetailTitle: 'Su Vehículo',
    noIndustrialOrdersTitle: 'Sin orden industrial',
    noIndustrialOrdersSubtitle: 'No hay orden industrial para esta orden comercial.',
  },

  orderResume: {
    orders: 'Ordenes',
    historySection: 'Entregas de Vendas Diretas de Historial de Ordenes',
    status: 'STATUS',
    numOrders: 'Nº DE Ordenes',
    kpiCsi: {
      onTime: 'Ordenes en día',
      late: 'Ordenes con retraso',
    },
  },

  // ================================ ================================ //

  timeLineLabels: {
    orderCreation: 'Orden creada',
    invoiceDownloadBlocked: 'Debido a que violaron los términos de uso, la descarga de DANFe fue bloqueada para su concesionario.',
    flagReprogrammedText: 'La fecha de producción de su orden aún se reprogramará, por lo que la fecha mostrada no es la fecha real.',
    alertDefault: {
      title: 'Previsión predeterminada ',
      text: 'El pronóstico abajo se determina mediante la estimación del modelo y se puede cambiar una vez que se conoce el destino del vehículo.',
    },
  },

  chamadoSiebelConsts: {
    editableProtocol: 'Informar al número de protocolo de SIEBEL para esta orden industrial',
    filledProtocol: 'Hay un número de protocolo SIEBEL para esta orden industrial',
    fillProtocolNumber: 'Inserte el número de protocolo SIEBEL',
    mensagemConfirmacao: '¿Está seguro de que desea eliminar el número de protocolo SIEBEL informado?',
  },

  stepDateStatus: {
    unavailable: 'FECHA INDISPONIBLE',
    done: 'CONCLUIDO',
    foreseen: 'ESTIMADO',
    noForeseen: 'SÍN PREVISION',
    ongoing: 'EN CURSO',
  },

  statusTabLabel: {
    late: 'Retraso',
    lateRisk: 'Riesgo Retraso',
    inRisk: 'En Riesgo',
    onTime: 'En Día',
    delivered: 'Entregado',
  },

  filterLabels: {
    filterBy: 'Filtrar por:',
    changeFilters: 'Cambiar filtros',
    filterOrders: 'Filtrar Ordenes',
    filterPlans: 'Filtrar Plan',
    tobeFiltered: 'Filtrar',
    filteredBy: 'Filtrado',
    changeDates: 'Cambiar fechas',
    filterDates: 'Filtrar fechas',
    date: 'FILTRAR POR FECHA',
    dateSelection: 'Seleccione las fechas de entrega actuales:',
    dateFrom: 'De:',
    dateTo: 'A:',
    dealerSelection: 'Seleccionar concesionarios del grupo',
    dealerSelected: 'concesionario seleccionado',
    dealersSelected: 'concesionarios seleccionados',
    secondaryDealersTitle: 'Concesionarios de la red',
    dealerIdTitle: 'Concesionarios',
    dealerId: 'Codigo del concesionario',
    dealerIdPlaceholder: 'Codigos separados por ;',
    models: 'Modelos',
  },

  macroAreaUser: {
    title: 'Alertas de pedidos \npor macro-áreas',
    infoText: () => <><p>{'Implementamos um sistema que notificará quando surgirem'} <strong>pedidos atrasados</strong> e com <strong>risco de atraso</strong>{', de acordo com as áreas responsáveis. Para isso, você pode selecionar de quais macro-áreas você quer ser notificado.'}</p><p>{'Caso você não selecione nenhuma, o sistema de notificações será suspenso, mas você pode reativá-lo ao clicar em '} <strong>{'Configurações > Alertas.'}</strong></p></>,
    noAlert: 'Não quero ser alertado',
    easyTracking: 'EASY TRACKING',
    confirm: 'Confirmar',
  },

  menu: {
    pedidos: 'Ordenes',
    dashboard: 'Dashboard',
    modelVersionForecast: 'Plazos Medios',
    clientOrders: 'Ordenes',
    popUpMenu: {
      transitTime: 'TransitTime',
      parametros: 'Parametros',
      visionConfiguration: 'Control de Visibilidad',
    },
  },

  filterDates: {
    title: 'Filtrar por fecha de entrega',
    subtitle: 'Vea solo las Ordenes que se espera que se entreguen entre las fechas a continuación.',
    titleDelay: 'Filtrar por fecha de vencimiento y riesgo',
    subtitleDelay: 'Filtre para ver solo las Ordenes que pasaron al estado de Riesgo atrasado o retrasado en fechas específicas:',
    initialDate: 'La fecha de Inicio',
    finalDate: 'Fecha Final',
  },

  feedbackLabels: {
    title: 'Cuéntenos su experiencia con Easy Tracking',
    subtitle: '¡Nos gustaría recopilar su opinión sobre el sistema para que sea posible ofrecer una plataforma cada vez más adecuada a sus necesidades!',
    aboutEasyTracking: '¿Cómo calificaría Easy Tracking?*',
    comment: '¿Le gustaría dejar alguna sugerencia de mejora?',
    warning: '*Al enviar estos comentarios, usted acepta que su nombre y los detalles de su correo electrónico se compartan con Stellantis.',
    selection: 'Seleccione una opción',
    nps: {
      bad: 'MUY MALO',
      veryGood: 'MUY BIEN',
    },
    success: {
      title: '¡Feedback enviados com correctamente!',
      subtitle: 'Gracias por ayudarnos a mejorar la experiencia de Easy Tracking.',
      textButton: 'Cerca',
    },
    error: {
      title: 'Se produjo un error al intentar enviar.',
      subtitle: 'Intente enviar feedback nuevamente.',
      textButton: 'Intentar nuevamente',
    },
    sendFeedback: 'Enviar feedback',
    sendingMessage: '¡Tus feedback están siendo enviados!',
    feedbackTip: {
      title: 'Nos gustaria saber de usted',
      text: 'Hola, nos gustaría conocer su experiencia con Easy Tracking.',
      buttonRefuse: 'Ahora no',
      buttonAccept: 'Aceptar',
    },
  },

  loginExpiredModal: {
    loginExpiredLogout: 'Cerrar Sesión',
    sessionExpiredTitle: 'Sesión Expirada',
    sessionaExpiredMessage: 'Su sesión ha expirado por inactividad. Por favor inicie sesión de nuevo.',
  },

  releaseNoteLabels: {
    errorTitle: 'Algo salió mal al cargar la información',
    errorSubtitle: 'Por favor, inténtalo de nuevo',
    notFoundTitle: 'No se encontraron actualizaciones',
    notFoundSubtitle: 'Por favor, inténtalo de nuevo más tarde',
  },

  orderInformation: {
    carName: 'Nombre del coche',
    warning: '*Imagen solo para fines ilustrativos.',
    coating: 'revestimiento',
    commission: 'comisión',
    createdOn: 'Creado el',
    discount: 'descuento',
    externalColor: 'color externo',
    model: 'modelo',
    modelAndManufactureYear: 'año de fabricación/modelo',
    optionals: 'opcionales',
    vehicle: 'Vehículo',
    version: 'versión',
    typeLabel: {
      dealer: 'CONCESIONARIOS',
      employee: 'EMPLEADOS',
      directsale: 'VENTA DIRECTA',
      savingsplan: 'PLAN DE AHORRO',
      notsold: 'NO VENDIDO',
      others: 'Otros',
    },
  },

  snackBarExport: {
    close: 'Cerrar',
    message: 'Hubo un error al intentar descargar el report.',
  },

  orderFilterModalWarn: {
    title: 'Búsqueda de Ordenes',
    text: 'Para buscar Ordenes, utilice el número de la orden comercial en lugar del número de la orden industrial.',
  },

  faqQuestions: [
    {
      questionText: '¿Con qué frecuencia se actualizan los datos?',
      answer: '<p>Los datos del sistema se actualizan al comienzo de cada día, haciendo referencia al día anterior. La hora exacta en que se actualizó el sistema por última vez se encuentra en la lista de Ordenes en la esquina superior izquierda.</p>',
    },
    {
      questionText: '¿Cómo se calculan las fechas previstas?',
      answer: '<p>Cada etapa tiene un tiempo establecido por el equipo de Stellantis, y varía según el modelo, el patio de salida y la ciudad de entrega. Todos los días, cuando se actualizan los datos, los pronósticos se vuelven a calcular una vez finalizados y/o retrasados ​​en alguna etapa.</p>',
    },
    {
      questionText: '¿Cuáles son las reglas para las indicaciones de retraso y riesgo de retraso?',
      answer: '<p>Cuando se registra la orden, se establece una fecha de entrega ideal, en base a los parámetros definidos por el equipo de Stellantis.</p>'
        + '<p>Si el sistema prevé un retraso en la orden, en base al cálculo de las fechas esperadas, indicará '
        + '<span class="badge-inRisk badge">Riesgo de Retraso</span></p>'
        + '<p>Si la fecha actual excede la fecha ideal inicialmente establecida, el sistema indicará la orden como '
        + '<span class="badge-late badge">Retraso</span></p>',
    },
    {
      questionText: 'La orden está pendiente/retrasado para la entrega, pero ya está en manos del cliente, ¿qué pasó?',
      answer: '<p>La entrega al cliente se registra a través de la Garantía o Emplacamento, y se considera la fecha más reciente entre estos dos. Compruebe si la orden tiene realmente una Garantía o Certificado registrado.</p>',
    },
    {
      questionText: '¿Qué utiliza el sistema para validar la entrega en el concesionario?',
      answer: '<p>El sistema considera la orden entregada al concesionario desde el momento de la cancelación del ePod.</p>',
    },
    {
      questionText: '¿Qué utiliza el sistema para validar la entrega al cliente?',
      answer: '<p>La entrega al cliente se registra a través de la Garantía o Emplacamento, y se considera la fecha más reciente entre estos dos.</p>',
    },
    {
      questionText: '¿Puedo exportar los datos de mi billetera?',
      answer: '<p>Sí. El botón de exportación se encuentra en la esquina superior derecha, en la lista de Ordenes. Es posible exportar en formatos CSV y XLSX (Excell).</p>',
    },
    {
      questionText: '¿Puedo ver las Ordenes bloqueadas de calidad?',
      answer: '<p>No es posible tener la vista de bloqueo de calidad con Easy Tracking.</p>',
    },
    {
      questionText: '¿El sistema maneja todas las solicitudes de allRisk?',
      answer: '<p>No.</p>',
    },
    {
      questionText: '¿Cómo maneja el sistema los giros de notas?',
      answer: '<p>Cuando la fecha de facturación es mayor que la fecha de envío, el sistema considera que hubo un cambio de nota en la orden. Sin embargo, aún no ha asignado un flujo específico para el turno.</p>',
    },
    {
      questionText: 'Solo puedo ver un concesionario del grupo, ¿cómo veo las Ordenes de otro?',
      answer: '<p>En la esquina superior izquierda de la lista de Ordenes, puede seleccionar el concesionario del grupo que desea tener visibilidad. Sin embargo, solo podrá acceder a los concesionarios vinculados a su usuario de WCD. Si algún distribuidor del grupo no aparece para usted, consulte el portal WCD si tiene acceso a él.</p>',
    },
    {
      questionText: '¿Cómo puedo encontrar una orden específica?',
      answer: '<p>A través del campo de búsqueda o filtros, dentro de la lista de Ordenes.</p>'
        + '<p>A través del campo de búsqueda, podrá encontrar una orden a través del número de la orden comercial, industrial, de chasis y el CUIL/CUIT del cliente.</p>'
        + '<p>En la interfaz de filtro, podrá filtrar por fecha, estado de la orden, categoría de cliente y modelos de vehículo.</p>',
    },
  ],
};
