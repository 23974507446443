import { User, Token } from 'Common/Helpers/AuthHelper';
import { roleConsts } from 'Utils/consts';
import RequestRepository from '../Repositories/RequestRepository';

class FeedbackService {
  putFeedback = async (body) => {
    try {
      const userData = User.get();
      const newBody = { ...body, userId: userData.email, userType: userData.userProfile[0] };

      const isEmpployee = User.hasProfile(roleConsts.employees);
      const isClient = User.hasProfile(roleConsts.client);
      let userDocument = null;
      if (isEmpployee) {
        userDocument = Token.get().token;
      } else if (isClient) {
        userDocument = userData.clientDocument;
        newBody.userId = userDocument;
      }
      const url = userDocument ? `${userDocument}/feedback` : 'feedback';
      return RequestRepository.put(url, newBody);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default FeedbackService;
