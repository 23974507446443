import React from 'react';
import PropTypes from 'prop-types';
import MuiIconButton from '@mui/material/IconButton';
import { withStyles } from 'tss-react/mui';
import { ReactComponent as PlusIcon } from 'Common/Assets/addIcon.svg';
import { ReactComponent as MinusIcon } from 'Common/Assets/removeIcon.svg';
import './ResizeButton.scss';

const IconButton = withStyles(MuiIconButton, {
  root: {
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
    borderRadius: '50%',
    backgroundColor: '#FFFFFF',
    height: '32px',
    width: '32px',
    border: '1px solid rgba(12,28,45,0.15)',
  },
});

const ResizeButton = ({ isDetailBoxOpen, setDetailBoxOpen }) => {
  const handleButtonClick = () => {
    setDetailBoxOpen(!isDetailBoxOpen);
  };
  const ButtonSign = isDetailBoxOpen ? MinusIcon : PlusIcon;
  return (
    <IconButton onClick={handleButtonClick} className="resizeButton-mobile-button">
      <ButtonSign className="resizeButton-mobile-icon" />
    </IconButton>
  );
};

ResizeButton.propTypes = {
  isDetailBoxOpen: PropTypes.bool.isRequired,
  setDetailBoxOpen: PropTypes.func.isRequired,
};

export default ResizeButton;
