import ListPedidosRepository from 'Infrastructure/Repositories/ListPedidosRepository';
import { getTimestampWithoutHours } from 'Utils/dateUtils';

class BaseService {
  constructor(listRepository = new ListPedidosRepository()) {
    this.listRepository = listRepository;
  }

  getTimestampDate = (dates) => {
    const endDate = dates && dates.endDate ? getTimestampWithoutHours(dates.endDate) : null;
    const startDate = dates && dates.startDate ? getTimestampWithoutHours(dates.startDate) : null;
    const newDates = {
      endDate,
      startDate,
      isUsingDateFilter: dates.isUsingDateFilter,
    };
    return newDates;
  }

  async export(searchString, filters, method, fileName, fileType, isPlanAhorro, filtersExportPDA) {
    const {
      appliedDates, appliedFilters, isBilledFilter, stepStatus, sorting, selectedAhorroFilters,
      searchAhorroString,
    } = filters;
    const timestampDate = this.getTimestampDate(appliedDates);
    try {
      const { result } = await this.listRepository.export(
        searchString, appliedFilters, timestampDate, isBilledFilter, stepStatus,
        method, fileName, fileType, sorting, isPlanAhorro, searchAhorroString,
        selectedAhorroFilters, filtersExportPDA,
      );
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getExportCountPda(filtersExportPDA) {
    try {
      const { result } = await this.listRepository.getExportCountPda(filtersExportPDA);
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default BaseService;
