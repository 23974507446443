import { User } from 'Common/Helpers/AuthHelper';
import PropTypes from 'prop-types';

const ToggleUser = ({ profilesList, children }) => {
  if (User.hasOneOfProfiles(profilesList)) {
    return children;
  }
  return null;
};

ToggleUser.propTypes = {
  profilesList: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
};

export default ToggleUser;
