import React from 'react';
import PropTypes from 'prop-types';

import { stepStatusId } from 'Utils/consts';
import langValues from 'Config/i18n/index';

import './TagOrderStatus.scss';

const TagOrderStatus = ({ lateDays = null, statusId }) => {
  let text;
  switch (statusId) {
    case stepStatusId.delivered: {
      text = langValues.listPedidosLabels.delivered;
      break;
    }
    case stepStatusId.late: {
      if (lateDays != null) {
        text = `${lateDays} ${lateDays > 1 ? langValues.listPedidosLabels.diasAtrasados : langValues.listPedidosLabels.diaAtrasado}`;
      } else {
        text = langValues.listPedidosLabels.atrasado;
      }
      break;
    }
    case stepStatusId.inRisk: {
      text = langValues.listPedidosLabels.emRisco;
      break;
    }
    case stepStatusId.onTime: {
      text = langValues.listPedidosLabels.emDia;
      break;
    }
    case stepStatusId.ongoing: {
      text = langValues.listPedidosLabels.ongoing;
      break;
    }
    case stepStatusId.withoutOrder: {
      text = langValues.listPedidosLabels.withoutOrder;
      break;
    }
    default: {
      text = '';
      break;
    }
  }

  return <div className={`tagOrderStatus ${statusId}`}>{text}</div>;
};

TagOrderStatus.propTypes = {
  lateDays: PropTypes.number,
  statusId: PropTypes.string.isRequired,
};

export default TagOrderStatus;
